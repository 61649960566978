import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { subscribeOn, Subscription } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  templateUrl: './StockDetails.component.html'
})
export class StockDetailsComponent {
  id: number = 0;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  count: 0;
  ProductList: ProductModel[] = [];;
  NewStockProduct: StockProductModel = new StockProductModel;
  FilteredProductList: ProductModel[] = [];;
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];;
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel;
  NewInvoice: InvoiceModel = new InvoiceModel;
  token: any;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,private auth: AuthService) { }
  getStorageToken(): any {
    let url = this.ApiUrl + "data/getstoragetokenforinvoice";
    this.http.get<any>(url).subscribe(res => {

      this.token = res;
      this.GetStockDetails();
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.getStorageToken();
      }
    });
  }
  GetStockDetails() {
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe(res => {
      this.NewStock = res;
      if (this.NewStock.Invoice.InvoiceFile != null && this.NewStock.Invoice.InvoiceFile != "") {
        this.NewStock.Invoice.InvoiceFile = this.token.StorageAccountHost + '/invoices/' + this.NewStock.Invoice.InvoiceFile + this.token.StorageAccountToken;
      }
      this.NewStock.StockProduct.forEach((nos) => {
        nos.ExpandRecord = false;
      })

    }, res => { });
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Stock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.getStorageToken();

  }
}
