<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Proforma Invoice List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Proforma Invoice order here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button *ngIf="this.permission.Add" nz-button nzType="primary"
          routerLink="/home/salesorder/proformaInvoice/add">
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px; float: right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px; margin-right: 8px"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px; margin-right: 8px"></nz-date-picker>
      <input type="text" style="width: 250px; margin-right: 8px" nz-input placeholder="Search any column"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1200px', y: '515px' }" style="width: 100%" #basicTable
      [nzData]="this.ProformaInvoiceList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzWidth="350px" nzLeft>Invoice No.</th>
          <th nzWidth="350px">Customer Name</th>
          <th nzWidth="350px">Country of Origin</th>
          <th nzWidth="350px">PI Date</th>
          <th nzWidth="350px">Material Type</th>
          <th nzWidth="350px">HSN Code</th>
          <th nzWidth="350px">Port of loading</th>
          <th nzWidth="350px">Mode of Transport</th>
          <th nzWidth="150px">Final Destination</th>
          <th nzWidth="150px">Port Discharge</th>
          <th nzWidth="150px">Currency</th>
          <th nzWidth="150px">IGST</th>
          <th nzWidth="150px">Added Date</th>
          <th nzWidth="200px">Added By</th>
          <th nzWidth="200px" style="text-align: center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft>{{ data.ProformaInvoiceNumber }}</td>
          <td>{{ data.Customer?.CustomerName }}</td>
          <td>{{ data.CountryOfOrigin }}</td>
          <td>{{ data.ProformaInvoiceDate | date: "dd-MMM-yyyy"}}</td>
          <td>{{ data.MaterialType }}</td>
          <td>{{ data.Hsncode }}</td>
          <td>{{ data.PortOfLoading }}</td>
          <td>{{ data.ModeOfTransport }}</td>
          <td>{{ data.FinalDestination }}</td>
          <td>{{ data.PortOfDischarge }}</td>
          <td>{{ data.Currency }}</td>
          <td>{{ data.Gstn }}</td>
          <td>{{ data.AddedDate | date: "dd-MMM-yyyy" }}</td>
          <td>{{ data.AddedBy }}</td>
          <td nzRight style="text-align:center">
            <div>
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item (click)="OpenPIIDrawer(data)">View</li>
                  <li style="width: 120px;" nz-menu-item (click)="Edit(data)">Edit</li>
                  <li style="width: 120px;" nz-menu-item (click)="View(data)">Print</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<!--<nz-modal [nzWidth]="1000"
            [nzStyle]="{ top: '20px' }"
            [(nzVisible)]="isVisible"
            [nzTitle]="modalTitle"
            [nzContent]="modalContent"
            [nzFooter]=null
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>{{PopUpTitle}}</ng-template>
  
    <ng-template #modalContent>
  
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="12">
  
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Transport Company Name</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select Company">-->
<!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
<!--<nz-select class="form-select mb-2" nzShowSearch formControlName="TransportId" name="TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllVehicle($event)">
                <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s" [nzLabel]="s.TransportCompanyName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Vehicle Number</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">-->
<!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
<!--<nz-select class="form-select mb-2" nzShowSearch formControlName="VehicleId" name="VehicleId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.VehicleList;" [nzValue]="s.VehicleId" [nzLabel]="s.VehicleNumber"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
  
  
          <nz-form-item>
            <nz-form-label [nzSpan]="10">GateIn Person Name</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Contact Person Name required">
              <input nz-input formControlName="GateInPerson" name="GateInPerson" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10">GateIn Person Number</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
              <input nz-input formControlName="GateInPersonContact" name="GateInPersonContact" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
  
  
          <nz-form-item>
            <nz-form-label [nzSpan]="5">Upload Image</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Contact Person Name required">
              <input class="form-select mb-2" [(ngModel)]="uploadinvoice" [ngModelOptions]="{standalone: true}" type="file" multiple="multiple" (change)="onChange($event)">
              <div *ngIf="filesSelected" hidden="true">
                <h2>Upload Progress</h2>
                <pre>{{uploadProgress$ | async | json}}</pre>
              </div>
            </nz-form-control>
          </nz-form-item>
  
        </div>
      </div>
  
      <nz-divider></nz-divider>
  
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="10">
          <nz-form-item>
  
            <nz-form-label [nzSpan]="10">Supplier Name</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select supplier Name">-->
<!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
<!--<nz-select #microwaveRef class="form-select mb-2" nzShowSearch nzSize="default" (ngModelChange)="onSelectedSupplierChange($event)" [(ngModel)]="InvoiceModel.SupplierId" [ngModelOptions]="{standalone: true}" name="SupplierName" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId" [nzLabel]="s.SupplierName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="10">
          <nz-form-item>
  
            <nz-form-label [nzSpan]="10">Purchase Order</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select supplier Name">-->
<!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
<!--<nz-select #microwaveRef class="form-select mb-2" nzShowSearch nzSize="default" (ngModelChange)="onSelectedPOChange($event)" [(ngModel)]="InvoiceModel.Poid" [ngModelOptions]="{standalone: true}" name="PO" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.FilteredPurchaseOrderList;" [nzValue]="s.Poid" [nzLabel]="s.Ponumber"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="10">
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Invoice Number</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter Invoice number">
              <nz-select nzShowSearch #microwaveRef class="form-select " *ngIf="IsChecked" (ngModelChange)="onSelectedInvoiceChange($event)" nzSize="default" [(ngModel)]="InvoiceModel.InvoiceId" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.InvoiceList;" [nzValue]="s.InvoiceId" [nzLabel]="s.InvoiceNumber"></nz-option>
              </nz-select>
              <input nz-input name="GateInPersonContact" *ngIf="!IsChecked" [(ngModel)]="InvoiceModel.InvoiceNumber" [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-form-item>
  
        </div>
        <div nz-col [nzSpan]="10">
          <nz-form-item>
  
            <label nz-checkbox [(ngModel)]="IsChecked" [ngModelOptions]="{standalone: true}">Existing Invoice</label>
          </nz-form-item>
  
        </div>
        <div nz-col [nzSpan]="4">
          <a class="btn btn-sm btn-light-primary" style="float:right  " (click)="handleAddInvoice()"><i nz-icon nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add</a>
        </div>-->
<!--<div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="12">Invoice Date</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
              <nz-date-picker class="form-select mb-2" formControlName="InvoiceDate"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="12">Invoice Total Price</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
              <input nz-input formControlName="InvoiceTotalPrice" name="GateInPersonContact" />
            </nz-form-control>
          </nz-form-item>
        </div>-->

<!--</div>
  
  
      <nz-divider></nz-divider>
  
  
  
  
  
  
    </ng-template>
  
  
  
  </nz-modal>-->

<!--<nz-drawer
  [nzClosable]="true"
  [nzVisible]="isVisible"
  nzPlacement="right"
  [nzWidth]="1000"
  nzTitle="Purchase Order details"
  (nzOnClose)="handleCancel()"
>
  <ng-container *nzDrawerContent>
    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="PurchaseOrder">
      <nz-descriptions-item nzTitle="Order No" nzColon="true"
        ><b>{{ PurchaseOrder.Ponumber }}</b>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Order Date"
        ><b>{{
          PurchaseOrder.PocreationDate | date: "dd-MMM-yyyy"
        }}</b></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="GRN"
        ><b>{{ PurchaseOrder.Grn }}</b></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Supplier Name" [nzSpan]="2"
        ><b>{{ PurchaseOrder.SupplierName }}</b></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Reference#"
        ><b>{{ PurchaseOrder.Reference }}</b></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Delivery Terms">
        <b>{{ PurchaseOrder.DeliveryTerm }}</b></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Shipment Preference"
        ><b>{{ PurchaseOrder.TransportCompanyName }}</b></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Payment Terms"
        ><b>{{ PurchaseOrder.PaymentTerm }}</b></nz-descriptions-item
      >-->

<!--<nz-descriptions-item nzTitle="View Invoice">
      <a class="btn btn-sm btn-light-info" *ngIf="this.NewStock.Invoice.InvoiceFile != null" [href]="NewStock.Invoice.InvoiceFile" target="_blank">Link to Invoice</a>
      <span *ngIf="this.NewStock.Invoice.InvoiceFile == null">No invoice file found</span>
    </nz-descriptions-item>-->
<!--</nz-descriptions>
    <nz-divider></nz-divider>
    <nz-table
      nzSize="small"
      *ngIf="PurchaseOrder"
      [nzData]="['']"
      #basicTable1
      nzBordered
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>S.No</th>
          <th>Product</th>
          <th>Unit</th>-->
<!-- <th>Grade</th> -->
<!--<th>Rate</th>
          <th>Quantity</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of PurchaseOrder.PurchaseOrderProduct; let i = index"
          nzShowPagination="false"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Unit }}</td>-->
<!-- <td>{{ data.Grade }}</td> -->
<!--<td>{{ data.Rate }}</td>
          <td>{{ data.Quantity }}</td>
          <td>{{ data.Amount }}</td>
        </tr>
      </tbody>
      <tfoot *ngIf="this.PurchaseOrder.PurchaseOrderProduct.length > 0">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ PurchaseOrder.PototalAmount }}</th>
          <th></th>-->
<!-- <th nzWidth="20%">Action</th> -->
<!--</tr>
      </tfoot>
    </nz-table>
  </ng-container>
</nz-drawer>-->
<app-PIDrawerView></app-PIDrawerView>