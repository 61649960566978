import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute,  Params } from '@angular/router';
import { LoadingService } from '../../Services/loadingService';
import { WorkPlanMasterModel, WorkPlanOrderModel } from '../../Models/WorkPlanModel';
import { SaleOrderCostingModel, SaleOrderModel, SaleOrderProductionMixingRawMaterialModel, SaleOrderProductionRawMaterialModel } from '../../Models/SalesOrderModel';
import { IssueProductModel } from '../../Models/IssueModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-Costing',
  templateUrl: './Costing.component.html',
  styleUrls: ['./Costing.component.css']
})
export class CostingComponent implements OnInit {
  Type = 'Production';
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SelectedOrderNo: string = '';
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  NewPOList: IssueProductModel[] = [];
  SaleOrder: SaleOrderModel = new SaleOrderModel;
  IsLoaded = false;
  FabricGSM =0;
    CoatingGSM = 0;
    permission = {
        View: false,
        Add: false,
        Delete: false
    }
    constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private loader: LoadingService, private auth: AuthService ) { }
    ngOnInit(): void {
        this.permission.View = this.auth.CheckResponsibility(Modules.Costing, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Edit);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Delete);
        if (this.permission.Add != true) {
            this.router.navigate(['/home/welcome']);
        }
    this.GetAllWorkPlanList();
  }
  GetAllWorkPlanList() {
    if (this.WorkPlanList.length <= 0) {
      
      let url = this.ApiUrl + 'workplan/getallworkplan';
      this.http.get<any>(url).subscribe(
        (res) => {
          this.WorkPlanList = res.Data;
        },
        (res) => { }
      );
    }
  }
  

  OnWorkPlanChange() {
    this.IsLoaded = false;
    this.SelectedOrderNo = '';
    this.loader.show();

    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + this.SelectedWorkPlan.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      this.SelectedWorkPlanOrder = res;
      console.log(res);
      this.loader.hide();
    }, res => { });
  }
  OnWorkPlanOrderChange() {
    this.loader.show();
    this.IsLoaded = false;
    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + this.SelectedOrderNo;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      console.log(res);
      this.SaleOrder = res;
      this.SaleOrder.SaleOrderCosting = new SaleOrderCostingModel;
      this.IsLoaded = true;
      this.loader.hide();
    }, res => { });
  }
  GetQuantityTotal(data:SaleOrderProductionMixingRawMaterialModel[]){
    return data.reduce((sum, current) => sum + current.Quantity, 0);
  } 
  GetScQuantityTotal(data:SaleOrderProductionMixingRawMaterialModel[]){
    return data.reduce((sum, current) => sum + current.Scquantity, 0);
  } 
  GetPriceTotal(data:SaleOrderProductionMixingRawMaterialModel[]){
    return data.reduce((sum, current) => sum + current.Price, 0);
  } 
  GetCostTotal(data:SaleOrderProductionMixingRawMaterialModel[]){
    return data.reduce((sum, current) => sum + current.Price, 0) * data.reduce((sum, current) => sum + current.Quantity, 0);
  } 
  GetGSMTotal(){
    return Number.parseInt(this.FabricGSM.toString()) + Number.parseInt(this.CoatingGSM.toString());
  }

  SaveCosting() {
    this.loader.show();
    let url = this.ApiUrl + "saleorder/addupdatesaleordercosting";
    this.http.post<any>(url, this.SaleOrder).subscribe({
      next: res => {
        this.alertService.success(res);
        
        this.loader.hide();
        
      },
      error: res => { this.alertService.error(res);  this.loader.hide(); },

    });

  }
 
}
