import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../../environments/environment";
import { UserInfo } from "../../../Authentication/UserInfo";
import { Modules, Responsibility } from "../../../Models/Enums";
import { FinalInspectionPrintModel, JumboDispatchModel, JumboInspectionModel } from "../../../Models/WorkPlanModel";
import { AlertMessageService } from "../../../Services/AlertMessageService";
import { AuthService } from "../../../Services/auth.service";
import { LoadingService } from "../../../Services/loadingService";
import { SaleOrderModel } from "src/PmsUIApp/Models/SalesOrderModel";
import moment from "moment";
import { FormulationCodeModel } from "src/PmsUIApp/Models/FormulationCodeModel";
import { CustomerModel } from "src/PmsUIApp/Models/SupplierModel";
import { ColorModel, GrainModel } from "src/PmsUIApp/Models/MasterModel";
import { SoDrawerService } from "src/PmsUIApp/Services/SoDrawerService";



@Component({
    selector: 'app-FinalInspectionList.',
    templateUrl: './FinalInspectionList.component.html'
})


export class FinalInspectionList implements OnInit {
    ApiUrl = environment.Api_Url;
    isSaleOrderVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    isDispatchVisible = false;
    permission = {
        View: false
    }
    request = {
        FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
        ToDate: new Date(new Date().setHours(23, 59, 59)),
        IsInspectionCompleted: "false",
        SaleFormulationCodeId: 0,
        SaleOrderNumber: "",
        GrainId: 0,
        ColorId: 0,
        CustomerId: 0,
        JumboNumber: ""
    }
    count: number;
    JumboInspection: JumboInspectionModel[] = [];
    finalInspectionList = [];
    exportfields: FinalInspectionPrintModel[] = [];
    exportoptions = {
        headers: [
            'JUMBO NUMBER',
            'WORKPLAN NUMBER.',
            'SALES ORDER NUMBER',
            'STATUS',
            'TOTAL ROLL SIZE',
            "CUSTOMER NAME"
        ],
    };
    fields: FinalInspectionPrintModel = new FinalInspectionPrintModel();
    Orderlist!: SaleOrderModel;
    totalItemsCount: number = 0;
    CustomerList: CustomerModel[];
    FormulationCodeList: FormulationCodeModel[];
    ColorList: ColorModel[];
    GrainList: GrainModel[];
    SingleJumboYield: number = 0;
    constructor(private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService,
        private soDrawer: SoDrawerService
    ) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.FinalInspection, Responsibility.View);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.GetFinalInspectionList();
    }
    GetFinalInspectionList() {
        this.isTableLoading = true; let url = this.ApiUrl + "WorkPlan/GetWorkPlanJumboForFinalInspection";
        let count = this.totalItemsCount = 0;
        this.http.post<any>(url, this.request).subscribe(res => {
            console.log(res)
            this.finalInspectionList = res;
            this.exportfields = [];
            count = this.totalItemsCount;
            this.finalInspectionList.forEach((x) => {
                count++;
                x.SerialNo = count;
                this.fields = new FinalInspectionPrintModel();
                this.fields.JUMBONUMBER = x.JumboNo;
                this.fields.WORKPLANNUMBER = x.WorkPlanNo;
                this.fields.SALESORDERNUMBER = x.SaleOrderNumber;
                this.fields.STATUS = x.SaleOrderStatus;
                this.fields.TOTALROLLSIZE = x.JumboRolQty;
                this.fields.CUSTOMERNAME = x.CustomerName;
                this.exportfields.push(this.fields);
            });
            this.isTableLoading = false;
        }, res => {
            this.count++;
            if (this.count < 2) { this.GetFinalInspectionList(); }
        });
    }
    export() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        if (this.exportfields.length > 0)
            new AngularCsv(
                this.exportfields,
                'final-Inspection-export' + exportdate,
                this.exportoptions
            );
    }
    GotoInspection(data: string) {
        this.router.navigate(['/home/production/finalInspection/add/' + data]);
    }
    handleDispatchCancel(): void {
        this.isDispatchVisible = false;
    }
    showDispatchModal(data: JumboDispatchModel): void {
        this.JumboInspection = data.JumboInspection
        let count = this.totalItemsCount = 0;
        this.JumboInspection.forEach((x) => {
            count++;
            x.SerialNo = count;
        });
        this.isDispatchVisible = true;
    }
    Reopen(data: any) {
        this.isTableLoading = true;
        var req = {
            WorkPlanJumboMasterId: data.WorkPlanJumboMasterId,
            SaleOrderId: data.SaleOrderId,
            AddedBy: UserInfo.UserName,
            SaleOrderStatus: data.SaleOrderStatus
        }
        let url = this.ApiUrl + "saleorder/reopenjumboinspection";
        this.http.post<any>(url, req).subscribe(res => {
            console.log(res)
            this.alertService.success(res);
            this.isTableLoading = false;
            this.GetFinalInspectionList();
        }, res => {
            this.count++;
            if (this.count < 2) { this.Reopen(data); }
        });
    }
    handleSave(data: any) {

        const modal = this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Are you sure you want to reopen inspection for Jumbo - ' + data.JumboNo + ' in Sale Order - ' + data.SaleOrderNumber + ' ???',
            nzOnOk: () => this.Reopen(data),
        });

        setTimeout(() => modal.destroy(), 10000);

    }
    enableReOpenButton(data: any) {
        if ((data.SaleOrderStatus == 'MoveToDispatch' || data.SaleOrderStatus == "PartialDispatchReady") && data.IsInspectionCompleted && data.JumboRolQty != this.sumquantity(data))
            return true;
        else if (data.SaleOrderStatus == 'JumboInspection' && this.request.IsInspectionCompleted == "true")
            return true;
        else
            return false;

    }
    sumquantity(data: any) {
        var sum = data.JumboInspection.reduce((accumulator, object) => {
            return accumulator + object.Quantity
        }, 0);
        return parseFloat(sum.toFixed(1))
    }
    GetJumboInspectionStatus(data: any) {
        if (data.IsInspectionCompleted == true)
            return "Completed"
        else
            return "Not Completed"
    }
    OpenViewPop(data: any) {
        this.soDrawer.SaleOrderId = data.SaleOrderId;
        this.soDrawer.show();
    }
    handleCancel(): void {
        this.isSaleOrderVisible = false;
    }
    GetAllCustomer() {
        let url = this.ApiUrl + "customer/getallcustomers";
        this.http.get<CustomerModel[]>(url).subscribe(res => {
            this.CustomerList = res;
        }, res => {
            this.count++
            if (this.count < 2) {
                this.GetAllCustomer()
            }
        });
    }
    GetAllFormulationCode() {
        let url = this.ApiUrl + "saleorder/getallformulationcodes";
        this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
            this.FormulationCodeList = res;
        }, res => {
            this.count++
            if (this.count < 2) {
                this.GetAllFormulationCode()
            }
        });
    }
    GetAllColor() {
        let url = this.ApiUrl + "Color/getallColors";
        this.http.get<ColorModel[]>(url).subscribe(res => {
            this.ColorList = res;
        }, res => {
            this.count++
            if (this.count < 2) {
                this.GetAllColor()
            }
        });
    }
    GetAllGrain() {
        let url = this.ApiUrl + "Grain/getallGrains";
        this.http.get<GrainModel[]>(url).subscribe(res => {
            this.GrainList = res;
        }, res => {
            this.count++
            if (this.count < 2) {
                this.GetAllGrain()
            }
        });
    }
    onFilterPanelOpen(data: any) {
        console.log(data)
        if (data == true) {
            this.GetAllCustomer();
            this.GetAllFormulationCode();
            this.GetAllColor();
            this.GetAllGrain();
        }
    }
    calculateYield() {
        var SingleJumboYield = 0
        var totalQty = this.JumboInspection.reduce((sum, current) => sum + current.Quantity, 0);
        var f = this.JumboInspection.filter(el => el.Grade == '1st').reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        var a = this.JumboInspection.filter(el => el.Grade == 'A').reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        var total = +f + a;
        SingleJumboYield = parseFloat(((total * 100) / totalQty).toFixed(3));
        return SingleJumboYield.toFixed(2);
    }
}


