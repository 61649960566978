<div style="border:1px solid">
  <nz-divider nzText="Formulation Sheet" style="background: #d9d9d9; margin: 0; padding: 10px; "></nz-divider>
  <nz-descriptions *ngIf="Production" nzBordered nzSize="small" [nzColumn]="2">
    <nz-descriptions-item nzTitle="Formulation Code"><b>{{Production.ProcessFormulationCode}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="APPLICATION"><b>{{Production.ProductCategory}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Grain"><b>-</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Printing"> <b>{{Production.Print.Name}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Colour"><b>{{Production.ColorName}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Embossing"><b>{{Production?.Embossing?.Name}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Fabric"><b>{{Production.ProductionRawMaterial[0]?.ProductName}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Tumbling"><b>{{Production?.Tumbling?.Name}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Lacquer"><b>{{Production?.Lacquer?.Name}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Thickness"><b>{{Production.Orders[0].OrderProduct[0].Thick}}</b></nz-descriptions-item>

  </nz-descriptions>
  <nz-divider></nz-divider>
  <div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">



    <div nz-col [nzSpan]="12" *ngFor="let i of Production.Process">


      <div style="padding: 10px 0; text-transform: uppercase; font-size: 17px; text-align: center; color: #fff; background: #415994;">

        {{i.ProductionProcessName}}
      </div>
      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">

        <tbody>
          <tr>

            <td width="50%"><b>Item Name</b></td>
            <td><b>QTY</b></td>
          </tr>
          <tr *ngFor="let data of i.ProductionProcessRawMaterial;let i=index">

            <td width="50%">{{ data.ProductName }}</td>
            <td style="text-align:right">{{ data.Quantity }}</td>
          </tr>

        </tbody>
        <tfoot style="background: #d9d9d9;">
          <tr>

            <td width="50%"><b> WT. in GSM </b></td>
            <td style="text-align:right"><b>{{i.WeightGsm}}</b></td>
          </tr>
        </tfoot>
      </nz-table>

    </div>



  </div>
  <nz-divider></nz-divider>
  <div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">



    <div nz-col [nzSpan]="12" *ngFor="let i of Production.Process">



      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">

        <tbody>

          <tr>

            <td width="50%">{{ i.ProductionProcessName }}</td>
            <td style="text-align:right">{{ i.WeightGsm }}</td>
          </tr>

        </tbody>

      </nz-table>

    </div>



  </div>
  <div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">
    <div nz-col [nzSpan]="12">
      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">

        <tbody>

          <tr>

            <td><b>Final Wt(GSM)</b></td>
            <td style="text-align:right"><b>{{Production.FinalProcessGSMTotal}}</b></td>
          </tr>

        </tbody>

      </nz-table>
    </div>

  </div>


</div>
