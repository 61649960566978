<div *ngIf="IsPopupOpen | async">
    <nz-drawer [nzClosable]="true" [nzVisible]="isTimelineVisible" nzPlacement="right" [nzWidth]="1000"
        nzTitle="Purchase Order Timeline" (nzOnClose)="handleTimelineCancel()">
        <ng-container *nzDrawerContent>
            <div>
                <div style="margin-top: 5px;">
                    <button nz-button [nzLoading]="refreshBtnLoading" nzType="primary"
                        (click)="GetTimelineRefresh(TimeLineObject.Poid)">Refresh</button>
                </div>
                <nz-divider nzText="Time Line"></nz-divider>
                <nz-steps nzDirection="vertical">
                    <nz-step *ngFor="let activity of ActivityLogList" nzTitle="{{ activity.Status }}"
                        [nzStatus]='GetTimeLineStatus(activity.Status)'
                        [nzDescription]="getDescriptionTemplate(activity)"
                        [nzSubtitle]="GetTimeLineSubtitle(activity)"></nz-step>
                </nz-steps>
            </div>
        </ng-container>
    </nz-drawer>
</div>

<ng-template #descriptionTemplate let-activity>
    <div *ngIf="this.descriptionActivity?.Remark && this.descriptionActivity.Remark.trim().length > 0; else onlyAddedBy">
      <p [title]="this.descriptionActivity?.AddedByUsername">By: {{ this.descriptionActivity?.AddedBy }}</p>
      <p style="color: black;">Remark: {{ this.descriptionActivity?.Remark }}</p>
    </div>
    <ng-template #onlyAddedBy>
      <p [title]="this.descriptionActivity?.AddedByUsername">By: {{ this.descriptionActivity?.AddedBy }}</p>
    </ng-template>
  </ng-template>