<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
  
      <nz-page-header [nzGhost]="false">
        <nz-page-header-title>Tumbling List</nz-page-header-title>
        <nz-page-header-subtitle>Manage your Tumbling here</nz-page-header-subtitle>
        <nz-page-header-extra>
  
          <button nz-button nzType="primary" (click)="showModal()" *ngIf="this.permission.Add">Add New</button>
        </nz-page-header-extra>
  
      </nz-page-header>
      <nz-divider></nz-divider>
      <div style="padding: 8px; float: right">
        <input
          type="text"
          style="width: 250px; margin-right: 8px"
          nz-input
          placeholder="Search name / code"
          [(ngModel)]="searchValue"
          (keydown)="onKeydown($event)"
          (ngModelChange)="ValidateText()"
        />
        <button
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="search()"
          style="margin-right: 8px"
        >
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
      </div>
      <nz-table nzSize="small" [nzPageSize]="100"style="width: 100%;"  [nzScroll]="{ x: '1200px',y:'515px' }" #basicTable [nzData]="this.TumblingList" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr>
            <th nzWidth="150px" nzLeft> Name</th>
            <th nzWidth="150px">Code</th>
            <th nzWidth="400px">Description</th>
            <th nzWidth="160px" style="text-align:center" nzRight>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td nzLeft>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
            
              
  
  
            <td nzRight style="text-align:center"> 
              <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
              <button class="btn btn-sm btn-light-danger">Delete</button>
  
            </td>
          </tr>
        </tbody>
      </nz-table>
  
  
      <nz-modal [nzWidth]="800"
                [nzStyle]="{ top: '20px' }"
                [(nzVisible)]="isVisible"
                [nzTitle]="modalTitle"
                [nzContent]="modalContent"
                [nzFooter]=null
                (nzOnCancel)="handleCancel()">
        <ng-template #modalTitle>{{PopUpTitle}}</ng-template>
  
        <ng-template #modalContent>
          <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
            <nz-form-item>
  
              <nz-form-label [nzSpan]="8" nzRequired>Name</nz-form-label>
              <nz-form-control [nzSpan]="14" [nzErrorTip]="TumblingNameError">
                <input nz-input formControlName="TumblingName" name="TumblingName" />
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Code</nz-form-label>
              <nz-form-control [nzSpan]="14" [nzErrorTip]="TumblingCodeError">
                <input nz-input formControlName="Code" name="Code" />
              </nz-form-control>
            </nz-form-item>     
            
            <nz-form-item>
              <nz-form-label [nzSpan]="8">Description</nz-form-label>
              <nz-form-control [nzSpan]="14"  nzErrorTip="Description cannot be more than 200 characters">
                <textarea nz-input formControlName="Description" name="Description"></textarea>
              </nz-form-control>
            </nz-form-item>
             <div class="text-center">
              <button nz-button nzType="primary" [nzLoading]="isLoading">Save </button>
            </div>
  
          </form>
        </ng-template>
      </nz-modal>
  
    </div>
  </div>
  
  