<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Pending Sale Orders List</nz-page-header-title>

      <nz-page-header-extra>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Customer</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                    [(ngModel)]="request.CustomerId" (ngModelChange)="onFilterChange()" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                      [nzLabel]="s.CustomerName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Formulation Code</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" (ngModelChange)="onFilterChange()"
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                      [nzLabel]="s.SaleFormulationCode"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                  <label>Article Name</label>
                  <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30" [(ngModel)]="request.ArticleName"
                    (ngModelChange)="onFilterChange()" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Color</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                    (ngModelChange)="onFilterChange()" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                      [nzLabel]="s.ColorName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Grain Code</label>
                  <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" (ngModelChange)="onFilterChange()"
                    nzSize="default" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                      [nzLabel]="s.GrainCode"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Thickness</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ThicknessId"
                    (ngModelChange)="onFilterChange()" nzAllowClear nzShowSearch nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                      [nzLabel]="s.ThicknessNumber"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Sale Order No.</label>
                  <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
                    [(ngModel)]="request.SaleOrderNumber" (ngModelChange)="onFilterChange()"
                    (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Date Type</label>
                  <nz-select name="select-error" [(ngModel)]="request.DateType" (ngModelChange)="onFilterChange()">
                    <nz-option *ngFor="let data of dateTypeList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='inspectiondate'">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Pre-Inspection Date (From) </label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromInspectionDate" (ngModelChange)="onFilterChange()"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='inspectiondate'">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Pre-Inspection Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToInspectionDate" (ngModelChange)="onFilterChange()"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='productiondate'">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Production Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromProductionDate" (ngModelChange)="onFilterChange()"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='productiondate'">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Production Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToProductionDate" (ngModelChange)="onFilterChange()"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Product Type</label>
                  <nz-select [(ngModel)]="request.ProductType" (ngModelChange)="onFilterChange()" nzAllowClear
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of PUPVCTypeList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" style="margin-top: 20px;">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label nz-checkbox [(ngModel)]="this.request.ShowConsumedOrders"
                    (ngModelChange)="onFilterChange()">Show Only Consumed Orders</label>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                (click)="GetPendingConsumptionOrders()">
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>

    <nz-table *ngIf="this.ConsumptionPendingList.length>0" [nzPageSize]="100" nzSize="small"
      [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable [nzData]="this.ConsumptionPendingList"
      [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger [nzPageSize]="20"
      [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="60px">S. No.</th>
          <th nzWidth="130px">Sale Order No.</th>
          <th nzWidth="140px">WorkPlan No.</th>
          <th nzWidth="90px">Order QTY</th>
          <th nzWidth="90px">MFD QTY</th>
          <th nzWidth="120px">Customer Name</th>
          <th nzWidth="140px">Formulation Code</th>
          <th nzWidth="110px">Grain Code</th>
          <th nzWidth="110px">Color Name</th>
          <th nzWidth="100px">Thickness</th>
          <th nzWidth="130px">Article Name</th>
          <th nzWidth="150px">Pre-Inspection Completed Date</th>
          <th nzWidth="150px">Pre-Inspection Completed By</th>
          <th nzWidth="130px">Pre-Inspection Store</th>
          <th nzWidth="150px">Production Completed Date</th>
          <th nzWidth="150px">Production Completed By</th>
          <th nzWidth="150px" nzRight>Consume</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">

          <td>{{ data.SerialNo }}</td>
          <td>{{ data.SaleOrderNo }}</td>
          <td>{{ data.WorkPlanNo }}</td>
          <td>{{ data.OrderQty }}</td>
          <td>{{ data.MFDQTY }}</td>
          <td>{{ data.CustomerName }}</td>
          <td>{{ data.FormulationCode }}</td>
          <td>{{ data.GrainCode }}</td>
          <td>{{ data.ColorName }}</td>
          <td>{{ data.Thickness }}</td>
          <td>{{ data.ArticleName }}</td>
          <td>{{ data.PreInspectionCompletedDate | DatetimeConverter }}</td>
          <td>{{ data.PreInspectionCompletedBy }}</td>
          <td>{{ data.PreInspectionStore }}</td>
          <td>{{ data.ProductionCompletedDate | DatetimeConverter }}</td>
          <td>{{ data.ProductionCompletedBy }}</td>
          <td nzRight>
            <nz-tag [nzColor]="'cyan'"><a class="btn-link" [href]="getConsumptionPath(data)" target="_blank"
                (click)="GotoConsumption($event,data)">
                Add Consumption
              </a></nz-tag>
          </td>
        </tr>
      </tbody>
    </nz-table>




  </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>