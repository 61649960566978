<div class="card card-flush h-xl-100" *ngIf="IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Manage General Configuration</nz-page-header-title>

      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <!--<div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>-->
    <nz-table nzSize="small" [nzData]="['']" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable1
      nzBordered>
      <thead>
        <tr>

          <th nzWidth="50px">

          </th>
          <th nzWidth="250px" nzLeft>
              Config Item

          </th>
          <th nzWidth="150px" style="text-align:center">ConfigValue</th>
          <th nzWidth="220px" nzRight style="text-align:center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ConfigurationList;let i=index">
          <td nzWidth="20px">
            {{i+1}}
          </td>
          <td nzLeft>{{data.ConfigItem}}</td>
          <td>{{data.ConfigValue}}</td>
          <td nzRight style="text-align:center">
            <button nz-button nzSize="small" nzType="primary" style="border-radius: 4px;font-weight: 600;"
              (click)="OpenEditPop(data)">Edit</button>
          </td>
        </tr>

      </tbody>

    </nz-table>



  </div>
</div>
<div class="card card-flush h-xl-100" *ngIf="!IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>You are not authorized</nz-page-header-title>



    </nz-page-header>
  </div>
</div>

<nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
          [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>{{isNew==true?'Add':'Modify'}} Config</ng-template>

    <ng-template #modalContent>

        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24">

                <div class="row gx-10 mb-5">
                    <div class="col-lg-12">
                        <label class="required form-label">Config Item</label>
                        <input nz-input nzSize="large" [disabled]="!isNew" [(ngModel)]="Configuration.ConfigItem" name="ConfigItem">
                    </div>

                </div>
                <div class="row gx-10 mb-5">
                    <div class="col-lg-12">
                        <label class="required form-label">Config Value</label>
                        <input nz-input nzSize="large" [(ngModel)]="Configuration.ConfigValue" name="ConfigValue">
                    </div>

                </div>

               
            </div>

        </div>

    </ng-template>
    <ng-template #modalFooter>
        <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="Save()">Save</button>
        </div>
    </ng-template>
</nz-modal>