<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Store List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Store here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzScroll]="{ x: '1500px', y:'515px' }" #basicTable
      [nzData]="this.StoreList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzLeft nzWidth="120px"> Store Name</th>
          <th nzWidth="120px">Store Code</th>
          <th nzWidth="120px">Work In Progress Store</th>
          <th nzWidth="150px">Store Desc</th>
          <th>Branch</th>
          <th>Department</th>
          <th nzWidth="120px">AddedDate</th>
          <th>AddedBy</th>

          <th nzRight nzWidth="170px" *ngIf="permission.Add || permission.Delete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft>{{ data.StoreName }}</td>
          <td>{{ data.StoreCode }}</td>
          <td *ngIf="data.IsWorkInProgressStore ==true">Yes</td>
          <td *ngIf="data.IsWorkInProgressStore ==false">No</td>
          <td>{{ data.StoreDesc }}</td>
          <td>{{ data.BranchName }}</td>
          <td>{{ data.DeptCode }}</td>
          <td>{{ data.StoreAddedDate | DatetimeConverter }}</td>
          <td>{{ data.StoreAddedBy }}</td>



          <td *ngIf="CheckUserAddPermission(data.StoreAddedBy )" nzRight>
            <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>

          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div style="padding: 8px;">
          <input type="text" style="width: 188px;margin-bottom: 8px;display: block;" nz-input placeholder="Search name"
            [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()"
            style="margin-right: 8px;">Search</button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>

    <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8" nzRequired>Store Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="NameError">
              <input nz-input formControlName="StoreName" name="StoreName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Store Code </nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="CodeError">
              <input nz-input formControlName="StoreCode" name="StoreCode" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Select Work In Progress Store</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select if this store is Work In Progress Store">
              <input nz-input type="checkbox" formControlName="IsWorkInProgressStore" name="IsWorkInProgressStore" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Branch</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Branch">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select mb-2" nzShowSearch formControlName="BranchId" name="BranchId"
                nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.BranchList;" [nzValue]="s.BranchId"
                  [nzLabel]="s.BranchName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Department</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Department">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select mb-2" nzShowSearch formControlName="DeptId" name="DeptId" nzSize="default"
                nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.DeptList;" [nzValue]="s.DeptId" [nzLabel]="s.DeptName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Description</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Description cannot be more than 200 characters">
              <textarea rows="4" nz-input formControlName="StoreDesc" name="StoreDesc"></textarea>
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save Store</button>
          </div>

        </form>
      </ng-template>
    </nz-modal>

  </div>
</div>