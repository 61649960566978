
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzTimelineItemComponent } from 'ng-zorro-antd/timeline';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { environment } from '../../../environments/environment';
import { Modules, Responsibility } from '../../Models/Enums';
import { ProductModel } from '../../Models/ProductModel';
import { SearchParamsProductStockReportModel } from '../../Models/ReportModel';
import { StockProductModel } from '../../Models/StockProductModel';
import { SupplierModel } from '../../Models/SupplierModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';
import { LoadingService } from '../../Services/loadingService';

@Component({
  selector: 'app-ProductStockHistory',
  templateUrl: './ProductStockHistory.component.html'
})

export class ProductStockHistoryReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  searchValue = '';
  isTableLoading: boolean = false;
  ReportList: StockProductModel[] = [];
  request: SearchParamsProductStockReportModel = new SearchParamsProductStockReportModel;
  isVisible = false;
  //exportfields: PurchaseOrderPrintModel[] = [];
  //exportoptions = {
  //  headers: [
  //    'Supplier Name',
  //    'PO Number',
  //    'Delivery Term',
  //    'Payment Term',
  //    'Product Name',
  //    "Currency",
  //    'PO Total Amount',
  //    'PO Date',

  //    'GRN',
  //    'Reference',
  //    'Added Date',
  //    'Added By',
  //    "Status"
  //  ],
  //};
  //fields: PurchaseOrderPrintModel = new PurchaseOrderPrintModel();
  timelineItem: timelineItem = new timelineItem
  timeline: timelineItem[] = [];
  Summary: timelineItem[] = [];
  showsearch: boolean = false;
  ProductList: ProductModel[] = [];
  SupplierList: SupplierModel[] = [];
  IsMsgShow = false;
  ClosingStock: string = '';
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private auth: AuthService, private alertService: AlertMessageService, private router: Router, public http: HttpClient, private loader: LoadingService) {
  }
  GetAllProducts() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  GetAllSuppliers() {
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllSuppliers(); }
    });
  }
  GetReport() {
    this.IsMsgShow = false;
    //this.request.ProductId = 35253;
    if (this.request.ProductId <= 0) {
      this.alertService.error("Select Product");
      return;
    }
    if (isNaN(this.request.SupplierId) || this.request.SupplierId == null) {
      this.request.SupplierId = 0;
    }
    let url = this.ApiUrl + 'stock/GetProductWisestockWithSupplierWithConsumption';
    this.http.post<any>(url, this.request).subscribe({

      next: res => {
        if (res == 'An error has occured. Please contact administrator') {
          this.alertService.error(res);
          return
        }
        this.ReportList = res;
        if (this.ReportList.length <= 0) {
          this.IsMsgShow = true;
        }
        this.timeline = [];

        this.ReportList.forEach(x => {
          x.StockProductAllocation.filter(z => z.InspectionType == "Accepted").forEach(y => {
            this.timelineItem = new timelineItem;
            this.timelineItem.Type = "Allocation";
            this.timelineItem.Date = new Date(x.ReceivedDate);
            this.timelineItem.QTY = y.Quantity.toFixed(3);
            this.timelineItem.IsReissued = x.IsStockReissued;
            this.timelineItem.RejectedQTY = 0;// x.RejectedQuantity;
            if (!x.IsStockReissued) {
              this.timelineItem.Description = "Stock Received / Batch: " + x.Batch + " / Barcode: " + x.Barcode + " / In Store: " + y.StoreName;
            }
            else {
              this.timelineItem.Description = "Stock Transferred / Batch: " + x.Batch + " / Barcode: " + x.Barcode + " / To Store: " + y.StoreName;
            }

            this.timelineItem.Barcode = x.Barcode;
            this.timelineItem.Batch = x.Batch;
            this.timelineItem.Store = y.StoreName;
            this.timelineItem.Rack = y.RackName;
            this.timelineItem.Rate = x.PricePerUnit;
            if (parseInt(this.timelineItem.QTY) > 0) {
              this.timeline.push(this.timelineItem);
            }
          })
          x.ConsumeStockProduct.forEach(y => {
            this.timelineItem = new timelineItem;
            this.timelineItem.Type = "Consumption";
            this.timelineItem.Date = new Date(y.ConsumedDate);
            this.timelineItem.QTY = parseFloat(y.Quantity).toFixed(3);
            this.timelineItem.Description = "Consumed / Batch: " + x.Batch + " / Barcode: " + x.Barcode + " / In Store: " + y.StoreName + " / By: " + y.AddedBy;
            this.timelineItem.Barcode = x.Barcode;
            this.timelineItem.Batch = x.Batch;
            this.timelineItem.Store = y.StoreName;
            this.timelineItem.Rack = y.RackName;
            this.timelineItem.Rate = x.PricePerUnit;
            this.timeline.push(this.timelineItem);
          })
        })
        const sortedAsc = this.timeline.sort(
          (objA, objB) => objA.Date.getTime() - objB.Date.getTime(),
        );
        this.timeline.forEach((x, i) => {

          if (i == 0) {
            if (x.RejectedQTY > 0) {
              x.RemainingQTY = (parseInt(x.QTY) - x.RejectedQTY).toFixed(2);
            }
            else {
              x.RemainingQTY = x.QTY
            }
          } else {
            if (x.Type == "Allocation") {
              if (x.IsReissued) {
                x.RemainingQTY = (parseInt(this.timeline[i - 1].RemainingQTY)).toFixed(2)
              }
              else {
                if (x.RejectedQTY > 0) {
                  x.RemainingQTY = ((parseInt(x.QTY) + +parseInt(this.timeline[i - 1].RemainingQTY)) - x.RejectedQTY).toFixed(2)
                }
                else {
                  x.RemainingQTY = (parseInt(x.QTY) + +parseInt(this.timeline[i - 1].RemainingQTY)).toFixed(2)
                }
              }
            }
            if (x.Type == "Consumption") {
              x.RemainingQTY = (parseInt(this.timeline[i - 1].RemainingQTY) - parseInt(x.QTY)).toString();
            }
          }
          //console.log(this.timeline[i - 1]?.QTY)
        })

        this.ClosingStock = parseFloat((this.timeline[this.timeline.length - 1]?.RemainingQTY)?.toString()).toFixed(3);
        this.Summary = [];
        this.ReportList.filter(x => x.Quantity != 0).forEach((x, i) => {
          let uniqueRack = [...new Set(x.StockProductAllocation.filter(x => x.InspectionType == 'Accepted').map((item) => item.RackId))];
          uniqueRack.forEach(itm => {
            this.timelineItem = new timelineItem;

            if (x.ConsumeStockProduct.length > 0) {
              this.timelineItem.Date = new Date(x.ConsumeStockProduct[x.ConsumeStockProduct.length - 1]?.ConsumedDate);
            }
            else {
              this.timelineItem.Date = new Date(x.ReceivedDate);
            }

            var cqty = x.ConsumeStockProduct.reduce((sum, current) => sum + + parseFloat(current.Quantity?.toString()), 0) ?? 0
            if (x.Quantity < parseFloat(x.RecievedQuantity)) {
              var qtysum = x.StockProductAllocation.filter(x => x.RackId == itm).filter(x => x.InspectionType == "Accepted").reduce((sum, current) => sum + + current.Quantity, 0).toString();
              this.timelineItem.RemainingQTY = qtysum;
            }
            else {
              if (x.IsStockReissued) {
                var qtysum2 = x.StockProductAllocation.filter(x => x.RackId == itm).filter(x => x.InspectionType == "Accepted").reduce((sum, current) => sum + + current.Quantity, 0);
                this.timelineItem.RemainingQTY = parseFloat((qtysum2 - parseInt(cqty.toString())).toString()).toFixed(2);
              }
              else {
                var qtysum = x.StockProductAllocation.filter(x => x.RackId == itm).filter(x => x.InspectionType == "Accepted").reduce((sum, current) => sum + + current.Quantity, 0).toString();
                this.timelineItem.RemainingQTY = parseFloat((parseInt(qtysum) - parseInt(cqty.toString())).toString()).toFixed(2);
              }
            }

            this.timelineItem.Barcode = x.Barcode;
            this.timelineItem.Batch = x.Batch;
            this.timelineItem.Store = x.StockProductAllocation.filter(x => x.RackId == itm)[0].StoreName;
            this.timelineItem.Rack = x.StockProductAllocation.filter(x => x.RackId == itm)[0].RackName;
            this.timelineItem.Rate = x.PricePerUnit;
            this.Summary.push(this.timelineItem);
          })
        })

        //this.alertService.success("Product Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllProducts();
      },
      error: res => {
        //this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false;
      },

    });
    //this.http.post<StockProductModel[]>(url, this.request).subscribe(
    //  (res) => {
    //    //this.ReportList = res;
    //    console.log(res)
    //  },
    //  (res) => { }
    //);
  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsProductStockHistory, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsProductStockHistory, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsProductStockHistory, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProducts();
    this.GetAllSuppliers()

  }
  reset(): void {
    //this.searchValue = '';
    this.request = new SearchParamsProductStockReportModel;
    this.IsMsgShow = false;
  }

  export() {
    //if (this.exportfields.length > 0)
    //  new AngularCsv(
    //    this.exportfields,
    //    'stock-report-export',
    //    this.exportoptions
    //  );
  }



  search() {

    this.GetReport()

  }
  showSearchSection(): void {
    this.showsearch = !this.showsearch;
  }

  onDdlChange() {
    this.ReportList = [];
    this.timeline = [];
    this.Summary = [];
  }

  OpenViewPop(data: StockProductModel) {
    let url = this.ApiUrl + 'stock/GetstockById/' + data.StockId;
    this.http.get<any>(url).subscribe(
      (res) => {

        console.log(res)


      },
      (res) => { }
    );
    let url1 = this.ApiUrl + 'consume/GetConsumptionbyStockProductId/' + data.StockProductId;
    this.http.get<any>(url1).subscribe(
      (res) => {

        console.log(res)

        this.isVisible = true;
      },
      (res) => { }
    );

  }
  handleCancel(): void {
    this.isVisible = false;
  }
}


export class timelineItem {
  "Type": string = '';
  "Date"!: Date;
  "QTY": string = '';
  "Description": string = '';
  "RemainingQTY": string = '';
  "RejectedQTY": number = 0;
  "Batch": string = '';
  "Barcode": string = '';
  "Store": string = '';
  "Rack": string = '';
  "Rate": number = 0;
  "IsReissued": boolean = false;
}
