<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Issue</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="OpenDemand()">Add Demand</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">


      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-control [nzSpan]="22" nzErrorTip="Enter Element">
            <label class="required"><b>Select Type</b></label>
            <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="Type" (ngModelChange)="OnTypeDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
              <nz-option nzValue="" nzLabel="Select"></nz-option>
              <nz-option nzValue="Production" nzLabel="Workplan"></nz-option>
              <nz-option *ngIf="IsAuth" nzValue="Store" nzLabel="Store to Store"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="this.Type=='Production'">

          <nz-form-control [nzSpan]="22" nzErrorTip="Enter Element">
            <label class="required"><b>Select Workplan</b></label>
            <!--<nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="Production" (ngModelChange)="OnProductionDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
        <nz-option *ngFor="let s of this.ProductionList;" [nzValue]="s" [nzLabel]="s.ManufacturingProductName"></nz-option>
      </nz-select>-->
            <nz-select class="form-select" nzShowSearch name="WorkPlanNo" [(ngModel)]="SelectedWorkPlan" nzSize="default" [ngModelOptions]="{standalone: true}"  nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanChange()">
              <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">

          <nz-form-control [nzSpan]="22" nzErrorTip="Enter Element" *ngIf="this.Type=='Production'">
            <label class="required"><b>Select Order</b></label>
            <!--<nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="Production" (ngModelChange)="OnProductionDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
        <nz-option *ngFor="let s of this.ProductionList;" [nzValue]="s" [nzLabel]="s.ManufacturingProductName"></nz-option>
      </nz-select>-->
            <nz-select class="form-select" nzShowSearch name="SelectedWorkPlanOrder" [(ngModel)]="SelectedOrderNo" [ngModelOptions]="{standalone: true}" nzSize="default"  nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanOrderChange()">
              <nz-option *ngFor="let s of SelectedWorkPlanOrder;" [nzValue]="s.SaleOrder.SaleOrderId" [nzLabel]="s.SaleOrder.SaleOrderNumber"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="this.Type!=''">
          <nz-form-control [nzSpan]="22" nzErrorTip="Enter Element">
            <label class="required"><b>My/To Store</b> </label>

            <nz-select class="form-select mb-2" nzShowSearch name="FromStoreID" [(ngModel)]="FromStoreID" [ngModelOptions]="{standalone: true}" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnStoreChange()">
              <nz-option *ngFor="let s of this.AdminStoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

       
      </div>
      

      
      <div>
        <div nz-col [nzSpan]="12">

        </div>
        <nz-divider></nz-divider>


        <div  style="margin:10px">
          <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
            <div nz-col [nzSpan]="24" style="background: #f0f2f5; border-radius: 4px; ">
              <nz-divider nzText="Raw Material"></nz-divider>

              <div class="mb-10 fv-row" *ngIf="this.Type=='Store'">
                  <div class="row gx-10 mb-5">
                      <div class="col-lg-4">
                          <label class=" form-label">Type</label>

                          <nz-select class="form-select mb-2" [(ngModel)]="SelectedProductType" (ngModelChange)="onSelectedProductTypeChange()" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose">
                              <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                              <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                              <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                          </nz-select>
                      </div>

                      <div class="col-lg-4">
                          <label class=" form-label">Category</label>

                          <nz-select #microwaveRef class="form-select " nzShowSearch [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category" [ngModelOptions]="{standalone: true}" (ngModelChange)="GetAllFirstCategory($event)">
                              <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                          </nz-select>
                      </div>

                      <div class="col-lg-4">
                          <label class="required form-label">Product Name</label>
                          <nz-select nzShowSearch class="form-select " name="ProductId" [(ngModel)]="selectedproductId" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnProductDdlchange()">
                              <nz-option *ngFor="let s of this.NewPOList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-2">
                          <label class="form-label">Unit</label>
                          <label nzSize="default" class="form-control " style="padding:5px">{{SelectedUnit}}</label>
                      </div>
                      <div class="col-lg-4">
                          <label class="required form-label">Supplier</label>
                          <nz-select [disabled]="IsSupplier" class="form-select " name="SupplierList" [(ngModel)]="SelectedSupplier" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnSupplierDdlchange()">
                              <nz-option *ngFor="let s of this.FilteredSupplierList;" [nzValue]="s.SupplierId" [nzLabel]="s.SupplierName"></nz-option>
                          </nz-select>
                      </div>

                      <div class="col-lg-4">
                          <label class="required form-label">Batch </label>
                          <nz-select [disabled]="IsBatch" nzShowSearch class="form-select " name="BatchList" [(ngModel)]="SelectedBatch" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnBatchDdlchange()">
                              <nz-option *ngFor="let s of this.BatchList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-4">
                          <label class="required form-label">Barcode</label>
                          <nz-select nzShowSearch [disabled]="IsBarcode" class="form-select " name="ProductId" [(ngModel)]="SelectedBarcode" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnBarCodeDdlchange()">
                              <nz-option *ngFor="let s of this.BarcodeList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-4">
                          <label class="required form-label">From Store</label>
                          <nz-select nzShowSearch [disabled]="IsStore" class="form-select " name="ProductId" [(ngModel)]="SelectedStore" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnStoreDdlchange()">
                              <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-4">
                          <label class="required form-label">Rack</label>
                          <nz-select nzShowSearch [disabled]="IsRack" class="form-select " name="ProductId" [(ngModel)]="SelectedRack" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnRackDdlchange()">
                              <nz-option *ngFor="let s of this.SelectedRawDetails;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-4">
                          <label class="required form-label">Stock</label>
                          <input type="text" name="QTY" nz-input [disabled]="true" style="background: #fff;color: #000;" [(ngModel)]="StockQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-lg-4">
                          <label class="required form-label">QTY</label>
                          <input type="number" [disabled]="IsQuantity" name="QTY" nz-input [(ngModel)]="SelectedQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-lg-7">
                          <label class="required form-label">Remark</label>
                          <input type="text" nz-input nzSize="default" [(ngModel)]="SelectedRemark" [ngModelOptions]="{standalone: true}" class="form-control " />
                      </div>
                      <div class="col-lg-1">
                          <a id="kt_ecommerce_add_product_submit" (click)="AddNewProductRecord()" class="btn btn-success btn-sm" style="margin-top: 30px;text-transform: uppercase;">
                              <span class="indicator-label">Add</span>
                              <span class="indicator-progress">
                                  Please wait...
                                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                          </a>
                      </div>
                  </div>
                <nz-table nzSize="small" [nzData]="['']" #basicTable2 nzBordered *ngIf="this.SelectedRawList && this.Type=='Store'" class="tbl">
                  <thead>
                    <tr>

                      <th nzWidth="50px"></th>
                      <th >Product</th>
                      <th nzWidth="100px">Supplier</th>
                      <th nzWidth="100px">Batch No.</th>
                      <th nzWidth="100px">Store</th>
                      <th nzWidth="100px">Stock</th>
                      <th nzWidth="70px">QTY</th>
                      <th nzWidth="70px">Rate</th>
                      <th nzWidth="100px">Amount</th>
                      <th nzWidth="50px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of SelectedRawList;let i=index">

                      <td nzWidth="20px">
                        {{i+1}}

                      </td>
                      <td>{{ data.ProductName }}</td>
                      <td>{{ data.SupplierName }}</td>
                      <td>{{ data.Batch }}</td>
                      <td>{{ data.StoreName }}</td>
                      <td>{{ data.StockQuantity }}</td>
                      <td>{{ data.Quantity }}</td>
                      <td>{{ data.PerUnitPrice }}</td>
                      <td>{{ CalculateAmount(data) }}</td>
                      <td>
                        <a class="btn btn-sm btn-light-danger" (click)="RemoveStockProduct(data)">Remove</a>
                      </td>

                    </tr>

                  </tbody>

                </nz-table>

              </div>


              <!--<nz-table nzSize="small" [nzData]="['']" #basicTable2 nzBordered *ngIf="this.StockList && this.Type=='Production'" class="tbl">
    <thead>
      <tr>

        <th nzWidth="50px">

        </th>
        <th>
          Product

        </th>
        <th nzWidth="100px">Req</th>

        <th nzWidth="100px">QTY</th>

        <th nzWidth="100px">Available</th>

        <th nzWidth="100px">QTY</th>
        <th nzWidth="200px">From Store</th>
        <th nzWidth="100px">Issue QTY</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of StockList;let i=index">

        <td nzWidth="20px">
          {{i+1}}

        </td>
        <td>

          {{ data.ProductName }}
        </td>

        <td>{{ data.Quantity }}</td>
        <td> <input nz-input value="data.AcceptQTY" [(ngModel)]="data.AcceptQTY" type="number" [ngModelOptions]="{standalone: true}" (ngModelChange)="CheckQty(data)" /></td>
        <td>{{ data.AvailableQTY }}</td>
        <td colspan="3">
          <table class="tbl" nzBordered nzSize="small">
            <tr *ngFor="let s of data.ProductStock;let i=index">
              <td width="100px">{{s.Quantity}}</td>
              <td> <label nz-checkbox [(ngModel)]="s.IsChecked" [ngModelOptions]="{standalone: true}">{{s.StoreName}}</label></td>
              <td width="100px"> <input *ngIf="s.IsChecked" nz-input type="number" [(ngModel)]="s.AcceptQTY" (ngModelChange)="CheckStoreQTY(data,s)" [ngModelOptions]="{standalone: true}" /></td>
            </tr>
          </table>

        </td>
      </tr>

    </tbody>

  </nz-table>-->
              <div class="mb-10 fv-row" *ngIf="this.Type=='Production' && SelectedOrderNo!=''">
                  <div class="row gx-10 mb-5">
                      <div class="col-lg-3">
                          <label class="required form-label">Raw Materials Category</label>
                          <nz-select nzShowSearch class="form-select " name="SelectedRawMaterialCategory" [(ngModel)]="SelectedRawMaterialCategory" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnRawMaterialCategoryDdlchange()">
                              <nz-option *ngFor="let s of this.NewRawMaterialCategoryList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                          </nz-select>
                      </div>

                      <div class="col-lg-3">
                          <label class="required form-label">Product Name</label>
                          <nz-select nzShowSearch class="form-select " name="ProductId" [(ngModel)]="selectedproductId" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnProductDdlchange()">
                              <nz-option *ngFor="let s of this.NewPOList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-2">
                          <label class="form-label">Unit</label>
                          <label nzSize="default" class="form-control " style="padding:5px">{{SelectedUnit}}</label>
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">Required QTY</label>
                          <input type="text" name="ReqQTY" nz-input [disabled]="true" style="background: #fff;color: #000;" [(ngModel)]="ReqQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">Supplier</label>
                          <nz-select [disabled]="IsSupplier" class="form-select " name="SupplierList" [(ngModel)]="SelectedSupplier" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnSupplierDdlchange()">
                              <nz-option *ngFor="let s of this.FilteredSupplierList;" [nzValue]="s.SupplierId" [nzLabel]="s.SupplierName"></nz-option>
                          </nz-select>
                      </div>

                      <div class="col-lg-2">
                          <label class="required form-label">Batch </label>
                          <nz-select [disabled]="IsBatch" nzShowSearch class="form-select " name="BatchList" [(ngModel)]="SelectedBatch" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnBatchDdlchange()">
                              <nz-option *ngFor="let s of this.BatchList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">Barcode</label>
                          <nz-select nzShowSearch [disabled]="IsBarcode" class="form-select " name="ProductId" [(ngModel)]="SelectedBarcode" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnBarCodeDdlchange()">
                              <nz-option *ngFor="let s of this.BarcodeList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">Store</label>
                          <nz-select nzShowSearch [disabled]="IsStore" class="form-select " name="ProductId" [(ngModel)]="SelectedStore" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnStoreDdlchange()">
                              <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">Rack</label>
                          <nz-select nzShowSearch [disabled]="IsRack" class="form-select " name="ProductId" [(ngModel)]="SelectedRack" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnRackDdlchange()">
                              <nz-option *ngFor="let s of this.SelectedRawDetails;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                          </nz-select>
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">Stock</label>
                          <input type="text" name="QTY" nz-input [disabled]="true" style="background: #fff;color: #000;" [(ngModel)]="StockQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-lg-2">
                          <label class="required form-label">QTY</label>
                          <input type="number" [disabled]="IsQuantity" name="QTY" nz-input [(ngModel)]="SelectedQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-lg-1">
                          <a id="kt_ecommerce_add_product_submit" (click)="AddNewProductRecord()" class="btn btn-success btn-sm" style="margin-top: 30px;text-transform: uppercase;">
                              <span class="indicator-label">Add</span>
                              <span class="indicator-progress">
                                  Please wait...
                                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                          </a>
                      </div>
                  </div>
                <nz-table nzSize="small" [nzData]="['']" #basicTable2 nzBordered *ngIf="this.SelectedRawList && this.Type=='Production'" class="tbl">
                  <thead>
                      <tr>

                          <th nzWidth="50px"></th>
                          <th>Category</th>
                          <th>Product</th>
                          <th nzWidth="150px">Supplier</th>
                          <th nzWidth="100px">Batch No.</th>
                          <th nzWidth="150px">Store</th>
                          <th nzWidth="100px">Stock</th>
                          <th nzWidth="100px">QTY</th>
                          <th nzWidth="100px">Rate</th>
                          <th nzWidth="100px">Amount</th>
                          <th nzWidth="100px">Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let data of SelectedRawList;let i=index">

                          <td nzWidth="20px">
                              {{i+1}}

                          </td>
                          <td>{{ data.MixingName }}</td>
                          <td>{{ data.ProductName }}</td>
                          <td>{{ data.SupplierName }}</td>
                          <td>{{ data.Batch }}</td>

                          <td>{{ data.StoreName }}</td>
                          <td>{{ data.StockQuantity }}</td>
                          <td>{{ data.Quantity }}</td>
                          <td>{{ data.PerUnitPrice }}</td>
                          <td>{{ CalculateAmount(data) }}</td>
                          <td>
                              <a class="btn btn-sm btn-light-danger" (click)="RemoveStockProduct(data)">Remove</a>
                          </td>

                      </tr>

                  </tbody>

                </nz-table>
              </div>
            </div>
            </div>
          <nz-divider></nz-divider>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
          </div>
          <nz-divider></nz-divider>
        </div>
      </div>
</form>


  </div>
</div>



<app-adddemand></app-adddemand>