import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ProductCategoryModel, ProductFirstSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-product-first-sub-category',
  templateUrl: './product-first-sub-category.component.html',
  styleUrls: ['./product-first-sub-category.component.css']
})
export class ProductFirstSubCategoryComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductFirstSubCategoryListOriginal: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  NewProductFirstSubCategory: ProductFirstSubCategoryModel = new ProductFirstSubCategoryModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Product Sub-Category";

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ProductSubCategory, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ProductSubCategory, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.ProductSubCategory, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ProductSubCategory, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      ProductFirstSubCategoryName: [null, [Validators.required, Validators.minLength(3)]],
      ProductFirstSubCategoryDesc: [null, [Validators.required]],
      ProductCategoryId: [null, [Validators.required]]
    });
    this.GetAllProductFirstSubCategory();
    this.GetAllProductCategory();
  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllProductFirstSubCategory() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res;
      this.ProductFirstSubCategoryListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProductFirstSubCategory();
      }
    });
  }
  GetAllProductCategory() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.isTableLoading = false;
    }, res => { this.GetAllProductCategory(); });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  SaveProductFirstSubCategory() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewProductFirstSubCategory.ProductFirstSubCategory = this.f['ProductFirstSubCategoryName'].value;
    this.NewProductFirstSubCategory.ProductCategoryId = this.f['ProductCategoryId'].value;
    this.NewProductFirstSubCategory.ProductFirstSubCategoryDesc = this.f['ProductFirstSubCategoryDesc'].value;
    this.NewProductFirstSubCategory.ProductFirstSubCategoryAddedBy = UserInfo.EmailID;
    // if(!this.Validate(this.NewProductFirstSubCategory))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "productcategory/addupdateproductfirstsubcategory";
    this.http.post<ProductFirstSubCategoryModel>(url, this.NewProductFirstSubCategory).subscribe({

      next: res => { this.alertService.success("ProductFirstSubCategory Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllProductFirstSubCategory(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: ProductFirstSubCategoryModel) {
    this.PopUpTitle = "Modify Product SubCategory Details";
    this.validateForm.setValue(
      {
        ProductFirstSubCategoryName: data.ProductFirstSubCategory,
        ProductCategoryId: data.ProductCategoryId,
        ProductFirstSubCategoryDesc: data.ProductFirstSubCategoryDesc,

      }
    )
    this.NewProductFirstSubCategory = new ProductFirstSubCategoryModel();
    this.NewProductFirstSubCategory.ProductFirstSubCategoryId = data.ProductFirstSubCategoryId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New ProductFirstSubCategory";
    this.validateForm.reset();
    this.NewProductFirstSubCategory.ProductFirstSubCategory = "";
    this.NewProductFirstSubCategory.ProductFirstSubCategoryId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveProductFirstSubCategory();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteProductFirstSubCategory() {
    let url = this.ApiUrl + "productcategory/deleteproductfirstsubcategory";
    this.http.post<any>(url, this.NewProductFirstSubCategory).subscribe({

      next: res => {
        this.alertService.success(res);
        this.GetAllProductFirstSubCategory();
      },

      error: res => {
        console.log(res)
        if (res.error.StatusCode == HttpStatusCode.BadRequest) {
          this.alertService.warning(res.error.ResponseBody)
        }
        else {
          this.alertService.error(res.error.ResponseBody);
          this.isLoading = false;
        }
      },

    });

  }
  handleDelete(data: ProductFirstSubCategoryModel) {
    this.NewProductFirstSubCategory = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Product First Sub Category?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteProductFirstSubCategory(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  enableEditDeleteBtn(data: any) {
    if (this.permission.Delete && data == "Delete") {
      return false;
    }
    if (this.permission.Edit && data == "Edit") {
      return false;
    }
    else {
      return true;
    }
  }
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllProductFirstSubCategory();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ProductFirstSubCategoryListOriginal;
    var res2 = this.ProductFirstSubCategoryListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ProductFirstSubCategoryModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.ProductFirstSubCategoryAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.ProductFirstSubCategoryAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ProductFirstSubCategoryList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ProductFirstSubCategoryList = res.filter(
        (item: ProductFirstSubCategoryModel) =>
          item?.ProductFirstSubCategory?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductCategory?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductFirstSubCategoryDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductFirstSubCategoryAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

}
