<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Activity Log</nz-page-header-title>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">


          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>From</label>
                <nz-date-picker [(ngModel)]="request.DateFrom" nzPlaceHolder="Date"
                  style="width: 150px;margin-right: 8px;"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>To</label>
                <nz-date-picker [(ngModel)]="request.DateTo" nzPlaceHolder="Date"
                  style="width: 150px;margin-right: 8px;"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Sale Order Number</label>
                <input nz-input [(ngModel)]="request.SaleOrderNumber" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Status</label>
                <nz-select #microwaveRef nzShowSearch nzSize="default" [(ngModel)]="request.Status" name="Category"
                  nzAllowClear>
                  <nz-option *ngFor="let s of this.statusList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Added By</label>
                <input nz-input [(ngModel)]="request.Addedby" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetAllActivityLog()">
              Search
            </button>

          </div>
        </div>
      </div>
    </div>
    <div style="padding: 8px;float:right">
      <!--<button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline" style="top: 2px;right: 48px;position: absolute;"></i> Export</button>-->
      <!--<button nz-button *ngIf="this.isEmailReportEnabled" [nzLoading]="isEmailButtonLoading" nzSize="small" (click)="sendEmail()"><i nz-icon nzType="mail" nzTheme="outline" style="top: 2px;right: 79px;position: absolute;"></i> Send Email</button>-->
      <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
        style="margin-left:5px"><i style="top: 2px;right: 37px;position: absolute;" nz-icon nzType="printer"
          nzTheme="outline"></i>Print</button>
    </div>
    <nz-divider></nz-divider>
    <div style="max-width:100%; overflow:scroll">
      <nz-table id="print-section" nzSize="small" style="width: 100%" #basicTable [nzShowPagination]="false"
        [nzPageSize]="1000" [nzData]="this.ActivityLogList" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr style="text-align: left;">

            <th>S. No.</th>
            <th>Sale Order No.</th>
            <th>Status</th>
            <th>Action by</th>
            <th>Action Date</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <td>{{ i + 1 }}</td>
            <td><nz-tag [nzColor]="'blue'"><a (click)="OpenViewPop(data)"> {{ data.SaleOrderNumber}}
                </a></nz-tag></td>
            <td>{{ data.Status }}</td>
            <td>{{ data.AddedBy }}</td>
            <td>{{ data.AddedDate | DatetimeConverter}}</td>
          </tr>

        </tbody>

      </nz-table>

    </div>
  </div>
</div>

<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1000"
  nzTitle="Sales Order details" (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div *ngIf="Orderlist">
      <nz-divider nzText="Sales Order Status"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="2">
        <nz-descriptions-item nzTitle="Current Status" [nzSpan]="1" nzColon="true">
          <nz-badge nzStatus="processing"></nz-badge><b>{{ Orderlist.Status }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="WorkPlan Number" [nzSpan]="2"><b>{{ Orderlist.WorkPlanNumber
            }}</b></nz-descriptions-item>
      </nz-descriptions>
      <nz-divider nzText="Sales Order Details"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="6">
        <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
          <b>{{ Orderlist.SaleOrderType }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="2"><b>{{ Orderlist.CustomerName
            }}</b></nz-descriptions-item>

        <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1">
          <b>
            {{
            Orderlist.SaleOrderNumber
            }}
          </b>
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Sale Order Date">
          <b>
            {{ Orderlist.SaleOrderDate | date: "dd-MMM-yyyy" }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Delivery Date">
          <b>
            {{ Orderlist.DeliveryDate | date: "dd-MMM-yyyy" }}
          </b>
        </nz-descriptions-item>
      </nz-descriptions>

      <nz-divider nzText="Production Details"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist.SaleOrderProduction" nzSize="small"
        [nzColumn]="4">
        <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true">
          <b>
            {{
            Orderlist.SaleFormulationCode
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ Orderlist.Category
            }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Thick">
          <b>
            {{
            Orderlist.SaleOrderProduction.ThicknessValue
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Width">
          <b>
            {{
            Orderlist.SaleOrderProduction.WidthNumber
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
          <b>
            {{
            Orderlist.SaleOrderProduction.ManufacturingProductName
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Quantity">
          <b>
            {{
            Orderlist.SaleOrderProduction.OrderQuantity
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Sale Price">
          <b>
            {{
            Orderlist.SaleOrderProduction.SalePrice
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Total Sale Price">
          <b>
            {{
            Orderlist.SaleOrderProduction.TotalSalePrice
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Grain Code">
          <b>
            {{
            Orderlist.SaleOrderProduction.GrainCode
            }}
          </b>
          <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
            nzTooltipTitle="Grain Name: {{Orderlist.SaleOrderProduction.GrainName}}" nzTooltipPlacement="left"
            nz-tooltip nzType="info-circle" nzTheme="outline">
          </span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Color">
          <b>
            {{
            Orderlist.SaleOrderProduction.ColorName
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric Color">
          <b>
            {{
            Orderlist.SaleOrderProduction.FabricColorName
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric Name">
          <b>
            {{
            Orderlist.FormulationFabricProductName
            }}
          </b>
        </nz-descriptions-item>
      </nz-descriptions>

      <!-- process details  -->
      <nz-divider nzText="Post Process"></nz-divider>
      <!-- print  -->
      <div *ngFor="let p of Orderlist.SaleOrderPostProcessOrder"
        style="text-align: center;padding: 5px;border: 1px solid #ccc;border-radius: 4px;margin-top: 10px;background: #fff;box-shadow: 1px 1px 2px 2px #415994;          ">
        <div style="padding: 10px 0; text-transform: uppercase">{{p.PostProcessName}}</div>
        <nz-table *ngIf="p.PostProcessName=='Print'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionPrint;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Removed by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Embossing'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionEmbossing;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Vaccum'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
              <!--<th nzWidth="100px">Price</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionVacuum;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
              <!--<td>{{ data.Price }}</td>-->
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Tumbling'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
              <!--<th nzWidth="100px">Price</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionTumbling;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
              <!--<td>{{ data.Price }}</td>-->
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Lacquar'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction?.Lacquer;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>

    </div>

    <!-- <nz-divider></nz-divider> -->
    <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
          <div nz-col [nzSpan]="24">
            <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="5" style="box-shadow: 1px 1px 2px 2px #415994">
              <nz-descriptions-item nzTitle="Pre Skin" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.PreSkinGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Skin" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.SkinGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Foam" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.FoamGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Adhesive" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.AdhesiveGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.FabricGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Remarks" nzColon="true">
                <b>
                  {{
                  Orderlist.Remarks
                  }}
                </b>
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>