import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { RackModel, StoreModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
@Component({
  selector: 'app-rack',
  templateUrl: './rack.component.html',
  styleUrls: ['./rack.component.css']
})
export class RackComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  RackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  StoreList: StoreModel[] = [];
  NewRack: RackModel = new RackModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Rack";
  NameError = 'Enter Rack name (maximum 50 characters)';
  CodeError = 'Enter Rack code';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  visible = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: number;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {

    this.permission.View = this.auth.CheckResponsibility(Modules.Rack, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Rack, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Rack, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }


    this.validateForm = this.fb.group({
      RackName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      RackCode: [null, [Validators.required], [this.CodeAsyncValidator]],
      RackDesc: [null, [Validators.maxLength(200)]],
      StoreId: [null, [Validators.required]]
    });
    this.GetAllRack();
    this.GetAllStore();
  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;
      this.RackListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllRack();
      }
    });
  }
  GetAllStore() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllStore();
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  SaveRack() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewRack.RackName = this.f['RackName'].value;
    this.NewRack.RackCode = this.f['RackCode'].value;
    this.NewRack.RackDesc = this.f['RackDesc'].value;
    this.NewRack.StoreId = this.f['StoreId'].value;
    this.NewRack.RackAddedBy = UserInfo.EmailID;
    var data = this.RackList.filter(x => x.RackCode == this.NewRack.RackCode || x.RackName == this.NewRack.RackName);
    if (data.length > 0) {
      if (this.NewRack.RackId > 0) {
        var res = this.RackList.filter(x => x.RackId != this.NewRack.RackId);
        var nre = res.filter(x => x.RackCode == this.NewRack.RackCode || x.RackName == this.NewRack.RackName);
        if (nre.length > 0) {
          this.alertService.error("Rack already exist. Please try again")
          this.isLoading = false
          return;
        }
      }
      else {
        this.alertService.error("Rack already exist. Please try again")
        this.isLoading = false
        return;
      }
    }
    // if(!this.Validate(this.NewRack))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "rack/addupdateRack";
    this.http.post<RackModel>(url, this.NewRack).subscribe({

      next: res => { this.alertService.success("Rack Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllRack(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: RackModel) {
    this.PopUpTitle = "Modify Rack Details";
    this.validateForm.setValue(
      {
        RackName: data.RackName,
        RackCode: data.RackCode,
        RackDesc: data.RackDesc,
        StoreId: data.StoreId,
      }
    )
    this.NewRack = new RackModel();
    this.NewRack.RackId = data.RackId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Rack";
    this.validateForm.reset();
    this.NewRack.RackName = "";
    this.NewRack.RackId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveRack();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteRack() {
    let url = this.ApiUrl + "Rack/deleteRack";
    this.http.post<any>(url, this.NewRack.RackId).subscribe({

      next: res => {

        this.alertService.success("Rack Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllRack();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: RackModel) {
    this.NewRack = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Rack?',
      nzOnOk: () => this.DeleteRack(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.RackList.filter(x => x.RackId != this.NewRack.RackId);
        var nre = res.filter(x => x.RackName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Rack name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Rack name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Rack name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  CodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.RackList.filter(x => x.RackId != this.NewRack.RackId);
        var nre = res.filter(x => x.RackCode.toLowerCase() == control.value.toLowerCase());

        this.CodeError = '';
        if (control.value == '') {
          this.CodeError = "Enter Rack code";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.CodeError = "Rack code already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.CodeError = "Enter Rack code";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllRack();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.RackListOriginal;
    var res2 = this.RackListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: RackModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.RackAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.RackAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });


      this.RackList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.RackList = res.filter(
        (item: RackModel) =>
          item?.RackName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.RackCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.RackDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.StoreName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.RackAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())



      )
    }

  }


}
