<app-loader></app-loader>

<div class="d-flex flex-column flex-root">
  <!--begin::Authentication - Sign-in -->
  <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style="background-image: url(assets/img/14.png)">
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <span class="mb-12">
        <img alt="Logo" src="../assets/img/pmsimg.png" />
      </span>
      <!--end::Logo-->
      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto" style="border: 1px solid #d6d8dd;">
        <!--begin::Form-->
        <!--<form class="form w-100" nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">-->
          <!--begin::Heading-->
          <!--<div class="text-center mb-10">-->
            <!--begin::Title-->
            <!--<h1 class="text-dark mb-3">Sign In to ZAIBUNCO</h1>-->
            <!--end::Title-->
            <!--begin::Link-->
            <!-- <div class="text-gray-400 fw-bold fs-4">New Here?
      <a href="" class="link-primary fw-bolder">Create an Account</a></div> -->
            <!--end::Link-->
          <!--</div>-->
          <!--begin::Heading-->
          <!--begin::Input group-->
          <!--<nz-form-item>
      <nz-form-control nzErrorTip="Please input your username!">
        <nz-input-group>
          <label class="form-label fs-6 fw-bolder text-dark">Email</label>
          <input class="form-control form-control-lg form-control-solid" formControlName="userName" type="text" name="email" autocomplete="off" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input your Password!">
        <nz-input-group>
          <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
          <input class="form-control form-control-lg form-control-solid" type="password" formControlName="password" name="password" autocomplete="off" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div nz-row class="login-form-margin">
      <div nz-col [nzSpan]="12">-->
          <!-- <label nz-checkbox formControlName="remember">
      <span class="form-label fs-6 fw-bolder text-dark" style="font-weight: 400!important;">Remember me</span>
    </label> -->
          <!--</div>
    <div nz-col [nzSpan]="12">-->
          <!-- <a class="login-form-forgot link-primary fs-6 fw-bolder">Forgot password ?</a> -->
          <!--</div>
    </div>-->

        <!--</form>-->
        <div class="text-center">
          <button class="btn btn-lg btn-primary w-100 mb-5" (click)="login()"><i nz-icon nzType="login" nzTheme="outline"></i>Log in with Azure</button>
          <!--<a routerLink="" class="navbar-brand login-padding"  (click)="login()">Login</a>-->
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <!--end::Content-->
    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <!-- <div class="d-flex align-items-center fw-bold fs-6">
        <a href="https://keenthemes.com" class="text-muted text-hover-primary px-2">About</a>
        <a href="mailto:support@keenthemes.com" class="text-muted text-hover-primary px-2">Contact</a>
        <a href="https://1.envato.market/EA4JP" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div> -->
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Authentication - Sign-in-->

</div>