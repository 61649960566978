import { ESalesOrderStatus } from "./Enums";
import { MixingModel } from "./MixingModel";
import { LinkedSaleOrderModel, SaleOrderModel, SaleOrderProductionModel } from "./SalesOrderModel";
import { CustomerModel } from "./SupplierModel";

export class WorkPlanMasterModel {
  WorkPlanId: any;
  WorkPlanNo: string = '';
  ProductionDetails: string = '';
  LotNo: string = '';
  BatchNo: string = '';
  WorkPlanDate: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
  IsReviewed: boolean = false;
  ReviewedBy: string = '';
  ReviewedDate: string = '';
  WorkPlanOrder: WorkPlanOrderModel[] = [];
  ProductionLineNo: string = '1';
  WorkShift: string = 'day';
}

export class WorkPlanOrderModel {
  WorkPlanOrdersId: number = 0;
  WorkplanId: number = 0;
  OrderId: number = 0;
  SaleOrder: SaleOrderModel = new SaleOrderModel;
  WorkPlanJumbo: WorkPlanJumboMasterModel[] = [];
  TotalJumboQty: number = 0;
  TotalWeight: number = 0;
  IsEdit: boolean = false;
  TempManufacturingQTY: number = 0;
}

export class WorkPlanMixingModel {
  WorkPlanMixingsId: number = 0;
  WorkplanId: number = 0;
  MixingId: number = 0;
  Mixing: MixingModel[] = [];
}

export class WorkPlanJumboMasterModel {
  WorkPlanJumboMasterId: number = 0;
  WorkPlanId: number = 0;
  SaleOrderId!: number;
  SaleOrderNumber: string = '';
  OrderId!: number;
  RackId!: number;
  StoreId!: number;
  RackName: string = '';
  RackCode: string = '';
  JumboRollDate!: string;
  JumboRollStartTime!: Date;
  JumboRollEndTime!: Date;
  JumboNo: string = '';
  Rate!: number;
  Amount!: number;
  JumboRolQty!: number;
  ActualQuantity!: number;
  Weight!: number;
  Remark: string = '';
  Yield: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  JumboInspection: JumboInspectionModel[] = [];
  IsInspectionCompleted: boolean = false;
  IsSampleJumbo: boolean = false;
  IsLiningOrder: boolean = false;
  WastagePrint !: number;
  WastageEmbossing !: number;
  WastageLacquer !: number;
  WastageVacuum !: number;
  WastageTumbling !: number;
  SaleOrderCode !: string;
  SaleOrderManufacturingProductName !: string;
  SaleOrderStatus!: string;
  ShiftSupervisorWorkerName!: string;
  ShiftSupervisorWorkerId: number = 0;
  ProductionLineNo: number = 0;
  LinkedSaleOrder: LinkedSaleOrderModel = null;
}
export class WorkPlanJumboListMasterModel {
  SerialNo: number = 0;
  WorkPlanJumboMasterId: number = 0;
  WorkPlanId: number = 0;
  WorkPlanNo: string = '';
  SaleOrderId!: number;
  OrderId!: number;
  RackId!: number;
  StoreId!: number;
  RackName: string = '';
  RackCode: string = '';
  JumboRollDate!: string;
  JumboRollStartTime!: Date;
  JumboRollEndTime!: Date;
  JumboRollPrdSpeed!: number;
  JumboNo: string = '';
  Rate!: number;
  Amount!: number;
  JumboRolQty!: number;
  ActualQuantity!: number;
  Weight!: number;
  Remark: string = '';
  Yield: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  JumboInspection: JumboInspectionModel[] = [];
  IsInspectionCompleted: boolean = false;
  IsSampleJumbo: boolean = false;
  IsLiningOrder: boolean = false;
  WastagePrint !: number;
  WastageEmbossing !: number;
  WastageLacquer !: number;
  WastageVacuum !: number;
  WastageTumbling !: number;
  SaleOrderNumber: string = '';
  CustomerName: string = '';
  SaleOrderCode !: string;
  SaleOrderManufacturingProductName !: string;
  SaleOrderStatus!: string;
  SaleFormulationCodeName: string;
  ShiftSupervisorWorkerName!: string;
  ShiftSupervisorWorkerId: number = 0;
  ProductionLineNo: number = 0;
  LinkedSaleOrder: LinkedSaleOrderModel = null;
}
export class WorkPlanPrintModel {
  WorkPlanNo: string = '';
  ProductionDetails: string = '';
  LotNo: string = '';
  BatchNo: string = '';
  WorkPlanDate: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
  WorkPlanOrder: WorkPlanOrderModel[] = [];
}

export class JumboInspectionModel {
  SerialNo: number = 0;
  WorkPlanJumboMasterId: number = 0;
  JumboInspectionId!: number;
  SaleOrderId!: number;
  SaleOrderNumber: string = '';
  Grade: string = '1st';
  Quantity!: number;
  Code: string = '';
  Unit: string = 'Mtrs';
  ThicknessNumber: string = '';
  AddedBy: string = '';
  SaleOrderCode: string = '';
  AddedDate: string = '';
  DispatchedQuantity!: number;
  DispatchStatus: string = '';
  InspectedBy: string = '';
  Weight!: number;
  JumboDispatchId: number | null;
  IsChecked: boolean = false;
  RollType!: string;
  IsSampleJumbo: boolean = false;
  SaleOrderStatus!: ESalesOrderStatus;
  RackId: number = 0;
  RackName: string = '';
  StoreId: number = 0;
  StoreName: string = '';
  enableWeightSave: boolean = false;
}

export class JumboInspectionPrintModel {
  WorkPlanJumboMasterId: number = 0;
  JumboInspectionId!: number;
  SaleOrderId!: number;
  SaleOrderCode: string;
  Grade: string = '1st';
  Quantity!: number;
  Weight!: number;
  Code: string = '';
  Unit: string = 'Mtrs';
  ThicknessNumber: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
  DispatchedQuantity!: number;
  DispatchStatus: string = '';
  InspectedBy: string = '';
  JumboDispatchId: number | null;
  IsChecked: boolean = false;
}

export class JumboActualListModel {
  WorkPlanJumboMasterId: number = 0;
  ActualQuantity: number = 0;
}

export class WorkPlanSearchModel {
  CustomerId: number = 0;
  FormulationCodeId: number = 0;
  FromDate!: Date;
  ToDate !: Date;
  Status!: ESalesOrderStatus;
  IsReviewed!: boolean;
  SaleOrderNumber!: string;
}
export class JumboInspectionFilterModel {
  SaleOrderId: number | null;
  CustomerId: number | null;
  SaleFormulationCodeId: number | null;
  GrainId: number | null;
  ColorId: number | null;
  FabricColorId: number | null;
  Thick: number | null;
  Width: number | null;
  OrderDateFrom: Date | null;
  OrderDateTo: Date | null;
  EmbossingMasterId: number | null;
  PrintMasterId: number | null;
  TumblingMasterId: number | null;
  VacuumMasterId: number | null;
  LacquerMasterId: number | null;
  FabricProductId: number | null;
  Article: string | null;
  Grade: string | null;
}

export class JumboDispatchModel {
  SerialNo: number | 0;
  PackingNumber: string;
  JumboDispatchId: number;
  TransportId: number | 0;
  TransportName: string;
  VehicleId: number | null;
  VehicleNumber: string;
  DispatchDate: Date;
  DispatchNumber: string;
  AddedBy: string;
  AddedDate: Date | null;
  IsGateIn: boolean | null;
  IsGateOut: boolean | null;
  CustomerId: number | null;
  DispatchQuantity: number | null;
  StoreId: number | null;
  StoreName: string;
  RackId: number | null;
  PackageId: string;
  Remark: string;
  RackName: string;
  Weight: number | null;
  Barcode: string;
  JumboInspection: JumboInspectionModel[];
  CustomerDetails: CustomerModel[]
  DispatchStatus: string;
}

export class JumboDispatchPrintModel {
  PackingNumber: string;
  JumboDispatchId: number;
  TransportId: number;
  TransportName: string;
  VehicleId: number | null;
  VehicleNumber: string;
  DispatchDate: Date;
  DispatchNumber: string;
  AddedBy: string;
  AddedDate: string;
  IsGateIn: boolean | null;
  IsGateOut: boolean | null;
  CustomerId: number | null;
  DispatchQuantity: number | null;
  StoreId: number | null;
  StoreName: string;
  RackId: number | null;
  PackageId: string;
  Remark: string;
  RackName: string;
  Weight: string;
  Barcode: string;
  JumboInspection: JumboInspectionPrintModel[];
  JumboList: WorkPlanJumboMasterModel[]
  CustomerDetails: CustomerModel;
}

export class FinalInspectionPrintModel {
  JUMBONUMBER: string;
  WORKPLANNUMBER: string;
  SALESORDERNUMBER: string;
  STATUS: string;
  TOTALROLLSIZE: string;
  CUSTOMERNAME: string;
}

export class JumboStickerPrintModel {
  JumboNo: string = '';
  SaleOrderNumber: string = '';
  ParentSaleOrderNumber: string = '';
  SaleFormulationCode: string = '';
  CustomerName: string = '';
  ArticleName: string = '';
  GrainCode: string = '';
  GrainName: string = '';
  ColorCode: string = '';
  ColorName: string = '';
  FinishCode: string = '';
  UpperFinishCode: string = '';
  Thickness: string = '';
  WidthNumber: string = '';
  Length: string = '';
  Weight: string = '';
  JumboRollDate: string = '';
  SupervisorName: string = '';
  ProductionLineNo: number = 0;
}