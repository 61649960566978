<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Lamination Post Process</nz-page-header-title>
      <nz-page-header-subtitle>Link your Sales Order here for Sandwich Type Product</nz-page-header-subtitle>
    </nz-page-header>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div style="float: right; margin-bottom: 5%; margin-right: 10px">
          <div style="display: flex">
            <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="onSearch()">
              Search
            </button>
            <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            <button nz-button nzSize="small" (click)="export()">
              <i nz-icon nzType="export" nzTheme="outline"></i> Export
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-table style="width: 100%" [nzScroll]="{ x: '1200px', y: '515px' }" nzSize="small" [nzPageSize]="10"
      style="width: 100%" #basicTable [nzData]="this.SalesList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger [nzShowTotal]="totalTemplate" nzShowQuickJumper="true">

      <thead>
        <tr>
          <th nzWidth="80px" style="text-align: center" nzLeft >Select Upper Order</th>
          <th nzWidth="80px" style="text-align: center" nzLeft >Select Lining Order</th>
          <th nzWidth="60px">S. No.</th>
          <th nzWidth="110px">Order Type</th>
          <th nzWidth="150px">Sale Order No.</th>
          <th nzWidth="150px">WorkPlan No</th>
          <th nzWidth="190px">Customer</th>
          <th nzWidth="170px">Formulation Code</th>
          <th nzWidth="150px">Category</th>
          <th nzWidth="160px">Article Name</th>
          <th nzWidth="120px">Color Name</th>
          <th nzWidth="115px">Grain Name</th>
          <th nzWidth="100px">Order QTY</th>
          <th nzWidth="115px">Order Date</th>
          <th nzWidth="125px">Delivery Date</th>
          <th nzWidth="200px">Added By</th>
          <th nzWidth="115px">Added Date</th>
          <th nzWidth="150px">Remark</th>
          <th nzWidth="160px" style="text-align: center" nzRight>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft style="text-align: center">
            <input style="margin-right: 5px" type="checkbox" [disabled]= "CheckDisabled(data)"
              [checked]="data.IsPrimaryChecked" (change)="data.IsPrimaryChecked = !data.IsPrimaryChecked; onPrimayChecked(data.IsPrimaryChecked)" />
          </td>
          <td nzLeft style="text-align: center">
            <input style="margin-right: 5px" type="checkbox" [disabled]= "CheckLiningDisabled(data)"
              [checked]="data.IsLinkedChecked" (change)="data.IsLinkedChecked = !data.IsLinkedChecked; onLinkedChecked(data.IsLinkedChecked)" />
          </td>
          <td>{{ data.SerialNo }}</td>
          <td>{{ data.SaleOrderType }}</td>
          <td>{{ data.SaleOrderNumber }}</td>
          <td>{{ data.WorkPlanNumber}}</td>
          <td>{{ data.CustomerName }}</td>
          <td>{{ data.SaleFormulationCode }}</td>
          <td>{{ data.Category }}</td>
          <td>{{ data.SaleOrderProduction.ManufacturingProductName }}</td>
          <td>{{ data.SaleOrderProduction.ColorName }}</td>
          <td>{{ data.SaleOrderProduction.GrainName }}</td>
          <td>{{ data.SaleOrderProduction.OrderQuantity }}</td>
          <td>{{ data.SaleOrderDate | date: "dd-MMM-yyyy" }}</td>
          <td>{{ data.DeliveryDate | date: "dd-MMM-yyyy" }}</td>
          <td>{{ data.AddedBy }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td>{{ data.Remarks }}</td>
          <td nzRight style="text-align: center">
            <nz-tag [nzColor]="'blue'"> <a>{{ data.Status }}</a></nz-tag>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <br><br>
    <button style="margin-right: 5px;" nz-button nzType="primary" (click)="SaveLinkSaleOrder()">
      Create Sandwich Order
    </button>
  </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>

