import { HttpClient, HttpRequest, HttpResponse, HttpResponseBase, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ProductCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {
  ProductList: ProductModel[] = [];
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  ProductCategoryList: ProductCategoryModel[] = [];
  ProductCategoryListOriginal: ProductCategoryModel[] = [];
  NewProduct: ProductModel = new ProductModel;
  NewProductCategory: ProductCategoryModel = new ProductCategoryModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Product Category";
  searchValue = '';
  visible = false;
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ProductCategory, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ProductCategory, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.ProductCategory, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ProductCategory, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProductCategory()
    this.typeList = [
      { name: "Raw" },
      { name: "Finished" },
      { name: "Internal-Use" }
    ];
    this.validateForm = this.fb.group({
      ProductCategory: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],

      ProductCategoryDesc: [null, [Validators.required, Validators.maxLength(200)]],
      ProductType: [null, [Validators.required]]
    });
    this.GetAllProductCategory();
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllProductCategory() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "productcategory/getallproductcategories";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.ProductCategoryListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProductCategory();
      }
    });
  }
  SaveProductCategory() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewProductCategory.ProductCategory = this.f['ProductCategory'].value;

    this.NewProductCategory.ProductCategoryDesc = this.f['ProductCategoryDesc'].value;
    this.NewProductCategory.ProductCategoryAddedBy = UserInfo.EmailID;
    this.NewProductCategory.ProductType = this.f['ProductType'].value;

    let url = this.ApiUrl + "productcategory/addupdateproductcategory";
    this.http.post<any>(url, this.NewProductCategory).subscribe({

      next: res => { this.alertService.success(res); this.isLoading = this.isVisible = false; this.GetAllProductCategory(); },
      error: res => {
        this.alertService.error(res.error); this.isLoading = this.isVisible = false;
      },

    });

  }

  OpenEditPop(data: ProductCategoryModel) {
    this.PopUpTitle = "Modify Product Category Details";
    this.validateForm.setValue(
      {
        ProductCategory: data.ProductCategory,

        ProductCategoryDesc: data.ProductCategoryDesc,
        ProductType: data.ProductType

      }
    )
    this.NewProductCategory = new ProductCategoryModel();
    this.NewProductCategory.ProductCategoryId = data.ProductCategoryId;
    this.NewProduct.ProductType = data.ProductType;
    this.isVisible = true;
    // this.NewProduct= new ProductModel();
  }

  showModal(): void {
    this.PopUpTitle = "Add New ProductCategory";
    this.validateForm.reset();
    this.NewProductCategory.ProductCategory = "";
    this.NewProductCategory.ProductCategoryId = 0;
    // this.NewProduct = new ProductModel;
    this.NewProduct.ProductType = "";
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveProductCategory();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteProductCategory() {
    let url = this.ApiUrl + "productcategory/deleteproductcategory";
    this.http.post<any>(url, this.NewProductCategory).subscribe({

      next: res => {
        this.alertService.success(res);
        this.GetAllProductCategory();
      },

      error: res => {
        console.log(res)
        if (res.error.StatusCode == HttpStatusCode.BadRequest) {
          this.alertService.warning(res.error.ResponseBody)
        }
        else {
          this.alertService.error(res.error.ResponseBody);
          this.isLoading = false;
        }
      }
    });

  }
  handleDelete(data: ProductCategoryModel) {
    this.NewProductCategory = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Product Category?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteProductCategory(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  enableEditDeleteBtn(data: any) {
    if (this.permission.Delete && data == "Delete") {
      return false;
    }
    if (this.permission.Edit && data == "Edit") {
      return false;
    }
    else {
      return true;
    }
  }

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllProductCategory();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ProductCategoryListOriginal;
    var res2 = this.ProductCategoryListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ProductCategoryModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.ProductCategoryAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.ProductCategoryAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ProductCategoryList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ProductCategoryList = res.filter(
        (item: ProductCategoryModel) =>
          item?.ProductType?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductCategory?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductCategoryDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductCategoryAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }


}
