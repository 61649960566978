export class BankMasterModel
{
  BankId: number=0;
  BankName: string='';
  AccountHolderName: string = '';
  AccountType: string = '';
  AccountNumber: string = '';
  Ifsc: string = '';
  Branch: string = '';
  SwiftCode: string = '';
  Micr: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
}
