import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SaleOrderModel } from '../../Models/SalesOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  selector: 'app-mixingprint',
  templateUrl: './mixingprint.component.html',
  styleUrls: ['./mixingprint.component.css']
})
export class MixingprintComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  ProductionList: SaleOrderModel[] = [];
  ProductionListOriginal: SaleOrderModel[] = [];
  Production!: SaleOrderModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production";
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription;
  ProductionId = 0;
  PrintStr = ''
  EmbossingStr = ''
  TumblingStr = ''
  LacquerStr = ''
  VaccumStr = ''
  permission = {
    View: false,
    SaleOrderView: false
  }
  constructor(public http: HttpClient, private route: ActivatedRoute, private loader: LoadingService, private auth: AuthService,
    private router: Router) { }

  ngOnInit() {
    // this.permission.View = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.View);
    // this.permission.SaleOrderView = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.View)
    // if (this.permission.View != true || this.permission.SaleOrderView != true) {
    //   this.router.navigate(['/home/unauthorized']);
    // }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProductionId = params["id"];
    })

    this.GetProductionDatabyid(this.ProductionId);
  }


  GetProductionDatabyid(id: number) {
    this.loader.show();
    //let url = this.ApiUrl + "production/getproductiondatabyid/" + id;
    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + id;
    this.http.get<any>(url).subscribe(res => {

      this.Production = res;
      console.log(this.Production)
      if (this.Production != null) {
        //this.Production?.Mixing.forEach(x => {
        //  x.WeightGsm = x.ProductionMixingRawMaterial.reduce((sum, current) => sum + parseInt(current.AvgGsm) * current.Quantity, 0);
        //})
        this.PrintStr = this.Production.SaleOrderProduction.SaleOrderProductionPrint.map(x => x.Name).join(', ');
        this.EmbossingStr = this.Production.SaleOrderProduction.SaleOrderProductionEmbossing.map(x => x.Name).join(', ');
        this.TumblingStr = this.Production.SaleOrderProduction.SaleOrderProductionTumbling.map(x => x.Name).join(', ');
        this.LacquerStr = this.Production.SaleOrderProduction.Lacquer.map(x => x.Name).join(', ');
        this.VaccumStr = this.Production.SaleOrderProduction.SaleOrderProductionVacuum.map(x => x.Name).join(', ');
        this.Production.SaleOrderProduction.FinalMixingGSMTotal = this.Production?.SaleOrderProduction?.FormulationMixing.reduce((sum, current) => sum + current.WeightGsm, 0) ?? 0
        this.loader.hide();
        setTimeout(function () {
          //window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
      }
    });
  }

}
