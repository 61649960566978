import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductionTumblingModel } from 'src/PmsUIApp/Models/ProductionModel';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';
@Component({
  selector: 'app-tumbling-list',
  templateUrl: './tumbling-list.component.html',
  styleUrls: ['./tumbling-list.component.css'],
})
export class TumblingListComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  TumblingList: ProductionTumblingModel[] = [];
  TumblingListOriginal: ProductionTumblingModel[] = [];
  TumblingListOriginal2: ProductionTumblingModel[] = [];
  NewTumbling: ProductionTumblingModel = new ProductionTumblingModel();
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Tumbling';
  TumblingNameError = 'Enter Tumbling name';
  TumblingCodeError = 'Enter Tumbling code';
  searchValue = ''
  exportoptions = {
    headers: ['TumblingName', 'Code', 'Description'],
  };
  fields: any;
  exportfields = [
    {
      TumblingName: '',
      Code: '',
      Description: '',
    },
  ];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService, private auth: AuthService, private router: Router
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Tumbling, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Tumbling, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Tumbling, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      TumblingName: [
        null,
        [Validators.required, Validators.minLength(3)],
        [this.TumblingNameAsyncValidator],
      ],
      Code: [null, [Validators.required], [this.VaccumCodeAsyncValidator]],
      Description: [null, [Validators.maxLength(200)]],
    });
    this.GetAllTumbling();
  }
  get f() {
    return this.validateForm.controls;
  }

  GetAllTumbling() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'tumbling/getalltumblings';
    this.http.get<ProductionTumblingModel[]>(url).subscribe(
      (res) => {
        this.TumblingList = res;
        this.TumblingListOriginal = res;
        this.TumblingListOriginal2 = res;
        this.exportfields = [];
        this.TumblingListOriginal.forEach((x) => {
          this.fields = {};
          this.fields.TumblingName = x.Name;
          this.fields.Code = x.Code;
          this.fields.Description = x.Description;
          this.exportfields.push(this.fields);
        });
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllTumbling(); }
      }
    );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search() {

    var res = this.TumblingListOriginal2;
    this.TumblingList = res.filter((item: ProductionTumblingModel) => {


      if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.TumblingMasterId == +this.searchValue
      ) {
        return true;
      } else if (
        item?.Code == this.searchValue
      ) {
        return true;
      }
      else if (
        item?.AddedDate?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else {
        let searchDate: any = new Date(this.searchValue);

        searchDate.setDate(searchDate.getDate() + 1);
        if (!isNaN(searchDate)) {
          searchDate = searchDate.toISOString().split('T')[0];
        }

        let splitDate = item.AddedDate.split('T')[0];

        return splitDate == searchDate ? true : false;
      }


    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  SaveTumbling() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;
      return;
    }

    const d = new Date();

    this.NewTumbling.Name = this.f['TumblingName'].value;
    this.NewTumbling.Code = this.f['Code'].value;
    this.NewTumbling.Description = this.f['Description'].value;
    this.NewTumbling.AddedDate = d.toLocaleTimeString();
    let url = this.ApiUrl + 'tumbling/addupdatetumbling';
    this.http.post<ProductionTumblingModel>(url, this.NewTumbling).subscribe({
      next: (res) => {
        this.alertService.success('Tumbling Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllTumbling();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
  Validate(model: ProductionTumblingModel) {
    var Isvalid: boolean = true;
    if (model.Name == '') {
      this.alertService.error('Enter Tumbling Name');
      Isvalid = false;
    } else if (model.Code == '') {
      this.alertService.error('Enter Code');
      Isvalid = false;
    }

    return Isvalid;
  }
  OpenEditPop(data: ProductionTumblingModel) {
    this.PopUpTitle = 'Modify Tumbling Details';
    this.validateForm.setValue({
      TumblingName: data.Name,
      Code: data.Code,
      Description: data.Description,
    });
    this.NewTumbling = new ProductionTumblingModel();
    this.NewTumbling.TumblingMasterId = data.TumblingMasterId;
    this.isVisible = true;
  }
  showModal(): void {
    this.PopUpTitle = 'Add New Tumbling';
    this.validateForm.reset();
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveTumbling();
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  // validation for Name 
  TumblingNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.TumblingList.filter(
          (x) => x.TumblingMasterId != this.NewTumbling.TumblingMasterId
        );
        var nre = res.filter(
          (x) => x.Name.toLowerCase() == control.value.toLowerCase()
        );
        this.TumblingNameError = '';

        if (control.value == '') {
          this.TumblingNameError = 'Enter Tumbling name';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.TumblingNameError = 'Tumbling name already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.TumblingNameError = 'Enter Tumbling name';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  // validation for Code 
  TumblingCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.TumblingList.filter(
          (x) => x.TumblingMasterId != this.NewTumbling.TumblingMasterId
        );
        var nre = res.filter(
          (x) => x.Code.toLowerCase() == control.value.toLowerCase()
        );

        this.TumblingCodeError = '';
        if (control.value == '') {
          this.TumblingCodeError = 'Enter Tumbling code';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.TumblingCodeError = 'Tumbling code already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.TumblingCodeError = 'Enter Branch code';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  // validation for Code
  VaccumCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.TumblingList.filter(
          (x) => x.TumblingMasterId != this.NewTumbling.TumblingMasterId
        );
        var nre = res.filter(
          (x) => x.Code.toLowerCase() == control.value.toLowerCase()
        );
        this.TumblingCodeError = '';
        if (control.value == '') {
          this.TumblingCodeError = 'Enter Tumbling code';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.TumblingCodeError = 'Tumbling code already exist';
          observer.next({ error: true, duplicated: true });
        } else {
          this.TumblingCodeError = 'Enter Tumbling code';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
}
