import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { PurchaseOrderModel, PurchaseOrderProductModel } from '../../Models/PurchaseOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { KnittingDivisionStockModel } from 'src/PmsUIApp/Models/KnittingDivisionStockModel';

@Component({
  selector: 'app-KnittingDivisionStockList',
  templateUrl: './KnittingDivisionStockList.component.html'
})
export class KnittingDivisionStockListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  KnittingDivisionStockList: KnittingDivisionStockModel[] = [];
  KnittingDivisionStockListOriginal: KnittingDivisionStockModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  IsBtnVisible: boolean = false;
  permission = {
    View: false,
    Add: false,
    Manage: false,
    CreatePO: false,
    ViewPO: false,
  }
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  count: number;
  constructor(private loader: LoadingService, private fb: UntypedFormBuilder, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.KnittingDivisionStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.KnittingDivisionStock, Responsibility.Add);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.KnittingDivisionStock, Responsibility.Manage);
    this.permission.CreatePO = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Add);
    this.permission.ViewPO = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.View);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    console.log(this.permission)
    this.GetAllKnittingFabricStock();
    this.GetAllProducts();
    this.GetAllProductCategory();
  }
  GetAllKnittingFabricStock() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "iot/getallknittingfabricstocks";
    this.http.get<KnittingDivisionStockModel[]>(url).subscribe(res => {
      this.KnittingDivisionStockListOriginal = this.KnittingDivisionStockList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllKnittingFabricStock();
      }
    });
  }
  OnCheckChanged() {
    var selectedFabricStockId = this.KnittingDivisionStockList.filter((item) => item.IsChecked).map(
      (finalResult) => {
        return {
          FabricStockId: finalResult.KnittingFabricWeightInputId,
        };
      }
    );
    localStorage.setItem("FabricStockIds", JSON.stringify(selectedFabricStockId));
    if (selectedFabricStockId.length > 0)
      this.IsBtnVisible = true
    else
      this.IsBtnVisible = false;
  }
  CheckCreatePOPermission() {
    if (this.permission.CreatePO && this.IsBtnVisible) {
      return true
    }
    else
      return false
  }
  CreatePO() {
    this.router.navigate(['/home/po/addfabricstock/0']);
  }
  OpenViewPop(data: any) {
    console.log(data)
    if (this.PurchaseOrderList.length <= 0) {
      let url = this.ApiUrl + 'purchaseorder/getpurchaseorderbyid/' + data.Poid;
      this.loader.show();
      this.http.get<PurchaseOrderModel>(url).subscribe(
        (res) => {
          this.PurchaseOrder = res;
          console.log(this.PurchaseOrder)
          this.isVisible = true;
          this.loader.hide();
        },
        () => { }
      );
    }
    else {
      this.PurchaseOrder = this.PurchaseOrderList.filter(x => x.Poid == data.Poid)[0];
      this.isVisible = true;
    }

  }
  handleCancel(): void {
    this.isVisible = false;
  }
  ChangeStatus(data: KnittingDivisionStockModel, status: string) {
    this.loader.show();
    data.Status = status;
    let url = this.ApiUrl + "purchaseorder/changedemandstatus";
    this.http.post<any>(url, data).subscribe({
      next: res => {
        this.alertService.success("Demand " + status);
        this.loader.hide();
      },
      error: res => { this.alertService.error(res.error); this.loader.hide(); },
    });
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetReport() {
    var res = this.KnittingDivisionStockListOriginal;
    this.KnittingDivisionStockList = res.filter((item: KnittingDivisionStockModel) => item.ProductType == this.SelectedProductType || this.SelectedProductType == '')
      .filter((item: KnittingDivisionStockModel) => item.ProductCategoryId == this.CategoryID || this.CategoryID == 0 || this.CategoryID == null)
      .filter((item: KnittingDivisionStockModel) => item.ProductFirstSubCategoryId == this.FirstCategoryID || this.FirstCategoryID == 0 || this.FirstCategoryID == null)
      .filter((item: KnittingDivisionStockModel) => item.ProductSecSubCategoryId == this.SecondCategoryID || this.SecondCategoryID == 0 || this.SecondCategoryID == null)
      .filter((item: KnittingDivisionStockModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null)
      .filter((item: KnittingDivisionStockModel) => item.Status == this.SelectedStatus || this.SelectedStatus == null || this.SelectedStatus == '')
    console.log(this.SelectedProductType)
    console.log(this.CategoryID)
    console.log(this.FirstCategoryID)
    console.log(this.SecondCategoryID)
    console.log(this.selectedProduct)
    console.log(this.SelectedStatus)
  }
  CalculatePendingQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY >= 0) {
      return RemainingQTY
    }
    return 0
  }
  CalculateExtraQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY < 0) {
      return '+' + (data.RecievedQuantity - data.Quantity).toFixed(2)
    }
    return 0;
  }
  CalculateGrandTotal(data: PurchaseOrderProductModel[], column: string) {
    var total = 0;
    switch (column) {
      case column = "OrderQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        break;
      }

      case column = "ReceivedQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.RecievedQuantity?.toString()), 0) ?? 0
        break;
      }

      case column = "PendingQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat((this.CalculatePendingQTY(current))?.toString()), 0) ?? 0
        break;
      }

      case column = "ExtraQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(this.CalculateExtraQTY(current)?.toString()), 0) ?? 0
        break;
      }
    }
    return parseFloat(total.toFixed(2));
  }
  setDemandCellColor(data: KnittingDivisionStockModel) {
    // Get the current date
    const currentDate = new Date();
    const addeddate = new Date(data.AddedDate).getTime();
    
    // Calculate the difference in time between the current date and the AddedDate
    const timeDiff = currentDate.getTime() - addeddate;

    // Convert the time difference from milliseconds to days
    const diffDays = timeDiff / (1000 * 3600 * 24);

    // Check if the difference is greater than 3 days
    if (diffDays > 1 && data.Status == 'Pending') {
      return '#ff0000'; // Red color for dates older than 3 days
    } else {
      return ''; // Default color for dates within 3 days
    }
  }
}
