<div *ngIf="this.CostingListOriginal">
    <div class="producttable-container">
        <table class="producttable" style="width: 100%; page-break-inside: auto;">
            <thead>
                <tr style="font-size: small;">
                    <th nzWidth="2%">S.No.</th>
                    <th nzWidth="8%">Sale Order No.</th>
                    <th nzWidth="7%" style="text-align:center">Status</th>
                    <th nzWidth="19%">Customer Name</th>
                    <!-- <th nzWidth="20%">Sale Order Code</th> -->
                    <th nzWidth="10%">Alias</th>
                    <th nzWidth="5%">Order QTY</th>
                    <th nzWidth="5%">MFD QTY</th>
                    <th nzWidth="5%">Rejection %</th>
                    <th nzWidth="5%">Rejection Cost /LM</th>
                    <th nzWidth="5%">Fabric Total Cost</th>
                    <th nzWidth="5%">PRD Cost /LM</th>
                    <th nzWidth="5%">Overhead Cost /LM</th>
                    <th nzWidth="5%">Total Cost /LM</th>
                    <th nzWidth="5%">Final Total Cost</th>
                    <th nzWidth="5%">Profit/Loss Per LM</th>
                    <th nzWidth="5%">Total Profit/Loss</th>
                    <th nzWidth="7%">Submitted Date</th>
                    <th nzWidth="7%">Submitted By</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of CostingListOriginal">
                    <td>{{ data.SerialNo }}</td>
                    <td>{{ data.SaleOrderNumber }}</td>
                    <td style="text-align:center">{{data.CostingStatus}}</td>
                    <td nzBreakWord>{{ data.CustomerName }}</td>
                    <!-- <td style="width: 60px;">{{ data.SaleOrderCode }}</td> -->
                    <td nzBreakWord>{{ data.SaleOrderProduction.ManufacturingProductName }}</td>
                    <td>{{ data.SaleOrderProduction.OrderQuantity }} MTR</td>
                    <td>{{ data.SaleOrderProduction.ManufacturingQuantity }} MTR</td>
                    <td>{{ data.Rejection }} %</td>
                    <td>&#8377;{{ data.RejectionCostLm }}</td>
                    <td>&#8377;{{ data.FabricCost }}</td>
                    <td>&#8377;{{ data.ProductionCostLm }}</td>
                    <td>&#8377;{{ data.OverheadCost }}</td>
                    <td>&#8377;{{ data.TotalCostLm }}</td>
                    <td>&#8377;{{ data.FinalTotalCost | RoundOff}}</td>
                    <td>&#8377;{{ data.ProfitLossLm | RoundOff }}</td>
                    <td>&#8377;{{ data.TotalProfitLoss }}</td>
                    <td>{{ data.AddedDate | DatetimeConverter }}</td>
                    <td>{{ data.AddedBy }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr style="text-align: left;font-weight: bold;">
                    <td colspan="5" style="text-align: right;">Grand Total</td>
                    <td>{{CalculateTotal('SaleOrderQuantity')}} MTRs</td>
                    <td>{{CalculateTotal('ManufacturingQuantity')}} MTRs</td>
                    <td colspan="6"></td>
                    <td>&#8377;{{CalculateTotal('FinalTotalCost')}}</td>
                    <td></td>
                    <td>&#8377;{{CalculateTotal('TotalProfitLoss')}}</td>
                    <td colspan="2"></td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>