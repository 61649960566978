import { Component } from "@angular/core";
import { LoadingService } from "../Services/loadingService";











@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  
  IsPageLoading = this.loader.loading$;
  constructor(private loader: LoadingService) {
  }

}
