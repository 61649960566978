import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProcessModel, ProcessPrintModel } from '../../Models/ProcessModel';

import { AlertMessageService } from '../../Services/AlertMessageService';

@Component({
  selector: 'app-Processlist',
  templateUrl: './processlist.component.html',
  styleUrls: ['./processlist.component.css']
})
export class ProcessListComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  ProductionList: ProcessModel[] = [];
  ProductionListOriginal: ProcessModel[] = [];
  Production!: ProcessModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Process";
  searchValue = '';
  visible = false;
  exportoptions = {
    headers: ["PROCESS NAME", "TEMPERATURE", "WEIGHT PRESSURE", "WEIGHT GSM", "RAW MATERIAL", "ADDED DATE", "ADDED BY"]
  };
  fields: ProcessPrintModel = new ProcessPrintModel;
  exportfields: ProcessPrintModel[] = []
  count: number;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {


    this.GetAllProduction();
  }


  GetAllProduction() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "process/getallprocesss";
    this.http.get<ProcessModel[]>(url).subscribe(res => {
      this.ProductionList = res;
      this.ProductionListOriginal = res;
      this.ProductionList.forEach(x => {
        this.fields = new ProcessPrintModel;

        this.fields.ProcessName = x.ProcessName;
        this.fields.Temperature = x.Temperature;
        this.fields.WeightPressure = x.WeightPressure;
        this.fields.WeightGsm = x.WeightGsm;
        this.fields.ProcessRawMaterial = x.ProcessRawMaterial.length;

        this.fields.AddedBy = x.AddedBy;
        this.fields.AddedDate = x.AddedDate;




        this.exportfields.push(this.fields);
      })
      this.isTableLoading = false;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProduction(); }
    });
  }
  OpenViewPop(data: any) {
    this.isVisible = true;
    this.Production = data;

  }
  export() {


    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'process-export', this.exportoptions);
  }
  handleOk(): void {

    //this.isLoading = true;
    //this.Save();

  }
  //calculateTotal() {
  //  return this.Production.ProductionProduct.reduce((accum, curr) => accum + curr.Amount, 0);
  //}
  handleCancel(): void {
    this.isVisible = false;
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.visible = false;
    var res = this.ProductionListOriginal;
    this.ProductionList = res.filter((item: ProcessModel) => item.ProcessName.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      || item.Temperature.toString().toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      || item.WeightPressure.toString().toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
  }
}
