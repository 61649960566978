import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { WorkPlanPrintModel, WorkPlanMasterModel, WorkPlanOrderModel, WorkPlanSearchModel } from 'src/PmsUIApp/Models/WorkPlanModel';
import { LoadingService } from '../../Services/loadingService';
import { SaleFormulationCodeMasterModel, SaleOrderCostingModel, SaleOrderDispatchReadyModel, SaleOrderModel, SaleOrderPostProcessOrderModel, SaleOrderProductionCompleteModel, SaleOrderProductionMixingModel, SaleOrderProductionModel, SaleOrderProductionRawMaterialModel } from '../../Models/SalesOrderModel';
import { AdminStoreModel, StoreModel } from '../../Models/MasterModel';
import { UserInfo } from '../../Authentication/UserInfo';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ESalesOrderStatus, Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';

@Component({
  selector: 'app-start-production',
  templateUrl: './start-production.component.html',
  styleUrls: ['./start-production.component.css']
})
export class StartProductionComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  WorkPlanListOriginal: WorkPlanMasterModel[] = [];
  PurchaseOrder!: WorkPlanMasterModel;
  WorkPlanModel!: WorkPlanMasterModel;
  isVisible = false;
  isVisibleInspection = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  searchValue = '';
  visible = false;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  AdminStoreList: AdminStoreModel[] = [];
  StoreListOriginal: StoreModel[] = [];
  StoreList: StoreModel[] = [];
  IsSalesOrderWorkplanLoaded = false;
  SelectedOrderNo: string = '';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  exportoptions = {
    headers: [
      'WorkPlanNo',
      'ProductionDetails',
      'LotNo',
      'BatchNo',
      'WorkPlanDate',

    ],
  };
  fields: WorkPlanPrintModel = new WorkPlanPrintModel();
  exportfields: WorkPlanPrintModel[] = [];
  Orderlist!: SaleOrderModel;
  isValidDate: any;
  FabricName: string = '';
  FabricQTY!: number;
  FromStoreID: number = 0;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  isSaleOrderVisible: boolean;
  request: WorkPlanSearchModel = new WorkPlanSearchModel;
  CustomerList: CustomerModel[] = [];
  FormulationCodeList: FormulationCodeModel[] = [];
  status: any = [
    {
      "Text": "NotYet",
      "Value": 0
    },
    {
      "Text": "WorkPlan",
      "Value": 1
    },
    {
      "Text": "Inspection",
      "Value": 2
    },
    {
      "Text": "RawMaterialRequested",
      "Value": 3
    },
    {
      "Text": "RawMaterialIssued",
      "Value": 4
    },
    {
      "Text": "ProductionStarted",
      "Value": 5
    },
    {
      "Text": "Mixing",
      "Value": 6
    },
    {
      "Text": "InJumbo",
      "Value": 7
    },
    {
      "Text": "MoveToPostProcess",
      "Value": 8
    },
    {
      "Text": "PrintAssigned",
      "Value": 9
    },
    {
      "Text": "PrintInProcess",
      "Value": 10
    },
    {
      "Text": "PrintCompleted",
      "Value": 11
    },
    {
      "Text": "EmbossingAssigned",
      "Value": 12
    },
    {
      "Text": "EmbossingInProcess",
      "Value": 13
    },
    {
      "Text": "EmbossingCompleted",
      "Value": 14
    },
    {
      "Text": "VacuumAssigned",
      "Value": 15
    },
    {
      "Text": "VacuumInProcess",
      "Value": 16
    },
    {
      "Text": "VacuumCompleted",
      "Value": 17
    },
    {
      "Text": "LacquerAssigned",
      "Value": 18
    },
    {
      "Text": "LacquerInProcess",
      "Value": 19
    },
    {
      "Text": "LacquerCompleted",
      "Value": 20
    },
    {
      "Text": "TumblingAssigned",
      "Value": 21
    },
    {
      "Text": "TumblingInProcess",
      "Value": 22
    },
    {
      "Text": "TumblingCompleted",
      "Value": 23
    },
    {
      "Text": "JumboInspection",
      "Value": 24
    },
    {
      "Text": "MoveToDispatch",
      "Value": 25
    },
    {
      "Text": "PartialDispatchReady",
      "Value": 26
    },
    {
      "Text": "PartialDispatchCompleted",
      "Value": 27
    },
    {
      "Text": "DispatchReady",
      "Value": 28
    },
    {
      "Text": "DispatchCompleted",
      "Value": 30
    },
    {
      "Text": "LiningOrderMerged",
      "Value": 101
    }
  ];
  PUPVCTypeList: any = [
    {
      "Text": "All",
      "Value": 'All'
    },
    {
      "Text": "GZ(PVC)",
      "Value": 'GZ-'
    },
    {
      "Text": "GZY(PU)",
      "Value": 'GZY-'
    }
  ]
  PUPVCType: any;
  FabricColorName: string;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loader: LoadingService, private auth: AuthService, private router: Router,
    private soDrawer: SoDrawerService

  ) {
  }

  expandSet = new Set<number>();
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.StartProduction, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.StartProduction, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.StartProduction, Responsibility.Delete);
    this.request.FromDate = new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 15));
    this.request.ToDate = new Date(new Date().setHours(23, 59, 59));
    this.request.Status = ESalesOrderStatus.RawMaterialIssued;
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllCustomer();
    this.GetAllFormulationCode();
    this.GetAllWorkPlanList();
    this.GetAllStore();
  }

  GetAllWorkPlanList() {
    this.isTableLoading = true;

    // let url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
    //   var item = ["MoveToDispatch", "DispatchReady"];
    //   this.http.post<any>(url, item).subscribe({
    //     next: res => {
    //       this.WorkPlanList = res.Data;
    //     },
    //     error: res => { this.alertService.error(res.error); },
    //   });
    // }
    if (isNaN(this.request.CustomerId) || this.request.CustomerId == null) {
      this.request.CustomerId = 0;
    }
    if (isNaN(this.request.FormulationCodeId) || this.request.FormulationCodeId == null) {
      this.request.FormulationCodeId = 0;
    }
    if (isNaN(this.request.Status)) {
      this.request.Status = null;
    }

    let url = this.ApiUrl + 'workplan/getallworkplanreport';
    var item = ["RawMaterialIssued"];
    this.http.post<any>(url, this.request).subscribe(
      (res) => {
        this.WorkPlanList = res.Data;
        this.WorkPlanListOriginal = res.Data;
        this.isTableLoading = false;
        this.exportfields = [];
        this.WorkPlanList.forEach((x) => {
          this.fields = new WorkPlanMasterModel();
          this.fields.WorkPlanNo = x.WorkPlanNo;
          this.fields.ProductionDetails = x.ProductionDetails;
          this.fields.LotNo = x.LotNo;
          this.fields.BatchNo = x.BatchNo;
          this.fields.WorkPlanDate = x.WorkPlanDate;
          // this.fields.WorkPlanOrder = x.WorkPlanOrder;       
          // this.fields.AddedDate = x.AddedDate;
          // this.fields.AddedBy = x.AddedBy;
          this.exportfields.push(this.fields);

        });

        this.isTableLoading = false;

      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllWorkPlanList(); }
      }
    );
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'pending-production-export' + exportdate,
        this.exportoptions
      );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  //dummy functions
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }



  OpenViewPop(data: any) {
    this.loader.show();

    let url = this.ApiUrl + "workplan/getallworkplanbyid/" + data.WorkPlanId;
    this.http.get<any>(url).subscribe(res => {

      this.WorkPlanModel = res.Data;
      this.WorkPlanModel.WorkPlanOrder.forEach((element) => {
        element.TotalJumboQty = element.WorkPlanJumbo.reduce((sum, current) => sum + parseFloat(current.JumboRolQty?.toString()), 0) ?? 0
        element.TotalWeight = element.WorkPlanJumbo.reduce((sum, current) => sum + parseFloat(current.Weight?.toString()), 0) ?? 0

      });
      this.isVisible = true;
      this.loader.hide();
    });
  }
  OpenInspectionPop(data: any) {
    //this.loader.show();
    this.IsSalesOrderWorkplanLoaded = false;
    this.SelectedWorkPlanOrder = [];
    this.isVisibleInspection = true;
    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + data.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      //this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "WorkPlan");
      this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "RawMaterialIssued");
      // this.SelectedWorkPlanOrder.forEach(el => {
      //   el.SaleOrder.SaleOrderProduction.ManufacturingQuantity = el.SaleOrder.SaleOrderProduction.OrderQuantity
      // })
      this.IsSalesOrderWorkplanLoaded = true;
      console.log(res);
      this.loader.hide();
    }, res => { });
  }
  OnWorkPlanOrderChange() {
    this.loader.show();
    this.GetSaleOrderDetails(parseInt(this.SelectedOrderNo))
    //let url = this.ApiUrl + "issueproduct/getstockforsaleorder/" + this.SelectedOrderNo
    //this.http.get<SaleOrderProductionRawMaterialModel[]>(url).subscribe(res => {
    //  console.log(res);
    //  this.StockList = res;
    //  console.log(this.SelectedOrderNo)
    //  console.log(this.SelectedWorkPlanOrder)
    //  //this.Orderlist = this.SelectedWorkPlanOrder.filter(x => x.SaleOrder.SaleOrderId.toString() == this.SelectedOrderNo)[0].SaleOrder;
    //  this.FabricName = this.SelectedWorkPlanOrder.filter(x => x.SaleOrder.SaleOrderId.toString() == this.SelectedOrderNo)[0].SaleOrder.FormulationCode.FabricProductName;
    //  this.FabricQTY = this.SelectedWorkPlanOrder.filter(x => x.SaleOrder.SaleOrderId.toString() == this.SelectedOrderNo)[0].SaleOrder.SaleOrderProduction.OrderQuantity;

    //  //this.StockList.forEach(x => {
    //  //  x.AcceptQTY = x.Quantity;
    //  //  x.AvailableQTY = x.ProductStock.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0;
    //  //  var issueitem = new IssueProductModel;
    //  //  issueitem.ProductId = x.ProductId;
    //  //  issueitem.ProductCode = x.ProductCode;
    //  //  issueitem.ProductName = x.ProductName;
    //  //  issueitem.Quantity = 0;
    //  //  issueitem.QuantityValue = 0;
    //  //  issueitem.ProductId = x.ProductId;
    //  //  this.NewPOList.push(issueitem);
    //  //})

    //  this.loader.hide();
    //}, res => { });
  }
  printMixing(data: WorkPlanOrderModel) {

    window.open(`${window.location.origin}/production/mixingprint/` + data.OrderId);
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  handleCancelInspection() {
    this.GetAllWorkPlanList();
    this.isVisibleInspection = false;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }




  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.WorkPlanListOriginal;
    var res2 = this.WorkPlanListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: WorkPlanMasterModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.WorkPlanList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.WorkPlanList = res.filter(
        (item: WorkPlanMasterModel) =>
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.WorkPlanNo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductionDetails?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.LotNo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.BatchNo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())

      )
    }

  }
  // search(): void {
  //   this.visible = false;
  //   var res = this.WorkPlanListOriginal;
  //     this.WorkPlanList = res.filter((item: WorkPlanMasterModel) => item.WorkPlanNo.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
  //     || item.ProductionDetails.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
  //     || item.LotNo.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
  //     || item.BatchNo.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
  //     || item.WorkPlanDate.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1

  //        );

  // }

  GetSaleOrderDetails(SaleOrderId: number) {
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + SaleOrderId;
    this.http.get<any>(url).subscribe(res => {

      this.Orderlist = res;

      this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
      this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach((element) => {

        this.Orderlist.SaleOrderProduction.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
      });
      this.Orderlist.SaleOrderProduction.MixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
      this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
        this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(element.Total) ? 0 : element.Total;
        this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(parseInt(element.WeightGsm?.toString())) ? 0 : parseInt(element.WeightGsm.toString());
        this
      })
      this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingGSMTotal + this.Orderlist.SaleOrderProduction.RawGSMTotal;
      var totalprint = this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalprint);
      var totalEmbossing = this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalEmbossing);
      var totalVaccum = this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalVaccum);
      var totalTumbling = this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalTumbling);
      //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      //console.log(totalLacquer);
      var totalElement = this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(isNaN(totalElement) ?? 0);
      var totalRaw = this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce((sum, current) => sum + parseFloat(current.TotalCost?.toString()), 0) ?? 0
      console.log(totalRaw);
      this.Orderlist.GrandTotal = parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) + parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
        parseFloat(isNaN(totalprint) == true ? "0" : totalprint.toString()) +
        parseFloat(isNaN(totalEmbossing) == true ? "0" : totalEmbossing.toString()) +
        parseFloat(isNaN(totalVaccum) == true ? "0" : totalVaccum.toString()) +
        parseFloat(isNaN(totalTumbling) == true ? "0" : totalTumbling.toString()) +
        /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
        parseFloat(isNaN(totalElement) == true ? "0" : totalElement.toString()) +
        parseFloat(totalRaw.toString());
      this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
      this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
      this.FabricQTY = this.Orderlist.SaleOrderProduction.OrderQuantity;
      console.log(this.Orderlist);
      this.loader.hide();
    });


  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  SaveComplete() {

    var request: any[] = [];
    var reclist = this.SelectedWorkPlanOrder.filter(x => x.SaleOrder.IsChecked);
    if (reclist.length < 1) {
      alert("Please select a saleorder to start the production.");
      return;
    }
    reclist.forEach(el => {
      var SaleOrderProduction = new SaleOrderProductionModel;
      SaleOrderProduction.ManufacturingQuantity = el.SaleOrder.SaleOrderProduction.ManufacturingQuantity;
      var item: SaleOrderModel = {
        SerialNo: 0,
        SaleOrderId: el.SaleOrder.SaleOrderId,
        IsJumboRequired: true,
        SaleOrderProduction: SaleOrderProduction,
        ProductionCompletionRemarks: el.SaleOrder.ProductionCompletionRemarks,
        AddedBy: UserInfo.EmailID,
        IsChecked: false,
        WorkPlanStatus: false,
        WorkPlanNumber: '',
        SaleOrderType: '',
        CustomerId: 0,
        CustomerName: '',
        CategoryId: 0,
        ProformaInvoiceId: 0,
        Category: '',
        FormulationFabricProductName: '',
        FormulationFabricProductId: 0,
        FormulationFabricProductPrice: 0,
        FormulationFabricProductQty: 0,
        FormulationFabricProductUnit: '',
        FormulationFabricProducWidthInMeter: 0,
        SaleOrderNumber: '',
        SaleOrderDate: '',
        DeliveryDate: '',
        LinkedSaleOrder: null,
        IsRawMaterialIssued: false,
        AddedDate: '',
        Remarks: '',
        CostingAdded: false,
        GrandTotal: 0,
        Status: 1,
        SaleOrderStatus: '',
        SaleFormulationCode: '',
        SaleFormulationCodeId: 0,
        FormulationCode: new SaleFormulationCodeMasterModel,
        SendToConsumption: false,
        ConsumptionStoreId: 0,
        IsPrintRequired: false,
        IsEmbossingRequired: false,
        IsLacquerRequired: false,
        IsVacuumRequired: false,
        IsTumblingRequired: false,
        SaleOrderDispatchReady: new SaleOrderDispatchReadyModel,
        SaleOrderPostProcessOrder: [],
        SaleOrderDispatch: [],
        SaleOrderCosting: new SaleOrderCostingModel,
        SaleOrderProductionComplete: new SaleOrderProductionCompleteModel,
        BORNumber: '',
        PreInspectionCompletedBy: '',
        PreInspectionCompletedOn: '',
        IsLinkedChecked: false,
        IsPrimaryChecked: false,
        OrderStatusActionBy: '',
        OrderStatusActionDate: '',
        OrderIdForLinking: 0,
        ShiftSupervisorWorkerId: 0,
        ParentSaleOrder: null,
        SaleOrderProductionPostProcess: null
      }
      request.push(item)
    })

    this.loader.show();
    let url = this.ApiUrl + "saleorder/startsaleorderproduction";
    this.http.post<any>(url, request).subscribe({
      next: res => {
        this.alertService.success(res);
        this.isLoading = false;
        this.loader.hide();
        this.handleCancelInspection()
      },
      error: res => { this.alertService.error(res); this.isLoading = false; this.loader.hide(); },

    });
  }
  GetAllStore() {
    //UserInfo.EmailID = "farhan@damasinfo.com";
    if (this.StoreListOriginal.length <= 0) {
      this.isTableLoading = true;
      let url = this.ApiUrl + "store/getallstores";
      this.http.get<StoreModel[]>(url).subscribe(res => {
        this.StoreList = res;
        res.forEach(x => {
          var str = new AdminStoreModel()
          str.StoreId = x.StoreId;
          str.StoreName = x.StoreName;
          this.AdminStoreList.push(str);
        })
        this.StoreListOriginal = res;
        //setTimeout(() => { this.SelectedStore1 = this.SelectedStore2 = this.StoreListOriginal[0].StoreId; }, 10)
        this.isTableLoading = false;
        this.GetUserStores(UserInfo.EmailID);
      }, res => { });
    }
  }
  GetUserStores(UserName: string) {
    this.isTableLoading = true;
    this.AdminStoreList.forEach(x => x.IsChecked = false);
    let url = this.ApiUrl + "user/getuserstores/" + UserName;
    this.http.get<number[]>(url).subscribe(res => {
      console.log(res);
      this.AdminStoreList.forEach(s => {
        if (res.includes(s.StoreId)) {
          s.IsChecked = true;
        }
      })
      this.AdminStoreList = this.AdminStoreList.filter(x => x.IsChecked);
      this.isTableLoading = false;
    }, res => { });
  }
  OpenSaleOrderViewPop(data: any) {
    console.log(data, "check onece these parts")
    this.soDrawer.SaleOrderId = data.SaleOrderId;
    this.soDrawer.show();
  }
  handleSaleOrderCancel() {
    this.isSaleOrderVisible = false;
    this.Orderlist = null;
  }
  GetSaleOrderTagColor(data: any) {
    if (data.Status == "WorkPlan") {
      return 'lime'
    }
    else if (data.Status == "InJumbo") {
      return '#87d068'
    }
    else if (data.Status == "JumboInspection") {
      return '#f50'
    }
    else
      return 'cyan'

  }
  GetAllCustomer() {

    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  EnableSaveBtn() {
    if (this.permission.Add)
      return false;
    else
      return true;
  }
}
