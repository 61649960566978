<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title *ngIf="this.IsPageView"
        >Stock List</nz-page-header-title
      >
      <nz-page-header-subtitle *ngIf="this.IsPageView"
        >View your stocks here</nz-page-header-subtitle
      >
      <nz-page-header-title *ngIf="this.IsPageInspection"
        >Stock Inspection List</nz-page-header-title
      >
      <nz-page-header-subtitle *ngIf="this.IsPageInspection"
        >Inspect your stocks here</nz-page-header-subtitle
      >
      <nz-page-header-title *ngIf="this.IsPageAllocation"
        >Stock Allocation List</nz-page-header-title
      >
      <nz-page-header-subtitle *ngIf="this.IsPageAllocation"
        >Allocate your stocks here</nz-page-header-subtitle
      >
      <nz-page-header-title *ngIf="this.IsPageManageRejected"
        >Stock Rejected List</nz-page-header-title
      >
      <nz-page-header-subtitle *ngIf="this.IsPageManageRejected"
        >Manage you Rejected items here</nz-page-header-subtitle
      >
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search invoice/date/Products/PONo" [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i> Export</button>
    </div>
    <nz-table
      [nzPageSize]="100"
      #basicTable
      [nzScroll]="{ x: '1200px', y: '715px' }"
      nzShowSizeChanger
      style="width: 100%"
      [nzData]="this.FilteredStockList"
      [nzLoading]="this.isPageLoading"
      nzSize="small"
      [nzPageSize]="100"
      nzBordered
    >
      <thead>
        <tr>
          <th nzWidth="50px">ID</th>
          <th nzWidth="120px">Stock Date</th>
          <th nzWidth="160px">GRN</th>
          <th nzWidth="160px">Invoice No.</th>
          <th nzWidth="160px">PO No.</th>

          <th nzWidth="120px">Invoice Date</th>
          <th nzWidth="120px">Total Price</th>
          <th nzWidth="350px">Products</th>
          <th nzWidth="100px">Count</th>
          <th nzWidth="150px">Received By</th>
          <th nzWidth="150px">Received Date</th>
          <th nzWidth="120px" style="text-align: center" nzRight>Inspection</th>
          <th nzWidth="120px" style="text-align: center" nzRight>Allocation</th>
          <th nzWidth="100px" style="text-align: center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td>{{ data.StockId }}</td>
          <td>{{ data.StockDate |DatetimeConverter}}</td>
          <td>{{ data.Invoice?.Grn }}</td>
          <td>{{ data.Invoice?.InvoiceNumber }}</td>
          <td>{{ data.Invoice?.PONumber }}</td>

          <td>{{ data.Invoice?.InvoiceDate | date : "dd-MMM-yyyy" }}</td>
          <td>{{ data.Invoice?.InvoiceTotal }}</td>
          <td>{{ data.Products }}</td>
          <td>{{ data.Products.split(",").length }}</td>
          <td>{{ data.AddedBy }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td style="text-align: center" nzRight>
            <div
              class="badge badge-light-danger"
              *ngIf="!data.InspectionCompleted"
            >
              In-Progress
            </div>
            <div
              class="badge badge-light-success"
              *ngIf="data.InspectionCompleted"
            >
              Completed
            </div>
          </td>
          <td style="text-align: center" nzRight>
            <div
              class="badge badge-light-danger"
              *ngIf="!data.AllocationCompleted"
            >
              In-Progress
            </div>
            <div
              class="badge badge-light-success"
              *ngIf="data.AllocationCompleted"
            >
              Completed
            </div>
          </td>
          <td style="text-align: center" nzRight>
            <button
              routerLink="/home/stockdetails/{{ data.StockId }}"
              *ngIf="this.IsPageView"
              class="btn btn-sm btn-light-primary"
            >
              View
            </button>
            <button
              *ngIf="this.IsPageView && data.IsOpeningStock"
              routerLink="/home/updatestock/{{ data.StockId }}"
              class="btn btn-sm btn-light-danger"
            >
              Edit
            </button>
            <button
              routerLink="/home/stockinspection/{{ data.StockId }}"
              *ngIf="this.IsPageInspection"
              [disabled]="data.InspectionCompleted"
              class="btn btn-sm btn-light-primary"
            >
              Inspect Stock
            </button>
            <button
              routerLink="/home/stockproductallocation/{{ data.StockId }}"
              *ngIf="this.IsPageAllocation"
              [disabled]="!data.InspectionCompleted"
              class="btn btn-sm btn-light-primary"
            >
              Product Allocation
            </button>
            <button
              routerLink="/home/stockmanagerejecteditems/{{ data.StockId }}"
              *ngIf="this.IsPageManageRejected"
              [disabled]="!data.InspectionCompleted"
              class="btn btn-sm btn-light-primary"
            >
              Manage Rejected Products
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
