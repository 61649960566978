<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Conversion</nz-page-header-title>
      <nz-page-header-subtitle>Manage your conversion here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div>
      <form nz-form>
        <nz-form-item>
          <nz-form-control>
            <nz-form-label [nzSpan]="4">Select Conversion Type</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <nz-radio-group [(ngModel)]="conversionType" (ngModelChange)="switchInput()"
                [ngModelOptions]="{ standalone: true }">
                <label nz-radio-button nzValue="MTRtoKG">MTR to KG</label>
                <label nz-radio-button nzValue="KGtoMTR">KG to MTR</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="4">Product</nz-form-label>
          <nz-form-control [nzSpan]="8" nzErrorTip="Select Product">
            <nz-select nzShowSearch class="form-select" name="ProductId" [(ngModel)]="selectedproductId"
              [ngModelOptions]="{ standalone: true }" nzPlaceHolder="Choose" (ngModelChange)="onProductChange()">
              <nz-option *ngFor="let s of this.ProductList" [nzValue]="s.ProductId"
                [nzLabel]="s.ProductName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="4">Quantity</nz-form-label>
          <nz-form-control [nzSpan]="8">
            <input nz-input [value]="
                  selectedProductDetails.Unit ? selectedProductDetails.Unit : 0
                " disabled name="ProductCode" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="4">GSM</nz-form-label>
          <nz-form-control [nzSpan]="8">
            <input nz-input [value]="
                  selectedProductDetails.AvgGsm
                    ? selectedProductDetails.AvgGsm
                    : 0
                " disabled name="ProductCode" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="4">Width</nz-form-label>
          <nz-form-control [nzSpan]="8">
            <input nz-input [value]="
                  selectedProductDetails.WidthInMeter
                    ? selectedProductDetails.WidthInMeter
                    : 0
                " disabled name="ProductCode" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="4">Quantity In Meter</nz-form-label>
          <nz-form-control [nzSpan]="8">
            <input nz-input [ngModel]="quantityInMeter" (ngModelChange)="calculateTotalInKg($event)" type="number"
              [disabled]="disableMTRQty" name="QuantityInMeter" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="4">Quantity In Kg</nz-form-label>
          <nz-form-control [nzSpan]="8">
            <input nz-input [ngModel]="totalQuantityInKg" (ngModelChange)="calculateTotalInMTR($event)" type="number"
              [disabled]="disableKGQty" name="QuantityInKg" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>