<img src="/assets/img/logo-globe.jpg" style="display:none" (load)="CheckOnload()" />
<div *ngFor="let data of this.JumboInspection" style="margin-bottom:70px;margin-top: 10px;">
  <table class="companytable">
    <tr>
      <td>
        <div class="container1">
          <div class="image">
            <img src="/assets/img/logo-globe.jpg" />
          </div>
          <div class="text">
            <b style="text-transform: uppercase;">{{ Company.CompanyName }}</b>
            <p style="font-size: 12px;">{{Company.Address}}</p>
          </div>
        </div>
        <div class="company">
          <table style="border-top: 1px solid; border-bottom: 1px solid; margin-top: 10px ">
            <b>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>ROLL NUMBER:</b></td>
                <td style="padding-left:10px"><b>{{data.Code}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>ITEM NAME:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.ITEMNAME}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>P.CODE/GRAIN:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.PRODUCTCODE}} / {{JumboData.GRAIN}}</b></td>
              </tr>

              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>COLOUR/FINISH:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.COLOUR}} / {{JumboData.FINISH}} </b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>THICKNESS:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.THICKNESS}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>WIDTH:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.WIDTH}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>ROLL LENGTH:</b></td>
                <td style="padding-left:10px"><b>{{data.Quantity }} </b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"> <b>GRADE:</b></td>
                <td style="padding-left:10px"><b>{{data.Grade}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>INSPECTED BY:</b></td>
                <td style="padding-left:10px"><b>{{data.InspectedBy}}</b></td>
              </tr>
            </b>
          </table>
          <b>
            <p style="font-size:12px"><b>Please Examine The Goods Before Cutting. We Don't Accept Any Claim. On Cut
                Goods,
                In Case Of Complaints Please Return The Labels.</b></p>
          </b>
        </div>
      </td>
    </tr>
  </table>
</div>