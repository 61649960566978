import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SaleOrderModel } from '../../../Models/SalesOrderModel';
import { LoadingService } from '../../../Services/loadingService';
import { AuthService } from '../../../Services/auth.service';
import { FormulationCodeModel } from '../../../Models/FormulationCodeModel';

@Component({
  selector: 'app-formulationprint',
  templateUrl: './formulationprint.component.html',
  styleUrls: ['./formulationprint.component.css']
})
export class FormulationPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  ProductionList: SaleOrderModel[] = [];
  ProductionListOriginal: SaleOrderModel[] = [];
  FormulationCode!: FormulationCodeModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production";
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription;
  ProductionId = 0;
  // PrintStr = ''
  // EmbossingStr = ''
  // TumblingStr = ''
  // LacquerStr = ''
  // VaccumStr = ''
  TotalGSM: number = 0;
  permission = {
    View: false,
    SaleOrderView: false
  }

  constructor(public http: HttpClient, private route: ActivatedRoute, private loader: LoadingService, private auth: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProductionId = params["id"];
    })

    this.GetProductionDatabyid(this.ProductionId);

  }
  ngOnDestroy() {
    this.route$.unsubscribe();
  }

  GetProductionDatabyid(id: number) {
    // this.loader.show();
    //let url = this.ApiUrl + "production/getproductiondatabyid/" + id;
    let url = this.ApiUrl + "saleorder/getformulationcodebyid/" + id;
    this.http.get<FormulationCodeModel>(url).subscribe(res => {

      this.FormulationCode = res;

      console.log(this.FormulationCode)
      if (this.FormulationCode != null) {
        //this.Production?.Mixing.forEach(x => {
        //  x.WeightGsm = x.ProductionMixingRawMaterial.reduce((sum, current) => sum + parseInt(current.AvgGsm) * current.Quantity, 0);
        //})
        // this.PrintStr = this.FormulationCode..SaleOrderProduction.SaleOrderProductionPrint.map(x => x.Name).join(', ');
        // this.EmbossingStr = this.Production.SaleOrderProduction.SaleOrderProductionEmbossing.map(x => x.Name).join(', ');
        // this.TumblingStr = this.Production.SaleOrderProduction.SaleOrderProductionTumbling.map(x => x.Name).join(', ');
        // this.LacquerStr = this.Production.SaleOrderProduction.Lacquer.map(x => x.Name).join(', ');
        // this.VaccumStr = this.Production.SaleOrderProduction.SaleOrderProductionVacuum.map(x => x.Name).join(', ');

        this.FormulationCode.MixingData.forEach((x) => {
          if (x.MixingName.toLocaleLowerCase() == 'pre skin') {
            x.GSM = this.FormulationCode.PreSkinGsm
          } else if (x.MixingName.toLocaleLowerCase() == 'skin') {
            x.GSM = this.FormulationCode.SkinGsm
          } else if (x.MixingName.toLocaleLowerCase() == 'adhesive') {
            x.GSM = this.FormulationCode.AdhesiveGsm
          } else if (x.MixingName.toLocaleLowerCase() == 'foam') {
            x.GSM = this.FormulationCode.FoamGsm
          }
        })

        this.TotalGSM = this.FormulationCode.PreSkinGsm + this.FormulationCode.SkinGsm + this.FormulationCode.AdhesiveGsm + this.FormulationCode.FoamGsm
        this.loader.hide();
        setTimeout(function () {
          window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
      }
    });
  }
}
