<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Mixing List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your mixing here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" routerLink="/home/production/mixing/add"  *ngIf="this.permission.Add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search Mixing" [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i> Export</button>
    </div>

    <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;"  [nzScroll]="{ x: '1200px',y:'515px' }" #basicTable [nzData]="this.ProductionList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>

          <th nzWidth="150px" nzLeft>Mixing Name</th>
          <th nzWidth="80px">Wastage</th>
          <th nzWidth="100px">Weight Gsm</th>
          <th nzWidth="80px">Total</th>
          <th nzWidth="150px">Added Date</th>
          <th nzWidth="200px">Added By</th>
          <!--<th nzWidth="100px" style="text-align:center" nzRight>Status</th>-->
          <th nzWidth="100px" style="text-align:center" nzRight  *ngIf="permission.Add || permission.Delete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft>{{ data.MixingName }}</td>
          <td>{{ data.Wastage }}</td>
          <td>{{ data.WeightGsm }}</td>
          <td>{{ data.Total }}</td>
          <td>{{ data.AddedDate |  DatetimeConverter  }}</td>
          <td>{{ data.AddedBy }}</td>
          <!--<td nzRight style="text-align:center">{{ data.ProductionStatus }}</td>-->
          <td nzRight style="text-align:center" *ngIf="CheckUserAddPermission(data.AddedBy )">
            <button class="btn btn-sm btn-light-primary" routerLink="/home/production/mixing/add/{{data.MixingId}}">Edit</button>
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>
          </td>

          <!--<td>
  <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
  <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>
        </td>-->
        </tr>
      </tbody>
    </nz-table>




  </div>
</div>



<!--<nz-drawer [nzClosable]="true"
           [nzVisible]="isVisible"
           nzPlacement="right"
           [nzWidth]="1000"
           nzTitle="Production details"
           (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <nz-divider nzText="Order List"></nz-divider>
    <nz-table *ngIf="Production" nzSize="small" #basicTable3 [nzData]="['']" nzBordered nzShowPagination="false">
      <thead>
        <tr>
          <th nzWidth="50px">S.No</th>
          <th>Order No.</th>
          <th>Product</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.Production.ProductionOrder;let i=index">
          <td nzWidth="20px">{{i+1}}</td>
          <td>{{ data.SaleOrderNo }}</td>
          <td>{{Production.ProductName}}</td>
          <td>{{ data.Value}}</td>
        </tr>
      </tbody>
    </nz-table>
    <nz-divider nzText="Production Details"></nz-divider>
    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Production" nzSize="small" [nzColumn]="4">
      <nz-descriptions-item nzTitle="Product Name" [nzSpan]="2" nzColon="true"><b>{{Production.ProductName}}</b> </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Product Code" [nzSpan]="2"><b>{{Production.ProductCode}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="MFG Product Name" [nzSpan]="2"><b>{{Production.ManufacturingProductName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Unit"><b>{{Production.Unit}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Lot"><b>{{Production.Lot}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Batch"> <b>{{Production.Batch}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Order Quantity"><b>{{Production.OrderQuantity}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="MFG Quantity"><b>{{Production.ManufacturingQuantity}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Color"><b>{{Production.ColorName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Barcode"><b>{{Production.Barcode}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Added Date"><b>{{Production.AddedDate| date: 'dd-MMM-yyyy'}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Added By"><b>{{Production.AddedBy}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Status"><b>{{Production.ProductionStatus}}</b></nz-descriptions-item>
    </nz-descriptions>
    <nz-divider nzText="Raw Material"></nz-divider>
    <nz-table nzSize="small" *ngIf="Production" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
      <thead>
        <tr>

          <th nzWidth="50px">S.No</th>
          <th>Product</th>
          <th>Code</th>
          <th nzWidth="100px">Unit</th>
          <th nzWidth="100px">Quantity</th>
          <th nzWidth="100px">Unit Cost</th>
          <th nzWidth="100px">Total Cost</th>-->
          <!-- <th nzWidth="20%">Action</th> -->
        <!--</tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of Production.ProductionRawMaterial;let i=index">

          <td nzWidth="20px">{{i+1}}</td>
          <td>{{ data.ProductName }}</td>
          <td>
          </td>
          <td>{{ data.Unit }}</td>
          <td>
            {{ data.Quantity }}

          </td>
          <td>{{ data.PerUnitCost }}</td>
          <td>{{ data.TotalCost }}</td>

        </tr>

      </tbody>

    </nz-table>

    <nz-divider nzText="Element List"></nz-divider>
    <nz-table nzSize="small" *ngIf="Production" style="width:100%;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
      <thead>
        <tr>

          <th nzWidth="50px">S.No</th>
          <th>Element</th>
          <th nzWidth="120px">Value</th>
          <th nzWidth="100px">Unit Cost</th>
          <th nzWidth="100px">Total Cost</th>-->


          <!-- <th nzWidth="20%">Action</th> -->
        <!--</tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of Production.ProductionElement;let i=index">

          <td nzWidth="20px">{{i+1}}</td>
          <td>{{ data.ElementName }}</td>
          <td>{{ data.Value }} </td>
          <td>{{ data.PerUnitCost }}</td>
          <td>{{ data.TotalCost }}</td>


        </tr>

      </tbody>

    </nz-table>
  </ng-container>
</nz-drawer>-->
