import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { WorkPlanPrintModel, WorkPlanMasterModel, WorkPlanOrderModel, WorkPlanSearchModel } from 'src/PmsUIApp/Models/WorkPlanModel';
import { LoadingService } from '../../Services/loadingService';
import { SaleFormulationCodeMasterModel, SaleOrderModel, SaleOrderProductionMixingRawMaterialModel, SaleOrderProductionModel, SaleOrderProductionRawMaterialModel } from '../../Models/SalesOrderModel';
import { AdminStoreModel, FactoryWorkersModel, StoreModel } from '../../Models/MasterModel';
import { UserInfo } from '../../Authentication/UserInfo';
import * as moment from 'moment';
import { FormulationCodeMixingModel, FormulationCodeModel, FormulationProductCalculatePasteReqRequestModel, FormulationProductCalculatePasteReqResponseModel } from '../../Models/FormulationCodeModel';
import { CustomerModel } from '../../Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { Router } from '@angular/router';
import { ESalesOrderStatus, Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { UserRoleMasterModel } from 'src/PmsUIApp/Models/UserRoleModel';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';

@Component({
  selector: 'app-workplan-list',
  templateUrl: './workplan-list.component.html',
  styleUrls: ['./workplan-list.component.css']
})
export class WorkplanListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  WorkPlanListOriginal: WorkPlanMasterModel[] = [];
  SelectedMixing!: FormulationCodeMixingModel;
  IsPopUpOpen = false;
  PurchaseOrder!: WorkPlanMasterModel;
  WorkPlanModel!: WorkPlanMasterModel;
  isVisible = false;
  isSaleOrderVisible = false;
  ProductRawList: ProductModel[] = [];
  ProductOriginalList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  selectedproduct: ProductModel = new ProductModel;
  selectedQuantity: number = 0;
  IsBaseMaterial: boolean = false;
  isVisibleInspection = false;
  isUnitConversionNotDone = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  searchValue = '';
  visible = false;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  InspectionCompletedOrders: WorkPlanOrderModel[] = [];
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  AdminStoreList: AdminStoreModel[] = [];
  StoreListOriginal: StoreModel[] = [];
  StoreList: StoreModel[] = [];
  SelectedOrderNo: string = '';
  SelectedFabricId: number = 0;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  exportoptions = {
    headers: [
      'WorkPlanNo',
      'ProductionDetails',
      'LotNo',
      'BatchNo',
      'WorkPlanDate',

    ],
  };
  fields: WorkPlanPrintModel = new WorkPlanPrintModel();
  exportfields: WorkPlanPrintModel[] = [];
  Orderlist!: SaleOrderModel;
  isValidDate: any;
  FabricName: string = '';
  FabricQTY!: number;
  FabricGsm: number = 0;
  FromStoreID: number = 0;
  IsSalesOrderWorkplanLoaded = false
  request: WorkPlanSearchModel = new WorkPlanSearchModel;
  CustomerList: CustomerModel[] = [];
  FormulationCodeList: FormulationCodeModel[] = [];
  count: number;
  isOpenWorkplanReview: boolean = false;
  workplantitle: string = '';
  IsInspectionCompleted: boolean;
  InspectedOrdersCount: WorkPlanOrderModel[] = [];
  UserRoleList: UserRoleMasterModel[] = [];
  UserRoleListFiltered: UserRoleMasterModel[] = [];
  PUPVCTypeList: any = [
    {
      "Text": "All",
      "Value": 'All'
    },
    {
      "Text": "GZ(PVC)",
      "Value": 'GZ-'
    },
    {
      "Text": "GZY(PU)",
      "Value": 'GZY-'
    }
  ]
  WorkPlanStatusList: any = [
    {
      "Text": "Reviewed",
      "Value": true
    },
    {
      "Text": "Pending",
      "Value": false
    }
  ]
  PUPVCType: any = 'All';
  permission = {
    View: false,
    Add: false,
    Delete: false,
    PreInspectionCancel: false,
    Approval: false,
    SaleOrderEdit: false
  }
  status: any = [
    {
      "Text": "NotYet",
      "Value": 0
    },
    {
      "Text": "WorkPlan",
      "Value": 1
    },
    {
      "Text": "Inspection",
      "Value": 2
    },
    {
      "Text": "RawMaterialRequested",
      "Value": 3
    },
    {
      "Text": "RawMaterialIssued",
      "Value": 4
    },
    {
      "Text": "ProductionStarted",
      "Value": 5
    },
    {
      "Text": "Mixing",
      "Value": 6
    },
    {
      "Text": "InJumbo",
      "Value": 7
    },
    {
      "Text": "MoveToPostProcess",
      "Value": 8
    },
    {
      "Text": "PrintAssigned",
      "Value": 9
    },
    {
      "Text": "PrintInProcess",
      "Value": 10
    },
    {
      "Text": "PrintCompleted",
      "Value": 11
    },
    {
      "Text": "EmbossingAssigned",
      "Value": 12
    },
    {
      "Text": "EmbossingInProcess",
      "Value": 13
    },
    {
      "Text": "EmbossingCompleted",
      "Value": 14
    },
    {
      "Text": "VacuumAssigned",
      "Value": 15
    },
    {
      "Text": "VacuumInProcess",
      "Value": 16
    },
    {
      "Text": "VacuumCompleted",
      "Value": 17
    },
    {
      "Text": "LacquerAssigned",
      "Value": 18
    },
    {
      "Text": "LacquerInProcess",
      "Value": 19
    },
    {
      "Text": "LacquerCompleted",
      "Value": 20
    },
    {
      "Text": "TumblingAssigned",
      "Value": 21
    },
    {
      "Text": "TumblingInProcess",
      "Value": 22
    },
    {
      "Text": "TumblingCompleted",
      "Value": 23
    },
    {
      "Text": "JumboInspection",
      "Value": 24
    },
    {
      "Text": "MoveToDispatch",
      "Value": 25
    },
    {
      "Text": "PartialDispatchReady",
      "Value": 26
    },
    {
      "Text": "PartialDispatchCompleted",
      "Value": 27
    },
    {
      "Text": "DispatchReady",
      "Value": 28
    },
    {
      "Text": "DispatchCompleted",
      "Value": 30
    },
    {
      "Text": "LiningOrderMerged",
      "Value": 101
    }
  ];
  FabricColorName: string;
  CancellationRequest = {
    Reason: '',
    SaleOrderId: 0
  };
  isInspectionCancelVisible: boolean = false;
  InspectionCancelForm!: UntypedFormGroup;
  InspectionCancelBtnToolTipTxt: string = '';
  FormulationPasteReqRecalculateRequest: FormulationProductCalculatePasteReqRequestModel = new FormulationProductCalculatePasteReqRequestModel;
  FormulationPasteReqRecalculateResponse: FormulationProductCalculatePasteReqResponseModel;
  disableInspectionBtn: boolean = false;
  SaveInspectionToolTipText: string = "Click to Complete Pre-Inspection and Print Job Card."
  disableAll: boolean = false;
  checkedStatus: { [key: string]: boolean } = {};
  totalCalculatedQuantities: { [mixingName: string]: number } = {};
  totalCalculatedSCQuantities: { [mixingName: string]: number } = {};
  totalMixingBaseQuantities: { [mixingName: string]: number } = {};
  FactoryWorkersList: FactoryWorkersModel[];
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loader: LoadingService, private auth: AuthService, private router: Router,
    private soDrawer: SoDrawerService

  ) { }

  expandSet = new Set<number>();
  ngOnInit() {
    this.UserRoleListFiltered = UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase() == 'admin' || x.UserRoleName.toLowerCase() == 'super admin');
    this.permission.View = this.auth.CheckResponsibility(Modules.Workplan, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Workplan, Responsibility.Add);
    this.permission.Approval = this.auth.CheckResponsibility(Modules.Workplan, Responsibility.Approval);
    this.permission.SaleOrderEdit = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Edit);
    this.permission.PreInspectionCancel = this.auth.CheckResponsibility(Modules.PreInspection, Responsibility.Cancel);
    this.request.FromDate = new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 15));
    this.request.ToDate = new Date(new Date().setHours(23, 59, 59));
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllCustomer()
    this.GetAllFormulationCode()
    this.GetAllWorkPlanReport()
    //this.GetAllWorkPlanList();
    this.GetAllStore();
    this.GetAllProducts();
    this.InspectionCancelForm = this.fb.group({
      reason: [null, [Validators.required, Validators.maxLength(180)]]
    });
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  GetAllCustomer() {

    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  GetAllWorkPlanList() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'workplan/getallworkplan';

    this.http.get<any>(url).subscribe(
      (res) => {
        this.WorkPlanList = res.Data;
        this.WorkPlanListOriginal = res.Data;
        this.isTableLoading = false;
        this.exportfields = [];
        this.WorkPlanList.forEach((x) => {
          this.fields = new WorkPlanMasterModel();
          this.fields.WorkPlanNo = x.WorkPlanNo;
          this.fields.ProductionDetails = x.ProductionDetails;
          this.fields.LotNo = x.LotNo;
          this.fields.BatchNo = x.BatchNo;
          this.fields.WorkPlanDate = x.WorkPlanDate;
          // this.fields.WorkPlanOrder = x.WorkPlanOrder;       
          // this.fields.AddedDate = x.AddedDate;
          // this.fields.AddedBy = x.AddedBy;
          this.exportfields.push(this.fields);

        });

        this.isTableLoading = false;

      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllWorkPlanList(); }
      }
    );
  }
  GetAllWorkPlanReport() {
    if (isNaN(this.request.CustomerId) || this.request.CustomerId == null) {
      this.request.CustomerId = 0;
    }
    if (isNaN(this.request.FormulationCodeId) || this.request.FormulationCodeId == null) {
      this.request.FormulationCodeId = 0;
    }
    if (isNaN(this.request.Status)) {
      this.request.Status = null;
    }
    this.isTableLoading = true;
    let url = this.ApiUrl + 'workplan/getallworkplanreport';

    this.http.post<any>(url, this.request).subscribe(
      (res) => {
        this.WorkPlanList = res.Data;
        this.WorkPlanListOriginal = res.Data;
        this.isTableLoading = false;
        this.exportfields = [];
        this.WorkPlanList.forEach((x) => {
          this.fields = new WorkPlanMasterModel();
          this.fields.WorkPlanNo = x.WorkPlanNo;
          this.fields.ProductionDetails = x.ProductionDetails;
          this.fields.LotNo = x.LotNo;
          this.fields.BatchNo = x.BatchNo;
          this.fields.WorkPlanDate = x.WorkPlanDate;
          // this.fields.WorkPlanOrder = x.WorkPlanOrder;       
          // this.fields.AddedDate = x.AddedDate;
          // this.fields.AddedBy = x.AddedBy;
          this.exportfields.push(this.fields);

        });
        var filterworkplanlist = [];
        if (this.PUPVCType != 'All') {
          if (this.PUPVCType == 'GZ-') {
            this.WorkPlanList = this.WorkPlanList.filter(x => {
              x.WorkPlanOrder.forEach(y => {
                if (y.SaleOrder.SaleFormulationCode?.substring(0, 3) == this.PUPVCType) {
                  filterworkplanlist.push(x);
                  return true;
                } else {
                  return false;
                }
              })
            }
            );
          }
          else if (this.PUPVCType == 'GZY-') {
            this.WorkPlanList = this.WorkPlanList.filter(x => {
              x.WorkPlanOrder.forEach(y => {
                if (y.SaleOrder.SaleFormulationCode?.substring(0, 4) == this.PUPVCType) {
                  filterworkplanlist.push(x);
                  return true;
                } else {
                  return false;
                }
              })
            }
            );
          }
          this.WorkPlanList = filterworkplanlist;
        }
        this.isTableLoading = false;

      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllWorkPlanList(); }
      }
    );
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'workplan-export' + exportdate,
        this.exportoptions
      );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  //dummy functions
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  OpenWorkplanReviewPop(data: any) {
    this.workplantitle = "Review WorkPlan details";
    this.isOpenWorkplanReview = true;
    this.OpenViewPop(data);
  }

  OpenWorkplanviewPop(data: any) {
    this.workplantitle = "WorkPlan details";
    this.isOpenWorkplanReview = false;
    this.OpenViewPop(data);
  }
  SaleOrderReviewOpen(data: any) {
    if (data.SaleOrder.Status == "WorkPlan") {
      return true;
    }
    return false;
  }

  OpenViewPop(data: any) {
    this.loader.show();

    let url = this.ApiUrl + "workplan/getallworkplanbyid/" + data.WorkPlanId;
    this.http.get<any>(url).subscribe(res => {

      this.WorkPlanModel = res.Data;
      this.WorkPlanModel.WorkPlanOrder.forEach((element) => {
        element.TotalJumboQty = element.WorkPlanJumbo.reduce((sum, current) => sum + parseFloat(current.JumboRolQty?.toString()), 0) ?? 0
        element.TotalWeight = element.WorkPlanJumbo.reduce((sum, current) => sum + parseFloat(current.Weight?.toString()), 0) ?? 0

      });
      this.isVisible = true;
      this.loader.hide();
    });
  }
  OpenInspectionPop(data: any) {
    //this.loader.show();
    this.IsSalesOrderWorkplanLoaded = false;
    this.SelectedWorkPlanOrder = [];
    this.InspectionCompletedOrders = [];
    this.isVisibleInspection = true;
    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + data.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      this.SelectedWorkPlanOrder = res
      this.InspectedOrdersCount = res.filter(x => x.SaleOrder.Status.toString() == "WorkPlan");
      //this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "WorkPlan");
      console.log(res);
      this.IsSalesOrderWorkplanLoaded = true;
      this.loader.hide();
    }, res => { });
  }
  OpenSaleOrderViewPop(data: any) {
    this.soDrawer.SaleOrderId = data.SaleOrderId;
    this.soDrawer.show();
  }

  // select order drop down change event
  OnWorkPlanOrderChange() {
    this.loader.show();
    this.IsInspectionCompleted = false;
    this.GetSaleOrderDetails(parseInt(this.SelectedOrderNo))
    this.GetAllFactoryWorkers()

  }
  printMixing(data: WorkPlanOrderModel) {

    window.open(`${window.location.origin}/production/mixingprint/` + data.OrderId);
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  handleCancelInspection() {
    this.isVisibleInspection = false;
    this.IsInspectionCompleted = false;
    this.Orderlist = null;
    this.SelectedWorkPlanOrder = [];
    this.SelectedOrderNo = '';
  }
  handleSaleOrderCancel() {
    this.isSaleOrderVisible = false;
    this.Orderlist = null;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }




  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.WorkPlanListOriginal;
    var res2 = this.WorkPlanListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: WorkPlanMasterModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.WorkPlanList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.WorkPlanList = res.filter(
        (item: WorkPlanMasterModel) =>
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.WorkPlanNo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductionDetails?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.LotNo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.BatchNo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())

      )
    }

  }

  // old
  _calculateFormulaQuantity() {
    this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      let totalquantity = element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
      let totalCalculatedForMixing = 0;
      let totalCalculatedSCForMixing = 0;
      let totalBaseQuantityForMixing = 0;
      element.MixingRawMaterial.forEach(raw => {
        if (element.MixingName.trim().toLowerCase() == 'pre skin') {
          raw.CalculatedQuantity = parseFloat(((element.PreSkinGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
          raw.CalculatedSCQuantity = raw.CalculatedSCQuantity == 0 ? 0 : parseFloat(((element.PreSkinGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
        } else if (element.MixingName.trim().toLowerCase() == 'skin') {
          if (raw.IsBaseMaterial) {
            raw.CalculatedQuantity = parseFloat(((element.SkinGsmPasteReq / totalquantity) * 100).toFixed(2))
            raw.CalculatedSCQuantity = raw.CalculatedSCQuantity == 0 ? 0 : parseFloat(((element.SkinGsmPasteReq / totalquantity) * 100).toFixed(2))
          }
          else {
            raw.CalculatedQuantity = parseFloat(((element.SkinGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
            raw.CalculatedSCQuantity = raw.CalculatedSCQuantity == 0 ? 0 : parseFloat(((element.SkinGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
          }
        }
        else if (element.MixingName.trim().toLowerCase() == 'foam') {
          raw.CalculatedQuantity = parseFloat(((element.FoamGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
          raw.CalculatedSCQuantity = raw.CalculatedSCQuantity == 0 ? 0 : parseFloat(((element.FoamGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
        }
        else if (element.MixingName.trim().toLowerCase() == 'adhesive') {
          raw.CalculatedQuantity = parseFloat(((element.AdhesiveGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
          raw.CalculatedSCQuantity = raw.CalculatedSCQuantity == 0 ? 0 : parseFloat(((element.AdhesiveGsmPasteReq / totalquantity) * raw.Quantity).toFixed(2));
        }
        else {
          raw.CalculatedQuantity = parseFloat(raw.Quantity.toFixed(2));
          raw.CalculatedSCQuantity = raw.CalculatedSCQuantity == 0 ? 0 : parseFloat(raw.Quantity.toFixed(2));
        }
        totalCalculatedForMixing += raw.CalculatedQuantity;
        totalCalculatedSCForMixing += raw.CalculatedSCQuantity;
        totalBaseQuantityForMixing += raw.Quantity;
      })
      this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedForMixing.toFixed(2));
      this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedSCForMixing.toFixed(2));
      this.totalMixingBaseQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalBaseQuantityForMixing.toFixed(2));
    })
  }

  calculateFormulaQuantity(isRecalculate: boolean = false) {
    this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      const totalQuantity = element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0;
      // set total Base quantity
      this.totalMixingBaseQuantities[element.MixingName.trim().toLowerCase()] = totalQuantity;

      // set Calculated quantity
      element.MixingRawMaterial.forEach(raw => {
        const percentage = raw.Quantity / totalQuantity;
        let key = '';
        switch (element.MixingName.trim().toLowerCase()) {
          case 'pre skin':
            key = 'PreSkinGsmPasteReq';
            break;
          case 'skin':
            key = 'SkinGsmPasteReq';
            break;
          case 'foam':
            key = 'FoamGsmPasteReq';
            break;
          case 'adhesive':
            key = 'AdhesiveGsmPasteReq';
            break;
          default:
            raw.CalculatedQuantity = parseFloat(raw.Quantity.toFixed(2));
            break
        }
        if (key != '') {
          let diff: number = parseFloat(element.MixingRawMaterial.filter(m => m.Perishable).reduce((s, m) => s + (m.CalculatedQuantity - m.CalculatedSCQuantity), 0).toFixed(2));
          raw.CalculatedQuantity = parseFloat((percentage * (element[key] + diff)).toFixed(2));
        }
      });
      this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + current.CalculatedQuantity, 0).toFixed(2)) ?? 0;

      // set SC calculated quantity
      const totalSCQuantity = element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Scquantity?.toString()), 0) ?? 0;
      element.MixingRawMaterial.forEach(raw => {
        const percentage = raw.Quantity / totalSCQuantity;
        var key = ''
        switch (element.MixingName.trim().toLowerCase()) {
          case 'pre skin':
            key = 'PreSkinGsmSCPasteReq'; // 'PreSkinGsmSCPasteReq';
            break;
          case 'skin':
            key = 'SkinGsmSCPasteReq'; // 'SkinGsmSCPasteReq';
            break;
          case 'foam':
            key = 'FoamGsmSCPasteReq'; // 'FoamGsmSCPasteReq';
            break;
          case 'adhesive':
            key = 'AdhesiveGsmSCPasteReq'; // 'AdhesiveGsmSCPasteReq';
            break;
          default:
            raw.CalculatedSCQuantity = parseFloat(raw.Quantity.toFixed(2));
            break
        }

        if (key != '' && !raw.Perishable) {
          let diff = element.MixingRawMaterial.filter(m => m.Perishable).reduce((s, m) => s + (m.CalculatedQuantity - m.CalculatedSCQuantity), 0).toFixed(2);
          raw.CalculatedSCQuantity = parseFloat((percentage * (element[key] + parseFloat(diff))).toFixed(2));
        }
      });
      this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + current.CalculatedSCQuantity, 0).toFixed(2)) ?? 0;

    })
  }

  calculateFormulaQuantityAfterInspection() {
    this.Orderlist.SaleOrderProduction.InspectionFormulationMixing.forEach(element => {
      let totalCalculatedForMixing = 0;
      let totalCalculatedSCForMixing = 0;
      let totalBaseQuantityForMixing = 0;
      element.MixingRawMaterial.forEach(raw => {
        totalCalculatedForMixing += raw.Quantity;
        totalCalculatedSCForMixing += raw.Scquantity;
        totalBaseQuantityForMixing += raw.BaseQuantity;
      })
      this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedForMixing.toFixed(2));
      this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedSCForMixing.toFixed(2));
      this.totalMixingBaseQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalBaseQuantityForMixing.toFixed(2));
    })
  }
  ReCalculateTotalPerMixing() {
    // this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
    // 	let totalCalculatedForMixing = 0;
    // 	let totalCalculatedSCForMixing = 0;
    // 	element.MixingRawMaterial.forEach(raw => {
    // 		totalCalculatedForMixing += raw.CalculatedQuantity;
    // 		totalCalculatedSCForMixing += raw.CalculatedSCQuantity;
    // 	})

    // 	let diff = totalCalculatedForMixing - totalCalculatedSCForMixing;
    // 	let key = '';
    // 	switch (element.MixingName.trim().toLowerCase()) {
    // 		case 'pre skin':
    // 			key = 'PreSkinGsmPasteReq'
    // 			break;
    // 		case 'skin':
    // 			key = 'SkinGsmPasteReq'
    // 			break;
    // 		case 'foam':
    // 			key = 'FoamGsmPasteReq'
    // 			break;
    // 		case 'adhesive':
    // 			key = 'AdhesiveGsmPasteReq'
    // 			break;
    // 	}

    // 	if (key != '') {
    // 		element[key] = element[key] + diff
    // 	}

    // })
    this.calculateFormulaQuantity(true)
    // this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
    // 	let totalCalculatedForMixing = 0;
    // 	let totalCalculatedSCForMixing = 0;
    // 	let totalBaseQuantityForMixing = 0;
    // 	element.MixingRawMaterial.forEach(raw => {
    // 		totalCalculatedForMixing += raw.CalculatedQuantity;
    // 		totalCalculatedSCForMixing += raw.CalculatedSCQuantity;
    // 		totalBaseQuantityForMixing += raw.Quantity;
    // 	})
    // 	this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedForMixing.toFixed(2));
    // 	this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedSCForMixing.toFixed(2));
    // 	this.totalMixingBaseQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalBaseQuantityForMixing.toFixed(2));
    // });
    // this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
    // 	element.StdPasteRequirementQuantity = this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()];
    // 	element.StdPasteRequirementScquantity = this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()];
    // });
  }
  SetTotalProductionQty() {
    if (this.Orderlist.SaleOrderProduction.ExtraProduction > 0) {
      this.FormulationPasteReqRecalculateRequest.TotalProductionQty = parseFloat((this.Orderlist.SaleOrderProduction.OrderQuantity + (this.Orderlist.SaleOrderProduction.OrderQuantity * this.Orderlist.SaleOrderProduction.ExtraProduction) / 100).toFixed(2));
      this.disableInspectionBtn = true;
      this.calculateFabricQTY();
      this.SetSaveInspectionToolTip();
    }
    else {
      this.FormulationPasteReqRecalculateRequest.TotalProductionQty = this.Orderlist.SaleOrderProduction.OrderQuantity
      this.calculateFabricQTY();
    }
  }
  SetSaveInspectionToolTip() {
    if (this.disableInspectionBtn == false) {
      this.SaveInspectionToolTipText = "Click to Complete Pre-Inspection and Print Job Card.";
    }
    else {
      this.SaveInspectionToolTipText = "First Click on Re-Calculate because Extra Production is changed.";
    }
  }
  ResetSaveInspection() {
    this.disableInspectionBtn = true;
    this.SetSaveInspectionToolTip();
  }
  GetPasteReqQtyToRecalculate() {
    if (this.Orderlist.SaleOrderProduction.ExtraProduction == null || this.Orderlist.SaleOrderProduction.ExtraProduction == undefined) {
      this.Orderlist.SaleOrderProduction.ExtraProduction = 0;
      this.FormulationPasteReqRecalculateRequest.TotalProductionQty = this.Orderlist.SaleOrderProduction.OrderQuantity;
    }
    if (this.Orderlist.SaleOrderProduction.LMConstant == 0 || this.Orderlist.SaleOrderProduction.LMConstant == null) {
      this.alertService.error("LM Constant cannot be 0");
    }
    var sop = new SaleOrderProductionModel;
    sop.PreSkinGsm = this.Orderlist.SaleOrderProduction.PreSkinGsm;
    sop.SkinGsm = this.Orderlist.SaleOrderProduction.SkinGsm;
    sop.FoamGsm = this.Orderlist.SaleOrderProduction.FoamGsm;
    sop.AdhesiveGsm = this.Orderlist.SaleOrderProduction.AdhesiveGsm;
    sop.LMConstant = this.Orderlist.SaleOrderProduction.LMConstant;
    this.FormulationPasteReqRecalculateRequest.SaleOrderProduction = sop;

    this.loader.show();
    let url = this.ApiUrl + "saleorder/getformulationcodecalculatepastereqquantity";
    this.http.post<FormulationProductCalculatePasteReqResponseModel>(url, this.FormulationPasteReqRecalculateRequest).subscribe({
      next: (res) => {
        this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
          element.MixingRawMaterial.forEach(raw => {
            if (element.MixingName.trim().toLowerCase() == 'pre skin') {
              element.PreSkinGsmPasteReq = res.PreSkinGsmPasteReq;
              element.PreSkinGsmSCPasteReq = res.PreSkinGsmSCPasteReq;
            } else if (element.MixingName.trim().toLowerCase() == 'skin') {
              element.SkinGsmPasteReq = res.SkinGsmPasteReq;
              element.SkinGsmSCPasteReq = res.SkinGsmSCPasteReq;
            }
            else if (element.MixingName.trim().toLowerCase() == 'foam') {
              element.FoamGsmPasteReq = res.FoamGsmPasteReq;
              element.FoamGsmSCPasteReq = res.FoamGsmSCPasteReq;
            }
            else if (element.MixingName.trim().toLowerCase() == 'adhesive') {
              element.AdhesiveGsmPasteReq = res.AdhesiveGsmPasteReq
              element.AdhesiveGsmSCPasteReq = res.AdhesiveGsmSCPasteReq;
            }
          })
        });
        this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
          element.StdPasteRequirementQuantity = this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()];
          element.StdPasteRequirementScquantity = this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()];
        }
        );
        this.loader.hide();
        this.calculateFormulaQuantity();
        this.disableInspectionBtn = false;
        this.SetSaveInspectionToolTip();
      },
      error: (err) => { this.loader.hide() }
    });
  }

  // get order from SaleOrderId
  GetSaleOrderDetails(SaleOrderId: number) {
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + SaleOrderId;
    this.http.get<any>(url).subscribe(res => {

      this.Orderlist = res;
      if (this.Orderlist.Status.toString() != "WorkPlan") {
        this.IsInspectionCompleted = true
      }
      this.calclulateTotalSalePrice();
      this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
      this.Orderlist.SaleOrderProduction.LMConstant = 1.5;
      this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach((element) => {

        this.Orderlist.SaleOrderProduction.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
      });
      this.Orderlist.SaleOrderProduction.MixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
      this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
        this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(element.Total) ? 0 : element.Total;
        this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(parseInt(element.WeightGsm?.toString())) ? 0 : parseInt(element.WeightGsm.toString());
      })
      if (this.IsInspectionCompleted) {
        this.calculateFormulaQuantityAfterInspection();
      }
      else {
        this.calculateFormulaQuantity();
      }

      this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingGSMTotal + this.Orderlist.SaleOrderProduction.RawGSMTotal;
      var totalprint = this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalprint);
      var totalEmbossing = this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalEmbossing);
      var totalVaccum = this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalVaccum);
      var totalTumbling = this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(totalTumbling);
      //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      //console.log(totalLacquer);
      var totalElement = this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      console.log(isNaN(totalElement) ?? 0);
      var totalRaw = this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce((sum, current) => sum + parseFloat(current.TotalCost?.toString()), 0) ?? 0
      console.log(totalRaw);
      this.Orderlist.GrandTotal = parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) + parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
        parseFloat(isNaN(totalprint) == true ? "0" : totalprint.toString()) +
        parseFloat(isNaN(totalEmbossing) == true ? "0" : totalEmbossing.toString()) +
        parseFloat(isNaN(totalVaccum) == true ? "0" : totalVaccum.toString()) +
        parseFloat(isNaN(totalTumbling) == true ? "0" : totalTumbling.toString()) +
        /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
        parseFloat(isNaN(totalElement) == true ? "0" : totalElement.toString()) +
        parseFloat(totalRaw.toString());
      this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
      this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
      this.SelectedFabricId = this.Orderlist.SaleOrderProduction.FabricProductId > 0 ? this.Orderlist.SaleOrderProduction.FabricProductId : 0;
      this.FabricQTY = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? 0 : this.FormulationPasteReqRecalculateRequest.TotalProductionQty;
      this.SetTotalProductionQty();
      this.SetSaveInspectionToolTip();
      this.ResetSaveInspection();
      this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(i => {
        this.checkedStatus[i.MixingName] = false;
      });
      console.log(this.Orderlist);
      this.loader.hide();
    });


  }
  calculateFabricQTY() {
    debugger
    if (this.Orderlist.SaleOrderProduction.FabricProductId != 0 && this.Orderlist.SaleOrderProduction.FabricProductId != null) {
      this.GetConditionalFabricList(this.Orderlist.SaleOrderProduction.FabricProductId);
      //this.ResetSaveInspection();
      if (this.IsInspectionCompleted) {
        this.isUnitConversionNotDone = false;
        this.FabricGsm = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm;
        this.Orderlist.FormulationFabricProductUnit = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductUnit;
        this.Orderlist.FormulationFabricProducWidthInMeter = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter;
        this.Orderlist.FormulationFabricProductId = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductId;
        this.Orderlist.FormulationFabricProductName = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductName;
      }
      else {
        this.isUnitConversionNotDone = false;
        var currentSelectedFabric = this.ProductFilteredList.filter(x => x.ProductId == this.SelectedFabricId)[0];
        if ((this.SelectedFabricId == 0 && this.Orderlist.FormulationFabricProductId != 0) && currentSelectedFabric == null || currentSelectedFabric == undefined) {
          this.loader.hide();
          this.alertService.error("Fabric Product provided for the Sale Order is either deleted or doesn't exist. Select correct Fabric by editing Sale Order to proceed.");
          return;
        }
        this.FabricGsm = isNaN(parseFloat(currentSelectedFabric?.AvgGsm)) ? 0 : parseFloat(currentSelectedFabric?.AvgGsm);

        this.Orderlist.FormulationFabricProductUnit = currentSelectedFabric.Unit;
        this.Orderlist.FormulationFabricProducWidthInMeter = currentSelectedFabric.WidthInMeter;
      }
      if (this.Orderlist.FormulationFabricProductId == 0 || this.Orderlist.FormulationFabricProductId == null) {
        this.FabricQTY = 0
      }
      else if (this.Orderlist.FormulationFabricProductUnit == "Mtrs") {
        this.FabricQTY = this.FormulationPasteReqRecalculateRequest.TotalProductionQty;
      }
      else {
        if (this.Orderlist.FormulationFabricProducWidthInMeter != null && this.Orderlist.FormulationFabricProducWidthInMeter > 0 && this.FabricGsm != null && this.FabricGsm > 0) {
          this.FabricQTY = parseFloat(((this.FormulationPasteReqRecalculateRequest.TotalProductionQty * this.Orderlist.FormulationFabricProducWidthInMeter * this.FabricGsm) / 1000).toFixed(2));
        } else {
          this.FabricQTY = this.FormulationPasteReqRecalculateRequest.TotalProductionQty;
          this.isUnitConversionNotDone = true;
        }
      }

    }
  }
  GetConditionalFabricList(ProductId: number) {
    let productType = this.ProductOriginalList.filter(x => x.ProductId == ProductId)[0].ProductType;
    this.ProductFilteredList = this.ProductOriginalList.filter(x => x.ProductType == productType);
  }
  ConfirmCompleteReview() {
    var msgContent: string;
    var rec = this.WorkPlanModel.WorkPlanOrder.filter(x => x.SaleOrder.IsChecked == true);
    if (rec.length == this.WorkPlanModel.WorkPlanOrder.length) {
      msgContent = 'No Sale Order left in this Work Plan. Work Plan will be Deleted. Are you sure you want to submit?';
    }
    else {
      msgContent = 'Are you sure all sale order(s) reviewed?';
    }
    const modal = this.modalService.confirm({
      nzTitle: 'Review Confirmation',
      nzContent: msgContent,
      nzOnOk: () => this.CompleteReview(),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  CompleteReview() {
    var rec = this.WorkPlanModel.WorkPlanOrder.filter(x => x.SaleOrder.IsChecked == true);
    var reviewobj = {
      WorkPlanId: this.WorkPlanModel.WorkPlanId,
      deleteWorkplan: rec.length == this.WorkPlanModel.WorkPlanOrder.length,
      SaleOrderId: rec.map(a => a.SaleOrder.SaleOrderId)
    }
    console.log(reviewobj);
    this.loader.show();
    let url = this.ApiUrl + "workplan/reviewworkplan";
    this.http.post<any[]>(url, reviewobj).subscribe({
      next: res => {
        this.alertService.success("WorkPlan Reviewed Successfully")
        this.isLoading = false;
        this.GetAllWorkPlanReport();
        this.loader.hide();
        this.handleCancel();
      },
      error: res => { this.alertService.error(res); this.isLoading = false; this.loader.hide(); },

    });
  }
  PrintJobCard(SaleOrderId: number) {
    window.open(`${window.location.origin}/inspectiondetailsprint/` + SaleOrderId);
  }

  SaveInspection(IsJobCardPrintRequired: boolean) {
    var request = new SaleOrderModel;
    if ((this.SelectedFabricId == 0 && this.Orderlist.FormulationFabricProductId != 0) && this.SelectedFabricId == null || this.SelectedFabricId == undefined) {
      this.alertService.error("Fabric is not selected.");
      return;
    }
    if ((this.SelectedFabricId != 0 && this.Orderlist.FormulationFabricProductId != 0) && this.FabricGsm == 0) {
      this.alertService.error("Fabric GSM cannot be 0. Get the AvgGSM updated on the Fabric product to proceed.");
      return;
    }
    if ((this.SelectedFabricId != 0 && this.Orderlist.FormulationFabricProductId != 0) && (this.Orderlist.FormulationFabricProducWidthInMeter == 0 && this.Orderlist.FormulationFabricProductUnit == 'Kgs')) {
      this.alertService.error("Fabric width is not updated. Get the width updated on the Fabric product to proceed.");
      return;
    }
    if (this.Orderlist.SaleOrderProduction.ExtraProduction == null || this.Orderlist.SaleOrderProduction.ExtraProduction == undefined) {
      request.SaleOrderProduction.ExtraProduction = 0;
    }
    if (this.Orderlist.SaleOrderProduction.LMConstant == 0 || this.Orderlist.SaleOrderProduction.LMConstant == null) {
      this.alertService.error("LM Constant cannot be 0");
      return;
    }

    this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      if (element.StdPasteRequirementQuantity > 0 && element.StdPasteRequirementScquantity == 0) {
        this.alertService.error("Update the SC Quantity for Raw Materials in Mixing: " + element.MixingName)
        return;
      }
    });
    let gsmCheckFlag = true;

    this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      if (element.MixingName.trim().toLowerCase() == 'pre skin' && element.MixingRawMaterial.length > 0 && this.Orderlist.SaleOrderProduction.PreSkinGsm <= 0) {
        this.alertService.error("Pre Skin GSM cannot be 0. Get it updated in Formulation Code Master Data and/or this Sale Order.");
        gsmCheckFlag = false;
        return;
      } else if (element.MixingName.trim().toLowerCase() == 'skin' && element.MixingRawMaterial.length > 0 && this.Orderlist.SaleOrderProduction.SkinGsm <= 0) {
        this.alertService.error("Skin GSM cannot be 0. Get it updated in Formulation Code Master Data and/or this Sale Order.");
        gsmCheckFlag = false;
        return;
      } else if (element.MixingName.trim().toLowerCase() == 'foam' && element.MixingRawMaterial.length > 0 && this.Orderlist.SaleOrderProduction.FoamGsm <= 0) {
        this.alertService.error("Foam GSM cannot be 0. Get it updated in Formulation Code Master Data and/or this Sale Order.");
        gsmCheckFlag = false;
        return;
      } else if (element.MixingName.trim().toLowerCase() == 'adhesive' && element.MixingRawMaterial.length > 0 && this.Orderlist.SaleOrderProduction.AdhesiveGsm <= 0) {
        this.alertService.error("Adhesive GSM cannot be 0. Get it updated in Formulation Code Master Data and/or this Sale Order.");
        gsmCheckFlag = false;
        return;
      }
    });

    if (!gsmCheckFlag) {
      return;
    }

    if (this.Orderlist.ShiftSupervisorWorkerId == 0 || this.Orderlist.ShiftSupervisorWorkerId == null) {
      this.alertService.error("Please select your current supervisor");
      return;
    }

    request.SaleOrderProduction = new SaleOrderProductionModel;
    request.SaleOrderProduction.ExtraProduction = this.Orderlist.SaleOrderProduction.ExtraProduction;
    request.SaleOrderProduction.LMConstant = this.Orderlist.SaleOrderProduction.LMConstant;
    if (request.FormulationCode == null) {
      request.FormulationCode = new SaleFormulationCodeMasterModel;
    } if (request.SaleOrderProduction?.InspectionFormulationMixing == null) {
      request.SaleOrderProduction.InspectionFormulationMixing = [];
    }
    request.SendToConsumption = this.Orderlist.SendToConsumption;
    request.ShiftSupervisorWorkerId = this.Orderlist.ShiftSupervisorWorkerId;
    request.ConsumptionStoreId = this.Orderlist.ConsumptionStoreId;
    request.SaleOrderId = this.Orderlist.SaleOrderId;
    request.SaleFormulationCodeId = this.Orderlist.SaleFormulationCodeId;
    request.FormulationCode.FabricProductQty = this.FabricQTY;
    request.FormulationCode.FabricProductId = this.SelectedFabricId;
    request.SaleOrderProduction.InspectionFormulationMixing = this.Orderlist.SaleOrderProduction.FormulationMixing;
    request.SaleOrderProduction.PreSkinGsm = this.Orderlist.SaleOrderProduction.PreSkinGsm;
    request.SaleOrderProduction.SkinGsm = this.Orderlist.SaleOrderProduction.SkinGsm;
    request.SaleOrderProduction.FoamGsm = this.Orderlist.SaleOrderProduction.FoamGsm;
    request.SaleOrderProduction.AdhesiveGsm = this.Orderlist.SaleOrderProduction.AdhesiveGsm;
    request.SaleOrderProduction.FabricGsm = this.FabricGsm;
    request.SaleOrderProduction.TotalGsm = this.Orderlist.SaleOrderProduction.PreSkinGsm + this.Orderlist.SaleOrderProduction.SkinGsm +
      this.Orderlist.SaleOrderProduction.FoamGsm + this.Orderlist.SaleOrderProduction.AdhesiveGsm +
      this.FabricGsm
    //request.SaleOrderProduction.Lacquer = this.Orderlist.SaleOrderProduction.Lacquer;
    request.SaleOrderProduction.InspectionFormulationMixing.forEach(element => {
      element.MixingRawMaterial.forEach(raw => {
        raw.BaseQuantity = raw.Quantity;
        raw.Quantity = raw.CalculatedQuantity;
        raw.Scquantity = raw.CalculatedSCQuantity;
        raw.IsBaseMaterial = raw.IsBaseMaterial;
      })
    })
    this.loader.show();
    let url = this.ApiUrl + "issueproduct/saleorderinspectionproductrequest";
    this.http.post<string[]>(url, request).subscribe({
      next: res => {
        if (res.length > 0) {
          alert("Inspection Completed Successully. But following Product(s) are not available in the store : " + res.toString());
        }
        else {
          this.alertService.success("Inspection Completed Successfully")
        }
        this.isLoading = false;
        this.loader.hide();
        this.Orderlist = new SaleOrderModel;
        this.GetAllWorkPlanReport();
        this.handleCancelInspection();
        if (IsJobCardPrintRequired) {
          this.PrintJobCard(request.SaleOrderId);
        }
      },
      error: res => {
        this.loader.hide();
        this.alertService.error(res.error);
        this.isLoading = false;
      },

    });
  }
  GetAllStore() {
    //UserInfo.EmailID = "farhan@damasinfo.com";
    if (this.StoreListOriginal.length <= 0) {
      this.isTableLoading = true;
      let url = this.ApiUrl + "store/getallstores";
      this.http.get<StoreModel[]>(url).subscribe(res => {
        this.StoreList = res;
        res.forEach(x => {
          var str = new AdminStoreModel()
          str.StoreId = x.StoreId;
          str.StoreName = x.StoreName;
          this.AdminStoreList.push(str);
        })
        this.StoreListOriginal = res;
        //setTimeout(() => { this.SelectedStore1 = this.SelectedStore2 = this.StoreListOriginal[0].StoreId; }, 10)
        this.isTableLoading = false;
        this.GetUserStores(UserInfo.EmailID);
      }, res => { });
    }
  }
  GetUserStores(UserName: string) {
    this.isTableLoading = true;
    this.AdminStoreList.forEach(x => x.IsChecked = false);
    let url = this.ApiUrl + "user/getuserstores/" + UserName;
    this.http.get<number[]>(url).subscribe(res => {
      console.log(res);
      this.AdminStoreList.forEach(s => {
        if (res.includes(s.StoreId)) {
          s.IsChecked = true;
        }
      })
      this.AdminStoreList = this.AdminStoreList.filter(x => x.IsChecked);
      this.isTableLoading = false;
    }, res => { });
  }
  calclulateTotalSalePrice() {
    this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
  }

  handleRemoveRow(data: any, MixingName: string) {
    // this.NewWorkPlan.JumboInspection = this.NewWorkPlan.JumboInspection.filter(obj => obj !== data);
    this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      element.MixingRawMaterial = element.MixingRawMaterial.filter(obj => obj !== data);
    });
    this.checkedStatus[MixingName] = false;
    this.calculateFormulaQuantity();
    this.ResetSaveInspection();
    // var res = this.Orderlist.SaleOrderProduction.FormulationMixing

  }

  openAddModel(data: any) {
    this.SelectedMixing = data;
    this.IsPopUpOpen = true;
  }
  closeAddModel() {
    this.IsPopUpOpen = false;
  }

  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductOriginalList = res;
      this.ProductRawList = res.filter(x => x.ProductType == "Raw");
    }, res => { this.GetAllProducts(); });
  }

  addRawMaterial() {
    var raw = new SaleOrderProductionMixingRawMaterialModel;
    raw.Price = this.selectedproduct.PricePerUnit;
    // raw.FormulationCodeMixingId = this.SelectedMixing.MixingId;
    raw.ProductCode = this.selectedproduct.ProductCode;
    raw.ProductId = this.selectedproduct.ProductId;
    raw.ProductName = this.selectedproduct.ProductName;
    raw.Quantity = this.selectedQuantity;
    raw.Unit = this.selectedproduct.Unit;
    raw.AvgGsm = parseInt(this.selectedproduct.AvgGsm);
    raw.Scquantity = this.selectedQuantity;
    this.Orderlist.SaleOrderProduction.FormulationMixing.filter(x => x.MixingName == this.SelectedMixing.MixingName)[0].MixingRawMaterial.push(raw);
    // this.listOfSelectedproducts.forEach(x => {
    //   var raw = new FormulationCodeMixingRawMaterialModel;
    //   raw.Price = 0;
    //   raw.FormulationCodeMixingId = this.SelectedMixing.MixingId;
    //   raw.ProductCode = x.ProductCode;
    //   raw.ProductId = x.ProductId;
    //   raw.ProductName = x.ProductName;
    //   raw.Quantity = 1;
    //   raw.Unit = x.Unit;
    //   raw.AvgGsm = parseInt(x.AvgGsm);
    //   raw.Scquantity = 1;
    //   this.SelectedMixing.MixingRawMaterial.push(raw);
    // })
    // this.listOfSelectedproducts = [];
    this.calculateFormulaQuantity();
    this.ResetSaveInspection();
    this.closeAddModel();
  }
  onBaseMaterialChecked(data: boolean, MixingName: string) {
    this.disableAll = data;
    this.checkedStatus[MixingName] = data;
    this.calculateFormulaQuantity();
  }
  CheckDisabled(checked: boolean, MixingName: string) {
    var checkdisable = false;
    this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      element.MixingRawMaterial.forEach(raw => {
        if (checked == true && MixingName != element.MixingName) {
          checkdisable = true;
        }
        else {
          checkdisable = false;
        }
      })
    })
    return checkdisable;
  }
  GetSaleOrderTagColor(data: any) {
    if (data.Status == "WorkPlan") {
      return 'lime'
    }
    else if (data.Status == "InJumbo") {
      return '#87d068'
    }
    else if (data.Status == "JumboInspection") {
      return '#f50'
    }
    else
      return 'cyan'

  }

  againReviewButtonEnable(data: WorkPlanMasterModel) {
    var reviewedSaleOrdersCount = data.WorkPlanOrder.filter(x => x.SaleOrder.Status.toString() == "WorkPlan").length
    if (data.IsReviewed == true && this.permission.Approval && reviewedSaleOrdersCount > 0) {
      return true;
    }
    return false;
  }
  SaleOrderEdit(data: any) {
    this.router.navigate(['/home/salesorder/edit/' + data.SaleOrderId]);
  }
  EnableSaleOrderEditButton(data: any) {
    if (data.Status == 'WorkPlan' && this.permission.SaleOrderEdit)
      return true;
    else
      return false;
  }
  get f() { return this.InspectionCancelForm.controls; }
  showInspectionCancelPopup(saleOrderId: number) {
    this.InspectionCancelForm.reset();
    this.isInspectionCancelVisible = true;
    this.CancellationRequest.SaleOrderId = saleOrderId;
  }
  saveInspectionCancel() {
    this.CancellationRequest.Reason = this.f["reason"].value;
    if (this.CancellationRequest.Reason == "" || this.CancellationRequest.Reason == null) {
      this.alertService.error("Please provide reason.");
      return;
    }
    this.isLoading = true;
    this.loader.show();
    var request = this.CancellationRequest
    let url = this.ApiUrl + "issueproduct/cancelsaleorderinspectionproductrequest";
    this.http.post<any[]>(url, request).subscribe({
      next: res => {
        this.isLoading = false;
        this.handleInspectionCancel();
        this.alertService.success(res)
        this.handleCancelInspection();
        this.GetAllWorkPlanReport();
        this.loader.hide();
      },
      error: res => {
        if (res.error.StatusCode == HttpStatusCode.Forbidden) {
          this.alertService.warning(res.error);
        }
        else {
          this.alertService.error(res.error);
        }
        this.isLoading = false;
        this.loader.hide();
      },
    });
  }
  handleInspectionCancel() {
    this.isInspectionCancelVisible = false;
  }

  getQuantityinKg() {
    this.isInspectionCancelVisible = false;
  }
  enablePreInspectionCancelBtn(SaleOrderStatus: ESalesOrderStatus) {
    if ((SaleOrderStatus.toString() == "WorkPlan" || SaleOrderStatus.toString() == "Inspection" || SaleOrderStatus.toString() == "RawMaterialRequested" || SaleOrderStatus.toString() == "RawMaterialIssued") && this.permission.PreInspectionCancel) {
      this.InspectionCancelBtnToolTipTxt = "Click to cancel."
      return false;
    }
    else {
      this.InspectionCancelBtnToolTipTxt = "You don't have acccess or it can't be cancelled as production started for this order. Check with your Supervisor or Admin."
      return true;
    }

  }
  drop(datalist: SaleOrderProductionMixingRawMaterialModel[], event: CdkDragDrop<string[]>): void {
    moveItemInArray(datalist, event.previousIndex, event.currentIndex);
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  GetAllFactoryWorkers() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "factoryworkers/getallfactoryworkerswithfilters";
    this.http.post<FactoryWorkersModel[]>(url, {}).subscribe(res => {
      this.FactoryWorkersList = res;

      this.isTableLoading = false;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllFactoryWorkers();
      }
    });
  }
}
