<div *ngIf="this.JumboDispatchFilteredList">

    <table class="companytable">
        <tr>
            <td>
                <div class="vendor" style="margin-top: 40px;font-weight: bold;" *ngIf="JumboDispatchFilteredList">
                    <p>Packing No.: {{ JumboDispatchFilteredList.PackingNumber }}</p>
                    <p>Packing Date: {{ JumboDispatchFilteredList.AddedDate | DatetimeConverter }}</p>
                    <p>Buyer Name: {{ JumboDispatchFilteredList?.CustomerDetails[0]?.CustomerName }}</p>
                    <p>
                        Address:
                    <div [innerHTML]="JumboDispatchFilteredList.CustomerDetails[0]?.Address"></div>
                    <p *ngIf="JumboDispatchFilteredList.TransportName">
                        Transport Name: {{
                        JumboDispatchFilteredList.TransportName
                        }}
                    </p>
                    <p *ngIf="JumboDispatchFilteredList.VehicleNumber">
                        Vehicle Number: {{
                        JumboDispatchFilteredList.VehicleNumber
                        }}
                    </p>
                    <p>
                        Remark:
                    <div [innerHTML]="JumboDispatchFilteredList.Remark"></div>
                </div>
                <hr />
            </td>
        </tr>
    </table>

    <!--<div class="producttable-container" *ngFor="let jm of JumboDispatchFilteredList.JumboList">

        <table class="producttable" style="width:600px">
            <thead>
                <tr>
                    <td colspan="6" style="text-align: center;"><b>{{jm.SaleOrderCode}} ({{jm.SaleOrderManufacturingProductName}})</b></td>
                </tr>
                <tr>
                    <th width="65px">S. No.</th>
                    <th width="130px">Roll No.</th>
                    <th width="100px">Quantity(MTR)</th>
                    <th width="100px">Weight(KG)</th>
                    <th width="80px">Grade</th>
                    <th width="100px">Thickness</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of jm.JumboInspection; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{data.Code }}</td>
                    <td>{{ data.Quantity }}</td>
                    <td>{{ data.Weight }}</td>
                    <td>{{ data.Grade }}</td>
                    <td>{{ data.ThicknessNumber }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td><b>TOTAL</b></td>
                    <td><b> {{this.sumquantity(jm)}}</b></td>
                    <td><b> {{this.sumweight(jm)}}</b></td>
                    <td><b>MTR</b></td>
                    <td><b>{{ jm.JumboInspection.length }} Roll</b></td>
                </tr>
            </tbody>
        </table>
        <br>
    </div>-->
    <div class="producttable-container" *ngFor="let it of JumboDispatchUIFilteredList; let totalcount = index" style="margin-top: 10px;">
        <table class="producttable" style="width:600px;page-break-inside: auto;">
            <thead>
                <tr>
                    <td colspan="6" style="text-align: center;"><b>{{it.SaleOrderCode}}
                            ({{it.SaleOrderManufacturingProductName}})</b></td>
                </tr>
            </thead>
        </table>
        <table class="producttable" style="width:600px;page-break-inside: auto;"
            *ngFor="let jm of it.item; let count = index">
            <thead *ngIf="count==0">

                <tr style="page-break-inside:avoid; page-break-after:auto">
                    <th width="65px">S. No.</th>
                    <th width="130px">Roll No.</th>
                    <th width="100px">Quantity(MTR)</th>
                    <th width="100px">Weight(KG)</th>
                    <th width="80px">Grade</th>
                    <th width="100px">Thickness</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of jm.JumboInspection; let i = index"
                    style="page-break-inside:avoid; page-break-after:auto">
                    <td width="65px">{{ i + 1 }}</td>
                    <td width="130px">{{data.Code }}</td>
                    <td width="100px">{{ data.Quantity }}</td>
                    <td width="100px">{{ data.Weight }}</td>
                    <td width="80px">{{ data.Grade }}</td>
                    <td width="100px">{{ data.ThicknessNumber }}</td>
                </tr>
                <tr style="page-break-inside:avoid; page-break-after:auto">
                    <td><b>{{jm.JumboInspection[0]?.Grade}}</b></td>
                    <td><b>Grade Total</b></td>
                    <td><b> {{this.sumquantity(jm)}}</b></td>
                    <td><b> {{this.sumweight(jm)}}</b></td>
                    <td><b>MTR</b></td>
                    <td><b>{{ jm.JumboInspection.length }} Roll</b></td>
                </tr>
            </tbody>
        </table>
        <table class="producttable" style="width:600px;page-break-after:auto;">
            <tbody>
                <tr style="page-break-inside:avoid; page-break-after:auto">
                    <td width="65px"></td>
                    <td width="130px"><b>Product Total</b></td>
                    <td width="100px"><b> {{this.totalsumquantity(it.SaleOrderId)}}</b></td>
                    <td width="100px"><b> {{this.totalsumweight(it.SaleOrderId)}}</b></td>
                    <td width="80px"><b>MTR</b></td>
                    <td width="100px"><b>{{this.totalsumRoll(it.SaleOrderId)}} Roll</b></td>
                </tr>
            </tbody>
        </table>
        <table class="producttable" style="width:600px;page-break-after:auto;margin-top: 5px;">
            <tbody *ngIf="totalcount == (this.JumboDispatchUIFilteredList.length - 1)">
                <tr style="page-break-inside:avoid; page-break-after:auto">
                    <td width="65px"></td>
                    <td width="130px"><b>Grand Total</b></td>
                    <td width="100px"><b> {{this.grandtotalsumquantity()}}</b></td>
                    <td width="100px"><b> {{this.grandtotalsumweight()}}</b></td>
                    <td width="80px"><b>MTR</b></td>
                    <td width="100px"><b>{{this.JumboDispatchFilteredList.JumboInspection.length}} Roll</b></td>
                </tr>
            </tbody>
        </table>
        <br>
    </div>
</div>