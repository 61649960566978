import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, ColorModel, GrainModel, ElementModel } from '../../Models/MasterModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../Models/ProductModel';
import { PurchaseOrderModel, PurchaseOrderProductModel } from '../../Models/PurchaseOrderModel';
import { OrderModel, OrderProductModel, SupplierModel } from '../../Models/SupplierModel';
import { TransportModel } from '../../Models/TransportModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';

import { UserInfo } from '../../Authentication/UserInfo';

import { MixingModel, MixingRawMaterialModel } from '../../Models/MixingModel';
import { Subscription } from 'rxjs';
import { isNumber } from '@amcharts/amcharts5/.internal/core/util/Type';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  selector: 'app-Mixing',
  templateUrl: './mixing.component.html',
  styleUrls: ['./mixing.component.css']
})
export class MixingComponent implements OnInit {
  validateForm!: UntypedFormGroup;

  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  NewPO: MixingModel = new MixingModel;
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: '',
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  }
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  listOfSelectedproducts: number[] = [];
  productionRawMaterial: MixingRawMaterialModel[] = [];
  MixingList: MixingModel[] = [];
  Wastagetype = '%';
  MixingId = 0;
  private route$: Subscription = new Subscription;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router,private auth: AuthService) { }
  handleOk(): void {

    this.isLoading = true;

    this.Save();
  }
  GetMixing() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mixing/getallmixings";
    this.http.get<MixingModel[]>(url).subscribe(res => {
      this.MixingList = res;
      if (this.MixingId > 0) {
        this.NewPO = res.filter(x => x.MixingId == this.MixingId)[0];
        this.productionRawMaterial = this.NewPO.MixingRawMaterial;
        this.productionRawMaterial.forEach(x => {
          this.listOfSelectedproducts.push(x.ProductId);
        });
        this.calculateTotal();
      }
    }, res => { });
  }
  Save() {

    if (this.NewPO.MixingName == '') {
      this.isLoading = false;
      this.alertService.error("Mixing Name Required");
      return;
    }

    if (this.productionRawMaterial.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add RawMaterial");
      return;
    }
    if (this.MixingId == 0) {
      if (this.MixingList.filter(x => x.MixingName == this.NewPO.MixingName).length > 0) {
        this.isLoading = false;
        this.alertService.error("Mixing name already exist");
        return;
      }
    } else {
      if (this.MixingList.filter(x => x.MixingName == this.NewPO.MixingName && x.MixingId != this.MixingId).length > 0) {
        this.isLoading = false;
        this.alertService.error("Mixing name already exist");
        return;
      }
    }


    if (this.productionRawMaterial.filter(x => x.Price <= 0).length > 0) {
      this.isLoading = false;
      this.alertService.error("Invalid Price (should be greater than 0)");
      return;
    }
    if (this.productionRawMaterial.filter(x => x.Quantity <= 0).length > 0) {
      this.isLoading = false;
      this.alertService.error("Invalid Quantity (should be greater than 0)");
      return;
    }
    this.NewPO.MixingRawMaterial = this.productionRawMaterial;

    this.NewPO.AddedBy = UserInfo.EmailID;


    var aurl = "mixing/addmixing";
    if (this.MixingId > 0) {
      aurl = "mixing/updatemixing";
    }
    let url = this.ApiUrl + aurl;

    this.http.post<MixingModel>(url, this.NewPO).subscribe({
      next: res => {
        this.alertService.success("Mixing Saved Successfully"); this.isLoading = false;
        this.prolist = [];
        this.router.navigate(['/home/production/mixing/list'])
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });

  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.Customer, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Customer, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Customer, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.MixingId = params["id"];
    })
    this.validateForm = this.fb.group({});
    this.GetMixing();
    this.GetAllProducts();
    //if (this.MixingId > 0)
    //  this.GetMixing();
  }
  get f() { return this.validateForm.controls; }

  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res;
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  handleCancel(): void {
    this.isVisible = false;
  }


  OnProductDdlchange() {
    //this.productionRawMaterial = [];
    if (this.listOfSelectedproducts.length < this.productionRawMaterial.length) {
      let filteredpid: number[] = [];
      this.productionRawMaterial.forEach(x => {
        filteredpid.push(x.ProductId);
      })
      let missing = filteredpid.filter(item => this.listOfSelectedproducts.indexOf(item) < 0)[0];
      var d = this.productionRawMaterial.filter(x => x.ProductId == missing)[0]
      this.productionRawMaterial = this.productionRawMaterial.filter(obj => obj !== d);
    }
    else {
      this.listOfSelectedproducts.forEach(item => {
        var checkexist = this.productionRawMaterial.filter(x => x.ProductId == item).length;
        if (checkexist == 0) {
          var product = this.ProductRawList.filter(x => x.ProductId == item)[0];
          var rawproduct = new MixingRawMaterialModel;
          rawproduct.ProductId = item;

          rawproduct.ProductName = product.ProductName;
          rawproduct.ProductCode = product.ProductCode;
          rawproduct.Unit = product.Unit;
          rawproduct.Quantity = 0;
          rawproduct.AvgGsm = product.AvgGsm;
          if (this.productionRawMaterial.filter(x => x.ProductId == item).length == 0) {
            this.productionRawMaterial.push(rawproduct);
            this.NewPO.WeightGsm = 0;
            this.productionRawMaterial.forEach(x => {
              if (isNumber(x.AvgGsm))
                this.NewPO.WeightGsm += parseInt(x.AvgGsm)
            })
          }
        }

      })
    }
    this.calculateTotal();
  }

  calculateTotal() {
    this.NewPO.Total = this.NewPO.WeightGsm = 0;
    this.productionRawMaterial.forEach((element) => {
      this.NewPO.Total += parseFloat(element.Quantity.toString());
      this.NewPO.WeightGsm += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });
    this.OnWastagetypeDdlchange('')
  }
  OnWastagetypeDdlchange(data: string) {
    var total = 0;
    this.productionRawMaterial.forEach((element) => {
      total += parseFloat(element.Quantity.toString());

    });
    if (this.NewPO.WastageType == "%") {
      this.NewPO.Total = total - ((this.NewPO.Wastage * total) / 100);

    } else {
      this.NewPO.Total = total - this.NewPO.Wastage;
    }

  }
}
