import { HttpClient } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

@Injectable()

export class AlertMessageService {
  private _value: any;
  ApiUrl = environment.Api_Url;
  constructor(private modalService: NzModalService, public http: HttpClient) { }

  success(msg: any): void {
    const modal = this.modalService.success({
      nzTitle: 'Success',
      nzContent: msg
    });

    setTimeout(() => modal.destroy(), 5000);
  }

  error(msg: any): void {
    const modal = this.modalService.error({
      nzTitle: 'Error',
      nzContent: msg
    });

    setTimeout(() => modal.destroy(), 10000);
  }
  warning(msg: any, timeout?: number): void {
    const modal = this.modalService.warning({
      nzTitle: 'Warning',
      nzContent: msg
    });
    if (timeout > 0) {
      setTimeout(() => modal.destroy(), timeout)
    }
    else {
      setTimeout(() => modal.destroy(), 50000);
    }
  }

  getStorageToken(): any {
    let url = this.ApiUrl + "data/getstoragetoken";
    this.http.get<any>(url).subscribe(res => {

      return res;
    }, res => { });
  }
}
