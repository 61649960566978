import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { Modules, Responsibility } from '../../Models/Enums';
import { AdminStoreModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';
import { OverheadCostModel } from 'src/PmsUIApp/Models/OverheadCost';

@Component({
  selector: 'app-OverheadCost',
  templateUrl: './OverheadCost.component.html'
})
export class OverheadCostComponent {
  ApiUrl = environment.Api_Url;
  AddOverheadCost: OverheadCostModel = new OverheadCostModel();
  OverheadCostList: OverheadCostModel[] = [];
  OverheadCostListOriginal: OverheadCostModel[] = [];
  isLoading: boolean = false;
  isVisible = false;
  searchValue = '';
  isNew = true;
  isTableLoading: boolean = false;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private msalService: MsalService,
    public http: HttpClient
  ) { }


  GetAllOverheadCost() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "overhead/getalloverheadcost";
    this.http.get<any>(url).subscribe(
      (res) => {
        this.OverheadCostList = res;
        this.OverheadCostListOriginal = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllOverheadCost();
        }
      }
    );
  }





  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      //event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  reset(): void {
    this.searchValue = '';

  }
  ngOnInit() {
    // this.IsAuth = this.authService.CheckModule(Modules.AdminGeneralConfiguration);
    this.permission.View = this.auth.CheckResponsibility(Modules.CostingOverhead, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.CostingOverhead, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.CostingOverhead, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.CostingOverhead, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllOverheadCost();
  }

  showModal() {
    this.isNew = true;
    this.isVisible = true;

  }
  OpenEditPop(data: OverheadCostModel) {
    this.isNew = false;
    this.AddOverheadCost = data;
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.AddOverheadCost = new OverheadCostModel;
  }

  Save() {
    if (this.AddOverheadCost.OverheadCost < 0) {
      this.alertService.error("Per Hour Cost required");
      return
    }
    if (this.AddOverheadCost.ApplicableOn == null) {
      this.alertService.error("Applicable date is required");
      return
    }
    let url = this.ApiUrl + "overhead/addupdateoverheadcost";
    this.isLoading = true;
    this.http.post<any>(url, this.AddOverheadCost).subscribe({
      next: res => { console.log(res); this.alertService.success(res); this.isLoading = this.isVisible = false; this.GetAllOverheadCost(); },
      error: res => { console.log(res); this.alertService.error(res.error); this.isLoading = this.isVisible = false; this.GetAllOverheadCost(); },
    });
  }
}
