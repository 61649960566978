import AzureData from 'src/Config.json';
export const environment = {
  production: true,
  Api_Url: "https://uatapi.pms.zaibunco.com/api/",
  BlobSASToken: '?sv=2020-08-04&ss=bfqt&srt=co&sp=rwdlacupitfx&se=2022-05-30T19:28:03Z&st=2022-05-10T11:28:03Z&spr=https&sig=eeDwK4IJE2jxtm5hhQnI0Aa3ELsp3SHO7LhQ4zUpXbE%3D',
  BlobURL: 'https://pmsdocumentstordev.blob.core.windows.net/',
  localCacheTtl: 60,
  switchURL: 'https://uat.belt.pms.zaibunco.com/login',
  switchLeatherURL: 'https://uat.leather.pms.zaibunco.com/login',
  DivisionName: 'UAT Rexine Division',
  EnvironmentName: 'uat'
};


export const ActiveX = {
  AppName: AzureData.UAT.AppName,
  clientId: AzureData.UAT.clientId,
  authority: AzureData.UAT.authority,
  redirectUri: AzureData.UAT.redirectUri,
  postLogoutRedirectUri: AzureData.UAT.postLogoutRedirectUri
}
