<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
      <nz-page-header [nzGhost]="false">
        <nz-page-header-title>Mixing</nz-page-header-title>
        <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
        <nz-page-header-extra>
  
          <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
        </nz-page-header-extra>
  
      </nz-page-header>
      <nz-divider></nz-divider>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
        <div nz-row [nzGutter]="24">
       <div nz-col [nzSpan]="12">
          <nz-form-item>
             <nz-form-control [nzSpan]="22" nzErrorTip="Enter Element">
              <label class="required">Select Orders</label>
              <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedOrder" (ngModelChange)="OnOrderDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let s of this.OrderList;" [nzValue]="s.OrderId" [nzLabel]="s.SaleOrderNo"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
  
        </div>
        <div nz-col [nzSpan]="6">
            <nz-form-item>
               <nz-form-control [nzSpan]="22" nzErrorTip="Enter Element">
                <label>Jumboroll number </label>
                <input nz-input name="Jumborollnumber" placeholder="Jumboroll number" type="number" />
              </nz-form-control>
            </nz-form-item>
    
          </div>
        </div>
      
        <div style="background: #f0f2f5; border-radius: 4px;  padding: 15px;">
          <nz-table nzSize="small"  #basicTable3 [nzData]="['']" nzBordered nzShowPagination="false">
            <thead>
              <tr>
                <th nzWidth="50px">S.No</th>
                <th>Order No.</th>
                <th>Product</th>
                <th>Code</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of this.productionOrder;let i=index">
                <td nzWidth="20px">{{i+1}}</td>
                <td>{{ data.SaleOrderNo }}</td>
                <td>{{ data.Products}}</td>
                <td>{{ data.ProductCode}}</td>
                <td>{{ data.Unit}}</td>
                <td>{{ data.Value}}</td>
                <td>
                  <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)"><i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Remove</a>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div *ngIf="this.productionOrder.length>0" style="margin:10px">
          <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
            <div nz-col [nzSpan]="14" style="background: #f0f2f5; border-radius: 4px; ">
              <nz-divider nzText="Formulation Code"></nz-divider>
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="6">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                        <label>Formulation Code</label>
                        <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"  nzPlaceHolder="Choose">
                          <nz-option ></nz-option>
                        </nz-select>
                      </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                      <label>Sales Price </label>
                      <input nz-input name="Product" placeholder="Product" type="number" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Quantity">
                      <label>Category</label>
                      <input nz-input name="Category" placeholder="Category" disabled  />
                    </nz-form-control>
                  </nz-form-item>
  
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Product Name</label>
                      <nz-select class="form-select mb-2" nzShowSearch name="ProductId" nzSize="default" nzPlaceHolder="Choose">
                        <nz-option ></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
  
                </div>
                <div nz-col [nzSpan]="4">
                    <nz-form-item>
    
                      <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                        <label>Qty</label>
                        <input nz-input name="Qty" placeholder="Qty" />
                      </nz-form-control>
                    </nz-form-item>
    
                  </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Thick</label>
                      <input nz-input name="Thick" placeholder="Thick" disabled value="NewPO.Thick" [(ngModel)]="NewPO.Thick" [ngModelOptions]="{standalone: true}" />
                    </nz-form-control>
                  </nz-form-item>
  
                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Width</label>
                      <input nz-input name="Width" placeholder="Width" disabled value="NewPO.Width" [(ngModel)]="NewPO.Width" [ngModelOptions]="{standalone: true}" />
                    </nz-form-control>
                  </nz-form-item>
  
                </div>
  
                 <div nz-col [nzSpan]="6">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Grain</label>
                      <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewPO.GrainId" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId" [nzLabel]="s.GrainName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
  
                </div>
  
                <div nz-col [nzSpan]="6">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Color</label>
                      <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewPO.ColorId" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId" [nzLabel]="s.ColorName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
  
                </div>            
              </div>
            </div>
            <div nz-col [nzSpan]="1"></div>
            <div nz-col [nzSpan]="9" style="background-color: #f0f2f5; border-radius: 4px;">
              <nz-divider nzText="Production Element"></nz-divider>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
  
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                    <label class="required">Select Elements</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple" [(ngModel)]="listOfSelectedElement" (ngModelChange)="OnElementDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ElementList;" [nzValue]="s.ElementId" [nzLabel]="s.ElementName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 1200px;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
                  <thead>
                    <tr>
  
                      <th nzWidth="50px">S.No</th>
                      <th>Element</th>
                      <th nzWidth="120px">Value</th>
  
  
  
                      <!-- <th nzWidth="20%">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of productionElement;let i=index">
  
                      <td nzWidth="20px">{{i+1}}</td>
                      <td>{{ data.ElementName }}</td>
                      <td> <input nz-input value="data.value" [(ngModel)]="data.Value" [ngModelOptions]="{standalone: true}" /></td>
  
  
                    </tr>
  
                  </tbody>
  
                </nz-table>
                <nz-divider></nz-divider>
              </div>
  
            </div>
          </div>
          <nz-divider></nz-divider>
          <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
            <div nz-col [nzSpan]="8" style="background: #f0f2f5; border-radius: 4px; ">
                <nz-divider nzText="Fabric Material"></nz-divider>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                      <label>Select Raw Products</label>                    
                   <nz-form-control [nzSpan]="22" nzErrorTip="Enter Amount">
                      <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple" [(ngModel)]="listOfSelectedproducts" (ngModelChange)="OnProductDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
    
                        <th nzWidth="50px">S.No</th>
                        <th  nzWidth="50px">Product</th>
                        <th  nzWidth="50px">Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of productionRawMaterial;let i=index">
    
                        <td nzWidth="20px">{{i+1}}</td>
                        <td  nzWidth="20px">{{ data.ProductName }}</td>
                        <td  nzWidth="20px">{{ data.ProductCode }}</td>
                      </tr>
    
                    </tbody>
    
                  </nz-table>
                  <nz-divider></nz-divider>
                </div>
  
              
            </div>
  
              <div nz-col [nzSpan]="8" style="background-color: #f0f2f5; border-radius: 4px; border-left: 5px solid #fff;">
                <nz-divider nzText="Lacquar"></nz-divider>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label>Select Lacquar</label>
                        <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewPO.LacquerMasterId" (ngModelChange)="OnLacquerDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                          <nz-option *ngFor="let s of this.LacquerList;" [nzValue]="s.LacquerMasterId" [nzLabel]="s.Name"></nz-option>
                        </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 100%;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
    
                       <th>Product Name</th>
                        <th nzWidth="100px">Code</th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of LacquerRawMaterial;let i=index">
    
                     
                        <td>{{ data.ProductName }}</td>
                        <td nzWidth="100px"> {{ data.ProductCode }}</td>
                      </tr>
    
                    </tbody>
    
                  </nz-table>
                  <nz-divider></nz-divider>
                </div>
  
              </div>
              <div nz-col [nzSpan]="8" style="background-color: #f0f2f5; border-radius: 4px; border-left: 5px solid #fff;">
                <nz-divider nzText="Print"></nz-divider>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label>Select Print</label>
                        <nz-select #microwaveRef class="form-select" nzMode="multiple" nzShowSearch nzSize="default" name="PrintMasterId" nzPlaceHolder="Select" >
                          <nz-option></nz-option>
                        </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 1200px;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
      
                        <th nzWidth="50px">S.No</th>
                        <th>Name</th>
                        <th>Code</th>
                        </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngFor="let data of listOfSelectedPrint;let i=index">
      
                        <td nzWidth="20px">{{i+1}}</td>
                        <td>{{ data.Name }}</td>
                        <td>{{ data.Code }}</td>
                       </tr> -->
      
                    </tbody>
      
                  </nz-table>
                  <nz-divider></nz-divider>
                </div>
                </div>
            </div>
          <nz-divider></nz-divider>
            <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
            <div nz-col [nzSpan]="8" style="background: #f0f2f5; border-radius: 4px; ">
                <nz-divider nzText="Embossing"></nz-divider>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                      <label>Select Embossing</label>                    
                   <nz-form-control [nzSpan]="22" nzErrorTip="Enter Amount">
                    <nz-select #microwaveRef class="form-select" nzMode="multiple" nzShowSearch nzSize="default" name="EmbossingMasterId" nzPlaceHolder="Select">
                      <nz-option ></nz-option>
                    </nz-select>
                  </nz-form-control>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 1200px;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
            
                        <th nzWidth="50px">S.No</th>
                        <th>Name</th>
                        <th>Code</th>
                        </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngFor="let data of listOfSelectedEmbossing;let i=index">
            
                        <td nzWidth="20px">{{i+1}}</td>
                        <td>{{ data.Name }}</td>
                        <td>{{ data.Code }}</td>
                      
                      </tr> -->
            
                    </tbody>
            
                  </nz-table>
                  <nz-divider></nz-divider>
                </div>
  
              
            </div>
  
              <div nz-col [nzSpan]="8" style="background-color: #f0f2f5; border-radius: 4px; border-left: 5px solid #fff;">
                <nz-divider nzText="Vaccum"></nz-divider>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label>Select Vaccum</label>
                        <nz-select #microwaveRef class="form-select" nzMode="multiple" nzShowSearch nzSize="default" name="VaccumMasterId" nzPlaceHolder="Select">
                          <nz-option></nz-option>
                        </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 1200px;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
            
                        <th nzWidth="50px">S.No</th>
                        <th>Name</th>
                        <th>Code</th>
                         </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngFor="let data of listOfSelectedVaccum;let i=index">
            
                        <td nzWidth="20px">{{i+1}}</td>
                        <td>{{ data.Name }}</td>
                        <td>{{ data.Code }}</td>
                        </tr> -->
            
                    </tbody>
            
                  </nz-table>
                  <nz-divider></nz-divider>
                </div>
  
              </div>
              <div nz-col [nzSpan]="8" style="background-color: #f0f2f5; border-radius: 4px; border-left: 5px solid #fff;">
                <nz-divider nzText="Tumbling"></nz-divider>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
  
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label>Select Tumbling</label>
                        <nz-select #microwaveRef class="form-select" nzMode="multiple" nzShowSearch nzSize="default" name="TumblingMasterId" nzPlaceHolder="Select">
                          <nz-option ></nz-option>
                        </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 1200px;" [nzData]="['']" #basicTable2 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
            
                        <th nzWidth="50px">S.No</th>
                        <th>Name</th>
                        <th>Code</th>
                        </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngFor="let data of listOfSelectedTumbling;let i=index">
            
                        <td nzWidth="20px">{{i+1}}</td>
                        <td>{{ data.Name }}</td>
                        <td>{{ data.Code }}</td>
                     
                      </tr>
             -->
                    </tbody>
            
                  </nz-table>
                  <nz-divider></nz-divider>
                </div>
                </div>
            </div>

            <nz-divider></nz-divider>
       
            <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding-bottom: 12px;    background: rgb(240, 242, 245);">
                <nz-divider></nz-divider>
                    <div nz-row [nzGutter]="24" >
                      <div nz-col [nzSpan]="3">
                        <label style="margin-top: 25px;margin-bottom: 25px;font-weight: 500;margin-left: 25px;">Weight in GSM</label>
                      </div>                
                    <div nz-col [nzSpan]="4">
                      <nz-form-item>     
            
                        <nz-form-control [nzSpan]="24" nzErrorTip="Select supplier Name">
                          <label>Pre Skin</label>
                          <input nz-input formControlName = "SupplierName" name="SupplierName" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                      <nz-form-item>     
            
                        <nz-form-control [nzSpan]="24" nzErrorTip="Select supplier Name">
                          <label>Skin</label>
                          <input nz-input formControlName = "SupplierName" name="SupplierName" />
                         </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                      <nz-form-item>       
            
                        <nz-form-control [nzSpan]="24" nzErrorTip="Select supplier Name">
                          <label>Foam</label>
                          <input nz-input formControlName = "SupplierName" name="SupplierName" />                    
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                      <nz-form-item>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                          <label>Adhesive</label>
                          <input nz-input formControlName = "SupplierName" name="SupplierName" />                    
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5">
                      <nz-form-item>
            
                        <nz-form-control [nzSpan]="20" nzErrorTip="Enter Unit number">
                          <label>Fabric (GSM)</label>
                          <input nz-input formControlName = "SupplierName" name="SupplierName" />   
                        </nz-form-control>
                      </nz-form-item>
            
                    </div>
                                      
               </div>
                  <div nz-col [nzSpan]="15">
              
                  
          
                </div>
                  <div nz-col [nzSpan]="9">
                    <nz-descriptions [nzColumn]="1">
                      <nz-descriptions-item  nzTitle="Total (GSM)" nzColon="true">
                           <input nz-input type="text" style="width:200px;"/>
                      </nz-descriptions-item>    
                    </nz-descriptions>
                    
                   
                  </div>
    
      
      
              </div>
              <nz-divider></nz-divider>
              <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
                <div nz-col [nzSpan]="24" style="background-color: #f0f2f5; border-radius: 4px;">
                  <nz-divider nzText="Mixing"></nz-divider>
                  <div nz-col [nzSpan]="24">
                    <nz-form-item>
      
                      <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label class="required">Select Mixing</label>
                        <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple" [(ngModel)]="listOfSelectedmixing" (ngModelChange)="OnMixingDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                          <nz-option *ngFor="let s of this.MixingList;" [nzValue]="s.MixingId" [nzLabel]="s.MixingName"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <div *ngFor="let i of MixingSelectedList" style="padding: 5px; border: 1px solid #ccc; border-radius: 4px; margin-top: 10px; background: #fff; box-shadow: 1px 1px 2px 2px #415994;">
                      <div style="padding: 10px 0;text-transform: uppercase;">
                        Mixing Name :
                        <b>{{i.ProductionMixingName}}</b>
                      </div>
                      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                        <thead>
                          <tr>
      
                            <th nzWidth="50px">S.No</th>
                            <th>Product</th>
                            <th nzWidth="200px">Code</th>
                            <th nzWidth="100px">Gsm</th>
                            <th nzWidth="100px">Unit</th>
                            <!-- <th nzWidth="100px">Price</th> -->
      
                            <th nzWidth="100px">QTY</th>
                            <th nzWidth="100px">Issue QTY</th>

      
                            <!-- <th nzWidth="20%">Action</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of i.ProductionMixingRawMaterial;let i=index">
      
                            <td nzWidth="20px">{{i+1}}</td>
                            <td>{{ data.ProductName }}</td>
                            <td>{{ data.ProductCode }}</td>
                            <td>{{ data.AvgGsm }}</td>
                            <td>{{ data.Unit }}</td>
                            <!-- <td>
      
                              <input nz-input placeholder="Price" value="data.Price" [(ngModel)]="data.Price" [ngModelOptions]="{standalone: true}" />
                            </td> -->
                            <td>
      
                              <input nz-input placeholder="Amount"  disabled value="data.Quantity" [(ngModel)]="data.Quantity" [ngModelOptions]="{standalone: true}" (ngModelChange)="calculateMixingTotal(data)" />
                            </td>
                            <td>
      
                                <input nz-input placeholder="Amount" value="data.Quantity" [(ngModel)]="data.Quantity" [ngModelOptions]="{standalone: true}" (ngModelChange)="calculateMixingTotal(data)" />
                              </td>
      
      
                          </tr>
      
                        </tbody>
      
                      </nz-table>
                      <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px;">
                        <div nz-col [nzSpan]="8"></div>
                        <div nz-col [nzSpan]="16">
      
                          <div nz-row [nzGutter]="24">
      
      
                            <div nz-col [nzSpan]="6">
                              <!-- <nz-form-item>
      
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
                                  <label>Wastage</label>
                                  <input nz-input name="Wastage" placeholder="Wastage" value="i.Wastage" [(ngModel)]="i.Wastage" [ngModelOptions]="{standalone: true}" (ngModelChange)="OnWastagetypeDdlchange(i)" />
                                </nz-form-control>
                              </nz-form-item> -->
      
                            </div>
                            <div nz-col [nzSpan]="6">
                              <!-- <nz-form-item>
      
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
                                  <label>Wastage Type</label>
                                  <nz-select #microwaveRef class="form-select" nzSize="default" [(ngModel)]="i.WastageType" [ngModelOptions]="{standalone: true}" name="Wastagetype" nzPlaceHolder="Product" (ngModelChange)="OnWastagetypeDdlchange(i)">
                                    <nz-option nzValue="%" nzLabel="%"></nz-option>
                                    <nz-option nzValue="Flat" nzLabel="Flat"></nz-option>
                                  </nz-select>
                                </nz-form-control>
                              </nz-form-item> -->
      
                            </div>
                            <div nz-col [nzSpan]="6">
                              <!-- <nz-form-item>
      
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                  <label>Weight Gsm</label>
                                  <input nz-input name="WeightGsm" placeholder="Weight Gsm"  value="i.WeightGsm" [(ngModel)]="i.WeightGsm" disabled [ngModelOptions]="{standalone: true}" />
                                </nz-form-control>
                              </nz-form-item> -->
      
                            </div>
                            <div nz-col [nzSpan]="6">
                              <nz-form-item>
      
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                  <label>Weight Gsm</label>
                                  <input nz-input name="WeightGsm" placeholder="Weight Gsm"  value="i.WeightGsm" [(ngModel)]="i.WeightGsm" disabled [ngModelOptions]="{standalone: true}" />
                                </nz-form-control>
                              </nz-form-item>
      
                            </div>
      
      
      
                          </div>
                        </div>
      
      
                      </div>
                    </div>
                
                    <nz-divider></nz-divider>
                  </div>
      
                </div>
              </div>
              <nz-divider></nz-divider>
              
              <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding-bottom: 12px;">
                    
                    <div nz-col [nzSpan]="15">  
            
                  </div>
                    <div nz-col [nzSpan]="9">
                        <nz-descriptions [nzColumn]="1">
                        <nz-descriptions-item nzTitle="Remark" nzColon="true">
                          <textarea nz-input name="remark" placeholder="Remark" style="height:100px; width:300px; margin-left: 25px;"></textarea>
                        </nz-descriptions-item>
          
                      </nz-descriptions>
                     
                    </div>     
        
                </div>
      
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
          </div>
          <nz-divider></nz-divider>
        </div>
  </form>
  
  
    </div>
  </div>
  
  
  <nz-modal [nzWidth]="800"
            [nzStyle]="{ top: '20px' }"
            [(nzVisible)]="isVisible"
            [nzTitle]="modalTitle"
            [nzContent]="modalContent"
            [nzFooter]=null
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>Select Product</ng-template>
  
    <ng-template #modalContent>
      <nz-table nzSize="small" [nzScroll]="{ y: '200px' }"  #basicTable4 [nzData]="this.listOfSelectedOrderProdects" nzBordered>
        <thead>
          <tr>
            
            <th nzWidth="50px">S.No</th>
            <th>Product Name</th>
            <th>Product Code</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable4.data;let i=index">
            
            <td nzWidth="20px">{{i+1}}</td>
            <td>{{ data.ProductName }}</td>
            <td>{{data.ProductCode}}</td>
            <td>{{ data.Quantity}}</td>
            <td><button nz-button nzType="primary" (click)="OnSelectProduct(data)">Select Product</button></td>
          </tr>
        </tbody>
      </nz-table>
       
       
  
      
    </ng-template>
  </nz-modal>
  