<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">

        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Jumbo List</nz-page-header-title>
            <nz-page-header-subtitle>Manage your Jumbo List here</nz-page-header-subtitle>
            <nz-page-header-extra>
            </nz-page-header-extra>

        </nz-page-header>
        <nz-divider></nz-divider>
        <nz-collapse>
            <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="3">
                            <label>From</label>
                            <nz-date-picker [(ngModel)]="request.FromDate" nzPlaceHolder="Date"
                                style="width: 150px;margin-right: 8px;"></nz-date-picker>
                        </div>
                        <div nz-col [nzSpan]="3">
                            <label>To</label>
                            <nz-date-picker [(ngModel)]="request.ToDate" nzPlaceHolder="Date"
                                style="width: 150px;margin-right: 8px;"></nz-date-picker>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <label>Sale Order No.</label>
                            <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
                                [(ngModel)]="request.SaleOrderNumber" />
                        </div>
                        <div nz-col [nzSpan]="4">
                            <label>Jumbo No.</label>
                            <input nz-input name="jumboNo" nzAllowClear placeholder="Enter Jumbo Number" maxlength="30"
                                [(ngModel)]="request.JumboNumber" />
                        </div>
                        <div nz-col [nzSpan]="4">
                            <label>Customer</label>
                            <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default"
                                nzAllowClear [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                                <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                    [nzLabel]="s.CustomerName"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <label>Formulation Code</label>
                            <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode"
                                nzSize="default" nzAllowClear [(ngModel)]="request.SaleFormulationCodeId"
                                nzPlaceHolder="Choose">
                                <nz-option *ngFor="let s of this.FormulationCodeList;"
                                    [nzValue]="s.SaleFormulationCodeId" [nzLabel]="s.SaleFormulationCode"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col [nzSpan]="3">
                            <label>Color</label>
                            <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                                nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                    [nzLabel]="s.ColorName"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col [nzSpan]="3">
                            <label>Grain Code</label>
                            <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default"
                                nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                                    [nzLabel]="s.GrainCode"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col [nzSpan]="3">
                            <label>Status</label>
                            <nz-radio-group style="margin-top: 7px;" [(ngModel)]="request.IsInspectionCompleted"
                                (ngModelChange)="this.WorkPlanList = []">
                                <label nz-radio nzValue="true">Inspected</label>
                                <label nz-radio nzValue="false">Not Inspected</label>
                            </nz-radio-group>
                        </div>
                    </div>
                    <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                        <div style="display: flex">
                            <button nz-button nzSize="small" nzType="primary" (click)="GetAllWorkPlanList()">
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float:right">
            <!-- <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
                style="width: 150px;margin-right: 8px;"></nz-date-picker>
            <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
                style="width: 150px;margin-right: 8px;"></nz-date-picker>
            <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
                [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()"
                style="margin-right: 8px;">Search</button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button> -->
            <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
                Export</button>
        </div>
        <div>
            <b>
                * Jumbo Print will not be generated for Lining Orders for Sandwich Type Orders once Linking is
                completed.
            </b>
        </div>
        <nz-table nzSize="small" [nzPageSize]="10" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;"
            #basicTable [nzData]="this.WorkPlanList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
            nzShowSizeChanger [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate"
            nzShowQuickJumper="true" nzResponsive=true>
            <thead>
                <tr>
                    <th nzWidth="60px">S.No.</th>
                    <th nzWidth="150px">Jumbo Roll No</th>
                    <th nzWidth="90px">Roll Qty</th>
                    <th nzWidth="150px">Workplan No.</th>
                    <th nzWidth="150px">Sale Order No.</th>
                    <th nzWidth="150px">Formulation Code</th>
                    <th nzWidth="150px">Customer</th>
                    <th nzWidth="150px">Supervisor</th>
                    <th nzWidth="130px">Roll Created Date</th>
                    <th nzWidth="110px">Roll Start Time</th>
                    <th nzWidth="110px">Roll End Time</th>
                    <th nzWidth="110px">Roll Line Speed</th>
                    <th nzWidth="80px">Weight</th>
                    <th nzWidth="150px">Added Date</th>
                    <th nzWidth="150px">AddedBy</th>
                    <th nzWidth="200px" style="text-align:center" nzRight>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.SerialNo}}</td>
                    <td>{{ data.JumboNo}}</td>
                    <td>{{ data.JumboRolQty}}</td>
                    <td>{{ data.WorkPlanNo}}</td>
                    <td><nz-tag [nzColor]="'blue'"><a (click)="OpenViewPop(data)"> {{ data.SaleOrderNumber}}
                            </a></nz-tag></td>
                    <td>{{ data.SaleFormulationCodeName}}</td>
                    <td>{{ data.CustomerName}}</td>
                    <td>{{ data.ShiftSupervisorWorkerName}}</td>
                    <td>{{ data.JumboRollDate}}</td>
                    <td>{{ data.JumboRollStartTime | date: 'hh:mm a':'+0530' }}</td>
                    <td>{{ data.JumboRollEndTime | date: 'hh:mm a':'+0530' }}</td>
                    <td>{{ data.JumboRollPrdSpeed}} MTRs/Minute</td>
                    <td>{{ data.Weight }}</td>
                    <td>{{ data.AddedDate | DatetimeConverter}}</td>
                    <td>{{ data.AddedBy}}</td>
                    <td nzRight style="text-align:center">

                        <button class="btn btn-sm btn-light-primary" (click)="print(data)">Print</button> |
                        <button class="btn btn-sm btn-light-primary" [hidden]="data.IsLiningOrder"
                            (click)="OpenInspection(data)">Grade Print</button>
                    </td>

                </tr>
            </tbody>
        </nz-table>




    </div>
</div>

<!-- To view open drawer  -->


<nz-drawer [nzClosable]="true" [nzVisible]="isVisibleInspection" nzPlacement="right" [nzWidth]="1000"
    nzTitle="Inspection details" (nzOnClose)="handleCancelInspection()">
    <ng-container *nzDrawerContent>
        <button nz-button nzSize="large" nzType="primary"
            (click)="inspectionprintAll(this.InspectionData?.JumboInspection[0],this.InspectionData.SaleOrderId)"
            style="margin-right: 8px;float: right;">Print All</button>
        <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" #basicTable
            [nzData]="this.InspectionData?.JumboInspection" [nzLoading]="isTableLoading" nzBordered>
            <thead>
                <tr>
                    <th nzWidth="50px">S.No</th>
                    <th nzWidth="150px">Code</th>
                    <th nzWidth="150px">Grade</th>
                    <th nzWidth="150px">Quantity</th>

                    <th nzWidth="200px">Added Date</th>
                    <th nzWidth="150px">AddedBy</th>
                    <th nzWidth="100px" style="text-align:center" nzRight>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data;let i=index">
                    <td>{{ i+1}}</td>
                    <td>{{ data.Code}}</td>
                    <td>{{ data.Grade}}</td>
                    <td>{{ data.Quantity}}</td>
                    <td>{{ data.AddedDate | DatetimeConverter}}</td>
                    <td>{{ data.AddedBy}}</td>
                    <td nzRight style="text-align:center">
                        <button class="btn btn-sm btn-light-primary"
                            (click)="inspectionprint(data,this.InspectionData.SaleOrderId)">Print</button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </ng-container>
</nz-drawer>

<app-SoDrawer></app-SoDrawer>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>