
export class MixingModel {
  MixingId: number= 0;
  MixingName: string = '';
  Wastage: number = 0;
  WastageType: string = '';
  WeightGsm: number = 0;
  Total: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  MixingRawMaterial: MixingRawMaterialModel[]=[];
}

export class MixingRawMaterialModel {
  MixingRawMaterialId: number = 0;
  MixingId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Unit: string = '';
  AvgGsm: string = '';
}

export class MixingPrintModel {
  
  MixingName: string = '';
  Wastage: number = 0;
  Total: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  
}
