import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { SaleOrderCostingModel, SaleOrderModel } from "../../Models/SalesOrderModel";
import { AlertMessageService } from "../../Services/AlertMessageService";
import { AuthService } from "../../Services/auth.service";
import { DatetimeConverter } from "../../Services/DatetimeConverter.pipe";
import { LoadingService } from "../../Services/loadingService";
import { PostProcessCostingMasterModel } from "../../Models/MasterModel";
import { SaleOrderCostingService } from "../../Services/SaleOrderCostingService";
import { SaleOrderCostingDataService } from "src/PmsUIApp/Services/SaleOrderCostingData.service";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";

@Component({
    selector: 'app-SaleOrderCostingView',
    templateUrl: './SaleOrderCostingView.component.html'
})

export class SaleOrderCostingViewComponent {
    ApiUrl = environment.Api_Url;
    IsCostingVisible = false;

    IsDisabled: boolean = false;
    count: 0;
    permission = {
        View: false,
        Add: false,
        Edit: false,
        Delete: false,
        Manage: false
    }

    InlineScraping: number;
    havePostProcess: boolean;
    Orderlist!: SaleOrderModel;
    FabricName: string;
    FabricColorName: string;
    RejectionConstant: number = 6;
    OverheadCost: number = 25;
    PerLMConstant: number = 1.45;
    ProductionCostLm: number = 0;
    TotalCostPerLm: number = 0;
    TotalCoating: number = 0;
    TotalFinalGSM: number = 0;
    TotalCostLm: number = 0;
    TotalLaquerPrice: number = 0;
    Rejection: number = 0;
    FabricProductCostPerLm: number = 0;
    MaterialType: string = 'PVC';
    PostProcessCostingList: PostProcessCostingMasterModel[];
    EnableEditing: boolean = false;
    constructor(private SaleOrderCostingService: SaleOrderCostingService, public http: HttpClient, private alertService: AlertMessageService,
        private router: Router,
        private datePipe: DatetimeConverter,
        private auth: AuthService,
        private loader: LoadingService,
        public costingService: SaleOrderCostingDataService
    ) {
        this.SaleOrderCostingService.event_callback.subscribe(x => {
            // this.GetPostProcessCosting()
            // this.GetSaleOrderDetails(x.SaleOrderId);
            this.costingService.GetPostProcessCosting();
            this.costingService.GetSaleOrderDetails(x.SaleOrderId, 'costing')
            this.EnableEditing = false;
        }
        )

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.Costing, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Add);
        this.permission.Edit = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Edit);
    }

    handleCancel() {
        //this.IsPopupOpen = new BehaviorSubject<boolean>(false);
        // this.SaleOrderCostingService.hide();
        this.IsCostingVisible = false;
    }
    PrintListPage() {
        const tempOrderId = this.costingService.Orderlist.SaleOrderId
        const printUrl = `/ordercostingprint/${tempOrderId}/costing`;
        window.open(printUrl, '_blank');
    }
    PrintPageStandardGSM() {
        const tempOrderId = this.costingService.Orderlist.SaleOrderId
        const printUrl = `/ordercostingprint/${tempOrderId}/standard`;
        window.open(printUrl, '_blank');
    }
    EnableEdit() {
        this.EnableEditing = true;
    }
}
