import { UserModel } from "./UserModel";

export class ResponsibilityMasterModel {
  ResponsibilityId: number = 0;
  ResponsibilityCode: string = '';
  Module: string = '';
  ResponsibilityName: string = '';
  ResponsibilityDescripton: string = '';
  AddedBy: string = '';
  AddedDate?: string = '';
  IsChecked: boolean = false;
  AssignedRoles: string = '';
}

export class UserRoleMasterModel {
  UserRoleId: number = 0;
  UserRoleCode: string = '';
  UserRoleName: string = '';
  UserRoleDescripton: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
  IsChecked: boolean = false;
  Responsibilities: ResponsibilityMasterModel[] = [];
  AssignedUsers: UserModel[] = [];
}

export class UsernameUserRoleMappingModel {
  UsernameUserRoleMappingId: number = 0;
  Username: string = '';
  UserRoleId: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
}

export class UserRoleResponsibilityMappingModel {
  UserRoleResponsibilityMappingId: number = 0;
  UserRoleId?: number = 0;
  ResponsibilityId: number = 0;
  AddedBy: string = '';
  AddedDate?: string = '';
}


export class MoudleResponsibilityModel {
  Module: string = '';
  IsChecked: boolean = false;
  Responsibilities: ResponsibilityMasterModel[] = [];
}


export class GeneralConfigurationModel {
  ConfigId: number = 0;
  ConfigItem: string = '';
  ConfigValue: string = '';
  AddedBy: string = '';
  AddedDate?: string = '';
}