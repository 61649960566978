import { SaleOrderProductionMixingModel } from "./SalesOrderModel";
import { OrderModel } from "./SupplierModel";


export class ProductionModel {
  ProductionId: number = 0;
  ProductId: number = 0;
  ProductCode: string= '';
  ProductName: string = '';
  ProductCategory: string = '';
  ManufacturingProductCode: string = '';
  ManufacturingProductName: string = '';
  Lot: number = 0;
  Batch: number = 0;
  OrderQuantity: number = 0;
  ManufacturingQuantity: number = 0;
  Unit: string= '';
  ColorId: number = 0;
  ColorName: string= '';
  Barcode: string= '';
  GrainId: number = 0;
  AddedBy: string = '';
  GrainName: string = '';
  GrainCode: string= '';
  AddedDate: string = '';
  ProductionStatus: string = '';
  CostingStatus: string = '';
  TotalCost: number = 0;
  SlippagePercent: number = 0;
  PerUnitCost: number = 0;
  MixingTotalCost: number = 0;
  ProcessTotalCost: number = 0;
  MixingGSMTotal: number = 0;
  RawGSMTotal: number = 0;
  FinalMixingGSMTotal: number = 0;
  FinalProcessGSMTotal: number = 0;
  ProcessGSMTotal: number = 0;
  Width: number = 0;
  Thick: number = 0;
  PrintMasterId: number = 0;

  Print!: ProductionPrintMasterModel;
  VacuumMasterId: number = 0;
  Vacuum!: ProductionVaccumModel;
  TumblingMasterId: number = 0;
  Tumbling!: ProductionTumblingModel;
  LacquerMasterId: number = 0;
  Lacquer!: ProductionLacquerModel;
  EmbossingMasterId: number = 0;
  Embossing!: ProductionEmbossingModel;
  ProcessFormulationCode: string = '';
  MixingFormulationCode: string = '';
  Orders: OrderModel[] = [];
  ProductionOrder: ProductionOrderModel[] = [];
  ProductionRawMaterial: ProductionRawMaterialModel[] = [];
  ProductionElement: ProductionElementModel[] = [];
  Process: ProductionProcessModel[] = [];
  Mixing: ProductionMixingModel[] = [];
  FormulationMixing: SaleOrderProductionMixingModel[] = [];
}

export class ProductionElementModel {
  productionElementId: number = 0;
  productionId: number = 0;
  ElementId: number = 0;
  ElementName: string= '';
  Value: string = '';
  PerUnitCost: number = 0;
  TotalCost: number = 0;
}

export class ProductionRawMaterialModel {
  productionRawMaterialId: number = 0;
  productionId: number = 0;
  ProductId: number = 0;
  ProductName: string= '';
  ProductCode: string= '';
  Unit: string = '';
  AvgGsm: string = '';
  Quantity: number = 0;
  PerUnitCost: number = 0;
  TotalCost: number = 0;
}
export class ProductionOrderModel {
  productionOrderId: number = 0;
  productionId: number = 0;
  orderId: number = 0;
  ProductCode: string = '';
  Unit: string = '';
  SaleOrderNo: string = '';
  Products: string = '';
  Value: number = 0;
}

export class ProductionProcessModel {
  ProductionProcessId: number = 0;
  ProductionProcessName: string = '';
  ProductionId: number = 0;
  Temperature: number = 0;
  WeightPressure: number = 0;
  WeightGsm: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  ProductionProcessRawMaterial: ProductionProcessRawMaterial[] = [];
}
export class ProductionProcessRawMaterial {
  ProductionProcessRawMaterialId: number = 0;
  ProductionProcessId: number = 0;
  ProductId: number = 0;
  ProductCode: string = '';
  ProductName: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Unit: string = '';
  AvgGsm: string = '';
}
export class ProductionMixingModel {
  ProductionMixingId: number = 0;
  ProductionMixingName: string = '';
  ProductionId: number = 0;
  Wastage: number = 0;
  WastageType: string = '';
  WeightGsm: number = 0;
  Total: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  ProductionMixingRawMaterial: ProductionMixingRawMaterial[] = [];
}
export class ProductionMixingRawMaterial {
  ProductionMixingRawMaterialId: number = 0;
  ProductionMixingId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Unit: string = '';
  AvgGsm: string = '';
}

export class ProductionPrintModel {

  ProductName: string = '';
  ProductCode: string = '';
  ManufacturingProductName: string = '';
  Lot: number = 0;
  Batch: number = 0;
  OrderQuantity: number = 0;
  ManufacturingQuantity: number = 0;
  Unit: string = '';
  ColorName: string = '';
  Barcode: string = '';
  AddedDate: string = '';
  AddedBy: string = '';
  ProductionStatus: string = '';
}
export class ProductionVaccumModel {
  VacuumMasterId: number = 0;
  Code: string= '';
  Name: string= ''; 
  Description: string= '';
  AddedBy: string= '';
  AddedDate: string = '';
}
export class ProductionTumblingModel {
  TumblingMasterId: number = 0;
  Code: string= '';
  Name: string= ''; 
  Description: string= '';
  AddedBy: string= '';
  AddedDate: string = '';
}

export class ProductionLacquerModel {

  LacquerMasterId: number = 0;
  Code: string= '';
  Name: string= ''; 
  Description: string= '';
  AddedBy: string= '';
  AddedDate: string = '';
  Wastage: number = 0;
  WastageType: string = '';
  WeightGsm: number = 0;
  Total: number = 0;
  LacquerRawMaterial: LacquerRawMaterial[] = [];
}
export class LacquerRawMaterial {
  LacquerRawMaterialId: number = 0;
  LacquerMasterId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Unit: string = '';
  AvgGsm: string = '';
}
export class ProductionEmbossingModel {
  EmbossingMasterId: number = 0;
  Code: string= '';
  Name: string= ''; 
  ImageName: string= ''; 
  Description: string= '';
  AddedBy: string= '';
  AddedDate: string = '';
}
export class ProductionPrintMasterModel {
  PrintMasterId: number = 0;
  Code: string = '';
  Name: string = '';
  ImageName: string = '';
  Description: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
}




