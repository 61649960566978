<div *ngIf="this.CostEstimationListOriginal">
    <div class="producttable-container">
        <table class="producttable" style="width: 100%; page-break-inside: auto;">
            <thead>
                <tr style="font-size: small;">
                    <th nzWidth="7%">S.No.</th>
                    <th nzWidth="9%">Estimation Order ID</th>
                    <th nzWidth="7%" style="text-align:center">Estimation Order Status</th>
                    <th nzWidth="19%">Customer Name</th>
                    <th nzWidth="10%">Product Category Name</th>
                    <th nzWidth="10%">Manufacturing Product Name</th>
                    <th nzWidth="5%">Order Quantity</th>
                    <th nzWidth="5%">LMconstant</th>
                    <th nzWidth="5%">Manufacturing Quantity</th>
                    <th nzWidth="7%">Color</th>
                    <th nzWidth="5%">Grain</th>
                    <th nzWidth="7%">Grain Price</th>
                    <th nzWidth="5%">Thick</th>
                    <th nzWidth="5%">Width</th>
                    <th nzWidth="5%">Estimation Price</th>
                    <th nzWidth="5%">Production Cost LM</th>
                    <th nzWidth="5%">Total Finish Price</th>
                    <th nzWidth="5%x">Overhead Cost</th>
                    <th nzWidth="5%">Rejection</th>
                    <th nzWidth="7%">Total Cost Per LM</th>
                    <th nzWidth="7%">Line Speed</th>
                    <th nzWidth="7%">Total Profit Loss</th>
                    <th nzWidth="7%">Remarks</th>
                    <th nzWidth="7%">Added By</th>
                    <th nzWidth="7%">Added Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of CostEstimationListOriginal; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data?.EstimationOrderId }}</td>
                    <td style="text-align: center">
                        {{ data?.EstimationOrderStatus?.EstimationOrderStatus }}
                    </td>
                    <td>{{ data?.CustomerName}}</td>
                    <td>{{ data?.ProductCategoryName }}</td>
                    <td>{{ data?.ManufacturingProductName }}</td>
                    <td>{{ data?.OrderQuantity }}</td>
                    <td>{{ data?.Lmconstant }}</td>
                    <td>{{ data?.ManufacturingQuantity }}</td>
                    <td>{{ data?.ColorName }}</td>
                    <td>{{ data?.GrainName }}</td>
                    <td>{{ data?.GrainPrice }}</td>
                    <td>{{ data?.ThicknessNumber }}</td>
                    <td>{{ data?.WidthNumber }}</td>
                    <td>{{ data?.EstimationPrice }}</td>
                    <td>{{ data?.ProductionCostLm }}</td>
                    <td>{{ data?.TotalFinishPrice }}</td>
                    <td>{{ data?.OverheadCost }}</td>
                    <td>{{ data?.Rejection }}</td>
                    <td>{{ data?.TotalCostPerLm }}</td>
                    <td>{{ data?.LineSpeed }}</td>
                    <td>{{ data?.TotalProfitLoss }}</td>
                    <td>{{ data?.Remarks }}</td>
                    <td>{{ data?.EstimationOrderStatus.AddedBy }}</td>
                    <td>{{ data?.EstimationOrderStatus?.AddedDate }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>