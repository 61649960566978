<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Manage Overhead Details</nz-page-header-title>

      <nz-page-header-extra>

        <button nz-button nzType="primary" *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <!--<div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>-->
    <nz-table nzSize="small" [nzData]="['']" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable1
      nzBordered [nzPageSize]="10" nzShowPagination="true" nzShowSizeChanger [nzPageSizeOptions]=[50,100,200,500]>
      <thead>
        <tr>

          <th nzWidth="60px">S.No. </th>
          <th nzWidth="180px" nzLeft> Applicable On & After </th>
          <th nzWidth="150px" style="text-align:center">Per Hour Avg Cost</th>
          <th nzWidth="150px" style="text-align:center">Added By</th>
          <th nzWidth="120px" style="text-align:center">Added Date</th>
          <th nzWidth="150px" style="text-align:center">Updated By</th>
          <th nzWidth="120px" style="text-align:center">Updated Date</th>
          <th nzWidth="140px" nzRight style="text-align:center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of OverheadCostList;let i=index">
          <td nzWidth="20px">
            {{i+1}}
          </td>
          <td nzLeft>{{data.ApplicableOn | date:'dd-MMM-yyyy'}}</td>
          <td>{{data.OverheadCost}}</td>
          <td>{{data.AddedBy}}</td>
          <td>{{data.AddedDate | DatetimeConverter}}</td>
          <td>{{data.UpdatedBy}}</td>
          <td>{{data.UpdatedDate == null ? 'New': data.UpdatedDate | DatetimeConverter}}</td>
          <td nzRight style="text-align:center">
            <button *ngIf="this.permission.Edit" nz-button nzSize="small" nzType="primary"
              style="border-radius: 4px;font-weight: 600;" (click)="OpenEditPop(data)">Edit</button>
          </td>
        </tr>

      </tbody>

    </nz-table>



  </div>
</div>

<nz-modal [nzWidth]="400" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>{{isNew==true?'Add':'Modify'}} Overhead Details</ng-template>

  <ng-template #modalContent>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">

        <div class="row gx-10 mb-5">
          <div class="col-lg-8">
            <label class="required form-label">Applicable On and After</label>
            <nz-date-picker nzPlaceHolder="Applicable On" class="form-control"
              [(ngModel)]="AddOverheadCost.ApplicableOn" nzAllowClear></nz-date-picker>
          </div>

        </div>
        <div class="row gx-10 mb-5">
          <div class="col-lg-8">
            <label class="required form-label">Per Hour Avg Overhead Cost</label>
            <input nz-input nzSize="large" [(ngModel)]="AddOverheadCost.OverheadCost">
          </div>

        </div>


      </div>

    </div>

  </ng-template>
  <ng-template #modalFooter>
    <div class="text-center">
      <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="Save()">Save</button>
    </div>
  </ng-template>
</nz-modal>