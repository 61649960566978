import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../Services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private msalService: MsalService) {}
  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // if (this.msalService.instance.getActiveAccount() != null) {
    if (this.msalService.instance.getAllAccounts().length > 0) {
    
      return true;
    }
    else {
      return false;
    }

  }
//  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//    let storeData = localStorage.getItem("isUserLoggedIn");
//    if( storeData != null && storeData == "true")
//         this.authService.isUserLoggedIn = true;
//      else
//         this.authService.isUserLoggedIn = false;
//    const currentUser = this.authService.isUserLoggedIn;
//    if (currentUser) {
//        // logged in so return true
//        return true;
//    }

//    // not logged in so redirect to login page with the return url
//    this.router.navigate(['/home'], { queryParams: { returnUrl: state.url } });
//    return false;
//}
  
  
}
