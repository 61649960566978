export class ConsumeStockProductMasterModel {
  SerialNo: number = 0;
  ConsumeStockProductId: number = 0;
  RackId?: number = 0;
  RackName: string = ''
  RackCode: string = ''
  StoreId?: number = 0;
  StoreName: string = ''
  StoreCode: string = ''
  ProductId: number = 0;
  ProductName: string = ''
  ProductCode: string = ''
  Quantity: number = 0;
  SCQuantity?: number = 0;
  Purpose: string = '';
  Unit: string = ''
  ConsumedDate?: string = ''
  AddedBy: string = ''
  AddedDate: string = ''
  SaleOrderId?: number = 0;
  SaleOrderNumber: string = ''
  StockId?: number = 0;
  StockProductId?: number = 0;
  IsDamaged?: boolean = false;
}



export class ConsumeStockProductPrintMasterModel {
  ProductName: string = ''
  SaleOrderNumber: string = ''
  Quantity: number = 0;
  IsDamaged?: boolean = false
  StoreName: string = ''
  RackName: string = ''
  AddedDate?: string = ''
  Purpose: string = ''
  AddedBy: string = ''
}

export class ConsumptionPendingListModel {
  SerialNo: number;
  SaleOrderNo: string;
  SaleOrderId: number;
  WorkPlanNo: number;
  WorkPlanId: number;
  OrderQty: number;
  MFDQTY: number;
  CustomerName: string;
  FormulationCode: string;
  GrainCode: string;
  ColorName: string;
  Thickness: string;
  ArticleName: string;
  PreInspectionCompletedBy: string;
  PreInspectionCompletedDate: string;
  PreInspectionStore: string;
  ProductionCompletedBy: string;
  ProductionCompletedDate: string;
}
export class ConsumptionPendingPrintModel {
  SaleOrderNo: string;
  WorkPlanNo: number;
  OrderQty: number;
  MFDQTY: number;
  PreInspectionCompletedBy: string;
  PreInspectionCompletedDate: string;
  PreInspectionStore: string;
  ProductionCompletedBy: string;
  ProductionCompletedDate: string;
}

export class ConsumedSaleOrderProductsStockModel {
  SerialNo: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductType: string = '';
  ProductCode: string = '';
  Batch: string = '';
  Unit: string = '';
  PerUnitPrice: number = 0;
  StockId: number = 0;
  StockProductId: number = 0;
  StoreId: number = 0;
  StoreName: string = '';
  RackId: number = 0;
  RackName: string = '';
  IsDamaged?: boolean = false;
  Damaged: string = '';
  ConsumedQty: number = 0;
  ConsumedScQty: number = 0;
  Remark: string = '';
  MaterialCategory: string = '';
  ConsumedBy: string = '';
  ConsumedDate: string = '';
}