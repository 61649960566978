<!-- <nz-alert *ngIf="this.innerWidth<=1000" nzType="error" nzMessage="Please change to landscape mode"></nz-alert> -->
<!-- <div class="card card-flush h-xl-100" *ngIf="this.innerWidth>1000"> -->
  <div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Gate entry List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your gate entry here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()" *ngIf="this.permission.Add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <div>Page is best viewed in Landscape mode.</div>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Date Type</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.DateType" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.dateTypeList;" [nzValue]="s.Value"
                      [nzLabel]="s.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>From Date</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="fromdate" class="form-control"
                    [(ngModel)]="request.FromDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>To Date</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="todate" class="form-control"
                    [(ngModel)]="request.ToDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Purchase Order No.</label>
                  <input nz-input name="purchaseOrderNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                    [(ngModel)]="request.PONumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Supplier</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.SupplierId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                      [nzLabel]="s.SupplierName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Invoice No.</label>
                  <input nz-input name="invoiceNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                    [(ngModel)]="request.InvoiceNumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Transport</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    [(ngModel)]="request.TransportId" (ngModelChange)="GetAllVehicleForFilter($event)"
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                      [nzLabel]="s.TransportCompanyName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Vehicle</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="Vehicle" nzSize="default"
                    [(ngModel)]="request.VehicleId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilterVehicleList;" [nzValue]="s.VehicleId"
                      [nzLabel]="s.VehicleNumber"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Gate In Type</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="GateInType" nzSize="default"
                    [(ngModel)]="request.Type" nzPlaceHolder="Choose">
                    <nz-option nzValue="Delivery" nzLabel="Delivery"></nz-option>
                    <nz-option nzValue="Order Dispatch" nzLabel="Order Dispatch"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Gate Pass</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="GatePass" nzSize="default"
                    [(ngModel)]="request.IsGatePassIssued" nzPlaceHolder="Choose">
                    <nz-option nzValue=true nzLabel="Issued"></nz-option>
                    <nz-option nzValue=false nzLabel="Not Issued"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                (click)="GetAllGateInRecords()">
                Apply Filter
              </button>
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="ResetFilters()">
                Reset
              </button>
              <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search" [(ngModel)]="searchValue"
        (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>

    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.GateInList" nzBordered
      [nzLoading]="isTableLoading" [nzScroll]="{ x: '1200px',y:'515px' }" nzShowPagination="true" nzShowSizeChanger
      [nzPageSizeOptions]=[50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="50px">Id</th>
          <th nzLeft nzWidth="150px">Vehicle Number</th>
          <th nzWidth="350px">Transport Company</th>
          <th nzWidth="130px">PO Number</th>
          <th nzWidth="150px">Invoice Number</th>
          <th nzWidth="150px">Type</th>
          <th nzWidth="200px">Driver Name</th>
          <th nzWidth="150px">Driver Number</th>

          <th nzWidth="180px">Gate-In Date</th>
          <th nzWidth="180px">Gate Pass Issued Date</th>
          <th nzWidth="180px">Out Date</th>
          <th nzWidth="180px">Added By</th>

          <th nzWidth="100px" style="text-align:center">Gate-In</th>
          <th nzWidth="100px" style="text-align:center">Pass Issued</th>
          <th nzWidth="100px" style="text-align:center">Gate-Out</th>
          <th nzWidth="150px" style="text-align:center" nzRight></th>
          <!-- <th nzWidth="20%">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.GateInId }}</td>
          <td nzLeft>{{ data.VehicleNumber }}</td>
          <td>{{ data.TransportCompanyName }}</td>
          <td>{{ data.Invoice[0]?.PONumber }}</td>
          <td>{{ GetInvoices(data.Invoice) }}</td>
          <td>{{ data.Type }}</td>
          <td>{{ data.GateInPerson }}</td>
          <td>{{ data.GateInPersonContact }}</td>


          <td>{{ data.GateInDate | DatetimeConverter}}</td>
          <td>{{ data.GatePassIssueDate | DatetimeConverter}}</td>
          <td>{{ data.GateOutDate | DatetimeConverter}}</td>
          <td>{{ data.AddedBy }}</td>
          <td style="text-align:center">
            <div class="badge badge-light-danger" *ngIf="!data.GateIn">Not yet</div>
            <div class="badge badge-light-success" *ngIf="data.GateIn">Completed</div>
          </td>

          <td style="text-align:center">
            <div class="badge badge-light-danger" *ngIf="!data.GatePassIssue">Not-Issued</div>
            <div class="badge badge-light-success" *ngIf="data.GatePassIssue">Issued</div>
          </td>

          <td style="text-align:center">
            <div class="badge badge-light-danger" *ngIf="!data.GateOut">Not Yet</div>
            <div class="badge badge-light-success" *ngIf="data.GateOut">Completed</div>
          </td>
          <td style="text-align:center" nzRight>
            <button class="btn btn-sm btn-light-primary" (click)="GetImages(data.GateInId)">View Images</button>

          </td>
        </tr>
      </tbody>
    </nz-table>


    <nz-modal [nzWidth]="1000" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24" *ngIf="!isDispatchSaleOrder">
              <nz-form-item>
                <nz-form-label [nzSpan]="5">Purchase Order</nz-form-label>
                <nz-form-control [nzSpan]="18" nzErrorTip="Select supplier Name">
                  <nz-select #microwaveRef class="form-select mb-2" nzShowSearch nzSize="default"
                    (ngModelChange)="onSelectedPOChange($event)" [(ngModel)]="selectedPOValue"
                    [ngModelOptions]="{standalone: true}" name="PO" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilteredPurchaseOrderList;" [nzValue]="s.Poid"
                      [nzLabel]="s.Ponumber"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col [nzSpan]="12">

              <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Transport Name</nz-form-label>
                <nz-form-control [nzSpan]="12" nzErrorTip=" Select Transport Name ">
                  <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                  <nz-select class="form-select mb-2" nzShowSearch formControlName="TransportId" name="TransportId"
                    nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllVehicle($event)">
                    <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s"
                      [nzLabel]="s.TransportCompanyName"></nz-option>
                  </nz-select>
                </nz-form-control>
                <a class="btn btn-sm" title="Add new" (click)="showModalT()"><i nz-icon nzType="plus-circle"
                    nzTheme="fill" style=" margin-bottom: 3px;"></i></a>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Vehicle Number</nz-form-label>
                <nz-form-control [nzSpan]="12" nzErrorTip="Select valid vehicle number">
                  <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                  <nz-select class="form-select mb-2" nzShowSearch formControlName="VehicleId" name="VehicleId"
                    nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.VehicleList;" [nzValue]="s.VehicleId"
                      [nzLabel]="s.VehicleNumber"></nz-option>
                  </nz-select>
                </nz-form-control>
                <a class="btn btn-sm" title="Add new" (click)="showModalV()"><i nz-icon nzType="plus-circle"
                    nzTheme="fill" style=" margin-bottom: 3px;"></i></a>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">


              <nz-form-item>
                <nz-form-label [nzSpan]="10">GateIn Driver Name</nz-form-label>
                <nz-form-control [nzSpan]="12" nzErrorTip="Contact Driver Name required">
                  <input nz-input formControlName="GateInPerson" name="GateInPerson" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label [nzSpan]="10">GateIn Driver Number</nz-form-label>
                <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
                  <input nz-input formControlName="GateInPersonContact" name="GateInPersonContact" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="24">
              <nz-form-item>
                <nz-form-label [nzSpan]="5">Upload Image</nz-form-label>
                <nz-form-control [nzSpan]="18" nzErrorTip="Contact Person Name required">
                  <input class="form-select mb-2" [(ngModel)]="uploadinvoice" [ngModelOptions]="{standalone: true}"
                    type="file" accept="image/*" multiple="multiple" (change)="onChange($event)">
                  <div *ngIf="filesSelected" hidden="true">
                    <h2>Upload Progress</h2>
                    <pre>{{uploadProgress$ | async | json}}</pre>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>


          <nz-tabset>
            <nz-tab nzTitle="Select Invoice" (nzClick)="this.isDispatchSaleOrder = false">
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="10" nzRequired>Supplier Name</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Select supplier Name">
                      <!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
                      <nz-select #microwaveRef class="form-select mb-2" nzShowSearch nzSize="default"
                        (ngModelChange)="onSelectedSupplierChange($event)" [(ngModel)]="InvoiceModel.SupplierId"
                        [ngModelOptions]="{standalone: true}" name="SupplierName" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                          [nzLabel]="s.SupplierName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="10" nzRequired>Invoice Number</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Enter Invoice number">
                      <nz-select nzShowSearch #microwaveRef class="form-select " *ngIf="IsChecked"
                        (ngModelChange)="onSelectedInvoiceChange($event)" nzSize="default"
                        [(ngModel)]="InvoiceModel.InvoiceId" [ngModelOptions]="{standalone: true}" nzAllowClear
                        nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.InvoiceList;" [nzValue]="s.InvoiceId"
                          [nzLabel]="s.InvoiceNumber"></nz-option>
                      </nz-select>
                      <input nz-input name="GateInPersonContact" *ngIf="!IsChecked"
                        [(ngModel)]="InvoiceModel.InvoiceNumber" [ngModelOptions]="{standalone: true}" />
                    </nz-form-control>
                  </nz-form-item>

                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item style="margin-left:100px">

                    <label nz-checkbox [(ngModel)]="IsChecked" [ngModelOptions]="{standalone: true}">Existing
                      Invoice</label>
                  </nz-form-item>

                </div>
                <div nz-col [nzSpan]="4">
                  <a class="btn btn-sm btn-light-primary" style="float:right  " (click)="handleAddInvoice()"><i nz-icon
                      nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add</a>
                </div>
              </div>
              <nz-table nzSize="small" style="width: 100%;" #basicTable1 [nzData]="['']" nzBordered>
                <thead>
                  <tr>

                    <th>Supplier</th>
                    <th>Invoice No.</th>
                    <th>PO No.</th>
                    <th></th>
                    <!-- <th nzWidth="20%">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of InvoiceMappingModel">
                    <td>{{ data.SupplierName }}</td>
                    <td>{{ data.InvoiceNumber }}</td>
                    <td>{{ data.Ponumber }}</td>
                    <td>
                      <a class="btn btn-sm btn-light-danger" style="float:right  "
                        (click)="handleRemoveInvoice(data)"><i nz-icon nzType="minus-circle" nzTheme="fill"
                          style=" margin-bottom: 3px;"></i>Remove</a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
              <nz-divider></nz-divider>


              <div class="text-center">
                <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
              </div>

            </nz-tab>
            <!--<nz-tab nzTitle="Select Order to Dispatch" (nzClick)="GetAllSalesOrder()">
              <nz-table
                [nzScroll]="{y: '515px' }"
                nzSize="small"
                [nzPageSize]="100"
                #basicTable
                [nzData]="this.SalesList"
                [nzLoading]="isTableLoading"
                nzBordered
              >
    
              <thead>
                <tr>
                  <th nzWidth="30px"></th>
                  <th nzWidth="150px">Sale Order No.</th>
                  <th nzWidth="150px">Customer</th>
                  <th nzWidth="100px">Category</th>
                  <th nzWidth="100px">MFG Name</th>
                  <th nzWidth="80px">Order QTY</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicTable.data">
                  <td>
                    <input
                      style="margin-right: 5px"
                      type="checkbox"
                      [checked]="data.IsChecked"
                      (change)="data.IsChecked = !data.IsChecked;"
                    />
                  </td>

                  <td>{{ data.SaleOrderNumber }}</td>
                  <td>{{ data.CustomerName }}</td>
                  <td>{{ data.Category }}</td>
                  <td>{{ data.SaleOrderProduction.ManufacturingProductName }}</td>
                  <td>{{ data.SaleOrderProduction.OrderQuantity }}</td>
                </tr>
              </tbody>
              </nz-table>
              <nz-divider></nz-divider>
    
    
              <div class="text-center">
                <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
              </div>
            </nz-tab>-->
          </nz-tabset>



        </form>
      </ng-template>



    </nz-modal>

    <nz-modal [nzWidth]="600" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isImageVisible" [nzTitle]="modalTitle1"
      [nzContent]="modalContent1" [nzFooter]=null (nzOnCancel)="handleCancelImage()">
      <ng-template #modalTitle1>GateIn Images</ng-template>

      <ng-template #modalContent1>
        <!--<nz-carousel nzAutoPlay *ngIf="FilteredImageArray.length>0">
        <div nz-carousel-content *ngFor="let index of FilteredImageArray">

          <img src="{{ index.URL }}" />
        </div>
      </nz-carousel>-->
        <!-- =" -->

        <div class="row" *ngIf="FilteredImageArray.length>0">
          <div class="column" *ngFor="let index of FilteredImageArray">
            <a href="{{ index.URL }}" target="_blank">
              <img src="{{ index.URL }}" alt="Snow" style="width:100%">
            </a>
          </div>

        </div>
        <div *ngIf="FilteredImageArray.length==0">No image found</div>
      </ng-template>



    </nz-modal>

    <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisibleT" [nzTitle]="modalTitleT"
      [nzContent]="modalContentT" [nzFooter]=null (nzOnCancel)="handleCancelT()">
      <ng-template #modalTitleT>{{PopUpTitleT}}</ng-template>

      <ng-template #modalContentT>
        <form nz-form [formGroup]="validateFormT" (ngSubmit)="handleOkT()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8">Company Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="NameError">
              <input nz-input formControlName="TransportCompanyName" name="TransportCompanyName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Company Contact Number</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Contact Number">
              <input nz-input formControlName="TransportCompanyContact" type="number" name="TransportCompanyContact" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Email</nz-form-label>
            <nz-form-control pattern="emailPattern" [nzSpan]="14" nzErrorTip="Invalid Email">
              <input nz-input formControlName="TransportCompanyEmail" name="TransportCompanyEmail" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Company Address</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Transport Company Address required">
              <input nz-input formControlName="TransportCompanyAddress" name="TransportCompanyAddress" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">GST / TAX</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Valid GST number required">
              <input nz-input formControlName="Gst" name="Gst" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>

            <nz-form-label [nzSpan]="8">Vehicle Number</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Vehicle Number">
              <input nz-input formControlName="VehicleNumber" name="VehicleNumber" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Vehicle Type</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Vehicle Type">
              <input nz-input formControlName="VehicleType" name="VehicleType" />
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoadingT">Save Transport</button>
          </div>

        </form>
      </ng-template>


    </nz-modal>

    <nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisibleV" [nzTitle]="modalTitleV"
      [nzContent]="modalContentV" [nzFooter]=null (nzOnCancel)="handleCancelV()">
      <ng-template #modalTitleV>{{PopUpTitleV}}</ng-template>

      <ng-template #modalContentV>
        <form nz-form [formGroup]="validateFormV" (ngSubmit)="handleOkV()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8">Vehicle Number</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Vehicle Number">
              <input nz-input formControlName="VehicleNumber" name="VehicleNumber" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Vehicle Type</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Vehicle Type">
              <input nz-input formControlName="VehicleType" name="VehicleType" />
            </nz-form-control>
          </nz-form-item>



          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoadingV">Save Vehicle</button>
          </div>

        </form>
      </ng-template>


    </nz-modal>
  </div>
</div>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>