<div *ngIf="IsPopupOpen | async">
  


  <nz-modal [nzWidth]="1000"
            
            nzVisible="true"
            [nzTitle]="modalTitle"
            [nzContent]="modalContent"
            [nzFooter]=null
            nzCentered="true"
            (nzAfterOpen)="handleOpen()"
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>Add Demand</ng-template>

    <ng-template #modalContent>
      <div>
        <div class="mb-10 fv-row">
          <div class="row gx-10 mb-5">
            <div class="col-lg-3">
              <label class="required form-label">Product Type</label>
              <nz-select class="form-select mb-2" nzSize="large" [disabled]="IsDisabled" [(ngModel)]="SelectedProductType" (ngModelChange)="onSelectedProductTypeChange()" nzAllowClear nzPlaceHolder="Choose">
                <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
              </nz-select>
            </div>
            <div class="col-lg-3">
              <label class="required form-label">Category</label>

              <nz-select #microwaveRef class="form-select " [disabled]="IsDisabled"  nzShowSearch nzSize="large" [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
              </nz-select>
            </div>
            <div class="col-lg-3">
              <label class="required form-label">SubCategory</label>
              <nz-select #microwaveRef class="form-select " [disabled]="IsDisabled"  nzShowSearch nzSize="large" [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllSecondCategory($event)">
                <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
              </nz-select>
            </div>
            <div class="col-lg-3">
              <label class="required form-label">2nd SubCategory</label>
              <nz-select #microwaveRef class="form-select " [disabled]="IsDisabled"  nzShowSearch nzSize="large" [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="SecondCategoryID" (ngModelChange)="GetSecondCategoryFilteredProduct()">
                <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
              </nz-select>
            </div>

            <div class="col-lg-4">
              <label class="required form-label">Product Name</label>
              <nz-select nzShowSearch class="form-select " [disabled]="IsDisabled"  nzSize="large" [(ngModel)]="NewDemand.ProductId" name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
              </nz-select>
            </div>
            <div class="col-lg-2">
              <label class="required form-label">Measure unit</label>
              <nz-select nzShowSearch class="form-select mb-2" [disabled]="IsDisabled"  nzSize="large" [(ngModel)]="NewDemand.Unit" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
              </nz-select>
            </div>
            <div class="col-lg-3">
              <label class="required form-label">Quantity</label>
              <input type="text" name="Quantity" style="border: 1px solid #d9d9d9;" [(ngModel)]="NewDemand.Quantity" class="form-control" placeholder="Quantity" value="" nzSize="large" />
            </div>
            <div class="col-lg-3" >
              <label class="required form-label">Store</label>
              <nz-select nzShowSearch class="form-select mb-2" nzSize="large" [(ngModel)]="NewDemand.StoreId" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="mb-10 fv-row">
          <div class="row gx-10 mb-5">
          
            
           
           
            <div class="col-lg-1000.2" style="text-align: right;padding-top: 25px;">
              <button type="submit" id="kt_ecommerce_add_product_submit" (click)="Save()" class="btn btn-success">
                <span class="indicator-label">Save</span>
                <span class="indicator-progress">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
      
      </div>
        </div>

      </div>
    </ng-template>



  </nz-modal>
</div>
