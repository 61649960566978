import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-startscreen',
  templateUrl: './startscreen.component.html',
  styleUrls: ['./startscreen.component.css']
})
export class StartscreenComponent implements OnInit {
  switchURL = environment.switchURL;
  switchLeatherURL = environment.switchLeatherURL;
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('token') == undefined || localStorage.getItem('expiresOn') == undefined || localStorage.getItem('userName') || localStorage.getItem('homeAccountId')) {
      localStorage.removeItem('token');
      localStorage.removeItem('homeAccountId');
      localStorage.removeItem('expiresOn');
      localStorage.removeItem('userName');
    }
  }
  GotoBelt() {
    //this.router.navigate([this.switchURL])
    location.href = this.switchURL

  }
  GotoLeather() {
    location.href = this.switchLeatherURL;
  }
}
