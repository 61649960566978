import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductionPrintMasterModel } from 'src/PmsUIApp/Models/ProductionModel';
import { combineLatestAll, from, map, Observable, Observer } from 'rxjs';
import { IUploadProgress } from 'src/PmsUIApp/Models/UploadModel';
import { ISasToken } from 'src/PmsUIApp/azure-storage/azureStorage';
import moment from 'moment';
import { BlobStorageService } from 'src/PmsUIApp/azure-storage/blob-storage.service';
import { InterceptorSkipHeader } from 'src/PmsUIApp/Services/HttpInterceptorService';
import { StorageService } from 'src/PmsUIApp/Services/storage.service';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';

@Component({
  selector: 'app-postprocessprint',
  templateUrl: './postprocessprint.component.html',
  styleUrls: ['./postprocessprint.component.css'],
})
export class PostprocessprintComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  PrintList: ProductionPrintMasterModel[] = [];
  PrintListOriginal2: ProductionPrintMasterModel[] = [];
  PrintListOriginal: ProductionPrintMasterModel[] = [];
  NewPrint: ProductionPrintMasterModel = new ProductionPrintMasterModel();
  StorageAccContainerName = 'productionprint';
  uploadProgress$: Observable<IUploadProgress[]> | undefined;
  filesSelected = false;
  uploadPrintFile: any;
  postToken: any;
  getToken: any;
  DateInFileUploadPath: string = moment(new Date()).format('YYYY/MM/DD');
  ImageArray: any[] = [];
  FilteredImageArray: any[] = [];
  isImageVisible = false;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Print';
  PrintNameError = 'Enter Print name';
  PrintCodeError = 'Enter  Print Code';
  searchValue = ''
  exportoptions = {
    headers: ['PrintName', 'Code', 'Image, Description'],
  };
  fields: any;
  exportfields = [
    {
      PrintName: '',
      Code: '',
      Image: '',
      Description: '',
      PrintMasterId: '',
    },
  ];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  IsNewFile = false;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private blobStorage: BlobStorageService,
    private storage: StorageService, private auth: AuthService, private router: Router
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Print, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Print, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Print, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      PrintName: [
        null,
        [Validators.required, Validators.minLength(3)],
        [this.VaccumNameAsyncValidator],
      ],
      Code: [null, [Validators.required], [this.VaccumCodeAsyncValidator]],
      Image: [null],
      Description: [null, [Validators.maxLength(200)]],
    });
    this.GetAllPrint();
    // this.getStorageSASToken(this.StorageAccContainerName);
  }
  get f() {
    return this.validateForm.controls;
  }

  GetAllPrint() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'print/getallprints';
    this.http.get<ProductionPrintMasterModel[]>(url).subscribe(
      (res) => {
        this.PrintList = res;
        this.PrintList = res;
        this.PrintListOriginal2 = res;

        this.exportfields = [];
        this.PrintListOriginal.forEach((x) => {
          this.fields = {};
          this.fields.PrintName = x.Name;
          this.fields.Code = x.Code;
          this.fields.ImageName = x.ImageName;
          this.fields.Description = x.Description;
          this.fields.PrintMasterId = x.PrintMasterId;
          this.exportfields.push(this.fields);
        });
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllPrint(); }
      }
    );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search() {

    var res = this.PrintListOriginal2;
    this.PrintList = res.filter((item: ProductionPrintMasterModel) => {


      if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.PrintMasterId == +this.searchValue
      ) {
        return true;
      } else if (
        item?.Code == this.searchValue
      ) {
        return true;
      }
      else if (
        item?.AddedDate?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else {
        let searchDate: any = new Date(this.searchValue);

        searchDate.setDate(searchDate.getDate() + 1);
        if (!isNaN(searchDate)) {
          searchDate = searchDate.toISOString().split('T')[0];
        }

        let splitDate = item.AddedDate.split('T')[0];

        return splitDate == searchDate ? true : false;
      }


    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  // getStorageSASToken(ContainerName: string): any {
  //   let url = this.ApiUrl + "storage/getstoragesastoken/" + ContainerName;
  //   this.http.get(url, { headers: { skip: "true" } }).subscribe(res => {

  //     this.getToken = res;
  //     this.postToken = res;
  //   }, res => { this.getStorageSASToken(ContainerName) });
  // }

  showModal(): void {
    this.PopUpTitle = 'Add New Print';
    this.validateForm.reset();
    this.NewPrint.Name = '';
    this.NewPrint.PrintMasterId = 0;

    this.NewPrint.Code = '';
    this.NewPrint.ImageName = '';
    this.NewPrint.Description = '';
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true;
    this.SavePrint();
  }
  // Edit Details 
  OpenEditPop(data: ProductionPrintMasterModel) {
    this.PopUpTitle = "Modify Print Details";
    this.validateForm.setValue(
      {
        PrintName: data.Name,
        Code: data.Code,
        Image: data.ImageName,
        Description: data.Description,

      }
    )
    this.NewPrint = new ProductionPrintMasterModel();
    this.NewPrint.PrintMasterId = data.PrintMasterId;
    this.uploadPrintFile = null;
    this.FilteredImageArray = [];
    this.isVisible = true;
    this.IsNewFile = false;
  }
  SavePrint() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;
      return;
    }
    const d = new Date();
    this.NewPrint.Name = this.f['PrintName'].value;
    this.NewPrint.Code = this.f['Code'].value;
    this.NewPrint.Description = this.f['Description'].value;
    this.NewPrint.AddedDate = d.toLocaleTimeString();
    let url = this.ApiUrl + 'print/addupdatprint';
    this.http.post<ProductionPrintMasterModel>(url, this.NewPrint).subscribe({
      next: (res) => {
        this.NewPrint = res;
        console.log('after save - ', this.uploadPrintFile);
        if (this.uploadPrintFile) {
          this.fetchStorageTokenandUpload();
        }
        this.alertService.success('Print Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllPrint();
        this.PrintList = [];
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }

  onChange(event: any): void {
    this.filesSelected = true;
    //this.getStorageSASToken(this.StorageAccContainerName);
    this.uploadPrintFile = event.target.files;
    this.IsNewFile = true;
    console.log('onChange - ', this.uploadPrintFile);
  }
  private fetchStorageTokenandUpload(): Observable<any>  {
    this.storage.getStorageSASToken(this.StorageAccContainerName)
      .subscribe(
        (token: string) => {
          this.uploadProgress$ = from(this.uploadPrintFile as FileList).pipe(
            map(file => this.uploadFile(file, token)),
            combineLatestAll()
          );
          this.uploadPrintFile = null;
        },
        (error: any) => {
          console.error('Error fetching storage token:', error);
        }
      );
      return null;
  }
  // onPrintUpload() {

  //   this.fetchStorageToken()
  //   .subscribe(
  //     (token: string) => {
  //       this.uploadProgress$ = from(this.uploadPrintFile as FileList).pipe(
  //         map(file => this.uploadFile(file)),
  //         combineLatestAll()
  //       );
  //       this.uploadPrintFile = null;
  //     }
  //   );
  //   // this.uploadProgress$ = from(this.uploadPrintFile as FileList).pipe(
  //   //   map(file => this.uploadFile(file)),
  //   //   combineLatestAll()
  //   // );
  //   // this.uploadPrintFile = null;
  //   // //this.IsNewFile = false;
  // }

  uploadFile(file: File, token: any): Observable<IUploadProgress> {
    if (this.IsNewFile) {
      //console.log('uploadFile - ', file);
      const accessToken: ISasToken = {
        container: token.StorageContainerName,
        filename: this.NewPrint.PrintMasterId + '/' + file.name,
        storageAccessToken: token.StorageAccountToken,
        storageUri: token.StorageAccountHost
      };
      return this.blobStorage
        .uploadToBlobStorage(accessToken, file)
        .pipe(map(progress => this.mapProgress(file, progress)));
    } else {
      return null;
    }
  }

  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress
    };
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  GetImages($event: any) {
    this.storage.getStorageSASToken(this.StorageAccContainerName).subscribe((res: any) => {
      this.getToken = res;
      this.FilteredImageArray = this.ImageArray = [];

      var Blobtoken = this.getToken.StorageAccountToken;
      let url = this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + Blobtoken + "&restype=container&comp=list";

      const headers = new HttpHeaders().set(InterceptorSkipHeader, ''); // To exclude AD Authorization header
      this.http.get<any>(url, <Object>{ responseType: 'text', headers }).subscribe(res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res, "text/xml");
        var x = xmlDOM.documentElement.childNodes;
        for (var i = 0; i < x.length; i++) {

          if (x[i].nodeName == "Blobs") {
            for (var j = 0; j < x[i].childNodes.length; j++) {

              var str = x[i].childNodes[j].childNodes[0].childNodes[0].nodeValue ?? "";
              var arr = str.split('/')
              var item = {
                //container: arr[0],
                ID: arr[0],
                Img: arr[1],
                URL: this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + "/" + str + Blobtoken
              }
              this.ImageArray.push(item);

            }
          }
          // + ": " + x[i].childNodes[0].nodeValue + "<br>"
        }
        this.FilteredImageArray = this.ImageArray.filter(x => x.ID == $event);
        console.log(this.ImageArray)
        console.log(this.FilteredImageArray)
        this.isImageVisible = true;
      }, res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res.error.text, "text/xml");
        const value = xmlDOM.getElementsByTagName("Blobs");
      });
    });
  }

  handleCancelImage(): void {
    this.isImageVisible = false;
  }

  // validation for Name
  VaccumNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.PrintList.filter(
          (x) => x.PrintMasterId != this.NewPrint.PrintMasterId
        );
        var nre = res.filter(
          (x) => x.Name.toLowerCase() == control.value.toLowerCase()
        );
        this.PrintNameError = '';

        if (control.value == '') {
          this.PrintNameError = 'Enter Print name';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.PrintNameError = 'Print name already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.PrintNameError = 'Enter Print name';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  // validation for Code
  VaccumCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.PrintList.filter(
          (x) => x.PrintMasterId != this.NewPrint.PrintMasterId
        );
        var nre = res.filter(
          (x) => x.Code.toLowerCase() == control.value.toLowerCase()
        );

        this.PrintCodeError = '';
        if (control.value == '') {
          this.PrintCodeError = 'Enter Print code';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.PrintCodeError = 'Print code already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.PrintCodeError = 'Enter Print code';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  handleDelete(data: ProductionPrintMasterModel) {
    this.NewPrint = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Print?',
      nzOkDanger: true,
      nzOnOk: () => this.DeletePrint(this.NewPrint),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  DeletePrint(data: ProductionPrintMasterModel) {
    let url = this.ApiUrl + 'print/deleteprint';

    this.http.post<ProductionPrintMasterModel>(url, data).subscribe({
      next: (res) => {
        this.alertService.success('Print Deleted Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllPrint();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
}
