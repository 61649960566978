import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';


@Component({
  templateUrl: './StockInspection.component.html'
})
export class StockInspectionComponent implements OnInit {
  id: number = 0;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  NewStockProduct: StockProductModel = new StockProductModel();
  FilteredProductList: ProductModel[] = [];
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];
  SelectedProductType: string = "";
  NewStock: StockModel = new StockModel();
  NewInvoice: InvoiceModel = new InvoiceModel();
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private alertService: AlertMessageService, private auth: AuthService) { }


  GetStockDetails() {
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe(res => {
      this.NewStock = res;
    }, res => { });
  }

  rejectedchange(item: StockProductModel) {
    if (item.RejectedQuantity > item.AcceptedQuantity) {
      item.RejectedQuantity = 0;
      return
    }
    item.AcceptedQuantity = item.Quantity - item.RejectedQuantity;
  }

  SaveStockInspection() {
    let url = this.ApiUrl + "stock/stockinspection";
    this.http.post<StockModel>(url, this.NewStock).subscribe({
      next: res => {
        this.alertService.success("Stock Inspected Successfully");
        this.router.navigate(['/home/stockinspectionlist'])
      },
      error: res => { this.alertService.error("An error has been occured. Please try again") },
    });
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Inspection, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Inspection, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Inspection, Responsibility.Delete);
    console.log(this.permission)
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.GetStockDetails();
  }

}
