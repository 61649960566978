<div class="card card-flush h-xl-100" *ngIf="IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Manage Module Operations</nz-page-header-title>

      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small" [nzData]="['']" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable1
      nzBordered>
      <thead>
        <tr>

          <th nzWidth="50px">

          </th>
          <th nzWidth="250px" nzLeft>
            Module

          </th>
          <th nzWidth="150px" style="text-align:center">Code</th>
          <th nzWidth="200px" style="text-align:center">Operation </th>
          <th style="text-align:center">Description </th>
          <th>Assigned Roles</th>
          <th nzWidth="220px" nzRight style="text-align:center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ResponsibilityList;let i=index">

          <td nzWidth="20px">
            {{i+1}}

          </td>
          <td nzLeft>{{data.Module}}</td>
          <td>{{data.ResponsibilityCode}}</td>
          <td>{{data.ResponsibilityName}}</td>
          <td>{{data.ResponsibilityDescripton}}</td>
          <td>{{data.AssignedRoles}}</td>
          <td nzRight style="text-align:center">

            <button nz-button nzSize="small" nzType="primary" style="border-radius: 4px;font-weight: 600;"
              (click)="OpenEditPop(data)">Edit</button>


          </td>


        </tr>

      </tbody>

    </nz-table>



  </div>
</div>
<div class="card card-flush h-xl-100" *ngIf="!IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>You are not authorized</nz-page-header-title>



    </nz-page-header>
  </div>
</div>

<nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>{{isNew==true?'Add':'Modify'}} Operation</ng-template>

  <ng-template #modalContent>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">

        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class="required form-label">Module</label>
            <nz-select nzShowSearch class="form-select mb-2" nzSize="large" nzAllowClear nzPlaceHolder="Choose"
              [(ngModel)]="Responsibility.Module">
              <nz-option *ngFor="let s of this.ModuleList;" [nzValue]="s" [nzLabel]="s"></nz-option>
            </nz-select>
          </div>

        </div>
        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class="required form-label">Name</label>
            <nz-select nzShowSearch class="form-select mb-2" nzSize="large" nzAllowClear nzPlaceHolder="Choose"
              [(ngModel)]="Responsibility.ResponsibilityName">
              <nz-option *ngFor="let s of this.ResponsibilityNameList;" [nzValue]="s" [nzLabel]="s"></nz-option>
            </nz-select>
            <!--<input type="text" class="form-control mb-2" value="" [(ngModel)]="Responsibility.ResponsibilityName" />-->
          </div>

        </div>
        <form nz-form [formGroup]="validateForm">
          <nz-form-item>
            <nz-form-label>Code</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="">
              <input nz-input nzSize="large" formControlName="ResponsibilityCode" name="ResponsibilityCode">
            </nz-form-control>
          </nz-form-item>
        </form>
        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class="form-label">Description</label>
            <textarea type="text" class="form-control mb-2" value="" rows="6"
              [(ngModel)]="Responsibility.ResponsibilityDescripton"></textarea>
          </div>

        </div>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="Save()">Save</button>
        </div>
      </div>

    </div>

  </ng-template>
</nz-modal>