import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';

import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import * as moment from 'moment';
import { BankMasterModel } from '../../Models/BankModel';

@Component({
  selector: 'app-bankDetails',
  templateUrl: './bankDetails.components.html',
  styleUrls: ['./bankDetails.component.css'],
})
export class BankDetailsComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Bank';

  BankName: any;
  AccountHolderName: any;
  AccountType: any;
  AccountNumber: any;
  Ifsc: any;
  Branch: any;
  SwiftCode: any;
  Micr: any;

  isValidDate: any;
  searchValue = '';
  permission = {
    View: false,
    Add: false,
    Delete: false,
  };
  BankList: BankMasterModel[] = [];
  BankListOriginal: BankMasterModel[] = [];
  NewBank: BankMasterModel = new BankMasterModel;
  count: 0;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private router: Router,
    private auth: AuthService,
    private alertService: AlertMessageService,
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(
      Modules.BankDetails,
      Responsibility.View
    );
    this.permission.Add = this.auth.CheckResponsibility(
      Modules.BankDetails,
      Responsibility.Add
    );
    this.permission.Delete = this.auth.CheckResponsibility(
      Modules.BankDetails,
      Responsibility.Delete
    );
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      BankName: [null, [Validators.required, Validators.maxLength(50)]],
      AccountHolderName: [null, [Validators.required, Validators.maxLength(50)]],
      AccountNumber: [null, [Validators.required, Validators.maxLength(20)]],
      Ifsc: [null, [Validators.required, Validators.maxLength(11)]],
      AccountType: [null, [Validators.required, Validators.maxLength(20)]],
      Branch: [null, [Validators.required, Validators.maxLength(100)]],
      SwiftCode: [null],
      Micr: [null]
    });
    this.GetAllBank();
  }
  get f() {
    return this.validateForm.controls;
  }

  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === 'Space') ||
      (event.key === 'Enter' && event.keyCode === 13)
    ) {
      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search() { }
  GetBankDetailsFromIFSC() {
    let url = "https://ifsc.razorpay.com/" + this.f['Ifsc'].value;

    this.http.get<any>(url).subscribe(res => {
      console.log(res);

    }, res => {
      this.GetBankDetailsFromIFSC();
    });
  }
  GetAllBank() {


    this.isTableLoading = true;
    let url = this.ApiUrl + "bank/getallbanks";
    this.http.get<BankMasterModel[]>(url).subscribe(res => {
      this.BankList = res;
      this.BankListOriginal = res

      this.isTableLoading = false;

      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllBank();
      }
    });
  }
  Save() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewBank.BankName = this.f['BankName'].value;
    this.NewBank.AccountHolderName = this.f['AccountHolderName'].value;
    this.NewBank.AccountNumber = this.f['AccountNumber'].value;
    this.NewBank.Ifsc = this.f['Ifsc'].value;
    this.NewBank.AccountType = this.f['AccountType'].value;
    this.NewBank.Branch = this.f['Branch'].value;
    this.NewBank.SwiftCode = this.f['SwiftCode'].value;
    this.NewBank.Micr = this.f['Micr'].value;
    this.NewBank.AddedBy = UserInfo.EmailID;

    let url = this.ApiUrl + "bank/addupdatebank";
    this.http.post<BankMasterModel>(url, this.NewBank).subscribe({

      next: res => { this.alertService.success("Bank Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllBank(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: BankMasterModel) {
    this.NewBank.BankId = data.BankId;
    this.PopUpTitle = 'Modify Bank Details';
    this.validateForm.setValue({
      BankName: data.BankName,
      AccountHolderName: data.AccountHolderName,
      AccountType: data.AccountType,
      AccountNumber: data.AccountNumber,
      Ifsc: data.Ifsc,
      Branch: data.Branch,
      SwiftCode: data.SwiftCode,
      Micr: data.Micr,
    });
    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = 'Add New Bank';
    this.validateForm.reset();
    this.isVisible = true;
  }

  handleOk() {
    this.isLoading = true;
    this.Save();
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  Delete() { }
  handleDelete(data: BankMasterModel) { }
}
