import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import CacheLocal from 'src/PmsUIApp/Authentication/tokenCache'
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {
  validateForm!: UntypedFormGroup;
  loading = false;
  submitted = false;
  // returnUrl: string='/start';
  returnUrl: string = 'home/welcome';
  error = '';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router, private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private loader: LoadingService) {
      if (this.msalService.instance.getAllAccounts().length > 0) {
        this.router.navigate([this.returnUrl]);
      }
    // if (this.msalService.instance.getActiveAccount() != null && this.authService.getAppSessionTime("appSessionExpiry") != null) {

    //   this.router.navigate([this.returnUrl]);
    // }
    // else {

    //   let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
    //   msalInstance["browserStorage"].clear();
    // }
    //if (this.authenticationService.isUserLoggedIn) { 
    //  //this.router.navigate([this.returnUrl]);
    //}
  }

  ngOnInit(): void {
    this.loader.show();
    if (localStorage.getItem('token') == null || localStorage.getItem('token') == undefined ) {
      this.authService.clearLocalDataStorage();
    }
    // this.loader.hide();
    //this.login(); No need to show popup on load.
    //this.validateForm = this.fb.group({
    //  userName: [null, [Validators.required]],
    //  password: [null, [Validators.required]],
    //  remember: [true]
    //});

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    //this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    // this.msalBroadcastService.msalSubject$
    //   .pipe(
    //     filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    //   )
    //   .subscribe((result: EventMessage) => {
    //     console.log(result,"534")

    //     if (this.msalService.instance.getAllAccounts().length === 0) {
    //       window.location.pathname = "/";
    //     } else {
    //       this.setLoginDisplay();
    //       console.log(result,"534")
    //     }
    //   });

    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {

      if (event.payload) {
        const response:any = event.payload;
        const now = new Date();
      const appSessionExpiry = now.getTime() + 43200000;

      localStorage.setItem("token", response.accessToken);
      localStorage.setItem("appSessionExpiry", appSessionExpiry?.toString());
      this.msalService.instance.setActiveAccount(response.account);
      localStorage.setItem('userName', response?.account?.username);
      localStorage.setItem('homeAccountId', response.account?.homeAccountId);
      let expiryTime = response.expiresOn?.toString();
      localStorage.setItem('expiresOn', expiryTime);
      }
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        if (this.msalService.instance.getAllAccounts().length > 0) {
          this.router.navigate([this.returnUrl]);
          this.loader.hide()
        }
        else {
            let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
            msalInstance["browserStorage"].clear();
            this.loader.hide()
        }
      })
      
// if (this.msalService.instance.getActiveAccount() != null && this.authService.getAppSessionTime("appSessionExpiry") != null) {

    //   this.router.navigate([this.returnUrl]);
    // }
    // else {

    //   let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
    //   msalInstance["browserStorage"].clear();
    // }
    //if (this.authenticationService.isUserLoggedIn) { 
    //  //this.router.navigate([this.returnUrl]);
    //}



      
  }
  // getWithExpiry(key: string) {
  //   const itemStr = localStorage.getItem(key)

  //   // if the item doesn't exist, return null
  //   if (!itemStr) {
  //     return null
  //   }

  //   const item = JSON.parse(itemStr)
  //   const now = new Date()

  //   // compare the expiry time of the item with the current time
  //   if (now.getTime() > item.expiry) {
  //     // If the item is expired, delete the item from storage
  //     // and return null
  //     localStorage.removeItem(key)
  //     return null
  //   }
  //   return item.value
  // }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    // let activeAccount = this.msalService.instance.getActiveAccount();

    // if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    this.loader.show()
    // this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
    //   const now = new Date();
    //   const appSessionExpiry = now.getTime() + 43200000;

    //   localStorage.setItem("token", response.accessToken);
    //   localStorage.setItem("appSessionExpiry", appSessionExpiry.toString());
    //   this.msalService.instance.setActiveAccount(response.account);
    //   localStorage.setItem('userName', response.account.username);
    //   localStorage.setItem('homeAccountId', response.account.homeAccountId);
    //   let expiryTime = response.expiresOn.toString();
    //   localStorage.setItem('expiresOn', expiryTime);

    //   if (this.msalService.instance.getActiveAccount() != null && this.authService.getAppSessionTime("appSessionExpiry") != null) {
    //     //CacheLocal.set('token', this.getWithExpiry("token"))
    //     this.router.navigate([this.returnUrl]);
    //   }
    //   else {

    //   }
    // })
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest).subscribe({
        next:(result)=> {
          console.log(result);
          this.loader.hide()
        },
        error: (error) => console.log(error)
    
      });
    } else {
      this.msalService.loginRedirect();
      this.loader.hide()
    }
    
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
