<div style="border:1px solid;background-color: white;" id="jobcardprint">
    <div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
        <p style="font-size: 15px;"><b>{{this.Company.CompanyName}}</b></p>
        <p style="font-size: 10px;"><b>Head Office: </b>{{this.Company.HeadOfficeAddress}}</p>
        <p style="font-size: 10px;"><b>Factory Address: </b>{{this.Company.FactoryAddress}}</p>
    </div>
    <div style="text-transform: uppercase; font-size: 13px; text-align: center; color: black; border: 1px solid black;">
        <b>Software Generated Job Card</b>
    </div>
    <nz-descriptions *ngIf="Production" nzBordered nzSize="small" [nzColumn]="2" style="border: 1px solid black;">
        <nz-descriptions-item nzTitle="WorkPlan No."><b>{{Production.WorkPlanNumber}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Sale Order No."><b>{{Production.SaleOrderNumber}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Formulation Code"><b>{{Production.SaleFormulationCode}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Application"><b>{{Production.Category}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Colour Name/Code"><b>{{Production?.SaleOrderProduction?.ColorName}} /
                {{Production?.SaleOrderProduction?.ColorCode}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Grain Name/Code"><b>{{Production?.SaleOrderProduction?.GrainName}} /
                {{Production?.SaleOrderProduction?.GrainCode}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="PRD MTR(Incl. Extra {{Production?.SaleOrderProduction?.ExtraProduction}}%)"><b>{{TotalProductionQty}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Customer"><b>{{Production?.CustomerName}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Inspected By"><b>{{Production?.PreInspectionCompletedBy}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Inspected Date & Time"><b>{{Production?.PreInspectionCompletedOn |
                DatetimeConverter}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Operator Name & Mobile No.">{{""}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="All Paste Ready Date & Time">{{""}}</nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Supervisor Name">{{this.Production.SaleOrderProduction.InspectionSaleFormulationCode.ShiftSupervisorWorkerName}}</nz-descriptions-item>

    </nz-descriptions>
    <div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">
        <div nz-col [nzSpan]="12" *ngFor="let i of Production.SaleOrderProduction.InspectionFormulationMixing">

            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false"
                style="margin-left: 0; margin-right:0;font-weight: bolder;">
                <thead>
                    <tr style="text-transform: uppercase; font-size: 13px; text-align: center; color: black;">
                        <td colspan="4" style="border: 1px solid black;">
                            <b>{{i.MixingName}}</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="40%" style="text-align:left; border: 1px solid;font-size: small;"><b>Item Name</b>
                        </td>
                        <td style="text-align:center; border: 1px solid;font-size: small;" width="15%"><b>QTY</b>
                        </td>
                        <td style="text-align:center; border: 1px solid;font-size: small;" width="15%"><b> SC QTY</b>
                        </td>
                        <td style="text-align:center; border: 1px solid;font-size: small;width: 30%;" width="30%">
                            <b>Additional
                                QTY</b>
                        </td>
                    </tr>
                    <tr *ngFor="let data of i.MixingRawMaterial;let i=index">

                        <td width="40%" style="border: 1px solid ;text-align: left;font-size: small;">{{
                            data.ProductName
                            }}</td>
                        <td width="15%" style="text-align:center; border:1px solid;font-size: small;">{{ data.Quantity
                            }}</td>
                        <td width="15%" style="text-align:center; border:1px solid;font-size: small;">{{ data.Scquantity
                            }}</td>
                        <td width="30%" style="text-align:center; border:1px solid;font-size: small;"></td>
                    </tr>
                    <tr *ngFor="let number of [].constructor(2)">
                        <td style="text-align:center; border:1px solid;height: 35px;"></td>
                        <td style="text-align:center; border:1px solid;"></td>
                        <td style="text-align:center; border:1px solid;"></td>
                        <td style="text-align:center; border:1px solid;"></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td width="60%" style="text-align:left; border: 1px solid;font-size: small;"><b> Total Weight
                            </b></td>

                        <td style="text-align:center; border: 1px solid;font-size: small;">
                            <b>{{this.totalCalculatedQuantities[i.MixingName.toLowerCase()]}} Kgs</b>
                        </td>
                        <td style="text-align:center; border: 1px solid;font-size: small;">
                            <b>{{this.totalCalculatedSCQuantities[i.MixingName.toLowerCase()]}} Kgs</b>
                        </td>
                        <td style="text-align:center; border: 1px solid"></td>
                    </tr>
                </tfoot>
            </nz-table>
        </div>
        <nz-divider nzText="Fabric Details"
            *ngIf="this.Production.SaleOrderProduction.FabricProductId > 0"></nz-divider>
        <div nz-col [nzSpan]="12" *ngIf="this.Production.SaleOrderProduction.FabricProductId > 0">
            <nz-table nzSize="small" nzBordered nzShowPagination="false" style="margin-left: 0; margin-right:0">
                <tfoot>
                    <tr>
                        <td width="60%" style="text-align:left; border: 1px solid ;"><b>Fabric Name -
                                {{this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductName}}</b>
                        </td>
                        <td style="text-align:center; border: 1px solid;">
                            <b>{{this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductQty}}
                                {{this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductUnit}}
                            </b>
                        </td>
                        <td style="text-align:center; border: 1px solid;"
                            *ngIf="this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductUnit != 'Mtrs'">
                            <b>{{this.FabricQTYFromKGToMTR}} MTRS
                            </b>
                        </td>
                    </tr>
                </tfoot>
            </nz-table>
        </div>
        <nz-divider nzText="Total Weights"></nz-divider>
        <div nz-col [nzSpan]="12" *ngFor="let i of Production.SaleOrderProduction.InspectionFormulationMixing">
            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false"
                style="margin-left: 0; margin-right:0">
                <tfoot>
                    <tr>
                        <td width="60%" style="text-align:left; border: 1px solid ;"><b> Total {{i.MixingName}} WT. </b>
                        </td>
                        <td style="text-align:center; border: 1px solid;">
                            <b>{{this.totalCalculatedQuantities[i.MixingName.toLowerCase()]}} Kgs / SC -
                                {{this.totalCalculatedSCQuantities[i.MixingName.toLowerCase()]}} Kgs</b>
                        </td>
                    </tr>
                </tfoot>
            </nz-table>
        </div>
    </div>



</div>
<app-loader></app-loader>