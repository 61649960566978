import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductAllocationModel, StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { RackModel, StoreModel } from '../../Models/MasterModel';
import { TransportVehicleModel } from '../../Models/TransportModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';

@Component({
  templateUrl: './StockProductAllocation.component.html'
})
export class StockProductAllocationComponent {
  id: number = 0;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  NewStockProduct: StockProductModel = new StockProductModel;
  FilteredProductList: ProductModel[] = [];
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel;
  NewInvoice: InvoiceModel = new InvoiceModel;
  NewAllocation: StockProductAllocationModel = new StockProductAllocationModel;
  TransportVehicleList: TransportVehicleModel[] = [];
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreID: number = 0;
  RackID: number = 0;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private alertService: AlertMessageService, private auth: AuthService) { }


  GetStockDetails() {
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe(res => {
      this.NewStock = res;

      this.NewStock.StockProduct.forEach((nos) => {
        nos.ExpandRecord = false;
      })
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetStockDetails();
      }
    });
  }

  rejectedchange(item: StockProductModel) {
    item.AcceptedQuantity = item.Quantity - item.RejectedQuantity;
  }

  AddAllocation(spl: StockProductModel) {
    spl.NewRecord = true;
    this.NewAllocation = new StockProductAllocationModel();
  }
  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllStore()
      }
    });
  }
  onSelectedStoreChange() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res.filter(x => x.StoreId == this.NewAllocation.StoreId);
      this.RackID = 0;
    }, res => { });
  }
  SaveAllocation(spl: StockProductModel) {

    if (spl.StockProductAllocation == undefined) {
      spl.StockProductAllocation = [];
    }
    if (this.NewAllocation.Quantity <= 0) {
      this.alertService.error("Enter valid Quantity ")
      return
    }

    var totalqtyAccepted = spl.StockProductAllocation.filter(x => x.InspectionType == 'Accepted').reduce((accumulator, current) => {

      return parseInt(accumulator.toString()) + parseInt(current.Quantity.toString());
    }, 0);
    var totalqtyRejected = spl.StockProductAllocation.filter(x => x.InspectionType == 'Rejected').reduce((accumulator, current) => {
      return parseInt(accumulator.toString()) + parseInt(current.Quantity.toString());
    }, 0);

    if (this.NewAllocation.InspectionType == 'Accepted') {
      totalqtyAccepted += parseInt(this.NewAllocation.Quantity.toString());
      if ((totalqtyAccepted) > (spl.AcceptedQuantity)) {
        this.alertService.error("Total Quantity in " + this.NewAllocation.InspectionType + " InspectionType cannot more than " + spl.AcceptedQuantity)
        return
      }
    } else {
      totalqtyRejected += parseInt(this.NewAllocation.Quantity.toString());
      if ((totalqtyRejected) > (spl.RejectedQuantity)) {
        this.alertService.error("Total Quantity in " + this.NewAllocation.InspectionType + " InspectionType cannot more than " + spl.RejectedQuantity)
        return
      }
    }


    var isExistAccepted = spl.StockProductAllocation.filter(x => x.InspectionType == 'Accepted' && x.RackId == this.NewAllocation.RackId);
    var isExistRejected = spl.StockProductAllocation.filter(x => x.InspectionType == 'Rejected' && x.RackId == this.NewAllocation.RackId);

    if (spl.AcceptedQuantity)
      spl.NewRecord = false;
    this.NewAllocation.StockProductId = spl.StockProductId;
    this.NewAllocation.RackName = this.RackList.filter(x => x.RackId == this.NewAllocation.RackId)[0].RackName;
    this.NewAllocation.RackCode = this.RackList.filter(x => x.RackId == this.NewAllocation.RackId)[0].RackCode;
    this.NewAllocation.StoreName = this.StoreList.filter(x => x.StoreId == this.NewAllocation.StoreId)[0].StoreName;
    if (this.NewAllocation.InspectionType == 'Accepted') {
      if (isExistAccepted.length == 1) {
        isExistAccepted[0].Quantity = parseInt(isExistAccepted[0].Quantity.toString()) + parseInt(this.NewAllocation.Quantity.toString());
      }
      else {
        spl.StockProductAllocation.push(this.NewAllocation);
      }
    } else {
      if (isExistRejected.length == 1) {
        isExistRejected[0].Quantity = parseInt(isExistRejected[0].Quantity.toString()) + parseInt(this.NewAllocation.Quantity.toString());
      }
      else {
        spl.StockProductAllocation.push(this.NewAllocation);
      }
    }

    this.NewAllocation = new StockProductAllocationModel();
  }

  SaveAllocationtoDB(spl: StockProductModel) {
    {
      let url = this.ApiUrl + "stock/stockallocation";
      this.http.post<StockProductModel>(url, spl).subscribe({
        next: res => { this.alertService.success("Stock allocated Successfully"); this.GetStockDetails() },
        error: res => { this.alertService.error("An error has been occured. Please try again") },
      });
    }
  }

  RemoveAllocation(spl: StockProductModel, alloc: StockProductAllocationModel) {
    var index = spl.StockProductAllocation.indexOf(alloc);
    if (index !== -1) {
      spl.StockProductAllocation.splice(index, 1);
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.GetStockDetails();
    this.GetAllStore()
  }

  Quantitychange(data: StockProductAllocationModel) {

    if (data.Quantity == 0) {

      return
    }
  }
}
