<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Store Wise Stock</nz-page-header-title>
      <nz-page-header-subtitle>View your store wise stock
      </nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px; float: right">

      <label>
        <h3>Store Name</h3>
      </label> <br>

      <nz-select type="text" style="width: 250px;
           margin-right: 8px" name="StoreName" [(ngModel)]="selectedStore">
        <nz-option *ngFor="let x of this.StoreList" [nzValue]="x" [nzLabel]="x.StoreName"></nz-option>
      </nz-select>




      <input type="text" style="width: 250px; margin-right: 8px" nz-input
        placeholder="Search Product ProductType/ProductName/Quantity" [(ngModel)]="searchValue"
        (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="SearchWithFilter()" style="margin-right: 8px">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset()">

        Reset
      </button>
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button>
      <!-- <div style="padding: 8px; float: left">
      <nz-form-item style="width: 250px; margin-right: 8px" >
       
            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
              <label>Store Name</label>
              <nz-select
                name="StoreName"
                [(ngModel)]="StoreName"
                nzAllowClear
              >
             
                <nz-option *ngFor="let data of this.storeNameList"
                  [nzValue]="data">
                  [nzLabel]="data"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          
      </nz-form-item>
    </div> -->



      <!-- <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search()"

        style="margin-right: 8px"
       
      >
        Search
      </button>
      <button
        nz-button
        nzSize="small"
       
        (click)="reset()"
      >
        Reset
      </button> 
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button> -->
    </div>
    <nz-table #basicTable [nzScroll]="{ x: '1200px', y: '515px' }" style="width: 100%" [nzData]="['']" nzSize="small"
      [nzPageSize]="12" [nzPageSize]="100" nzBordered>
      <thead>
        <tr>
          <th nzWidth="30px">SNo.</th>
          <th nzWidth="80px">Product Type</th>

          <th nzWidth="120px">Product Name</th>
          <th nzWidth="120px">Total Quantity</th>
          <th nzWidth="120px" *ngIf="!hideCol">Store Name</th>

          <th nzWidth="120px">Rack Name</th>
          <th nzWidth="120px">Quantity Per Rack</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.FilteredStoreWiseList ;let i=index">
          <td style="
            border-bottom: 1px solid #415994;
            border-left: 1px solid #415994;
          ">
            {{i+1}}
          </td>
          <td style="
            border-bottom: 1px solid #415994;
          ">
            {{ data.ProductType }}
          </td>

          <td style="border-bottom: 1px solid #415994">
            {{ data.ProductName }}
          </td>
          <td style="border-bottom: 1px solid #415994">{{data.calculatedQuantity != null ? data.calculatedQuantity:
            data.Quantity }} / {{ data.Unit}}</td>
          <td style="
                border-bottom: 1px solid #415994;
                padding: 0;
                border-right: 1px solid #415994;
              " nzWidth="360px" [attr.colspan]="hideCol?'2':'3'">
            <table style="width: 100%">
              <tr *ngFor="
                    let d of data.StoreDetailsCopy.length
                      ? data.StoreDetailsCopy
                      : data.StoreDetails
                  ">
                <td Width="120px" style="padding: 5px; border: 1px solid #eee" *ngIf="!hideCol">
                  {{ d.StoreName }}
                </td>
                <td Width="120px" style="padding: 5px; border: 1px solid #eee">
                  {{ d.RackName }}
                </td>
                <td Width="120px" style="padding: 5px; border: 1px solid #eee">
                  {{ d.Quantity }} / {{d.Unit}}
                </td>
              </tr>
            </table>
          </td>
          <!--<td>
                <span *ngFor="let el of productStoreData[data.ProductName]">
                    <span *ngIf="el.storeData.length"> {{el.storeName}}
                        <hr>
                    </span>
                </span>
            </td>
            <td>
                <span *ngFor="let el of productStoreData[data.ProductName]">
                    <span *ngIf="el.storeData.length">
                        <span *ngFor="let data of el.storeData"> {{
                            data.racks}} <br></span>
                        <hr>
                    </span>
                </span>

            </td>
            <td>
                <span *ngFor="let el of productStoreData[data.ProductName]">
                    <span *ngIf="el.storeData.length">
                        <span *ngFor="let data of el.storeData"> {{
                            data.rackQuantity}} <br> </span>
                        <hr>
                    </span>
                </span>
            </td>-->
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>