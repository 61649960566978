import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { environment } from 'src/environments/environment';
import {
  StoreWiseStockModel,
  StoreWiseStockStoreViewModel,
  StoreWiseStockViewModel,
} from 'src/PmsUIApp/Models/StockModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel } from '../../Models/MasterModel';
import { ProductModel } from '../../Models/ProductModel';
import { AuthService } from '../../Services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-store-wise-stock',
  templateUrl: './Product-wise-stock.component.html',
  styleUrls: ['./Product-wise-stock.component.css'],
})
export class ProductWiseStockComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  storeSearch: any[] = [];

  storeWiseList: any[] = [];
  FilteredStoreWiseList: any[] = [];
  latestFields: any;
  // latestFields: StoreWiseStockModel = new StoreWiseStockModel();
  exportlatestFields: StoreWiseStockModel[] = [];
  ProductList: ProductModel[] = [];
  searchValue = '';
  isPageLoading: boolean = false;
  products = [];
  productStoreData: any = {};
  exportoptions = {
    headers: [
      'SNO',
      'PRODUCT NAME',
      'PRODUCT TYPE',
      'TOTAL QUANTITY',
      'STORE NAME',
      'RACK NAME',
      'QUANTITY PER RACK',
      'Unit',
    ],
  };
  // fields:any
  fields: StoreWiseStockModel = new StoreWiseStockModel();
  exportfields: StoreWiseStockModel[] = [];
  FilteredProductList: StoreWiseStockViewModel[] = [];
  SelectedProductType: string = '';
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  FilteredPProductList: ProductModel[] = [];
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    public http: HttpClient,
    public route: ActivatedRoute,
    public router: Router, private auth: AuthService
  ) {
    this.FilteredStoreWiseList = [];
    this.storeWiseList = [];
  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsProductWiseStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsProductWiseStock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsProductWiseStock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProductCategory();
    this.GetAllProducts();
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
        this.GetStockWise();
      },
      (res) => { }
    );
  }
  GetStockWise() {
    this.isPageLoading = true;
    let url = this.ApiUrl + 'stock/getproductwisestorerackstock';
    this.http.get<StoreWiseStockModel[]>(url).subscribe((res) => {
      console.log(res);

      let uniqueProduct = [...new Set(res.map((item) => item.ProductId))];
      console.log(uniqueProduct);
      var fp = this.ProductList.filter((x) =>
        uniqueProduct.includes(x.ProductId)
      );
      fp.forEach((x) => {
        var it = new StoreWiseStockViewModel();
        it.ProductType = x.ProductType;
        it.ProductId = x.ProductId;
        it.ProductCategoryId = x.ProductCategoryId;
        it.ProductFirstSubCategoryId = x.ProductFirstSubCategoryId;
        it.ProductSecSubCategoryId = x.ProductSecSubCategoryId;
        it.ProductName = x.ProductName;
        it.Quantity =
          res
            .filter((y) => y.ProductId == x.ProductId)
            .reduce(
              (sum, current) => sum + parseFloat(current.Quantity?.toString()),
              0
            ) ?? 0;
        it.Unit = x.Unit;
        res
          .filter((y) => y.ProductId == x.ProductId)
          .forEach((iit) => {
            var st = new StoreWiseStockStoreViewModel();
            st.StoreName = iit.StoreName;
            st.RackName = iit.RackName;
            st.Quantity = iit.Quantity;
            st.Unit = iit.Unit;
            it.StoreDetails.push(st);
          });
        this.FilteredProductList.push(it);
      });
      console.log(this.FilteredProductList);
      this.storeWiseList = this.FilteredProductList; //this.structureResponce(res);
      this.setExportData(this.FilteredProductList);
      this.FilteredStoreWiseList = [...this.FilteredProductList];

      this.isPageLoading = false;
      this.structureExportFields();
    });
  }
  setExportData(data: any[]) {
    this.exportfields = [];
    data.forEach((x) => {
      this.latestFields = {};
      this.latestFields.ProductId = x.ProductId;
      this.latestFields.ProductCategoryId = x.ProductCategoryId;
      this.latestFields.ProductName = x.ProductName;
      this.latestFields.ProductType = x.ProductType;
      this.latestFields.StoreName = x.StoreName;
      this.latestFields.RackName = x.RackName;
      this.latestFields.Quantity = x.Quantity;
      this.latestFields.Unit = x.Unit;
      this.latestFields.StoreDetails = x.StoreDetails;
      x.StoreDetailsCopy = [...x.StoreDetails];
      this.exportfields.push(x);
    });
    this.structureExportFields();
  }
  onKeydown(event: any) {
    //if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

    //  event.preventDefault();
    //  event = this.search()
    //}
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  structureExportFields() {
    let resultArr = [];

    for (let j = 0; j < this.exportfields.length; j++) {
      if (this.exportfields[j].StoreDetails.length == 1) {
        const obj = {
          SNO: j + 1,
          ProductName: this.exportfields[j].ProductName,
          ProductType: this.exportfields[j].ProductType,
          TotalQuantity: this.exportfields[j].Quantity,
        };
        resultArr.push({ ...obj, ...this.exportfields[j]['StoreDetails'][0] });
      } else if (this.exportfields[j].StoreDetails.length > 1) {
        for (let i = 0; i < this.exportfields[j].StoreDetails.length; i++) {
          if (i == 0) {
            const obj = {
              SNO: j + 1,
              ProductName: this.exportfields[j].ProductName,
              ProductType: this.exportfields[j].ProductType,
              TotalQuantity: this.exportfields[j].Quantity,
            };
            resultArr.push({
              ...obj,
              ...this.exportfields[j]['StoreDetails'][i],
            });
          } else {
            let obj = {
              SNO: '',
              ProductName: '',
              ProductType: '',
              TotalQuantity: '',
              ...this.exportfields[j]['StoreDetails'][i],
            };

            resultArr.push(obj);
          }
        }
      }
    }

    this.exportfields = resultArr;

    console.log(
      'exportFieldsData here',
      this.exportfields,
      'result formed',
      resultArr
    );
  }
  structureResponce(data: any[]) {
    let structureData: any = {};
    let structureProduct: any = {};
    let productsUniqueData: any = [];
    for (let i = 0; i < data.length - 1; i++) {
      if (!structureData[data[i].StoreName] && data[i].Quantity > 0) {
        structureData[data[i].StoreName] = [
          {
            racks: data[i].RackName,
            rackQuantity: data[i].Quantity,
            productName: data[i].ProductName,
          },
        ];
      } else if (structureData[data[i].StoreName] && data[i].Quantity > 0) {
        if (
          !structureData[data[i].StoreName].filter(
            (el: any) => el.rackName == data[i].RackName
          ).length
        ) {
          structureData[data[i].StoreName].push({
            racks: data[i].RackName,
            rackQuantity: data[i].Quantity,
            productName: data[i].ProductName,
          });
        }
      }
    }
    for (let i = 0; i < data.length - 1; i++) {
      let totalQuantity = 0;
      if (!structureProduct[data[i].ProductName]) {
        structureProduct[data[i].ProductName] = [
          {
            productType: data[i].ProductType,
            productName: data[i].ProductName,
            totalQuantity: totalQuantity + data[i].Quantity,
            storeName: data[i].StoreName,
            storeData: { ...structureData }[data[i].StoreName].filter(
              (el: any) => el.productName == data[i].ProductName
            ),
          },
        ];
        totalQuantity = data[i].Quantity;
      } else if (
        structureProduct[data[i].ProductName] &&
        !structureProduct[data[i].ProductName].filter(
          (el: any) => el.storeName == data[i].StoreName
        ).length
      ) {
        structureProduct[data[i].ProductName].push({
          productType: data[i].ProductType,
          productName: data[i].ProductName,
          storeName: data[i].StoreName,
          totalQuantity: totalQuantity + data[i].Quantity,
          storeData: { ...structureData }[data[i].StoreName].filter(
            (el: any) => el.productName == data[i].ProductName
          ),
        });
      }
    }

    this.productStoreData = structureProduct;
    for (let i = 0; i < data.length - 1; i++) {
      const count = productsUniqueData.filter(
        (el: any) => el.ProductName == data[i].ProductName
      ).length;
      if (!count) {
        productsUniqueData.push({
          ...data[i],
          Quantity: data[i].Quantity,
        });
      } else {
        let _index = productsUniqueData.findIndex(
          (el: any) => el.ProductName == data[i].ProductName
        );

        productsUniqueData[_index].Quantity += data[i].Quantity;
      }
    }
    return productsUniqueData;
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'Product-Wise-Stock' + exportdate,
        this.exportoptions
      );
  }
  GetReport() {
    var res = this.storeWiseList;
    console.log(res)
    this.FilteredStoreWiseList = res.filter((item: StoreWiseStockModel) => item.ProductType == this.SelectedProductType || this.SelectedProductType == '')
      .filter((item: StoreWiseStockModel) => item.ProductCategoryId == this.CategoryID || this.CategoryID == 0 || this.CategoryID == null)
      .filter((item: StoreWiseStockModel) => item.ProductFirstSubCategoryId == this.FirstCategoryID || this.FirstCategoryID == 0 || this.FirstCategoryID == null)
      .filter((item: StoreWiseStockModel) => item.ProductSecSubCategoryId == this.SecondCategoryID || this.SecondCategoryID == 0 || this.SecondCategoryID == null)
      .filter((item: StoreWiseStockModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null)
    this.setExportData(this.FilteredStoreWiseList);
  }

  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
      },
      (res) => { }
    );
  }

  onSelectedProductTypeChange() {
    this.FilteredPProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }

  onSelectedProductChange(data: any) {
    var fp = this.FilteredPProductList.filter(
      (x) => x.ProductId == this.selectedProduct
    )[0];
    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredPProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredPProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredPProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  //search() {
  //  var res = [...this.storeWiseList];
  //  res.forEach((el) => {
  //    el.StoreDetailsCopy = [];
  //  });
  //  console.log('res here to search', res);

  //  this.FilteredStoreWiseList = res.filter((item: StoreWiseStockModel) => {
  //    if (
  //      item.ProductType.toLowerCase().includes(this.searchValue.toLowerCase())
  //    ) {
  //      return true;
  //    } else if (
  //      item.ProductName.toLowerCase().includes(this.searchValue.toLowerCase())
  //    ) {
  //      return true;
  //    } else if (item.Quantity == +this.searchValue) {
  //      return true;
  //    } else {
  //      const data = item.StoreDetails.filter((x: any) => {
  //        if (
  //          x.StoreName.toLowerCase().includes(this.searchValue.toLowerCase())
  //        ) {
  //          return true;
  //        } else if (
  //          x.RackName.toLowerCase().includes(this.searchValue.toLowerCase())
  //        ) {
  //          return true;
  //        } else {
  //          return false;
  //        }
  //      });
  //      if (data.length) {
  //        //  item.StoreDetails = data
  //        item.StoreDetailsCopy = data;

  //        return true;
  //      } else {
  //        return false;
  //      }
  //    }
  //  });
  //}

  reset(): void {
    this.searchValue = '';
    this.FilteredStoreWiseList = [...this.storeWiseList];
    this.FilteredStoreWiseList.forEach((el) => {
      el.StoreDetailsCopy = [];
    });
  }

  // showdata() {
  //   var res = [...this.storeWiseList];
  //   this.FilteredStoreWiseList = res.filter((item: StoreWiseStockModel) => {
  //     if (
  //       item.ProductType.toLowerCase().includes(this.searchValue.toLowerCase())
  //     ) {
  //       return true;
  //     } else if (
  //       item.ProductName.toLowerCase().includes(this.searchValue.toLowerCase())
  //     ) {
  //       return true;
  //     } else if (item.Quantity == +this.searchValue) {
  //       return true;
  //     } else if (
  //       item.StoreName.toLowerCase().includes(this.searchValue.toLowerCase())
  //     ) {
  //       return true;
  //     } else if (
  //       item.RackName.toLowerCase().includes(this.searchValue.toLowerCase())
  //     ) {
  //       return true;
  //     }

  //     return false;
  //   });
  // }
}
