  <div class="card card-flush h-xl-100">
    <div class="card-body pt-5">

      <nz-page-header [nzGhost]="false">
        <nz-page-header-title>Product List</nz-page-header-title>
        <nz-page-header-subtitle>Manage your product here</nz-page-header-subtitle>
        <nz-page-header-extra>

          <button nz-button nzType="primary" (click)="showModal()">Add New</button>
        </nz-page-header-extra>

      </nz-page-header>
      <nz-divider></nz-divider>
      <div style="padding: 8px;float:right">
        <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search name / code / type" [(ngModel)]="searchValue"    (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" 
        />
        <button nz-button nzSize="small" nzType="primary" (click)="search()" [disabled]="IsSearching" style="margin-right: 8px;">Search</button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
        <button nz-button nzSize="small"  (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i> Export</button>
      </div>
      <nz-table   [nzPageSize]="100" style="width: 100%;" nzBordered [nzScroll]="{ x: '1200px',y:'515px' }" nzSize="small" #basicTable [nzData]="this.ProductList" [nzLoading]="isTableLoading">
        <thead>
          <tr>
            <th nzWidth="200px" nzLeft>
              Product Name
              <!--<nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
      <i nz-icon nzType="search"></i>
    </nz-filter-trigger>-->
            </th>
            <th nzWidth="200px">Product Code</th>
            <th nzWidth="150px">Product Type</th>
            <th nzWidth="60px">Unit</th>
            <th nzWidth="100px">Avg Gsm</th>
            <th nzWidth="100px">Width</th>
            <th nzWidth="100px">Min Qty</th>
            <th nzWidth="200px">Category</th>
            <th nzWidth="200px">Sub-Category</th>
            <th nzWidth="200px">2nd Sub-Category</th>

            <th nzWidth="160px" nzRight style="text-align:center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td nzLeft>{{ data.ProductName }}</td>
            <td>{{ data.ProductCode }}</td>
            <td>{{ data.ProductType }}</td>
            <td>{{data.Unit}}</td>
            <td>{{data.AvgGsm}}</td>
            <td>{{data.WidthInMeter}}</td>
            <td>{{data.MinimumQuantity}}</td>
            <td>{{ data.ProductCategory }}</td>
            <td>{{ data.ProductFirstSubCategory }}</td>
            <td>{{ data.ProductSecSubCategory }}</td>


            <td nzRight style="text-align:center">
              <button class="btn btn-sm btn-light-primary" [disabled]="enableEditDeleteBtn('Edit')" (click)="OpenEditPop(data)">Edit</button> &nbsp;
              <button class="btn btn-sm btn-light-danger" [disabled]="enableEditDeleteBtn('Delete')" (click)="handleDelete(data)">Delete</button>

            </td>
          </tr>
        </tbody>
      </nz-table>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="ant-table-filter-dropdown">
          <div style="padding: 8px;">
            <input type="text" style="width: 188px;margin-bottom: 8px;display: block;" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button>
          </div>
        </div>
      </nz-dropdown-menu>


      <nz-modal [(nzVisible)]="isVisible"
                [nzStyle]="{ top: '20px' }"
                [nzTitle]="modalTitle"
                [nzContent]="modalContent"
                [nzFooter]="modalFooter"
                (nzOnCancel)="handleCancel()">
        <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

        <ng-template #modalContent>
          <form nz-form>
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Enter Product Name</nz-form-label>
              <nz-form-control nzValidateStatus="success"
                               [nzSpan]="14"
                               nzErrorTip="Should be combination of numbers & alphabets">
                <input nz-input [(ngModel)]="NewProduct.ProductName" name="ProductName" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Enter Product Code</nz-form-label>
              <nz-form-control nzValidateStatus="success"
                               [nzSpan]="14"
                               nzErrorTip="Should be combination of numbers & alphabets">
                <input nz-input [(ngModel)]="NewProduct.ProductCode" name="ProductCode" />
              </nz-form-control>
            </nz-form-item>
            <!-- <nz-form-item>

              
              <nz-form-label [nzSpan]="8">Select Product Type</nz-form-label>
              <nz-form-control [nzSpan]="14" nzValidateStatus="success">
                <nz-select name="select-error" [(ngModel)]="NewProduct.ProductType">

                  <nz-option *ngFor="let s of this.typeList;" [nzValue]="s.name" [nzLabel]="s.name"></nz-option>

                </nz-select>
              </nz-form-control>
            </nz-form-item> -->
            <nz-form-item>

              <nz-form-label [nzSpan]="8" nzRequired>Select Unit</nz-form-label>
              <nz-form-control [nzSpan]="14" nzValidateStatus="success">
                <nz-select name="select-error1" [disabled]="!this.isNew" nzShowSearch [(ngModel)]="NewProduct.Unit">
                  <nz-option nzValue="0" nzLabel="Select Unit"></nz-option>
                  <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Product Type</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Type">
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewProduct.ProductType" name="ProductType" (ngModelChange)="GetAllProductCategory()" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.typeList;" [nzValue]="s.name" [nzLabel]="s.name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Category</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Category">
                <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="NewProduct.ProductCategoryId" name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllFirstCategory($event)">
                  <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8">Sub Category</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Select Product First Category">
                <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="NewProduct.ProductFirstSubCategoryId" name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllSecondCategory($event)">
                  <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8">2nd Sub Category</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Second Category">
                <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="NewProduct.ProductSecSubCategoryId" name="ProductSecSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8">Average GSM(m²)</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input [(ngModel)]="NewProduct.AvgGsm" type="number" name="AvgGsm" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8">Minimum Quantity</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input [(ngModel)]="NewProduct.MinimumQuantity" type="number" name="MinimumQuantity" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8">Width in meter</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input [(ngModel)]="NewProduct.WidthInMeter" type="number" name="WidthInMeter" />
              </nz-form-control>
            </nz-form-item>
          </form>
          <form nz-form [formGroup]="AddProductDescription">
            <nz-form-item>
              <nz-form-label [nzSpan]="8">Description</nz-form-label>
              <nz-form-control nzErrorTip="Only 800 characters allowed.">
                <nz-textarea-count [nzMaxCharacterCount]="800">
                  <textarea nz-input [(ngModel)]="NewProduct.ProductDescription" formControlName="description"
                   value="" rows="5"></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </form>
        </ng-template>

        <ng-template #modalFooter>

          <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
          <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isLoading">Save Product</button>
        </ng-template>
      </nz-modal>
    </div>
  </div>


