import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.css']
})
export class SupplierListComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  SupplierList: SupplierModel[] = [];
  SupplierListOriginal: SupplierModel[] = [];
  NewSupplier: SupplierModel = new SupplierModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Supplier";
  NameError = 'Enter Supplier name';
  exportoptions = {
    headers: ["Supplier Name", "Email", "Supplier Contact Number", "Contact Person Name", "Contact Person Number", "Address", "GST"]
  };
  fields: any;
  exportfields = [{
    "SupplierName": "",
    "Email": "",
    "SupplierContactNumber": "",
    "ContactPersonName": "",
    "ContactPersonNumber": "",
    "Address": "",
    "Gst": ""
  }];
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Supplier, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Supplier, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Supplier, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      SupplierName: [null, [Validators.required, Validators.minLength(3)], [this.NameAsyncValidator]],
      ContactPersonName: [null, [Validators.required]],
      Email: [null, [Validators.email]],
      //SupplierContactNumber: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      SupplierContactNumber: [null, [Validators.required]],
      ContactPersonNumber: [null, [Validators.required]],
      Address: [null, [Validators.required]],
      Gst: [null]
      //Gst: [null, [Validators.required,  Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]]
    });
    this.GetAllSupplier();
  }
  get f() { return this.validateForm.controls; }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  GetAllSupplier() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
      this.SupplierListOriginal = res;
      this.exportfields = [];
      this.SupplierList.forEach(x => {
        this.fields = {};
        this.fields.SupplierName = x.SupplierName;
        this.fields.Email = x.Email;
        this.fields.SupplierContactNumber = x.SupplierContactNumber;
        this.fields.ContactPersonName = x.ContactPersonName;
        this.fields.ContactPersonNumber = x.ContactPersonNumber;
        this.fields.Address = x.Address;
        this.fields.Gst = x.Gst;
        this.exportfields.push(this.fields);

      })
      this.isTableLoading = false;
    }, res => { this.GetAllSupplier(); });
  }
  SaveSupplier() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewSupplier.SupplierName = this.f['SupplierName'].value;
    this.NewSupplier.ContactPersonName = this.f['ContactPersonName'].value;
    this.NewSupplier.Email = this.f['Email'].value;
    this.NewSupplier.SupplierContactNumber = this.f['SupplierContactNumber'].value;
    this.NewSupplier.ContactPersonNumber = this.f['ContactPersonNumber'].value;
    this.NewSupplier.Address = this.f['Address'].value;
    this.NewSupplier.Gst = this.f['Gst'].value;
    // if(!this.Validate(this.NewSupplier))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "supplier/addupdatesupplier";
    this.http.post<SupplierModel>(url, this.NewSupplier).subscribe({

      next: res => { this.alertService.success("Supplier Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllSupplier(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  Validate(model: SupplierModel) {
    var Isvalid: boolean = true;
    const emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    if (model.SupplierName == '') {
      this.alertService.error("Enter Supplier Name"); Isvalid = false;
    }
    else if (model.ContactPersonName == '') {
      this.alertService.error("Enter Contact Person Name"); Isvalid = false;
    }
    // else if (model.Email == '') {
    //   this.alertService.error("Enter Email"); Isvalid = false;
    // }

    return Isvalid;
  }
  OpenEditPop(data: SupplierModel) {
    this.PopUpTitle = "Modify Supplier Details";
    this.validateForm.setValue(
      {
        SupplierName: data.SupplierName,
        ContactPersonName: data.ContactPersonName,
        Email: data.Email,
        SupplierContactNumber: data.SupplierContactNumber,
        ContactPersonNumber: data.ContactPersonNumber,
        Address: data.Address,
        Gst: data.Gst
      }
    )
    this.NewSupplier = new SupplierModel();
    this.NewSupplier.SupplierId = data.SupplierId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Supplier";
    this.validateForm.reset();
    //this.validateForm.setValue(
    //  {
    //    SupplierName: null,
    //    ContactPersonName: null,
    //    Email: null,
    //    SupplierContactNumber: null,
    //    ContactPersonNumber: null,
    //    Address: null,
    //    Gst: null
    //  }
    //)
    this.NewSupplier.SupplierName = "";
    this.NewSupplier.SupplierId = 0;
    this.NewSupplier.ContactPersonName = "";
    this.NewSupplier.Email = "";
    this.NewSupplier.SupplierContactNumber = "";
    this.NewSupplier.ContactPersonNumber = "";
    this.NewSupplier.Address = "";
    this.NewSupplier.Gst = "";
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveSupplier();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  export() {

    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'supplier-export' + exportdate, this.exportoptions);
  }
  DeleteSupplier() {
    let url = this.ApiUrl + "supplier/deletesupplier";
    this.http.post<any>(url, this.NewSupplier.SupplierId).subscribe({

      next: res => {

        this.alertService.success("Supplier Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllSupplier();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: SupplierModel) {
    this.NewSupplier = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this supplier?',
      nzOnOk: () => this.DeleteSupplier(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.SupplierList.filter(x => x.SupplierId != this.NewSupplier.SupplierId);
        var nre = res.filter(x => x.SupplierName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Supplier name";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Supplier name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Supplier name";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search(): void {
    this.visible = false;
    var res = this.SupplierListOriginal;
    this.SupplierList = res.filter((item: SupplierModel) => item?.SupplierName?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.Email?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.Address?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.ContactPersonName?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.ContactPersonNumber?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.Gst?.toLowerCase().includes(this.searchValue?.toLowerCase())
      || item?.SupplierContactNumber?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1);
  }

}
