import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { DeliveryTermModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
@Component({
  selector: 'app-delivery-term',
  templateUrl: './delivery-term.component.html',
  styleUrls: ['./delivery-term.component.css']
})
export class DeliveryTermComponent implements OnInit {

  validateForm!: UntypedFormGroup;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  ApiUrl = environment.Api_Url;
  DeliveryTermList: DeliveryTermModel[] = [];
  DeliveryTermListOriginal: DeliveryTermModel[] = [];
  NewDeliveryTerm: DeliveryTermModel = new DeliveryTermModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New DeliveryTerm";
  NameError = 'Enter Department name';
  searchValue = ''
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.DeliveryTerm, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.DeliveryTerm, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.DeliveryTerm, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      DeliveryTermName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      DeliveryTermDesc: [null, [Validators.maxLength(200)]]

    });
    this.GetAllDeliveryTerm();
  }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  get f() { return this.validateForm.controls; }

  GetAllDeliveryTerm() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "deliveryterm/getalldeliveryterms";
    this.http.get<DeliveryTermModel[]>(url).subscribe(res => {
      this.DeliveryTermList = res;
      this.DeliveryTermListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllDeliveryTerm();
      }
    });
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllDeliveryTerm();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }


  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.DeliveryTermListOriginal;
    var res2 = this.DeliveryTermListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: DeliveryTermModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.DeliveryTermAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.DeliveryTermAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.DeliveryTermList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.DeliveryTermList = res.filter(
        (item: DeliveryTermModel) =>
          item?.DeliveryTerm?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.DeliveryTermDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.DeliveryTermAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  SaveDeliveryTerm() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewDeliveryTerm.DeliveryTerm = this.f['DeliveryTermName'].value;
    this.NewDeliveryTerm.DeliveryTermDesc = this.f['DeliveryTermDesc'].value;
    this.NewDeliveryTerm.DeliveryTermAddedBy = UserInfo.EmailID;
    //var data = this.DeliveryTermList.filter(x=>x.DeliveryTerm == this.NewDeliveryTerm.DeliveryTerm);
    //if(data.length>0){
    //  if(this.NewDeliveryTerm.DeliveryTermId > 0){
    //    var res = this.DeliveryTermList.filter(x=>x.DeliveryTermId != this.NewDeliveryTerm.DeliveryTermId);
    //    var nre = res.filter(x=>x.DeliveryTerm == this.NewDeliveryTerm.DeliveryTerm);
    //    if( nre.length > 0 ){
    //      this.alertService.error("Delivery Term already exist. Please try again")
    //      this.isLoading = false
    //      return;
    //    }
    //  }
    //  else{
    //    this.alertService.error("Delivery Term already exist. Please try again")
    //    this.isLoading = false
    //    return;
    //  }
    //}
    // if(!this.Validate(this.NewDeliveryTerm))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "deliveryterm/addupdatedeliveryterm";
    this.http.post<DeliveryTermModel>(url, this.NewDeliveryTerm).subscribe({

      next: res => { this.alertService.success("DeliveryTerm Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllDeliveryTerm(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: DeliveryTermModel) {
    this.PopUpTitle = "Modify DeliveryTerm Details";
    this.validateForm.setValue(
      {
        DeliveryTermName: data.DeliveryTerm,
        DeliveryTermDesc: data.DeliveryTermDesc,

      }
    )
    this.NewDeliveryTerm = new DeliveryTermModel();
    this.NewDeliveryTerm.DeliveryTermId = data.DeliveryTermId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New DeliveryTerm";
    this.validateForm.reset();
    this.NewDeliveryTerm.DeliveryTerm = "";
    this.NewDeliveryTerm.DeliveryTermId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveDeliveryTerm();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteDeliveryTerm() {
    let url = this.ApiUrl + "deliveryTerm/deleteDeliveryTerm";
    this.http.post<any>(url, this.NewDeliveryTerm).subscribe({

      next: res => {

        this.alertService.success("Delivery Term Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllDeliveryTerm();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: DeliveryTermModel) {
    this.NewDeliveryTerm = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Delivery Term?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteDeliveryTerm(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.DeliveryTermList.filter(x => x.DeliveryTermId != this.NewDeliveryTerm.DeliveryTermId);
        var nre = res.filter(x => x.DeliveryTerm.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter DeliveryTerm name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "DeliveryTerm name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter DeliveryTerm name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

}
