import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PurchaseOrderModel } from '../../../Models/PurchaseOrderModel';
import { CompanyInfo } from '../../../Authentication/UserInfo';
import { SupplierModel } from '../../../Models/SupplierModel';
import { WorkPlanJumboMasterModel } from '../../../Models/WorkPlanModel';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  selector: 'app-inspectionprint',
  templateUrl: './inspectionprint.component.html',
  styleUrls: ['./inspectionprint.component.css']
})
export class InspectionPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  NewSupplier: SupplierModel = new SupplierModel();
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Production';
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription();
  Poid = 0;
  Jumboid = 0;
  InspectionId = 0;
  Company = {
    CompanyName: '',
    Contact: '',
    Email: '',
    Address: '',
    GST: '',
  };
  JumboData = {
    JUMBOROLLID: '',
    ITEMNAME: '',
    PRODUCTCODE: '',
    GRAIN: '',
    COLOUR: '',
    FINISH: '',
    THICKNESS: '',
    WIDTH: '',
    LENGTH: '',
    GRADE: '',
    INSPECTEDBY: '',
  }
  jumboTableData: WorkPlanJumboMasterModel[] = [];
  permission = {
    View: false
  }
  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) {}
                                     
  ngOnInit() {
    // this.permission.View = this.auth.CheckResponsibility(Modules.FinalInspection, Responsibility.View);
    // if (this.permission.View != true) {
    //   this.router.navigate(['/home/unauthorized']);
    // }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.Poid = params['id'];
      this.Jumboid = params['JumboId'];
      this.InspectionId = params['InspectionId'];
    });
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.Contact = CompanyInfo.Contact;
    this.Company.Email = CompanyInfo.Email;
    this.Company.Address = CompanyInfo.ShortAddress;
    // this.Company.GST = CompanyInfo.GST;
    // this.Company.Website = CompanyInfo.Website;
    //this.OpenViewPop();
    
  }
  GetAllSupplier() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'supplier/getallsuppliers';
    this.http.get<SupplierModel[]>(url).subscribe(
      (res) => {
        this.NewSupplier = res.filter(
          (x) => x.SupplierId == this.PurchaseOrder.SupplierId
        )[0];
        
      },
      (res) => {}
    );
  }
  getJumboRollsById(): void {
    var check = false;
    let url = this.ApiUrl + 'workplan/getworkplanjumbobyworkplanordersid';
    this.http.get<any>(url + '/' + this.Poid).subscribe(
      (res) => {
        this.jumboTableData = res.Data;
        this.jumboTableData = this.jumboTableData.filter(x => x.WorkPlanJumboMasterId == this.Jumboid)
        this.JumboData.JUMBOROLLID = this.jumboTableData[0].JumboInspection.filter(el => el.JumboInspectionId == this.InspectionId)[0].Code;
        this.JumboData.LENGTH = this.jumboTableData[0].JumboInspection.filter(el => el.JumboInspectionId == this.InspectionId)[0].Quantity.toString()
        this.JumboData.GRADE = this.jumboTableData[0].JumboInspection.filter(el => el.JumboInspectionId == this.InspectionId)[0].Grade.toString()
        this.JumboData.INSPECTEDBY = this.jumboTableData[0].JumboInspection.filter(el => el.JumboInspectionId == this.InspectionId)[0].InspectedBy.toString()
        check = true;
        if (check == true) {
          setTimeout(function () {
            window.print();
            window.onafterprint = close;
            function close() {
              window.close();
          }
          }, 1);
        }
        
        
        console.log(this.jumboTableData[0])

      });
  }
  OpenViewPop() {
    
    let url = this.ApiUrl + 'saleorder/getsaleorderdatabyidforgradeprint/' + this.Poid;
    this.http.get<any>(url).subscribe((res) => {
      this.getJumboRollsById();
      //this.Orderlist = res;
      console.log(res)
      this.JumboData.COLOUR = res.SaleOrderProduction.ColorCode
      this.JumboData.GRAIN = res.SaleOrderProduction.GrainCode
      this.JumboData.THICKNESS = res.SaleOrderProduction.ThicknessValue
      this.JumboData.PRODUCTCODE = res.SaleOrderProduction.ProductCode
      this.JumboData.WIDTH = res.SaleOrderProduction.WidthNumber
      this.JumboData.FINISH = res.FinishCode
      this.JumboData.ITEMNAME = res.SaleOrderProduction.ManufacturingProductName
      this.JumboData.PRODUCTCODE = res.SaleFormulationCode
    });
    }
    CheckOnload() {
        this.OpenViewPop();
    }
}


  
  
  
