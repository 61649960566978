<div *ngIf="IsPopupOpen | async">
  
    <nz-drawer [nzClosable]="true" [nzVisible]="true" nzPlacement="right" [nzWidth]="1000"
               nzTitle="Sales Order Timeline" (nzOnClose)="handleTimelineCancel()">
        <ng-container *nzDrawerContent>
            <div>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="ActivityLogList" nzSize="small" [nzColumn]="6">
                    <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
                        {{ ActivityLogList[0].SaleOrderType }}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="2">{{ ActivityLogList[0].CustomerName }}</nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1">
                        {{ ActivityLogList[0].SaleOrderNumber }}
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Sale Order Date">
                        {{ ActivityLogList[0].SaleOrderDate | date: "dd-MMM-yyyy" }}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Delivery Date">
                        {{ ActivityLogList[0].DeliveryDate | date: "dd-MMM-yyyy" }}
                    </nz-descriptions-item>
                </nz-descriptions>
                <div style="margin-top: 20px;">
                    <button nz-button nzSize="large" [nzLoading]="refreshBtnLoading" nzType="primary" (click)="GetTimelineRefresh(TimeLineObject.SaleOrderNumber)">Refresh</button>
                </div>
                <nz-divider nzText="Time Line"></nz-divider>

                <nz-steps nzDirection="vertical">
                    <nz-step *ngIf="GetTimeLineStatus(0) != 'hide'" nzTitle="Sale Order Created" [nzStatus]='GetTimeLineStatus(0)' [nzDescription]="GetTimeLineDescription(0)" [nzSubtitle]="GetTimeLineSubtitle(0)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(1) != 'hide'" nzTitle="Work plan Submitted" [nzStatus]='GetTimeLineStatus(1)' [nzDescription]="GetTimeLineDescription(1)" [nzSubtitle]="GetTimeLineSubtitle(1)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(2) != 'hide'" nzTitle="Inspection done" [nzStatus]='GetTimeLineStatus(2)' [nzDescription]="GetTimeLineDescription(2)" [nzSubtitle]="GetTimeLineSubtitle(2)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(3) != 'hide'" nzTitle="Raw Material Requested" [nzStatus]='GetTimeLineStatus(3)' [nzDescription]="GetTimeLineDescription(3)" [nzSubtitle]="GetTimeLineSubtitle(3)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(4) != 'hide'" nzTitle="Raw Material Issued" [nzStatus]='GetTimeLineStatus(4)' [nzDescription]="GetTimeLineDescription(4)" [nzSubtitle]="GetTimeLineSubtitle(4)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(5) != 'hide'" nzTitle="Production Started" [nzStatus]='GetTimeLineStatus(5)' [nzDescription]="GetTimeLineDescription(5)" [nzSubtitle]="GetTimeLineSubtitle(5)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(7) != 'hide'" nzTitle="Jumbo Creation In-Progress" [nzStatus]='GetTimeLineStatus(7)' [nzDescription]="GetTimeLineDescription(7)" [nzSubtitle]="GetTimeLineSubtitle(7)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(8) != 'hide'" nzTitle="All Jumbo Created & Sent To Post Process" [nzStatus]='GetTimeLineStatus(8)' [nzDescription]="GetTimeLineDescription(8)" [nzSubtitle]="GetTimeLineSubtitle(8)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(9) != 'hide'" nzTitle="Print Assigned" [nzStatus]='GetTimeLineStatus(9)' [nzDescription]="GetTimeLineDescription(9)" [nzSubtitle]="GetTimeLineSubtitle(9)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(10) != 'hide'" nzTitle="Print In-Process" [nzStatus]='GetTimeLineStatus(10)' [nzDescription]="GetTimeLineDescription(10)" [nzSubtitle]="GetTimeLineSubtitle(10)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(11) != 'hide'" nzTitle="Print Completed" [nzStatus]='GetTimeLineStatus(11)' [nzDescription]="GetTimeLineDescription(11)" [nzSubtitle]="GetTimeLineSubtitle(11)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(12) != 'hide'" nzTitle="Embossing Assigned" [nzStatus]='GetTimeLineStatus(12)' [nzDescription]="GetTimeLineDescription(12)" [nzSubtitle]="GetTimeLineSubtitle(12)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(13) != 'hide'" nzTitle="Embossing In-Process" [nzStatus]='GetTimeLineStatus(13)' [nzDescription]="GetTimeLineDescription(13)" [nzSubtitle]="GetTimeLineSubtitle(13)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(14) != 'hide'" nzTitle="Embossing Completed" [nzStatus]='GetTimeLineStatus(14)' [nzDescription]="GetTimeLineDescription(14)" [nzSubtitle]="GetTimeLineSubtitle(14)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(15) != 'hide'" nzTitle="Vacuum Assigned" [nzStatus]='GetTimeLineStatus(15)' [nzDescription]="GetTimeLineDescription(15)" [nzSubtitle]="GetTimeLineSubtitle(15)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(16) != 'hide'" nzTitle="Vacuum In-Process" [nzStatus]='GetTimeLineStatus(16)' [nzDescription]="GetTimeLineDescription(16)" [nzSubtitle]="GetTimeLineSubtitle(16)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(17) != 'hide'" nzTitle="Vacuum Completed" [nzStatus]='GetTimeLineStatus(17)' [nzDescription]="GetTimeLineDescription(17)" [nzSubtitle]="GetTimeLineSubtitle(17)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(18) != 'hide'" nzTitle="Lacquer Assigned" [nzStatus]='GetTimeLineStatus(18)' [nzDescription]="GetTimeLineDescription(18)" [nzSubtitle]="GetTimeLineSubtitle(18)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(19) != 'hide'" nzTitle="Lacquer In-Process" [nzStatus]='GetTimeLineStatus(19)' [nzDescription]="GetTimeLineDescription(19)" [nzSubtitle]="GetTimeLineSubtitle(19)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(20) != 'hide'" nzTitle="Lacquer Completed" [nzStatus]='GetTimeLineStatus(20)' [nzDescription]="GetTimeLineDescription(20)" [nzSubtitle]="GetTimeLineSubtitle(20)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(21) != 'hide'" nzTitle="Tumbling Assigned" [nzStatus]='GetTimeLineStatus(21)' [nzDescription]="GetTimeLineDescription(21)" [nzSubtitle]="GetTimeLineSubtitle(21)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(22) != 'hide'" nzTitle="Tumbling In-Process" [nzStatus]='GetTimeLineStatus(22)' [nzDescription]="GetTimeLineDescription(22)" [nzSubtitle]="GetTimeLineSubtitle(22)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(23) != 'hide'" nzTitle="Tumbling Completed" [nzStatus]='GetTimeLineStatus(23)' [nzDescription]="GetTimeLineDescription(23)" [nzSubtitle]="GetTimeLineSubtitle(23)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(24) != 'hide'" nzTitle="Sent for Quality Check(Final Inspection)" [nzStatus]='GetTimeLineStatus(24)' [nzDescription]="GetTimeLineDescription(24)" [nzSubtitle]="GetTimeLineSubtitle(24)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(25) != 'hide'" nzTitle="Move To Dispatch" [nzStatus]='GetTimeLineStatus(25)' [nzDescription]="GetTimeLineDescription(25)" [nzSubtitle]="GetTimeLineSubtitle(25)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(26) != 'hide'" nzTitle="Partially Dispatch Ready" [nzStatus]='GetTimeLineStatus(26)' [nzDescription]="GetTimeLineDescription(26)" [nzSubtitle]="GetTimeLineSubtitle(26)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(27) != 'hide'" nzTitle="Partially Dispatch Completed" [nzStatus]='GetTimeLineStatus(27)' [nzDescription]="GetTimeLineDescription(27)" [nzSubtitle]="GetTimeLineSubtitle(27)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(28) != 'hide'" nzTitle="All Dispatch Ready" [nzStatus]='GetTimeLineStatus(28)' [nzDescription]="GetTimeLineDescription(28)" [nzSubtitle]="GetTimeLineSubtitle(28)"></nz-step>
                     <nz-step *ngIf="GetTimeLineStatus(28) != 'hide'" nzTitle="All Dispatch Completed" [nzStatus]='GetTimeLineStatus(28)' [nzDescription]="GetTimeLineDescription(28)" [nzSubtitle]="GetTimeLineSubtitle(28)"></nz-step> 
                    <nz-step *ngIf="GetTimeLineStatus(30) != 'hide'" nzTitle="All Dispatch Completed" [nzStatus]='GetTimeLineStatus(30)' [nzDescription]="GetTimeLineDescription(30)" [nzSubtitle]="GetTimeLineSubtitle(30)"></nz-step>
                    <nz-step *ngIf="GetTimeLineStatus(101) != 'hide'" nzTitle="Sandwich Order Merged" [nzStatus]='GetTimeLineStatus(101)' [nzDescription]="GetTimeLineDescription(101)" [nzSubtitle]="GetTimeLineSubtitle(101)"></nz-step>


                </nz-steps>
                <div *ngIf="ActivityLogList">
                    <p *ngIf="ActivityLogList[0].Status == 'LiningOrderMerged'"><b>This Order is only for Lining to create Sandwich
                        Order and the produciton flow is stopped now </b></p>
                  </div>
                  <div *ngIf="ActivityLogList">
                    <p *ngIf="ActivityLogList[0].Status == 'LiningOrderMerged'"><b>and this order won't be shown in any further steps in
                        production.</b></p>
                  </div>
            </div>
        </ng-container>
    </nz-drawer>
</div>
