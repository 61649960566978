import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';


@Component({
  selector: 'app-digital-weight',
  templateUrl: './digital-weight.component.html',
  styleUrls: ['./digital-weight.component.css']
})
export class DigitalWeightComponent {

  ApiUrl = environment.Api_Url;

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  ProductList: ProductModel[];
  weightres: number[];
  count: any;
  constructor(
    public http: HttpClient,
    private auth: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private loader: LoadingService) { }

  digitalWeightList = [
    { name: 'Demo 1', weight: '10kg' },
    { name: 'Demo 2', weight: '1kg' },
    { name: 'Demo 3', weight: '5kg' },
    { name: 'Demo 4', weight: '3kg' },
    { name: 'Demo 5', weight: '6kg' },
  ];

  name = null;
  index = -1;
  SelectedRow: ProductModel;
  textLable = null;

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.IoTDevicesDigitalWeight, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.IoTDevicesDigitalWeight, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.IoTDevicesDigitalWeight, Responsibility.Delete);

    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    else {
      this.GetProductsByCategory();
    }

  }

  setSelected(item: ProductModel, index) {
    this.SelectedRow = item;
    this.name = item.ProductName;
    this.index = index;
    this.weightres = [];
    console.log(item);
  }

  captureWeight() {
    this.loader.show();
    let url = "http://localhost:8081/Weight/captureweight";
    this.http.get<number[]>(url).subscribe({
      next: res => {
        console.log(res);
        this.weightres = res;
        console.log(this.weightres);
        this.loader.hide();
      },
      error: () => {
        this.loader.hide();
        this.alertService.error("Error trying to reach Digital Weight Machine. Check if device is Powered On or Switched Off.")
      }
    })
  }

  save(item: ProductModel, weight: number) {
    var request = {
      ProductId: item.ProductId,
      Weight: weight
    }
    this.loader.show();
    let url = this.ApiUrl + "iot/addknittingfabricwithweight";
    this.http.post<any>(url, request).subscribe({
      next: res => {
        this.alertService.success("Weight Saved Successfully...!!!");
        this.loader.hide();
      },
      error: (res) => {
        this.loader.hide();
        this.alertService.error(res.error.ResponseBody);
      }
    })

  }
  GetProductsByCategory() {
    var request = {
      ProductCategoryName: 'Fabric',
      ProductFirstSubCategoryName: 'Fabric Loopknit',
      ProductSecSubCategoryName: ''
    }
    this.loader.show();
    let url = this.ApiUrl + "product/getproductsbycategorynames";
    this.http.post<ProductModel[]>(url, request).subscribe({
      next: res => {
        this.ProductList = res;
        this.loader.hide();
      },
      error: () => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetProductsByCategory(); }
      }
    })
  }

}
