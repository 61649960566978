<div class="print-wrap" style="border:1px solid">
  <div style="padding: 5px 0; margin-top: 10px;font-size: 13px; text-align: center; color: black; background: #CCC;">
    <b> STANDARD FORMULATION SHEET</b>
  </div>
  <nz-descriptions *ngIf="FormulationCode" nzBordered nzSize="small" [nzColumn]="2"
    style="margin-left: 18px; margin-right:18px;border-right: 1px solid black;">
    <nz-descriptions-item nzTitle="FORMULATION CODE"
      nzTitleWidth="50%"><b>{{FormulationCode.SaleFormulationCode}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="APPLICATION" nzTitleWidth="50%"><b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Grain" nzTitleWidth="50%"><b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Printing" nzTitleWidth="50%"> <b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Colour" nzTitleWidth="50%"><b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Embossing" nzTitleWidth="50%"><b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Fabric"
      nzTitleWidth="50%"><b>{{FormulationCode.FabricProductName}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Tumbling" nzTitleWidth="50%"><b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Thickness"
      nzTitleWidth="50%"><b>{{FormulationCode.ThicknessNumber}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Lacquer" nzTitleWidth="50%"><b>{{""}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Last Updated By"
      nzTitleWidth="50%"><b>{{FormulationCode.AddedBy}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Last Updated Date Time" nzTitleWidth="50%"><b>{{FormulationCode.AddedDate | DatetimeConverter}}</b></nz-descriptions-item>
  </nz-descriptions>
  <div *ngIf="FormulationCode" nz-row [nzGutter]="24" style="margin: 0px 0px;" class="print-wrap">
    <div nz-col [nzSpan]="12" *ngFor="let i of FormulationCode.MixingData">
      <div
        style="padding: 2px 0; margin-top: 2px; text-transform: uppercase; text-align: center; color: black; background-color: #CCC;border: 1px solid black;">
        <b>{{i.MixingName}}</b>
      </div>
      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false"
        style="border-right: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">
        <tbody>
          <tr>
            <td width="60%" style="text-align:left;"><b>ITEM NAME</b></td>
            <td style="text-align:center;"><b>RATIO</b></td>
          </tr>
          <tr *ngFor="let data of i.MixingRawMaterial;let i=index">

            <td width="60%" style="text-align:left;text-transform: uppercase;">{{ data.ProductName }}</td>
            <td style="text-align:center;">{{ data.Quantity }}</td>
          </tr>

        </tbody>
        <tfoot style="background: #d9d9d9;">
          <tr>
            <td width="60%" style="text-align:left;border-right: 1px solid #BBB;border-bottom: 1px solid #BBB;"><b> WT.
                in GSM </b></td>
            <td style="text-align:center;border-right: 1px solid #BBB;border-bottom: 1px solid #BBB;"><b>{{i.GSM}}</b>
            </td>
          </tr>
        </tfoot>
      </nz-table>
    </div>
  </div>
  <nz-divider></nz-divider>
  <nz-descriptions nzBordered nzSize="small" [nzColumn]="2"
    style="margin-left: 18px; margin-right:18px;border-right: 1px solid black;">
    <nz-descriptions-item nzTitle="Pre Skin"><b>{{FormulationCode.PreSkinGsm}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Skin"><b>{{FormulationCode.SkinGsm}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Foam"><b>{{FormulationCode.FoamGsm}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Adh."> <b>{{FormulationCode.AdhesiveGsm}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Final Wt.(GSM)"><b>{{TotalGSM}}</b></nz-descriptions-item>
  </nz-descriptions>
</div>
<app-loader></app-loader>