import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';



@Pipe({
  name: 'RoundOff'
})

export class RoundOff implements PipeTransform {
  constructor() {
  }
  transform(value: number) {
    if (value) {

      return value.toFixed(2)
    }
    else {
      return '';
    }
  }
}
