<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Consumption List</nz-page-header-title>
      
      <nz-page-header-extra>

        <button nz-button nzType="primary" routerLink="/home/consumption/add" *ngIf="this.permission.Add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzShowTime="true"  nzPlaceHolder="Start Date" style="width: 200px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzShowTime="true"  nzPlaceHolder="End Date" style="width: 200px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search" [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i> Export</button>
    </div>

    <nz-table *ngIf="this.ConsumptionList.length>0"   [nzPageSize]="100" nzSize="small" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable [nzData]="this.ConsumptionList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          
          <th nzWidth="200px" nzLeft>Product</th>
          <th nzWidth="150px">Sale Order </th>
          <th nzWidth="100px">Quantity</th>
          <th nzWidth="100px">Is Damaged</th>
          <th nzWidth="150px">Store</th>
          <th nzWidth="150px">Rack</th>
          <th nzWidth="150px">Added Date</th>
          <th nzWidth="200px">Purpose</th>
          <th nzWidth="200px">Added By</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          
          <td nzLeft>{{ data.ProductName }}</td>
          <td>{{ data.SaleOrderNumber }}</td>
          <td>{{ data.Quantity }} {{ data.Unit }}</td>
          <td>
            <span *ngIf="data.IsDamaged == true">Yes</span>
            <span *ngIf="data.IsDamaged != true">No</span>
          </td>
          <td>{{ data.StoreName }}</td>
          <td>{{ data.RackName }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td>{{ data.Purpose }}</td>
          <td>{{ data.AddedBy }}</td>
        </tr>
      </tbody>
    </nz-table>




  </div>
</div>

