<div class="card card-flush h-xl-100">
    <div *ngIf="Orderlist" class="row justify-content-between align-items-center">
        <div class="col-6 col-md-auto">
            <button style="margin: 20px;" nz-button nzType="primary" (click)="goBack()">
                Back
            </button>
        </div>
        <div class="col-6 col-md-auto">
            <button style="margin: 20px;" nz-button nzType="primary" (click)="OpenTimelinePop()">
                Show Timeline
            </button>
        </div>
    </div>
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Sale Order Trail Report</nz-page-header-title>
        </nz-page-header>
        <nz-divider></nz-divider>
        <div *ngIf="Orderlist">
            <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="5">
                <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
                    <b>{{ Orderlist.SaleOrderType }}</b>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="1"><b>{{ Orderlist.CustomerName
                        }}</b>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1"><b>{{ Orderlist.SaleOrderNumber
                        }}</b>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Sale Order Date">
                    <b>
                        {{ Orderlist.SaleOrderDate | date: "dd-MMM-yyyy" }}
                    </b>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Delivery Date">
                    <b>
                        {{ Orderlist.DeliveryDate | date: "dd-MMM-yyyy" }}
                    </b>
                </nz-descriptions-item>
            </nz-descriptions>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(0) ? 'Sales Order Details - Pending' : 'Sales Order Details'"
                    [nzActive]="false" (nzActiveChange)="onSalesFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(0)">
                    <nz-divider nzText="Production Details"></nz-divider>
                    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist.SaleOrderProduction" nzSize="small"
                        [nzColumn]="4">
                        <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                                Orderlist.SaleFormulationCode
                                }}</b></nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ Orderlist.Category
                                }}</b></nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Thick">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.ThicknessValue
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Width">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.WidthNumber
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.ManufacturingProductName
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Order Quantity">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.OrderQuantity
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Sale Price">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.SalePrice
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Total Sale Price">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.TotalSalePrice
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Grain Name">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.GrainName
                                }}
                            </b>
                            <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                                nzTooltipTitle="Grain Code: {{Orderlist.SaleOrderProduction.GrainCode}}"
                                nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                            </span>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Color">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.ColorName
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Fabric Color">
                            <b>
                                {{
                                this.FabricColorName
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Fabric Name">
                            <b>
                                {{
                                this.FabricName
                                }}
                            </b>
                        </nz-descriptions-item>
                        <nz-descriptions-item nzTitle="Manufactured QTY"
                            *ngIf="Orderlist.SaleOrderProduction.ManufacturingQuantity > 0">
                            <b>
                                {{
                                Orderlist.SaleOrderProduction.ManufacturingQuantity
                                }}
                            </b>
                        </nz-descriptions-item>
                    </nz-descriptions>
                    <nz-divider nzText="Post Process"></nz-divider>
                    <div style="
          text-align: center;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 10px;
          background: #fff;
          box-shadow: 1px 1px 2px 2px #415994;">
                        <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                            <thead>
                                <tr>
                                    <th nzWidth="50px">Order</th>
                                    <th>Post Process Name</th>
                                    <th>Name</th>
                                    <th nzWidth="160px">Code</th>
                                    <th nzWidth="40px">Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="postProcessArray.length > 0">
                                <tr *ngFor="let data of postProcessArray; let i = index">
                                    <td nzWidth="20px">{{ data.Rank }}</td>
                                    <td>{{ data.PostProcessName }}</td>
                                    <td>{{ data.Name }}</td>
                                    <td>{{ data.Code }}</td>
                                    <td style="text-align: center">
                                        <div *ngIf="data.Removed == true">
                                            <div class="badge badge-light-danger">InActive</div>
                                            <span nz-icon nzTooltipTitle="Removed by {{ data.RemovedBy }} on {{
                      data.RemovedDate | DatetimeConverter
                    }}" nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                            </span>
                                        </div>
                                        <div class="badge badge-light-success" *ngIf="data.Removed != true">
                                            Active
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                    <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
                        <div nz-col [nzSpan]="24">
                            <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
                                <div nz-col [nzSpan]="24">
                                    <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="5"
                                        style="box-shadow: 1px 1px 2px 2px #415994">
                                        <nz-descriptions-item nzTitle="Pre Skin" nzColon="true">
                                            <b>
                                                {{ Orderlist.SaleOrderProduction.PreSkinGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                        <nz-descriptions-item nzTitle="Skin" nzColon="true">
                                            <b>
                                                {{ Orderlist.SaleOrderProduction.SkinGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                        <nz-descriptions-item nzTitle="Foam" nzColon="true">
                                            <b>
                                                {{ Orderlist.SaleOrderProduction.FoamGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                        <nz-descriptions-item nzTitle="Adhesive" nzColon="true">
                                            <b>
                                                {{ Orderlist.SaleOrderProduction.AdhesiveGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                        <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                                            <b>
                                                {{ Orderlist.SaleOrderProduction.FabricGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                        <nz-descriptions-item nzTitle="Remarks" nzColon="true"><b>{{ Orderlist.Remarks
                                                }}</b></nz-descriptions-item>
                                    </nz-descriptions>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(1) ? 'WorkPlan details - Pending' : 'WorkPlan details'"
                    [nzActive]="false" (nzActiveChange)="onWorkplanFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(1)">
                    <nz-divider nzText="WorkPlan Details"></nz-divider>
                    <div *ngIf="WorkPlanModel">
                        <nz-descriptions nzBordered>
                            <nz-descriptions-item
                                nzTitle="WORKPLAN NO">{{WorkPlanModel.WorkPlanNo}}</nz-descriptions-item>
                            <nz-descriptions-item nzTitle="LOT NO.">{{WorkPlanModel.LotNo}}</nz-descriptions-item>
                            <nz-descriptions-item nzTitle="BATCH NO.">{{WorkPlanModel.BatchNo}}</nz-descriptions-item>
                            <nz-descriptions-item nzTitle="WORKPLAN DATE">{{WorkPlanModel.WorkPlanDate | date:
                                "dd-MMM-yyyy"}}</nz-descriptions-item>
                            <nz-descriptions-item nzTitle="ADDED BY">{{WorkPlanModel.AddedBy}}</nz-descriptions-item>
                            <nz-descriptions-item nzTitle="PRODUCTION DETAILS" [nzSpan]="3">
                                {{WorkPlanModel.ProductionDetails}}</nz-descriptions-item>
                        </nz-descriptions>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(2) ? 'Inspection Details - Pending' : 'Inspection Details'"
                    [nzActive]="false" (nzActiveChange)="onInspectionFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(2)">
                    <div *ngIf="Orderlist">
                        <nz-descriptions nzBordered nzLayout="vertical" *ngIf="IsInspectionCompleted" nzSize="small"
                            [nzColumn]="2">
                            <nz-descriptions-item nzTitle="Inspection Completed On" [nzSpan]="1" nzColon="true">
                                <b>{{ Orderlist.PreInspectionCompletedOn | DatetimeConverter }}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Inspection Completed By"
                                [nzSpan]="2"><b>{{Orderlist.PreInspectionCompletedBy}}</b></nz-descriptions-item>
                        </nz-descriptions>
                        <nz-divider nzText="Inspection Details"></nz-divider>
                        <nz-descriptions nzBordered nzLayout="vertical"
                            [hidden]="Orderlist.FormulationFabricProductId == null" nzSize="small" [nzColumn]="5">
                            <nz-descriptions-item nzTitle="Fabric" [nzSpan]="1" nzColon="true">
                                <b>
                                    <input type="text" [disabled]="IsInspectionCompleted" readonly nz-input
                                        nzSize="default" [(ngModel)]="Orderlist.SaleOrderProduction.FabricProductName"
                                        [ngModelOptions]="{standalone: true}" class="form-control " />
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Unit" [nzSpan]="1" nzColon="true"
                                *ngIf="!IsInspectionCompleted">
                                <b>{{Orderlist.FormulationFabricProductUnit}}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Unit" [nzSpan]="1" nzColon="true"
                                *ngIf="IsInspectionCompleted">
                                <b>{{Orderlist.SaleOrderProduction.FabricProductUnit}}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Width" [nzSpan]="1" nzColon="true"
                                *ngIf="!IsInspectionCompleted">
                                <b>{{Orderlist.FormulationFabricProducWidthInMeter}}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Width" [nzSpan]="1" nzColon="true"
                                *ngIf="IsInspectionCompleted">
                                <b>{{Orderlist.SaleOrderProduction.FabricProductWidthInMeter}}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="QTY" [nzSpan]="1" nzColon="true">
                                <b *ngIf="!IsInspectionCompleted">
                                    <input type="text" name="Febric" readonly nz-input nzSize="default"
                                        [(ngModel)]="FabricQTY" [ngModelOptions]="{standalone: true}"
                                        class="form-control " />
                                </b>
                                <b *ngIf="IsInspectionCompleted">{{Orderlist.FormulationFabricProductQty}}</b> <br>
                                <span *ngIf="isUnitConversionNotDone" style="color: red;">(Conversion could not be
                                    completed, as width is
                                    not available)</span>
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
                            <div nz-col [nzSpan]="24">
                                <nz-divider nzText="Mixing"></nz-divider>
                                <span *ngIf="!IsInspectionCompleted">
                                    <div *ngFor="let i of Orderlist.SaleOrderProduction.FormulationMixing" style="
                          padding: 5px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          margin-top: 10px;
                          background: #fff;
                          box-shadow: 1px 1px 2px 2px #415994;
                        ">
                                        <div style="padding: 10px 0; text-transform: uppercase">
                                            Mixing Name :
                                            <b>{{ i.MixingName }}</b>
                                            <a class="btn btn-sm btn-light-success" [hidden]="IsInspectionCompleted"
                                                style="float: right; margin-bottom: 10px; ">

                                            </a>
                                        </div>
                                        <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                            nzShowPagination="false">
                                            <thead>
                                                <tr>
                                                    <th nzWidth="50px">S.No</th>
                                                    <th nzWidth="70px" *ngIf="i.MixingName.toLowerCase() == 'skin'">
                                                        Base
                                                        Material</th>
                                                    <th>Product</th>
                                                    <th nzWidth="100px">Unit</th>
                                                    <th nzWidth="100px">Price</th>
                                                    <th nzWidth="100px">QTY</th>
                                                    <th nzWidth="100px">SC QTY</th>
                                                </tr>
                                            </thead>
                                            <tbody cdkDropList (cdkDropListDropped)="drop(i.MixingRawMaterial, $event)">
                                                <tr cdkDrag *ngFor="let data of i.MixingRawMaterial; let j = index">
                                                    <td nzWidth="20px">{{ j + 1 }}</td>
                                                    <td nzLeft style="text-align: center"
                                                        *ngIf="i.MixingName.toLowerCase() == 'skin'">
                                                        <input style="margin-right: 5px" type="checkbox"
                                                            [checked]="data.IsBaseMaterial"
                                                            [disabled]="(checkedStatus[i.MixingName] && !data.IsBaseMaterial)" />
                                                    </td>
                                                    <td>{{ data.ProductName }}</td>
                                                    <td>{{ data.Unit }}</td>
                                                    <td>{{ data.Price }}</td>
                                                    <td>
                                                        <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                            type="number" [(ngModel)]="data.Quantity" />
                                                    </td>
                                                    <td> <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                            type="number" [(ngModel)]="data.Scquantity" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </nz-table>
                                    </div>
                                </span>
                                <span *ngIf="IsInspectionCompleted">
                                    <div *ngFor="let i of Orderlist.SaleOrderProduction.InspectionFormulationMixing"
                                        style="
                          padding: 5px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          margin-top: 10px;
                          background: #fff;
                          box-shadow: 1px 1px 2px 2px #415994;
                        ">
                                        <div style="padding: 10px 0; text-transform: uppercase">
                                            Mixing Name :
                                            <b>{{ i.MixingName }}</b>
                                            <a class="btn btn-sm btn-light-success" [hidden]="IsInspectionCompleted"
                                                style="float: right; margin-bottom: 10px; ">
                                            </a>
                                        </div>
                                        <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                            nzShowPagination="false">
                                            <thead>
                                                <tr>
                                                    <th nzWidth="50px">S.No</th>
                                                    <th>Product</th>
                                                    <th nzWidth="100px">Unit</th>
                                                    <th nzWidth="150px">Price</th>
                                                    <th nzWidth="150px">QTY</th>
                                                    <th nzWidth="100px">SC QTY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of i.MixingRawMaterial; let j = index">
                                                    <td nzWidth="20px">{{ j + 1 }}</td>
                                                    <td>{{ data.ProductName }}</td>
                                                    <td>{{ data.Unit }}</td>
                                                    <td>
                                                        <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                            type="number" [(ngModel)]="data.Price" />
                                                    </td>
                                                    <td>
                                                        <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                            type="number" [(ngModel)]="data.Quantity" />
                                                    </td>
                                                    <td> <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                            type="number" [(ngModel)]="data.Scquantity" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </nz-table>
                                    </div>
                                </span>
                            </div>
                            <nz-divider></nz-divider>
                            <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
                                <div nz-col [nzSpan]="24">
                                    <nz-descriptions nzBordered nzLayout="vertical"
                                        [nzColumn]="Orderlist.SaleOrderProduction.FormulationMixing.length"
                                        style="box-shadow: 1px 1px 2px 2px #415994">
                                        <ng-container
                                            *ngFor="let item of Orderlist.SaleOrderProduction.FormulationMixing">
                                            <nz-descriptions-item
                                                *ngIf="item.MixingName.trim().toLowerCase() === 'pre skin'"
                                                nzTitle="Pre Skin (GSM)" nzColon="true">
                                                <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                    type="number"
                                                    [(ngModel)]="Orderlist.SaleOrderProduction.PreSkinGsm" />
                                            </nz-descriptions-item>

                                            <nz-descriptions-item
                                                *ngIf="item.MixingName.trim().toLowerCase() === 'skin'"
                                                nzTitle="Skin (GSM)" nzColon="true">
                                                <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                    type="number" [(ngModel)]="Orderlist.SaleOrderProduction.SkinGsm" />
                                            </nz-descriptions-item>

                                            <nz-descriptions-item
                                                *ngIf="item.MixingName.trim().toLowerCase() === 'foam'"
                                                nzTitle="Foam (GSM)" nzColon="true">
                                                <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                    type="number" [(ngModel)]="Orderlist.SaleOrderProduction.FoamGsm" />
                                            </nz-descriptions-item>
                                            <nz-descriptions-item
                                                *ngIf="item.MixingName.trim().toLowerCase() === 'adhesive'"
                                                nzTitle="Adhesive (GSM)" nzColon="true">
                                                <input [disabled]="IsInspectionCompleted" readonly nz-input
                                                    type="number"
                                                    [(ngModel)]="Orderlist.SaleOrderProduction.AdhesiveGsm" />
                                            </nz-descriptions-item>
                                        </ng-container>

                                    </nz-descriptions>
                                    <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="2"
                                        style="box-shadow: 1px 1px 2px 2px #415994;margin-top: 10px;">
                                        <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                                            <b>
                                                {{ this.FabricGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                        <nz-descriptions-item nzTitle="Total GSM" nzColon="true">
                                            <b>
                                                {{ Orderlist.SaleOrderProduction.PreSkinGsm +
                                                Orderlist.SaleOrderProduction.SkinGsm +
                                                Orderlist.SaleOrderProduction.FoamGsm +
                                                Orderlist.SaleOrderProduction.AdhesiveGsm +
                                                this.FabricGsm }}
                                            </b>
                                        </nz-descriptions-item>
                                    </nz-descriptions>
                                </div>
                            </div>
                            <nz-divider></nz-divider>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(4) ? 'Raw Material Details - Pending' : 'Raw Material Details'"
                    [nzActive]="false" (nzActiveChange)="onRawMaterialPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(4)">
                    <nz-divider nzText="Approved by Store for this Sale Order Only"></nz-divider>
                    <div *ngIf="SaleOrderNumber">
                        <nz-table [nzPageSize]="100" nzSize="small" [nzScroll]="{ x: '1200px',y:'515px' }"
                            style="width: 100%;" #basicTable [nzData]="this.ProductionList" nzBordered>
                            <thead>
                                <tr style="font-size: smaller;">
                                    <th nzWidth="100px">Issue Serial No.</th>
                                    <th nzWidth="170px">Issue Slip No.</th>
                                    <th nzWidth="100px">Request Type</th>
                                    <th nzWidth="200px" nzLeft>Product</th>
                                    <th nzWidth="80px">Demand QTY</th>
                                    <th nzWidth="140px">Batch No.</th>
                                    <th nzWidth="180px">From Store</th>
                                    <th nzWidth="150px">To Store</th>
                                    <th nzWidth="150px">Remark</th>
                                    <th nzWidth="110px">Added Date Time</th>
                                    <th nzWidth="150px">Added By</th>
                                    <th nzWidth="110px">Action Date Time</th>
                                    <th nzWidth="150px">Action By</th>
                                    <th nzWidth="120px" style="text-align:center" nzRight>Status
                                        <span nz-icon nzType="caret-up" nzTheme="outline"
                                            (click)="onUp('Status',basicTable.data)"></span>
                                        <span nz-icon nzType="caret-down" nzTheme="outline"
                                            (click)="onDown('Status',basicTable.data)"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of basicTable.data;">
                                    <td>{{ data.IssueId }}</td>
                                    <td>{{ data.IssueSlipNumber }}</td>
                                    <td>{{ data.RequestType }}</td>
                                    <td nzLeft>{{ data.ProductName }}</td>
                                    <td>{{ data.DemandQuantity }}</td>
                                    <td>{{ data.Batch }}</td>
                                    <td>{{ data.FromStoreName }}</td>
                                    <td>{{ data.ToStoreName }}</td>
                                    <td>{{ data.Remark }}</td>
                                    <td>{{ data.CreatedDate | DatetimeConverter }}</td>
                                    <td>{{ data.CreatedBy }}</td>
                                    <td>{{ data.ActionDate | DatetimeConverter }}</td>
                                    <td>{{ data.ActionBy }}</td>
                                    <td nzRight style="text-align:center">{{ data.Status }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <div style="text-align: left; margin-top: 10px; font-weight: bold;">
                            <p>Number of automated approved requests: {{ getAutomatedApprovedCount() }}</p>
                            <p>Auto approved means the raw materials were already present in the respective store or it was requested by Store To Store Issue Request.</p>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(5) ? 'Production Details - Pending' : 'Production Details'"
                    [nzActive]="false" (nzActiveChange)="onProductionPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(5)">
                    <nz-descriptions nzBordered>
                        <nz-descriptions-item nzTitle="WORKPLAN NO.">{{WorkplanNo(5)}}</nz-descriptions-item>
                        <nz-descriptions-item nzTitle="PRODUCTION DATE">{{ProductionDate(5)}}</nz-descriptions-item>
                        <nz-descriptions-item nzTitle="ADDED BY">{{AddedBy(5)}}</nz-descriptions-item>
                    </nz-descriptions>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel [nzHeader]="!GetTimeLineStatus(7) ? 'Jumbo Details - Pending' : 'Jumbo Details'"
                    [nzActive]="false" (nzActiveChange)="onJumboFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(7)">
                    <div *ngIf="jumboTableData.length > 0">
                        <nz-table style="width: 100%" [nzPageSize]="100" nzSize="small"
                            [nzScroll]="{ x: '1200px', y: '515px' }" #basicTable [nzData]="['']"
                            [nzShowPagination]="false" nzBordered>
                            <thead>
                                <tr>
                                    <th>Jumbo No.</th>
                                    <th>Date</th>
                                    <th>StartTime</th>
                                    <th>EndTime</th>
                                    <th>Jumbo Roll QTY</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                    <th>Weight(K.G.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let jumboData of jumboTableData">
                                    <tr>
                                        <td>{{ jumboData.JumboNo }}</td>
                                        <td>{{ jumboData.JumboRollDate }}</td>
                                        <td>{{ jumboData.JumboRollStartTime | date: 'hh:mm':'+0530' }}</td>
                                        <td>{{ jumboData.JumboRollEndTime | date: 'hh:mm':'+0530' }}</td>
                                        <td>{{ jumboData.JumboRolQty }}</td>
                                        <td>{{ jumboData.Rate }}</td>
                                        <td>{{ jumboData.Amount }}</td>
                                        <td>{{ jumboData.Weight }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </nz-table>
                        <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px;">
                            <div nz-col [nzSpan]="33">
                                <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="2"
                                    style="border: 2px dotted rgb(181, 181, 195); margin-bottom: 16px;margin-top: 8px;">
                                    <nz-descriptions-item nzTitle="Total Jumbo Roll QTY" nzColon="true">
                                        <b>{{TotalJumboQty}}</b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Total Jumbo Roll Weight " [nzSpan]="1"
                                        nzColon="true">
                                        <b>{{TotalWeight}}</b>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                            </div>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(7) ? 'Consumption Details - Pending' : 'Consumption Details'"
                    [nzActive]="false" (nzActiveChange)="onConsumptionFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(7)">
                    <div *ngIf="this.NoConsumptionMsg == true"
                        style="text-align: left; margin-top: 10px; font-weight: bold;">
                        <label>No product has been consumed for this order till now.</label>
                    </div>
                    <div *ngIf="this.NoConsumptionMsg == false">
                        <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1200px',y:'515px' }"
                            style="width: 100%;" #basicTable [nzData]="[{}]" [nzLoading]="isConsumptionTableLoading"
                            nzBordered>
                            <thead>
                                <tr>
                                    <th nzWidth="70px">S.No.</th>
                                    <th nzWidth="80px">Type</th>
                                    <th nzWidth="110px">Material Category</th>
                                    <th nzWidth="150px" nzLeft>Name</th>
                                    <th nzWidth="150px">Batch </th>
                                    <th nzWidth="150px">Store</th>
                                    <th nzWidth="150px">Rack</th>
                                    <th nzWidth="120px">Consumed QTY</th>
                                    <th nzWidth="120px">Consumed SC QTY</th>
                                    <th nzWidth="90px">Damaged</th>
                                    <th nzWidth="100px">Consumed By</th>
                                    <th nzWidth="120px">Consumed Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of this.ConsumedStockList">
                                    <td>{{data.SerialNo}}</td>
                                    <td>{{ data.ProductType }}</td>
                                    <td>{{ data.MaterialCategory }}</td>
                                    <td nzLeft>{{ data.ProductName }}</td>
                                    <td>{{ data.Batch }}</td>
                                    <td>{{ data.StoreName }}</td>
                                    <td>{{ data.RackName }}</td>
                                    <td>{{data.ConsumedQty}} {{data.Unit}}</td>
                                    <td>{{data.ConsumedScQty}} {{data.Unit}}</td>
                                    <td>{{data.Damaged}}</td>
                                    <td>{{data.ConsumedBy}}</td>
                                    <td>{{data.ConsumedDate | DatetimeConverter}}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider *ngIf="postProcessArray.length > 0"></nz-divider>
            <nz-collapse *ngIf="postProcessArray.length > 0">
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(8) ? 'Post Process Details - Pending' : 'Post Process Details'"
                    [nzActive]="false" (nzActiveChange)="onPostProcessFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(8)">
                    <div *ngIf="jumboTableData.length > 0">
                        <nz-divider>Post Process Details</nz-divider>
                        <nz-table nzSize="small" style="width: 100%;" [nzData]="this.jumboTableData" #basicTable1
                            nzBordered [nzShowPagination]="false">
                            <thead>
                                <tr>
                                    <th>Jumbo No.</th>
                                    <th>Received QTY</th>
                                    <th>Complete QTY</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of jumboTableData;let i=index">
                                    <td>{{ data.JumboNo }}</td>
                                    <td *ngIf="data.ActualQuantity==null">{{ data.JumboRolQty }}</td>
                                    <td *ngIf="data.ActualQuantity!=null">{{ data.ActualQuantity }}</td>
                                    <td>
                                        <input [disabled]=true type="text" style="color: #000;" readonly nz-input
                                            [(ngModel)]="data.Yield" />
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <nz-descriptions nzBordered>
                            <nz-descriptions-item nzTitle="Complete QTY">
                                <input [disabled]=true type="text" readonly nz-input
                                    [(ngModel)]="RequestModel.CompletedQuantity" />
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Measurement Unit">
                                {{RequestModel.Unit}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Wastage QTY">
                                {{RequestModel.WastageQuantity}}
                            </nz-descriptions-item>
                        </nz-descriptions>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(24) ? 'Final Inspection(Grading) - Pending' : 'Final Inspection(Grading)'"
                    [nzActive]="false" (nzActiveChange)="onGradingFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(24)">
                    <nz-divider nzText="Grading"></nz-divider>
                    <div class="row gx-10 mb-6">
                        <nz-table *ngIf="NewWorkPlan.JumboInspection" nzSize="small" style="width: 100%;" #basicTable1
                            nzBordered [nzData]="[{}]" nzShowPagination="false">
                            <thead>
                                <tr>
                                    <th nzWidth="5%">S.No</th>
                                    <th nzWidth="10%">Code</th>
                                    <th nzWidth="6%">Grade</th>
                                    <th nzWidth="6%"> Quantity</th>
                                    <th nzWidth="5%"> Unit</th>
                                    <th nzWidth="8%"> **Weight</th>
                                    <th nzWidth="9%"> Inspected By</th>
                                    <th nzWidth="12%"> Store</th>
                                    <th nzWidth="12%"> Rack</th>
                                    <th nzWidth="8%"> Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of NewWorkPlan.JumboInspection;let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{data.Code}}</td>
                                    <td>{{data.Grade}}</td>
                                    <td>{{data.Quantity}}</td>
                                    <td>{{data.Unit}}</td>
                                    <td>{{data.Weight}}</td>
                                    <td>{{data.InspectedBy}}</td>
                                    <td>{{data.StoreName}}</td>
                                    <td>{{data.RackName}}</td>
                                    <td>{{data.RollType}}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngIf="this.NewWorkPlan.JumboInspection.length>0" style="font-weight: bold;">
                                    <td></td>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{{totalRollSize}}</td>
                                    <td></td>
                                    <td>{{totalRollWeight}}</td>
                                    <td colspan="4"></td>
                                    <td></td>

                                </tr>
                            </tbody>
                        </nz-table>
                        <br>
                        <br>
                        <nz-divider></nz-divider>
                        <div>
                            <b><label>Remaining QTY : </label></b> &nbsp;
                            <input type="text" style="width: 170px;margin-right: 8px;" readonly nz-input
                                [(ngModel)]="this.RemainingQty" />
                        </div>
                        <br>
                        <div>
                            <b><label style="margin-right:41px;">Yield(%) :</label></b> &nbsp;
                            <input type="text" style="width: 170px;margin-right: 8px;" readonly nz-input
                                [(ngModel)]="NewWorkPlan.Yield" />
                        </div>
                        <nz-divider></nz-divider>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <nz-divider></nz-divider>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="!GetTimeLineStatus(30) ? 'Packaging Dispatch - Pending' : 'Packaging Dispatch'"
                    [nzActive]="false" (nzActiveChange)="onPackagingFilterPanelOpen($event)"
                    [nzDisabled]="!GetTimeLineStatus(30)">
                    <div *ngIf="this.JumboInspection.length>0">
                        <nz-divider nzText="Packaging Dispatch"></nz-divider>
                        <h4 style="margin: 15px 0;">Jumbo Inspection - {{this.JumboInspection.length}} Records Found
                        </h4>
                        <nz-table nzSize="small" *ngIf="this.JumboInspection.length>0" [nzPageSize]="20"
                            style="width: 80%;" #basicTable nzShowPagination="true" [nzData]="this.JumboInspection"
                            nzBordered nzShowSizeChanger>
                            <thead>
                                <tr>
                                    <th nzWidth="40px"></th>
                                    <th nzWidth="120px">Sale Order Code</th>
                                    <th nzWidth="120px">Code
                                        <span nz-icon nzType="caret-up" nzTheme="outline"
                                            (click)="onUp('Code',basicTable.data)"></span>
                                        <span nz-icon nzType="caret-down" nzTheme="outline"
                                            (click)="onDown('Code',basicTable.data)"></span>
                                    </th>
                                    <th nzWidth="60px">Grade
                                        <span nz-icon nzType="caret-up" nzTheme="outline"
                                            (click)="onUp('Grade',basicTable.data)"></span>
                                        <span nz-icon nzType="caret-down" nzTheme="outline"
                                            (click)="onDown('Grade',basicTable.data)"></span>
                                    </th>
                                    <th nzWidth="60px">Quantity</th>
                                    <th nzWidth="60px">Weight</th>
                                    <th nzWidth="90px">Type
                                        <span nz-icon nzType="caret-up" nzTheme="outline"
                                            (click)="onUp('RollType',basicTable.data)"></span>
                                        <span nz-icon nzType="caret-down" nzTheme="outline"
                                            (click)="onDown('RollType',basicTable.data)"></span>
                                    </th>
                                    <th nzWidth="90px">Added By</th>
                                    <th nzWidth="90px">Inspected Date</th>
                                    <th nzWidth="50px" nzRight style="text-align: center">Sales Order</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of basicTable.data;let i = index">
                                    <td>{{ data.SerialNo }}</td>
                                    <td>{{ data.SaleOrderCode}}</td>
                                    <td title='Created on {{data.AddedDate | DatetimeConverter }}'>{{ data.Code}}
                                    </td>
                                    <td>{{ data.Grade}}</td>
                                    <td>{{ data.Quantity}} {{data.Unit}}</td>
                                    <td>{{ data.Weight}}</td>
                                    <td>{{ data.RollType}}</td>
                                    <td>{{ data.AddedBy}}</td>
                                    <td>{{ data.AddedDate | DatetimeConverter}}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
        </div>
    </div>
</div>
<app-Timeline></app-Timeline>