import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import {
  StockProductAllocationModel,
  StockProductModel,
} from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { IUploadProgress, StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzUploadFile, NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { combineAll, from, map, Observable } from 'rxjs';
import { ISasToken } from 'src/PmsUIApp/azure-storage/azureStorage';
import { BlobStorageService } from 'src/PmsUIApp/azure-storage/blob-storage.service';
import { PurchaseOrderModel } from 'src/PmsUIApp/Models/PurchaseOrderModel';
import {
  ProductSecSubCategoryModel,
  ProductFirstSubCategoryModel,
  ProductCategoryModel,
  RackModel,
  StoreModel,
} from '../../Models/MasterModel';
import { MainservicesService } from 'src/PmsUIApp/mainservices.service';
import { UserInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  templateUrl: './OpeningStock.component.html',
  styleUrls: ['./OpeningStock.component.css'],
})
export class OpeningStockComponent {
  ApiUrl = environment.Api_Url;
  isValid = false;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];

  NewStockProduct: StockProductModel = new StockProductModel();
  IsDisabled = false;
  FilteredProductList: ProductModel[] = [];
  StockProductList: StockProductModel[] = [];
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel();
  NewInvoice: InvoiceModel = new InvoiceModel();
  InvoiceList: InvoiceModel[] = [];
  InvoiceListOriginal: InvoiceModel[] = [];
  isLoading: boolean = false;
  size: NzButtonSize = 'large';
  uploadProgress$: Observable<IUploadProgress[]> | undefined;
  filesSelected = false;
  uploadinvoicefile: any;
  uploadinvoice: any;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  FilteredPurchaseOrderList: PurchaseOrderModel[] = [];
  isVisible = false;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];

  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  InvoiceListWithoutPO: InvoiceModel[] = [];
  FilteredInvoiceListWithoutPO: InvoiceModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  IsPoGet = false;
  token: any;
  startValue: Date | null = null;
  endValue: Date | null = null;
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  isTableLoading: boolean = false;
  StoreID: any;
  RackID: number = 0;
  StockId: any;
  StockProductId: any;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private blobStorage: BlobStorageService,
    private route: ActivatedRoute,
    private mainService: MainservicesService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router
  ) { }

  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }

  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == this.NewStockProduct.ProductId
    )[0];

    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    this.NewStockProduct.Unit = fp.Unit;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }

  fetchProductData(id: any) {
    this.isLoading = true;

    this.mainService.getStockProductData(id).subscribe((res: any) => {
      console.log(`fetchData==++++++`, res);

      this.FilteredProductList = res.StockProduct.filter((el: any) => {
        return el.StockId == id;
      });
      this.IsDisabled = true;
      console.log(`this.FilteredProductList`, this.FilteredProductList);
      this.SelectedProductType = this.FilteredProductList[0].ProductType;

      this.NewStockProduct.Unit = this.FilteredProductList[0].Unit;
      this.NewStockProduct.SupplierId = res.Invoice.SupplierId;

      this.StoreID =
        this.FilteredProductList[0].StockProductAllocation[0]['StoreId'] || '';

      this.NewStockProduct.Barcode =
        this.FilteredProductList[0]['Barcode'] || '';

      this.NewStockProduct.StockDate = res.StockDate;
      this.isValid = true;
      this.NewStockProduct.ManufacturedDate =
        this.FilteredProductList[0]['ManufacturedDate'] || '';

      this.NewStockProduct.ExpiryDate =
        this.FilteredProductList[0]['ExpiryDate'] || '';

      this.NewStockProduct.Sku = this.FilteredProductList[0]['Sku'] || '';

      this.NewStockProduct.Quantity =
        this.FilteredProductList[0]['Quantity'] || 0;
      this.NewStockProduct.PricePerUnit =
        this.FilteredProductList[0]['PricePerUnit'] || 0;
      // this.StoreID =this.FilteredProductList[0]["StoreId"]||0
      let url = this.ApiUrl + 'rack/getallracks';
      this.http.get<RackModel[]>(url).subscribe(
        (res) => {
          this.RackList = res.filter((x) => x.StoreId == this.StoreID);
          this.RackID =
            this.FilteredProductList[0].StockProductAllocation[0]['RackId'] || 0;
        },
        () => { }
      );

      this.CategoryID = this.FilteredProductList[0].ProductCategoryId;
      this.FirstCategoryID =
        this.FilteredProductList[0].ProductFirstSubCategoryId;
      this.SecondCategoryID =
        this.FilteredProductList[0].ProductSecSubCategoryId;
      this.GetAllFirstCategory(this.CategoryID, this.FirstCategoryID);
      this.GetAllSecondCategory(this.FirstCategoryID, this.SecondCategoryID);
      this.NewStockProduct.ProductId = this.FilteredProductList[0].ProductId;
      this.NewStockProduct.Grade = this.FilteredProductList[0]['Grade'] || '';
      this.StockProductId = res.StockProduct[0].StockProductId;
    });
  }

  GetAllUnits() {
    let url = this.ApiUrl + 'data/GetMeasureUnits';
    this.http.get<MeasureUnitModel[]>(url).subscribe(
      (res) => {
        this.MeasureUnits = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllUnits();
        }
      }
    );
  }

  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'rack/getallracks';
    this.http.get<RackModel[]>(url).subscribe(
      (res) => {
        this.RackList = res;

        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllRack();
        }
      }
    );
  }

  onSelectedStoreChange() {
    let url = this.ApiUrl + 'rack/getallracks';
    this.http.get<RackModel[]>(url).subscribe(
      (res) => {
        this.RackList = res.filter((x) => x.StoreId == this.StoreID);
        this.RackID = 0;
      },
      (res) => { }
    );
  }

  GetAllStore() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }

  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductWiseStock() {
    let url = this.ApiUrl + 'stock/getproductwisestock';
    this.http.get<StockProductModel[]>(url).subscribe(
      (res) => {
        this.StockProductList = res.sort((a, b) => {
          return a.AcceptedQuantity - b.AcceptedQuantity;
        });
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProductWiseStock();
        }
      }
    );
  }

  AddNewProductRecord() {
    if (!this.ValidateProduct(this.NewStockProduct)) {
      return;
    }

    if (this.selectedProduct == 0) {
      this.NewStockProduct.ProductType = this.SelectedProductType;
      this.NewStockProduct.ProductName = this.ProductList.filter(
        (x) => x.ProductId == this.NewStockProduct.ProductId
      )[0].ProductName;
      this.NewStockProduct.StockProductId =
        this.NewStock.StockProduct.length + 1;
      this.NewStock.StockProduct.push(this.NewStockProduct);
    } else {
      var index = this.NewStock.StockProduct.findIndex(
        (x) => x.StockProductId == this.selectedProduct
      );
      var selectedpro = this.NewStock.StockProduct[index];
      //selectedpro = this.NewStockProduct;

      selectedpro.ProductType = this.SelectedProductType;
      selectedpro.ProductName = this.ProductList.filter(
        (x) => x.ProductId == this.NewStockProduct.ProductId
      )[0].ProductName;
      selectedpro.ExpiryDate = this.NewStockProduct.ExpiryDate;
      selectedpro.ManufacturedDate = this.NewStockProduct.ManufacturedDate;
      selectedpro.Unit = this.NewStockProduct.Unit;
      selectedpro.Quantity = this.NewStockProduct.Quantity;
      selectedpro.PricePerUnit = this.NewStockProduct.PricePerUnit;

      selectedpro.Grade = this.NewStockProduct.Grade;
      selectedpro.Sku = this.NewStockProduct.Sku;
      selectedpro.ManufacturedDate = this.NewStockProduct.ManufacturedDate;
      selectedpro.ExpiryDate = this.NewStockProduct.ExpiryDate;
      selectedpro.Barcode = this.NewStockProduct.Barcode;
      this.selectedProduct = 0;
    }
    this.NewStockProduct = new StockProductModel();
    this.SaveStock();
  }

  RemoveStockProduct(item: StockProductModel) {
    var index = this.NewStock.StockProduct.indexOf(item);
    if (index !== -1) {
      this.NewStock.StockProduct.splice(index, 1);
    }
  }

  selectMeausreUnit(unit: string) {
    this.NewStockProduct.Unit = unit;
  }
  GetAllSupplier() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllSupplier()
      }
    });
  }
  handleSave() {

    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to save this stock?',
      nzOnOk: () => this.SaveStock(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  SaveStock() {
    this.NewStockProduct.RackId = this.RackID;
    this.NewStockProduct.StoreId = this.StoreID;
    this.NewStockProduct.AddedBy = UserInfo.EmailID;
    this.NewStockProduct.StockId = (this.StockId == null ? 0 : this.StockId);
    this.NewStockProduct.StockProductId = (this.StockProductId == null ? 0 : this.StockProductId);;
    if (this.ValidateProduct(this.NewStockProduct)) {
      let url = this.ApiUrl + 'stock/addstockmaster';
      this.isLoading = true;
      this.NewStockProduct.RackId = this.RackID;
      this.http.post<StockProductModel>(url, this.NewStockProduct).subscribe({
        next: (res) => {
          this.alertService.success('Stock added Successfully');
          this.isLoading = false;
          this.NewStockProduct = new StockProductModel();
          this.NewStock = new StockModel();
          this.NewStock.Invoice = new InvoiceModel();
          this.uploadinvoice = null;
          this.StoreID = this.RackID = 0;
        },
        error: (res) => {
          this.alertService.error(
            'An error has been occured. Please try again'
          );
          this.isLoading = false;
        },
      });
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.OpeningStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.OpeningStock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.OpeningStock, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.StockId = this.route.snapshot.paramMap.get('id');
    if (this.StockId) {
      this.fetchProductData(this.StockId);
      console.log(`this.StockId`, this.StockId);
    }
    if (!this.StockId) {
      this.NewStockProduct = new StockProductModel();
    }
    this.GetAllUnits();
    // this.GetAllRack();
    this.GetAllProducts();
    this.GetAllProductWiseStock();
    //this.GetAllInvoices();
    this.GetAllProductCategory();
    //this.GetAllPurchaseOrder();

    this.NewStock = new StockModel();
    this.NewStock.Invoice = new InvoiceModel();
    this.NewStock.Invoice.InvoiceDate =
      this.NewStock.Invoice.EwayBillDate =
      this.NewStock.StockDate =
      new Date().toUTCString();
    //this.postInvoiceToken();
    this.GetAllStore();
    this.GetAllSupplier();
  }

  ValidateProduct(model: StockProductModel) {
    var Isvalid: boolean = true;
    if (this.SelectedProductType == '') {
      this.alertService.error('Select ProductType');
      Isvalid = false;
    } else if (model.ProductId <= 0) {
      this.alertService.error('Select Product');
      Isvalid = false;
    } else if (model.Unit.toString() == '') {
      this.alertService.error('Select Unit');
      Isvalid = false;
    } else if (model.SupplierId == 0) {
      this.alertService.error('Select Supplier');
      Isvalid = false;
    } else if (model.StockDate == '') {
      this.alertService.error('Select Stock Date');
      Isvalid = false;
    } else if (model.Quantity <= 0) {
      this.alertService.error('Enter Quantity');
      Isvalid = false;
    } else if (model.PricePerUnit <= 0) {
      this.alertService.error('Enter Per Unit Price');
      Isvalid = false;
    } else if (model.RackId <= 0) {
      this.alertService.error('Select Rack ');
      Isvalid = false;
    } else if (model.StoreId <= 0) {
      this.alertService.error('Select Store ');
      Isvalid = false;
    }
    return Isvalid;
  }

  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
        console.log(`catagory data here+++++`, res);
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProductCategory();
        }
      }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    //this.FilteredProductList = this.ProductList.filter(
    //  (x) =>
    //    x.ProductType == this.SelectedProductType &&
    //    x.ProductCategoryId == this.CategoryID
    //);

    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );
      },
      (res) => { }
    );
  }

  GetAllSecondCategory(data: any, id: number = 0) {
    //this.FilteredProductList = this.ProductList.filter(
    //  (x) =>
    //    x.ProductType == this.SelectedProductType &&
    //    x.ProductFirstSubCategoryId == this.FirstCategoryID
    //);

    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  EditProduct(data: any) {
    this.NewStockProduct.ProductType = this.SelectedProductType =
      data.ProductType;
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == data.ProductType
    );
    setTimeout(() => {
      this.NewStockProduct.ProductId = data.ProductId;
      this.onSelectedProductChange(data.ProductId);
      this.NewStockProduct.Quantity = data.Quantity;
      this.NewStockProduct.PricePerUnit = data.PricePerUnit;
      this.NewStockProduct.Unit = data.Unit;
      this.NewStockProduct.Grade = data.Grade;
      this.NewStockProduct.Sku = data.Sku;
      this.NewStockProduct.ManufacturedDate = data.ManufacturedDate;
      this.NewStockProduct.ExpiryDate = data.ExpiryDate;
      this.NewStockProduct.Barcode = data.Barcode;
      this.selectedProduct = data.StockProductId;
    }, 0);

    this.isVisible = true;
  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.NewStockProduct.ExpiryDate) {
      return false;
    }
    return (
      //startValue.getTime() > new Date(this.NewStockProduct.ExpiryDate).getTime() ||
      startValue.getTime() < new Date().getTime()
    );
  };
  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.NewStockProduct.ManufacturedDate) {
      return false;
    }
    return (
      endValue.getTime() <=
      new Date(this.NewStockProduct.ManufacturedDate).getTime()
    );
  };
}
