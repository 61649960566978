import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { WorkPlanPrintModel, WorkPlanMasterModel, WorkPlanOrderModel, WorkPlanJumboListMasterModel, WorkPlanJumboMasterModel, JumboInspectionModel } from 'src/PmsUIApp/Models/WorkPlanModel';
import { LoadingService } from '../../../Services/loadingService';
import { SaleFormulationCodeMasterModel, SaleOrderModel, SaleOrderProductionMixingModel, SaleOrderProductionModel, SaleOrderProductionRawMaterialModel } from '../../../Models/SalesOrderModel';
import { AdminStoreModel, ColorModel, GrainModel, StoreModel } from '../../../Models/MasterModel';

import * as moment from 'moment';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { formatDate } from '@angular/common';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';

@Component({
  selector: 'app-jumbolist-list',
  templateUrl: './jumbolist.component.html'

})
export class JumboListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanJumboListMasterModel[] = [];
  WorkPlanListCopy: WorkPlanJumboListMasterModel[] = [];
  WorkPlanListOriginal: WorkPlanMasterModel[] = [];
  PurchaseOrder!: WorkPlanMasterModel;
  WorkPlanModel!: WorkPlanMasterModel;
  isVisible = false;
  isVisibleInspection = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  searchValue = '';
  visible = false;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  AdminStoreList: AdminStoreModel[] = [];
  StoreListOriginal: StoreModel[] = [];
  StoreList: StoreModel[] = [];
  SelectedOrderNo: string = '';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  exportoptions = {
    headers: [
      "Jumbo Roll No",
      "Jumbo Roll Qty",
      "Work Plan No.",
      "Sale Order No.",
      "Formulation Code",
      "Buyer",
      "Jumbo Roll Date",
      "Jumbo Roll Start Time",
      "Jumbo Roll End Time",
      "Jumbo Roll Line Speed",
      "Weight",
      "Added Date",
      "AddedBy"
    ],
  };
  fields: any
  exportfields: WorkPlanJumboListMasterModel[] = [];
  Orderlist!: SaleOrderModel;
  isValidDate: any;
  FabricName: string = '';
  FabricQTY!: number;
  FromStoreID: number = 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  InspectionData!: WorkPlanJumboMasterModel;
  FabricColorName: string;
  request = {
    FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 3)),
    ToDate: new Date(new Date().setHours(23, 59, 59)),
    IsInspectionCompleted: "false",
    SaleFormulationCodeId: 0,
    SaleOrderNumber: "",
    GrainId: 0,
    ColorId: 0,
    CustomerId: 0,
    JumboNumber: ""
  }
  CustomerList: CustomerModel[];
  count: number = 0;
  FormulationCodeList: FormulationCodeModel[];
  ColorList: ColorModel[];
  GrainList: GrainModel[];
  totalItemsCount: number = 0;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loader: LoadingService, private auth: AuthService, private router: Router,
    private soDrawer: SoDrawerService

  ) { }

  expandSet = new Set<number>();
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.GetAllWorkPlanList();

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }




  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {
    var res = this.WorkPlanListCopy;
    var res2 = this.WorkPlanListCopy
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: WorkPlanJumboListMasterModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.WorkPlanList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.WorkPlanList = res.filter(
        (item: WorkPlanJumboListMasterModel) =>
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.CustomerName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.JumboNo == this.searchValue ||
          item.JumboRolQty == +this.searchValue ||
          item.Weight == +this.searchValue ||
          item.SaleOrderNumber == this.searchValue
      )
    }

    this.exportfields = [];
    this.WorkPlanList.forEach((x) => {
      this.fields = {}
      this.fields.JumboNo = x.JumboNo
      this.fields.JumboRolQty = x.JumboRolQty
      this.fields.WorkPlanNo = x.WorkPlanNo
      this.fields.SaleOrderNumber = x.SaleOrderNumber
      this.fields.SaleFormulationCodeName = x.SaleFormulationCodeName
      this.fields.CustomerName = x.CustomerName
      this.fields.JumboRollDate = x.JumboRollDate;
      this.fields.JumboRollStartTime = x.JumboRollStartTime != null ? this.convertToIST(x.JumboRollStartTime.toString()) : ''
      this.fields.JumboRollEndTime = x.JumboRollEndTime != null ? this.convertToIST(x.JumboRollEndTime.toString()) : ''
      this.fields.JumboRollPrdSpeed = x.JumboRollPrdSpeed + ' MTRs/Minute'
      this.fields.Weight = x.Weight
      this.fields.AddedDate = new DatetimeConverter().transform(x.AddedDate)
      this.fields.AddedBy = x.AddedBy
      this.exportfields.push(this.fields);
    })
  }
  //   search(){
  //     let  res=this.WorkPlanListCopy; 
  //     this.WorkPlanList = res.filter((item:WorkPlanJumboListMasterModel)=>{
  //      if(item.JumboNo==this.searchValue){
  //       return true;
  //      } 
  //      else if(item.JumboRolQty==+this.searchValue){
  //       return true;
  //      }
  //      else if(item.SaleOrderNumber.toLowerCase().includes(this.searchValue.toLowerCase())){
  //       return true;
  //      }
  //      else if(item.CustomerName.toLowerCase().includes(this.searchValue.toLowerCase())){
  //       return true;
  //      }
  //      else if(item.JumboRolQty==+this.searchValue){
  //       return true;
  //      }
  //      else if(item.Weight==+this.searchValue){
  //       return true;
  //      }
  // return false
  //     })
  //   }

  GetAllWorkPlanList() {
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + 'workplan/getallworkplanjumbowithfilters';
    this.http.post<any>(url, this.request).subscribe({
      next: res => {
        this.WorkPlanList = res.Data;
        count = this.totalItemsCount;
        this.WorkPlanList.forEach(element => {
          count++;
          element.SerialNo = count;
          if (element.JumboRollStartTime != null && element.JumboRollEndTime != null) {
            element.JumboRollDate = formatDate(element.JumboRollDate.toString(), 'dd-MM-yyyy', "en-US", 'IST');
            element.JumboRollStartTime = new Date(new DatetimeConverter().transform(element.JumboRollStartTime.toString()));
            element.JumboRollEndTime = new Date(new DatetimeConverter().transform(element.JumboRollEndTime.toString()));
          }
        });
        this.totalItemsCount = count;
        this.WorkPlanListCopy = res.Data;
        this.isTableLoading = false;
        this.exportfields = [];
        this.WorkPlanListCopy.forEach((x) => {
          this.fields = {}
          this.fields.JumboNo = x.JumboNo
          this.fields.JumboRolQty = x.JumboRolQty
          this.fields.WorkPlanNo = x.WorkPlanNo
          this.fields.SaleOrderNumber = x.SaleOrderNumber
          this.fields.SaleFormulationCodeName = x.SaleFormulationCodeName
          this.fields.CustomerName = x.CustomerName
          this.fields.JumboRollDate = x.JumboRollDate;
          this.fields.JumboRollStartTime = x.JumboRollStartTime != null ? this.convertToIST(x.JumboRollStartTime.toString()) : ''
          this.fields.JumboRollEndTime = x.JumboRollEndTime != null ? this.convertToIST(x.JumboRollEndTime.toString()) : ''
          this.fields.JumboRollPrdSpeed = x.JumboRollPrdSpeed + ' / MTRs'
          this.fields.Weight = x.Weight
          this.fields.AddedDate = new DatetimeConverter().transform(x.AddedDate)
          this.fields.AddedBy = x.AddedBy
          this.exportfields.push(this.fields);
        })
      },
      error: res => { this.isTableLoading = false; },
    });
  }
  convertToIST(utcTimestamp: string): string {
    const utcDate = new Date(utcTimestamp);
    const istOffset = 330; // IST is UTC+5:30
    const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000);

    let hours = istDate.getUTCHours();
    const minutes = istDate.getUTCMinutes().toString().padStart(2, '0');
    let meridiem = 'AM';

    if (hours >= 12) {
      meridiem = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    }

    hours = hours === 0 ? 12 : hours;

    return `${hours}:${minutes} ${meridiem}`;
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'Jumbo-export' + exportdate,
        this.exportoptions
      );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.myDateValue = undefined;
    this.toDate = undefined;
    // this.GetAllWorkPlanList()
  }
  //dummy functions
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  print(data: any) {
    window.open(`${window.location.origin}/jumboprint/` + data.SaleOrderId + '/' + data.WorkPlanJumboMasterId);
  }
  inspectionprint(data: any, WorkPlanOrdersId: number) {
    window.open(`${window.location.origin}/inspectionprint/` + WorkPlanOrdersId + '/' + data.WorkPlanJumboMasterId + '/' + data.JumboInspectionId);
  }
  inspectionprintAll(data: any, WorkPlanOrdersId: number) {
    window.open(`${window.location.origin}/inspectionallprint/` + WorkPlanOrdersId + '/' + data.WorkPlanJumboMasterId);
  }

  OpenInspection(data: WorkPlanJumboMasterModel) {
    if (data.IsLiningOrder == true) {
      this.alertService.error("Don't be oversmart. It is hidden for a reason");
      return
    }
    else {
      this.InspectionData = data;
      this.isVisibleInspection = true;
    }
  }
  handleCancelInspection() {
    this.isVisibleInspection = false;
  }
  OpenViewPop(data: any) {
    this.soDrawer.SaleOrderId = data.SaleOrderId;
    this.soDrawer.show();
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  GetAllCustomer() {
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllCustomer()
      }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllFormulationCode()
      }
    });
  }
  GetAllColor() {
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllColor()
      }
    });
  }
  GetAllGrain() {
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllGrain()
      }
    });
  }
  onFilterPanelOpen(data: any) {
    console.log(data)
    if (data == true) {
      this.GetAllCustomer();
      this.GetAllFormulationCode();
      this.GetAllColor();
      this.GetAllGrain();
    }
  }
}
