<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Product Stock Summary Report</nz-page-header-title>
      <nz-page-header-subtitle>Manage your product stock summary here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">

          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Status</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                  [(ngModel)]="request.RecordStatus" name="Status" nzAllowClear nzPlaceHolder="">
                  <nz-option nzValue="null" nzLabel="All"></nz-option>
                  <nz-option nzValue="Available" nzLabel="Available"></nz-option>
                  <nz-option nzValue="Dispatched" nzLabel="Dispatched"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                <label>Formulation Code</label>
                <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                  nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                    [nzLabel]="s.SaleFormulationCode"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label>Customer</label>
                <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                  [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                    [nzLabel]="s.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Color</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId" nzShowSearch
                  nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                    [nzLabel]="s.ColorName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Grain Name</label>
                <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default" nzShowSearch
                  nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                    [nzLabel]="s.GrainName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                <label>Article Name</label>
                <input nz-input nzAllowClear placeholder="Enter Alias to Search" maxlength="30"
                  [(ngModel)]="request.ArticleName" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Embossing</label>
                <nz-select #microwaveRef class="form-select" [(ngModel)]="request.EmbossingMasterId" nzAllowClear
                  nzShowSearch nzSize="default" ame="EmbossingMasterId" nzPlaceHolder="Select">
                  <nz-option *ngFor="let s of this.EmbossingList;" [nzValue]="s.EmbossingMasterId"
                    [nzLabel]="s.Name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Print</label>
                <nz-select class="form-select" nzShowSearch [(ngModel)]="request.PrintMasterId" nzAllowClear
                  nzSize="default" name="PrintMasterId" nzPlaceHolder="Select">
                  <nz-option *ngFor="let s of this.PrintMasterList;" [nzValue]="s.PrintMasterId"
                    [nzLabel]="s.Name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Vaccum</label>
                <nz-select #microwaveRef class="form-select " [(ngModel)]="request.VacuumMasterId" nzShowSearch
                  nzSize="default" name="Category" nzAllowClear nzPlaceHolder="Vaccum">
                  <nz-option *ngFor="let s of this.VaccumList;" [nzValue]="s.VacuumMasterId"
                    [nzLabel]="s.Name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetProductStockSummaryReport()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 8px;float:right">
      <button nz-button nzSize="small" (click)="export()"><i style="top: 4px;right: 51px;position: absolute;" nz-icon
          nzType="export" nzTheme="outline"></i>
        Export</button>
      <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
        style="margin-left:5px"><i style="top: 4px;right: 38px;position: absolute;" nz-icon nzType="printer"
          nzTheme="outline"></i>Print</button>
    </div>
    <nz-divider></nz-divider>
    <div style="max-width:100%; overflow:scroll">
      <nz-table id="print-section" nzSize="small" [nzPageSize]="10" style="width: 100%" #basicTable
        [nzData]="this.DataList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger
        [nzShowTotal]="totalTemplate" nzShowQuickJumper="true">
        <thead>
          <tr style="font-size: small;">

            <th nzWidth="50px" style="font-size: x-small;">S. No.</th>
            <th nzWidth="65px">Sale Order No.</th>
            <th nzWidth="150px">Sale Order Code</th>
            <th nzWidth="95px">Grain Code</th>
            <th nzWidth="100px">Color Name</th>
            <th nzWidth="100px">Alias</th>
            <th nzWidth="130px">Customer</th>
            <th nzWidth="60px">Grade</th>
            <th nzWidth="55px">Roll</th>
            <th nzWidth="65px">MTR</th>
            <th nzWidth="100px">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data" style="font-size: small;">

            <td nzWidth="45px">{{ data.SerialNo }}</td>
            <td nzWidth="65px">
              <nz-tag nzColor="blue"><a (click)="OpenSaleOrderViewPop(data.SaleOrderId)">
                  {{data.SaleOrderNumber}}
                </a></nz-tag>
            </td>
            <td nzWidth="150px" nzBreakWord>{{ data.SaleOrderCode }}</td>
            <td nzWidth="100px" title="Name: {{data.GrainName}}" nzBreakWord>{{ data.GrainCode }}</td>
            <td nzWidth="120px" nzBreakWord>{{ data.ColorName }}</td>
            <td nzWidth="120px" nzBreakWord>{{ data.ArticleName }}</td>
            <td nzWidth="160px" nzBreakWord>{{ data.CustomerName }}</td>
            <td nzWidth="80px">{{ data.Grade }}</td>
            <td nzWidth="80px">{{ data.Roll }}</td>
            <td nzWidth="80px">{{ data.Quantity }}</td>
            <td nzWidth="100px">{{ data.SaleOrderStatus == 'DispatchCompleted' ? 'Dispatched' : 'Available' }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>

  </div>
</div>

<nz-drawer [nzClosable]="true" [nzVisible]="isSaleOrderVisible" nzPlacement="right" [nzWidth]="1000"
  nzTitle="Sales Order details" (nzOnClose)="handleSaleOrderCancel()">
  <ng-container *nzDrawerContent>
    <div *ngIf="Orderlist">
      <nz-divider nzText="Sales Order Status"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="2">
        <nz-descriptions-item nzTitle="Current Status" [nzSpan]="1" nzColon="true">
          <nz-badge nzStatus="processing"></nz-badge><b>{{ Orderlist.Status }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="WorkPlan Number" [nzSpan]="2"><b>{{ Orderlist.WorkPlanNumber
            }}</b></nz-descriptions-item>
      </nz-descriptions>
      <nz-divider nzText="Sales Order Details"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="6">
        <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
          <b>{{ Orderlist.SaleOrderType }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="2"><b>{{ Orderlist.CustomerName }}</b></nz-descriptions-item>

        <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1">
          <b>
            {{
            Orderlist.SaleOrderNumber
            }}
          </b>
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Sale Order Date">
          <b>
            {{ Orderlist.SaleOrderDate | date: "dd-MMM-yyyy" }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Delivery Date">
          <b>
            {{ Orderlist.DeliveryDate | date: "dd-MMM-yyyy" }}
          </b>
        </nz-descriptions-item>
      </nz-descriptions>

      <nz-divider nzText="Production Details"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist.SaleOrderProduction" nzSize="small"
        [nzColumn]="4">
        <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true">
          <b>
            {{
            Orderlist.SaleFormulationCode
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ Orderlist.Category }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Thick">
          <b>
            {{
            Orderlist.SaleOrderProduction.ThicknessValue
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Width">
          <b>
            {{
            Orderlist.SaleOrderProduction.WidthNumber
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
          <b>
            {{
            Orderlist.SaleOrderProduction.ManufacturingProductName
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Quantity">
          <b>
            {{
            Orderlist.SaleOrderProduction.OrderQuantity
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Sale Price">
          <b>
            {{
            Orderlist.SaleOrderProduction.SalePrice
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Total Sale Price">
          <b>
            {{
            Orderlist.SaleOrderProduction.TotalSalePrice
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Grain Name">
          <b>
            {{
            Orderlist.SaleOrderProduction.GrainName
            }}
          </b>
          <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
            nzTooltipTitle="Grain Code: {{Orderlist.SaleOrderProduction.GrainCode}}" nzTooltipPlacement="right"
            nz-tooltip nzType="info-circle" nzTheme="outline">
          </span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Color">
          <b>
            {{
            Orderlist.SaleOrderProduction.ColorName
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric Color">
          <b>
            {{
            Orderlist.SaleOrderProduction.FabricColorName
            }}
          </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric Name">
          <b>
            {{
            Orderlist.FormulationFabricProductName
            }}
          </b>
        </nz-descriptions-item>
      </nz-descriptions>

      <!-- process details  -->
      <nz-divider nzText="Post Process"></nz-divider>
      <!-- print  -->
      <div *ngFor="let p of Orderlist.SaleOrderPostProcessOrder"
        style="text-align: center;padding: 5px;border: 1px solid #ccc;border-radius: 4px;margin-top: 10px;background: #fff;box-shadow: 1px 1px 2px 2px #415994;          ">
        <div style="padding: 10px 0; text-transform: uppercase">{{p.PostProcessName}}</div>
        <nz-table *ngIf="p.PostProcessName=='Print'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionPrint;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Removed by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Embossing'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionEmbossing;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Vaccum'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
              <!--<th nzWidth="100px">Price</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionVacuum;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
              <!--<td>{{ data.Price }}</td>-->
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Tumbling'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
              <!--<th nzWidth="100px">Price</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionTumbling;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
              <!--<td>{{ data.Price }}</td>-->
            </tr>
          </tbody>
        </nz-table>
        <nz-table *ngIf="p.PostProcessName=='Lacquar'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
          nzShowPagination="false">
          <thead>
            <tr>
              <th nzWidth="50px">S.No</th>
              <th>Name</th>
              <th nzWidth="160px">Code</th>
              <th nzWidth="40px">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction?.Lacquer;
                let i = index
              ">
              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{ data.Name }}</td>
              <td>{{ data.Code }}</td>
              <td style="text-align:center">
                <div *ngIf="data.Removed == true">
                  <div class="badge badge-light-danger">InActive</div>
                  <span nz-icon
                    nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                  </span>
                </div>
                <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>

    </div>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
      <div nz-col [nzSpan]="24">


        <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
          <div nz-col [nzSpan]="24">
            <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="5" style="box-shadow: 1px 1px 2px 2px #415994">
              <nz-descriptions-item nzTitle="Pre Skin" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.PreSkinGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Skin" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.SkinGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Foam" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.FoamGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Adhesive" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.AdhesiveGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                <b>
                  {{ Orderlist.SaleOrderProduction.FabricGsm }}
                </b>
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Remarks" nzColon="true">
                <b>
                  {{
                  Orderlist.Remarks
                  }}
                </b>
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>