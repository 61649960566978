<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Post Process Report</nz-page-header-title>
        </nz-page-header>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Date</label>
                                <nz-date-picker [(ngModel)]="RequestDate" nzPlaceHolder="Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>


                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="PostProcessReport()">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div style="padding: 8px;float:right">
            <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"
                    style="top: 2px;right: 48px;position: absolute;"></i> Export</button>
            <button nz-button nzSize="small" printSectionId="print-section" [useExistingCss]="true" ngxPrint style="margin-left:5px"><i
                    style="top: 2px;right: 37px;position: absolute;" nz-icon nzType="printer"
                    nzTheme="outline"></i>Print</button>
        </div>
        <nz-divider></nz-divider>

        <div style="max-width:100%; overflow:scroll" >
            <nz-table id="print-section" nzSize="small" nzShowPagination="false"
                      #basicTable
                      [nzData]="this.PostProcessList"
                      [nzLoading]="isTableLoading"
                      
                      style="width: 100%"
                       
                      nzBordered>
                <thead>

                    <tr style="text-align: left;">
                        <th nzWidth="40px">S. No.</th>
                        <th nzWidth="80px">Date</th>
                        <th nzWidth="90px">Sale Order No.</th>
                        <th nzWidth="110px">Party Name</th>
                        <th nzWidth="120px">Code</th>
                        <th nzWidth="60px">Grain</th>
                        <th nzWidth="80px">Color</th>
                        <th nzWidth="120px">Finish</th>
                        <th nzWidth="70px">QTY</th>
                        <th nzWidth="100px">Printing
                            <span>(Total : {{this.printtotal}})</span>
                        </th>
                        <th nzWidth="100px">Steel Emboss
                            <span>(Total : {{this.embosstotal}})</span>
                        </th>
                        <th nzWidth="100px">Vacuum Emboss
                            <span>(Total : {{this.vacuumtotal}})</span>
                        </th>
                        <th nzWidth="110px">Remarks</th>

                    </tr>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of this.PostProcessList; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>
                            <span *ngIf ="(data.SaleOrderStatus == 'PrintAssigned' || data.SaleOrderStatus == 'PrintInProcess')">{{ data.PrintAcceptedDate.toString()| DatetimeConverter }}</span>
                            <span *ngIf ="(data.SaleOrderStatus == 'EmbossingAssigned' || data.SaleOrderStatus == 'EmbossingInProcess')">{{ data.EmbossAcceptedDate.toString()| DatetimeConverter }}</span>
                            <span *ngIf ="(data.SaleOrderStatus == 'VacuumAssigned' || data.SaleOrderStatus == 'VacuumInProcess')">{{ data.VaccumAcceptedDate.toString()| DatetimeConverter }}</span>
                        </td>
                        <td nzBreakWord>{{ data.SaleOrderNumber }}</td>
                        <td nzBreakWord>{{ data.CustomerName }}</td>
                        <td nzBreakWord>{{ data.SaleOrderCode }}</td>
                        <td nzBreakWord>{{ data.GrainName }}</td>
                        <td nzBreakWord>{{ data.ColorName}}</td>
                        <td nzBreakWord>{{ data.FinishCode}}</td>
                        <td>{{ data.OrderQuantity}}</td>
                        <td>{{ (data.SaleOrderStatus == "PrintAssigned" || data.SaleOrderStatus == "PrintInProcess") ? data.PrintQuantity : ""}}</td>
                        <td>{{ (data.SaleOrderStatus == "EmbossingAssigned" || data.SaleOrderStatus == "EmbossingInProcess") ? data.EmbossQuantity : ""}}</td>
                        <td>{{ (data.SaleOrderStatus == "VacuumAssigned" || data.SaleOrderStatus == "VacuumInProcess") ? data.VacuumQuantity : ""}}</td>
                        <!-- <td nzBreakWord>{{ data.Remarks }}</td> -->
                        <td nzBreakWord>{{ ((data.PrintRemark == null ? '' : "Print: " + data.PrintRemark) + (data.PrintRemark == null ? '' : " | ") + (data.EmbossingRemark == null ? '' : "Emboss: " + data.EmbossingRemark) + (data.EmbossingRemark == null ? '' : " | ") + (data.VacuumRemark == null ? '' : "Vaccum: " + data.VacuumRemark)) }}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>

    </div>
</div>