<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Print List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Print here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()" *ngIf="this.permission.Add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px; float: right">
      <input
        type="text"
        style="width: 250px; margin-right: 8px"
        nz-input
        placeholder="Search name / code"
        [(ngModel)]="searchValue"
        (keydown)="onKeydown($event)"
        (ngModelChange)="ValidateText()"
      />
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search()"
        style="margin-right: 8px"
      >
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzScroll]="{ x: '1200px',y:'515px' }" #basicTable
      [nzData]="this.PrintList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzWidth="150px" nzLeft> Name</th>
          <th nzWidth="150px">Code</th>
          <th nzWidth="150px">Image</th>
          <th nzWidth="400px">Description</th>
          <th nzWidth="160px" style="text-align:center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft>{{ data.Name }}</td>
          <td>{{ data.Code }}</td>
          <td style="text-align:center">
            <button class="btn btn-sm btn-light-primary" (click)="GetImages(data.PrintMasterId)">View Image</button>
          </td>
          <td>{{ data.Description }}</td>
          <td nzRight style="text-align:center">
            <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button class="btn btn-sm btn-light-danger" [disabled]="!this.permission.Delete" (click)="handleDelete(data)">Delete</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8" nzRequired>Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="PrintNameError">
              <input nz-input formControlName="PrintName" name="PrintName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Code</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="PrintCodeError">
              <input nz-input formControlName="Code" name="Code" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Upload Image</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Contact Person Name required">
              <input class="form-select mb-2" type="file" accept="image/*" (change)="onChange($event)">
              <div *ngIf="filesSelected" hidden="true">
                <h2>Upload Progress</h2>
                <pre>{{uploadProgress$ | async | json}}</pre>
              </div>
            </nz-form-control>
          </nz-form-item>


          <nz-form-item>
            <nz-form-label [nzSpan]="8">Description</nz-form-label>
            <nz-form-control [nzSpan]="14"  nzErrorTip="Description cannot be more than 200 characters">
              <textarea nz-input formControlName="Description" name="Description required"></textarea>
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save </button>
          </div>

        </form>
      </ng-template>
    </nz-modal>

    <nz-modal [nzWidth]="600" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isImageVisible" [nzTitle]="modalTitle1"
      [nzContent]="modalContent1" [nzFooter]="null" (nzOnCancel)="handleCancelImage()">
      <ng-template #modalTitle1>Print Image</ng-template>
      <ng-template #modalContent1>
        <div><b style="color: red;"> Verify the image with physical copy also.</b></div>
        <nz-divider></nz-divider>
        <div class="row" *ngIf="FilteredImageArray.length > 0">
          <div class="column" *ngFor="let index of FilteredImageArray">
            <a href="{{ index.URL }}" target="_blank">
              <img src="{{ index.URL }}" alt="Snow" style="width: 100%" />
            </a>
          </div>
        </div>
        <div *ngIf="FilteredImageArray.length == 0">No image found</div>
      </ng-template>
    </nz-modal>

  </div>
</div>