import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, ColorModel, GrainModel, ElementModel, ThicknessModel, WidthMasterModel, PostProcessCostingMasterModel } from '../../../Models/MasterModel';
import { ProductModel } from '../../../Models/ProductModel';
import { CustomerModel, OrderModel, OrderProductModel, SupplierModel } from '../../../Models/SupplierModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { UserInfo } from '../../../Authentication/UserInfo';
import { Subscription } from 'rxjs';
import { ProcessRawMaterialModel } from '../../../Models/ProcessModel';
import { MixingModel } from '../../../Models/MixingModel';
import { SaleOrderModel, SaleOrderProductionEmbossingModel, SaleOrderProductionPrintModel, SaleOrderProductionTumblingModel, SaleOrderProductionVacuumModel, SaleOrderProductionLacquerRawMaterialModel, SaleOrderProductionElementModel, SaleOrderProductionRawMaterialModel } from '../../../Models/SalesOrderModel';
import { ProductionLacquerModel } from '../../../Models/ProductionModel';
import { FormulationCodeModel } from '../../../Models/FormulationCodeModel';
import { LoadingService } from '../../../Services/loadingService';
import { AuthService } from '../../../Services/auth.service';
import { CostEstimationProductionModel, EstimationCodeFabricsModel, EstimationCodeMixingModel, EstimationCodeMixingRawMaterialModel } from 'src/PmsUIApp/Models/CostingModel';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';

@Component({
  selector: 'app-costEstimation',
  templateUrl: './costEstimationList.component.html',
  styleUrls: ['./costEstimationList.component.css']
})
export class CostEstimationComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  filterProductBasedOnProductTypeAndCategory: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  ProductFilteredFabricBaseList: ProductModel[] = [];
  OrderList: OrderModel[] = [];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  ColorList: ColorModel[] = [];
  GrainList: GrainModel[] = [];
  ElementList: ElementModel[] = [];
  VaccumList: SaleOrderProductionVacuumModel[] = [];
  TumblingList: SaleOrderProductionTumblingModel[] = [];
  LacquerList: ProductionLacquerModel[] = [];
  EmbossingList: SaleOrderProductionEmbossingModel[] = [];
  PrintMasterList: SaleOrderProductionPrintModel[] = [];
  NewSaleOrder: SaleOrderModel = new SaleOrderModel;
  NewCE: CostEstimationProductionModel = new CostEstimationProductionModel;
  selectedProduct: any;
  selectedproductId: any = 0;
  selectedCategory: any;
  FinishingTypeData: any;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  IsPopUpOpen = false;
  havePostProcess = false;
  MaterialType: string = '';
  ProductType: string = '';
  FinishingType: string = '';
  Price: number = 0;
  TotalEstimatedPrice: string = '';
  ProductionCostLm: number = 0;
  OverheadCost: number = 25;
  TotalCostPerLm: number = 0;
  TotalCostLm: number = 0;
  PreSkinGsm: number = 0;
  AdhesiveGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  FabricGsm: number = 0;
  TotalGsm: number = 0;
  TotalCoating: number = 0;
  TotalLaquerPrice: number = 0;
  TotalQuantity: number = 0;
  TotalPrice: number = 0;
  GrandTotalFabricPrice: number = 0;
  TotalFabricGSM: number = 0;
  TotalFabricQuantity: number = 0;
  TotalFabricPrice: number = 0;
  FabricCategoryId: number = 0;
  FabricCategory: any;
  FabricProductName: string;
  FabricUnit: string;
  FabricQuantity: number;
  FabricPrice: number;
  EstimationOrderId: number;
  FabricProductCostPerLm: number = 0;
  PerLMConstant: number = 1.45;
  Fabric: number = 0;
  Coating: number = 0;
  mixingData: any;
  dataRows: any[] = [];
  fabricDataRows: any[] = [];
  productionElement: any[] = [];
  newData: any = {};
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  listOfSelectedproducts: ProductModel[] = [];
  listOfSelectedElement: number[] = [];
  listOfSelectedOrder = [];
  listOfSelectedprocess: number[] = [];
  listOfSelectedmixing: number[] = [];
  SelectedOrder = 0;
  SelectedProcess = 0;
  SelectedMixing!: EstimationCodeMixingModel;
  LacquerRawMaterial: SaleOrderProductionLacquerRawMaterialModel[] = [];
  SalesOrderId = 0;
  private route$: Subscription = new Subscription;
  processRawMaterial: ProcessRawMaterialModel[] = [];
  MixingList: MixingModel[] = [];
  mixingRawMaterial: EstimationCodeMixingRawMaterialModel[] = [];
  MixingSelectedList: EstimationCodeMixingModel[] = [];
  Wastagetype = '%';
  CustomerList: CustomerModel[] = [];
  FormulationCodeList: FormulationCodeModel[] = [];
  SelectedFormulationCode: FormulationCodeModel = new FormulationCodeModel();
  SelectedFormulationCodeId = 0;
  SelectedFinishingType: any;
  ThicknessList: ThicknessModel[] = [];
  WidthList: WidthMasterModel[] = [];
  Rejection: number = 0;
  RejectionConstant: number = 6;
  PostProcess: string[] = [
    "Print", "Embossing", "Lacquar", "Vaccum", "Tumbling"
  ]
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  ActionType = '';
  disableFabricChange: boolean = true;
  selectedFabricCategory: string = 'fabric'
  selectedFabricName: string | null;
  SaleFormulationCode: string | '';
  selectedNewFabric: ProductModel;
  showProcess: boolean = false;
  LinkingSalesOrderList: SaleOrderModel[];
  PostProcessCostingList: PostProcessCostingMasterModel[] = [];
  FormulationCodeDropDownSpan: number = 6;
  InlineScraping: number = 0;
  constructor(private fb: UntypedFormBuilder, private auth: AuthService, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private loader: LoadingService) { }
  handleOk(): void {
    this.isLoading = true;
    this.Save();
  }
  Save() {
    if (this.NewCE.GrainId == 0) {
      this.isLoading = false;
      this.alertService.error("Grain Selection Required");
      return;
    }
    if (this.NewCE.Width == 0) {
      this.isLoading = false;
      this.alertService.error("Width Selection Required");
      return;
    }
    if (this.NewCE.ColorId == 0) {
      this.isLoading = false;
      this.alertService.error("Color Selection Required");
      return;
    }
    if (this.NewCE.SaleOrderType == '') {
      this.isLoading = false;
      this.alertService.error("SaleOrderType Required");
      return;
    }
    if (this.NewCE.ManufacturingProductName == '') {
      this.isLoading = false;
      this.alertService.error("ManufacturingProductName");
      return;
    }
    if (this.NewCE.OrderQuantity == 0) {
      this.isLoading = false;
      this.alertService.error("Quantity");
      return;
    }
    if (this.NewCE.EstimationPrice == 0) {
      this.isLoading = false;
      this.alertService.error("EstimationPrice");
      return;
    }
    if (this.NewCE.CustomerId == 0) {
      this.isLoading = false;
      this.alertService.error("Customer Required");
      return;
    }
    if (this.NewCE.EstimationFormulationCodeId == 0) {
      this.isLoading = false;
      this.alertService.error("FormulationCode Required");
      return;
    }
    if (this.NewCE.ProductCategoryId == 0) {
      this.isLoading = false;
      this.alertService.error("Category Required");
      return;
    }
    if (this.NewCE.OrderQuantity <= 0) {
      this.isLoading = false;
      this.alertService.error("Order Quantity Required");
      return;
    }
    this.NewCE.AddedBy = UserInfo.EmailID;
    this.NewCE.MixingData = this.MixingSelectedList;
    this.NewCE.GrainPrice = this.GrainList.filter(x => x.GrainId == this.NewCE.GrainId)[0].Price;
    let request = {
      "SaleOrderType": this.NewCE.SaleOrderType,
      "CustomerId": this.NewCE.CustomerId,
      "Remarks": this.NewCE.Remarks,
      "EstimationFormulationCodeId ": this.NewCE.EstimationFormulationCodeId,
      "Thick": this.NewCE.Thick,
      "GrainId": this.NewCE.GrainId,
      "Width": this.NewCE.Width,
      "ManufacturingProductName": this.NewCE.ManufacturingProductName,
      "OrderQuantity": this.NewCE.OrderQuantity,
      "EstimationPrice": this.NewCE.EstimationPrice,
      "ColorId": this.NewCE.ColorId,
      "LineSpeed": this.NewCE.LineSpeed,
      "OverheadCost": this.NewCE.OverheadCost,
      "Rejection": this.NewCE.Rejection,
      "ProductionCostLm": this.NewCE.ProductionCostLm,
      "EstimationFabricProductDetail": this.NewCE.FabricsData,
      "EstimationMixingTable": this.MixingSelectedList.map((mixing: any) => {
        return {
          EstimationOrderId: mixing.EstimationOrderId,
          MixingId: mixing.MixingId,
          MixingName: mixing.MixingName,
          AdhesiveGsm: mixing.AdhesiveGsm,
          PreSkinGsm: mixing.PreSkinGsm,
          SkinGsm: mixing.SkinGsm,
          FoamGsm: mixing.FoamGsm,
          FabricGsm: mixing.FabricGsm,
          EstimationCodeMixingRawMaterialTable: mixing.MixingRawMaterial
        };
      }),
      "EstimationFinishingTable": this.dataRows
    };
    if (this.EstimationOrderId > 0) {
      request["EstimationOrderId"] = this.EstimationOrderId;
    }
    let url = this.ApiUrl + "estimationorder/addupdateestimationorder";
    this.http.post<any>(url, request).subscribe({
      next: res => {
        this.isLoading = false;
        if (res == 'An error has occured. Please contact administrator') {
          this.alertService.error(res);
        } else {
          this.alertService.success("Entry Saved Successfully");
          this.router.navigate(['/home'])
        }
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },
    });

  }

  ngOnInit(): void {
    this.permission.Add = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.Delete);
    this.permission.View = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.View);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.EstimationOrderId = params["id"];
      this.ActionType = params["type"];
    })
    this.validateForm = this.fb.group({});
    this.GetAllCustomer();
    this.GetMixing();
    this.GetAllFormulationCode();
    this.GetAllThickness();
    this.GetAllWidth();
    this.GetAllColor();
    this.GetAllGrain();
    this.GetAllProducts();
    if (this.EstimationOrderId > 0) {
      setTimeout(() => {
        this.GetEstimationOrderById(this.EstimationOrderId)
      }, 3000);
    }
  }
  get f() { return this.validateForm.controls; }

  GetEstimationOrderById(id: number) {
    let url = this.ApiUrl + "estimationorder/getestimationorderbyid/" + id;
    this.http.get<any>(url).subscribe(data => {
      this.NewCE.SaleOrderType = data.SaleOrderType || '';
      this.NewCE.CustomerId = data.CustomerId || '';
      this.NewCE.Remarks = data.Remarks || '';
      this.NewCE.EstimationFormulationCodeId = data.EstimationFormulationCodeId || '';
      this.NewCE.Thick = data.Thick || '';
      this.NewCE.GrainId = data.GrainId || '';
      this.NewCE.Width = data.Width || '';
      this.NewCE.ManufacturingProductName = data.ManufacturingProductName || '';
      this.NewCE.OrderQuantity = data.OrderQuantity || '';
      this.NewCE.EstimationPrice = data.EstimationPrice || '';
      this.NewCE.ColorId = data.ColorId || '';
      this.NewCE.LineSpeed = data.LineSpeed || '';
      this.NewCE.OverheadCost = data.OverheadCost || '';
      this.NewCE.Rejection = data.Rejection || '';
      this.NewCE.ProductionCostLm = data.ProductionCostLm || '';
      this.listOfSelectedmixing = data.EstimationMixingTable.map(mixing => mixing.MixingId);
      if (data.EstimationMixingTable.length > 0) {
        this.AdhesiveGsm = data.EstimationMixingTable[0].AdhesiveGsm || 0;
        this.PreSkinGsm = data.EstimationMixingTable[0].PreSkinGsm || 0;
        this.SkinGsm = data.EstimationMixingTable[0].SkinGsm || 0;
        this.FoamGsm = data.EstimationMixingTable[0].FoamGsm || 0;
        this.FabricGsm = data.EstimationMixingTable[0].FabricGsm || 0;
      } else {
        this.AdhesiveGsm = 0;
        this.PreSkinGsm = 0;
        this.SkinGsm = 0;
        this.FoamGsm = 0;
        this.FabricGsm = 0;
      }
      this.MixingSelectedList = data.EstimationMixingTable.map((mixing: any) => {
        const newMixing: EstimationCodeMixingModel = {
          MixingId: mixing.MixingId,
          MixingName: mixing.MixingName,
          AdhesiveGsm: this.AdhesiveGsm,
          PreSkinGsm: this.PreSkinGsm,
          SkinGsm: this.SkinGsm,
          FoamGsm: this.FoamGsm,
          FabricGsm: this.FabricGsm,
          MixingRawMaterial: mixing.EstimationCodeMixingRawMaterialTable
        };
        return newMixing;
      });
      this.Coating = this.PreSkinGsm + this.SkinGsm + this.AdhesiveGsm;
      this.NewCE.FabricsData = data.EstimationFabricProductDetail;
      this.NewCE.FinishingData = data.EstimationFinishingTable;
      this.dataRows = data.EstimationFinishingTable;
      this.calculateGrandTotals();
      this.calculateGSMGrandTotal();
      this.havePostProcess = true;
    });
  }
  GetAllCustomer() {
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }
  calculateInline(res: number) {
    this.InlineScraping = res;
  }
  GetAllColor() {
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllColor(); }
    });
  }
  GetAllGrain() {
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllGrain(); }
    });
  }
  GetAllProductCategory(productType: string) {
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res.filter(category => category.ProductType === productType);
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(productType); }
    });
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res.filter(x => x.ProductType == "Finished");
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => { });
  }
  GetAllVaccum() {
    let url = this.ApiUrl + 'vacuum/getallvacuums';
    this.http.get<SaleOrderProductionVacuumModel[]>(url).subscribe(
      (res) => {
        this.VaccumList = res;
        this.VaccumList.forEach(x => x.Quantity = 1);
        this.FinishingTypeData = this.VaccumList;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllVaccum(); }
      }
    );
  }
  GetAllTumbling() {
    let url = this.ApiUrl + 'tumbling/getalltumblings';
    this.http.get<SaleOrderProductionTumblingModel[]>(url).subscribe(
      (res) => {
        this.TumblingList = res;
        this.TumblingList.forEach(x => x.Quantity = 1);
        this.FinishingTypeData = this.TumblingList;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllTumbling(); }
      }
    );
  }
  GetAllLacquer() {
    let url = this.ApiUrl + 'lacquer/getalllacquers';
    this.http.get<ProductionLacquerModel[]>(url).subscribe(
      (res) => {
        this.LacquerList = res;
        this.FinishingTypeData = this.LacquerList
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllLacquer(); }
      }
    );
  }
  GetAllEmbossing() {
    let url = this.ApiUrl + "embossing/getallembossings";
    this.http.get<SaleOrderProductionEmbossingModel[]>(url).subscribe(res => {
      this.EmbossingList = res;
      this.EmbossingList.forEach(x => x.Quantity = 1);
      this.FinishingTypeData = this.EmbossingList;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllEmbossing(); }
    });
  }
  GetAllPrint() {
    let url = this.ApiUrl + "print/getallprints";
    this.http.get<SaleOrderProductionPrintModel[]>(url).subscribe(res => {
      this.PrintMasterList = res;
      this.PrintMasterList.forEach(x => x.Quantity = 1);
      this.FinishingTypeData = this.PrintMasterList
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllPrint(); }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  GetAllThickness() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllThickness(); }
    });
  }
  GetAllWidth() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "width/getallwidthdata";
    this.http.get<WidthMasterModel[]>(url).subscribe(res => {
      this.WidthList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllWidth(); }
    });
  }

  OnFormulationddlChange() {
    this.NewCE.FabricProductId = 0;
    this.SelectedFormulationCode = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.NewCE.EstimationFormulationCodeId)[0];
    this.SaleFormulationCode = this.SelectedFormulationCode.SaleFormulationCode;
    this.SkinGsm = this.SelectedFormulationCode.SkinGsm;
    this.PreSkinGsm = this.SelectedFormulationCode.PreSkinGsm;
    this.FoamGsm = this.SelectedFormulationCode.FoamGsm;
    this.AdhesiveGsm = this.SelectedFormulationCode.AdhesiveGsm;
    this.FabricGsm = this.SelectedFormulationCode.FabricGsm;
    this.NewCE.ProductCategoryId = this.SelectedFormulationCode.CategoryId;
    this.NewCE.Thick = this.SelectedFormulationCode.ThicknessId;
    if (this.SelectedFormulationCode.IsOrderLinkingRequired) {
      this.FormulationCodeDropDownSpan = 4;
      this.GetAllSalesOrderForLinking();
    }
    else {
      this.FormulationCodeDropDownSpan = 6;
    }
    this.SetFabricProduct();
    this.calculateGSMGrandTotal();
    this.getMixingData();
  }
  GetMixing() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mixing/getallmixings";
    this.http.get<MixingModel[]>(url).subscribe(res => {
      this.MixingList = res;
    }, res => { });
  }
  getMixingData() {
    const url = this.ApiUrl + `saleorder/GetFormulationCodeById/${this.NewCE.EstimationFormulationCodeId}`;
    this.http.get(url).subscribe((data: any) => {
      this.mixingData = data;
      this.SaleFormulationCode = this.mixingData.SaleFormulationCode
      this.AdhesiveGsm = data.AdhesiveGsm;
      this.PreSkinGsm = data.PreSkinGsm;
      this.SkinGsm = data.SkinGsm;
      this.FoamGsm = data.FoamGsm;
      this.FabricGsm = data.FabricGsm;
      this.Coating = this.PreSkinGsm + this.SkinGsm + this.AdhesiveGsm;
      this.listOfSelectedmixing = data.MixingData.map(mixing => mixing.MixingId);
      this.MixingSelectedList = data.MixingData.map((mixing: EstimationCodeMixingModel) => {
        const newMixing: EstimationCodeMixingModel = {
          MixingId: mixing.MixingId,
          MixingName: mixing.MixingName,
          AdhesiveGsm: this.AdhesiveGsm,
          PreSkinGsm: this.PreSkinGsm,
          SkinGsm: this.SkinGsm,
          FoamGsm: this.FoamGsm,
          FabricGsm: this.FabricGsm,
          MixingRawMaterial: mixing.MixingRawMaterial.map((rawMaterial: any) => {
            const newRawMaterial: EstimationCodeMixingRawMaterialModel = {
              EstimationCodeRawMaterialMixingId: rawMaterial.FormulationCodeMixingRawMaterialId,
              EstimationCodeMixingId: rawMaterial.FormulationCodeMixingId,
              ProductId: rawMaterial.ProductId,
              ProductName: rawMaterial.ProductName,
              ProductCode: rawMaterial.ProductCode,
              Quantity: rawMaterial.Quantity,
              Unit: rawMaterial.Unit,
              Price: rawMaterial.Price,
            };
            return newRawMaterial;
          })
        };
        return newMixing;
      });
      this.calculateGrandTotals();
      this.havePostProcess = true;

    }, (error) => {
      console.error('Error fetching mixing data:', error);
    });
  }
  OnMixingDdlchange() {
    this.MixingSelectedList = [];
    this.listOfSelectedmixing.forEach(item => {
      var checkexist = this.mixingRawMaterial.filter(x => x.EstimationCodeRawMaterialMixingId == item).length;
      if (checkexist == 0) {
        var product = this.MixingList.filter(x => x.MixingId == item)[0];
        var mixing = new EstimationCodeMixingModel();
        mixing.MixingId = product.MixingId;
        mixing.MixingName = product.MixingName;
        mixing.MixingRawMaterial = [];
        product.MixingRawMaterial.forEach(x => {
          var raw = new EstimationCodeMixingRawMaterialModel();
          raw.Price = x.Price;
          raw.EstimationCodeRawMaterialMixingId = x.MixingId;
          raw.ProductCode = x.ProductCode;
          raw.ProductId = x.ProductId;
          raw.ProductName = x.ProductName;
          raw.Quantity = x.Quantity;
          raw.Unit = this.ProductRawList.filter(y => y.ProductId == x.ProductId)[0].Unit;
          mixing.MixingRawMaterial.push(raw);
        })
        this.MixingSelectedList.push(mixing)
      }
    })
  }
  updatePrice(index: number, data: any, newPrice: number) {
    this.MixingSelectedList[index].MixingRawMaterial[index].Price = newPrice;
  }

  updateQuantity(index: number, data: any, newQuantity: number) {
    this.MixingSelectedList[index].MixingRawMaterial[index].Quantity = newQuantity;
  }
  calculateGSMGrandTotal() {
    if (!isNaN(this.PreSkinGsm)) {
      this.TotalGsm += this.PreSkinGsm;
    }
    if (!isNaN(this.SkinGsm)) {
      this.TotalGsm += this.SkinGsm;
    }
    if (!isNaN(this.FoamGsm)) {
      this.TotalGsm += this.FoamGsm;
    }
    if (!isNaN(this.AdhesiveGsm)) {
      this.TotalGsm += this.AdhesiveGsm;
    }
    if (!isNaN(this.TotalFabricGSM)) {
      this.TotalGsm += this.TotalFabricGSM;
    }
  }
  calculateTotalEstimatedPrice() {
    this.TotalEstimatedPrice = parseFloat(((isNaN(this.NewCE.OrderQuantity) ? 0 : this.NewCE.OrderQuantity) * (isNaN(this.NewCE.EstimationPrice) ? 0 : this.NewCE.EstimationPrice)).toString()).toFixed(3)
  }
  FilterFabricBaseProduct(data: any) {
    this.ProductFilteredFabricBaseList = [];
    this.ProductFilteredFabricBaseList = this.ProductRawList.filter(x => x.ProductCategory.toLowerCase() == data)
  }
  SetFabricProduct() {
    if (this.NewCE.FabricProductId != null) {
      if (this.ActionType == 'edit' && this.SelectedFormulationCode?.FabricProductName == 'N/A') {
        this.selectedFabricName = this.SelectedFormulationCode?.FabricProductName
      }
      else if (this.ActionType == 'edit' && this.NewCE.FabricProductName != 'N/A' && this.NewCE.FabricProductId > 0) {
        var fabricProductStatus = this.ProductRawList.filter(x => x.ProductId == this.NewCE.FabricProductId)[0] != null ? true : false;
        if (fabricProductStatus) {
          this.selectedFabricName = this.ProductRawList.filter(x => x.ProductId == this.NewCE.FabricProductId)[0]?.ProductName;
          this.NewCE.FabricProductId = this.SelectedFormulationCode?.FabricProductId;
        }
        else {
          this.alertService.warning("Not able to Fetch Fabric Product. It was not saved during Sale Order Creation. Please select correct Fabric Product.");
        }
      }
      else {
        this.selectedFabricName = this.SelectedFormulationCode?.FabricProductName;
        this.NewCE.FabricProductId = this.SelectedFormulationCode?.FabricProductId;
      }
    }
    if (this.selectedFabricName == "" && this.SelectedFormulationCode == undefined) {
      this.alertService.warning("Not able to Fetch Fabric Product. It was not saved during Sale Order Creation. Please select correct Fabric Product.");
    }
  }

  GetAllSalesOrderForLinking() {
    var item = ["NotYet",
      "WorkPlan"];
    let url = this.ApiUrl + 'saleorder/getallsaleordersforfilterbystatus';
    this.http.post<SaleOrderModel[]>(url, item).subscribe(
      (res) => {
        this.LinkingSalesOrderList = res;
      },
      (res) => { }
    );
  }
  openAddModel(i: EstimationCodeMixingModel): void {
    this.SelectedMixing = i;
    this.IsPopUpOpen = true;
  }
  closeAddModel() {
    this.IsPopUpOpen = false;
  }
  OnProductDdlchange() {
    this.NewCE.FabricProductId = 0;
    this.FabricGsm = 0;
    this.calculateGSMGrandTotal();
  }
  addRawMaterial() {
    this.listOfSelectedproducts.forEach(x => {
      var raw = new EstimationCodeMixingRawMaterialModel;
      raw.Price = 0;
      raw.EstimationCodeMixingId = this.SelectedMixing.MixingId;
      raw.ProductCode = x.ProductCode;
      raw.ProductId = x.ProductId;
      raw.ProductName = x.ProductName;
      raw.Quantity = 1;
      raw.Unit = x.Unit;
      this.SelectedMixing.MixingRawMaterial.push(raw);
    })
    this.listOfSelectedproducts = [];
    this.closeAddModel();
  }
  handleRemoveRow(data: EstimationCodeMixingRawMaterialModel) {
    if (data.EstimationCodeMixingId > 0) {
      this.MixingSelectedList.filter(x => x.MixingId == data.EstimationCodeMixingId)[0].MixingRawMaterial =
        this.MixingSelectedList.filter(x => x.MixingId == data.EstimationCodeMixingId)[0]?.MixingRawMaterial.filter(obj => obj !== data);
    }
    else {
      this.MixingSelectedList.filter(x => x.MixingId == data.EstimationCodeMixingId)[0].MixingRawMaterial = this.MixingSelectedList.filter(x => x.MixingId == data.EstimationCodeMixingId)[0].MixingRawMaterial.filter(obj => obj !== data);
    }
  }
  updateGrainPrice() {
    const selectedGrain = this.GrainList.find(grain => grain.GrainId === this.NewCE.GrainId);
    if (selectedGrain) {
      this.NewCE.GrainPrice = selectedGrain.Price;
    } else {
      this.NewCE.GrainPrice = null;
    }
  }
  initilizeMaterialType(type: string): void {
    this.MaterialType = type;
  }
  initilizeProductType(type: string): void {
    this.ProductType = type;
    this.GetAllProductCategory(type);
  }
  filterProductDetailsByCategory() {
    this.filterProductBasedOnProductTypeAndCategory = this.ProductList.filter(product => product.ProductCategoryId === this.FabricCategory.ProductCategoryId && product.ProductType === this.ProductType);
  }
  fetchFinishingType(type: string): void {
    this.FinishingType = type;
    this.SelectedFinishingType = "";
    if (type == 'Printing') {
      this.GetAllPrint()
    } else if (type == 'Embossing') {
      this.GetAllEmbossing();
    } else if (type == 'Tumbling') {
      this.GetAllTumbling();
    } else if (type == 'Vacuum') {
      this.GetAllVaccum()
    } else if (type == 'Lacquer') {
      this.GetAllLacquer();
    }
  }
  StoreFinishingType(type: string): void {
    this.SelectedFinishingType = type;
  }
  StorePrice(Price: number): void {
    this.Price = Price;
  }
  calculateGrandTotals() {
    this.TotalFabricGSM = this.NewCE.FabricsData.reduce((total, fabric) => {
      return total + fabric.FabricGsm;
    }, 0);
    this.TotalFabricQuantity = this.NewCE.FabricsData.reduce((total, fabric) => {
      return total + fabric.FabricEstimationQuantity;
    }, 0);
    this.TotalFabricPrice = this.NewCE.FabricsData.reduce((total, fabric) => {
      return total + fabric.FabricProductCostPerLm;
    }, 0);
    this.GrandTotalFabricPrice = this.NewCE.FabricsData.reduce((total, fabric) => {
      return total + (fabric.FabricEstimationQuantity * fabric.FabricProductCostPerLm);
    }, 0);
  }
  addFabricData(): void {
    this.NewCE.FabricsData.push({
      FabricProductId: this.selectedProduct?.ProductId,
      FabricProductName: this.selectedProduct?.ProductName,
      FabricGsm: this.FabricGsm,
      FabricEstimationQuantity: this.FabricQuantity,
      FabricProductCostPerLm: this.FabricPrice
    }
    );
    // Clearing input values
    this.MaterialType = null;
    this.FabricCategory = null;
    this.selectedProduct = null;
    this.FabricQuantity = null;
    this.FabricGsm = null;
    this.FabricPrice = null;
    this.calculateGrandTotals();
  }

  removeFabricData(index: number): void {
    if (index >= 0 && index < this.NewCE.FabricsData.length) {
      this.NewCE.FabricsData.splice(index, 1);
    }
  }
  addData(): void {
    this.dataRows.push({
      MaterialType: this.MaterialType,
      FinishingType: this.FinishingType,
      SelectedFinishingType: this.SelectedFinishingType,
      Price: this.Price
    });
    this.NewCE.TotalFinishPrice = this.NewCE.TotalFinishPrice + this.Price;
    this.FinishingType = ''
    this.SelectedFinishingType = '';
    this.Price = 0;
  }
  removeData(index: number): void {
    if (index >= 0 && index < this.dataRows.length) {
      const removedPrice = this.dataRows[index].Price;
      this.dataRows.splice(index, 1);
      this.NewCE.TotalFinishPrice -= removedPrice;
    }
  }
  calculateTotalPriceForItem(item: any): String {
    let totalCost = item.MixingRawMaterial.reduce((sum, current) => sum + (current.Price) == 0 ? 0 : current.Price * parseFloat(current.Quantity?.toFixed(2)), 0);
    let totalQuantity = item.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0);
    return (totalCost / totalQuantity).toFixed(2);
  }
  calculateCostPerLmForItem(item: any): number {
    let totalCost = item.MixingRawMaterial.reduce((sum, current) => sum + (current.Price) == 0 ? 0 : current.Price * parseFloat(current.Quantity?.toFixed(2)), 0);
    let totalQuantity = item.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0);
    let GSM = item.MixingName === 'PRE SKIN' ? this.PreSkinGsm : (item.MixingName === 'SKIN' ? this.SkinGsm :
      (item.MixingName === 'ADHESIVE' ? this.AdhesiveGsm : 0));
    let clm = ((totalCost / totalQuantity / 1000) * this.PerLMConstant) * GSM;
    this.TotalQuantity = this.TotalQuantity + totalQuantity
    this.TotalPrice = this.TotalPrice + totalCost
    this.NewCE.TotalCostPerLm = parseFloat((this.TotalCostPerLm + clm).toFixed(2));
    return isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
  }
  calculateLMMost(PerLMConstant: number) {
    this.PerLMConstant = PerLMConstant;
    this.Rejection = parseFloat(((this.NewCE.TotalCostPerLm + this.GrandTotalFabricPrice + this.NewCE.GrainPrice) * (this.RejectionConstant / 100)).toFixed(2));
    this.NewCE.ProductionCostLm = parseFloat((this.NewCE.TotalCostPerLm + this.GrandTotalFabricPrice + this.NewCE.GrainPrice + this.NewCE.Rejection + this.NewCE.TotalFinishPrice).toFixed(2));
    this.NewCE.TotalCostPerLm = parseFloat((this.NewCE.ProductionCostLm + this.NewCE.OverheadCost).toFixed(2))
  }
  calclulateRejection(rejection: number) {
    this.NewCE.Rejection = rejection;
    this.Rejection = parseFloat(((this.NewCE.TotalCostPerLm + this.GrandTotalFabricPrice + this.NewCE.GrainPrice) * (rejection / 100)).toFixed(2));
    this.RejectionConstant = rejection;
    this.NewCE.ProductionCostLm = parseFloat((this.NewCE.TotalCostPerLm + this.GrandTotalFabricPrice + this.NewCE.GrainPrice + this.NewCE.Rejection + this.NewCE.TotalFinishPrice).toFixed(2));
  }
  calclulateProductinCostPerLm(overheadcost: number) {
    this.NewCE.OverheadCost = overheadcost;
    this.NewCE.TotalCostPerLm = parseFloat((this.NewCE.ProductionCostLm + this.NewCE.OverheadCost).toFixed(2))
  }
  calculateTotalProfitLoss() {
    return (parseFloat(this.TotalEstimatedPrice) - this.TotalCostPerLm * this.NewCE.OrderQuantity).toFixed(2)
  }
}

