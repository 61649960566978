import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductionLacquerModel } from 'src/PmsUIApp/Models/ProductionModel';
import { SaleOrderProductionEmbossingModel, SaleOrderProductionPrintModel, SaleOrderProductionTumblingModel, SaleOrderProductionVacuumModel } from 'src/PmsUIApp/Models/SalesOrderModel';

@Component({
  selector: 'app-post-process-options',
  templateUrl: './post-process-options.component.html',
  styleUrls: ['./post-process-options.component.css']
})
export class PostProcessOptionsComponent {

  PostProcess: string[] = [
    "Print", "Embossing", "Lacquar", "Vaccum", "Tumbling"
  ]
  @Input() SelectedPostProcess: any = [];
  @Output() handleProcess: EventEmitter<string> = new EventEmitter();
  @Input() PrintMasterList: SaleOrderProductionPrintModel[] = []
  @Input() VaccumList: SaleOrderProductionVacuumModel[] = [];
  @Input() TumblingList: SaleOrderProductionTumblingModel[] = [];
  @Input() LacquerList: ProductionLacquerModel[] = [];
  @Input() EmbossingList: SaleOrderProductionEmbossingModel[] = [];

  showProcess: boolean = false;

  removeProcess(process: string) {
    const index = this.SelectedPostProcess.findIndex((postPro)=> postPro.PostProcessName === process)
    this.SelectedPostProcess.splice(index, 1);
  }

  getSequenceList(){
    const tempList = new Array(this.SelectedPostProcess.length).fill(0).map((_, index) => index +1);
    // const remainingValues = tempList.filter(value => !this.SelectedPostProcess.some(item => item.Rank === value));
    return tempList
  }

  handleShowProcessList(){
    this.showProcess = !this.showProcess
  }

  closeProcessList(){
    this.showProcess=false
  }

}
