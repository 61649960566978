import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ColorModel, GrainModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { DemandPopService } from '../../Services/DemandPopService';
import { LoadingService } from '../../Services/loadingService';
import { HttpClient } from '@angular/common/http';
import { ProductStockSummaryExportModel, ProductStockSummaryForJumboGradeModel } from 'src/PmsUIApp/Models/ReportModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { SaleOrderModel, SaleOrderProductionEmbossingModel, SaleOrderProductionPrintModel, SaleOrderProductionVacuumModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import moment from 'moment';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
  selector: 'app-ProductStockSummary',
  templateUrl: './ProductStockSummary.component.html'
})

export class ProductStockSummaryReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  statusfilter: string = 'Available';
  DataList: ProductStockSummaryForJumboGradeModel[] = [];
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  request = {
    //DateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    //DateTo: new Date(new Date().setHours(23, 59, 59)),
    CustomerId: 0,
    ColorId: 0,
    GrainId: 0,
    SaleFormulationCodeId: 0,
    ArticleName: "",
    RecordStatus: "Available",
    EmbossingMasterId: 0,
    PrintMasterId: 0,
    VacuumMasterId: 0,
  }
  CustomerList: CustomerModel[];
  count: any;
  ColorList: ColorModel[];
  GrainList: GrainModel[];
  FormulationCodeList: FormulationCodeModel[];
  Orderlist!: SaleOrderModel;
  isSaleOrderVisible: boolean;
  EmbossingList: SaleOrderProductionEmbossingModel[];
  PrintMasterList: SaleOrderProductionPrintModel[];
  VaccumList: SaleOrderProductionVacuumModel[];
  totalItemsCount: number = 0;
  exportfields: ProductStockSummaryExportModel[] = [];
  exportoptions = {
    headers: [
      'S. No.',
      'Sale Order No.',
      'Sale Order Code',
      'Grain Code',
      'Grain Name',
      'Color Name',
      "Alias",
      'Customer',
      'Grade',
      'Roll',
      'Quantity(MTR)',
      'Status'
    ],
  };
  fields: ProductStockSummaryExportModel = new ProductStockSummaryExportModel();
  constructor(private loader: LoadingService, private demandService: DemandPopService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsProductStockSummary, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsProductStockSummary, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsProductStockSummary, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllCustomer();
    this.GetAllFormulationCode();
    this.GetAllColor();
    this.GetAllGrain();
    this.GetAllEmbossing();
    this.GetAllPrint();
    this.GetAllVaccum();
    this.GetProductStockSummaryReport();
  }

  GetProductStockSummaryReport() {
    if (this.statusfilter == '' || this.statusfilter == 'null') {
      this.statusfilter = null;
    }
    this.isTableLoading = true; let url = this.ApiUrl + "report/GetProductStockSummaryForJumboGrade";
    let count = this.totalItemsCount = 0;
    this.http.post<ProductStockSummaryForJumboGradeModel[]>(url, this.request).subscribe(res => {
      console.log(res)
      this.DataList = res;
      count = this.totalItemsCount;
      this.exportfields = [];
      this.DataList.forEach((x) => {
        count++;
        x.SerialNo = count;

        this.fields = new ProductStockSummaryExportModel();
        this.fields.SerialNo = x.SerialNo;
        this.fields.SaleOrderNumber = x.SaleOrderNumber;
        this.fields.SaleOrderCode = x.SaleOrderCode;
        this.fields.GrainCode = x.GrainCode;
        this.fields.GrainName = x.GrainName;
        this.fields.ColorName = x.ColorName;
        this.fields.ArticleName = x.ArticleName
        this.fields.CustomerName = x.CustomerName
        this.fields.Grade = x.Grade;
        this.fields.Roll = x.Roll;
        this.fields.Quantity = x.Quantity;
        this.fields.Status = x.SaleOrderStatus == 'DispatchCompleted' ? 'Dispatched' : 'Available';
        this.exportfields.push(this.fields);
      });
      this.totalItemsCount = count;
      this.isTableLoading = false;

    }, res => {
      this.GetProductStockSummaryReport()
    });
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'product-stock-summary' + exportdate,
        this.exportoptions
      );
  }

  GetAllCustomer() {

    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  GetAllColor() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllColor();
      }
    });
  }
  GetAllGrain() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllGrain();
      }
    });
  }
  GetAllVaccum() {
    let url = this.ApiUrl + 'vacuum/getallvacuums';
    this.http.get<SaleOrderProductionVacuumModel[]>(url).subscribe(
      (res) => {
        this.VaccumList = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllVaccum()
        }
      }
    );
  }
  GetAllEmbossing() {
    let url = this.ApiUrl + "embossing/getallembossings";
    this.http.get<SaleOrderProductionEmbossingModel[]>(url).subscribe(res => {
      this.EmbossingList = res;

    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllEmbossing()
      }
    });
  }
  GetAllPrint() {
    let url = this.ApiUrl + "print/getallprints";
    this.http.get<SaleOrderProductionPrintModel[]>(url).subscribe(res => {
      this.PrintMasterList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllPrint()
      }
    });
  }

  OpenSaleOrderViewPop(data: any) {
    this.loader.show();
    let url =
      this.ApiUrl + 'saleorder/getsaleorderdataforviewbyid/' + data;

    this.http.get<any>(url).subscribe((res) => {
      this.Orderlist = res;
      this.isSaleOrderVisible = true;
      this.calclulateTotalSalePrice();
      this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
      this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach(
        (element) => {
          this.Orderlist.SaleOrderProduction.RawGSMTotal +=
            parseFloat(element.Quantity.toString()) *
            (isNaN(parseInt(element.AvgGsm?.toString()))
              ? 0
              : parseInt(element.AvgGsm?.toString()));
        }
      );
      this.Orderlist.SaleOrderProduction.MixingGSMTotal =
        this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
      this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(
        (element) => {
          this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(
            element.Total
          )
            ? 0
            : element.Total;
          this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(
            parseInt(element.WeightGsm?.toString())
          )
            ? 0
            : parseInt(element.WeightGsm.toString());
          this;
        }
      );
      this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal =
        this.Orderlist.SaleOrderProduction.MixingGSMTotal +
        this.Orderlist.SaleOrderProduction.RawGSMTotal;
      var totalprint =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalprint);
      var totalEmbossing =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalEmbossing);
      var totalVaccum =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalVaccum);
      var totalTumbling =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalTumbling);
      //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      //console.log(totalLacquer);
      var totalElement =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(isNaN(totalElement) ?? 0);
      var totalRaw =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce(
          (sum, current) => sum + parseFloat(current.TotalCost?.toString()),
          0
        ) ?? 0;
      console.log(totalRaw);
      this.Orderlist.GrandTotal =
        parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
        parseFloat(isNaN(totalprint) == true ? '0' : totalprint.toString()) +
        parseFloat(
          isNaN(totalEmbossing) == true ? '0' : totalEmbossing.toString()
        ) +
        parseFloat(isNaN(totalVaccum) == true ? '0' : totalVaccum.toString()) +
        parseFloat(
          isNaN(totalTumbling) == true ? '0' : totalTumbling.toString()
        ) +
        /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
        parseFloat(
          isNaN(totalElement) == true ? '0' : totalElement.toString()
        ) +
        parseFloat(totalRaw.toString());
      console.log(this.Orderlist);
      this.loader.hide();
    });
  }

  calclulateTotalSalePrice() {
    this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
  }

  handleSaleOrderCancel() {
    this.isSaleOrderVisible = false;
    this.Orderlist = null;
  }

}
