import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ColorModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-Color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.css']
})
export class ColorComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  ColorList: ColorModel[] = [];
  ColorListOriginal: ColorModel[] = [];
  NewColor: ColorModel = new ColorModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Color";
  NameError = 'Enter Color name (maximum 50 characters)';
  CodeError = 'Enter Color code';
  searchValue = '';
  visible = false;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Color, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Color, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Color, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      ColorName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      ColorCode: [null, [Validators.required], [this.CodeAsyncValidator]],
      ColorDesc: [null, [Validators.maxLength(200)]]

    });
    this.GetAllColor();
  }
  get f() { return this.validateForm.controls; }

  GetAllColor() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;
      this.ColorListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllColor();
      }
    });
  }
  SaveColor() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewColor.ColorName = this.f['ColorName'].value;
    this.NewColor.ColorCode = this.f['ColorCode'].value;
    this.NewColor.ColorDesc = this.f['ColorDesc'].value;
    this.NewColor.ColorAddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + "Color/addupdateColor";
    this.http.post<ColorModel>(url, this.NewColor).subscribe({

      next: res => { this.alertService.success("Color Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllColor(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }

  OpenEditPop(data: ColorModel) {
    this.PopUpTitle = "Modify Color Details";
    this.validateForm.setValue(
      {
        ColorName: data.ColorName,
        ColorCode: data.ColorCode,
        ColorDesc: data.ColorDesc,

      }
    )
    this.NewColor = new ColorModel();
    this.NewColor.ColorId = data.ColorId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Color";
    this.validateForm.reset();
    this.NewColor.ColorName = "";
    this.NewColor.ColorId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveColor();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteColor() {
    let url = this.ApiUrl + "Color/deleteColor";
    this.http.post<any>(url, this.NewColor).subscribe({

      next: res => {

        this.alertService.success("Color Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllColor();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: ColorModel) {
    this.NewColor = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Color?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteColor(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }

  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.ColorList.filter(x => x.ColorId != this.NewColor.ColorId);
        var nre = res.filter(x => x.ColorName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Color name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Color name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Color name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  CodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.ColorList.filter(x => x.ColorId != this.NewColor.ColorId);
        var nre = res.filter(x => x.ColorCode.toLowerCase() == control.value.toLowerCase());

        this.CodeError = '';
        if (control.value == '') {
          this.CodeError = "Enter Color code";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.CodeError = "Color code already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.CodeError = "Enter Color code";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllColor();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {
    var res = this.ColorListOriginal;
    var res2 = this.ColorListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ColorModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.ColorAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.ColorAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });


      this.ColorList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.ColorList = res.filter(
        (item: ColorModel) =>
          item?.ColorName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ColorCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ColorDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ColorAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())



      )
    }

  }




}
