import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
})

export class UnauthorizedComponent implements OnInit {
  private root: any;
  constructor(private router: Router) { }
  ngOnInit() {
  }
  backtoHome() {
    this.router.navigate(['/home/welcome']);
  }
}
