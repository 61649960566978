<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Product Category List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your product category here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary"  *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>

    <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.ProductCategoryList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th  nzWidth="150px">Product Type</th>
          <th >Category Name</th>

          <th >Category Desc</th>
          <th>AddedDate</th>
          <th >AddedBy</th>

          <th nzWidth="100px"  *ngIf="permission.Add || permission.Delete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.ProductType}}</td>
          <td>{{ data.ProductCategory }}</td>
          <td>{{ data.ProductCategoryDesc }}</td>
          <td>{{ data.ProductCategoryAddedDate | DatetimeConverter}}</td>
          <td>{{ data.ProductCategoryAddedBy }}</td>



          <td *ngIf="CheckUserAddPermission(data.ProductCategoryAddedBy )">
            <button class="btn btn-sm btn-light-primary" [disabled]="enableEditDeleteBtn('Edit')" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button class="btn btn-sm btn-light-danger" [disabled]="enableEditDeleteBtn('Delete')"  (click)="handleDelete(data)">Delete</button>

          </td>
        </tr>
      </tbody>
    </nz-table>


    <nz-modal [nzWidth]="800"
              [nzStyle]="{ top: '20px' }"
              [(nzVisible)]="isVisible"
              [nzTitle]="modalTitle"
              [nzContent]="modalContent"
              [nzFooter]=null
              (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8"  nzRequired>Product Category Name</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Category Name (max 50 characters)">
              <input nz-input formControlName="ProductCategory" name="ProductCategoryName" />
            </nz-form-control>
          </nz-form-item>

 <nz-form-item>

              
              <nz-form-label [nzSpan]="8"nzRequired>Select Product Type</nz-form-label>
              <nz-form-control [nzSpan]="14"  nzErrorTip="Product Type Required">
                <nz-select formControlName="ProductType" name="SelectProductType">

                  <nz-option *ngFor="let s of this.typeList;" [nzValue]="s.name" [nzLabel]="s.name"></nz-option>

                </nz-select>
              </nz-form-control>
            </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Description</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Description (max 200 characters)">
              <textarea rows="4" nz-input formControlName="ProductCategoryDesc" name="ProductCategoryDesc"></textarea>
            </nz-form-control>
          </nz-form-item>
          -->
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save Product Category</button>
          </div>

        </form>
      </ng-template>
    </nz-modal>

  </div>
</div>

