import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GateInModel, GateInPrintModel } from 'src/PmsUIApp/Models/GateInModel';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { from, map, combineAll, Observable, combineLatestAll } from 'rxjs';
import { ISasToken } from '../../azure-storage/azureStorage';
import { IUploadProgress } from '../../Models/StockModel';
import { BlobStorageService } from '../../azure-storage/blob-storage.service';
import { DatetimeConverter } from '../../Services/DatetimeConverter.pipe';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { UserInfo } from '../../Authentication/UserInfo';
import { InterceptorSkipHeader } from 'src/PmsUIApp/Services/HttpInterceptorService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { StorageService } from 'src/PmsUIApp/Services/storage.service';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { TransportModel, TransportVehicleModel } from 'src/PmsUIApp/Models/TransportModel';

@Component({
  templateUrl: './GateOut.component.html',
  styles: [
    `
 
* {
  box-sizing: border-box;
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}
    `
  ]
})
export class GateOutComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  validateForm!: UntypedFormGroup;
  GateInList: GateInModel[] = [];
  GateInListOriginal: GateInModel[] = [];
  NewGateIn!: GateInModel;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  isImageVisible = false;
  IsDriverInputVisible = false
  isImageViewVisible = false;
  uploadProgress$: Observable<IUploadProgress[]> | undefined;
  filesSelected = false;
  uploadinvoicefile: any;
  uploadinvoice: string = '';
  token: any;
  getToken: any;
  ImageArray: any[] = [];
  FilteredImageArray: any[] = [];
  exportoptions = {
    headers: ["VEHICLE NUMBER", "TRANSPORT COMPANY", "PERSON NAME", "CONTACT NUMBER", "GATE-IN DATE", "GATE PASS ISSUED DATE", "OUT DATE", "ADDED BY", "GATE-IN", "PASS ISSUED", "GATE-OUT"]
  };
  fields: GateInPrintModel = new GateInPrintModel;
  exportfields: GateInPrintModel[] = []
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  StorageAccContainerName: string = 'gate';
  request = {
    FromDate: new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 2)),
    ToDate: new Date(new Date().setHours(23, 59, 59)),
    VehicleId: 0,
    TransportId: 0,
    DateType: 'gatein', // options are - gatein, gateout, gatepass
    SupplierId: 0,
    Type: '',
    IsGatePassIssued: null,
    IsGateOutCompleted: null,
    PONumber: '',
    InvoiceNumber: ''
  }
  dateTypeList = [
    {
      "Text": "Gate In",
      "Value": 'gatein'
    },
    {
      "Text": "Gate Out",
      "Value": 'gateout'
    },
    {
      "Text": "Gate Pass",
      "Value": 'gatepass'
    }];
  totalItemsCount: number;
  TransportList: TransportModel[];
  SupplierList: SupplierModel[];
  FilterVehicleList: TransportVehicleModel[] = [];
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private blobStorage: BlobStorageService, private auth: AuthService, private router: Router,
    public storage: StorageService, public loader: LoadingService
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.GateOut, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.GateOut, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.GateOut, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllGateInRecords();
    // this.getStorageToken();
    this.validateForm = this.fb.group({
      GateInPerson: [null, [Validators.required]],
      GateInPersonContact: [null, [Validators.required, Validators.minLength(10)]]
    })

  }

  get f() { return this.validateForm.controls; }

  GetAllGateInRecords() {
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "gate/getgateinrecordswithfilter";
    this.http.post<GateInModel[]>(url, this.request).subscribe(res => {

      this.GateInListOriginal = res;
      this.GateInList = this.GateInListOriginal.filter(x => x.GatePassIssue == true);
      this.GateInList.forEach(x => {
        count++;
        this.fields = new GateInPrintModel;

        this.fields.VehicleNumber = x.VehicleNumber;
        this.fields.TransportCompanyName = x.TransportCompanyName;
        this.fields.GateInPerson = x.GateInPerson;
        this.fields.GateInPersonContact = x.GateInPersonContact;
        this.fields.GateInDate = new DatetimeConverter().transform(x.GateInDate) ?? "";
        this.fields.GatePassIssueDate = new DatetimeConverter().transform(x.GatePassIssueDate) ?? "";
        this.fields.GateOutDate = new DatetimeConverter().transform(x.GateOutDate) ?? "";
        this.fields.AddedBy = '';
        this.fields.GateIn = x.GateIn == true ? "Completed" : "Not yet";
        this.fields.GatePassIssue = x.GatePassIssue == true ? "Issued" : "Not Issued";
        this.fields.GateOut = x.GateOut == true ? "Completed" : "Not yet";



        this.exportfields.push(this.fields);
      })
      this.totalItemsCount = count;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllGateInRecords();
      }
    });
  }
  export() {

    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'gateout-export' + exportdate, this.exportoptions);
  }
  // getStorageToken(): any {
  //   let url = this.ApiUrl + "data/getstoragetokenforgate";
  //   this.http.get<any>(url).subscribe(res => {

  //     this.tokenget = res;
  //     this.token = res;
  //   }, res => {
  //     this.count++;
  //     if (this.count < 2) {
  //       this.getStorageToken();
  //     }
  //   });
  // }
  files: NzUploadFile[] = [];
  mockOSSData = {
    dir: 'user-dir/',
    expire: '1577811661',
    host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
    accessId: 'c2hhb2RhaG9uZw==',
    policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
    signature: 'ZGFob25nc2hhbw=='
  };

  transformFile = (file: NzUploadFile): NzUploadFile => {
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = this.mockOSSData.dir + filename;

    return file;
  };

  getExtraData = (file: NzUploadFile): {} => {
    const { accessId, policy, signature } = this.mockOSSData;

    return {
      key: file.url,
      OSSAccessKeyId: accessId,
      policy,
      Signature: signature
    };
  };

  //onChange(e: NzUploadChangeParam): void {

  //}
  onChange(event: any): void {
    this.filesSelected = true;

    this.uploadinvoicefile = event.target.files;
    //this.oninvoiceupload();
    // this.uploadProgress$ = from(event.target.files as FileList).pipe(
    //   map(file => this.uploadFile(file)),
    //   combineAll()
    // );
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  private fetchStorageTokenAndUpload(): Observable<any> {
    this.storage.getStorageSASToken(this.StorageAccContainerName)
      .subscribe(
        (token: string) => {
          this.uploadProgress$ = from(this.uploadinvoicefile as FileList).pipe(
            map(file => this.uploadFile(file, token)),
            combineLatestAll()
          );
          this.uploadinvoicefile = null;
        },
        (error: any) => {
          console.error('Error fetching storage token:', error);
        }
      );
    return null;
  }
  // oninvoiceupload() {
  //   this.fetchStorageToken();
  //   this.uploadProgress$ = from(this.uploadinvoicefile as FileList).pipe(
  //     map(file => this.uploadFile(file)),
  //     combineAll()
  //   );
  // }

  uploadFile(file: File, token: any): Observable<IUploadProgress> {


    const accessToken: ISasToken = {
      container: token.StorageContainerName,
      filename: "GateOut/" + this.NewGateIn.GateInId + '/' + file.name,
      storageAccessToken: token.StorageAccountToken,
      storageUri: token.StorageAccountHost
    };


    return this.blobStorage
      .uploadToBlobStorage(accessToken, file)
      .pipe(map(progress => this.mapProgress(file, progress)));
  }
  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress
    };
  }
  GateOut() {
    if (this.IsDriverInputVisible) {
      if (this.validateForm.invalid) {
        Object.values(this.validateForm.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
        return;
      }
    }

    this.isLoading = true;
    this.NewGateIn.GateOutAddedBy = UserInfo.EmailID;
    if (this.NewGateIn.GateInPerson = 'System') {
      this.NewGateIn.GateInPerson = this.f["GateInPerson"].value;
      this.NewGateIn.GateInPersonContact = this.f["GateInPersonContact"].value
    }
    var item = this.NewGateIn;
    let url = this.ApiUrl + "gate/gateout";
    this.http.post<GateInModel>(url, item).subscribe({
      next: res => {
        this.alertService.success("Entry Saved Successfully"); this.isLoading = false; item.GateOut = true;
        if (this.uploadinvoicefile) {
          this.fetchStorageTokenAndUpload();
        }
        this.handleCancelImage();
      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = false; },
    });
  }
  OpenImagePopop(item: GateInModel) {
    this.validateForm.reset();
    this.NewGateIn = item;
    this.isImageVisible = true;
    if (this.NewGateIn.GateInPerson == 'System') {
      this.IsDriverInputVisible = true;
    }
  }

  handleCancelImage(): void {
    this.isImageVisible = false;
    this.IsDriverInputVisible = false;
  }
  handleOk(): void {

  }
  handleCancelViewImage(): void {
    this.isImageViewVisible = false;
  }

  GetImages($event: any) {
    this.storage.getStorageSASToken(this.StorageAccContainerName).subscribe((res: any) => {
      this.getToken = res;
      this.FilteredImageArray = this.ImageArray = [];
      var Blobtoken = this.getToken.StorageAccountToken;
      let url = this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + Blobtoken + "&restype=container&comp=list";

      const headers = new HttpHeaders().set(InterceptorSkipHeader, ''); // To exclude AD Authorization header
      this.http.get<any>(url, <Object>{ responseType: 'text', headers }).subscribe(res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res, "text/xml");
        var x = xmlDOM.documentElement.childNodes;
        for (var i = 0; i < x.length; i++) {

          if (x[i].nodeName == "Blobs") {
            for (var j = 0; j < x[i].childNodes.length; j++) {

              var str = x[i].childNodes[j].childNodes[0].childNodes[0].nodeValue ?? "";
              var arr = str.split('/')
              var item = {
                container: arr[0],
                ID: arr[1],
                Img: arr[2],
                URL: this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + "/" + str + Blobtoken
              }
              this.ImageArray.push(item);

            }
          }
          // + ": " + x[i].childNodes[0].nodeValue + "<br>"
        }
        this.FilteredImageArray = this.ImageArray.filter(x => x.container == "GateOut").filter(x => x.ID == $event);
        this.isImageViewVisible = true;
      }, res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res.error.text, "text/xml");
        const value = xmlDOM.getElementsByTagName("Blobs");

      });
    });
  }

  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllGateInRecords();
    this.myDateValue = undefined;
    this.toDate = undefined;

  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {
    var res = this.GateInListOriginal;
    var res2 = this.GateInListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: GateInModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.GateInDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.GateInDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.GateInList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.GateInList = res.filter(
        (item: GateInModel) => {
          if (
            item?.VehicleNumber?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.TransportCompanyName?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.Invoice[0]?.PONumber?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            (this.GetInvoices(item.Invoice)).toLowerCase().includes(this.searchValue?.toLowerCase())
          ) {
            return true;
          } else if (
            item?.GateInPerson?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.GateInPersonContact?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
          ) {
            return true;
          } else if (this.searchValue?.toLowerCase() == 'completed') {
            return item.GateIn == true || item.GateOut == true;
          } else if (this.searchValue?.toLowerCase() == 'not yet') {
            return !item.GateIn || !item.GateOut
          } else if (this.searchValue?.toLowerCase() == 'issued') {
            return item.GatePassIssue == true;
          } else if (this.searchValue?.toLowerCase() == 'not-issued') {
            return !item.GatePassIssue
          } else {
            return false;
          }
        })

    }
  }

  GetInvoices(data: InvoiceModel[]) {
    if (data.length == 0) {
      return 'NA'
    }
    else if (data.length == 1) {
      return data[0].InvoiceNumber;
    }
    else {
      var r = '';
      data.forEach((x, i) => {
        r += x.InvoiceNumber + ", "
      })
      return r.substring(0, r.length - 2);
    }
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllSuppliers();
      this.GetAllTransport();
    }
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  ResetFilters() {
    this.request = {
      FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
      ToDate: new Date(new Date().setHours(23, 59, 59)),
      VehicleId: 0,
      TransportId: 0,
      DateType: 'gatein', // options are - gatein, gateout, gatepass
      SupplierId: 0,
      Type: '',
      IsGatePassIssued: null,
      IsGateOutCompleted: null,
      PONumber: '',
      InvoiceNumber: ''
    }
  }
  GetAllTransport() {
    let url = this.ApiUrl + 'transport/getalltransport';
    this.http.get<TransportModel[]>(url).subscribe({
      next: (res) => {
        this.TransportList = res;
        this.loader.hide()
      },
      error: (res) => {
        this.loader.hide()
        this.GetAllTransport();
      },
    });
  }
  GetAllSuppliers() {
    let url = this.ApiUrl + 'supplier/getallsuppliers';
    this.http.get<SupplierModel[]>(url).subscribe(
      (res) => {
        this.SupplierList = res;
        this.loader.hide()
      },
      (res) => {
        this.count++;
        this.loader.hide()
        if (this.count < 2) {
          this.GetAllSuppliers();
        }
      }
    );
  }
  GetAllVehicleForFilter(TransportId: any) {
    this.FilterVehicleList = [];
    if (TransportId != undefined && TransportId) {
      this.FilterVehicleList = this.TransportList.find(x => x.TransportId == TransportId).TransportVehicle;
    }
  }

}
