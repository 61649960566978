<div style="border:1px solid">
  <!-- <nz-divider nzText="Formulation Sheet" style="background: #d9d9d9; margin: 0; padding: 10px; "></nz-divider> -->
  <div style="padding: 10px 0; margin-top: 10px; text-transform: uppercase; font-size: 13px; text-align: center; color: black; background: #415994;">
   <b> Formulation Sheet</b>
   
  </div>
  <nz-descriptions *ngIf="Production" nzBordered nzSize="small" [nzColumn]="2" style="margin-left: 18px; margin-right:18px;">
    <nz-descriptions-item nzTitle="Formulation Code"><b>{{Production.SaleFormulationCode}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="APPLICATION"><b>{{Production.Category}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Grain"><b>{{Production?.SaleOrderProduction?.GrainName}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Printing"> <b>{{PrintStr}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Colour"><b>{{Production?.SaleOrderProduction?.ColorName}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Embossing"><b>{{EmbossingStr}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Fabric"><b>{{Production.FormulationFabricProductName}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Tumbling"><b>{{TumblingStr}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Fabric Color"><b>{{Production?.SaleOrderProduction?.FabricColorName}}</b></nz-descriptions-item>

    <nz-descriptions-item nzTitle="Lacquer"><b>{{LacquerStr}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Thickness"><b>{{Production?.SaleOrderProduction?.ThicknessValue}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Vaccum"><b>{{VaccumStr}}</b></nz-descriptions-item>

  </nz-descriptions>
  <!--<nz-divider style="border: 1px solid rgb(168, 167, 167);"></nz-divider>-->
  <div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">

    <div nz-col [nzSpan]="12" *ngFor="let i of Production.SaleOrderProduction.InspectionFormulationMixing">


      <div style="padding: 10px 0; margin-top: 10px; text-transform: uppercase; font-size: 13px; text-align: center; color: black; background: #415994;">

        <b>{{i.MixingName}}</b>
      </div>
      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false" style="margin-left: 0; margin-right:0">

        <tbody>
          <tr>

            <td width="60%" style="text-align:left; border: 1px solid rgb(104, 102, 102);"><b>Item Name</b></td>
            <td style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>QTY</b></td>
            <td style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>SC QTY</b></td>
          </tr>
          <tr *ngFor="let data of i.MixingRawMaterial;let i=index">

            <td width="60%" style="border: 1px solid rgb(104, 102, 102);text-align: left;">{{ data.ProductName }}</td>
            <td style="text-align:center; border:1px solid rgb(104, 102, 102);">{{ data.Quantity }}</td>
            <td style="text-align:center; border:1px solid rgb(104, 102, 102);">{{ data.Scquantity }}</td>
          </tr>

        </tbody>
        <tfoot style="background: #d9d9d9;">
          <tr>

            <td width="60%" style="text-align:left; border: 1px solid rgb(104, 102, 102);"><b> WT. in GSM </b></td>
            <td style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>{{i.WeightGsm}}</b></td>
            <td style="text-align:center; border: 1px solid rgb(104, 102, 102);"></td>
          </tr>
        </tfoot>
      </nz-table>

    </div>



  </div>
  <nz-divider style="border: 1px solid rgb(168, 167, 167);"></nz-divider>
  <!--<div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">



    <div nz-col [nzSpan]="24" *ngFor="let i of Production.SaleOrderProduction.FormulationMixing">


     
      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false" style="margin-left: 18px; margin-right:18px;">

        <tbody>
         
          <tr>

            <td width="60%" style="text-align: left;">{{ i.ProductionMixingName }}</td>
            <td style="text-align:center; padding-left: 15px;">{{ i.WeightGsm }}</td>
          </tr>

        </tbody>
        
      </nz-table>

    </div>



  </div>-->
  <!--<div *ngIf="Production" nz-row [nzGutter]="24" style="margin: 0px 0px;">
    <div nz-col [nzSpan]="24">
      <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false" style="margin-left: 18px; margin-right:18px;">

        <tbody>
         
          <tr>

            <td width="60%" style="text-align:left;"><b>Final Wt(GSM)</b></td>
            <td style="text-align:center; padding-left: 15px;"><b>{{Production.SaleOrderProduction.FinalMixingGSMTotal}}</b></td>
          </tr>

        </tbody>

      </nz-table>
    </div>
    
  </div>-->


</div>
<app-loader></app-loader>
