import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LacquerRawMaterial, ProductionLacquerModel } from 'src/PmsUIApp/Models/ProductionModel';
import { ProductModel } from '../../../Models/ProductModel';
import { isNumber } from '@amcharts/amcharts5/.internal/core/util/Type';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';

@Component({
  selector: 'app-lacquer-list',
  templateUrl: './lacquer-list.component.html',
  styleUrls: ['./lacquer-list.component.css'],
})
export class LacquerListComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  LacquerList: ProductionLacquerModel[] = [];
  LacquerListOriginal: ProductionLacquerModel[] = [];
  LacquerListOriginal2: ProductionLacquerModel[] = [];
  NewLacquer: ProductionLacquerModel = new ProductionLacquerModel;
  productionRawMaterial: LacquerRawMaterial[] = [];
  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  searchValue = ''
  selectedProduct = 0
  selectedProductM = 0
  LacquerMasterId: number = 0;
  listOfSelectedproducts: number[] = [];
  Wastagetype = '%';
  NewOrderProductItem: any = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductId: 0

  };
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Lacquer';
  LacquerNameError = 'Enter Lacquer name';
  LacquerCodeError = 'Enter Lacquer code';
  exportoptions = {
    headers: ['LacquerName', 'Code', 'Description'],
  };
  fields: any;
  exportfields = [
    {
      LacquerName: '',
      Code: '',
      Description: '',
    },
  ];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    private auth: AuthService, private router: Router,
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Lacquer, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Lacquer, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Lacquer, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      LacquerName: [
        null,
        [Validators.required, Validators.minLength(3)],
        [this.LacquerNameAsyncValidator],
      ],
      Code: [null, [Validators.required], [this.LacquerCodeAsyncValidator]],
      Description: [null, [Validators.maxLength(200)]],
    });
    this.GetAllLacquer();
    this.GetAllProducts();
  }
  get f() {
    return this.validateForm.controls;
  }
  //get all lacquer details
  GetAllLacquer() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'lacquer/getalllacquers';
    this.http.get<ProductionLacquerModel[]>(url).subscribe(
      (res) => {
        this.LacquerList = res;
        this.LacquerListOriginal = res;
        this.LacquerListOriginal2 = res;
        this.exportfields = [];
        this.LacquerListOriginal.forEach((x) => {
          this.fields = {};
          this.fields.LacquerName = x.Name;
          this.fields.Code = x.Code;
          this.fields.Description = x.Description;
          this.exportfields.push(this.fields);
        });
        this.isTableLoading = false;

        //this.NewLacquer = res.filter(x => x.LacquerMasterId == this.LacquerMasterId)[0];
        //this.productionRawMaterial = this.NewLacquer?.LacquerRawMaterial;
        //this.productionRawMaterial?.forEach(x => {
        //  this.listOfSelectedproducts.push(x.ProductId);
        //});
        //this.calculateTotal();

      }, res => {
        this.count++;
        if (this.count < 2) { this.GetAllLacquer(); }
      });

  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search() {

    var res = this.LacquerListOriginal2;
    this.LacquerList = res.filter((item: ProductionLacquerModel) => {


      if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.WeightGsm == +this.searchValue
      ) {
        return true;
      }

      else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.LacquerMasterId == +this.searchValue
      ) {
        return true;
      } else if (
        item?.Code == this.searchValue
      ) {
        return true;
      }
      else if (
        item?.AddedDate?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else {
        let searchDate: any = new Date(this.searchValue);

        searchDate.setDate(searchDate.getDate() + 1);
        if (!isNaN(searchDate)) {
          searchDate = searchDate.toISOString().split('T')[0];
        }

        let splitDate = item.AddedDate.split('T')[0];

        return splitDate == searchDate ? true : false;
      }


    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  //get all product details
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res;
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }

  SaveLacquer() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;
      return;
    }
    const d = new Date();
    this.NewLacquer.Name = this.f['LacquerName'].value;
    this.NewLacquer.Code = this.f['Code'].value;
    this.NewLacquer.Description = this.f['Description'].value;
    this.NewLacquer.AddedDate = d.toLocaleTimeString();
    this.NewLacquer.LacquerRawMaterial = this.productionRawMaterial;
    this.NewLacquer.WeightGsm = this.NewLacquer.WeightGsm;
    this.NewLacquer.Total = this.NewLacquer.Total;
    if (this.NewLacquer.LacquerRawMaterial.length <= 0) {
      this.alertService.error('Add Products');
      return;
    }
    console.log(this.NewLacquer)
    let url;
    if (this.NewLacquer.LacquerMasterId == 0) {
      url = this.ApiUrl + 'lacquer/addlacquer';
    }
    else {
      url = this.ApiUrl + 'lacquer/updatelacquer';
    }

    this.http.post<ProductionLacquerModel>(url, this.NewLacquer).subscribe({
      next: (res) => {
        this.alertService.success('Lacquer Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.listOfSelectedproducts = [];
        this.productionRawMaterial = [];
        this.GetAllLacquer();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
  Validate(model: ProductionLacquerModel) {
    var Isvalid: boolean = true;
    if (model.Name == '') {
      this.alertService.error('Enter Lacquer Name');
      Isvalid = false;
    } else if (model.Code == '') {
      this.alertService.error('Enter Code');
      Isvalid = false;
    }

    return Isvalid;
  }
  OpenEditPop(data: ProductionLacquerModel) {
    this.PopUpTitle = 'Modify Lacquer Details';
    this.validateForm.setValue({
      LacquerName: data.Name,
      Code: data.Code,
      Description: data.Description,
    });
    this.productionRawMaterial = data.LacquerRawMaterial;;
    this.NewLacquer = new ProductionLacquerModel();
    this.listOfSelectedproducts = [];
    this.productionRawMaterial?.forEach(x => {
      this.listOfSelectedproducts.push(x.ProductId);
    });
    this.calculateTotal();
    this.NewLacquer.LacquerMasterId = data.LacquerMasterId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = 'Add New Lacquer';
    this.validateForm.reset();
    this.NewLacquer = new ProductionLacquerModel();

    this.isVisible = true;

  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveLacquer();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.listOfSelectedproducts = [];
    this.productionRawMaterial = [];
  }
  // validation for Name
  LacquerNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.LacquerList.filter(
          (x) => x.LacquerMasterId != this.NewLacquer.LacquerMasterId
        );
        var nre = res.filter(
          (x) => x.Name.toLowerCase() == control.value.toLowerCase()
        );
        this.LacquerNameError = '';

        if (control.value == '') {
          this.LacquerNameError = 'Enter Lacquer name';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0 && this.NewLacquer.LacquerMasterId == 0) {
          this.LacquerNameError = 'Lacquer name already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.LacquerNameError = 'Enter Lacquer name';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  // validation for Code
  LacquerCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.LacquerList.filter(
          (x) => x.LacquerMasterId != this.NewLacquer.LacquerMasterId
        );
        var nre = res.filter(
          (x) => x.Code.toLowerCase() == control.value.toLowerCase()
        );

        this.LacquerCodeError = '';
        if (control.value == '') {
          this.LacquerCodeError = 'Enter Lacquer code';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0 && this.NewLacquer.LacquerMasterId == 0) {
          this.LacquerCodeError = 'Lacquer code already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.LacquerCodeError = 'Enter Lacquer code';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  // UpdateProduct(data: any) {

  //   this.selectedProduct = data.ProductId;
  //   this.selectedProductM = data;
  //   this.NewOrderProductItem.CategoryID = data.ProductCategoryId;
  //   var pro = this.ProductList.filter(x => x.ProductId == this.selectedProduct)[0];

  //   var item = new LacquerRawMaterial();
  //   item.ProductId = pro.ProductId;
  //   item.ProductName = pro.ProductName;
  //   item.Unit = pro.Unit;
  //   item.ProductCode=pro.ProductCode;
  //   item.AvgGsm = pro.AvgGsm;
  //   item.Price = 0;
  //   item.Quantity = 0;
  //   this.productionRawMaterial.push(item)    
  //   }


  // bind product raw details on product selection   
  OnProductDdlchange() {
    if (this.listOfSelectedproducts.length < this.productionRawMaterial.length) {
      let filteredpid: number[] = [];
      this.productionRawMaterial.forEach(x => {
        filteredpid.push(x.ProductId);
      })
      let missing = filteredpid.filter(item => this.listOfSelectedproducts.indexOf(item) < 0)[0];
      var d = this.productionRawMaterial.filter(x => x.ProductId == missing)[0]
      this.productionRawMaterial = this.productionRawMaterial.filter(obj => obj !== d);
    }
    else {
      this.listOfSelectedproducts.forEach(item => {
        var checkexist = this.productionRawMaterial.filter(x => x.ProductId == item).length;
        if (checkexist == 0) {
          var product = this.ProductRawList.filter(x => x.ProductId == item)[0];
          var rawproduct = new LacquerRawMaterial;
          rawproduct.ProductId = item;

          rawproduct.ProductName = product.ProductName;
          rawproduct.ProductCode = product.ProductCode;
          rawproduct.Unit = product.Unit;
          rawproduct.Quantity = 0;
          rawproduct.AvgGsm = product.AvgGsm;
          if (this.productionRawMaterial.filter(x => x.ProductId == item).length == 0) {
            this.productionRawMaterial.push(rawproduct);
            this.NewLacquer.WeightGsm = 0;
            this.productionRawMaterial.forEach(x => {
              if (isNumber(x.AvgGsm))
                this.NewLacquer.WeightGsm += parseInt(x.AvgGsm)
            })
          }
        }

      })
    }
    this.calculateTotal();
  }

  handleRemoveRow(data: any) {

    this.productionRawMaterial = this.productionRawMaterial.filter(obj => obj !== data);
    this.calculateTotal();

  }

  calculateTotal() {
    this.NewLacquer.Total = this.NewLacquer.WeightGsm = 0;
    this.productionRawMaterial.forEach((element) => {
      this.NewLacquer.Total += parseFloat(element.Quantity.toString());
      this.NewLacquer.WeightGsm += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });
    this.OnWastagetypeDdlchange('')
  }
  OnWastagetypeDdlchange(data: string) {
    var total = 0;
    this.productionRawMaterial.forEach((element) => {
      total += parseFloat(element.Quantity.toString());

    });
    if (this.NewLacquer.WastageType == "%") {
      this.NewLacquer.Total = total - ((this.NewLacquer.Wastage * total) / 100);

    } else {
      this.NewLacquer.Total = total - this.NewLacquer.Wastage;
    }

  }
  handleDelete(data: ProductionLacquerModel) {
    this.NewLacquer = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Lacquer?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteLacquer(this.NewLacquer),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  DeleteLacquer(data: ProductionLacquerModel) {
    let url = this.ApiUrl + 'lacquer/deletelacquer';

    this.http.post<ProductionLacquerModel>(url, data).subscribe({
      next: (res) => {
        this.alertService.success('Lacquer Deleted Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllLacquer();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
}
