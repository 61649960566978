import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SaleOrderCostingParamsModel, SaleOrderModel } from "../Models/SalesOrderModel";

@Injectable({
    providedIn: 'root'
})

export class SaleOrderCostingService {
    private _loading = new BehaviorSubject<boolean>(false);
    public readonly loading$ = this._loading.asObservable();
    public SaleOrderId: number;
    public SaleOrderCosting: SaleOrderModel = null;
    public SaleOrderCostingViewParams: SaleOrderCostingParamsModel;
    event_callback: EventEmitter<SaleOrderCostingParamsModel> = new EventEmitter();
    show() {
        this.event_callback.emit(this.SaleOrderCostingViewParams);
        console.log(this.SaleOrderCostingViewParams)
        this._loading.next(true);
        return this.SaleOrderCosting;
    }
    hide() {
        this._loading.next(false);
    }
    setSaleOrderCosting(data: any) {
        this.SaleOrderCosting = data;
        console.log('In service', this.SaleOrderCosting);
        // localStorage.setItem('SaleOrderId:' + this.SaleOrderCosting.SaleOrderId.toString(),JSON.stringify(this.SaleOrderCosting));

    }
    getSaleOrderCosting() {
        return this.SaleOrderCosting;

    }


}
