<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Packaging Dispatch List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Packaging Dispatch here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" style="margin-right: 5px;"
          (click)="showSearchSection(); GetAllCustomer();GetAllFormulationCode();GetAllTransport()">
          {{this.searchBtnText}}
        </button>
        <button nz-button nzType="primary" *ngIf="this.permission.Add" routerLink="/home/dispatch/packaging/add">Add
          New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <div *ngIf="showsearch" nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="5">
            <label>Packing Number</label>
            <nz-input-group nzAddOnBefore="ZB/PCK/">
              <input type="text" nz-input [(ngModel)]="this.PackingNumber" maxlength="30" (keydown)="space($event)"
                placeholder="XXXX-XXXX/DP-XX" />
            </nz-input-group>
          </div>
          <div nz-col [nzSpan]="4">
            <label>Customer</label>
            <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
              [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                [nzLabel]="s.CustomerName"></nz-option>
            </nz-select>
          </div>
          <!-- <div nz-col [nzSpan]="4">
            <label>Formulation Code</label>
            <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
              nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                [nzLabel]="s.SaleFormulationCode"></nz-option>
            </nz-select>
          </div>
          <div nz-col [nzSpan]="4">
            <label>Mini Roll Code</label>
            <input nz-input nzAllowClear placeholder="ZJ-XXXXX/X" maxlength="30" [(ngModel)]="request.RollCode"
              (keydown)="space($event)" />
          </div>
          <div nz-col [nzSpan]="4">
            <label>Jumbo Number</label>
            <input nz-input name="salesOrderNo" nzAllowClear placeholder="ZJ-XXXXX" maxlength="30"
              [(ngModel)]="request.JumboNumber" (keydown)="space($event)" />
          </div> -->
          <div nz-col [nzSpan]="5">
            <label>Transport</label>
            <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
              nzAllowClear [(ngModel)]="request.TransportId" nzPlaceHolder="Choose"
              (ngModelChange)="onSelectedTransportChange($event)">
              <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                [nzLabel]="s.TransportCompanyName"></nz-option>
            </nz-select>
          </div>
          <div nz-col [nzSpan]="4">
            <label>Vehicle</label>
            <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
              nzAllowClear [(ngModel)]="request.VehicleId" nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId"
                [nzLabel]="s.VehicleNumber"></nz-option>
            </nz-select>
          </div>
          <!-- <div nz-col [nzSpan]="4">
            <label>Sale Order No.</label>
            <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
              [(ngModel)]="request.SaleOrderNumber" (keydown)="space($event)" />
          </div> -->
          <div nz-col [nzSpan]="4">
            <label>Dispatch Created By</label>
            <input nz-input name="" nzAllowClear placeholder="Enter Username" maxlength="30"
              [(ngModel)]="request.AddedBy" (keydown)="space($event)" />
          </div>
          <div nz-col [nzSpan]="4">
            <label>Remark</label>
            <input nz-input name="remark" nzAllowClear placeholder="Remark" maxlength="30" [(ngModel)]="request.Remark"
              (keydown)="space($event)" />
          </div>
          <div nz-col [nzSpan]="4">
            <label> Dispatch Date (From)</label>
            <nz-date-picker name="DispatchDateFrom" class="form-control" [(ngModel)]="request.DispatchDateFrom"
              nzAllowClear></nz-date-picker>
          </div>
          <div nz-col [nzSpan]="4">
            <label> Dispatch Date (To)</label>
            <nz-date-picker name="DispatchDateTo" class="form-control" [(ngModel)]="request.DispatchDateTo"
              nzAllowClear></nz-date-picker>
          </div>
        </div>

        <div style="float: right; margin-bottom: 5px; margin-right: 10px">
          <div style="display: flex">
            <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="SearchSubmit()">
              Search Submit
            </button>
            <button nz-button nzSize="small" (click)="SearchReset()">Search Reset</button>
          </div>
        </div>
      </div>
    </div>
    <nz-divider></nz-divider>
    <nz-table nzSize="small" [nzPageSize]="10" [nzScroll]="{ x: '1200px', y: '515px' }" #basicTable
      [nzData]="this.JumboDispatchList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
      nzShowSizeChanger [nzPageSizeOptions]=[10,20,50,100,500] [nzShowTotal]="totalTemplate">
      <thead>
        <tr>
          <th nzWidth="60px" nzLeft>S. No.</th>
          <th nzWidth="220px">Packing Number</th>
          <th nzWidth="180px">Dispatch Date</th>
          <th nzWidth="200px">Dispatch Number</th>
          <th nzWidth="100px">Quantity</th>
          <th nzWidth="100px">Status</th>
          <th nzWidth="250px">Transport</th>
          <th nzWidth="120px">Vehicle</th>
          <th nzWidth="250px">Customer</th>
          <th nzWidth="250px">Store</th>
          <th nzWidth="250px">Rack</th>
          <th nzWidth="100px">Weight</th>
          <th nzWidth="250px">Barcode</th>
          <th nzWidth="250px">Added By</th>
          <th nzRight nzWidth="100px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data;">
          <td nzLeft>{{ data.SerialNo }}</td>
          <td *ngIf="data.DispatchStatus == 'Created'"> <a class="btn-link" (click)="showDispatchModal(data)">{{
              data.PackingNumber }} </a> </td>
          <td *ngIf="data.DispatchStatus == 'Cancelled'">{{ data.PackingNumber }} </td>
          <td>{{ data.DispatchDate.toString() | DatetimeConverter }}</td>
          <td>{{ data.DispatchNumber }}</td>
          <td *ngIf="data.DispatchStatus == 'Created'"> <a class="btn-link" (click)="showDispatchModal(data)">{{
              data.DispatchQuantity }} </a></td>
          <td *ngIf="data.DispatchStatus == 'Cancelled'">{{ data.DispatchQuantity }}</td>
          <td>{{ data.DispatchStatus }}</td>
          <td>{{ data.TransportName }} </td>
          <td>{{ data.VehicleNumber }} </td>
          <td>{{ data.CustomerDetails[0]?.CustomerName }} </td>
          <td>{{ data.StoreName }} </td>
          <td>{{ data.RackName }} </td>
          <td>{{ data.Weight }} </td>
          <td>{{ data.Barcode }} </td>
          <td>{{ data.AddedBy }} </td>
          <td nzRight>
            <div *ngIf="data.DispatchQuantity > 0">
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li nz-menu-item (click)="showDispatchModal(data)">View Dispatch Jumbo</li>
                  <li nz-menu-item *ngIf="EnableEditButtons(data)"
                    routerLink="/home/dispatch/packaging/edit/{{data.JumboDispatchId}}">Add More Rolls</li>
                  <li nz-menu-item *ngIf="EnableEditButtons(data)" (click)="showDispatchModal(data)">Remove Rolls</li>
                  <li nz-menu-item (click)="Print(data)" *ngIf="EnablePrintButton(data)">Print</li>
                  <li nz-menu-item (click)="email(data.JumboDispatchId)" *ngIf="EnableEmailButton(data)">Email Packaging List</li>
                  <li nz-menu-item (click)="handleAddTransport(data)"
                    *ngIf="data.IsGateIn!=true && this.permission.AddTransport">Add Transport</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisible" [nzStyle]="{ top: '20px' }" [nzTitle]="modalTitle" [nzContent]="modalContent"
  [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>Add Transport</ng-template>

  <ng-template #modalContent>
    <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="2">

      <nz-descriptions-item nzTitle="Transport">
        <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedTransportChange($event)"
          [(ngModel)]="NewDispatch.TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
            [nzLabel]="s.TransportCompanyName"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Vehicle">
        <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default" [(ngModel)]="NewDispatch.VehicleId"
          (ngModelChange)="onSelectedVehicleChange($event)" nzAllowClear nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId"
            [nzLabel]="s.VehicleNumber"></nz-option>
          <nz-option nzValue="0" nzLabel="Others"></nz-option>
        </nz-select>

      </nz-descriptions-item>
    </nz-descriptions>
    <span style="color: crimson;"><b>{{this.VehicleStatus}}</b></span>
  </ng-template>

  <ng-template #modalFooter>

    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="SaveDispatchItems()" [nzLoading]="isLoading">Save</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="isDispatchVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="1150" [nzTitle]="modalTitleDispatch"
  [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch" (nzOnCancel)="handleDispatchCancel()">
  <ng-template #modalTitleDispatch>Jumbo Dispatch</ng-template>

  <ng-template #modalContentDispatch>
    <nz-table nzSize="small" *ngIf="this.JumboInspection.length>0" [nzPageSize]="10" style="width: 100%;" #basicTable
      nzShowPagination="true" [nzData]="JumboInspection" nzBordered [nzShowTotal]="totalTemplate">
      <thead>
        <tr>
          <th nzWidth="50px"></th>
          <th nzWidth="50px"> <input style="margin-right: 5px" type="checkbox" [checked]="IsCheckedAll"
              (change)="IsCheckedAll = !IsCheckedAll; CheckAll()" />
          </th>
          <th nzWidth="150px">Sale Order No.</th>
          <th nzWidth="150px">Mini Roll Code</th>
          <th nzWidth="150px">Grade</th>
          <th nzWidth="150px">Quantity</th>
          <th nzWidth="150px">Weight</th>
          <th nzWidth="150px">Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data;let i = index">
          <td>{{data.SerialNo}}</td>
          <td>
            <input style="margin-right: 5px" type="checkbox" [checked]="data.IsChecked"
              (change)="data.IsChecked = !data.IsChecked;" [disabled]="DisableRollSelection(data)" />
          </td>
          <td>{{ data.SaleOrderNumber}}</td>
          <td title='Created on {{data.AddedDate | DatetimeConverter }}'>{{ data.Code}}</td>
          <td>{{ data.Grade}}</td>
          <td>{{ data.Quantity}}</td>
          <td>{{ data.Weight}}</td>
          <td>{{ data.RollType}}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooterDispatch>
    <button nz-button nzType="primary" nzDanger
      [disabled]="DisableRemoveRollsBtn(this.JumboInspection[0].JumboDispatchId)" (click)="handleRemove()">Remove
      Selected Rolls</button>
    <button nz-button nzType="primary"
      routerLink="/home/dispatch/packaging/edit/{{this.JumboInspection[0].JumboDispatchId}}"
      [disabled]="!this.permission.Add">Add More Rolls</button>
    <button nz-button nzType="default" (click)="handleDispatchCancel()">Cancel</button>
  </ng-template>
</nz-modal>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>