import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { ProformaInvoiceModel } from '../../../Models/ProformaModel';
import { Router } from '@angular/router';
import { AuthService } from '../../../Services/auth.service';
import { Modules, Responsibility } from '../../../Models/Enums';
import moment from 'moment';
import { PIDrawerService } from 'src/PmsUIApp/Services/PIDrawerService';

@Component({
  selector: 'app-proformalist',
  templateUrl: './proformalist.component.html',
  styleUrls: ['./proformalist.component.css'],
})
export class proformaListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  ProformaInvoiceList: ProformaInvoiceModel[] = [];
  ProformaInvoiceListOriginal: ProformaInvoiceModel[] = [];
  ProformaInvoice!: ProformaInvoiceModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New PurchaseOrder';
  searchValue = '';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  visible = false;
  error: any = { isError: false, errorMessage: '' };
  exportoptions = {
    headers: [
      'ProformaInvoiceId',
      // 'Customer Name',
      'CountryOfOrigin',
      'ProformaInvoiceDate',
      'MaterialType',
      'Hsncode',
      'PortOfLoading',
      'ModeOfTransport',
      'FinalDestination ',
      ' PortOfDischarge ',
      'Gst',
      'Added Date'

    ],
  };
  fields: ProformaInvoiceModel = new ProformaInvoiceModel();
  exportfields: ProformaInvoiceModel[] = [];
  isValidDate: any;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  IsPIDrawerOpen = false;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private router: Router,
    private auth: AuthService,
    private PIDrawer:PIDrawerService 
  ) { }

  ngOnInit() {
    // this.myDateValue = new Date('12-08-2019');
    this.permission.View = this.auth.CheckResponsibility(Modules.SalesOrderProforma, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.SalesOrderProforma, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.SalesOrderProforma, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    console.log(this.permission)
    this.GetAllProformaInvoice();
  }

  GetAllProformaInvoice() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'proformainvoice/getallproformainvoices';

    this.http.get<ProformaInvoiceModel[]>(url).subscribe(
      (res) => {
        console.log(`res`, res)
        this.ProformaInvoiceListOriginal = res;
        this.ProformaInvoiceList = this.ProformaInvoiceListOriginal;
        this.isTableLoading = false;
        this.exportfields = [];
        this.ProformaInvoiceListOriginal.forEach((x: any) => {
          this.fields = new ProformaInvoiceModel()
          this.fields.ProformaInvoiceId = x.ProformaInvoiceId
          //  this.fields.Customer.CustomerName=x.Customer.CustomerName
          this.fields.CountryOfOrigin = x.CountryOfOrigin
          this.fields.ProformaInvoiceDate = x.ProformaInvoiceDate
          this.fields.MaterialType = x.MaterialType
          this.fields.Hsncode = x.Hsncode
          this.fields.PortOfLoading = x.PortOfLoading
          this.fields.ModeOfTransport = x.ModeOfTransport
          this.fields.FinalDestination = x.FinalDestination
          this.fields.PortOfDischarge = x.PortOfDischarge
          this.fields.Gst = x.Gst
          this.fields.AddedDate = x.AddedDate
          this.exportfields.push(this.fields);

        })


        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProformaInvoice();
        }
      }
    );
  }
  OpenViewPop(data: any) {
    this.isVisible = true;

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {
      console.log(event);
      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  handleOk(): void {

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'purchaseorder-export' + exportdate,
        this.exportoptions
      );
  }
  search(): void {
    var res = this.ProformaInvoiceListOriginal


    this.ProformaInvoiceList = res.filter((item: ProformaInvoiceModel) => {
      if (item?.ProformaInvoiceNumber?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true
      }
      else if (item?.Customer?.CustomerName?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.CountryOfOrigin?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.MaterialType?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.Hsncode?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.PortOfLoading?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.ModeOfTransport?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.FinalDestination?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.PortOfDischarge?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      }
      else if (item?.Gstn == this.searchValue) {
        return true;
      }
      else if (item?.AddedDate?.toLowerCase().includes(this.searchValue?.toLowerCase())) {
        return true;
      } else {
        let searchDate: any = new Date(this.searchValue);

        searchDate.setDate(searchDate.getDate() + 1);
        if (!isNaN(searchDate)) {
          searchDate = searchDate.toISOString().split('T')[0];
        }

        let splitDate = item.AddedDate.split('T')[0];

        return splitDate == searchDate ? true : false;
      }
      return false;
    })
  }
  View(data: ProformaInvoiceModel) {
    window.open(`${window.location.origin}/proformaPrint/` + data.ProformaInvoiceId);
  }
  Edit(data: ProformaInvoiceModel) {

    this.router.navigate(['/home/salesorder/proformaInvoice/edit/' + data.ProformaInvoiceId]);

  }
  OpenPIIDrawer(data: any): void {
    this.IsPIDrawerOpen = false;
    this.PIDrawer.ProformaInvoiceId = data.ProformaInvoiceId;
    this.PIDrawer.show();

  }
}
