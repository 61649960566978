import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductAllocationModel, StockProductManageRejectedModel, StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { TransportModel, TransportVehicleModel } from 'src/PmsUIApp/Models/TransportModel';
import { RackModel, StoreModel } from '../../Models/MasterModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';

@Component({
  templateUrl: './StockManageRejectedItems.component.html'
})
export class StockManageRejectedItemsComponent {
  id: number = 0;
  isPageLoading: boolean = false;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  NewStockProduct: StockProductModel = new StockProductModel;
  FilteredProductList: ProductModel[] = [];
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];
  TransportList: TransportModel[] = [];
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel;
  NewInvoice: InvoiceModel = new InvoiceModel;
  NewManageRejectItem: StockProductManageRejectedModel = new StockProductManageRejectedModel;
  TransportVehicleList: TransportVehicleModel[] = [];
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreID: number = 0;
  RackID: number = 0;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private alertService: AlertMessageService,
    private auth: AuthService) { }


  GetStockDetails() {
    this.isPageLoading = true
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe(res => {
      this.NewStock = res;

      this.isPageLoading = false;
      this.NewStock.StockProduct.forEach((nos) => {
        nos.ExpandRecord = false;
      })


    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetStockDetails();
      }
    });
  }

  GetAllTransport() {
    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe({
      next: res => { this.TransportList = res; },
      error: res => { this.alertService.error("An error has been occured. Please try again"), this.GetAllTransport(); },
    });
  }
  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllStore();
      }
    });
  }

  onSelectedTransportChange($event: number) {



    var StockProductRejectedDispatch = this.TransportList.filter(x => x.TransportId == $event)[0];

    this.TransportVehicleList = StockProductRejectedDispatch.TransportVehicle;
    this.NewManageRejectItem.StockProductRejectedDispatch.VehicleId = 0;

  }
  onSelectedStoreChange() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res.filter(x => x.StoreId == this.NewManageRejectItem.StoreId);
      this.RackID = 0;
    }, res => { });
  }

  AddManageRejectItem(spl: StockProductModel) {
    spl.NewRecord = true;
    this.NewManageRejectItem = new StockProductManageRejectedModel();
  }

  SaveManageRejectItem(spl: StockProductModel) {
    if (spl.StockProductManageRejected == undefined) {
      spl.StockProductManageRejected = [];
    }
    if (this.NewManageRejectItem.Quantity <= 0) {
      this.alertService.error("Enter valid Quantity ")
      return
    }
    if (this.NewManageRejectItem.ItemAction == '') {
      this.alertService.error("Select Action")
      return
    }
    if (this.NewManageRejectItem.ItemAction == "Dispatch") {
      if (this.NewManageRejectItem.StockProductRejectedDispatch.TransportId == null) {
        this.alertService.error("Select Transport")
        return
      }
      if (this.NewManageRejectItem.StockProductRejectedDispatch.VehicleId == null) {
        this.alertService.error("Select Vehicle")
        return
      }

    }
    var totalqtyRejected = spl.StockProductManageRejected.reduce((accumulator, current) => {
      return parseInt(accumulator.toString()) + parseInt(current.Quantity.toString());
    }, 0);
    totalqtyRejected += parseInt(this.NewManageRejectItem.Quantity.toString());
    if ((totalqtyRejected) > (spl.RejectedQuantity)) {
      this.alertService.error("Total Quantity cannot more than " + spl.RejectedQuantity)
      return
    }
    spl.NewRecord = false;

    this.NewManageRejectItem.StockProductId = spl.StockProductId;
    if (this.NewManageRejectItem.ItemAction == "Dispatch") {
      this.NewManageRejectItem.StockProductRejectedDispatch.TransportCompany = this.TransportList.filter(x => x.TransportId == this.NewManageRejectItem.StockProductRejectedDispatch.TransportId)[0].TransportCompanyName;
      this.NewManageRejectItem.StockProductRejectedDispatch.VehicleNumber = this.TransportVehicleList.filter(x => x.VehicleId == this.NewManageRejectItem.StockProductRejectedDispatch.VehicleId)[0].VehicleNumber;
    }
    spl.StockProductManageRejected.push(this.NewManageRejectItem);

    this.NewManageRejectItem = new StockProductManageRejectedModel();
  }

  SaveManageRejectItemtoDB(spl: StockProductModel) {
    {
      let url = this.ApiUrl + "stock/stockmanagerejected";
      this.http.post<StockProductModel>(url, spl).subscribe({
        next: res => {
          this.alertService.success("Stock allocated Successfully")
          this.GetStockDetails();
        },
        error: res => { this.alertService.error("An error has been occured. Please try again") },
      });
    }
  }

  RemoveManageRejectItem(spl: StockProductModel, alloc: StockProductManageRejectedModel) {
    var index = spl.StockProductManageRejected.indexOf(alloc);
    if (index !== -1) {
      spl.StockProductManageRejected.splice(index, 1);
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Rejected, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Rejected, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Rejected, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.GetStockDetails();
    this.GetAllTransport();
    this.GetAllStore();
  }
  filterRejected(item: StockProductAllocationModel) {
    return item.InspectionType == 'Rejected'
  }
  validateModel(item: StockProductAllocationModel, tb: number) {
    if (isNaN(item.AcceptQTY)) {
      item.AcceptQTY = 0;
    }
    if (isNaN(item.DispatchQTY)) {
      item.DispatchQTY = 0;
    }
    if (isNaN(item.DisposeQTY)) {
      item.DisposeQTY = 0;
    }
    var tqty = parseInt(this.checkNumber(item.AcceptQTY).toString()) + parseInt(this.checkNumber(item.DispatchQTY).toString()) + parseInt(this.checkNumber(item.DisposeQTY).toString())
    if (isNaN(tqty)) {
      if (tb == 1) {
        item.AcceptQTY = 0;
      }
      else if (tb == 2) {
        item.DisposeQTY = 0;
      }
      else if (tb == 3) {
        item.DispatchQTY = 0;
      }
    }
    console.log(tqty)
    if (tqty > item.Quantity) {
      this.alertService.error("Total Quantity cannot more than " + item.Quantity)
      if (tb == 1) {
        item.AcceptQTY = 0;
      }
      else if (tb == 2) {
        item.DisposeQTY = 0;
      }
      else if (tb == 3) {
        item.DispatchQTY = 0;
      }
    }

  }
  checkNumber(x: number) {
    if (x == null) {
      return 0
    }
    else if (x === null) {
      return 0
    } else if (typeof x === 'undefined') {
      return 0
    } else
      return x
  }
  SaveManageRejectItems(spl: StockProductModel) {
    {
      console.log(spl);
      var request: StockProductManageRejectedModel[] = [];
      spl.StockProductAllocation.forEach(el => {
        var tqty = parseInt(this.checkNumber(el.AcceptQTY).toString()) + parseInt(this.checkNumber(el.DispatchQTY).toString()) + parseInt(this.checkNumber(el.DisposeQTY).toString())
        if (tqty <= el.Quantity) {
          //console.log(el);
          if (el.AcceptQTY > 0) {
            var item: StockProductManageRejectedModel = new StockProductManageRejectedModel();
            item.ItemAction = "Accept";
            item.StockProductId = spl.StockProductId;
            item.Quantity = el.AcceptQTY;
            item.ToRackId = el.RackId;
            item.FromRackId = el.RackId;
            request.push(item);
          }
          if (el.DisposeQTY > 0) {
            var item: StockProductManageRejectedModel = new StockProductManageRejectedModel();
            item.ItemAction = "Dispose";
            item.Quantity = el.DisposeQTY;
            item.ToRackId = el.RackId;
            item.FromRackId = el.RackId;
            item.StockProductId = spl.StockProductId;
            request.push(item);
          }
          if (el.DispatchQTY > 0) {
            var item: StockProductManageRejectedModel = new StockProductManageRejectedModel();
            item.ItemAction = "Dispatch";
            item.Quantity = el.DispatchQTY;
            item.ToRackId = el.RackId;
            item.FromRackId = el.RackId;
            item.StockProductId = spl.StockProductId;
            item.StockProductRejectedDispatch.TransportId = el.TransportId;
            item.StockProductRejectedDispatch.VehicleId = el.VehicleId;
            item.StockProductRejectedDispatch.DispatchId = el.DispatchId;
            request.push(item);
          }
        }
      })
      console.log(request);
      if (request.length > 0) {
        let url = this.ApiUrl + "stock/stockmanagerejected";
        this.http.post<any>(url, request).subscribe({
          next: res => {
            this.alertService.success(res)
            this.GetStockDetails();
          },
          error: res => { this.alertService.error(res) },
        });
      }

      else {
        this.alertService.error("Invalid request")
      }

    }
  }
}
