import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { ActiveX } from 'src/environments/environment';
import { Router } from '@angular/router';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
    constructor(private msalService: MsalService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({ headers }));
        }
        const now = new Date();
        var expiresOn = new Date(this.msalService.instance.getActiveAccount()?.idTokenClaims?.exp * 1000)
        const refreshTime = new Date(expiresOn.setMinutes(expiresOn.getMinutes() - 5));

        if (now > refreshTime) {
            const accessTokenRequest = {
                scopes: ["openid", `${ActiveX.clientId}/.default`],
                loginHint: localStorage.getItem('userName') || "",
                //forceRefresh: true
            }
            // Refresh the token using acquireTokenSilent
            return from(this.msalService.acquireTokenSilent(accessTokenRequest)).pipe(
                mergeMap((accessToken: AuthenticationResult) => {
                    // Update the token in local storage
                    localStorage.setItem('token', accessToken.accessToken);

                    // Clone the request and update the headers
                    const clonedRequest = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken.accessToken}`
                        }
                    });

                    // Process the updated request
                    return next.handle(clonedRequest);
                }),
                catchError((error: InteractionRequiredAuthError) => {
                    console.error('Token refresh failed', error);

                    if (error.errorCode === 'interaction_required' || error.errorCode === 'login_required') {
                        // Redirect user for interactive login
                        let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
                        msalInstance["browserStorage"].clear();
                        this.router.navigate(['/login']);
                    }

                    // Correct usage of throwError
                    return throwError(() => error);
                })
            );
        }
        else {
            const itemStr = localStorage.getItem("token") ?? ""
            const authRequest = req.clone({
                setHeaders: {
                    'authorization': 'Bearer ' + itemStr
                }
            })
            return next.handle(authRequest);
        }
    }
}
