<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Final Inspection</nz-page-header-title>

    </nz-page-header>
    <nz-divider></nz-divider>



    <div nz-col [nzSpan]="24">

      <nz-form-item *ngIf="this.Type=='Production'">

        <nz-form-control [nzSpan]="7" [hidden]="this.DisableSearchOptions" nzErrorTip="Enter Element">
          <label><b>Select Workplan</b></label>
          <nz-select class="form-select" nzShowSearch name="WorkPlanNo" [(ngModel)]="SelectedWorkPlan" nzSize="default"
            [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose"
            (ngModelChange)="OnWorkPlanChange()">
            <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">

        <nz-form-control [nzSpan]="7" [hidden]="this.DisableSearchOptions" nzErrorTip="Enter Element"
          *ngIf="this.Type=='Production'">
          <label><b> Select Sales Order</b></label>
          <nz-select class="form-select" nzShowSearch name="SelectedOrder" [(ngModel)]="SelectedOrderNo"
            [ngModelOptions]="{standalone: true}" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
            (ngModelChange)="OnWorkPlanOrderChange()">
            <nz-option *ngFor="let s of SelectedWorkPlanOrder;" [nzValue]="s.SaleOrder.SaleOrderId"
              [nzLabel]="s.SaleOrder.SaleOrderNumber"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div style="padding: 8px;float:right">
        <input type="text" style="width: 170px;margin-right: 8px;" nz-input placeholder="ZJ-XXXXX"
          [(ngModel)]="JumboNumber" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
        <button nz-button nzSize="small" nzType="primary" (click)="GetWorkplanDetailsbyJumbo()"
          style="margin-right: 8px;">Search Jumbo</button>
      </div>
      <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">

        <nz-form-control [nzSpan]="8" [hidden]="this.DisableSearchOptions" nzErrorTip="Enter Element"
          *ngIf="this.Type=='Production'">
          <label><b>Select Jumbo</b></label>
          <nz-select class="form-select" nzShowSearch name="SelectedJumbo" [(ngModel)]="SelectedJumboNo"
            (ngModelChange)="CalculateActualQty()" [ngModelOptions]="{standalone: true}" nzSize="default" nzAllowClear
            nzPlaceHolder="Choose">
            <nz-option *ngFor="let s of jumboTableData;" [nzValue]="s" [nzLabel]="s.JumboNo"></nz-option>
          </nz-select>
          <!-- <b>
            <label style="margin:10px" nz-checkbox [(ngModel)]="JIModel.IsSampleJumbo"><b> Is Sample Jumbo</b></label>
          </b> -->
        </nz-form-control>
      </nz-form-item>

      <button style="margin-top:10px;margin-bottom: 25px;" class="btn btn-sm btn-light-success"
        (click)="CompleteInspection()" [hidden]="SelectedJumboNo == null" [disabled]="SelectedJumboNo == null">Complete
        Inspection for Selected Jumbo</button>


      <!-- <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search Jumbo...." [(ngModel)]="searchValue" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
    </div> -->
      <div *ngIf="this.SaleOrder" nz-row style="margin: 0px 0px;">
        <div nz-col [nzSpan]="24">
          <!--<nz-descriptions nzBordered nzLayout="vertical" *ngIf="SaleOrder?.SaleOrderProduction?.SaleOrderProductionId>0" nzSize="small"
                       [nzColumn]="8">

        <nz-descriptions-item nzTitle="Inspection Quantity">
          <input type="text" nz-input
                 [(ngModel)]="DispatchReadyBarcode" *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'" />
          <span>{{SelectedJumboNo?.ActualQuantity}}</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Formulation Code">{{SaleOrder.SaleFormulationCode}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Customer Name">{{SaleOrder.CustomerName}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Quantity">
          {{SaleOrder.SaleOrderProduction.OrderQuantity}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="MFG Product">{{SaleOrder.SaleOrderProduction.ManufacturingProductName}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="MFG.Quantity">
          {{SaleOrder.SaleOrderProduction.ManufacturingQuantity}}
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Product Name">
          {{SaleOrder.FormulationFabricProductName}}
        </nz-descriptions-item>

      </nz-descriptions>-->
          <nz-descriptions nzBordered nzLayout="vertical" *ngIf="SaleOrder.SaleOrderProduction" nzSize="small"
            [nzColumn]="5">
            <nz-descriptions-item nzTitle="Inspection Quantity">
              <input type="text" nz-input [(ngModel)]="DispatchReadyBarcode"
                *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'" />
              <span><b>{{SelectedJumboNo?.ActualQuantity}}</b></span>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true">
              <b>
                {{
                SaleOrder.SaleFormulationCode
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Customer Name"><b>{{SaleOrder.CustomerName}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ SaleOrder.Category }}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Thick">
              <b>
                {{
                SaleOrder.SaleOrderProduction.ThicknessValue
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Width">
              <b>
                {{
                SaleOrder.SaleOrderProduction.WidthNumber
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
              <b>
                {{
                SaleOrder.SaleOrderProduction.ManufacturingProductName
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="MFG.Quantity">
              <b> {{SaleOrder.SaleOrderProduction.ManufacturingQuantity}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Order Quantity">
              <b>
                {{
                SaleOrder.SaleOrderProduction.OrderQuantity
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Sale Price">
              <b>
                {{
                SaleOrder.SaleOrderProduction.SalePrice
                }}
              </b>
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Grain Code">
              <b>
                {{
                SaleOrder.SaleOrderProduction.GrainCode
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Color">
              <b>
                {{
                SaleOrder.SaleOrderProduction.ColorName
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Fabric Color">
              <b>
                {{
                SaleOrder.SaleOrderProduction.FabricColorName
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Fabric Name">
              <b>
                {{
                SaleOrder.FormulationFabricProductName
                }}
              </b>
            </nz-descriptions-item>
          </nz-descriptions>

          <div *ngIf="SelectedOrderNo!=''">
            <nz-divider nzText="Grading"></nz-divider>
            <div class="row gx-10 mb-6">
              <div class="col-lg-2">
                <label class="required form-label">Grade</label>
                <nz-select class="form-select mb-2" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                  [(ngModel)]="JIModel.Grade">
                  <nz-option nzValue="1st" nzLabel="1st"></nz-option>
                  <nz-option nzValue="A" nzLabel="A"></nz-option>
                  <nz-option nzValue="LOT" nzLabel="LOT"></nz-option>
                  <nz-option nzValue="NS" nzLabel="NS"></nz-option>
                  <nz-option nzValue="CUT-PC" nzLabel="CUT-PC"></nz-option>
                  <nz-option nzValue="FILM" nzLabel="FILM"></nz-option>
                  <nz-option nzValue="WASTE" nzLabel="WASTE"></nz-option>
                  <nz-option nzValue="DIRECT" nzLabel="DIRECT"></nz-option>
                  <nz-option nzValue="N/A" nzLabel="N/A"></nz-option>
                </nz-select>
              </div>

              <div class="col-lg-1">
                <label class="required form-label">Quantity</label>
                <input type="text" class="form-select mb-2" nz-input [(ngModel)]="JIModel.Quantity" name="Quantity"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />

              </div>
              <div class="col-lg-2">
                <label class="required form-label">Unit</label>
                <nz-select class="form-select mb-2" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                  [(ngModel)]="JIModel.Unit">
                  <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
                </nz-select>
              </div>
              <div class="col-lg-2">
                <label class=" form-label">Weight(Kgs)</label>
                <input type="text" class="form-select mb-2" nz-input [(ngModel)]="JIModel.Weight"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
              </div>
              <div class="col-lg-2">
                <label class="required form-label">Inspected By</label>
                <input type="text" class="form-select mb-2" nz-input [(ngModel)]="JIModel.InspectedBy" />
              </div>
              <div class="col-lg-3">
                <p style="margin-top:26px;">
                  <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="AddSaleOrderDispatchItems()">
                    ADD
                  </button>
                  <b> <label style="position:relative; left: 30px;" nz-checkbox [(ngModel)]="JIModel.IsSampleJumbo"><b>
                        Sample</b></label></b>
                  <button nz-button nzSize="large" nzType="primary"
                    (click)="inspectionprintAll(this.NewWorkPlan?.JumboInspection[0],this.NewWorkPlan.SaleOrderId)"
                    style="position: absolute; right: 0;">
                    Print All
                  </button>
                </p>

              </div>

              <nz-table *ngIf="NewWorkPlan.JumboInspection" nzSize="small" style="width: 100%;" #basicTable nzBordered
                [nzData]="[{}]" nzShowPagination="false">
                <thead>

                  <tr>
                    <th nzWidth="5%">S.No</th>
                    <th nzWidth="10%">Code</th>
                    <th nzWidth="10%">Grade</th>
                    <th nzWidth="10%"> Quantity</th>
                    <th nzWidth="10%"> Unit</th>
                    <th nzWidth="10%"> **Weight</th>
                    <th nzWidth="10%"> Inspected By</th>
                    <th nzWidth="10%"> Type</th>
                    <th nzWidth="18%">*Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th nzWidth="40%"></th>
                    <th nzWidth="20%"></th>
                    <th nzWidth="20%"></th>
                    <th nzWidth="20%"></th>
                    <th nzWidth="20%"></th>
                    <th nzWidth="20%"></th>
                  </tr>
                  <tr *ngFor="let data of NewWorkPlan.JumboInspection;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{data.Code}}</td>
                    <td>{{data.Grade}}</td>
                    <td>{{data.Quantity}}</td>
                    <td>{{data.Unit}}</td>
                    <td>
                      <div class="editable-cell" [hidden]="weightEditId === data.JumboInspectionId"
                        (click)="StartWeightEdit(data.JumboInspectionId)">
                        {{data.Weight}}
                      </div>
                      <input [hidden]="weightEditId !== data.JumboInspectionId" type="number" nz-input
                        [(ngModel)]="data.Weight" />
                    </td>
                    <td>{{data.InspectedBy}}</td>
                    <td>{{data.RollType}}</td>
                    <td style="text-align: center">
                      <button class="btn btn-sm btn-light-primary"
                        *ngIf="data.enableWeightSave && weightEditId === data.JumboInspectionId"
                        (click)="SaveWeight()">Save Weight</button> &nbsp;
                      <button class="btn btn-sm btn-light-primary" [hidden]="hideRemoveButton(data.DispatchStatus)"
                        (click)="handleRemoveRow(data)">Remove</button> &nbsp;
                      <button class="btn btn-sm btn-light-primary"
                        (click)="inspectionprint(data,this.NewWorkPlan.SaleOrderId)">Print</button>
                    </td>

                  </tr>

                </tbody>
                <tbody>
                  <tr *ngIf="this.NewWorkPlan.JumboInspection.length>0" style="font-weight: bold;">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td>{{totalRollSize}}</td>
                    <td></td>
                    <td>{{totalRollWeight}}</td>
                    <td colspan="4"></td>
                  </tr>
                </tbody>
              </nz-table>
              <br>
              <br>
              <div><b>* Items cannot be removed once dispatch packaging is created for it. Remove button will be
                  disabled for it.</b></div>
              <div><b>** Click on Weight cell in the table above to edit the Weight and click on Save Weight button to
                  complete the update.</b></div>
              <nz-divider></nz-divider>
              <!-- <div>
      <label>Remaining QTY :</label>
      <input type="text">
      <br>
      <label>Yield(%):</label>
      <input type="text">
    </div> -->
              <!-- <nz-form-control [nzSpan]="4" nzErrorTip="Enter Element"> -->
              <!-- <label><b>Remaining QTY :</b></label> -->
              <div>
                <b><label>Remaining QTY : </label></b> &nbsp;

                <input type="text" style="width: 170px;margin-right: 8px;" readonly nz-input
                  [(ngModel)]="this.RemainingQty" />
                <!-- <button nz-button nzSize="small" nzType="primary"(click)="search()" style="margin-right: 8px;">Search</button> -->
              </div>
              <br>
              <div>
                <b><label style="margin-right:41px;">Yield(%) :</label></b> &nbsp;
                <input type="text" style="width: 170px;margin-right: 8px;" readonly nz-input
                  [(ngModel)]="NewWorkPlan.Yield" />
                <!-- <button nz-button nzSize="small" nzType="primary"(click)="search()" style="margin-right: 8px;">Search</button> -->
              </div>
              <!-- </nz-form-control> -->

              <nz-divider></nz-divider>
              <nz-descriptions style="width: 80%;margin-bottom: 4px;" nzBordered nzLayout="vertical" nzSize="small"
                [nzColumn]="6">
                <nz-descriptions-item nzTitle="Total QTY">
                  {{sumquantity()}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="1st Grade QTY">
                  {{sum1stquantity()}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="A Grade QTY">
                  {{sumAquantity()}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Total Weight">
                  {{sumweight()}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="1st Grade Weight">
                  {{sum1stweight()}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="A Grade Weight">
                  {{sumAweight()}}
                </nz-descriptions-item>
              </nz-descriptions>
              <nz-divider></nz-divider>
              <!-- <div class="text-center">
      <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="Save()">SAVE </button>
    </div> -->
            </div>
          </div>
        </div>

      </div>





    </div>
  </div>