<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Knitting Division Stock List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Fabric Stock coming from Knitting Division here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button *ngIf="CheckCreatePOPermission()" nz-button nzType="primary" (click)="CreatePO()"
          style="margin-right:10px;">
          Create PO
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Type</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                  (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                  <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                  <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                  <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Category</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID"
                  name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                  <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                    [nzLabel]="s.ProductCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>SubCategory</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                  [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category"
                  (ngModelChange)="GetAllSecondCategory($event)">
                  <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId"
                    [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>2nd SubCategory</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                  [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="SecondCategoryID"
                  (ngModelChange)="GetSecondCategoryFilteredProduct()">
                  <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                    [nzLabel]="s.ProductSecSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Name</label>
                <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="selectedProduct"
                  name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                    [nzLabel]="s.ProductName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Status</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedStatus" nzPlaceHolder="Choose"
                  nzAllowClear>
                  <nz-option nzValue="Active" nzLabel="Active"></nz-option>
                  <nz-option nzValue="PO Created" nzLabel="PO Created"></nz-option>
                  <nz-option nzValue="Partial Received" nzLabel="Partial Received"></nz-option>
                  <nz-option nzValue="Received" nzLabel="Received"></nz-option>
                  <nz-option nzValue="Blocked" nzLabel="Blocked"></nz-option>
                  <nz-option nzValue="Cancelled" nzLabel="Cancelled"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetReport()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%" #basicTable [nzData]="this.KnittingDivisionStockList"
      [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzWidth="30px"></th>
          <th nzWidth="80px">S. No.</th>
          <th>Product Name</th>
          <th>Quantity</th>
          <th>Unit</th>
          <th>Status</th>
          <th nzWidth="200px">Added Date</th>
          <th>Added By</th>
          <th>PO Number</th>
          <th nzWidth="200px" nzRight style="text-align: center" *ngIf="permission.ViewPO || permission.Manage">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td>
            <input *ngIf="data.Status == 'Pending'" style="margin-right: 5px" type="checkbox" [checked]="data.IsChecked"
              (change)="data.IsChecked = !data.IsChecked; OnCheckChanged()" />
          </td>
          <td>{{ i + 1 }}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Weight }}</td>
          <td>{{ data.Unit }}</td>
          <td [ngStyle]="{'background-color': setDemandCellColor(data)}">{{ data.Status }}</td>
          <td [ngStyle]="{'background-color': setDemandCellColor(data)}">{{ data.AddedDate.toString() | DatetimeConverter}}</td>
          <td>{{ data.AddedBy }}</td>
          <td>{{ data.PONumber }}</td>

          <td nzRight style="text-align: center">
            <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="btn btn-sm btn-light-primary"
              style="line-height:0">
              Action
              <span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li style="width: 120px;" nz-menu-item
                  *ngIf="this.permission.ViewPO && (data.Status == 'PO Created' || data.Status == 'Partial Received' || data.Status == 'Received')"
                  (click)="OpenViewPop(data)">View PO</li>
                <li style="width: 120px;" nz-menu-item *ngIf="this.permission.Manage && data.Status == 'Active'"
                  (click)="ChangeStatus(data,'Blocked')">Block</li>
                <li style="width: 120px;" nz-menu-item *ngIf="this.permission.Manage && data.Status == 'Active'"
                  (click)="ChangeStatus(data,'Cancelled')">Cancel</li>
                <li style="width: 120px;" nz-menu-item
                  *ngIf="this.permission.Manage && data.Status == 'Blocked' || data.Status == 'Cancelled'"
                  (click)="ChangeStatus(data,'Active')">Active</li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>


  </div>
</div>



<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="800"
  nzTitle="Purchase Order details" (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="PurchaseOrder">
      <nz-descriptions-item nzTitle="Order No" nzColon="true"
        *ngIf="PurchaseOrder.Status == 'Active' || PurchaseOrder.Status == 'Complete'"><b>{{PurchaseOrder.Ponumber}}</b>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Order Date"><b>{{PurchaseOrder.PocreationDate | date:
          'dd-MMM-yyyy'}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Department Name"><b>{{PurchaseOrder.DepartmentName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Supplier Name"
        [nzSpan]="2"><b>{{PurchaseOrder.SupplierName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="GRN"><b>{{PurchaseOrder.Grn}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Reference#"><b>{{PurchaseOrder.Reference}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Delivery Terms"> <b>{{PurchaseOrder.DeliveryTerm}}</b></nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="Shipment Preference"><b>{{PurchaseOrder.TransportCompanyName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Payment Terms"><b>{{PurchaseOrder.PaymentTerm}}</b></nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="Currency"><b>{{PurchaseOrder.PurchaseOrderProduct[0].Currency}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Prepared By"><b>{{PurchaseOrder.AddedBy?.Name}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Remark" [nzSpan]="3"><b>{{PurchaseOrder.Remarks}}</b></nz-descriptions-item>

    </nz-descriptions>
    <nz-divider></nz-divider>
    <nz-table nzSize="small" *ngIf="PurchaseOrder" [nzData]="['']" #basicTable1 nzBordered style="width:100%">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Product</th>
          <th>Unit</th>
          <th>Grade</th>
          <th>Rate</th>
          <th>Order QTY</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">Received QTY</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">Pending QTY</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">Extra QTY</th>
          <th>Amount</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of PurchaseOrder.PurchaseOrderProduct;let i=index" nzShowPagination="false">
          <td>{{i+1}}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Unit }}</td>
          <td>{{ data.Grade }}</td>
          <td>{{ data.Rate }}</td>
          <td>{{ data.Quantity }}</td>
          <td *ngIf="PurchaseOrder.Status != 'New'">{{ data.RecievedQuantity }}</td>
          <td *ngIf="PurchaseOrder.Status != 'New'">{{ CalculatePendingQTY(data) }}</td>
          <td *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateExtraQTY(data) }}</td>
          <td>{{ data.Amount }}</td>

        </tr>

      </tbody>
      <tfoot *ngIf="this.PurchaseOrder.PurchaseOrderProduct.length>0">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct ,'OrderQuantity') }}</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct
            ,'ReceivedQuantity') }}</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct
            ,'PendingQuantity') }}</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct
            ,'ExtraQuantity') }}</th>
          <th>{{PurchaseOrder.PototalAmount}}</th>
          <!-- <th nzWidth="20%">Action</th> -->
        </tr>
      </tfoot>
    </nz-table>
  </ng-container>
</nz-drawer>
<app-adddemand></app-adddemand>