<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Yield Report</nz-page-header-title>
            <nz-page-header-subtitle>Manage your yield here</nz-page-header-subtitle>
        </nz-page-header>
        <nz-divider></nz-divider>

        <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">

                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Product Type</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                                    (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                                    <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                                    <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                                    <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                <label>Category</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category"
                                    (ngModelChange)="GetAllFirstCategory($event)">
                                    <nz-option *ngFor="let s of this.ProductCategoryList;"
                                        [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>SubCategory</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear
                                    nzPlaceHolder="Category" (ngModelChange)="GetAllSecondCategory($event)">
                                    <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                                        [nzValue]="s.ProductFirstSubCategoryId"
                                        [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>2nd SubCategory</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                                    nzPlaceHolder="SecondCategoryID"
                                    (ngModelChange)="GetSecondCategoryFilteredProduct()">
                                    <nz-option *ngFor="let s of this.ProductSecSubCategoryList;"
                                        [nzValue]="s.ProductSecSubCategoryId"
                                        [nzLabel]="s.ProductSecSubCategory"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Fabric Name</label>
                                <nz-select nzShowSearch class="form-select " nzSize="default"
                                    [(ngModel)]="request.FabricProductId" name="ProductId"
                                    (ngModelChange)="onSelectedProductChange($event)" nzAllowClear
                                    nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                                        [nzLabel]="s.ProductName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>From</label>
                                <nz-date-picker [(ngModel)]="request.DateFrom" nzPlaceHolder="Date"
                                    [nzShowToday]="false" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                    nzFormat="yyyy-MM-dd HH:mm a"
                                    style="width: 150px;margin-right: 8px;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>To</label>
                                <nz-date-picker [(ngModel)]="request.DateTo" nzPlaceHolder="Date" [nzShowToday]="false"
                                    [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
                                    style="width: 150px;margin-right: 8px;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="YieldReport()">
                            Search
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 8px;float:right">
            <!--<button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline" style="top: 2px;right: 48px;position: absolute;"></i> Export</button>-->
            <button nz-button *ngIf="this.isEmailReportEnabled" [nzLoading]="isEmailButtonLoading" nzSize="small"
                (click)="sendEmail()"><i nz-icon nzType="mail" nzTheme="outline"
                    style="top: 2px;right: 79px;position: absolute;"></i> Send Email</button>
            <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
                [printStyle]="{ '@page': { size: 'landscape !important' } }" style="margin-left:5px"><i
                    style="top: 2px;right: 37px;position: absolute;" nz-icon nzType="printer"
                    nzTheme="outline"></i>Print</button>
        </div>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float:left">
            <p><nz-tag nzColor="#ffa500" style="width: 60px;text-align: center;">Orange</nz-tag></p>
            <p><nz-tag nzColor="#ff0000" style="width: 60px;text-align: center;">Red</nz-tag></p>
            <p><nz-tag nzColor="#ff0000" style="width: 60px;text-align: center;">Red</nz-tag></p>
        </div>
        <div style="padding: 8px;float:left">
            <p><b>= PRD MTR is above 5% of Order QTY</b></p>
            <p><b>= PRD MTR is less than Order QTY</b></p>
            <p><b>= GRD Total is less than Order QTY</b></p>
        </div>
        <div style="padding: 8px;float:left">
            <p><nz-tag [nzColor]="'#87d068'">Light Green</nz-tag><b>= PRD MTR is within 5% of Order QTY</b></p>
        </div>
        <div style="padding: 8px;float:left">
            <p><nz-tag nzColor="#ffa500" style="width: 60px;text-align: center;">Orange</nz-tag></p>
            <p><nz-tag nzColor="#ff0000" style="width: 60px;text-align: center;">Red</nz-tag></p>
        </div>
        <div style="padding: 8px;float:left">
            <p><b>= Yield % is below 94% & above 90%</b></p>
            <p><b>= Yield is or below 90% </b></p>
        </div>
        <div style="padding: 8px;float:left">
            <p><nz-tag [nzColor]="'#87d068'">Light Green</nz-tag><b>= Yield is or above 94%</b></p>
        </div>
        <nz-divider></nz-divider>
        <div style="max-width:100%; overflow:scroll">
            <nz-table id="print-section" nzSize="small" style="width: 100%;page-break-inside: auto;" #basicTable
                [nzPageSize]="20" [nzData]="this.DemandList" [nzLoading]="isTableLoading" nzBordered
                nzShowPagination="true" nzShowSizeChanger [nzShowTotal]="totalTemplate" nzShowQuickJumper="true"
                [nzPageSizeOptions]=[10,20,50,100,500]>
                <thead style="font-weight: bolder;">
                    <tr
                        style="text-align: left;page-break-inside:avoid; page-break-after:auto;background-color: black;">

                        <th>S. No.</th>
                        <th>Inspection Date</th>
                        <th>Sale Order No.</th>
                        <th>Sale Order Code</th>
                        <th>Fabric</th>
                        <th>Order QTY</th>
                        <th>PRD MTR</th>
                        <th>Over PRD %</th>
                        <th>1st</th>
                        <th>A</th>
                        <th>Lot</th>
                        <th>NS</th>
                        <th>CP</th>
                        <th>Yield(%)</th>
                        <th>SAMP</th>
                        <th>SCRP</th>
                        <th>Grd Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data" style="page-break-inside:avoid; page-break-after:auto">

                        <td>{{ data.SerialNo }}</td>
                        <td>{{ data.AddedDate.toString() | DatetimeConverter }}</td>
                        <td>{{ data.SaleOrderNumber }}</td>
                        <td style="font-weight: bolder;">{{ data.SaleOrderCode }}</td>
                        <td>{{ data.FabricName }}</td>
                        <td>{{ data.SaleOrderQuantity }}</td>
                        <td style="font-weight: bolder;" title="No. of Jumbo Roll: {{data.JumboCount}}"
                            [ngStyle]="{'background-color': setPRDMTRCellColor(data)}">{{ data.ManufacturingQuantity }}
                        </td>
                        <td [ngStyle]="{'background-color': setPRDMTRCellColor(data)}">{{
                            this.calculateOverPRDPerc(data) }}</td>
                        <td title="No. of Roll: {{data.FirstGradeCount}}">{{ data.FirstGrade }} </td>
                        <td title="No. of Roll: {{data.AGradeCount}}">{{ data.AGrade}}</td>
                        <td title="No. of Roll: {{data.LOTGradeCount}}">{{ data.LOTGrade }}</td>
                        <td title="No. of Roll: {{data.NSGradeCount}}">{{ data.NSGrade }}</td>
                        <td title="No. of Roll: {{data.CUTPCGradeCount}}">{{ data.CUTPCGrade }}</td>
                        <td style="font-weight: bolder;"
                            [ngStyle]="{'background-color': setYieldCellColor(CalculateYield(data))}">{{
                            CalculateYield(data) }}</td>
                        <td title="No. of Roll: {{data.SampleCount}}">{{ data.SampleQuantity }}</td>
                        <td
                            title="Waste: {{data.WASTEGrade}} and No. of Roll: {{data.WASTEGradeCount}} / Film: {{data.FILMGrade}} and No. of Roll: {{data.FILMGradeCount}}">
                            {{ data.FILMGrade + data.WASTEGrade }}
                        </td>
                        <td [ngStyle]="{'background-color': setGRDTotalCellColor(data)}">{{ CalculateGRDTotal(data) }}
                        </td>
                    </tr>
                    <tr style="page-break-inside:avoid; page-break-after:auto;font-weight: bolder;">
                        <td colspan="5" style="text-align:right">Total</td>
                        <td> {{ CalculateTotal('SaleOrderQuantity') }} </td>
                        <td> {{ CalculateTotal('ManufacturingQuantity') }} </td>
                        <td> {{ calculateTotalOverPRDPerc() }} </td>
                        <td> {{ CalculateTotal('FirstGrade') }} </td>
                        <td> {{ CalculateTotal('AGrade') }} </td>
                        <td> {{ CalculateTotal('LOTGrade') }} </td>
                        <td> {{ CalculateTotal('NSGrade') }} </td>
                        <td> {{ CalculateTotal('CUTPCGrade') }} </td>
                        <td> {{ CalculateTotal('Yield')}} </td>
                        <td> {{ CalculateTotal('SampleQuantity') }} </td>
                        <td> {{ CalculateTotal('SCRP') }} </td>
                        <td> {{ CalculateTotal('Amount') }} </td>
                    </tr>
                </tbody>

            </nz-table>

        </div>
    </div>
</div>



<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1000"
    nzTitle="Purchase Order details" (nzOnClose)="handleCancel()">
    <ng-container *nzDrawerContent>
        <nz-descriptions nzBordered nzLayout="vertical" *ngIf="PurchaseOrder">
            <nz-descriptions-item nzTitle="Order No" nzColon="true"><b>{{PurchaseOrder.Ponumber}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Order Date"><b>{{PurchaseOrder.PocreationDate | date:
                    'dd-MMM-yyyy'}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="GRN"><b>{{PurchaseOrder.Grn}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Supplier Name"
                [nzSpan]="2"><b>{{PurchaseOrder.SupplierName}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Reference#"><b>{{PurchaseOrder.Reference}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Delivery Terms"> <b>{{PurchaseOrder.DeliveryTerm}}</b></nz-descriptions-item>
            <nz-descriptions-item
                nzTitle="Shipment Preference"><b>{{PurchaseOrder.TransportCompanyName}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Payment Terms"><b>{{PurchaseOrder.PaymentTerm}}</b></nz-descriptions-item>
            <nz-descriptions-item
                nzTitle="Currency"><b>{{PurchaseOrder.PurchaseOrderProduct[0].Currency}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Prepared By"><b>{{PurchaseOrder.AddedBy}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Remark" [nzSpan]="3"><b>{{PurchaseOrder.Remarks}}</b></nz-descriptions-item>

            <!--<nz-descriptions-item nzTitle="View Invoice">
        <a class="btn btn-sm btn-light-info" *ngIf="this.NewStock.Invoice.InvoiceFile != null" [href]="NewStock.Invoice.InvoiceFile" target="_blank">Link to Invoice</a>
        <span *ngIf="this.NewStock.Invoice.InvoiceFile == null">No invoice file found</span>
      </nz-descriptions-item>-->

        </nz-descriptions>
        <nz-divider></nz-divider>
        <nz-table nzSize="small" *ngIf="PurchaseOrder" [nzData]="['']" #basicTable1 nzBordered style="width:100%">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Product</th>
                    <th>Unit</th>
                    <th>Grade</th>
                    <th>Rate</th>
                    <th>Order QTY</th>
                    <th>Received QTY</th>
                    <th>Pending QTY</th>
                    <th>Amount</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of PurchaseOrder.PurchaseOrderProduct;let i=index" nzShowPagination="false">
                    <td>{{i+1}}</td>
                    <td>{{ data.ProductName }}</td>
                    <td>{{ data.Unit }}</td>
                    <td>{{ data.Grade }}</td>
                    <td>{{ data.Rate }}</td>
                    <td>{{ data.Quantity }}</td>
                    <td>{{ data.RecievedQuantity }}</td>
                    <td>{{ data.Quantity - data.RecievedQuantity }}</td>
                    <td>{{ data.Amount }}</td>

                </tr>

            </tbody>
            <tfoot *ngIf="this.PurchaseOrder.PurchaseOrderProduct.length>0">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Total</th>
                    <th>{{PurchaseOrder.PototalAmount}}</th>
                    <th></th>
                    <!-- <th nzWidth="20%">Action</th> -->
                </tr>
            </tfoot>
        </nz-table>
    </ng-container>
</nz-drawer>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>