import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyInfo } from '../../../Authentication/UserInfo';
import { JumboStickerPrintModel, WorkPlanJumboMasterModel } from '../../../Models/WorkPlanModel';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';




@Component({
  selector: 'app-jumboprint',
  templateUrl: './jumboprint.component.html',
  styleUrls: ['./jumboprintcomponent.css']
})
export class JumboPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  private route$: Subscription = new Subscription();
  Poid = 0;
  SaleOrderId: number = 0;
  JumboPrintData: JumboStickerPrintModel = new JumboStickerPrintModel();
  Jumboid = 0;
  Company = {
    CompanyName: '',
    Contact: '',
    Email: '',
    Address: '',
    GST: '',
  };
  JumboData = {
    JUMBOROLLID: '',
    CUSTOMERNAME: '',
    PRODUCTCODE: '',
    ARTICLENAME: '',
    GRAIN: '',
    COLOUR: '',
    FINISH: '',
    THICKNESS: '',
    WIDTH: '',
    LENGTH: '',
    WEIGHT: '',
    DATE: '',
    SupervisorName: '',
    ProductionLineNo: '',
    SaleOrderNumber: ''
  }
  permission = {
    View: false
  }
  jumboTableData: WorkPlanJumboMasterModel[] = [];
  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.permission.View = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.View);
    // if (this.permission.View != true) {
    //   this.router.navigate(['/home/unauthorized']);
    // }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.Poid = params['id'];
      this.SaleOrderId = params['id'];
      this.Jumboid = params['JumboId'];
    });
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.Contact = CompanyInfo.Contact;
    this.Company.Email = CompanyInfo.Email;
    this.Company.Address = CompanyInfo.ShortAddress;
    // this.Company.GST = CompanyInfo.GST;
    // this.Company.Website = CompanyInfo.Website;

  }
  // getJumboRollsById(): void {
  //   var check = false;
  //   let url = this.ApiUrl + 'workplan/getworkplanjumbobyworkplanordersid';
  //   this.http.get<any>(url + '/' + this.Poid).subscribe(
  //     (res) => {
  //       this.jumboTableData = res.Data;
  //       this.jumboTableData = this.jumboTableData.filter(x => x.WorkPlanJumboMasterId == this.Jumboid)
  //       this.JumboData.JUMBOROLLID = this.jumboTableData[0].JumboNo;
  //       this.JumboData.WEIGHT = this.jumboTableData[0].Weight.toString();
  //       this.JumboData.DATE = this.jumboTableData[0].JumboRollDate.toString();
  //       this.JumboData.SupervisorName = this.jumboTableData[0].ShiftSupervisorWorkerName;
  //       this.JumboData.ProductionLineNo = this.jumboTableData[0].ProductionLineNo.toString();
  //       this.JumboData.SaleOrderNumber = this.jumboTableData[0].SaleOrderNumber;
  //       var d = new Date(this.JumboData.DATE);
  //       var dd = new Date(new Date(d).setMinutes(d.getMinutes() + 330))
  //       this.JumboData.DATE = new DatePipe('en-US').transform(dd, 'dd/MM/yyyy')?.toString() ?? '';
  //       this.JumboData.LENGTH = this.jumboTableData[0].JumboRolQty.toString();
  //       check = true
  //       console.log(this.jumboTableData[0])
  //       if (check == true) {
  //         setTimeout(function () {
  //           window.print();
  //           window.onafterprint = close;
  //           function close() {
  //             window.close();
  //           };
  //         }, 1);
  //       }
  //     });


  // }


  // OpenViewPop() {
  //   this.GetJumboPrintData()
  //   let url = this.ApiUrl + 'saleorder/getsaleorderdatabyid/' + this.Poid;
  //   this.http.get<any>(url).subscribe((res) => {
  //     this.getJumboRollsById();
  //     //this.Orderlist = res;
  //     console.log(res)
  //     this.JumboData.COLOUR = res.SaleOrderProduction.ColorName
  //     this.JumboData.GRAIN = res.SaleOrderProduction.GrainName
  //     this.JumboData.CUSTOMERNAME = res.CustomerName
  //     this.JumboData.ARTICLENAME = res.SaleOrderProduction.ManufacturingProductName
  //     this.JumboData.FINISH = res.FinishCode
  //     this.JumboData.THICKNESS = res.SaleOrderProduction.ThicknessValue
  //     this.JumboData.PRODUCTCODE = res.SaleFormulationCode
  //     this.JumboData.WIDTH = res.SaleOrderProduction.WidthNumber

  //   });
  // }
  CheckOnload() {
    this.GetJumboPrintData()
  }
  GetJumboPrintData() {
    let url = this.ApiUrl + 'workplan/getjumboprintbyorderidjumboid/?saleorderid=' + this.SaleOrderId + '&jumboid=' + this.Jumboid;
    this.http.get<any>(url).subscribe((res) => {
      // this.getJumboRollsById();
      //this.Orderlist = res;
      console.log(res.Data)
      this.JumboPrintData = res.Data;
      var d = new Date(this.JumboPrintData.JumboRollDate);
      var dd = new Date(new Date(d).setMinutes(d.getMinutes() + 330))
      this.JumboPrintData.JumboRollDate = new DatePipe('en-US').transform(dd, 'dd/MM/yyyy')?.toString() ?? '';
      setTimeout(function () {
        window.print();
        window.onafterprint = close;
        function close() {
          window.close();
        };
      }, 1);
    });
  }
}





