import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GateInModel, GateInPrintModel } from 'src/PmsUIApp/Models/GateInModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DatetimeConverter } from '../../Services/DatetimeConverter.pipe';
import { UserInfo } from '../../Authentication/UserInfo';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { TransportModel, TransportVehicleModel } from 'src/PmsUIApp/Models/TransportModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';

@Component({
  templateUrl: './GatePass.component.html'
})
export class GatePassComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  GateInList: GateInModel[] = [];
  GateInListOriginal: GateInModel[] = [];
  NewGatePass: GateInModel = null;
  validateForm!: FormGroup;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  DriverName: string = '';
  DriverContact: string = '';

  isLoading: boolean = false;
  isTableLoading: boolean = false;
  exportoptions = {
    headers: ["VEHICLE NUMBER", "TRANSPORT COMPANY", "PERSON NAME", "CONTACT NUMBER", "GATE-IN DATE", "GATE PASS ISSUED DATE", "OUT DATE", "ADDED BY", "GATE-IN", "PASS ISSUED", "GATE-OUT"]
  };
  IsDriverInputVisible: boolean = false;
  IsWeightInputVisible: boolean = false;
  fields: GateInPrintModel = new GateInPrintModel;
  exportfields: GateInPrintModel[] = []
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  request = {
    FromDate: new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 2)),
    ToDate: new Date(new Date().setHours(23, 59, 59)),
    VehicleId: 0,
    TransportId: 0,
    DateType: 'gatein', // options are - gatein, gateout, gatepass
    SupplierId: 0,
    Type: '',
    IsGatePassIssued: null,
    PONumber: '',
    InvoiceNumber: ''
  }
  dateTypeList = [
    {
      "Text": "Gate In",
      "Value": 'gatein'
    },
    {
      "Text": "Gate Out",
      "Value": 'gateout'
    },
    {
      "Text": "Gate Pass",
      "Value": 'gatepass'
    }];
  totalItemsCount: number;
  TransportList: TransportModel[];
  SupplierList: SupplierModel[];
  FilterVehicleList: TransportVehicleModel[] = [];
  constructor(public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService
    , private auth: AuthService, private router: Router, private fb: FormBuilder,
    public loader: LoadingService) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.IssueGatePass, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.IssueGatePass, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.IssueGatePass, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllGateInRecords();
    this.validateForm = this.fb.group({
      DriverName: [null, [Validators.required]],
      DriverContact: [null, [Validators.required, Validators.minLength(10)]]

    })
  }
  get f() { return this.validateForm.controls; }

  GetAllGateInRecords() {
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "gate/getgateinrecordswithfilter";
    this.http.post<GateInModel[]>(url, this.request).subscribe(res => {
      this.GateInList = res;
      this.GateInListOriginal = res;
      this.GateInList.forEach(x => {
        count++;
        this.fields = new GateInPrintModel;

        this.fields.VehicleNumber = x.VehicleNumber;
        this.fields.TransportCompanyName = x.TransportCompanyName;
        this.fields.GateInPerson = x.GateInPerson;
        this.fields.GateInPersonContact = x.GateInPersonContact;
        this.fields.GateInDate = new DatetimeConverter().transform(x.GateInDate) ?? "";
        this.fields.GatePassIssueDate = new DatetimeConverter().transform(x.GatePassIssueDate) ?? "";
        this.fields.GateOutDate = new DatetimeConverter().transform(x.GateOutDate) ?? "";
        this.fields.AddedBy = '';
        this.fields.GateIn = x.GateIn == true ? "Completed" : "Not yet";
        this.fields.GatePassIssue = x.GatePassIssue == true ? "Issued" : "Not Issued";
        this.fields.GateOut = x.GateOut == true ? "Completed" : "Not yet";



        this.exportfields.push(this.fields);
      })
      this.totalItemsCount = count;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllGateInRecords();
      }
    });
  }
  export() {

    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'getepass-export' + exportdate, this.exportoptions);
  }

  ConfirmGatePass(item: GateInModel) {
    this.NewGatePass = item;
    if (item.GateInPerson != 'System') {
      this.IsWeightInputVisible = true;
    }
    else {
      this.IsDriverInputVisible = true;
    }
  }


  IssueGatePass() {
    if (this.IsDriverInputVisible) {
      if (this.DriverName == null || this.DriverName == "") {
        this.alertService.error("Please enter driver name.");
        return;
      }
      if (this.DriverContact == null || this.DriverContact == "") {
        this.alertService.error("Please enter driver contact number.");
        return;
      }
    }
    if (this.IsWeightInputVisible || this.IsDriverInputVisible) {
      if (this.NewGatePass.InWeight == null || this.NewGatePass.InWeight == '') {
        this.alertService.error("Please enter valid vehicle weight when it arrived in factory.");
        return;
      }
      if (this.NewGatePass.OutWeight == null || this.NewGatePass.OutWeight == '') {
        this.alertService.error("Please enter valid vehicle weight when it is leaving the factory.");
        return;
      }
    }
    this.isLoading = true;
    this.NewGatePass.GatePassAddedBy = UserInfo.EmailID;
    if (this.NewGatePass.GateInPerson == 'System') {
      this.NewGatePass.GateInPerson = this.DriverName;
      this.NewGatePass.GateInPersonContact = this.DriverContact;
    }
    let url = this.ApiUrl + "gate/issuegatepass";
    this.http.post<GateInModel>(url, this.NewGatePass).subscribe({
      next: res => {
        this.alertService.success("Entry Saved Successfully");
        this.isLoading = false;
        this.IsDriverInputVisible = false;
        this.IsWeightInputVisible = false;
        this.NewGatePass = null;
        this.DriverName = '';
        this.DriverContact = '';
        this.GetAllGateInRecords();
      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = false; },
    });
  }

  handleDriverDetailsPopup(): void {
    this.IsDriverInputVisible = false;
  }
  handleWeightDetailsPopup(): void {
    this.IsWeightInputVisible = false;
  }
  handleOk(): void {

  }

  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllGateInRecords()
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.GateInListOriginal;
    var res2 = this.GateInListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: GateInModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.GateInDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.GateInDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.GateInList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.GateInList = res.filter(
        (item: GateInModel) =>
          item?.VehicleNumber?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.TransportCompanyName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.GateInPerson?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.GateInPersonContact?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  print(data: GateInModel) {

    window.open(`${window.location.origin}/gatepassprint/` + data.GateInId);
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllSuppliers();
      this.GetAllTransport();
    }
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  ResetFilters() {
    this.request = {
      FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
      ToDate: new Date(new Date().setHours(23, 59, 59)),
      VehicleId: 0,
      TransportId: 0,
      DateType: 'gatein', // options are - gatein, gateout, gatepass
      SupplierId: 0,
      Type: '',
      IsGatePassIssued: null,
      PONumber: '',
      InvoiceNumber: ''
    }
  }
  GetAllTransport() {
    let url = this.ApiUrl + 'transport/getalltransport';
    this.http.get<TransportModel[]>(url).subscribe({
      next: (res) => {
        this.TransportList = res;
        this.loader.hide()
      },
      error: (res) => {
        this.loader.hide()
        this.GetAllTransport();
      },
    });
  }
  GetAllSuppliers() {
    let url = this.ApiUrl + 'supplier/getallsuppliers';
    this.http.get<SupplierModel[]>(url).subscribe(
      (res) => {
        this.SupplierList = res;
        this.loader.hide()
      },
      (res) => {
        this.count++;
        this.loader.hide()
        if (this.count < 2) {
          this.GetAllSuppliers();
        }
      }
    );
  }
  GetAllVehicleForFilter(TransportId: any) {
    this.FilterVehicleList = [];
    if (TransportId != undefined && TransportId) {
      this.FilterVehicleList = this.TransportList.find(x => x.TransportId == TransportId).TransportVehicle;
    }
  }
}
