import { ConsumeStockProductModel, IssueSaleOrderProductsStockModel } from "./StockProductModel";

export class MbformulationMasterModel {
  MbformulationId: number= 0;
  MbformulationName: string = '';
  BatchSize: number = 0;
  FinalProductId: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  MbformulationRawMaterial: MbformulationRawMaterialMasterModel[]=[];
}

export class MbformulationRawMaterialMasterModel {
  MbformulationRawMaterialId: number = 0;
  MbformulationId: number = 0;
  RawMaterialProductId: number = 0;
  RawMaterialProductName: string = '';
  RawMaterialProductCode: string = '';
  QuantityInKg: number = 0;
  QuantityBatchSizeInKg: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  IssueProduct: IssueSaleOrderProductsStockModel[]=[];
}


export class MbformulationProductModel {
  MbformulationProductId: number= 0;
  MbformulationId: number= 0;
  MbformulationProductName: string = '';
  ProductCategoryId: number = 0;
  ProductFirstSubCategoryId: number = 0;
  ProductSecSubCategoryId: number = 0;
  BatchSize: number = 0;
  RackId: number = 0;
  FromStoreId: number = 0;
  StockId: number = 0;
  Quantity: number = 0;
  FinalProductId: number = 0;
  Batch: string = '';
  IsMasterUpdate: boolean = false;
  AddedBy: string = '';
  AddedDate: string = '';
  MbformulationRawMaterialProduct: MbformulationRawMaterialProductModel[]=[];
}

export class MbformulationRawMaterialProductModel {
  MbformulationRawMaterialProductId: number = 0;
  MbformulationProductId: number = 0;
  RawMaterialProductId: number = 0;
  RawMaterialProductName: string = '';
  RawMaterialProductCode: string = '';
  QuantityInKg: number = 0;
  QuantityBatchSizeInKg: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  ConsumeStockForRawMaterial: ConsumeStockProductModel[]=[];
}

