<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Users Login History List</nz-page-header-title>

      <nz-page-header-extra>


      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>

    <div> <b> Note: City location information may not be accurate. It may show nearby approximate locations. List will
        contain history for last 7 days only.</b></div>
    <nz-divider></nz-divider>
    <nz-table nzSize="small" [nzData]="['']" [nzPageSize]="50" [nzScroll]="{ x: '1200px',y:'515px' }"
      style="width: 100%;" #basicTable1 nzBordered>
      <thead>
        <tr>
          <th nzWidth="50px">S. No.</th>
          <th nzWidth="250px" nzLeft>Name</th>
          <th nzWidth="250px">Email</th>
          <th nzWidth="100px">status</th>
          <th nzWidth="100px">Login Date/Time</th>
          <th nzWidth="100px">IP Address</th>
          <th nzWidth="100px">Location</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of FilteredLoginHistoryList;let i=index">

          <td nzWidth="20px">
            {{i+1}}

          </td>
          <td nzLeft><b>{{data.userDisplayName}}</b></td>

          <td>{{data.userPrincipalName}}</td>
          <td *ngIf="data.status.errorCode==0"
            title='Logged in using {{data.deviceDetail.browser}} on {{ data.deviceDetail.operatingSystem }}'>Success
          </td>
          <td *ngIf="data.status.errorCode!=0" title={{data.status.failureReason}}>Failed</td>
          <td>{{data.createdDateTime | date:'dd-MM-yyyy HH:mm:ss a'}}</td>
          <td>{{data.ipAddress}}</td>
          <td>{{data.location.city}}, {{data.location.state}}, {{data.location.countryOrRegion}}</td>




        </tr>

      </tbody>

    </nz-table>



  </div>
</div>