import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { ColorModel, GrainModel, PostProcessCostingMasterModel, ThicknessModel } from 'src/PmsUIApp/Models/MasterModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import moment from 'moment';
import { SaleOrderCostingDataService } from 'src/PmsUIApp/Services/SaleOrderCostingData.service';
import { environment } from 'src/environments/environment';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { CostingModel } from 'src/PmsUIApp/Models/CostingModel';
import { EODrawerService } from 'src/PmsUIApp/Services/EODrawerService';
@Component({
    selector: 'app-Costinglist',
    templateUrl: './costEstimationList.component.html',
    styleUrls: ['./costEstimationList.component.css'],
})
export class CostEstimationListComponent implements OnInit {
    ApiUrl = environment.Api_Url;
    CostingList: CostingModel[] = [];
    CostEstimationListOriginal: any[] = [];
    PerLmData: CostingModel;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    typeList: any[] = [];
    PopUpTitle: string = 'Add New Production';
    searchValue = '';
    myDateValue: Date | undefined;
    toDate: Date | undefined;
    isValidDate: any;
    visible = false;
    exportoptions = {
        headers: [
            'S.No.',
            'Estimation Order ID',
            'Product Category ID',
            'Manufacturing Product Name',
            'Order Quantity',
            'LMconstant',
            'Manufacturing Quantity',
            'Color ID',
            'Grain ID',
            'Grain Price',
            'Thick',
            'Width',
            'Estimation Price',
            'Production Cost LM',
            'Total Finish Price',
            'Overhead Cost',
            'Rejection',
            'Total Cost Per LM',
            'Line Speed',
            'Total Profit Loss',
            'Customer ID',
            'Remarks',
            'Disabled',
            'Added By',
            'Disabled By',
            'Added Date',
            'Disabled Date',
            'Estimation Order Status ID',
            'Estimation Order Status',
            'Fabric Product IDs',
            'Mixing Names'
        ],
    };
    count: 0;
    permission = {
        View: false,
        Add: false,
        Edit: false,
        Cancel: false
    }
    totalItemsCount: number;
    isPerMtrDetailsVisible = false;
    request = {
        FabricProductId: 0,
        CustomerId: 0,
        Thick: 0,
        EstimationFromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
        EstimationToDate: new Date(new Date().setHours(23, 59, 59)),
        EstimationOrderId: 0
    }

    CustomerList: CustomerModel[];
    FormulationCodeList: FormulationCodeModel[];
    ColorList: ColorModel[];
    GrainList: GrainModel[];
    isVisibleCosting: boolean;
    PostProcessCostingList: PostProcessCostingMasterModel[];
    InlineScraping: number;
    havePostProcess: boolean;
    FabricName: string;
    FabricColorName: string;
    RejectionConstant: number = 6;
    OverheadCost: number = 25;
    PerLMConstant: number = 1.45;
    ProductionCostLm: number = 0;
    TotalCostPerLm: number = 0;
    TotalCoating: number = 0;
    TotalFinalGSM: number = 0;
    TotalCostLm: number = 0;
    TotalLaquerPrice: number = 0;
    Rejection: number = 0;
    FabricProductCostPerLm: number = 0;
    MaterialType: string = 'PVC';
    ThicknessList: ThicknessModel[];
    constructor(
        public http: HttpClient,
        private loader: LoadingService,
        private auth: AuthService, private router: Router,
        public costingService: SaleOrderCostingDataService,
        private EODrawer: EODrawerService
    ) { }

    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.Add);
        this.permission.Edit = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.Edit);
        this.permission.Cancel = this.auth.CheckResponsibility(Modules.CostEstimation, Responsibility.Cancel);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.GetFilteredEstimationCost();
    }

    export() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        const exportData = this.CostEstimationListOriginal.map((item, index) => ({
            'S.No.': index + 1,
            'Estimation Order ID': item.EstimationOrderId,
            'Product Category ID': item.ProductCategoryId,
            'Manufacturing Product Name': item.ManufacturingProductName,
            'Order Quantity': item.OrderQuantity,
            'LMconstant': item.Lmconstant,
            'Manufacturing Quantity': item.ManufacturingQuantity,
            'Color ID': item.ColorId,
            'Grain ID': item.GrainId,
            'Grain Price': item.GrainPrice,
            'Thick': item.Thick,
            'Width': item.Width,
            'Estimation Price': item.EstimationPrice,
            'Production Cost LM': item.ProductionCostLm,
            'Total Finish Price': item.TotalFinishPrice,
            'Overhead Cost': item.OverheadCost,
            'Rejection': item.Rejection,
            'Total Cost Per LM': item.TotalCostPerLm,
            'Line Speed': item.LineSpeed,
            'Total Profit Loss': item.TotalProfitLoss,
            'Customer ID': item.CustomerId,
            'Remarks': item.Remarks,
            'Disabled': item.Disabled,
            'Added By': item.AddedBy,
            'Disabled By': item.DisabledBy,
            'Added Date': item.AddedDate,
            'Disabled Date': item.DisabledDate,
            'Estimation Order Status ID': item.EstimationOrderStatus?.EstimationOrderStatusId,
            'Estimation Order Status': item.EstimationOrderStatus?.EstimationOrderStatus,
            'Fabric Product IDs': item.EstimationFabricProductDetail?.map(detail => detail.FabricProductId).join(','),
            'Mixing Names': item.EstimationMixingTable?.map(table => table.MixingName).join(',')
        }));

        if (exportData.length > 0)
            new AngularCsv(
                exportData,
                'cost-Estimation-list-export' + exportdate,
                this.exportoptions
            );
    }
    PrintListPage(): void {
        const queryParams: any = {};
        for (const filter in this.request) {
            if (this.request[filter] !== undefined && this.request[filter] !== null) {
                // Convert the date to a string with the desired format
                if (this.request[filter] instanceof Date) {
                    queryParams[filter] = this.request[filter].toISOString();
                } else {
                    queryParams[filter] = this.request[filter];
                }
            }
        }
        const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
        console.log("check if the query correct", queryString)
        const printUrl = `/costestimationlistprint?${queryString}`;
        window.open(printUrl, '_blank');
    }

    GetFilteredEstimationCost() {
        this.isTableLoading = false;
        this.CostEstimationListOriginal = null;
        let count = this.totalItemsCount = 0;
        let url = this.ApiUrl + 'estimationorder/getestimationlistbyfilter';
        this.http.post<any[]>(url, this.request).subscribe({
            next: res => {
                this.CostEstimationListOriginal = res;
                this.isTableLoading = false;
            },
            error: res => {
                this.count++
                if (this.count < 2) {
                    this.GetFilteredEstimationCost();
                }
            }
        })
    }
    onSearch() {
        this.GetFilteredEstimationCost();

    }
    OpenViewPop(EstimationOrderId: any) {
        this.EODrawer.EstimationOrderId = EstimationOrderId;
        this.EODrawer.show();
    }
    Add() {
        this.router.navigate(['/home/costing/estimation/add']);
    }
    Edit(data: any) {
        console.log(data);
        this.router.navigate(['/home/costing/estimation/add/' + data.EstimationOrderId]);
    }
    Print(EstimationOrderId: any) {
        const printUrl = `/costestimationprint/${EstimationOrderId}`;
        window.open(printUrl, '_blank');
    }
    Cancelled(data: any) {
        const cancelledReq = {
            "EstimationOrderId": data.EstimationOrderId,
            "EstimationStatus": "Cancelled"
        }
        let url = this.ApiUrl + `estimationorder/updateestimationorderstatus`;
        this.http.post<any[]>(url, cancelledReq).subscribe({
            next: res => {
            },
            error: res => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.Cancelled(data); }
            }
        });
    }
    handleRMDetailsCancel() {
        this.isPerMtrDetailsVisible = false;
    }
    onKeydown(event: any) {
        if (
            (event.target.selectionStart === 0 && event.code === 'Space') ||
            (event.key === 'Enter' && event.keyCode === 13)
        ) {
            event.preventDefault();
            //event = this.search();
        }
    }

    reset(): void {
        this.searchValue = '';
        this.myDateValue = undefined;
        this.toDate = undefined;
    }

    GetAllCustomer() {
        this.loader.show();
        let url = this.ApiUrl + "customer/getallcustomers";
        this.http.get<CustomerModel[]>(url).subscribe({
            next: res => {
                this.CustomerList = res;
                this.loader.hide();
            },
            error: res => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.GetAllCustomer(); }
            }
        });
    }
    GetAllFormulationCode() {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/getallformulationcodesforfilter";
        this.http.get<FormulationCodeModel[]>(url).subscribe({
            next: res => {
                this.FormulationCodeList = res;
                this.loader.hide();
            },
            error: res => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.GetAllFormulationCode(); }
            }
        });
    }
    GetAllColor() {
        this.loader.show();
        let url = this.ApiUrl + "Color/getallColors";
        this.http.get<ColorModel[]>(url).subscribe({
            next: res => {
                this.ColorList = res;
                this.loader.hide();
            },
            error: res => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) {
                    this.GetAllColor();
                }
            }
        });
    }
    GetAllGrain() {
        this.loader.show();
        let url = this.ApiUrl + "Grain/getallGrains";
        this.http.get<GrainModel[]>(url).subscribe({
            next: res => {
                this.GrainList = res;
                this.loader.hide();
            }, error: res => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) {
                    this.GetAllGrain();
                }
            }
        });
    }
    GetAllThickness() {
        let url = this.ApiUrl + "thickness/getallthicknessdata";
        this.http.get<ThicknessModel[]>(url).subscribe(res => {
            this.ThicknessList = res;
        }, res => {
            this.count++
            if (this.count < 2) {
                this.GetAllThickness()
            }
        });
    }
    space(el: any) {
        if (el.target.selectionStart === 0 && el.code === "Space") {
            el.preventDefault();
        }
    }
    onFilterPanelOpen(data: any) {
        if (data == true) {
            //   this.loader.show();
            this.GetAllCustomer();
            this.GetAllFormulationCode();
            this.GetAllThickness();
        }
    }
    GetPostProcessCosting() {
        let url = this.ApiUrl + "saleorder/getpostprocesscosting";
        this.http.get<PostProcessCostingMasterModel[]>(url).subscribe(res => {
            this.PostProcessCostingList = res;
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetPostProcessCosting();
            }
        });
    }
}
