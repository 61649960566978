import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';

import { WorkPlanPrintModel, WorkPlanMasterModel, WorkPlanOrderModel } from 'src/PmsUIApp/Models/WorkPlanModel';
import { environment } from '../../../../environments/environment';
import { FormulationCodeModel, FormulationCodePrintModel } from '../../../Models/FormulationCodeModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { LoadingService } from '../../../Services/loadingService';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';

@Component({
  selector: 'app-formulationCode-list',
  templateUrl: './formulation-list.component.html',
  styleUrls: ['./formulation-list.component.css']
})
export class FormulationCodeListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  FormulationCodeList: FormulationCodeModel[] = [];
  FormulationCodeListOriginal: FormulationCodeModel[] = [];

  WorkPlanModel!: FormulationCodeModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  searchValue = '';
  visible = false;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  exportoptions = {
    headers: [
      "FormulationCode",
      "CategoryName",
      " FabricProductName",
      "AddedBy",
    ]
  };
  fields: any;
  // fields: FormulationCodePrintModel = new FormulationCodePrintModel();
  exportfields: FormulationCodePrintModel[] = [];

  isValidDate: any;
  count: number;
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loader: LoadingService,
    private router: Router,
    private auth: AuthService
  ) { }

  expandSet = new Set<number>();
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.GetAllFormulationCode();
  }
  OpenViewPop(data: any) {
    console.log(`popup data`, data)

    this.isVisible = true;
    this.WorkPlanModel = data
    this.WorkPlanModel.TotalGsm = this.WorkPlanModel.PreSkinGsm + this.WorkPlanModel.SkinGsm + this.WorkPlanModel.FoamGsm + this.WorkPlanModel.AdhesiveGsm + this.WorkPlanModel.FabricGsm

  }


  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
      this.FormulationCodeListOriginal = res;
      console.log(`this.FormulationCodeListOriginal`, this.FormulationCodeListOriginal)
      this.isTableLoading = false;
      this.exportfields = [];
      this.FormulationCodeListOriginal.forEach((x) => {
        this.fields = {};
        // this.fields = new FormulationCodeModel();
        this.fields.SaleFormulationCode = x.SaleFormulationCode
        this.fields.CategoryName = x.CategoryName
        this.fields.FabricProductName = x.FabricProductName,
          this.fields.AddedBy = x.AddedBy,

          this.exportfields.push(this.fields);

      });
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllFormulationCode();
      }
    });
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'Formulation-export' + exportdate,
        this.exportoptions
      );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()

    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  //dummy functions
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  onExpandChanges(id: number, checkedd: boolean): void {
    if (checkedd) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfData = [
    {
      id: 1,
      orderno: '06/10/2022/10/6',
      buyer: '32',
      subcategory: 'fsdhdsj',
      expand: false,
      code: 'code123',
      qty: '232',
      unit: 'New York No. 1 Lake Park'
    },
    {
      id: 2,
      orderno: '06/10/2022/10/6',
      buyer: '32',
      subcategory: 'fsdhdsj',
      expand: false,
      code: 'code123',
      qty: '232',
      unit: 'New York No. 1 Lake Park'
    },
    {
      id: 3,
      orderno: '06/10/2022/10/6',
      buyer: '32',
      subcategory: 'fsdhdsj',
      expand: false,
      code: 'code123',
      qty: '232',
      unit: 'New York No. 1 Lake Park.'
    }
  ];

  listOfData1 = [
    {
      id: 1,
      JUMBONO: '120130',
      DATE: '45872845',
      STARTTIME: '726376237',
      ENDTIME: '548763874',
      JUMBOROLLQTY: '32',
      RATE: 'code123',
      AMOUNT: '347348',
      WEIGHT: '34673',
      RACK: 'RACK22',
      REMARK: 'REMARK1232',
      expand: false,
    },
    {
      id: 2,
      JUMBONO: '120130',
      DATE: '45872845',
      STARTTIME: '726376237',
      ENDTIME: '548763874',
      JUMBOROLLQTY: '32',
      RATE: 'code123',
      AMOUNT: '347348',
      WEIGHT: '34673',
      RACK: 'RACK22',
      REMARK: 'REMARK1232',
      expand: false,
    },
    {
      id: 3,
      JUMBONO: '120130',
      DATE: '45872845',
      STARTTIME: '726376237',
      ENDTIME: '548763874',
      JUMBOROLLQTY: '32',
      RATE: 'code123',
      AMOUNT: '347348',
      WEIGHT: '34673',
      RACK: 'RACK22',
      REMARK: 'REMARK1232',
      expand: false,
    }
  ];


  // OpenViewPop(data: any) {
  //   //this.loader.show();
  //   //let url = this.ApiUrl + "workplan/getallworkplanbyid/" + data.WorkPlanId;
  //   //this.http.get<any>(url).subscribe(res => {

  //   //  this.WorkPlanModel = res.Data;
  //   //  this.WorkPlanModel.WorkPlanOrder.forEach((element) => {
  //   //    element.TotalJumboQty = element.WorkPlanJumbo.reduce((sum, current) => sum + parseFloat(current.JumboRolQty?.toString()), 0) ?? 0
  //   //    element.TotalWeight = element.WorkPlanJumbo.reduce((sum, current) => sum + parseFloat(current.Weight?.toString()), 0) ?? 0

  //   //  });
  //   //   this.isVisible = true;
  //   //   this.loader.hide();
  //   //  });
  // }
  printstandardformulation(data: any) {
    window.open(`${window.location.origin}/production/formulationprint/` + data);
  }

  handleCancel(): void {
    this.isVisible = false;
  }


  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }



  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.FormulationCodeListOriginal;
    var res2 = this.FormulationCodeListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: FormulationCodeModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.FormulationCodeList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.FormulationCodeList = res.filter(
        (item: FormulationCodeModel) =>
          item?.CategoryName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.SaleFormulationCode?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1 ||
          item?.FabricProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())

      )
    }

  }
  handleDisable(data: number) {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to disable this Forumulation Code?',
      nzOkDanger: true,
      nzOkText: 'Yes',
      nzOnOk: () => this.DisableFormulationCode(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }
  DisableFormulationCode(FormulationCodeId: number) {
    var url = this.ApiUrl + "saleorder/disableformulationcode/" + FormulationCodeId;
    this.http.get<any>(url).subscribe({
      next: res => {
        this.alertService.success(res.ResponseBody);
        this.GetAllFormulationCode();
        this.isLoading = false;
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });
  }
}
