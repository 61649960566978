import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { DemandModel } from "../Models/DemandModel";
import { Modules, Responsibility } from "../Models/Enums";
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, StoreModel } from "../Models/MasterModel";
import { MeasureUnitModel } from "../Models/MeasureUnitModel";
import { ProductModel } from "../Models/ProductModel";
import { StockModel } from "../Models/StockModel";
import { AlertMessageService } from "../Services/AlertMessageService";
import { AuthService } from "../Services/auth.service";
import { DemandPopService } from "../Services/DemandPopService";




@Component({
  selector: 'app-adddemand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.css']
})

export class DemandComponent {
  ApiUrl = environment.Api_Url;
  IsPopupOpen = this.loader.loading$;
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  SelectedProductType: string = '';
  NewDemand: DemandModel = new DemandModel;
  ProductCategoryList: ProductCategoryModel[] = [];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  ProductList: ProductModel[] = [];
  FilteredProductList: ProductModel[] = [];
  StoreList: StoreModel[] = [];
  IsDisabled: boolean = false;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false
  }
  constructor(private loader: DemandPopService, public http: HttpClient, private alertService: AlertMessageService,
    private router: Router,
    private auth: AuthService) {

  }
  ngOnInit() {

    this.permission.View = this.auth.CheckResponsibility(Modules.Demand, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Delete);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Manage);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllUnits();
    this.GetAllProducts();
    this.GetAllProductCategory();
    this.GetAllStore()
  }
  handleOpen() {
    if (this.loader.IsFromOutside) {
      this.NewDemand = this.loader._demandModel;
      this.SelectedProductType = this.ProductList.filter(x => x.ProductId == this.NewDemand.ProductId)[0]?.ProductType;
      this.onSelectedProductTypeChange();
      this.CategoryID = this.ProductList.filter(x => x.ProductId == this.NewDemand.ProductId)[0]?.ProductCategoryId;
      this.NewDemand.Unit = this.ProductList.filter(x => x.ProductId == this.NewDemand.ProductId)[0]?.Unit;
      this.IsDisabled = this.loader.IsFromOutside;
      console.log('open demand pop')
    } else {

    }
  }
  handleCancel() {
    //this.IsPopupOpen = new BehaviorSubject<boolean>(false);
    this.loader.hide();
    this.loader._demandModel = new DemandModel;
    this.loader.IsFromOutside = false;
    console.log(this.IsPopupOpen)
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllUnits()
      }
    });
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
    }, res => {
      this.count++
      if (this.count < 2) { this.GetAllProducts() }
    });
  }

  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;

    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllProductCategory()
      }
    });
  }
  GetAllStore() {
    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;
    }, res => { });
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
  }

  GetAllFirstCategory(data: any, id: number = 0) {

    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductCategoryId == this.CategoryID);

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);


    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {

    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductFirstSubCategoryId == this.FirstCategoryID);

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);


    }, res => { });
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductSecSubCategoryId == this.SecondCategoryID);
  }

  onSelectedProductChange(data: any) {

    var fp = this.FilteredProductList.filter(x => x.ProductId == this.NewDemand.ProductId)[0];

    this.NewDemand.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == this.CategoryID);
      this.FirstCategoryID = fp.ProductFirstSubCategoryId;
      let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
      this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
        this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == this.FirstCategoryID);
        this.SecondCategoryID = fp.ProductSecSubCategoryId;

      }, res => { });


    }, res => { });
  }
  Save() {
    console.log(this.NewDemand)
    if (this.NewDemand.ProductId <= 0 || this.NewDemand.ProductId == null) {
      this.alertService.error("Select Product");
      return;
    }
    else if (this.NewDemand.Quantity <= 0 || this.NewDemand.Quantity == null) {
      this.alertService.error("Enter Quantity");
      return;
    }
    this.loader.show();
    let url = this.ApiUrl + "purchaseorder/adddemand";
    this.http.post<any>(url, this.NewDemand).subscribe({
      next: res => {
        this.alertService.success("Demand Saved Successfully");
        this.loader.hide();
        this.NewDemand = new DemandModel;
        window.location.reload();
      },
      error: res => { this.alertService.error(res.error); this.loader.hide(); },
    });
  }

}
