import { Component, OnInit, OnDestroy, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { Subject, interval, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { LoadingService } from '../../Services/loadingService';
import { HttpClient } from '@angular/common/http';
import { MfgHearbeatReport, MfgHeartbeatChartData, MfgHeartbeatTableData, SalesReportExportModel, SearchParamsReportModel } from '../../Models/ReportModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';

@Component({
    selector: 'app-ManufacturingHeartbeatReport',
    templateUrl: './ManufacturingHeartbeatReport.component.html',
    styleUrls: ['./ManufacturingHeartbeatReport.component.css']
})

export class ManufacturingHeartbeatReportComponent implements OnInit, OnDestroy {
    ApiUrl = environment.Api_Url;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    MfgHearbeatReportList: MfgHearbeatReport;
    MfgHearbeatReportListOriginal: MfgHearbeatReport[] = [];
    MfgHearbeatChartDataList: MfgHeartbeatChartData[];
    MfgHearbeatTableDataList: MfgHeartbeatTableData[];
    permission = {
        View: false,
        Add: false,
        Delete: false
    }
    ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
    ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
    ProductCategoryList: ProductCategoryModel[] = [];
    FilteredProductCategoryList: ProductCategoryModel[] = [];
    exportoptions = {
        headers: [
            "Order Date",
            "Order No.",
            "Item Name",
            "P.Code",
            "Grain Name",
            "Colors",
            "Width",
            "Fabric",
            "Thickness",
            "Finish",
            "Order QTY",
            "Pre Skin (GSM)",
            "Skin (GSM)",
            "Foam (GSM)",
            "Adhesive (GSM)",
            "Fabric (GSM)",
            "Party name",
            "Remarks",
            "Production Status",
            "Order Status",
            "Created Date",
            "Created By"
        ]
    };
    count: number;
    isEmailReportEnabled: boolean = false;
    isEmailButtonLoading: boolean = false;
    enableCustomDateRange: boolean = false;
    enableWorkShiftDropDown: boolean = false;

    ProductionLineList: any = [
        {
            "Text": "All",
            "Value": 0
        },
        {
            "Text": "Line 1",
            "Value": 1
        },
        {
            "Text": "Line 2",
            "Value": 2
        }
    ];
    dateFilterOptions: any = [
        {
            "Text": "Day Shift (8AM to 8PM)",
            "Value": 'dayshift'
        },
        {
            "Text": "Night Shift (8PM to 8AM)",
            "Value": 'nightshift'
        },
        {
            "Text": "Today",
            "Value": 'today'
        },
        {
            "Text": "Yesterday",
            "Value": 'yesterday'
        },
        {
            "Text": "Last 7 Days",
            "Value": 'last7days'
        },
        {
            "Text": "Last 30 Days",
            "Value": 'last30days'
        },
        {
            "Text": "Last Month",
            "Value": 'lastmonth'
        },
        {
            "Text": "Last Year",
            "Value": 'lastyear'
        },
        {
            "Text": "Custom Range",
            "Value": 'custom'
        }
    ];
    WorkShiftList: any = [
        {
            "Text": "All",
            "Value": ''
        },
        {
            "Text": "Day Shift (8AM to 8PM)",
            "Value": 'day'
        },
        {
            "Text": "Night Shift (8PM to 8AM)",
            "Value": 'night'
        }
    ];
    selecteddateFilter: string = 'today';
    previouslyselecteddateFilter: string = null;
    refreshInterval: number = 60; // Input for refresh interval in seconds
    autoRefreshSubscription: any;
    private unsubscribe$ = new Subject<void>();
    autoRefreshBtnText: string = 'Start Auto Refresh';
    totalItemsCount: number;
    private root1: am5.Root;
    IsMsgShow: boolean = false;
    MfgHeartbeatRequest: SearchParamsReportModel = new SearchParamsReportModel();
    activeTab: string = 'chart'; // Default to chart view

    constructor(@Inject(PLATFORM_ID) private platformId: any, private zone: NgZone, private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService,
        private message: NzMessageService, private soDrawer: SoDrawerService) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.ReportsMfgHeartbeat, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsMfgHeartbeat, Responsibility.Add);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsMfgHeartbeat, Responsibility.Delete);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.getDateRange("today");
        this.selecteddateFilter = 'today';
        //this.startAutoRefresh();
        this.GetMfgHearbeatReportData()
    }

    getDateRange(label: string) {
        this.enableCustomDateRange = false;
        this.enableWorkShiftDropDown = false;
        this.MfgHeartbeatRequest.WorkShift = '';
        const today = new Date();
        let startDate: Date;
        let endDate: Date;

        switch (label) {
            case 'dayshift':
                startDate = new Date(new Date(new Date().setHours(8, 0, 0)))
                endDate = new Date(new Date(new Date().setHours(20, 0, 0)))
                this.MfgHeartbeatRequest.WorkShift = 'day'
                break;

            case 'nightshift':
                var currentHour = parseInt(new Date().getHours().toString());
                if (currentHour >= 0 && currentHour <= 8) {
                    startDate = new Date(new Date(new Date().setHours(20, 0, 0)).setDate(new Date().getDate() - 1))
                    endDate = new Date(new Date().setHours(8, 0, 0))
                }
                else {
                    startDate = new Date(new Date().setHours(20, 0, 0))
                    endDate = new Date(new Date(new Date().setHours(8, 0, 0)).setDate(new Date().getDate() + 1))
                }
                this.MfgHeartbeatRequest.WorkShift = 'night'
                break;

            case 'today':
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(today.getDate()));
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                this.enableWorkShiftDropDown = true;
                break;

            case 'yesterday':
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 1));
                // startDate.setDate(today.getDate() - 1);
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(new Date().getDate() - 1));
                // endDate.setDate(today.getDate() - 1);
                this.enableWorkShiftDropDown = true;
                break;

            case 'last7days':
                // startDate = new Date(today);
                // startDate.setDate(today.getDate() - 6);
                // endDate = new Date(today);
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 6));
                // startDate.setDate(today.getDate() - 1);
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                // endDate.setDate(today.getDate() - 1);
                this.enableWorkShiftDropDown = true;
                break;

            case 'last30days':
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 29));
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastmonth':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastyear':
                startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate(), 0, 0, 0);
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                this.enableWorkShiftDropDown = true;
                break;

            case 'custom':
                startDate = new Date(today);
                endDate = new Date(today);
                this.enableCustomDateRange = true;
                this.enableWorkShiftDropDown = true;
                break;

            default:
                startDate = new Date();
                endDate = new Date();
                break;
        }

        this.MfgHeartbeatRequest.DateFrom = startDate.toISOString();
        this.MfgHeartbeatRequest.DateTo = endDate.toISOString();

    }

    setAutoRefreshTime(seconds: number) {
        this.refreshInterval = seconds;
        this.message.info('Refresh Interval set to ' + seconds + ' seconds')
        this.startAutoRefresh();
    }

    startAutoRefresh(): void {
        this.autoRefreshSubscription = interval(this.refreshInterval * 1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.GetMfgHearbeatReportData();
            });
    }

    setAutoRefreshAction(): void {
        if (!this.autoRefreshSubscription?.closed) {
            this.autoRefreshSubscription.unsubscribe();
            this.autoRefreshBtnText = 'Start Auto Refresh';
            this.message.info('Auto refresh stopped');
        }
        else {

            this.startAutoRefresh();
            this.message.info('Auto refresh started');
            this.autoRefreshBtnText = 'Stop Auto Refresh';
        }
    }

    space(el: any) {
        if (el.target.selectionStart === 0 && el.code === "Space") {
            el.preventDefault();
        }
    }
    onFilterPanelOpen(data: any) {
        if (data == true) {

        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.browserOnly(() => {
            if (this.root1) {
                this.root1.dispose();
            }
        });
    }
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    GetMfgHeartbeatChart() {
        if (this.root1) {
            this.root1.dispose();
        };
        this.root1 = am5.Root.new("mfgheartbeatdiv");

        this.root1.setThemes([
            am5themes_Animated.new(this.root1)
        ]);

        let chart = this.root1.container.children.push(am5xy.XYChart.new(this.root1, {
            panX: true,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root1, {
            behavior: "zoomX"
        }));
        cursor.lineY.set("visible", false);

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(this.root1, {
            baseInterval: { timeUnit: "minute", count: 1 },
            renderer: am5xy.AxisRendererX.new(this.root1, {}),
            tooltip: am5.Tooltip.new(this.root1, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root1, {
            renderer: am5xy.AxisRendererY.new(this.root1, {})
        }));

        let series = chart.series.push(am5xy.ColumnSeries.new(this.root1, {
            name: "Production Line Status",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            openValueXField: "openDate"
        }));

        series.columns.template.setAll({
            strokeOpacity: 0,
            tooltipY: 0
        });

        // Add this custom tooltip renderer
        series.columns.template.set("tooltipText", "{status} IST");
        series.columns.template.set("tooltipHTML", "{status}: {openDate.formatDate('yyyy-MM-dd HH:mm:ss a','en-US', 'IST')} to {date.formatDate('yyyy-MM-dd HH:mm:ss a', 'en-US', 'IST')}");

        series.columns.template.adapters.add("fill", (fill, target) => {
            return (target.dataItem.dataContext as { status: string }).status === "Running" ? am5.color(0x00ff00) : (target.dataItem.dataContext as { status: string }).status === "Break" ? am5.color(0xff0000) : am5.color(0xFFFF00);
        });

        // Set data
        let data = this.MfgHearbeatChartDataList.map(item => ({
            date: new Date(item.endTime).getTime(),
            openDate: new Date(item.startTime).getTime(),
            value: 1,
            status: item.status
        }));
        series.data.setAll(data);

        chart.set("scrollbarX", am5.Scrollbar.new(this.root1, {
            orientation: "horizontal"
        }));

        series.appear(1000);
        chart.appear(1000, 100);
    }

    ResetTableChart() {
        this.MfgHearbeatChartDataList = [];
        this.MfgHearbeatTableDataList = [];
    }

    GetMfgHearbeatReportData() {
        this.isTableLoading = true;
        this.IsMsgShow = false;
        let url = this.ApiUrl + "report/getmfghearbeatreport";

        this.http.post<MfgHearbeatReport>(url, this.MfgHeartbeatRequest).subscribe({
            next: res => {
                this.MfgHearbeatReportList = res;
                this.MfgHearbeatChartDataList = res.ChartData;
                this.MfgHearbeatTableDataList = res.TableData;

                if (this.MfgHearbeatTableDataList.length > 0) {

                    let count = this.totalItemsCount = 0;
                    this.MfgHearbeatTableDataList.forEach((x) => {
                        count++
                        x.SerialNo = count;
                        x.JumboRollDate = formatDate(x.JumboRollDate.toString(), 'dd-MM-yyyy', "en-US", 'IST');
                        x.JumboRollStartTime = new Date(new DatetimeConverter().transform(x.JumboRollStartTime.toString()));
                        x.JumboRollEndTime = new Date(new DatetimeConverter().transform(x.JumboRollEndTime.toString()));                        
                    })
                    this.totalItemsCount = count;

                }
                else {
                    this.IsMsgShow = true;
                }

                if (this.MfgHearbeatChartDataList.length > 0 && this.activeTab === 'chart') {
                    this.GetMfgHeartbeatChart();
                }
                this.isTableLoading = false;
            },
            error: res => {
                this.count++;
                if (this.count < 2) { this.GetMfgHearbeatReportData(); }
            }
        });
    }

    OpenSaleOrderDetailViewPop(data: any) {
        this.soDrawer.SaleOrderId = data;
        this.soDrawer.show();
    }

    onTabChange(index: number): void {
        this.activeTab = index === 0 ? 'chart' : 'table';
        if (this.activeTab === 'chart') {
            this.GetMfgHeartbeatChart();
        }
    }
}
