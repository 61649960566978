import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ELOOP } from 'constants';
import { environment } from 'src/environments/environment';
import {
  StoreWiseStockModel,
  StoreWiseStockStoreViewModel,
  StoreWiseStockViewModel,
} from 'src/PmsUIApp/Models/StockModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { ProductModel } from '../../Models/ProductModel';
import { AuthService } from '../../Services/auth.service';
import moment from 'moment';
import { StoreModel } from 'src/PmsUIApp/Models/MasterModel';

@Component({
  selector: 'app-store-wise-stock',
  templateUrl: './StoreWiswStock.html',
  styleUrls: ['./StoreWiseStock.css'],
})
export class StoreWiseStockComponent implements OnInit {
  selectedStoreDn: any = 'All';
  StoreName: any = 'All';
  hideCol = false;

  storeNameList: any = [];
  ApiUrl = environment.Api_Url;
  storeSearch: any[] = [];
  valll: any;
  storeWiseList: any[] = [];
  FilteredStoreWiseList: any[] = [];
  selectedStore: StoreModel;
  latestFields: StoreWiseStockModel = new StoreWiseStockModel();
  exportlatestFields: StoreWiseStockModel[] = [];
  ProductList: ProductModel[] = [];
  searchValue = '';
  isPageLoading: boolean = false;
  products = [];
  myArr = [];
  productStoreData: any = {};
  exportoptions = {
    headers: [
      'SNO',
      'PRODUCT NAME',
      'PRODUCT TYPE',
      'TOTAL QUANTITY',
      'STORE NAME',
      'RACK NAME',
      'QUANTITY PER RACK',
      'UNIT',
    ],
  };
  fields: StoreWiseStockModel = new StoreWiseStockModel();
  exportfields: StoreWiseStockModel[] = [];
  FilteredProductList: StoreWiseStockViewModel[] = [];
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  StoreList: StoreModel[];
  count: number = 0;
  constructor(
    public http: HttpClient,
    public route: ActivatedRoute,
    public router: Router, private auth: AuthService
  ) {
    this.FilteredStoreWiseList = [];
    this.storeWiseList = [];
  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsStoreWiseStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsStoreWiseStock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsStoreWiseStock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProducts();
  }

  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
        this.GetAllStore();
        this.GetStockWise();
      },
      (res) => { }
    );
  }
  GetAllStore() {
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  SearchWithFilter() {
    this.GetStockWise();
  }
  GetStockWise() {
    this.isPageLoading = true;
    var storeid = 0
    this.FilteredStoreWiseList = [];
    this.FilteredProductList = [];
    if (this.selectedStore == undefined) {
      storeid = 0
    }
    else {
      storeid = this.selectedStore.StoreId
    }
    let url = this.ApiUrl + 'stock/getproductwisestorerackstockbystoreid/' + storeid;
    this.http.get<StoreWiseStockModel[]>(url).subscribe((res) => {
      console.log(res);

      this.storeNameList = res.map((x) => x.StoreName);
      this.storeNameList = [...new Set(this.storeNameList)];

      console.log('this.storeNameList', this.storeNameList);
      let uniqueProduct = [...new Set(res.map((item) => item.ProductId))];
      console.log(uniqueProduct);

      var fp = this.ProductList.filter((x) =>
        uniqueProduct.includes(x.ProductId)
      );
      fp.forEach((x) => {
        var it = new StoreWiseStockViewModel();
        it.ProductId = x.ProductId;
        it.ProductCode = x.ProductCode;
        it.ProductType = x.ProductType;
        it.ProductName = x.ProductName;

        it.Quantity =
          res
            .filter((y) => y.ProductId == x.ProductId)

            .reduce(
              (sum, current) => sum + parseFloat(current.Quantity?.toString()),
              0
            ) ?? 0;
        it.Unit = x.Unit;

        res
          .filter((y) => y.ProductId == x.ProductId)
          .forEach((iit) => {
            var st = new StoreWiseStockStoreViewModel();
            st.StoreName = iit.StoreName;
            st.RackName = iit.RackName;
            st.Quantity = iit.Quantity;
            st.Unit = iit.Unit;
            it.StoreDetails.push(st);
          });

        this.FilteredProductList.push(it);
      });
      console.log(this.FilteredProductList);
      this.storeWiseList = this.FilteredProductList; //this.structureResponce(res);

      this.FilteredStoreWiseList = [...this.FilteredProductList];
      this.setExportData(this.storeWiseList);
      this.isPageLoading = false;
    });
  }

  setExportData(data: any[]) {
    this.exportfields = [];
    data.forEach((x) => {
      this.latestFields = new StoreWiseStockModel();
      this.latestFields.SNO = x.SNO;

      this.latestFields.ProductName = x.ProductName;
      this.latestFields.ProductType = x.ProductType;
      this.latestFields.StoreName = x.StoreName;
      this.latestFields.RackName = x.RackName;
      this.latestFields.Quantity = x.Quantity;
      this.latestFields.StoreDetails = x.StoreDetails;
      this.exportfields.push(x);
      x.StoreDetailsCopy = [...x.StoreDetails];
    });
    this.structureExportFields();
  }

  structureExportFields() {
    let resultArr = [];

    for (let j = 0; j < this.exportfields.length; j++) {
      if (this.exportfields[j].StoreDetails.length == 1) {
        const obj = {
          SNO: j + 1,

          ProductName: this.exportfields[j].ProductName,
          ProductType: this.exportfields[j].ProductType,
          TotalQuantity: this.exportfields[j].Quantity,
        };
        resultArr.push({ ...obj, ...this.exportfields[j]['StoreDetails'][0] });
      } else if (this.exportfields[j].StoreDetails.length > 1) {
        for (let i = 0; i < this.exportfields[j].StoreDetails.length; i++) {
          if (i == 0) {
            const obj = {
              SNO: j + 1,

              ProductName: this.exportfields[j].ProductName,
              ProductType: this.exportfields[j].ProductType,
              TotalQuantity: this.exportfields[j].Quantity,
            };
            resultArr.push({
              ...obj,
              ...this.exportfields[j]['StoreDetails'][i],
            });
          } else {
            let obj = {
              SNO: '',

              ProductName: '',
              ProductType: '',
              TotalQuantity: '',
              ...this.exportfields[j]['StoreDetails'][i],
            };

            resultArr.push(obj);
          }
        }
      }
    }

    this.exportfields = resultArr;

    console.log(
      'exportFieldsData here',
      this.exportfields,
      'result formed',
      resultArr
    );
  }

  // structureResponce(data: any[]) {
  //   let structureData: any = {};
  //   let structureProduct: any = {};
  //   let productsUniqueData: any = [];
  //   for (let i = 0; i < data.length - 1; i++) {
  //     if (!structureData[data[i].StoreName] && data[i].Quantity > 0) {
  //       structureData[data[i].StoreName] = [
  //         {
  //           racks: data[i].RackName,
  //           rackQuantity: data[i].Quantity,
  //           productName: data[i].ProductName,
  //         },
  //       ];
  //     } else if (structureData[data[i].StoreName] && data[i].Quantity > 0) {
  //       if (
  //         !structureData[data[i].StoreName].filter(
  //           (el: any) => el.rackName == data[i].RackName
  //         ).length
  //       ) {
  //         structureData[data[i].StoreName].push({
  //           racks: data[i].RackName,
  //           rackQuantity: data[i].Quantity,
  //           productName: data[i].ProductName,
  //         });
  //       }
  //     }
  //   }
  //   for (let i = 0; i < data.length - 1; i++) {
  //     let totalQuantity = 0;
  //     if (!structureProduct[data[i].ProductName]) {
  //       structureProduct[data[i].ProductName] = [
  //         {
  //           productType: data[i].ProductType,
  //           productName: data[i].ProductName,
  //           totalQuantity: totalQuantity + data[i].Quantity,
  //           storeName: data[i].StoreName,
  //           storeData: { ...structureData }[data[i].StoreName].filter(
  //             (el: any) => el.productName == data[i].ProductName
  //           ),
  //         },
  //       ];
  //       totalQuantity = data[i].Quantity;
  //     } else if (
  //       structureProduct[data[i].ProductName] &&
  //       !structureProduct[data[i].ProductName].filter(
  //         (el: any) => el.storeName == data[i].StoreName
  //       ).length
  //     ) {
  //       structureProduct[data[i].ProductName].push({
  //         productType: data[i].ProductType,
  //         productName: data[i].ProductName,
  //         storeName: data[i].StoreName,
  //         totalQuantity: totalQuantity + data[i].Quantity,
  //         storeData: { ...structureData }[data[i].StoreName].filter(
  //           (el: any) => el.productName == data[i].ProductName
  //         ),
  //       });
  //     }
  //   }

  //   this.productStoreData = structureProduct;
  //   for (let i = 0; i < data.length - 1; i++) {
  //     const count = productsUniqueData.filter(
  //       (el: any) => el.ProductName == data[i].ProductName
  //     ).length;
  //     if (!count) {
  //       productsUniqueData.push({
  //         ...data[i],
  //         Quantity: data[i].Quantity,
  //       });
  //     } else {
  //       let _index = productsUniqueData.findIndex(
  //         (el: any) => el.ProductName == data[i].ProductName
  //       );

  //       productsUniqueData[_index].Quantity += data[i].Quantity;
  //     }
  //   }
  //   return productsUniqueData;
  // }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'Store-Wise-Stock' + exportdate, this.exportoptions);
  }

  onShow(val: any) {
    this.hideCol = true;
    this.selectedStoreDn = val;
    const res = [...this.storeWiseList];
    console.log(`value`);
    if (this.selectedStoreDn != 'All') {
      this.FilteredStoreWiseList = res.filter((item: StoreWiseStockModel) => {
        const data = item.StoreDetails.filter((x: any) => {
          if (
            x.StoreName.toLowerCase().includes(
              this.selectedStoreDn.toLowerCase()
            )
          ) {
            return true;
          } else if (
            x.RackName.toLowerCase().includes(this.searchValue.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (data.length) {
          item.StoreDetailsCopy = data;
          // item.StoreDetailsCopy = data.filter((x: any) =>
          //   x.RackName.toLowerCase().includes(
          //     this.selectedStoreDn.toLowerCase()
          //   )
          // );
          item.calculatedQuantity = item.StoreDetailsCopy.reduce(
            (acc: any, curr: any) => acc + curr.Quantity,
            0
          );

          return !!item.StoreDetailsCopy.length;
        } else {
          return false;
        }
      });
    } else {
      this.FilteredStoreWiseList = [...this.storeWiseList];
      this.FilteredStoreWiseList.forEach((el) => {
        el.StoreDetailsCopy = [];
        el.calculatedQuantity = null;
        this.hideCol = false;
      });
    }
    this.Search()
  }


  Search() {
    console.log(this.storeWiseList.length)
    if (this.searchValue == '' && this.selectedStoreDn == 'All') {
      this.FilteredStoreWiseList = this.storeWiseList;
    }
    else if (this.searchValue && this.selectedStoreDn == 'All') {
      this.FilteredStoreWiseList = [];

      //this.storeWiseList.filter((item: StoreWiseStockModel) => {

      //  item.StoreDetailsCopy = item.StoreDetails.filter((y: StoreWiseStockStoreViewModel) => y.StoreName == this.selectedStoreDn)
      //  item.StoreDetailsCopy.filter((x: StoreWiseStockStoreViewModel) => {

      //    if (x.StoreName == this.selectedStoreDn) {
      //      this.FilteredStoreWiseList.push(item)
      //    }
      //  })
      //  item.calculatedQuantity = item.StoreDetailsCopy.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
      //});
      this.FilteredStoreWiseList = this.storeWiseList.filter((item: StoreWiseStockModel) => {
        if (item.ProductType?.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return true;
        }
        else if (item.ProductName.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return true;
        }
        const data = item.StoreDetailsCopy.filter((x: any) => {
          if (x.RackName.toLowerCase().includes(this.searchValue.toLowerCase())) {
            return true;
          }
          else {
            return false;
          }
        });
        if (data.length) {
          item.StoreDetailsCopy = data;
          if (item.ProductType.toLowerCase().includes(this.searchValue.toLowerCase())) {
            item.calculatedQuantity = data.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            console.log('calculated quantity here', item.calculatedQuantity);
            return true;
          }
          else if (item.ProductName.toLowerCase().includes(this.searchValue.toLowerCase())) {
            item.calculatedQuantity = data.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            console.log('calculated quantity here', item.calculatedQuantity);
            return true;
          }
          else if (item.Quantity == +this.searchValue) {
            item.calculatedQuantity = data.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            console.log('calculated quantity here', item.calculatedQuantity);
            return true;
          }
          else {
            item.StoreDetailsCopy = data.filter((x: any) =>
              x.RackName.toLowerCase().includes(this.searchValue.toLowerCase())
            );
            item.calculatedQuantity = item.StoreDetailsCopy.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            return !!item.StoreDetailsCopy.length;
          }

          return false;
        } else {
          return false;
        }
      })
    }
    else if (this.searchValue && this.selectedStoreDn != 'All') {
      this.FilteredStoreWiseList = [];

      this.storeWiseList.filter((item: StoreWiseStockModel) => {

        item.StoreDetailsCopy = item.StoreDetails.filter((y: StoreWiseStockStoreViewModel) => y.StoreName == this.selectedStoreDn)
        item.StoreDetailsCopy.filter((x: StoreWiseStockStoreViewModel) => {

          if (x.StoreName == this.selectedStoreDn) {
            if (this.FilteredStoreWiseList.filter((it: StoreWiseStockModel) => it.ProductId == item.ProductId).length == 0) {
              this.FilteredStoreWiseList.push(item)
            }
          }
        })
        item.calculatedQuantity = item.StoreDetailsCopy.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
      });
      this.FilteredStoreWiseList = this.FilteredStoreWiseList.filter((item: StoreWiseStockModel) => {
        if (item.ProductType.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return true;
        }
        else if (item.ProductName.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return true;
        }
        const data = item.StoreDetailsCopy.filter((x: any) => {
          if (x.RackName.toLowerCase().includes(this.searchValue.toLowerCase())) {
            return true;
          }
          else {
            return false;
          }
        });
        if (data.length) {
          item.StoreDetailsCopy = data;
          if (item.ProductType.toLowerCase().includes(this.searchValue.toLowerCase())) {
            item.calculatedQuantity = data.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            console.log('calculated quantity here', item.calculatedQuantity);
            return true;
          }
          else if (item.ProductName.toLowerCase().includes(this.searchValue.toLowerCase())) {
            item.calculatedQuantity = data.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            console.log('calculated quantity here', item.calculatedQuantity);
            return true;
          }
          else if (item.Quantity == +this.searchValue) {
            item.calculatedQuantity = data.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            console.log('calculated quantity here', item.calculatedQuantity);
            return true;
          }
          else {
            item.StoreDetailsCopy = data.filter((x: any) =>
              x.RackName.toLowerCase().includes(this.searchValue.toLowerCase())
            );
            item.calculatedQuantity = item.StoreDetailsCopy.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
            return !!item.StoreDetailsCopy.length;
          }

          return false;
        } else {
          return false;
        }
      })

    }
    else {
      //console.log(this.storeWiseList)
      this.FilteredStoreWiseList = [];
      this.storeWiseList.filter((item: StoreWiseStockModel) => {
        item.StoreDetailsCopy = item.StoreDetails.filter((y: StoreWiseStockStoreViewModel) => y.StoreName == this.selectedStoreDn)
        item.StoreDetailsCopy.filter((x: StoreWiseStockStoreViewModel) => {

          if (x.StoreName == this.selectedStoreDn) {
            if (this.FilteredStoreWiseList.filter((it: StoreWiseStockModel) => it.ProductId == item.ProductId).length == 0) {
              this.FilteredStoreWiseList.push(item)
            }
          }
        })
        item.calculatedQuantity = item.StoreDetailsCopy.reduce((acc: any, curr: any) => acc + curr.Quantity, 0);
      });
      console.log(this.FilteredStoreWiseList)
    }
    this.setExportData(this.FilteredStoreWiseList);
  }
  reset(): void {
    this.searchValue = '';
    this.hideCol = false;

    this.onShow(this.selectedStoreDn);
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.Search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
}
