import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import {
  ProductSecSubCategoryModel,
  ProductFirstSubCategoryModel,
  ProductCategoryModel,
  DeliveryTermModel,
  PaymentTermModel,
} from '../../../Models/MasterModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../../Models/ProductModel';
import { CustomerModel } from '../../../Models/SupplierModel';
import { TransportModel } from '../../../Models/TransportModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserInfo } from '../../../Authentication/UserInfo';
import { Subscription } from 'rxjs';
import { ProformaInvoiceItemModel, ProformaInvoiceModel } from '../../../Models/ProformaModel';
import { FormulationCodeModel } from '../../../Models/FormulationCodeModel';
import { BankMasterModel } from '../../../Models/BankModel';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';

@Component({
  selector: 'app-proformaAdd',
  templateUrl: './proformaAdd.component.html',
  styleUrls: ['./proformaAdd.component.css'],
})
export class proformaAddComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  CustomerList: CustomerModel[] = [];
  ProductList: ProductModel[] = [];
  FormulationCodeList: FormulationCodeModel[] = [];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  TransportList: TransportModel[] = [];
  DeliveryTermList: DeliveryTermModel[] = [];
  PaymentTermList: PaymentTermModel[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  NewPO: ProformaInvoiceModel = new ProformaInvoiceModel();
  NewInvoiceItem: ProformaInvoiceItemModel = new ProformaInvoiceItemModel();
  selectedproduct: any;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  CurrencyList: string[] = [];
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: 'NA',
    IGST: 0,
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  };
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  private route$: Subscription = new Subscription();
  BankList: BankMasterModel[] = [];
  ProformaInvoiceId = 0;
  ActionType = '';
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) { }
  handleOk(): void {
    this.isLoading = true;

    const customerId = this.NewPO?.CustomerId;
    const deliveryTermId = this.NewPO?.DeliveryTermId;
    const bankId = this.NewPO?.BankId;
    const modeOfTransport = this.NewPO?.ModeOfTransport;
    const buyerRefNo = this.NewPO?.BuyerReferenceNumber;
    const discount = this.NewPO?.Discount;
    const countryOfOrigin = this.NewPO?.CountryOfOrigin;
    const hsnCode = this.NewPO?.Hsncode;
    const finalDestination = this.NewPO?.FinalDestination;
    const refType = this.NewPO?.ReferenceType;
    const consignorReference = this.NewPO?.ConsignorReference;
    const piDate = this.NewPO?.ProformaInvoiceDate;
    const portOfLoading = this.NewPO?.PortOfLoading;
    const portDischarge = this.NewPO?.PortOfDischarge;
    const moq = this.NewPO?.Moq;
    const igst = this.NewPO?.Gstn;

    const missingFields = [];
    if (!customerId) missingFields.push('Customer');
    if (!deliveryTermId) missingFields.push('Delivery Term');
    if (!bankId) missingFields.push('Bank');
    if (!modeOfTransport) missingFields.push('Mode of Transport');
    if (!buyerRefNo) missingFields.push('Buyer Ref No');
    if (!discount && discount !== 0) missingFields.push('Discount %');
    if (!countryOfOrigin) missingFields.push('Country of Origin');
    if (!hsnCode) missingFields.push('HSN Code');
    if (!finalDestination) missingFields.push('Final Destination');
    if (!refType) missingFields.push('Ref Type');
    if (!consignorReference) missingFields.push('Consignor Reference');
    if (!piDate) missingFields.push('PI Date');
    if (!portOfLoading) missingFields.push('Port of loading');
    if (!portDischarge) missingFields.push('Port Discharge');
    if (!moq && moq !== 0) missingFields.push('MOQ %');
    if (!igst) missingFields.push('IGST');

    if (missingFields.length > 0) {
      const errorMessage = `Please fill in all mandatory fields: ${missingFields.join(', ')}`;
      this.alertService.error(errorMessage);
      this.isLoading = false;
      return;
    }

    this.Save();
  }
  Save() {

    if (this.NewPO.ProformaInvoiceItem.length <= 0) {
      this.isLoading = false;
      this.alertService.error('Please add Items');
      return;
    }
    this.NewPO.TotalPrice = this.NewPO.ProformaInvoiceItem.reduce((sum, current) => sum + parseFloat(current.Amount.toString()), 0) ?? 0
    this.NewPO.Moqtotal = (this.NewPO.TotalPrice * this.NewPO.Moq) / 100
    this.NewPO.Gst = ((this.NewPO.TotalPrice + this.NewPO.Moqtotal) * parseInt(this.NewPO.Gstn)) / 100
    this.NewPO.Currency = this.f['Currency'].value;
    this.NewPO.AddedBy = UserInfo.EmailID;
    var url = this.ApiUrl + 'proformainvoice/addproformainvoice';
    if (this.NewPO.ProformaInvoiceId > 0) {
      url = this.ApiUrl + 'proformainvoice/updateproformainvoice';

    }

    this.http.post<any>(url, this.NewPO).subscribe({
      next: (res) => {
        this.alertService.success('Entry Saved Successfully');
        this.isLoading = false;

        this.router.navigate(['/home/salesorder/proformaInvoice/list']);
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.SalesOrderProforma, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.SalesOrderProforma, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.SalesOrderProforma, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/welcome']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProformaInvoiceId = params['id'];

    });
    this.validateForm = this.fb.group({
      Currency: ['INR'],
      MaterialType: [null, [Validators.maxLength(49)]],
      ModeofTransport: [null, [Validators.maxLength(49)]],
      BuyerRefNo: [null, [Validators.maxLength(49)]],
      CountryofOrigin: [null, [Validators.maxLength(49)]],
      HSNCode: [null, [Validators.maxLength(49)]],
      FinalDestination: [null, [Validators.maxLength(49)]],
      ConsignoReferencer: [null, [Validators.maxLength(49)]],
      Portofloading: [null, [Validators.maxLength(49)]],
      PortDischarge: [null, [Validators.maxLength(49)]],
      DescriptionofGoods: [null, [Validators.maxLength(149)]],
      ArticleName: [null, [Validators.maxLength(49)]],
      TermsAndCondition: [null, [Validators.maxLength(49)]],
      deliveryTermId: [null, [Validators.maxLength(49)]]
    });
    this.NewPO.ProformaInvoiceDate = new Date().toUTCString();
    this.NewPO.TermsCondition = "Terms & Conditions";
    this.GetAllCustomer()
    this.GetAllUnits();
    this.GetAllCurrency();
    this.GetAllFormulationCode()
    this.GetAllBank()
    this.GetAllDeliveryTerm()
    if (this.ProformaInvoiceId > 0) {
      this.GetAllProformaInvoice()
    }
  }
  get f() {
    return this.validateForm.controls;
  }

  GetAllCustomer() {
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }

  GetAllUnits() {
    let url = this.ApiUrl + 'data/GetMeasureUnits';
    this.http.get<MeasureUnitModel[]>(url).subscribe(
      (res) => {
        this.MeasureUnits = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllUnits();
        }
      }
    );
  }
  GetAllDeliveryTerm() {

    let url = this.ApiUrl + "deliveryterm/getalldeliveryterms";
    this.http.get<DeliveryTermModel[]>(url).subscribe(res => {
      this.DeliveryTermList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllDeliveryTerm(); }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  GetAllProformaInvoice() {
    let url = this.ApiUrl + 'proformainvoice/getallproformainvoices';
    this.http.get<ProformaInvoiceModel[]>(url).subscribe(
      (res) => {

        this.NewPO = res.filter(x => x.ProformaInvoiceId == this.ProformaInvoiceId)[0];
        this.NewPO.ProformaInvoiceItem.forEach(x => {
          x.Amount = x.Quantity * x.Price
        })
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProformaInvoice();
        }
      }
    );
  }
  updateTermsAndCondition(selectedDeliveryTermId: any) {
    const selectedDeliveryTerm = this.DeliveryTermList.find(term => term.DeliveryTermId === selectedDeliveryTermId);
    if (selectedDeliveryTerm) {
      this.NewPO.TermsCondition = selectedDeliveryTerm.DeliveryTerm;
      this.NewPO.DeliveryTermId = selectedDeliveryTerm.DeliveryTerm;
    }
  }
  GetAllBank() {


    this.isTableLoading = true;
    let url = this.ApiUrl + "bank/getallbanks";
    this.http.get<BankMasterModel[]>(url).subscribe(res => {
      this.BankList = res;
      this.isTableLoading = false;

      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllBank();
      }
    });
  }
  GetAllCurrency() {
    let url = this.ApiUrl + "data/getcurrencylist";
    this.http.get<string[]>(url).subscribe(res => {
      this.CurrencyList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCurrency(); }
    });
  }
  OnRatechange(data: any) {
    var amt = data * this.NewInvoiceItem.Quantity;

    this.NewInvoiceItem.Amount = parseFloat(amt.toFixed(3));
  }
  OnQtychange(data: any) {
    var amt = data * this.NewInvoiceItem.Price;

    //this.Amount = amt;
    this.NewInvoiceItem.Amount = parseFloat(amt.toFixed(3));
  }
  btnAddClick() {

    this.NewInvoiceItem.SaleFormulationCodeProductName = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.NewInvoiceItem.SaleFormulationCodeId)[0].SaleFormulationCode
    this.NewInvoiceItem.Amount = parseFloat((this.NewInvoiceItem.Quantity * this.NewInvoiceItem.Price).toFixed(3));
    this.NewInvoiceItem.Price = parseFloat(parseFloat(this.NewInvoiceItem.Price.toString()).toFixed(3));
    this.NewPO.ProformaInvoiceItem.push(this.NewInvoiceItem);
    this.NewInvoiceItem = new ProformaInvoiceItemModel;
  }
  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ProductID == '0') {
      this.alertService.error('Select Product');
      Isvalid = false;
    } else if (model.Rate == '0') {
      this.alertService.error('Enter Rate');
      Isvalid = false;
    } else if (model.Quantity == '0') {
      this.alertService.error('Enter Quantity');
      Isvalid = false;
    } else if (model.IGST == '') {
      this.alertService.error('Enter IGST');
      Isvalid = false;
    }
    return Isvalid;
  }
  handleRemoveRow(data: any) {
    this.NewPO.ProformaInvoiceItem = this.NewPO.ProformaInvoiceItem.filter((obj) => obj !== data);

  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase() == 'admin').length <= 0) {
      return startValue.getTime() < new Date().getTime();
    }
    else {
      return false;
    }
  };
}
