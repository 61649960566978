import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ConsumeStockProductMasterModel, ConsumeStockProductPrintMasterModel, ConsumptionPendingListModel, ConsumptionPendingPrintModel } from '../../Models/ConsumptionModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
import { AuthService } from '../../Services/auth.service';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { ColorModel, GrainModel, ThicknessModel } from 'src/PmsUIApp/Models/MasterModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { JSONParser } from '@amcharts/amcharts5';

@Component({
  selector: 'app-pendinglist',
  templateUrl: './pendinglist.component.html',
  styleUrls: ['./pendinglist.component.css']
})
export class ConsumptionPendingListComponent implements OnInit {

  myDateValue: Date | undefined;
  isValidDate: any
  ApiUrl = environment.Api_Url;
  ConsumptionPendingList: ConsumptionPendingListModel[] = [];
  ConsumptionPendingListOriginal: ConsumptionPendingListModel[] = [];
  ConsumptionPendingListPrint: ConsumptionPendingPrintModel[] = [];
  ConsumptionPendingListView: ConsumptionPendingPrintModel[] = [];
  ConsumptionPrint!: ConsumptionPendingPrintModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;

  PopUpTitle: string = "Add New Issue";
  searchValue = '';
  toDate: Date | undefined;
  visible = false;
  fields: ConsumptionPendingPrintModel = new ConsumptionPendingPrintModel();


  exportfields: ConsumptionPendingPrintModel[] = [];

  exportoptions = {
    headers: [
      "Sale Order No",
      "WorkPlan No",
      "MFD QTY",
      "Inspection Completed Date",
      "Inspection Completed By",
      "Inspection Store",
      "Production Completed Date",
      "Production Completed By"
    ]
  };
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false

  }
  CustomerList: CustomerModel[];
  FormulationCodeList: FormulationCodeModel[];
  ColorList: ColorModel[];
  GrainList: GrainModel[];
  request = {
    FromInspectionDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
    ToInspectionDate: new Date(new Date().setHours(23, 59, 59)),
    FromProductionDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
    ToProductionDate: new Date(new Date().setHours(23, 59, 59)),
    SaleFormulationCodeId: 0,
    SaleOrderNumber: null,
    DateType: 'productiondate', // options are - inspectiondate, productiondate
    Status: [],
    AddedBy: '',
    ArticleName: '',
    CustomerId: 0,
    ThicknessId: 0,
    GrainId: 0,
    ColorId: 0,
    ProductType: '', //options are - GZ- or GZY-
    OrderType: '',
    ShowConsumedOrders: false
  }
  dateTypeList = [
    {
      "Text": "Production Date",
      "Value": 'productiondate'
    },
    {
      "Text": "Inspection Date",
      "Value": 'inspectiondate'
    }
  ];
  PUPVCTypeList: any = [
    {
      "Text": "GZ(PVC)",
      "Value": 'GZ-'
    },
    {
      "Text": "GZY(PU)",
      "Value": 'GZY-'
    }
  ]
  totalItemsCount: number = 0;
  ThicknessList: ThicknessModel[];
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService,
    private router: Router, private auth: AuthService, private loader: LoadingService
  ) { }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    const existingFilters = sessionStorage.getItem('requestfilters');
    if (existingFilters) {
      this.request = JSON.parse(existingFilters);
    }
    this.GetPendingConsumptionOrders();
  }
  GetPendingConsumptionOrders() {
    this.loader.show();
    var item = ["RawMaterialIssued",
      "ProductionStarted",
      "Mixing",
      "InJumbo",
      "MoveToPostProcess",
      "PrintAssigned",
      "PrintInProcess",
      "PrintCompleted",
      "EmbossingAssigned",
      "EmbossingInProcess",
      "EmbossingCompleted",
      "VacuumAssigned",
      "VacuumInProcess",
      "VacuumCompleted",
      "LacquerAssigned",
      "LacquerInProcess",
      "LacquerCompleted",
      "TumblingAssigned",
      "TumblingInProcess",
      "TumblingCompleted",
      "JumboInspection",
      "MoveToDispatch",
      "DispatchReady",
      "PartialDispatchReady",
      "PartialDispatchCompleted"];
    this.request.Status = item;
    let url = this.ApiUrl + "consume/getallpendingconsumptionorderswithfilter";
    this.http.post<ConsumptionPendingListModel[]>(url, this.request).subscribe(res => {
      this.ConsumptionPendingListOriginal = res;
      this.ConsumptionPendingList = res;
      this.isTableLoading = false;
      this.exportfields = [];
      let count = this.totalItemsCount = 0;
      this.ConsumptionPendingList.forEach((x) => {
        count++;
        x.SerialNo = count;
        this.fields = new ConsumptionPendingPrintModel()
        this.fields.SaleOrderNo = x.SaleOrderNo
        this.fields.WorkPlanNo = x.WorkPlanNo
        this.fields.OrderQty = x.OrderQty
        this.fields.MFDQTY = x.MFDQTY
        this.fields.PreInspectionCompletedDate = x.PreInspectionCompletedDate
        this.fields.PreInspectionCompletedBy = x.PreInspectionCompletedBy
        this.fields.PreInspectionStore = x.PreInspectionStore
        this.fields.ProductionCompletedDate = x.ProductionCompletedDate
        this.fields.ProductionCompletedBy = x.ProductionCompletedBy
        this.exportfields.push(this.fields);
        this.isTableLoading = false;
      })
      this.totalItemsCount = count;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetPendingConsumptionOrders();
      }
    });

  }
  onFilterChange() {
    sessionStorage.setItem('requestfilters', JSON.stringify(this.request));
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      //event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  GetAllCustomer() {
    this.loader.show();
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe({
      next: res => {
        this.CustomerList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetAllCustomer(); }
      }
    });
  }
  GetAllFormulationCode() {
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getallformulationcodesforfilter";
    this.http.get<FormulationCodeModel[]>(url).subscribe({
      next: res => {
        this.FormulationCodeList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetAllFormulationCode(); }
      }
    });
  }
  GetAllColor() {
    this.loader.show();
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe({
      next: res => {
        this.ColorList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllColor();
        }
      }
    });
  }
  GetAllGrain() {
    this.loader.show();
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe({
      next: res => {
        this.GrainList = res;
        this.loader.hide();
      }, error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllGrain();
        }
      }
    });
  }
  GetAllThickness() {
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllThickness()
      }
    });
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllCustomer();
      this.GetAllFormulationCode();
      this.GetAllColor();
      this.GetAllGrain();
      this.GetAllThickness();
    }
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        ('Consumption-export' + exportdate),
        this.exportoptions
      );
  }
  GotoConsumption(event: Event, data: ConsumptionPendingListModel): void {
    event.preventDefault();

    // Navigate within the app using Angular's Router
    const path = this.getConsumptionPath(data);
    this.router.navigateByUrl(path);
  }
  getConsumptionPath(data: ConsumptionPendingListModel): string {
    return '/home/consumption/add/' + data.WorkPlanId + "/" + data.SaleOrderId + "/" + this.request.ShowConsumedOrders
  }
}
