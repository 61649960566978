import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { BranchModel, DeptModel, StoreModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  StoreList: StoreModel[] = [];
  StoreListOriginal: StoreModel[] = [];
  BranchList: BranchModel[] = [];
  DeptList: DeptModel[] = [];
  NewStore: StoreModel = new StoreModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Store";
  NameError = 'Enter Store name (maximum 50 characters)';
  CodeError = 'Enter Store code';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  visible = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: number;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Store, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Store, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Store, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }


    this.validateForm = this.fb.group({
      StoreName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      StoreCode: [null, [Validators.required], [this.CodeAsyncValidator]],
      IsWorkInProgressStore: [null, [Validators.required]],
      StoreDesc: [null, [Validators.maxLength(200)]],
      DeptId: [null, [Validators.required]],
      BranchId: [null, [Validators.required]]

    });
    this.GetAllStore();
    this.GetAllBranch();
    this.GetAllDept();
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }

  GetAllStore() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;
      this.StoreListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllStore(); }
    });
  }
  GetAllBranch() {

    let url = this.ApiUrl + "branch/getallbranches";
    this.http.get<BranchModel[]>(url).subscribe(res => {
      this.BranchList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllBranch(); }
    });
  }
  GetAllDept() {

    let url = this.ApiUrl + "dept/getalldepts";
    this.http.get<DeptModel[]>(url).subscribe(res => {
      this.DeptList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllDept(); }
    });
  }
  SaveStore() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewStore.StoreName = this.f['StoreName'].value;
    this.NewStore.StoreCode = this.f['StoreCode'].value;
    this.NewStore.IsWorkInProgressStore = this.f['IsWorkInProgressStore'].value;
    this.NewStore.StoreDesc = this.f['StoreDesc'].value;
    this.NewStore.DeptId = this.f['DeptId'].value;
    this.NewStore.BranchId = this.f['BranchId'].value;
    this.NewStore.StoreAddedBy = UserInfo.EmailID;

    let url = this.ApiUrl + "store/addupdatestore";
    this.http.post<StoreModel>(url, this.NewStore).subscribe({

      next: res => { this.alertService.success("Store Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllStore(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: StoreModel) {
    this.PopUpTitle = "Modify Store Details";
    this.validateForm.setValue(
      {
        StoreName: data.StoreName,
        StoreCode: data.StoreCode,
        IsWorkInProgressStore: data.IsWorkInProgressStore,
        StoreDesc: data.StoreDesc,
        DeptId: data.DeptId,
        BranchId: data.BranchId
      }
    )
    this.NewStore = new StoreModel();
    this.NewStore.StoreId = data.StoreId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Store";
    this.validateForm.reset();
    this.NewStore.StoreName = "";
    this.NewStore.StoreId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveStore();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteStore() {
    let url = this.ApiUrl + "Store/deleteStore";
    this.http.post<any>(url, this.NewStore.StoreId).subscribe({

      next: res => {

        this.alertService.success("Store Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllStore();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: StoreModel) {
    this.NewStore = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Store?',
      nzOnOk: () => this.DeleteStore(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.StoreList.filter(x => x.StoreId != this.NewStore.StoreId);
        var nre = res.filter(x => x.StoreName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Store name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Store name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Store name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  CodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.StoreList.filter(x => x.StoreId != this.NewStore.StoreId);
        var nre = res.filter(x => x.StoreCode.toLowerCase() == control.value.toLowerCase());

        this.CodeError = '';
        if (control.value == '') {
          this.CodeError = "Enter Store code";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.CodeError = "Store code already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.CodeError = "Enter Store code";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllStore();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.StoreListOriginal;
    var res2 = this.StoreListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: StoreModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.StoreAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.StoreAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });


      this.StoreList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.StoreList = res.filter(
        (item: StoreModel) =>
          item?.StoreName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.StoreCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.StoreDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.BranchCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.DeptCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.StoreAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())



      )
    }

  }



}
