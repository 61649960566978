import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()

export class PoDrawerService {
    private _loading = new BehaviorSubject<boolean>(false);
    public readonly loading$ = this._loading.asObservable();
    public Poid: string;
    @Output() event_callback: EventEmitter<any> = new EventEmitter();
    show() {
        console.log("Is these line running**", this.Poid);
        this.event_callback.emit(this.Poid);
        this._loading.next(true);
    }
    hide() {
        this._loading.next(false);
    }
}
