import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()

export class TimelineService {
    private _loading = new BehaviorSubject<boolean>(false);
    public readonly loading$ = this._loading.asObservable();
    public SaleOrderNo: string;
    @Output() event_callback: EventEmitter<any> = new EventEmitter();
    show() {
        this.event_callback.emit(this.SaleOrderNo);
        this._loading.next(true);
    }
    hide() {
        this._loading.next(false);
    }
    
   
}
