<div class="card card-flush h-xl-100" *ngIf="IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Manage Users</nz-page-header-title>

      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue"  (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" 
      />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small" [nzData]="['']" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable1 nzBordered>
      <thead>
        <tr>

          <th nzWidth="50px">

          </th>
          <th nzWidth="250px" nzLeft>
            Full Name

          </th>
          <th >Role</th>
          <th nzWidth="250px">Username</th>
          <th nzWidth="100px">Mobile</th>

          <!--<th nzWidth="100px" style="text-align:center">Admin</th>
  <th nzWidth="100px" style="text-align:center">Master </th>
  <th nzWidth="100px" style="text-align:center">Gate </th>
  <th nzWidth="100px" style="text-align:center">Gate Pass</th>
  <th nzWidth="100px" style="text-align:center">Inventory </th>
  <th nzWidth="150px" style="text-align:center">Sales Order</th>
  <th nzWidth="120px" style="text-align:center">Production </th>
  <th nzWidth="100px" style="text-align:center">PO </th>
  <th nzWidth="100px" style="text-align:center">Issue </th>-->
          <th nzWidth="120px" nzRight style="text-align:center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of UserList;let i=index">

          <td nzWidth="20px">
            {{i+1}}

          </td>
          <td nzLeft><b>{{data.Name}}</b></td>
          <td>{{data.Roles}}</td>
          <td>{{data.Email}}</td>
          <td>{{data.Contact}}</td>


          <td nzRight style="text-align:center">
            <div>
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item (click)="OpenEditPop(data)">Edit</li>
                  <li style="width: 120px;" nz-menu-item (click)="OpenRolePop(data)">Manage Role</li>
                  <li style="width: 120px;" nz-menu-item (click)="OpenStorePop(data)">Manage Store</li>
                  <li style="width: 120px;" nz-menu-item (click)="handleDisable(data)">Disable</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>


        </tr>

      </tbody>

    </nz-table>
    


  </div>
</div>
<div class="card card-flush h-xl-100" *ngIf="!IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>You are not authorized</nz-page-header-title>



    </nz-page-header>
  </div>
  </div>

<nz-modal [nzWidth]="600"
          [nzStyle]="{ top: '20px' }"
          [nzVisible]="isVisible"
          [nzTitle]="modalTitle"
          [nzContent]="modalContent"
          [nzFooter]="modalFooter"
          (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>{{isNew==true?'Add New':'Modify'}} User</ng-template>

  <ng-template #modalContent>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="20">
          <nz-form-item style=" margin-bottom: 5px;">
            <nz-form-label [nzSpan]="10">Username</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <input nz-input name="Email" [(ngModel)]="UserRoles.Email" [disabled]="!isNew" [ngModelOptions]="{standalone: true}" (change)="onUsernamechange()" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style=" margin-bottom: 5px;">
            <nz-form-label [nzSpan]="10">Full Name</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <input nz-input name="Username"  [(ngModel)]="UserRoles.Name"  [ngModelOptions]="{standalone: true}"  />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style=" margin-bottom: 5px;">
            <nz-form-label [nzSpan]="10">Mobile</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <input nz-input name="Mobile"   [(ngModel)]="UserRoles.Contact"  [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-form-item>


        </div>

      </div>








    </form>
  </ng-template>
  <ng-template #modalFooter>
    <div class="text-center">
      <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="SaveUser()">Save</button>
    </div>
  </ng-template>
</nz-modal>


  <nz-modal [nzWidth]="800"
            [nzStyle]="{ top: '20px' }"
            [(nzVisible)]="isStoreVisible"
            [nzTitle]="modalTitle1"
            [nzContent]="modalContent1"
            [nzFooter]=null
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle1>Modify User Store</ng-template>

    <ng-template #modalContent1>
      <nz-form-item style=" margin-bottom: 5px;">
        <nz-form-label [nzSpan]="10">Email</nz-form-label>
        <nz-form-label [nzSpan]="10">{{UserRoles.Email}}</nz-form-label>

      </nz-form-item>
      <nz-table nzSize="small" style="width: 100%;" #basicTable [nzData]="this.StoreList" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr>
            <th>
              <label nz-checkbox [(ngModel)]="allChecked" (ngModelChange)="updateAllChecked()" style="color: #fff;" [ngModelOptions]="{standalone: true}">
                Store Name
              </label>

              <!--<nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
                <i nz-icon nzType="search"></i>
              </nz-filter-trigger>-->
            </th>
            <th>Store Code</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>
              <label nz-checkbox [(ngModel)]="data.IsChecked" [ngModelOptions]="{standalone: true}">{{data.StoreName}}</label>

            </td>
            <td>{{ data.StoreCode }}</td>




          </tr>
        </tbody>
      </nz-table>
      <br />
      <div class="text-center">
        <button nz-button nzType="primary" [nzLoading]="isStoreLoading" (click)="SaveStore(UserRoles.Email)">Save</button>
      </div>
    </ng-template>

  </nz-modal>


  <nz-drawer [nzClosable]="true"
             [nzVisible]="isRoleVisible"
             nzPlacement="right"
             [nzWidth]="600"
             nzTitle="Manage Roles for {{UserRoles.Email}}"
             (nzOnClose)="handleRoleCancel()">
    <ng-container *nzDrawerContent>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="SaveUserRole()">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24">

            <!--<nz-form-item style=" margin-bottom: 5px;">
              <nz-form-label [nzSpan]="10">Username</nz-form-label>
              <nz-form-control [nzSpan]="12" nzErrorTip="Select Supplier">
                <input nz-input name="Username" [(ngModel)]="UserRoles.Username" [disabled]="!isNew" [ngModelOptions]="{standalone: true}" (change)="onUsernamechange()" />
              </nz-form-control>
            </nz-form-item>-->
            
            <nz-form-item style=" margin-bottom: 5px;" *ngFor="let data of UserRoleList;let i=index">
              <nz-form-label [nzSpan]="10"> {{i+1}}</nz-form-label>
              <nz-form-control [nzSpan]="12">
                <label nz-checkbox [(ngModel)]="data.IsChecked" [ngModelOptions]="{standalone: true}">{{data.UserRoleName}}</label>
              </nz-form-control>
            </nz-form-item>
            <nz-divider></nz-divider>

          </div>

        </div>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
        </div>







      </form>
    </ng-container>
  </nz-drawer>
