import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { JumboDispatchPrintModel, WorkPlanJumboMasterModel } from 'src/PmsUIApp/Models/WorkPlanModel';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';

@Component({
  selector: 'app-PackagingListEmail',
  templateUrl: './PackagingListEmail.component.html',
  styleUrls: ['./PackagingListEmail.component.css'],
})
export class PackagingListEmailComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  JumboDispatchList: JumboDispatchPrintModel[] = [];
  JumboDispatchFilteredList!: JumboDispatchPrintModel;
  JumboDispatchUIFilteredList!: any;
  private route$: Subscription = new Subscription();
  DispatchId = 0;
  count: 0;
  EmailList: string[] = [];
  NewEmail = '';
  isLoading: boolean = false;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private alertService: AlertMessageService
  ) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.DispatchId = params['id'];
    });
    this.GetAllJumboDispatch();
  }

  GetAllJumboDispatch() {
    let url = this.ApiUrl + 'saleorder/GetJumboDispatchListByDispatchId/' + this.DispatchId;
    this.http.get<JumboDispatchPrintModel>(url).subscribe(
      (res) => {
        this.JumboDispatchFilteredList = res;
        this.EmailList = this.JumboDispatchFilteredList.CustomerDetails[0].Email == null ? null : this.JumboDispatchFilteredList.CustomerDetails[0].Email?.split(',');
        console.log(this.JumboDispatchFilteredList)
        var saleorderlist = [...new Set(this.JumboDispatchFilteredList.JumboInspection.map(item => item.SaleOrderId))];
        var gradelist = [...new Set(this.JumboDispatchFilteredList.JumboInspection.map(item => item.Grade))];
        console.log(saleorderlist)
        console.log(gradelist)
        this.JumboDispatchUIFilteredList = [];
        saleorderlist.forEach(s => {
          var mainItem = {
            SaleOrderId: s,
            SaleOrderCode: this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == s)[0]?.SaleOrderCode,
            SaleOrderManufacturingProductName: this.JumboDispatchFilteredList.JumboList.filter(x => x.SaleOrderCode == this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == s)[0]?.SaleOrderCode)[0]?.SaleOrderManufacturingProductName,
            item: []
          }
          gradelist.forEach(g => {
            var item = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == s && x.Grade == g);

            if (item.length > 0) {
              var it = {
                JumboInspection: item
              };
              mainItem.item.push(it);

            }
          })
          this.JumboDispatchUIFilteredList.push(mainItem);
        })
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllJumboDispatch();
        }
      }
    );
  }

  sumquantity(obj: WorkPlanJumboMasterModel) {
    var sum = obj.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return sum.toFixed(2)
  }

  sumweight(obj: WorkPlanJumboMasterModel) {
    var sum = obj.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return sum.toFixed(2)
  }

  totalsumquantity(SaleOrderId: number) {
    var sum = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == SaleOrderId).reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return sum.toFixed(2)
  }

  totalsumweight(SaleOrderId: number) {
    var sum = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == SaleOrderId).reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return sum.toFixed(2)
  }
  totalsumRoll(SaleOrderId: number) {
    var sum = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == SaleOrderId).reduce((accumulator, object) => {
      return accumulator + 1
    }, 0);
    return sum
  }
  grandtotalsumquantity() {
    var sum = this.JumboDispatchFilteredList.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return sum.toFixed(2)
  }
  grandtotalsumweight() {
    var sum = this.JumboDispatchFilteredList.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return sum.toFixed(2)
  }
  public captureScreen() {
    this.isLoading = true;
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4', true); // A4 size page of PDF  
      let position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST')
      var pfile = pdf.output('blob');
      this.uploadFile(<File>pfile);
    });
  }

  validateEmail() {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return !expression.test(this.NewEmail); // true
  }

  RemoveEmail(data) {
    var index = this.EmailList.indexOf(data);
    if (index !== -1) {
      this.EmailList.splice(index, 1);
    }
  }
  uploadFile(file: File) {
    let dispatchData: FormData = new FormData();
    dispatchData.append('file_upload', file, this.JumboDispatchFilteredList.PackingNumber.toString());
    dispatchData.append('DispatchId', this.DispatchId.toString());
    dispatchData.append('EmailList', JSON.stringify(this.EmailList));
    let url = this.ApiUrl + "saleorder/senddispatchpackagingemail";
    this.http.post(url, dispatchData).subscribe({
      next: res => {
        this.isLoading = false;
        this.alertService.success(res);
      },
      error: res => {
        this.isLoading = false;
        this.alertService.error(res.error);
      }
    });
  }


}