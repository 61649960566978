<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Process</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">

      <div nz-col [nzSpan]="10">
        <nz-form-item>

          <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
            <label class="required">Process Name</label>
            <input nz-input name="ProcessName" placeholder="Process Name" value="NewPO.ProcessName" [(ngModel)]="NewPO.ProcessName" [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>

      </div>

      <div style="margin:10px">


        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24" style="background-color: #f0f2f5; border-radius: 4px;">
            <nz-divider nzText="Raw Material"></nz-divider>
            <div nz-col [nzSpan]="24">
              <nz-form-item>

                <nz-form-control [nzSpan]="22" nzErrorTip="Enter Amount">
                  <label class="required">Select Raw Products</label>
                  <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple" [(ngModel)]="listOfSelectedproducts" (ngModelChange)="OnProductDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                  <tr>

                    <th nzWidth="50px">S.No</th>
                    <th>Product</th>
                    <th>Code</th>
                    <th>GSM</th>
                    <th nzWidth="200px">Unit</th>
                    <th nzWidth="200px">Price</th>

                    <th nzWidth="200px">Quantity</th>

                    <!-- <th nzWidth="20%">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of productionRawMaterial;let i=index">

                    <td nzWidth="20px">{{i+1}}</td>
                    <td>{{ data.ProductName }}</td>
                    <td>{{ data.ProductCode }}</td>
                    <td>{{ data.AvgGsm }}</td>
                    <td>{{ data.Unit }}</td>
                    <td>

                      <input nz-input placeholder="Price" value="data.Price" [(ngModel)]="data.Price" [ngModelOptions]="{standalone: true}" />
                    </td>
                    <td>

                      <input nz-input placeholder="Amount" value="data.Quantity" [(ngModel)]="data.Quantity" [ngModelOptions]="{standalone: true}"  (ngModelChange)="calculateProcessTotal()"/>
                    </td>


                  </tr>

                </tbody>

              </nz-table>
            </div>

          </div>

        </div>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
          <div nz-col [nzSpan]="10">


          </div>
          <div nz-col [nzSpan]="14" >

            <div nz-row [nzGutter]="24">


              <div nz-col [nzSpan]="8">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
                    <label>Temperature(°C)</label>
                    <input nz-input name="Temperature" placeholder="Temperature" value="NewPO.Temperature" [(ngModel)]="NewPO.Temperature" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>

              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
                    <label>Weight Pressure(kg)</label>
                    <input nz-input name="WeightPressure" placeholder="WeightPressure" value="NewPO.WeightPressure" [(ngModel)]="NewPO.WeightPressure" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>

              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Weight Gsm</label>
                    <input nz-input name="WeightGsm" placeholder="Weight Gsm" disabled value="NewPO.WeightGsm" [(ngModel)]="NewPO.WeightGsm" [ngModelOptions]="{standalone: true}"  />
                  </nz-form-control>
                </nz-form-item>

              </div>



            </div>
          </div>


        </div>
        <nz-divider></nz-divider>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
        </div>
        <nz-divider></nz-divider>
      </div>
    </form>


  </div>
</div>



