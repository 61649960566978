import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ThicknessModel } from '../../Models/MasterModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
@Component({
  selector: 'app-measurement-unit',
  templateUrl: './measurement-unit.componnet.html',
  styleUrls: ['./measurement-unit.component.css']
})
export class MeasurementUnitComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  UnitList: MeasureUnitModel[] = [];
  UnitListOriginal: MeasureUnitModel[] = [];
  NewUnit: MeasureUnitModel = new MeasureUnitModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Unit";
  UnitError = 'Enter Unit';
  UnitNameError = 'Enter Unit code';
  UnitTypeError = 'Enter Unit code';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = ''
  options = {

    headers: ["Sno.", "Branch Name", "Branch Code", "Branch Desc", "Added Date", "Added By"]

  };
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) {


  }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Unit, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Unit, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Unit, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      Unit: [null, [Validators.required, Validators.minLength(2)], [this.ThicknessNumberAsyncValidator]],
      UnitName: [null, [Validators.required]],
      UnitType: [null]

    });
    this.GetAllUnit();
  }

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllUnit();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.UnitListOriginal;
    var res2 = this.UnitListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: MeasureUnitModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.UnitList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.UnitList = res.filter(
        (item: MeasureUnitModel) =>
          item?.Unit?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.UnitName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.UnitType?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }


  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }

  GetAllUnit() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "data/getmeasureunits";
    this.http.get<any>(url).subscribe(res => {
      this.UnitList = res;
      this.UnitListOriginal = res;

      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllUnit();
      }
    });
  }
  SaveThickness() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewUnit.Unit = this.f['Unit'].value;
    this.NewUnit.UnitName = this.f['UnitName'].value;
    this.NewUnit.UnitType = this.f['UnitType'].value;
    this.NewUnit.AddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + "data/addupdatemeasureunits";
    this.http.post<MeasureUnitModel>(url, this.NewUnit).subscribe({

      next: res => { this.alertService.success("Unit Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllUnit(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: MeasureUnitModel) {
    this.PopUpTitle = "Modify Measurement Details";
    this.validateForm.setValue(
      {
        Unit: data.Unit,
        UnitName: data.UnitName,
        UnitType: data.UnitType
      }
    )
    this.NewUnit = new MeasureUnitModel();
    this.NewUnit.Id = data.Id;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Measurement Unit";
    this.validateForm.reset();
    this.NewUnit = new MeasureUnitModel();
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveThickness();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteThickness() {
    let url = this.ApiUrl + "Branch/deleteBranch";
    this.http.post<any>(url, this.NewUnit.Id).subscribe({

      next: res => {

        this.alertService.success("Branch Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllUnit();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = false; },

    });

  }
  handleDelete(data: MeasureUnitModel) {
    this.NewUnit = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Branch?',
      nzOnOk: () => this.DeleteThickness(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }

  ThicknessNumberAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.UnitList.filter(x => x.Id != this.NewUnit.Id);
        var nre = res.filter(x => x.Unit.toLowerCase() == control.value.toLowerCase());
        this.UnitNameError = '';

        if (control.value == "") {
          this.UnitNameError = "Enter Measurement unit";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.UnitNameError = "Measurement Unit already exist";
          observer.next({ error: true, duplicated: true });
        } else {
          this.UnitNameError = "Enter Measurement Unit";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

}
