<app-Timeline></app-Timeline>
<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Cost Estimation List</nz-page-header-title>
            <nz-page-header-subtitle>Manage your costing here</nz-page-header-subtitle>
            <nz-page-header-extra>
                <button nz-button nzType="primary" routerLink="/home/costing/estimation/add"
                    *ngIf="this.permission.Add">Add New</button>
            </nz-page-header-extra>
        </nz-page-header>
        <nz-collapse>
            <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                                    <label>Customer</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default"
                                        nzAllowClear [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                            [nzLabel]="s.CustomerName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="3">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Thickness</label>
                                    <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.Thick"
                                        nzAllowClear nzShowSearch nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                                            [nzLabel]="s.ThicknessNumber"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label>Estimation Order No.</label>
                                    <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number"
                                        maxlength="30" [(ngModel)]="request.EstimationOrderId"
                                        (keydown)="space($event)" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label> Estimation Date (From)</label>
                                    <nz-date-picker nzPlaceHolder="From Date" name="EstimationFromDate"
                                        class="form-control" [(ngModel)]="request.EstimationFromDate"
                                        nzAllowClear></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label> Estimation Date (To)</label>
                                    <nz-date-picker nzPlaceHolder="To Date" name="EstimationToDate" class="form-control"
                                        [(ngModel)]="request.EstimationToDate" nzAllowClear></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div style="float: right; margin-bottom: 5%; margin-right: 10px">
                            <div style="display: flex">
                                <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                                    (click)="onSearch()">
                                    Apply Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float:right">
            <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
                Export
            </button>
            <button nz-button nzSize="small" (click)="PrintListPage()"><i nz-icon nzType="printer"
                    nzTheme="outline"></i>
                Print
            </button>
        </div>
        <nz-table id="print-section" nzSize="small" style="font-size: small;" #basicTable
            [nzData]="this.CostEstimationListOriginal" [nzLoading]="isTableLoading" nzBordered
            [nzScroll]="{ x: '1500px', y: '515px' }" [nzPageSize]="20" nzShowPagination="true" nzShowSizeChanger
            [nzPageSizeOptions]=[50,100,200,500] nzShowQuickJumper="true" nzResponsive=true>
            <thead>
                <tr style="font-size: small;">
                    <th nzWidth="60px">S.No.</th>
                    <th nzWidth="120px">Estimation Order ID</th>
                    <th nzWidth="150px">Estimation Order Status</th>
                    <th nzWidth="120px">Customer Name</th>
                    <th nzWidth="120px">Product Category Name</th>
                    <th nzWidth="200px">Manufacturing Product Name</th>
                    <th nzWidth="90px">Order Quantity</th>
                    <th nzWidth="90px">LMconstant</th>
                    <th nzWidth="90px">Manufacturing Quantity</th>
                    <th nzWidth="100px">Color</th>
                    <th nzWidth="100px">Grain</th>
                    <th nzWidth="110px">Grain Price</th>
                    <th nzWidth="110px">Thick</th>
                    <th nzWidth="110px">Width</th>
                    <th nzWidth="110px">Estimation Price</th>
                    <th nzWidth="110px">Production Cost LM</th>
                    <th nzWidth="110px">Total Finish Price</th>
                    <th nzWidth="110px">Overhead Cost</th>
                    <th nzWidth="110px">Rejection</th>
                    <th nzWidth="110px">Total Cost Per LM</th>
                    <th nzWidth="110px">Line Speed</th>
                    <th nzWidth="110px">Total Profit Loss</th>
                    <th nzWidth="150px">Remarks</th>
                    <th nzWidth="150px">Added By</th>
                    <th nzWidth="150px">Added Date</th>
                    <th nzWidth="150px" style="text-align:center" nzRight>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of this.CostEstimationListOriginal; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data?.EstimationOrderId }}</td>
                    <td style="text-align: center">
                        <nz-tag [nzColor]="'blue'"> <a>{{ data?.EstimationOrderStatus?.EstimationOrderStatus }}</a>
                        </nz-tag>
                    </td>
                    <td>{{ data?.CustomerName}}</td>
                    <td>{{ data?.ProductCategoryName }}</td>
                    <td>{{ data?.ManufacturingProductName }}</td>
                    <td>{{ data?.OrderQuantity }}</td>
                    <td>{{ data?.Lmconstant }}</td>
                    <td>{{ data?.ManufacturingQuantity }}</td>
                    <td>{{ data?.ColorName }}</td>
                    <td>{{ data?.GrainName }}</td>
                    <td>{{ data?.GrainPrice }}</td>
                    <td>{{ data?.ThicknessNumber }}</td>
                    <td>{{ data?.WidthNumber }}</td>
                    <td>{{ data?.EstimationPrice }}</td>
                    <td>{{ data?.ProductionCostLm }}</td>
                    <td>{{ data?.TotalFinishPrice }}</td>
                    <td>{{ data?.OverheadCost }}</td>
                    <td>{{ data?.Rejection }}</td>
                    <td>{{ data?.TotalCostPerLm }}</td>
                    <td>{{ data?.LineSpeed }}</td>
                    <td>{{ data?.TotalProfitLoss }}</td>
                    <td>{{ data?.Remarks }}</td>
                    <td>{{ data?.EstimationOrderStatus.AddedBy }}</td>
                    <td>{{ data?.EstimationOrderStatus?.AddedDate }}</td>
                    <td nzRight style="text-align:center">
                        <div>
                            <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                                class="btn btn-sm btn-light-primary" style="line-height:0">
                                Action
                                <span nz-icon nzType="down"></span>
                            </button>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li style="width: 120px;" nz-menu-item
                                        (click)="OpenViewPop(data.EstimationOrderId)">View
                                    </li>
                                    <li style="width: 120px;" nz-menu-item *ngIf="this.permission.Edit" (click)="Edit(data)">Edit
                                    </li>
                                    <li style="width: 120px;" nz-menu-item *ngIf="this.permission.Cancel" (click)="Cancelled(data)">Cancel
                                    </li>
                                    <li style="width: 120px;" nz-menu-item (click)="Print(data.EstimationOrderId)">Print
                                    </li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>
<app-EODrawer></app-EODrawer>