import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';


import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CompanyInfo } from '../../Authentication/UserInfo';
import { BlobStorageService } from 'src/PmsUIApp/azure-storage/blob-storage.service';
import { NzQRCodeModule } from 'ng-zorro-antd/qr-code';
import { IssueProductSlipModel } from 'src/PmsUIApp/Models/IssueModel';


@Component({
  selector: 'app-issueslipprint',
  templateUrl: './issueslipprint.component.html',
  styleUrls: ['./issueslipprint.component.css']
})
export class IssueSlipPrintComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  IssueSlipProducts: IssueProductSlipModel[] = [];
  private route$: Subscription = new Subscription;
  IssueSlipId = 0;
  retryCount: number = 0;

  Company = {
    CompanyName: '',
    HeadOfficeAddress: '',
    FactoryAddress: ''
  };


  constructor(public http: HttpClient, private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.IssueSlipId = params["id"];
    })
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.HeadOfficeAddress = CompanyInfo.HeadOfficAddress;
    this.Company.FactoryAddress = CompanyInfo.FactoryAddress;
    this.PrintSlip();
  }

  PrintSlip() {
    let url = this.ApiUrl + "issueproduct/getstockbyslipid/" + this.IssueSlipId;
    this.http.get<IssueProductSlipModel[]>(url).subscribe(res => {
      this.IssueSlipProducts = res;
      setTimeout(function () {
        window.print();
        window.onafterprint = close;
        function close() {
          window.close();
        }
      }, 1)
    }, () => {
      this.retryCount++
      if (this.retryCount < 2) {
        this.PrintSlip();
      }
    });
  }

}
