import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserInfo } from '../../../Authentication/UserInfo';
import { Modules, Responsibility } from '../../../Models/Enums';
import { FormulationCodeModel, FormulationCodeMixingModel, FormulationCodeMixingRawMaterialModel } from '../../../Models/FormulationCodeModel';
import { ProductCategoryModel, ThicknessModel } from '../../../Models/MasterModel';
import { MixingModel, MixingRawMaterialModel } from '../../../Models/MixingModel';
import { ProductModel } from '../../../Models/ProductModel';
import { SaleOrderProductionModel, SaleOrderProductionRawMaterialModel } from '../../../Models/SalesOrderModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { AuthService } from '../../../Services/auth.service';
import { LoadingService } from '../../../Services/loadingService';


@Component({
  selector: 'app-newformulationcode',
  templateUrl: './newformulationcode.component.html',
  styleUrls: ['./newformulationcode.component.css']
})
export class FormulationcodeComponent implements OnInit {
  validateForm!: UntypedFormGroup;

  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  NewSaleOrder: FormulationCodeModel = new FormulationCodeModel;
  NewPO: SaleOrderProductionModel = new SaleOrderProductionModel;
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedCategory: any;
  selectedunit: any;
  selectedFormulationProduct: ProductModel;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  listOfSelectedproducts: ProductModel[] = [];
  listOfSelectedmixing: number[] = [];
  Selectedproduct = 0;
  SelectedMixing!: FormulationCodeMixingModel;
  productionRawMaterial: SaleOrderProductionRawMaterialModel[] = [];
  private route$: Subscription = new Subscription;
  MixingList: MixingModel[] = [];
  mixingRawMaterial: MixingRawMaterialModel[] = [];
  MixingSelectedList: FormulationCodeMixingModel[] = [];
  Wastagetype = '%';
  ThicknessList: ThicknessModel[] = [];
  IsPopUpOpen = false;
  FormulationCodeList: FormulationCodeModel[] = [];
  FormulationCodeId = 0;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router,
    private auth: AuthService, private loader: LoadingService) { }
  handleOk(): void {
    this.isLoading = true;
    this.Save();
  }
  Save() {
    if (this.NewSaleOrder.SaleFormulationCode == '') {
      this.isLoading = false;
      this.alertService.error("FormulationCode Required");
      return;
    }
    if (this.NewSaleOrder.FabricProductId != 0 && isNaN(this.NewSaleOrder.FabricGsm)) {
      this.isLoading = false;
      this.alertService.error("Fabric GSM is required. Update AvgGSM for the Fabric Product from Product Master.");
      return;
    }
    if (this.NewSaleOrder.CategoryId == 0) {
      this.isLoading = false;
      this.alertService.error("Category is Required");
      return;
    }

    if (this.MixingSelectedList.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add Mixing details");
      return;
    }
    console.log(this.FormulationCodeId)

    if (this.FormulationCodeId == 0 || this.FormulationCodeId === undefined) {
      if (this.FormulationCodeList.filter(x => x.SaleFormulationCode == this.NewSaleOrder.SaleFormulationCode).length > 0) {
        this.isLoading = false;
        this.alertService.error("Formulation Code already exist");
        return;
      }
    }

    this.NewSaleOrder.MixingData = this.MixingSelectedList;
    this.NewSaleOrder.AddedBy = UserInfo.EmailID;
    console.log(this.NewSaleOrder);
    var url = this.ApiUrl + "saleorder/addformulationcode";
    if (this.FormulationCodeId > 0) {
      this.NewSaleOrder.SaleFormulationCodeId = this.FormulationCodeId;
      url = this.ApiUrl + "saleorder/updateformulationcode";
    }
    this.http.post<any>(url, this.NewSaleOrder).subscribe({
      next: res => {
        this.alertService.success(res); this.isLoading = false;
        this.router.navigate(['/home/production/formulationcode/list'])
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });

  }
  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.FormulationCode, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.FormulationCodeId = params["id"];

    })
    this.validateForm = this.fb.group({});
    this.GetMixing();
    this.GetAllProducts();
    this.GetAllProductCategory();
    this.GetAllThickness();
    this.GetAllFormulationCode();
  }
  get f() { return this.validateForm.controls; }
  GetAllThickness() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
      this.isTableLoading = false;
      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllThickness(); }
    });
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res.filter(x => x.ProductType == 'Finished');

    }, res => { this.GetAllProductCategory(); });
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res.filter(x => x.ProductType == "Finished");
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  GetAllFormulationCode() {
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;

      if (this.FormulationCodeId > 0) {
        this.NewSaleOrder.SaleFormulationCode = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].SaleFormulationCode;
        this.NewSaleOrder.CategoryId = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].CategoryId;
        this.NewSaleOrder.ThicknessId = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].ThicknessId;
        this.NewSaleOrder.FabricProductId = this.Selectedproduct = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].FabricProductId;
        this.NewSaleOrder.MixingData = this.MixingSelectedList = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].MixingData;
        this.listOfSelectedmixing = this.NewSaleOrder.MixingData.map(el => el.MixingId);
        console.log(this.listOfSelectedmixing)
        this.NewSaleOrder.PreSkinGsm = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].PreSkinGsm;
        this.NewSaleOrder.SkinGsm = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].SkinGsm;
        this.NewSaleOrder.FoamGsm = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].FoamGsm;
        this.NewSaleOrder.AdhesiveGsm = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].AdhesiveGsm;
        this.NewSaleOrder.FabricGsm = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].FabricGsm;
        this.NewSaleOrder.IsOrderLinkingRequired = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.FormulationCodeId)[0].IsOrderLinkingRequired;
        this.calculateGrandTotal()
        console.log(this.NewSaleOrder)
      }
      this.loader.hide();

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  OnProductDdlchange() {
    if (this.Selectedproduct != null) {
      this.NewSaleOrder.FabricProductId = this.Selectedproduct;
      var product = this.ProductRawList.filter(x => x.ProductId == this.Selectedproduct)[0];
      var rawproduct = new SaleOrderProductionRawMaterialModel;
      rawproduct.ProductId = this.Selectedproduct;
      rawproduct.ProductName = product.ProductName;
      rawproduct.ProductCode = product.ProductCode;
      rawproduct.Quantity = 1;
      rawproduct.Unit = product.Unit;
      rawproduct.AvgGsm = product.AvgGsm == null ? 0 : parseFloat(product.AvgGsm);
      rawproduct.PerUnitCost = 0;
      this.NewSaleOrder.FabricGsm = product.AvgGsm == null ? 0 : parseFloat(product.AvgGsm);
      this.calculateGrandTotal();
      this.productionRawMaterial = [];
      this.productionRawMaterial.push(rawproduct);
    }
    else {
      this.NewSaleOrder.FabricProductId = 0;
      this.NewSaleOrder.FabricGsm = 0;
      this.calculateGrandTotal();
    }

  }
  GetMixing() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mixing/getallmixings";
    this.http.get<MixingModel[]>(url).subscribe(res => {
      this.MixingList = res;


    }, res => { this.GetMixing(); });
  }
  OnMixingDdlchange() {
    this.MixingSelectedList = [];

    console.log(this.listOfSelectedmixing)
    this.listOfSelectedmixing.forEach(item => {

      var checkexist = this.mixingRawMaterial.filter(x => x.MixingId == item).length;
      if (checkexist == 0) {
        var product = this.MixingList.filter(x => x.MixingId == item)[0];

        var mixing = new FormulationCodeMixingModel();
        mixing.AddedBy = product.AddedBy;
        mixing.AddedDate = product.AddedDate;
        mixing.MixingId = product.MixingId;
        mixing.MixingName = product.MixingName;

        mixing.MixingRawMaterial = [];
        console.log(product)
        product.MixingRawMaterial.forEach(x => {
          var raw = new FormulationCodeMixingRawMaterialModel();
          raw.Price = x.Price;
          raw.FormulationCodeMixingId = x.MixingId;
          raw.ProductCode = x.ProductCode;
          raw.ProductId = x.ProductId;
          raw.ProductName = x.ProductName;
          raw.Quantity = x.Quantity;
          raw.Scquantity = x.Quantity;
          raw.Unit = this.ProductRawList.filter(y => y.ProductId == x.ProductId)[0].Unit;
          raw.AvgGsm = parseInt(x.AvgGsm);
          mixing.MixingRawMaterial.push(raw);
        })

        this.MixingSelectedList.push(mixing)
      }

    })
    //this.NewPO.MixingTotalCost = 0;
    //this.MixingSelectedList.forEach(x => {
    //  this.NewPO.MixingTotalCost += x.Total;
    //  this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    //})
    //this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;

  }
  handleRemoveRow(data: FormulationCodeMixingRawMaterialModel) {
    if (data.FormulationCodeMixingRawMaterialId > 0) {
      this.MixingSelectedList.filter(x => x.FormulationCodeMixingId == data.FormulationCodeMixingId)[0].MixingRawMaterial =
        this.MixingSelectedList.filter(x => x.FormulationCodeMixingId == data.FormulationCodeMixingId)[0]?.MixingRawMaterial.filter(obj => obj !== data);
    }
    else {
      this.MixingSelectedList.filter(x => x.MixingId == data.FormulationCodeMixingId)[0].MixingRawMaterial = this.MixingSelectedList.filter(x => x.MixingId == data.FormulationCodeMixingId)[0].MixingRawMaterial.filter(obj => obj !== data);
    }
  }
  calculateGrandTotal() {
    this.NewPO.TotalGsm =
      parseFloat(this.NewSaleOrder.PreSkinGsm?.toString()) +
      parseFloat(this.NewSaleOrder.SkinGsm?.toString()) +
      parseFloat(this.NewSaleOrder.FoamGsm?.toString()) +
      parseFloat(this.NewSaleOrder.AdhesiveGsm?.toString()) +
      parseFloat(this.NewSaleOrder.FabricGsm?.toString());
  }
  openAddModel(data: FormulationCodeMixingModel) {
    this.SelectedMixing = data;
    this.IsPopUpOpen = true;
  }
  closeAddModel() {

    this.IsPopUpOpen = false;
  }
  addRawMaterial() {
    this.listOfSelectedproducts.forEach(x => {
      var raw = new FormulationCodeMixingRawMaterialModel;
      raw.Price = 0;
      raw.FormulationCodeMixingId = this.SelectedMixing.MixingId;
      raw.ProductCode = x.ProductCode;
      raw.ProductId = x.ProductId;
      raw.ProductName = x.ProductName;
      raw.Quantity = 1;
      raw.Unit = x.Unit;
      raw.AvgGsm = parseInt(x.AvgGsm);
      raw.Scquantity = 1;
      this.SelectedMixing.MixingRawMaterial.push(raw);
    })
    this.listOfSelectedproducts = [];
    this.closeAddModel();
  }
}
