<div *ngIf="IsPopupOpen | async">
    <nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1000"
        nzTitle="Order Cost Estimation" (nzOnClose)="handleCancel()">
        <ng-container *nzDrawerContent>
            <div *ngIf="EOList">
                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3">
                    <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
                        <b>{{ EOList.OrderType }}</b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Estimation for" [nzSpan]="1"><b>{{ EOList.CustomerName
                            }}</b></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Remark" [nzSpan]="1"><b>{{ EOList.Remarks
                            }}</b></nz-descriptions-item>
                </nz-descriptions>
                <nz-divider nzText="Production Details"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="4">
                    <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                            EOList.EstimationSaleFormulationCode 
                            }}</b></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Thick">
                        <b>
                            {{
                            EOList.ThicknessNumber
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Grain">
                        <b>
                            {{
                            EOList.GrainName
                            }}
                        </b>
                        <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                            nzTooltipTitle="Grain Code: {{EOList.GrainId}}" nzTooltipPlacement="left" nz-tooltip
                            nzType="info-circle" nzTheme="outline">
                        </span>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Width">
                        <b>
                            {{
                            EOList.WidthNumber
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
                        <b>
                            {{
                            EOList.ManufacturingProductName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="QTY">
                        <b>
                            {{
                            EOList.OrderQuantity
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Estimated Price">
                        <b>
                            {{
                            EOList.EstimationPrice
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Total Estimated Price">
                        <b>
                            {{
                            calculateTotalEstimatedPrice()
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Color">
                        <b>
                            {{
                            EOList.ColorName
                            }}
                        </b>
                    </nz-descriptions-item>
                </nz-descriptions>
                <nz-divider nzText="Fabric"></nz-divider>
                <div nz-row [nzGutter]="24" style="margin: 0px 0px">
                    <div nz-col [nzSpan]="24">
                        <span>
                            <div>
                                <nz-table *ngIf="EOList.FabricsData.length > 0" nzSize="small" [nzData]="['']"
                                    #basicTable1 nzBordered nzShowPagination="false">
                                    <thead>
                                        <tr>
                                            <th nzTitle="S. No." nzWidth="50px">S.No</th>
                                            <th nzTitle="Product Name">Product Name</th>
                                            <th nzTitle="GSM" nzWidth="100px">GSM</th>
                                            <th nzTitle="Quantity" nzWidth="100px">Quantity</th>
                                            <th nzTitle="Price" nzWidth="100px">Price</th>
                                            <th nzTitle="Total Price" nzWidth="100px">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of EOList.FabricsData; let i = index">
                                            <td nzWidth="20px">{{ i + 1 }}</td>
                                            <td>{{ data.FabricProductName }}</td>
                                            <td>{{ data.FabricGsm }}</td>
                                            <td>{{ data.FabricEstimationQuantity }}</td>
                                            <td>{{ data.FabricProductCostPerLm }}</td>
                                            <td>{{ data.FabricEstimationQuantity * data.FabricProductCostPerLm }}</td>
                                        </tr>
                                        <tr style="font-weight: bold;">
                                            <td></td>
                                            <td>Grand Total:</td>
                                            <td>{{ TotalFabricGSM }}</td>
                                            <td>{{ TotalFabricQuantity }}</td>
                                            <td>{{ TotalFabricPrice }}</td>
                                            <td>{{ GrandTotalFabricPrice }}</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </span>
                    </div>
                    <div nz-col [nzSpan]="24">
                        <nz-divider nzText="Mixing"></nz-divider>
                        <span>
                            <div *ngFor="let i of MixingSelectedList" style="
                                padding: 5px;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                                margin-top: 10px;
                                background: #fff;
                                box-shadow: 1px 1px 2px 2px #415994;
                            ">
                                <div style="padding: 10px 0; text-transform: uppercase">
                                    Mixing Name :
                                    <b>{{ i.MixingName }}</b>
                                </div>
                                <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                    nzShowPagination="false">
                                    <thead>
                                        <tr>
                                            <th nzWidth="50px">S.No</th>
                                            <th>Product</th>
                                            <th nzWidth="100px">Code</th>
                                            <th nzWidth="50px">Unit</th>
                                            <th nzWidth="100px">Price</th>
                                            <th nzWidth="100px">QTY</th>
                                            <th nzWidth="100px">Total Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of i.MixingRawMaterial; let i = index">
                                            <td nzWidth="20px">{{ i + 1 }}</td>
                                            <td>{{ data.ProductName }}</td>
                                            <td>{{ data.ProductCode }}</td>
                                            <td>{{ data.Unit }}</td>
                                            <td>{{ data.Price }}</td>
                                            <td>{{ data.Quantity }}</td>
                                            <td>{{(data.Price * data.Quantity).toFixed(2)}}</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </span>
                    </div>
                    <nz-divider nzText="Weight in GSM"></nz-divider>
                    <div nz-row [nzGutter]="24"
                        style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding: 30px 0;    background: rgb(240, 242, 245);">
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzSpan]="3">
                            </div>
                            <div nz-col [nzSpan]="4">
                                <nz-form-item>
                                    <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                                        nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                                        <label style="margin-left: -1px;">Pre Skin</label><br>
                                        <input nz-input type="number" readonly [(ngModel)]="PreSkinGsm"
                                            [ngModelOptions]="{standalone: true}" name="PreSkinGsm" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col [nzSpan]="4">
                                <nz-form-item>
                                    <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                                        nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                                        <label style="margin-left: -1px;">Skin</label>
                                        <input nz-input type="number" readonly [(ngModel)]="SkinGsm"
                                            [ngModelOptions]="{standalone: true}" name="SkinGsm" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col [nzSpan]="4">
                                <nz-form-item>
                                    <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                                        nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                                        <label style="margin-left: -1px;">Foam</label>
                                        <input nz-input type="number" readonly [(ngModel)]="FoamGsm"
                                            [ngModelOptions]="{standalone: true}" name="FoamGsm" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col [nzSpan]="4">
                                <nz-form-item>
                                    <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                                        nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                                        <label style="margin-left: -1px;">Adhesive</label>
                                        <input nz-input type="number" readonly [(ngModel)]="AdhesiveGsm"
                                            [ngModelOptions]="{standalone: true}" name="AdhesiveGsm" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col [nzSpan]="5">
                                <nz-form-item>
                                    <nz-form-control style="margin-left: -70px;" [nzSpan]="20"
                                        nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                                        <label style="margin-left: -1px;">Fabric (GSM)</label>
                                        <input nz-input type="number" readonly [(ngModel)]="TotalFabricGSM"
                                            [ngModelOptions]="{standalone: true}" name="FabricGsm" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="15">
                        </div>
                        <div nz-col [nzSpan]="9">
                            <nz-descriptions [nzColumn]="1">
                                <nz-descriptions-item nzTitle="Total (GSM)" nzColon="true">
                                    <input nz-input type="text" readonly style="width:130px;" [(ngModel)]="TotalGsm"
                                        [ngModelOptions]="{standalone: true}" />
                                </nz-descriptions-item>
                            </nz-descriptions>
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                    <div nz-col [nzSpan]="24">
                        <nz-divider nzText="Costing Details"></nz-divider>
                        Note: Costing Details are calculated after the consumption for raw material is completed
                        and if there are
                        Post Process included in this order then that also needs to be processed before to show
                        the actual cost.
                        <nz-divider></nz-divider>
                        <nz-table *ngIf="EOList.FinishingData?.length > 0" [nzData]="EOList.FinishingData" nzBordered
                            nzSize="small">
                            <thead>
                                <tr>
                                    <th nzTitle="Material Type">Material Type</th>
                                    <th nzTitle="Finishing Type">Finishing Type</th>
                                    <th nzTitle="Selected Finishing Type">Selected Finishing Type</th>
                                    <th nzTitle="Price">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of EOList.FinishingData; let i = index">
                                    <td>{{ row.MaterialType }}</td>
                                    <td>{{ row.FinishingType }}</td>
                                    <td>{{ row.SelectedFinishingType }}</td>
                                    <td>{{ row.Price }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <br>
                        <div>
                            <nz-table [nzData]="MixingSelectedList" #basicTable1 nzBordered nzShowPagination="false">
                                <thead>
                                    <tr>
                                        <th>Mixing Name</th>
                                        <th>Per Kg Cost</th>
                                        <th>GSM</th>
                                        <th>Cost Per Lm</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let i of MixingSelectedList">
                                        <td>{{ i.MixingName }}</td>
                                        <td>{{ calculateTotalPriceForItem(i) }}</td>
                                        <td> {{
                                            i.MixingName === 'PRE SKIN' ? this.PreSkinGsm :
                                            (i.MixingName === 'SKIN' ? this.SkinGsm :
                                            (i.MixingName === 'ADHESIVE' ? this.AdhesiveGsm :
                                            (i.MixingName === 'FOAM' ? this.FoamGsm : 0)))
                                            }}
                                        <td>{{ calculateCostPerLmForItem(i) }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                        <div>
                            <br>
                            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>GSM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Fabric</td>
                                        <td>{{ this.FabricGsm }}</td>
                                    </tr>
                                    <tr>
                                        <td>Coating</td>
                                        <td>{{ this.Coating }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>{{ this.FabricGsm + this.Coating}}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                        <br>
                        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">
                            <nz-descriptions-item nzTitle="Per LM Constant" [nzSpan]="1">
                                <b>{{this.PerLMConstant}}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="PASTE COST / LM" [nzSpan]="1" nzColon="true">
                                <b>{{ EOList.TotalCostPerLm }}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="GRAIN COST / LM" [nzSpan]="1" nzColon="true"><b>
                                    {{ EOList.GrainPrice }}</b></nz-descriptions-item>
                            <nz-descriptions-item nzTitle="FABRIC COST/LM" [nzSpan]="1" nzColon="true"><b>
                                    {{EOList.FabricProductCostPerLm}}</b></nz-descriptions-item>
                            <nz-descriptions-item nzTitle="FINISHING COST/ LM" [nzSpan]="1" nzColon="true">
                                <b>
                                    {{EOList.TotalFinishPrice}}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="R\M COST / LM">
                                <b>
                                    {{EOList.TotalCostPerLm + EOList.GrainPrice + EOList.FabricProductCostPerLm +
                                    EOList.TotalFinishPrice}}
                                </b>
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">
                            <nz-descriptions-item nzTitle="** REJECTION %">
                                <div class="row">
                                    <div class="column" style="width: 50%;">
                                        <input type="number" style="width: 80%;" placeholder="Rejection Constant"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="EOList.Rejection" />
                                    </div>
                                    <div class="column" style="width: 50%;">
                                        <b>
                                            {{ EOList.Rejection }}
                                        </b>
                                    </div>
                                </div>

                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="PRODUCTION COST / LM">
                                <b>
                                    {{ EOList.ProductionCostLm }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Line Speed">
                                <input type="number" style="width: 80%;" readonly placeholder="Line Speed" nz-input
                                    [(ngModel)]="this.EOList.LineSpeed" />
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Overhead">
                                <input type="number" style="width: 80%;" readonly placeholder="Overhead cost" nz-input
                                    [(ngModel)]="this.EOList.OverheadCost" />
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Total Cost">
                                <b>
                                    {{ EOList.TotalCostPerLm }}
                                </b>
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <p style="margin: 10px;">** Rejection Percentage shown above is auto calculated by
                            taking sum quantity of
                            these Graded Mini Rolls - LOT, NS, CUT-PC, FILM, Waste. Each Roll Details can be
                            seen on Yield Report.
                        </p>
                        <p style="margin: 10px;">*** Overhead is auto calculated based on Line Speed for this
                            order -
                            {{EOList.LineSpeed}}
                            MTRs/Minute and Current
                            Monthly Average Overhead
                            provided in Config Setting.
                        </p>
                        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3">
                            <nz-descriptions-item nzTitle="Estimated QTY">
                                <b>
                                    {{ EOList.OrderQuantity }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Production Line Speed">
                                <b>
                                    {{ EOList.LineSpeed}}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Formulation Code">
                                <b>
                                    {{ EOList.SaleFormulationCode }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Estimated Price">
                                <b>
                                    {{ EOList.EstimationPrice }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Thick">
                                <b>
                                    {{ EOList.Thick}}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Final Cost">
                                <b>
                                    {{ EOList.TotalSalePrice}}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Estimated Profit/Loss Per LM">
                                <b>
                                    {{ (+EOList.TotalSalePrice - +TotalCostPerLm).toFixed(2) }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Total Estimated Profit/Loss">
                                <b>
                                    {{calculateTotalProfitLoss()}}
                                </b>
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <div style="margin-top: 10px;">
                            <p>
                                Note: Total Profit/Loss is calculated based on Total Sale Cost - Total
                                Manufactured Cost
                            </p>
                        </div>
                    </div><br>
                </div>
            </div>
        </ng-container>
    </nz-drawer>
</div>