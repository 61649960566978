<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Dispatch</nz-page-header-title>

    </nz-page-header>
    <nz-divider></nz-divider>



    <div nz-col [nzSpan]="24">

      <nz-form-item *ngIf="this.Type=='Production'">

        <nz-form-control [nzSpan]="12" nzErrorTip="Enter Element">
          <label><b>Select Workplan</b></label>
          <nz-select class="form-select" nzShowSearch name="WorkPlanNo" [(ngModel)]="SelectedWorkPlan" nzSize="default"
            [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose"
            (ngModelChange)="OnWorkPlanChange()">
            <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">

        <nz-form-control [nzSpan]="12" nzErrorTip="Enter Element" *ngIf="this.Type=='Production'">
          <label><b>Select Order</b></label>
          <nz-select class="form-select" nzShowSearch name="SelectedWorkPlanOrder" [(ngModel)]="SelectedOrderNo" [ngModelOptions]="{standalone: true}" nzSize="default" nzAllowClear="false" nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanOrderChange()">
            <nz-option *ngFor="let s of SelectedWorkPlanOrder;" [nzValue]="s.SaleOrder.SaleOrderId" [nzLabel]="s.SaleOrder.SaleOrderNumber"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <div *ngIf="this.SaleOrder.SaleOrderId>0" nz-row style="margin: 0px 0px;">
        <div nz-col [nzSpan]="24">
          <h4 style="margin: 15px 0;" *ngIf="this.JumboInspection.length>0" >Jumbo Inspection Details</h4>
          <nz-table nzSize="small" *ngIf="this.JumboInspection.length>0" [nzPageSize]="100" style="width: 80%;" #basicTable nzShowPagination="false"
                    [nzData]="[{}]" nzBordered>
            <thead>
              <tr>
                <th nzWidth="150px">Code</th>
                <th nzWidth="150px">Grade</th>
                <th nzWidth="150px">Quantity</th>
                <th nzWidth="150px">Dispatched Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of JumboInspection">
                <td>{{ data.Code}}</td>
                <td>{{ data.Grade}}</td>
                <td>{{ data.Quantity}}</td>
                <td><input type="number" nz-input [(ngModel)]="data.DispatchedQuantity"  (change)="sumDispatchQTY(data)"/></td>
              </tr>
            </tbody>
          </nz-table>
          <h4 style="margin: 15px 0;" *ngIf="SaleOrder.SaleOrderProduction">Sales Order Details</h4>

          <nz-descriptions style="width: 80%;" nzBordered
                           nzLayout="vertical"
                           *ngIf="SaleOrder.SaleOrderProduction"
                           nzSize="small"
                           [nzColumn]="4">

            <nz-descriptions-item nzTitle="Lot No">{{SelectedWorkPlan.LotNo}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Batch No">{{SelectedWorkPlan.BatchNo}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Barcode">
              <input type="text" nz-input [(ngModel)]="DispatchReadyBarcode"
                *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'" />
              <span
                *ngIf="this.SaleOrder.Status.toString() == 'DispatchReady' || isDispatchedReady">{{SaleOrder?.SaleOrderDispatchReady?.DispatchReadyBarcode}}</span>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Customer">{{SaleOrder.CustomerName}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Order Quantity">{{SaleOrder?.SaleOrderProduction?.OrderQuantity}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Dispatch Quantity">
              <input type="text" nz-input [(ngModel)]="DispatchReadyQuantity"
                *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'" />
              <span
                *ngIf="this.SaleOrder.Status.toString() == 'DispatchReady' || isDispatchedReady">{{SaleOrder?.SaleOrderDispatchReady?.DispatchReadyQuantity}}</span>
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Store">
              <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange($event)"
                [(ngModel)]="StoreId" *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'" nzSize="default"
                nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
              </nz-select>
              <span
                *ngIf="this.SaleOrder.Status.toString() == 'DispatchReady' || isDispatchedReady">{{SaleOrder?.SaleOrderDispatchReady?.StoreName}}</span>
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Rack">
              <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default" nzAllowClear
                [(ngModel)]="DispatchReadyRack" *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'"
                nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
              </nz-select>
              <span
                *ngIf="this.SaleOrder.Status.toString() == 'DispatchReady' || isDispatchedReady">{{SaleOrder?.SaleOrderDispatchReady?.RackName}}</span>
            </nz-descriptions-item>

          </nz-descriptions>
          <h4 style="margin: 15px 0;" *ngIf="SaleOrderDispatchList.length>0">Dispatch Details</h4>

          <nz-table *ngIf="SaleOrderDispatchList.length>0" nzSize="small" style="width: 100%;" #basicTable nzBordered [nzData]="[{}]" nzShowPagination="false">
            <thead>
              <tr>
                <th nzWidth="100px">Packet No</th>
                <th nzWidth="100px">KG</th>
                <th nzWidth="20%">Transport</th>
                <th nzWidth="20%">Vehicle</th>
                <th nzWidth="20%">Dispatch Number</th>
                <th nzWidth="20%">Dispatch Date</th>
                <th nzWidth="15%">Dispatch Quantity</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let data of SaleOrderDispatchList;let i=index">
                <td>{{data.PacketNumber}}</td>
                <td>{{data.PacketWeight}}</td>
                <td>{{data.TransportName}}</td>
                <td>{{data.VehicleNumber}}</td>
                <td>{{data.DispatchNumber}}</td>
                <td>{{data.DispatchDate | date}}</td>
                <td>{{data.DispatchDate}}</td>
              </tr>

            </tbody>

          </nz-table>

          <nz-divider *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'"></nz-divider>
          <div class="text-center">
            <button nz-button nzType="primary" *ngIf="this.SaleOrder.Status.toString() == 'MoveToDispatch'"
              (click)="SaveReadyDispatch()">Save And Ready to Dispatch</button>
          </div>
          <div *ngIf="this.SaleOrder.Status.toString() == 'DispatchReady' || isDispatchedReady">
            <nz-divider></nz-divider>
            <p style="color: darkorange;">
              <b>
                Enter transport details for auto Gate-in the dispatch. You can leave the transport details if its not
                yet decided, you can later select the transport
                and Gate-in from Gate management page.
              </b>
            </p>
            <h4 style="margin: 15px 0;">Add Packet</h4>

            <nz-table nzSize="small" style="width: 80%;" #basicTable nzBordered [nzData]="[{}]" nzShowPagination="false">
              <thead>
                <tr>
                  <th nzWidth="10%">Packet No</th>
                  <th nzWidth="10%">KG</th>
                  <th nzWidth="30%">Transport</th>
                  <th nzWidth="20%">Vehicle</th>
                  <th nzWidth="10%">Dispatch Number</th>
                  <th nzWidth="20%">Dispatch Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th nzWidth="25%"><input type="number" nz-input [(ngModel)]="PacketNumber" /></th>
                  <th nzWidth="25%"><input type="number" nz-input [(ngModel)]="PacketWeight" /></th>
                  <th nzWidth="25%">
                    <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedTransportChange($event)"
                      [(ngModel)]="TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                      <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                        [nzLabel]="s.TransportCompanyName"></nz-option>
                    </nz-select>
                  </th>
                  <th nzWidth="25%">
                    <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default" [(ngModel)]="VehicleId"
                      nzAllowClear nzPlaceHolder="Choose">
                      <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId"
                        [nzLabel]="s.VehicleNumber"></nz-option>
                      <nz-option nzValue="0" nzLabel="Others"></nz-option>
                    </nz-select>
                  </th>
                  <th>
                    <input type="text" nz-input [(ngModel)]="DispatchNumber" />
                  </th>
                  <th>
                    <nz-date-picker class="form-select" [(ngModel)]="DispatchDate" nzValue="default"></nz-date-picker>
                  </th>
                  <th style="text-align:center"><button nz-button nzType="primary"
                      (click)="AddSaleOrderDispatchItems()">ADD</button></th>

                </tr>
                <tr *ngFor="let data of SaleOrderDispatchItems;let i=index">
                  <td>{{data.PacketNumber}}</td>
                  <td>{{data.PacketWeight}}</td>
                  <td>{{data.TransportName}}</td>
                  <td>{{data.VehicleNumber}}</td>
                  <td>{{data.DispatchNumber}}</td>
                  <td>{{data.DispatchDate | date}}</td>
                  <td>
                    <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)">
                      <i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;">
                      </i>Remove
                    </a>
                  </td>
                </tr>

              </tbody>
              <tfoot>
                <tr *ngIf="this.SaleOrderDispatchItems.length>0">
                  <td nzWidth="40%"><b>Total Packet: </b>{{sumPacketNumber}}</td>
                  <td nzWidth="40%"><b>Total KG: </b>{{sumPacketWeight}}</td>
                  <td></td>

                </tr>
              </tfoot>
            </nz-table>
            <nz-divider></nz-divider>

            <nz-divider></nz-divider>
            <div class="text-center" *ngIf="this.SaleOrderDispatchItems.length>0">
              <b >
                <label style="margin:10px" nz-checkbox [(ngModel)]="this.IsDispatchCompleted"><b> Complete Dispatch </b></label>
              </b>
              <button nz-button nzType="primary" (click)="SaveDispatchItems()">Save and Dispatch</button>
            </div>
          </div>
        </div>
      </div>

    </div>





  </div>
</div>
