import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { Modules, Responsibility } from '../../Models/Enums';
import { AdminStoreModel } from '../../Models/MasterModel';
import { UserModel, UserRoleModel, UserStoreMappingModel } from '../../Models/UserModel';
import { UsernameUserRoleMappingModel, UserRoleMasterModel } from '../../Models/UserRoleModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';



@Component({
  selector: 'app-Admin',
  templateUrl: './Admin.component.html',
  styleUrls: ['./Admin.component.css'],
})
export class AdminComponent {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  UserRoles: UserModel = new UserModel();
  UserList: UserModel[] = [];
  UserListOriginal: UserModel[] = [];
  UserRoleList: UserRoleMasterModel[] = [];
  isLoading: boolean = false;
  isStoreLoading: boolean = false;
  isVisible = false;
  isRoleVisible = false;
  isStoreVisible = false;
  isNew = true;
  isTableLoading: boolean = false;
  StoreList: AdminStoreModel[] = [];
  allChecked = false;
  IsAuth = false;
  searchValue = ''
  count: 0;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private msalService: MsalService,
    public http: HttpClient,
    private modalService: NzModalService,
  ) { }

  handleOk(): void {
    this.isLoading = true;
    this.SaveUser();
  }
  GetAllUsers() {
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        console.log(res);

        this.UserList = res;
        this.UserListOriginal = res;
        this.UserList.forEach(el => {
          el.Roles = el.UserRole.map(x => x.UserRoleName).join(',');

        })
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  search(): void {

    var res = this.UserListOriginal;
    this.UserList = res.filter((item: UserModel) => {
      if (
        item?.Address?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;

      } else if (
        item?.Contact?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Email?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }


      else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item.UserId == +this.searchValue
      ) {
        return true;
      }
      // else if (
      //   item?.Roles?.toLowerCase().includes(this.searchValue?.toLowerCase())
      // ) {
      //   return true;
      // } 
      else if (
        item?.Roles == this.searchValue
      ) {
        return true;
      }

      return false;
    });
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  GetAllUserRole() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + 'User/getallUserrole';
    this.http.get<any>(url).subscribe(
      (res) => {
        this.UserList = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllUserRole();
        }
      }
    );
  }
  GetRole(UserName: string) {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + 'User/getuserrole/' + UserName;
    this.http.get<any>(url).subscribe(
      (res) => {
        if (res != null) {
          this.UserRoles = res;
        } else {
          //this.UserRoles = new UserRoleModel();
          //this.UserRoles.Username = UserName;
        }
      },
      (res) => { }
    );
  }
  GetAllUserRoles() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "userrolesresponsibility/getalluserroles";
    this.http.get<any>(url).subscribe(
      (res) => {
        this.UserRoleList = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllUserRoles();
        }
      }
    );
  }
  GetUserRoles(Username: string) {
    this.UserRoles = new UserModel;
    this.UserRoles.Email = Username;
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "userrolesresponsibility/getalluserrolesbyusername/" + Username;
    this.http.get<UserRoleMasterModel[]>(url).subscribe(
      (res) => {
        var aa = res.map(x => x.UserRoleId);
        //console.log(aa)
        this.UserRoleList.forEach(el => {
          if (aa.includes(el.UserRoleId)) {
            el.IsChecked = true;
          } else {
            el.IsChecked = false;
          }
        })
      },
      (res) => {
        //this.GetAllUserRoles();
      }
    );
  }
  SaveRole() {
    console.log("userinfo", UserInfo.UserRolesMaster)
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName == "Super Admin").length <= 0) {
      this.alertService.error("You are not authorized. Only Super Admin can make this change.");
      return;
    }
    let url = this.ApiUrl + 'user/addupdaterole';
    this.http.post<any>(url, this.UserRoles).subscribe({
      next: (res) => {
        this.alertService.success('Roles Saved Successfully');
        this.isLoading = false;
        this.handleRoleCancel();
        this.GetAllUsers();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = false;
      },
    });
  }
  SaveUser() {
    console.log("userinfo", UserInfo.UserRolesMaster)
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName == "Super Admin").length <= 0) {
      this.alertService.error("You are not authorized. Only Super Admin can make this change.");
      return;
    }
    if (this.UserRoles.Email == '') {
      this.alertService.error('Email ID required');
      return;
    }
    if (this.UserRoles.Name == '') {
      this.alertService.error('User Name required');
      return;
    }
    if ((this.UserList.filter(x => x.Email == this.UserRoles.Email).length > 0) && (this.isNew == true)) {
      this.alertService.error('Email Already Exist');
      return;
    }
    this.isLoading = true;
    let url = this.ApiUrl + 'userrolesresponsibility/Adduser';
    this.http.post<any>(url, this.UserRoles).subscribe({
      next: (res) => {
        this.alertService.success('User Saved Successfully');
        this.isLoading = false;
        this.handleCancel();
        this.GetAllUsers();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = false;
      },
    });
  }
  GetMyRole(UserName: string) {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + 'User/getuserrole/' + UserName;
    this.http.get<any>(url).subscribe(
      (res) => {
        if (res != null) {
          UserInfo.UserRoles = res;
          this.IsAuth = UserInfo.UserRoles.Admin;
        }
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetMyRole(UserInfo.EmailID);
        }
      }
    );
  }
  onUsernamechange() {
    //this.GetRole(this.UserRoles.Email);
  }
  ngOnInit() {
    this.IsAuth = this.authService.CheckModule(Modules.AdminUsers);
    //this.GetMyRole(UserInfo.EmailID);
    this.GetAllUsers();
    //this.GetAllUserRole();
    this.GetAllUserRoles();
    this.GetAllStore();
    this.validateForm = this.fb.group({
      Username: [null, [Validators.required]],
    });
  }
  get f() {
    return this.validateForm.controls;
  }
  showModal() {
    this.isNew = true;
    this.isVisible = true;
    this.UserRoles = new UserModel();
  }
  OpenEditPop(data: UserModel) {
    this.isNew = false;
    this.UserRoles = data;
    //this.GetRole(data.Email);
    //this.GetUserRoles(data.Email);
    this.isVisible = true;
  }
  OpenRolePop(data: UserModel) {
    //this.GetRole(data.Username);
    this.GetUserRoles(data.Email);
    this.isRoleVisible = true;
  }
  handleDisable(data: UserModel) {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to disable User - ' + data.Name + '?',
      nzOkDanger: true,
      nzOkText: 'Yes',
      nzOnOk: () => this.DisableUser(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }
  DisableUser(data: UserModel) {
    this.UserRoles = data;
    //this.GetRole(data.Email);
    //this.GetUserRoles(data.Email);
    this.isLoading = true;
    let url = this.ApiUrl + 'userrolesresponsibility/removeuser/' + data.Email.split('@')[0];
    this.http.get<any>(url).subscribe({
      next: (res) => {
        this.alertService.success('User Disabled Successfully');
        this.isLoading = false;
        this.GetAllUsers();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = false;
      },
    });
  }
  handleCancel(): void {
    this.isVisible = false;
    this.isStoreVisible = false;
  }
  handleRoleCancel(): void {
    this.isRoleVisible = false;
    this.isStoreVisible = false;
  }
  OpenStorePop(data: UserModel) {
    this.isNew = false;
    this.UserRoles.Email = data.Email;
    this.GetUserStores(data.Email);

    this.isStoreVisible = true;
  }
  GetAllStore() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<AdminStoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;

        this.isTableLoading = false;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetUserStores(UserName: string) {
    this.isTableLoading = true;
    this.StoreList.forEach((x) => (x.IsChecked = false));
    let url = this.ApiUrl + 'user/getuserstores/' + UserName;
    this.http.get<number[]>(url).subscribe(
      (res) => {
        console.log(res);
        this.StoreList.forEach((s) => {
          if (res.includes(s.StoreId)) {
            s.IsChecked = true;
          }
        });
        this.isTableLoading = false;
      },
      (res) => { }
    );
  }
  updateAllChecked() {
    this.StoreList.forEach((x) => (x.IsChecked = this.allChecked));
  }
  SaveStore(UserName: string) {
    var request: UserStoreMappingModel[] = [];
    this.StoreList.forEach((x) => {
      var r = new UserStoreMappingModel();
      r.Action = x.IsChecked == true ? 'add' : 'remove';
      r.StoreId = x.StoreId;
      r.UserStoreId = x.StoreId;
      r.Username = UserName;
      request.push(r);
    });
    let url = this.ApiUrl + 'user/addupdateuserstoremapping';
    console.log(request);
    this.http.post<any>(url, request).subscribe({
      next: (res) => {
        this.alertService.success('Store  Saved Successfully');
        this.isLoading = false;
        this.handleRoleCancel();
        this.GetAllUsers();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = false;
      },
    });
  }
  SaveUserRole() {
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName == "Super Admin").length <= 0) {
      this.alertService.error("You are not authorized. Only Super Admin can give access to a role.");
      return;
    }
    var Request: UsernameUserRoleMappingModel[] = [];

    this.UserRoleList.forEach(el => {
      var m: UsernameUserRoleMappingModel = new UsernameUserRoleMappingModel;
      m.AddedBy = UserInfo.EmailID;
      m.UserRoleId = el.UserRoleId;
      m.Username = this.UserRoles.Email;
      if (el.IsChecked) {
        Request.push(m);
      }
    })

    if (Request.length > 0) {
      let url = this.ApiUrl + "userrolesresponsibility/addupdateusernameuserrolemapping";
      this.http.post<any>(url, Request).subscribe({

        next: res => {
          if (res.Code == "Forbid") {
            this.alertService.warning(res.Message.toString());
            this.isLoading = this.isRoleVisible = false;
          }
          else {
            this.alertService.success("Role Saved Successfully"); this.isLoading = this.isRoleVisible = false; this.GetAllUsers();
          }
        },
        error: res => { console.log(res); this.alertService.error(res.Message.toString()); this.isLoading = this.isRoleVisible = false; },

      });
    }
  }
}
