<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Wastage Report</nz-page-header-title>
        </nz-page-header>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="5">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label style="display:block">From</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.DateFrom"
                                    nzPlaceHolder="Date"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>To</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.DateTo"
                                    nzPlaceHolder="Date"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="WastageReport()">
                            Search
                        </button>
                    </div>
                    <div nz-col [nzSpan]="5">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label style="display:block;margin-bottom:5px;">Zero Wastage Orders</label>
                                <nz-radio-group [(ngModel)]="ShowWastage" (ngModelChange)="CalculateWastage()">
                                    <label nz-radio nzValue="Y">Hide</label>
                                    <label nz-radio nzValue="N">Show</label>
                                </nz-radio-group>

                            </nz-form-control>
                        </nz-form-item>
                    </div>

                </div>

            </div>
        </div>
        <div style="max-width:100%; overflow:scroll">
            <nz-table id="print-section" nzSize="small" style="width: 100%" #basicTable [nzShowPagination]="false"
                [nzPageSize]="1000" [nzData]="this.WastageReportList" [nzLoading]="isTableLoading" nzBordered>
                <thead>
                    <tr style="text-align: left;">

                        <th>S. No.</th>
                        <th nzWidth="120px">WorkPlan No.</th>
                        <th nzWidth="120px">Sale Order No.</th>
                        <th nzWidth="100px">Jumbo No.</th>
                        <th nzWidth="180px">Sale Order Code</th>
                        <th>Jumbo QTY</th>
                        <th>After PP Quantity</th>
                        <th>Wastage Embossing</th>
                        <th>Wastage Lacquer</th>
                        <th>Wastage Print</th>
                        <th>Wastage Tumbling</th>
                        <th>Wastage Vacuum</th>
                        <th>Wastage Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data; let i = index">

                        <td>{{ i + 1 }}</td>
                        <td>{{ data.WorkPlanNo }}</td>
                        <td>{{ data.SaleOrderNumber }}</td>
                        <td>{{ data.JumboNo }}</td>
                        <td nzBreakWord>{{ data.SaleOrderCode }}</td>
                        <td>{{ data.JumboRolQty }}</td>
                        <td>{{ data.ActualQuantity }}</td>
                        <td>{{ data.WastageEmbossing }}</td>
                        <td>{{ data.WastageLacquer }}</td>
                        <td>{{ data.WastagePrint }}</td>
                        <td>{{ data.WastageTumbling }}</td>
                        <td>{{ data.WastageVacuum }}</td>
                        <td>{{ data.WastageEmbossing + data.WastageLacquer + data.WastagePrint + data.WastageTumbling +
                            data.WastageVacuum }}</td>
                    </tr>
                </tbody>

            </nz-table>

        </div>
    </div>
</div>