import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  CustomerList: CustomerModel[] = [];
  CustomerListOriginal: CustomerModel[] = [];
  NewCustomer: CustomerModel = new CustomerModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Customer";
  NameError = 'Enter Customer name';
  exportoptions = {
    headers: ["Customer Name", "Email", "Customer Contact Number", "Address", "State", "Country"]
  };
  fields: any;
  exportfields = [{
    "CustomerName": "",
    "Email": "",
    "CustomerContactNumber": "",
    "Address": "",
    "State": "",
    "Country": ""
  }];
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService,
    private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Customer, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Customer, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Customer, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      CustomerName: [null, [Validators.required, Validators.minLength(3)], [this.NameAsyncValidator]],
      Email: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}(,[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})*$")]],
      CustomerContactNumber: [null, [Validators.required]],
      Address: [null, [Validators.required]],
      //Gst: [null, [Validators.required,  Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]]
      Gst: [null, [Validators.required]],
      Country: [null, [Validators.required]],
      State: [null, [Validators.required]]
    });
    this.GetAllCustomer();
  }
  get f() { return this.validateForm.controls; }

  GetAllCustomer() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
      this.CustomerListOriginal = res;
      this.exportfields = [];
      this.CustomerListOriginal.forEach(x => {

        this.fields = {};
        this.fields.CustomerName = x.CustomerName;
        this.fields.Email = x.Email;
        this.fields.CustomerContactNumber = x.CustomerContactNumber;
        this.fields.Address = x.Address;
        this.fields.State = x.State;
        this.fields.Country = x.Country;
        this.exportfields.push(this.fields);

      })
      this.isTableLoading = false;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllCustomer();
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  SaveCustomer() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewCustomer.CustomerName = this.f['CustomerName'].value;
    this.NewCustomer.Email = this.f['Email'].value;
    this.NewCustomer.CustomerContactNumber = this.f['CustomerContactNumber'].value;
    this.NewCustomer.Address = this.f['Address'].value;
    this.NewCustomer.Gstnumber = this.f['Gst'].value;
    this.NewCustomer.State = this.f['State'].value;
    this.NewCustomer.Country = this.f['Country'].value;
    // if(!this.Validate(this.NewCustomer))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "customer/addupdatecustomer";
    this.http.post<CustomerModel>(url, this.NewCustomer).subscribe({

      next: res => { this.alertService.success("Customer Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllCustomer(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  Validate(model: CustomerModel) {
    var Isvalid: boolean = true;
    //const emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}(,[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})*$";
    if (model.CustomerName == '') {
      this.alertService.error("Enter Customer Name"); Isvalid = false;
    }
    // else if (model.Email == '') {
    //   this.alertService.error("Enter Email"); Isvalid = false;
    // }
    else if (model.Address == '') {
      this.alertService.error("Enter Address"); Isvalid = false;
    }
    else if (model.State == '') {
      this.alertService.error("Enter State"); Isvalid = false;
    }
    else if (model.Country == '') {
      this.alertService.error("Enter State"); Isvalid = false;
    }
    else if (model.Gstnumber == '') {
      this.alertService.error("Enter GST"); Isvalid = false;
    }

    return Isvalid;
  }
  OpenEditPop(data: CustomerModel) {
    this.PopUpTitle = "Modify Customer Details";
    this.validateForm.setValue(
      {
        CustomerName: data.CustomerName,
        Email: data.Email,
        CustomerContactNumber: data.CustomerContactNumber,
        Address: data.Address,
        Gst: data.Gstnumber,
        State: data.State,
        Country: data.Country
      }
    )
    this.NewCustomer = new CustomerModel();
    this.NewCustomer.CustomerId = data.CustomerId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Customer";
    this.validateForm.reset();
    this.NewCustomer.CustomerName = "";
    this.NewCustomer.CustomerId = 0;
    this.NewCustomer.State = "";
    this.NewCustomer.Country = "";
    this.NewCustomer.Email = "";
    this.NewCustomer.CustomerContactNumber = "";
    this.NewCustomer.Address = "";
    this.NewCustomer.Gstnumber = "";
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveCustomer();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  export() {

    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'customer-export' + exportdate, this.exportoptions);
  }
  DeleteCustomer() {
    let url = this.ApiUrl + "customer/deletecustomer";
    this.http.post<any>(url, this.NewCustomer).subscribe({

      next: res => {

        this.alertService.success("Customer Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllCustomer();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: CustomerModel) {
    this.NewCustomer = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete Customer Name - ' + data.CustomerName + '?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteCustomer(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.CustomerList.filter(x => x.CustomerId != this.NewCustomer.CustomerId);
        var nre = res.filter(x => x.CustomerName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Customer name";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Customer name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Customer name";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search(): void {
    this.visible = false;
    var res = this.CustomerListOriginal;
    this.CustomerList = res.filter((item: CustomerModel) => item?.CustomerName?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.Email?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.CustomerContactNumber?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.Address?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1);
  }

}
