<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Tag List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your tag here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button
          nz-button
          nzType="primary"
          *ngIf="this.permission.Add"
          (click)="showModal()"
        >
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table
      nzSize="small"
      [nzPageSize]="100"
      style="width: 100%"
      #basicTable
      [nzData]="this.TagList"
      [nzLoading]="isTableLoading"
      nzBordered
    >
      <thead>
        <tr>
          <th>Tag Name</th>
          <th>Tag Desc</th>
          <th>Added Date</th>
          <th>AddedBy</th>

          <th nzWidth="20%" *ngIf="permission.Add || permission.Delete">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.TagName }}</td>
          <td>{{ data.TagDesc }}</td>
          <td>{{ data.TagAddedDate |DatetimeConverter }}</td>
          <td>{{ data.TagAddedBy }}</td>

          <td *ngIf="CheckUserAddPermission(data.TagAddedBy)">
            <button
              class="btn btn-sm btn-light-primary"
              (click)="OpenEditPop(data)"
            >
              Edit
            </button>
            &nbsp;
            <button
              class="btn btn-sm btn-light-danger"
              (click)="handleDelete(data)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <nz-modal
      [nzWidth]="800"
      [nzStyle]="{ top: '20px' }"
      [(nzVisible)]="isVisible"
      [nzTitle]="modalTitle"
      [nzContent]="modalContent"
      [nzFooter]="null"
      (nzOnCancel)="handleCancel()"
    >
      <ng-template #modalTitle>{{ PopUpTitle }}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Tag Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="NameError">
              <input nz-input formControlName="TagName" name="TagName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8">Description</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <textarea
                rows="4"
                nz-input
                formControlName="TagDesc"
                name="TagDesc"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">
              Save Tag
            </button>
          </div>
        </form>
      </ng-template>
    </nz-modal>
  </div>
</div>
