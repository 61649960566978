<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Proforma Invoice</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Customer</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Select Customer">
              <nz-select class="form-select" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                nzPlaceHolder="Choose" [(ngModel)]="NewPO.CustomerId" [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId" [nzLabel]="s.CustomerName">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="14" nzRequired>Delivery Terms</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <nz-select class="form-select" nzShowSearch name="VehicleId" formControlName="deliveryTermId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="updateTermsAndCondition($event)">
                <nz-option *ngFor="let s of this.DeliveryTermList;" [nzValue]="s.DeliveryTermId" [nzLabel]="s.DeliveryTerm"></nz-option>
              </nz-select>                            
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Bank</nz-form-label>
            <nz-form-control [nzSpan]="12">

              <nz-select class="form-select" nzShowSearch nzSize="default" [(ngModel)]="NewPO.BankId" [ngModelOptions]="{standalone: true}" nzPlaceHolder="Choose">

                <nz-option *ngFor="let s of this.BankList;" [nzValue]="s.BankId" [nzLabel]="s.BankName"></nz-option>
              </nz-select>

            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Material Type </nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="MaterialType" [(ngModel)]="NewPO.MaterialType" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Mode of Transport</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="ModeofTransport" [(ngModel)]="NewPO.ModeOfTransport" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Buyer Ref No</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="BuyerRefNo" [(ngModel)]="NewPO.BuyerReferenceNumber" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Currency</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select Currency">

              <nz-select class="form-select" nzShowSearch nzSize="default" formControlName="Currency" name="Currency">
                <nz-option *ngFor="let s of this.CurrencyList;" [nzValue]="s" [nzLabel]="s"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Discount %</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select Payment Terms">
              <input nz-input type="number" [(ngModel)]="NewPO.Discount" [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">

          <nz-form-item>
            <nz-form-label [nzSpan]="14" nzRequired>Country of Origin</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="CountryofOrigin" [(ngModel)]="NewPO.CountryOfOrigin" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="14" nzRequired>HSN Code</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="HSNCode" [(ngModel)]="NewPO.Hsncode" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="14" nzRequired>Final Destination</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="FinalDestination" [(ngModel)]="NewPO.FinalDestination" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="14" nzRequired>Ref Type</nz-form-label>
            <nz-form-control [nzSpan]="12">

              <nz-select class="form-select" nzShowSearch nzSize="default" [(ngModel)]="NewPO.ReferenceType"
                [ngModelOptions]="{standalone: true}">
                <nz-option nzValue="Consignor" nzLabel="Consignor"></nz-option>
                <nz-option nzValue="Other" nzLabel="Other"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="14" nzRequired> {{NewPO.ReferenceType}} </nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Maximum number of 50 characters allowed">
                <input nz-input formControlName="ConsignoReferencer" [(ngModel)]="NewPO.ConsignorReference" maxlength="50" />
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>PI Date</nz-form-label>
            <nz-form-control [nzSpan]="12">

              <nz-date-picker class="form-select" [(ngModel)]="NewPO.ProformaInvoiceDate"
                [nzDisabledDate]="disabledStartDate" [ngModelOptions]="{standalone: true}"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Port of loading</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="Portofloading" [(ngModel)]="NewPO.PortOfLoading" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Port Discharge</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Maximum number of 50 characters allowed">
              <input nz-input formControlName="PortDischarge" [(ngModel)]="NewPO.PortOfDischarge" maxlength="50" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>MOQ %</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select Payment Terms">
              <input nz-input type="number" [(ngModel)]="NewPO.Moq" [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>IGST</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter IGST">
              <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewPO.Gstn" nzPlaceHolder="Choose"
                [ngModelOptions]="{standalone: true}">
                <nz-option nzValue="0" nzLabel="N/A"></nz-option>
                <nz-option nzValue="5" nzLabel="5%"></nz-option>
                <nz-option nzValue="12" nzLabel="12%"></nz-option>
                <nz-option nzValue="18" nzLabel="18%"></nz-option>
                <nz-option nzValue="28" nzLabel="28%"></nz-option>

              </nz-select>
            </nz-form-control>
          </nz-form-item>

        </div>
      </div>

      <nz-divider></nz-divider>

      <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="4">
          <nz-form-item>


            <nz-form-control [nzSpan]="40" nzErrorTip="Select supplier Name">
              <label>Zaibunco Product Code</label>
              <!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
              <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                [(ngModel)]="NewInvoiceItem.SaleFormulationCodeId" [ngModelOptions]="{standalone: true}"
                name="SaleFormulationCodeId" nzAllowClear nzPlaceHolder="Select Product Code">
                <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                  [nzLabel]="s.SaleFormulationCode"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>


            <nz-form-control [nzSpan]="40" nzErrorTip="Maximum number of 150 characters allowed">
              <label>Description of Goods </label>
                <input nz-input formControlName="DescriptionofGoods" [(ngModel)]="NewInvoiceItem.DescriptionOfGoods" maxlength="150" />

            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="4">
          <nz-form-item>


            <nz-form-control [nzSpan]="40" nzErrorTip="Maximum number of 50 characters allowed">
              <label>Article Name </label>
              <input nz-input formControlName="ArticleName" [(ngModel)]="NewInvoiceItem.ArticleName" maxlength="50" />

            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="2">
          <nz-form-item>

            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Quantity">
              <label class="required">Quantity</label>
              <input nz-input name="Quantity" placeholder="Quantity" value="NewInvoiceItem.Quantity"
                [(ngModel)]="NewInvoiceItem.Quantity" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="OnQtychange($event)" />
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="2">
          <nz-form-item>

            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
              <label class="required">Rate</label>
              <input nz-input name="Rate" placeholder="Rate" value="NewInvoiceItem.Price"
                [(ngModel)]="NewInvoiceItem.Price" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="OnRatechange($event)" />
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="2">
          <nz-form-item>
            <nz-form-control [nzSpan]="40" nzErrorTip="Enter Amount">
              <label>Amount</label>
              <input nz-input name="Amount" style="color: #000;" disabled placeholder="Amount"
                value="NewInvoiceItem.Amount" [(ngModel)]="NewInvoiceItem.Amount"
                [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="2" style=" padding-top: 21px;">
          <a class="btn btn-sm btn-light-primary" style="float:right ; " (click)="btnAddClick()">
            <i nz-icon nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add
          </a>
        </div>

      </div>

      <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzData]="['']" #basicTable1 nzBordered>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Zaibunco Product Code</th>
            <th>Description of Goods</th>
            <th>Article Name</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Amount</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of NewPO.ProformaInvoiceItem;let i=index">
            <td>{{i+1}}</td>
            <td>{{ data.SaleFormulationCodeProductName }}</td>
            <td>{{ data.DescriptionOfGoods }}</td>
            <td>{{ data.ArticleName }}</td>
            <td>{{ data.Quantity }}</td>
            <td>{{ data.Price }}</td>
            <td>{{ data.Amount }}</td>
            <td>
              <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)">
                <i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;">
                </i>Remove
              </a>
            </td>
          </tr>

        </tbody>

      </nz-table>
      <nz-divider></nz-divider>

      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="6">

        </div>
        <div nz-col [nzSpan]="18">

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Terms & Condition</nz-form-label>
            <nz-form-control [nzSpan]="20" nzErrorTip="Maximum number of 500 characters allowed">
              <textarea nz-input formControlName="TermsAndCondition" [(ngModel)]="NewPO.TermsCondition" rows="6"
                maxlength="500"></textarea>
            </nz-form-control>
          </nz-form-item>


        </div>
      </div>

      <div class="text-center">
        <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
      </div>

    </form>


  </div>
</div>
