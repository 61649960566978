import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { environment } from '../../../../environments/environment';
import { ProductCategoryReportChildModel, ProductCategoryReportParentModel, ProductWiseStockWithSupplierReportExportModel, SearchParamsProductCategoryReportModel } from '../../../Models/ReportModel';
import { AuthService } from '../../../Services/auth.service';
import { LoadingService } from '../../../Services/loadingService';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../../Models/MasterModel';
import { StockProductModel } from '../../../Models/StockProductModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { Modules, Responsibility } from '../../../Models/Enums';
import moment from 'moment';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';





@Component({
  selector: 'app-CategoryWiseStock',
  templateUrl: './CategoryWiseStock.component.html'
})

export class CategoryWiseStock implements OnInit {
  ApiUrl = environment.Api_Url;
  searchValue = '';
  isTableLoading: boolean = false;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any;
  dateTypeSelected = ''
  request: SearchParamsProductCategoryReportModel = new SearchParamsProductCategoryReportModel;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  exportfields: ProductWiseStockWithSupplierReportExportModel[] = [];
  ReportList: StockProductModel[] = [];
  FilteredReportList: ProductCategoryReportParentModel[] = [];
  typeList: any[] = [];
  FirstReport: any[] = [];
  //fields: ProductWiseStockWithSupplierReportExportModel = new ProductWiseStockWithSupplierReportExportModel();
  exportoptions = {
    headers: [
      "SNo",
      "Product Name",
      "Product Type",
      "Category",
      "Subcategory",
      "2nd Subcategory",
      "Unit",
      "QTY",
      "Price/Unit",
      "Total AMT",
      "Total QTY",
      "Grand Total AMT",



    ]
  };
  exportoptions1 = {
    headers: [

      "Type",
      "Category",
      "Subcategory",
      "2nd Subcategory",
      "Unit",
      "Total QTY",
      "Total AMT",

    ]
  };
  IsMsgShow = false;
  Total = {
    QTY: 0,
    Amount: 0
  }
  FilteredProductCategoryList: ProductCategoryModel[];
  FilteredProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  FilteredProductSecSubCategoryList: ProductSecSubCategoryModel[];
  MeasureUnits: MeasureUnitModel[] = [];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  SupplierList: SupplierModel[];
  constructor(private authService: AuthService,
    private alertService: AlertMessageService, private auth: AuthService, private router: Router, public http: HttpClient, private loader: LoadingService) {
  }
  GetReport() {
    this.IsMsgShow = false;
    //this.request.ProductId = 35253;
    console.log(this.request);
    if (this.request.ProductType == '') {
      this.alertService.error("Select Product Type");
      return;
    }
    //if (this.request.ProductCategoryId <=0) {
    //  this.alertService.error("Select Product Category");
    //  return;
    //}
    if (isNaN(this.request.ProductCategoryId) || this.request.ProductCategoryId == null) {
      this.request.ProductCategoryId = 0;
    }
    if (isNaN(this.request.ProductFirstSubCategoryId) || this.request.ProductFirstSubCategoryId == null) {
      this.request.ProductFirstSubCategoryId = 0;
    }
    if (isNaN(this.request.ProductSecSubCategoryId) || this.request.ProductSecSubCategoryId == null) {
      this.request.ProductSecSubCategoryId = 0;
    }
    if (this.request.IncludeWIPStore == "null") {
      this.request.IncludeWIPStore = null;
    }
    let url = this.ApiUrl + 'stock/GetProductWisestockByFilter';
    this.http.post<any>(url, this.request).subscribe({

      next: res => {
        console.log(res)
        if (res == 'An error has occured. Please contact administrator') {
          this.alertService.error(res);
          return
        }
        if (this.request.IncludeWIPStore == null) {
          this.request.IncludeWIPStore = "null";
        }
        this.ReportList = res;

        if (this.request.Unit != '' && this.request.Unit != null) {
          this.ReportList = res.filter(x => x.Unit == this.request.Unit);
        }
        console.log(this.ReportList)
        if (this.ReportList.length <= 0) {
          this.IsMsgShow = true;
          return;
        }

        this.FilteredReportList = [];
        let uniqueProduct = [...new Set(res.map((item) => item.ProductId))];
        var fp = this.ReportList.filter((x) =>
          uniqueProduct.includes(x.ProductId)
        );
        uniqueProduct.forEach((x1) => {
          var it = new ProductCategoryReportParentModel();
          var x = this.ReportList.filter(z => z.ProductId == x1)[0];
          it.ProductType = x.ProductType;
          it.ProductCode = x.ProductCode;
          it.ProductName = x.ProductName;
          it.ProductCategory = x.ProductCategory;
          it.ProductFirstSubCategory = x.ProductFirstSubCategory ?? '';
          it.ProductSecSubCategory = x.ProductSecSubCategory ?? '';
          it.TotalAmount = res
            .filter((y) => y.ProductId == x.ProductId)
            .reduce(
              (sum, current) => sum + (parseFloat(current.PricePerUnit) * parseFloat(current.Quantity)),
              0
            ) ?? 0;
          it.TotalAmount = parseFloat(it.TotalAmount.toFixed(3));
          it.TotalQty = res
            .filter((y) => y.ProductId == x.ProductId)
            .reduce(
              (sum, current) => sum + parseFloat(current.Quantity?.toFixed(3)),
              0
            ) ?? 0;
          it.TotalQty = parseFloat(it.TotalQty.toFixed(2))
          it.Unit = x.Unit;
          res
            .filter((y) => y.ProductId == x.ProductId)
            .forEach((iit) => {
              var st = new ProductCategoryReportChildModel();
              st.Amount = iit.PricePerUnit * iit.Quantity;
              st.Amount = parseFloat(st.Amount.toFixed(3));
              st.Rate = iit.PricePerUnit;
              st.Qty = iit.Quantity;
              st.Unit = iit.Unit;
              it.ChildList.push(st);
            });

          this.FilteredReportList.push(it);
        });
        this.FirstReport = [];
        let uniqueCategory = [... new Set(res.map((item) => item.ProductCategoryId))];
        if (this.request.ProductCategoryId > 0) {
          uniqueCategory.includes(this.request.ProductCategoryId)
        }
        else {
          var tf = this.ReportList.filter((x) => {
            uniqueCategory.includes(x.ProductCategoryId)
          })
        }
        uniqueCategory.forEach((x1) => {
          var it: any = {};
          var x = this.ReportList.filter(z => z.ProductCategoryId == x1)[0];
          it.Type = x.ProductType;
          it.Category = x.ProductCategory;
          it.FirstSubCategory = this.request.ProductFirstSubCategoryId > 0 ? x.ProductFirstSubCategory : "ALL";
          it.SecSubCategory = this.request.ProductSecSubCategoryId > 0 ? x.ProductSecSubCategory : "ALL";
          it.Unit = (this.request.Unit != "" && this.request.Unit != null) ? x.Unit : "ALL";

          it.TotalQty = res
            .filter((y) => y.ProductCategoryId == x.ProductCategoryId)
            .reduce(
              (sum, current) => sum + parseFloat(current.Quantity?.toString()),
              0
            ) ?? 0;

          it.TotalAmount = res
            .filter((y) => y.ProductCategoryId == x.ProductCategoryId)
            .reduce(
              (sum, current) => sum + (parseFloat(current.PricePerUnit) * parseFloat(current.Quantity)),
              0
            ) ?? 0;
          it.TotalAmount = parseFloat(it.TotalAmount.toFixed(3));
          it.TotalQty = parseFloat(it.TotalQty.toFixed(3))
          it.TotalAmount = parseFloat(it.TotalAmount.toFixed(3))
          this.FirstReport.push(it);
        });
        console.log(this.FirstReport)
        this.Total.QTY = this.FilteredReportList.reduce(
          (sum, current) => sum + parseFloat(parseFloat(current.TotalQty?.toString()).toFixed(3)),
          0
        ) ?? 0;
        this.Total.Amount = this.FilteredReportList.reduce(
          (sum, current) => sum + parseFloat(parseFloat(current.TotalAmount?.toString()).toFixed(3)),
          0
        ) ?? 0;

        this.structureExportFields()
      },
      error: res => {

      },

    });

  }

  onSelectedProductTypeChange() {
    this.ReportList = [];
    this.request.ProductCategoryId = this.request.ProductFirstSubCategoryId = this.request.ProductSecSubCategoryId = 0;
    this.FilteredProductCategoryList = this.ProductCategoryList.filter(
      (x) => x.ProductType == this.request.ProductType
    );
  }

  // onSelectedProductCategoryChange() {
  //   this.FilteredProductFirstCategoryList = this.ProductFirstSubCategoryList.filter(
  //     (x) => x.ProductCategoryId == this.request.ProductCategoryId
  //   );
  // }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsCategoryWiseStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsCategoryWiseStock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsCategoryWiseStock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProductCategory();

    this.GetAllUnits();
    this.typeList = [
      { name: "Raw" },
      { name: "Finished" },
      { name: "Internal-Use" }
    ];
    //this.dateTypeSelected = this.MDate[2];
  }
  reset(): void {
    this.searchValue = '';

    this.GetReport()
    this.myDateValue = undefined;
    this.toDate = undefined;

  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'stock-report-export' + exportdate,
        this.exportoptions
      );
  }
  export1() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.FirstReport,
        'stock-report-export' + exportdate,
        this.exportoptions1
      );
  }
  GetAllProductCategory() {
    this.ReportList = [];
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(); }
    });

  }
  GetAllFirstCategory(data: any) {
    this.ReportList = [];
    this.request.ProductFirstSubCategoryId = this.request.ProductSecSubCategoryId = 0;
    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.FilteredProductFirstSubCategoryList = this.ProductFirstSubCategoryList.filter(
        (x) => x.ProductCategoryId == this.request.ProductCategoryId
      );
    }, res => { });
  }
  GetAllSecondCategory(data: any) {
    this.ReportList = [];
    this.request.ProductSecSubCategoryId = 0;
    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.FilteredProductSecSubCategoryList = this.ProductSecSubCategoryList.filter(
        (x) => x.ProductFirstSubCategoryId == this.request.ProductFirstSubCategoryId
      );

    }, res => { });
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnits(); }
    });
  }
  OnUnitChange() {
    this.ReportList = [];
  }
  structureExportFields() {
    let resultArr = [];
    console.log(this.FilteredReportList)
    for (let j = 0; j < this.FilteredReportList.length; j++) {
      if (this.FilteredReportList.length == 1) {
        const obj = {
          SNO: j + 1,

          ProductName: this.FilteredReportList[j].ProductName,
          ProductType: this.FilteredReportList[j].ProductType,
          ProductCategory: this.FilteredReportList[j].ProductCategory,
          ProductFirstSubCategory: this.FilteredReportList[j].ProductFirstSubCategory,
          ProductSecSubCategory: this.FilteredReportList[j].ProductSecSubCategory,
          Unit: this.FilteredReportList[j].Unit,
          TotalQty: this.FilteredReportList[j].TotalQty,
          TotalAmount: this.FilteredReportList[j].TotalAmount,
        };
        resultArr.push({ ...obj, ...this.FilteredReportList[j].ChildList[0] });
      }
      else if (this.FilteredReportList.length > 1) {
        for (let i = 0; i < this.FilteredReportList[j].ChildList.length; i++) {
          if (i == 0) {
            const obj = {
              SNO: j + 1,

              ProductName: this.FilteredReportList[j].ProductName,
              ProductType: this.FilteredReportList[j].ProductType,
              ProductCategory: this.FilteredReportList[j].ProductCategory,
              ProductFirstSubCategory: this.FilteredReportList[j].ProductFirstSubCategory,
              ProductSecSubCategory: this.FilteredReportList[j].ProductSecSubCategory,
              Unit: this.FilteredReportList[j].Unit,
              ...this.FilteredReportList[j].ChildList[i],
              TotalQty: this.FilteredReportList[j].TotalQty,
              TotalAmount: this.FilteredReportList[j].TotalAmount,
            };
            resultArr.push({
              ...obj

            });
          } else {
            let obj = {
              SNO: '',
              ProductName: '',
              ProductType: '',
              ProductCategory: '',
              ProductFirstSubCategory: '',
              ProductSecSubCategory: '',
              Unit: '',
              ...this.FilteredReportList[j].ChildList[i],
              TotalQty: '',
              TotalAmount: '',

            };

            resultArr.push(obj);
          }
        }
      }
    }

    this.exportfields = resultArr;

    console.log(
      'exportFieldsData here',
      this.exportfields,
      'result formed',
      resultArr
    );
  }
  GetAllSupplier() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++;
      if (this.count < 2) {
        this.GetAllSupplier()
      }
    });
  }
}
