<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Digital Weight Machine</nz-page-header-title>
      <nz-page-header-subtitle>Knitting Fabric Stock Input</nz-page-header-subtitle>
      <nz-page-header-extra>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="font-weight: bold; color: red;margin-bottom: 20px;">
      <p>NOTE: The functionality of connecting to Weight Machine only works when you open this page on Factory Intranet.
        It will not work if you are not using a computer either on Factory Wired LAN or Factory WiFi.</p>
    </div>
    <div style="font-weight: bold;margin-bottom: 20px;">
      <p>Products in below list are by default filtered to Category Name "Fabric" and First Category Name "Loopknit".</p>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <nz-list class="weight-table" nzBordered nzSize="small">
          <nz-list-header>
            <div class="headerSection">
              <p class="mb-0">Fabric Product Names</p>
            </div>
          </nz-list-header>
          <div class="list-scroll-container">
            <nz-list-item *ngFor="let item of ProductList; let i = index"
              [ngClass]="{ 'isSelected': name == item.ProductName}" (click)="setSelected(item, i)">
              <div class="weightItems">
                <p>{{ item.ProductName }}</p>
              </div>
            </nz-list-item>
          </div>
        </nz-list>
      </div>
      <div class="col-lg-6" *ngIf="SelectedRow">
        <div class="viewdetails">
          <div style="font-size: larger;font-weight: bolder;">
            <span>
              <p><b>Selected Product:</b>{{SelectedRow?.ProductName}} </p>
            </span>
          </div>
          <nz-divider nzText="Connection to Device"></nz-divider>
          <div>
            <button class="btn btn-sm btn-light-danger" (click)="captureWeight()">Capture Weight</button>
          </div>
          <div style="margin-top: 20px; font-size: larger;font-weight: bolder;" *ngIf="this.weightres.length > 0">
            <span>Weight Captured:- {{weightres[0]}}</span>
          </div>
          <div *ngIf="weightres.length > 0 && weightres[0] > 0">
            <nz-divider nzText="Save to Send Data"></nz-divider>
            <button class="btn btn-sm btn-primary" (click)="save(SelectedRow, weightres[0])">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>