import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ProductModel } from "src/PmsUIApp/Models/ProductModel";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-measurement-conversion-new",
  templateUrl: "./measurement-conversion-new.component.html",
  styleUrls: ["./measurement-conversion-new.component.css"],
})
export class MeasurementConversionNewComponent {
  ApiUrl = environment.Api_Url;
  ProductList: ProductModel[] = [];
  selectedproductId: any = 0;
  selectedProductDetails: any = {};
  totalQuantityInKg: any = 0;
  quantityInMeter: any = 0;
  disableQuantity: boolean = true;
  disableMTRQty: boolean = true;
  disableKGQty: boolean = true;
  conversionType = 'MTRtoKG';
  count: any;
  constructor(public http: HttpClient) { }

  ngOnInit() {
    this.GetAllProducts();
    this.conversionType = 'MTRtoKG';
  }
  GetAllProducts() {
    // this.isTableLoading = true;
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        if (res?.length > 0) {
          this.ProductList = res.filter(
            (pro) =>
              pro.Unit === "Kgs" &&
              pro.WidthInMeter !== null &&
              pro.WidthInMeter > 0 &&
              pro.AvgGsm !== null &&
              +pro.AvgGsm > 0
          );
        }
        // this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllProducts(); }
      }
    );
  }

  onProductChange() {
    this.selectedProductDetails = this.ProductList.find(
      (pro) => pro.ProductId === this.selectedproductId
    );
    this.switchInput();
  }
  switchInput() {
    if (this.conversionType == 'MTRtoKG' && this.selectedProductDetails.AvgGsm > 0) {
      this.disableMTRQty = false;
      this.disableKGQty = true;
    }
    else if (this.conversionType == 'KGtoMTR' && this.selectedProductDetails.AvgGsm > 0) {
      this.disableKGQty = false;
      this.disableMTRQty = true;
    }
  }

  calculateTotalInKg(event) {
    if (this.conversionType === 'MTRtoKG') {
      this.quantityInMeter = event;
      const tempTotalQuantity = (
        (this.quantityInMeter * this.selectedProductDetails.WidthInMeter * this.selectedProductDetails.AvgGsm) /
        1000
      ).toFixed(2);
      this.totalQuantityInKg = parseFloat(tempTotalQuantity);
    }
  }

  calculateTotalInMTR(event) {
    if (this.conversionType === 'KGtoMTR') {
      this.totalQuantityInKg = event;
      const tempTotalQuantity = (
        (this.totalQuantityInKg * 1000) /
        (this.selectedProductDetails.AvgGsm * this.selectedProductDetails.WidthInMeter)
      ).toFixed(2);
      this.quantityInMeter = parseFloat(tempTotalQuantity);
    }
  }
}
