import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  getToken: any;
  ApiUrl = environment.Api_Url;

  constructor(private http: HttpClient) { }

  getStorageSASToken(ContainerName: string): any {
    let url = this.ApiUrl + "storage/getstoragesastoken/" + ContainerName;
    this.getToken = this.http.get(url)
    return this.getToken
  }
}
