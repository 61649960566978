<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Customer List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your customer here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()" *ngIf="this.permission.Add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search name / email / phone"
        [(ngModel)]="searchValue"   (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" 
        />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-table nzSize="small" style="width: 100%;" [nzScroll]="{ x: '1200px',y:'515px' }" #basicTable
      [nzData]="this.CustomerList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
          <tr>
              <th nzWidth="250px" nzLeft>Customer Name</th>
              <th nzWidth="150px">Contact No.</th>
              <th nzWidth="350px">Email</th>
              <th nzWidth="400px">Address</th>
              <th nzWidth="350px">GST/TAX</th>
              <th nzWidth="150px">State</th>
              <th nzWidth="150px">Country</th>
              <th nzWidth="160px" style="text-align:center" nzRight>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let data of basicTable.data">
              <td nzLeft>{{ data.CustomerName }}</td>
              <td>{{ data.CustomerContactNumber }}</td>
              <td>{{ data.Email }}</td>

              <td>{{ data.Address }}</td>
              <td>{{ data.Gstnumber }}</td>
              <td>{{ data.State }}</td>
              <td>{{ data.Country }}</td>
              <td nzRight style="text-align:center" >
                  <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
                  <button class="btn btn-sm btn-light-danger" [disabled]="!this.permission.Delete" (click)="handleDelete(data)">Delete</button>

              </td>
          </tr>
      </tbody>
    </nz-table>


    <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
          <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
              <nz-form-item>

                  <nz-form-label [nzSpan]="8" nzRequired>Customer Name</nz-form-label>
                  <nz-form-control [nzSpan]="14" [nzErrorTip]="NameError">
                      <input nz-input formControlName="CustomerName" name="CustomerName" />
                  </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                  <nz-form-label [nzSpan]="8" nzRequired>Contact Number</nz-form-label>
                  <nz-form-control [nzSpan]="14" nzErrorTip="Enter mobile number">
                      <input nz-input formControlName="CustomerContactNumber" type="number" name="CustomerContactNumber" />
                  </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                  <nz-form-label [nzSpan]="8">Email</nz-form-label>
                  <nz-form-control pattern="emailPattern" [nzSpan]="14" nzErrorTip="Invalid Email">
                      <input nz-input formControlName="Email" name="Email" />
                  </nz-form-control>
              </nz-form-item>


              <nz-form-item>
                  <nz-form-label [nzSpan]="8" nzRequired>Address</nz-form-label>
                  <nz-form-control [nzSpan]="14" nzErrorTip="Address required">
                      <textarea nz-input formControlName="Address" name="Address required"></textarea>
                  </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                  <nz-form-label [nzSpan]="8" nzRequired>State</nz-form-label>
                  <nz-form-control [nzSpan]="14" nzErrorTip="State required">
                      <input nz-input formControlName="State" name="State" />
                  </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                  <nz-form-label [nzSpan]="8" nzRequired>Country</nz-form-label>
                  <nz-form-control [nzSpan]="14" nzErrorTip="Country required">
                      <input nz-input formControlName="Country" name="Country" />
                  </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                  <nz-form-label [nzSpan]="8" nzRequired>GST/TAX</nz-form-label>
                  <nz-form-control [nzSpan]="14">
                      <input nz-input formControlName="Gst" name="Gst" />
                  </nz-form-control>
              </nz-form-item>
              <!-- <ng-template #modalFooter>

       <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>

    </ng-template> -->
              <div class="text-center">
                  <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
              </div>

          </form>
      </ng-template>
    </nz-modal>

  </div>
</div>
