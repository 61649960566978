import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MainservicesService {
  productData: any = {}
  tokenForPost: any;
  tokenForGet: any;

  ApiUrl = environment.Api_Url;

  constructor(private http: HttpClient) { }


  get selectedProductData() {
    return this.productData
  }

  setProductData(data: any) {
    this.productData = data
  }

  getStockProductData(id: any) {

    let url = this.ApiUrl + "stock/getstockbyid/" + id;
    return this.http.get(url)
  }
  // getstockbyid/id

  // getProductData(id:any){

  //   let url = this.ApiUrl + "issueproduct/getstockforproduct/"+ id;
  //     return this.http.get(url)
  // }

  getStorageSASToken(ContainerName: string): any {
    let url = this.ApiUrl + "storage/getstoragesastoken/" + ContainerName;
    this.http.get(url).subscribe(res => {

      this.tokenForGet = res;
      return this.tokenForGet
    }, res => { this.getStorageSASToken(ContainerName) });
  }
}
