import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { productItem, ProductModel } from '../../Models/ProductModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { UserInfo } from '../../Authentication/UserInfo';
import { ProcessModel, ProcessRawMaterialModel } from '../../Models/ProcessModel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-Process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
  validateForm!: UntypedFormGroup;

  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  NewPO: ProcessModel = new ProcessModel;
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: '',
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  }
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  listOfSelectedproducts: number[] = [];
  productionRawMaterial: ProcessRawMaterialModel[] = [];
  ProcessId = 0;
  private route$: Subscription = new Subscription;
  count: number;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router) { }
  handleOk(): void {

    this.isLoading = true;

    this.Save();
  }
  GetProcess() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "process/getallprocesss";
    this.http.get<ProcessModel[]>(url).subscribe(res => {

      this.NewPO = res.filter(x => x.ProcessId == this.ProcessId)[0];
      this.productionRawMaterial = this.NewPO.ProcessRawMaterial;
      this.productionRawMaterial.forEach(x => {
        this.listOfSelectedproducts.push(x.ProductId);
      });

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetProcess(); }
    });
  }
  Save() {

    if (this.NewPO.ProcessName == '') {
      this.isLoading = false;
      this.alertService.error("Process Name Required");
      return;
    }

    if (this.productionRawMaterial.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add RawMaterial");
      return;
    }
    if (this.productionRawMaterial.filter(x => x.Price <= 0).length > 0) {
      this.isLoading = false;
      this.alertService.error("Invalid Price (should be greater than 0)");
      return;
    }
    if (this.productionRawMaterial.filter(x => x.Quantity <= 0).length > 0) {
      this.isLoading = false;
      this.alertService.error("Invalid Quantity (should be greater than 0)");
      return;
    }

    this.NewPO.ProcessRawMaterial = this.productionRawMaterial;

    this.NewPO.AddedBy = UserInfo.EmailID;


    var aurl = "process/addprocess";
    if (this.ProcessId > 0) {
      aurl = "process/updateprocess";
    }
    let url = this.ApiUrl + aurl;
    this.http.post<ProcessModel>(url, this.NewPO).subscribe({
      next: res => {
        this.alertService.success("Process Saved Successfully"); this.isLoading = false;
        this.prolist = [];
        this.router.navigate(['/home/process/list'])
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });

  }

  ngOnInit(): void {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProcessId = params["id"];
    })
    this.validateForm = this.fb.group({

    });


    this.GetAllProducts();
    if (this.ProcessId > 0)
      this.GetProcess();
  }
  get f() { return this.validateForm.controls; }

  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res;
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  GetProductsPrice(id: number) {
    let url = this.ApiUrl + "product/getproductdefaultperunitcost/" + id;
    this.http.get<ProductModel[]>(url).subscribe(res => {
      return res;
    }, res => { });
  }
  handleCancel(): void {
    this.isVisible = false;
  }


  OnProductDdlchange() {
    //this.productionRawMaterial = [];
    if (this.listOfSelectedproducts.length < this.productionRawMaterial.length) {
      let filteredpid: number[] = [];
      this.productionRawMaterial.forEach(x => {
        filteredpid.push(x.ProductId);
      })
      let missing = filteredpid.filter(item => this.listOfSelectedproducts.indexOf(item) < 0)[0];
      var d = this.productionRawMaterial.filter(x => x.ProductId == missing)[0]
      this.productionRawMaterial = this.productionRawMaterial.filter(obj => obj !== d);
    }
    else {
      this.listOfSelectedproducts.forEach(item => {
        var checkexist = this.productionRawMaterial.filter(x => x.ProductId == item).length;
        if (checkexist == 0) {
          var product = this.ProductRawList.filter(x => x.ProductId == item)[0];
          var rawproduct = new ProcessRawMaterialModel;
          rawproduct.ProductId = item;
          rawproduct.ProductName = product.ProductName;
          rawproduct.ProductCode = product.ProductCode;
          rawproduct.Unit = product.Unit;
          rawproduct.AvgGsm = product.AvgGsm;
          var price = this.GetProductsPrice(item);

          //rawproduct.Price = parseFloat(price);
          rawproduct.Quantity = 0;

          if (this.productionRawMaterial.filter(x => x.ProductId == item).length == 0) {
            this.productionRawMaterial.push(rawproduct);

          }

        }

      })
    }
  }

  calculateProcessTotal() {
    this.NewPO.WeightGsm = 0;
    this.productionRawMaterial.forEach((element) => {
      this.NewPO.WeightGsm += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });
  }
}
