import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd/modal';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-product-sec-sub-category',
  templateUrl: './product-sec-sub-category.component.html',
  styleUrls: ['./product-sec-sub-category.component.css']
})
export class ProductSecSubCategoryComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductSecSubCategoryListOriginal: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  FilteredFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  NewProductSecSubCategory: ProductSecSubCategoryModel = new ProductSecSubCategoryModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New 2nd SubCategory";
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,

    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Product2ndSubCategory, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Product2ndSubCategory, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.Product2ndSubCategory, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Product2ndSubCategory, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      ProductSecSubCategoryName: [null, [Validators.required, Validators.minLength(3)]],
      ProductFirstSubCategoryId: [null, [Validators.required]],
      ProductCategoryId: [null, [Validators.required]],
      ProductSecSubCategoryDesc: [null, [Validators.required]]

    });
    this.GetAllProductSecSubCategory();
    this.GetAllProductCategory();
    this.GetAllFirstCategory(0);
  }
  get f() { return this.validateForm.controls; }


  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  GetAllProductSecSubCategory() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res;
      this.ProductSecSubCategoryListOriginal = res;
      this.isTableLoading = false;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProductSecSubCategory();
      }
    });
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProductCategory();
      }
    });
  }
  GetFilteredFC(data: any) {
    this.FilteredFirstSubCategoryList = this.ProductFirstSubCategoryList.filter(x => x.ProductCategoryId == data)
  }
  GetAllFirstCategory(data: any) {



    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res;//.filter(x => x.ProductCategoryId==data);


    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllFirstCategory(0);
      }
    });
  }
  SaveProductSecSubCategory() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewProductSecSubCategory.ProductSecSubCategory = this.f['ProductSecSubCategoryName'].value;
    this.NewProductSecSubCategory.ProductFirstSubCategoryId = this.f['ProductFirstSubCategoryId'].value;
    this.NewProductSecSubCategory.ProductSecSubCategoryDesc = this.f['ProductSecSubCategoryDesc'].value;
    this.NewProductSecSubCategory.ProductSecSubCategoryAddedBy = UserInfo.EmailID;
    // if(!this.Validate(this.NewProductSecSubCategory))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "productcategory/addupdateProductSecSubCategory";
    this.http.post<ProductSecSubCategoryModel>(url, this.NewProductSecSubCategory).subscribe({

      next: res => { this.alertService.success("ProductSecSubCategory Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllProductSecSubCategory(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: ProductSecSubCategoryModel) {
    this.PopUpTitle = "Modify 2nd SubCategory Details";


    this.validateForm.setValue(
      {
        ProductSecSubCategoryName: data.ProductSecSubCategory,
        ProductFirstSubCategoryId: data.ProductFirstSubCategoryId,
        ProductCategoryId: this.ProductFirstSubCategoryList.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId)[0].ProductCategoryId,
        ProductSecSubCategoryDesc: data.ProductSecSubCategoryDesc,

      }
    )

    this.NewProductSecSubCategory = new ProductSecSubCategoryModel();
    this.NewProductSecSubCategory.ProductSecSubCategoryId = data.ProductSecSubCategoryId;
    //let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    //this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
    //  this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.pro);
    //  this.NewProduct.ProductFirstSubCategoryId = data.ProductFirstSubCategoryId;

    //}, res => { });
    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New 2nd SubCategory";
    this.validateForm.reset();
    this.NewProductSecSubCategory.ProductSecSubCategory = "";
    this.NewProductSecSubCategory.ProductSecSubCategoryId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveProductSecSubCategory();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteProductSecSubCategory() {
    let url = this.ApiUrl + "productcategory/deleteproductsecsubcategory";
    this.http.post<any>(url, this.NewProductSecSubCategory).subscribe({

      next: res => {
        this.alertService.success(res);
        this.GetAllProductSecSubCategory();
      },

      error: res => {
        console.log(res)
        if (res.error.StatusCode == HttpStatusCode.BadRequest) {
          this.alertService.warning(res.error.ResponseBody)
        }
        else {
          this.alertService.error(res.error.ResponseBody);
          this.isLoading = false;
        }
      }

    });

  }
  handleDelete(data: ProductSecSubCategoryModel) {
    this.NewProductSecSubCategory = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Product Second Sub Category?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteProductSecSubCategory(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  enableEditDeleteBtn(data: any) {
    if (this.permission.Delete && data == "Delete") {
      return false;
    }
    if (this.permission.Edit && data == "Edit") {
      return false;
    }
    else {
      return true;
    }
  }

  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllProductSecSubCategory();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }


  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {
    var res = this.ProductSecSubCategoryListOriginal;
    var res2 = this.ProductSecSubCategoryListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ProductSecSubCategoryModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.ProductSecSubCategoryAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.ProductSecSubCategoryAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ProductSecSubCategoryList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ProductSecSubCategoryList = res.filter(
        (item: ProductSecSubCategoryModel) =>
          item?.ProductCategory?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductFirstSubCategory?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductSecSubCategory?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductSecSubCategoryDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductSecSubCategoryAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }
}
