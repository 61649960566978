<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Consumption</nz-page-header-title>

            <nz-page-header-extra>

                <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
            </nz-page-header-extra>

        </nz-page-header>
        <nz-divider></nz-divider>
        <div class="mb-10 fv-row">

            <div *ngIf="this.Type=='Production'" style="padding: 8px;float:right">
                <p><nz-tag nzColor="#f50">RED</nz-tag>- Consumption Quantity is above allowed limit</p>
                <p><nz-tag nzColor="#ffc700">Yellow</nz-tag>- Consumption Quantity is more than Required Quantity</p>
                <p>Mixing Store Name: <b>{{MixingStoreName}}</b></p>
                <p></p>
            </div>
            <div class="col-lg-3">
                <label class="required"><b>Select Type</b></label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="Type"
                    (ngModelChange)="OnTypeDdlchange()" nzAllowClear nzPlaceHolder="Choose"
                    [ngModelOptions]="{standalone: true}">
                    <nz-option nzValue="" nzLabel="Select"></nz-option>
                    <nz-option nzValue="Production" nzLabel="Workplan"></nz-option>
                    <nz-option *ngIf="IsAuth" nzValue="Store" nzLabel="Store"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-3" *ngIf="this.Type=='Production'">


                <label class="required"><b>Select Workplan</b></label>

                <nz-select class="form-select" [disabled]="this.WorkPlanId > 0" nzShowSearch name="WorkPlanNo"
                    [(ngModel)]="SelectedWorkPlan" nzSize="default" [ngModelOptions]="{standalone: true}"
                    nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanChange()">
                    <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-3" *ngIf="SelectedWorkPlanOrder.length>0 && this.Type=='Production'">
                <label class="required"><b>Select Order</b></label>
                <nz-select class="form-select" [disabled]="this.saleOrderbtnDisabled" nzShowSearch
                    name="SelectedWorkPlanOrder" [(ngModel)]="SelectedOrderNo" [ngModelOptions]="{standalone: true}"
                    nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanOrderChange()">
                    <nz-option *ngFor="let s of SelectedWorkPlanOrder;" [nzValue]="s.SaleOrder.SaleOrderId"
                        [nzLabel]="s.SaleOrder.SaleOrderNumber"></nz-option>
                </nz-select>
                <button nz-button style="margin-left: 20px;float: right;" [hidden]="this.saleOrderbtnHidden"
                    nzSize="small" (click)="resetOrdersBtn()">Reset</button>
            </div>
            <div class="col-lg-3" *ngIf="this.Type=='Store'">
                <label class=" form-label">Select Store</label>
                <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
                    [(ngModel)]="SelectedStoreID" (ngModelChange)="GetStorewiseStock($event)">
                    <nz-option *ngFor="let s of this.AdminStoreList;" [nzValue]="s.StoreId"
                        [nzLabel]="s.StoreName"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-3" *ngIf="SelectedOrderNo > 0 && this.Type=='Production'">
                <div style="margin-top:30px">
                    <button nz-button nzType="primary" (click)="openAddModel()">Add Product</button>
                    <button nz-button style="margin-left: 20px;" *ngIf="this.SaleOrderId > 0" nzType="primary"
                        (click)="backToPendingOrderbtn()">Back to Pending Orders</button>
                </div>
            </div>

        </div>
        <div *ngIf="this.Type=='Production' && this.ConsumedStockList.length > 0">
            <nz-divider nzText="Consumed Materials"></nz-divider>
            <div style="padding: 8px;float:right">
                <button nz-button nzSize="small" style="margin-left: 8px;" (click)="exportConsumedList()"><i nz-icon
                        nzType="export" nzTheme="outline"></i> Export</button>
            </div>
            <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;"
                #basicTable [nzData]="[{}]" [nzLoading]="isTableLoading" nzBordered>
                <thead>
                    <tr>
                        <th nzWidth="70px">S.No.</th>
                        <th nzWidth="80px">Type</th>
                        <th nzWidth="110px">Material Category</th>
                        <th nzWidth="150px" nzLeft>Name</th>
                        <th nzWidth="150px">Batch </th>
                        <th nzWidth="150px">Store</th>
                        <th nzWidth="150px">Rack</th>
                        <!-- <th nzWidth="130px">Calculated Actual QTY </th> -->
                        <th nzWidth="120px">Consumed QTY</th>
                        <th nzWidth="120px">Consumed SC QTY</th>
                        <th nzWidth="90px">Damaged</th>
                        <th nzWidth="100px">Consumed By</th>
                        <th nzWidth="120px">Consumed Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of this.ConsumedStockList">
                        <td>{{data.SerialNo}}</td>
                        <td>{{ data.ProductType }}</td>
                        <td>{{ data.MaterialCategory }}</td>
                        <td nzLeft>{{ data.ProductName }}</td>
                        <td>{{ data.Batch }}</td>
                        <td>{{ data.StoreName }}</td>
                        <td>{{ data.RackName }}</td>
                        <!-- <td>{{ data.ActualQuantity }}</td> -->
                        <td>{{data.ConsumedQty}} {{data.Unit}}</td>
                        <td>{{data.ConsumedScQty}} {{data.Unit}}</td>
                        <td>{{data.Damaged}}</td>
                        <td>{{data.ConsumedBy}}</td>
                        <td>{{data.ConsumedDate | DatetimeConverter}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float: left;font-weight: bold;" *ngIf="this.Type == 'Production'">
            <p>Total Products to consume: {{this.TotalCountofProductToConsume}} </p>
            <p>Quantity Filled for Products - Completed: {{this.TotalProductsQuantityFilled}} and Remaining:
                {{this.TotalRemainingProductToFillQuantity}}</p>
            <div nz-col [nzSpan]="17" *ngIf="this.StoreWiseStock.length > 0">
                <label>Select to Filter Materials with Batch No. of maximum Qty</label>
                <nz-radio-group style="margin-top: 7px;" [(ngModel)]="IsMaterialFiltered"
                    (ngModelChange)="FilterConsumedProduct()">
                    <label nz-radio nzValue=true>Show Filtered</label>
                    <label nz-radio nzValue=false>Show All</label>
                </nz-radio-group>
            </div>
        </div>
        <div style="padding: 8px;float:right">
            <input type="text" style="width: 250px;margin-right: 8px;" nz-input
                placeholder="Search ProductType / Name / Quantity" [(ngModel)]="searchValue"
                (ngModelChange)="ValidateText()" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()"
                style="margin-right: 8px;">Search</button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button>
            <button nz-button nzSize="small" style="margin-left: 8px;" (click)="export()"><i nz-icon nzType="export"
                    nzTheme="outline"></i> Export</button>
        </div>

        <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1500px',y:'515px' }" style="width: 100%;"
            #basicTable [nzData]="[{}]" [nzLoading]="isTableLoading" nzBordered [nzShowTotal]="totalTemplate">
            <thead>
                <tr>
                    <th nzWidth="100px">
                        Type
                        <span nz-icon nzType="caret-up" nzTheme="outline"
                            (click)="onUp('ProductType',basicTable.data)"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"
                            (click)="onDown('ProductType',basicTable.data)"></span>
                    </th>
                    <th nzWidth="130px" *ngIf="this.Type=='Production'">
                        Material Category
                        <span nz-icon nzType="caret-up" nzTheme="outline"
                            (click)="onUp('ProductType',basicTable.data)"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"
                            (click)="onDown('ProductType',basicTable.data)"></span>
                    </th>
                    <th nzWidth="150px" nzLeft>
                        Name
                        <span nz-icon nzType="caret-up" nzTheme="outline"
                            (click)="onUp('ProductName',basicTable.data)"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"
                            (click)="onDown('ProductName',basicTable.data)"></span>

                    </th>
                    <th nzWidth="150px">Batch </th>
                    <th nzWidth="150px">Barcode </th>
                    <th nzWidth="150px" *ngIf="this.Type=='Store'">
                        Rack

                        <span nz-icon nzType="caret-up" nzTheme="outline"
                            (click)="onUp('RackName',basicTable.data)"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"
                            (click)="onDown('RackName',basicTable.data)"></span>
                    </th>
                    <!-- <th *ngIf="this.Type=='Production'" nzWidth="130px">Required QTY </th> -->
                    <th *ngIf="this.Type=='Production'" nzWidth="130px">Actual QTY </th>
                    <th nzWidth="130px">
                        Available QTY
                        <span nz-icon nzType="caret-up" nzTheme="outline"
                            (click)="onUp('Quantity',basicTable.data)"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"
                            (click)="onDown('Quantity',basicTable.data)"></span>
                    </th>
                    <th nzWidth="150px">
                        Consumption QTY

                    </th>
                    <th nzWidth="120px">
                        Is Damaged

                    </th>
                    <th nzWidth="130px">
                        Remaining QTY
                        <!-- <span nz-icon nzType="caret-up" nzTheme="outline"(click)="onUp('RemainingQuantity',basicTable.data)"></span>
                    <span nz-icon nzType="caret-down" nzTheme="outline" (click)="onDown('RemainingQuantity',basicTable.data)"></span> -->
                    </th>
                    <th nzWidth="350px" *ngIf="this.Type=='Store'">
                        Purpose
                    </th>
                    <th nzWidth="220px" *ngIf="this.Type=='Production'">
                        Consumption SC QTY
                        <span nz-icon nzType="caret-up" nzTheme="outline"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                    </th>
                    <!-- <th nzWidth="180px" *ngIf="this.Type=='Production'">Remaining SC QTY
                  <span nz-icon nzType="caret-up" nzTheme="outline"></span>
                 <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                </th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of this.StoreWiseStock">
                    <td>{{ data.ProductType }}</td>
                    <td *ngIf="this.Type=='Production'">{{ data.MixingName }}</td>
                    <td nzLeft>{{ data.ProductName }}</td>
                    <td>{{ data.Batch }}</td>
                    <td>{{ data.BarCode }}</td>
                    <!--<td nzLeft>{{ data.ProductCode }}</td>-->
                    <td *ngIf="this.Type=='Store'">{{ data.RackName }}</td>

                    <!-- <td *ngIf="this.Type=='Production'">{{ data.SaleOrderQuantity }}</td> -->
                    <td *ngIf="this.Type=='Production'">{{ data.ActualQuantity }}</td>
                    <td>{{ data.Quantity }} / {{data.Unit}}</td>
                    <td *ngIf="this.Type=='Store'">
                        <input nz-input [(ngModel)]="data.ConsumptionQuantity" type="number"
                            (ngModelChange)="CheckQty(data)" [style]="data.QtyStyle" />
                    </td>
                    <td *ngIf="this.Type=='Production'">
                        <input nz-input [(ngModel)]="data.ConsumptionQuantity" type="number"
                            (ngModelChange)="CheckQtyProduction(data)" [style]="data.QtyStyle" />
                    </td>
                    <td>
                        <label style="margin:10px" nz-checkbox [(ngModel)]="data.IsDamaged"
                            (ngModelChange)="CheckQtyProduction(data)"></label>
                    </td>
                    <td>{{ data.RemainingQuantity }}</td>
                    <td *ngIf="this.Type=='Store'">
                        <input nz-input [(ngModel)]="data.Remark" />
                    </td>

                    <!--<td *ngIf="this.Type=='Production'"></td>-->
                    <td *ngIf="this.Type=='Production'">
                        <input nz-input type="number" [(ngModel)]="data.SCQuantity"
                            (ngModelChange)="CheckQtyProduction(data)" />
                    </td>
                </tr>
                <!-- <td *ngIf="this.Type=='Production'">
                  {{ data.SCRemainingQuantity }}
                </td> -->

            </tbody>
        </nz-table>
        <nz-divider *ngIf="this.Type=='Production' && this.OutofStockList.length > 0"></nz-divider>
        <div *ngIf="this.Type=='Production' && this.OutofStockList.length > 0">
            <nz-divider nzText="Out of Stock Materials"></nz-divider>
            <!-- <div style="padding: 8px;float:right">
                <button nz-button nzSize="small" style="margin-left: 8px;" (click)="exportConsumedList()"><i nz-icon
                        nzType="export" nzTheme="outline"></i> Export</button>
            </div> -->
            <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;"
                #basicTable [nzData]="[{}]" [nzLoading]="isTableLoading" nzBordered>
                <thead>
                    <tr>
                        <th nzWidth="100px">
                            Type
                        </th>
                        <th nzWidth="130px">
                            Material Category
                        </th>
                        <th nzWidth="150px" nzLeft>
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of this.OutofStockList">
                        <td>{{ data.ProductType }}</td>
                        <td>{{ data.MixingName }}</td>
                        <td nzLeft>{{ data.ProductName }}</td>
                    </tr>
                </tbody>
            </nz-table>
            <div class="text-center" style="padding: 8px;float:left;color: red;font-weight: bolder;">
                <p> First get required stock transfered into the mixing store mentioned above. Only after that
                    consumption can be completed.</p>
            </div>
        </div>
        <nz-divider></nz-divider>
        <div class="text-center" *ngIf="this.Type == 'Production'">
            <p style="font-weight: bolder">Save Button will be enabled, once the quantity is filled for all the required
                products same as actual
                quantity.</p>
        </div>
        <div class="text-center">
            <button nz-button nzType="primary" [disabled]="this.DisableSaveBtn()" [nzLoading]="isLoading"
                (click)="validate()">Save</button>
        </div>
        <nz-divider></nz-divider>
    </div>
</div>




<nz-modal [nzWidth]="1000" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsPopUpOpen" [nzTitle]="modalTitle"
    [nzContent]="modalContent" [nzFooter]="modalfooter" (nzOnCancel)="closeAddModel()">
    <ng-template #modalTitle>Add Raw material</ng-template>

    <ng-template #modalContent>
        <div class="row gx-10 mb-5">
            <div class="col-lg-3">

                <label class="required form-label">Materials Category</label>
                <!-- <input *ngIf="newCategory" nz-input [(ngModel)]="SelectedMaterialCategory" nzSize="default"
                    class="form-control " [ngModelOptions]="{standalone: true}" /> -->
                <nz-select nzShowSearch class="form-select " name="SelectedMaterialCategory"
                    [(ngModel)]="SelectedMaterialCategory" [ngModelOptions]="{standalone: true}" nzAllowClear
                    nzPlaceHolder="Choose" (ngModelChange)="GetAllStoreNewCategory($event)">
                    <nz-option *ngFor="let s of this.NewRawMaterialCategoryList;" [nzValue]="s"
                        [nzLabel]="s"></nz-option>
                    <nz-option nzValue="Packaging" nzLabel="Packaging"></nz-option>
                    <nz-option nzValue="Other" nzLabel="Other"></nz-option>
                </nz-select>
                <!-- <label nz-checkbox [(ngModel)]="newCategory" (ngModelChange)="GetAllStoreNewCategory()">Click to add New
                    Category</label> -->
            </div>
            <div class="col-lg-3" *ngIf="newCategory && SelectedMaterialCategory == 'Other'">
                <label class=" form-label">Other Category Name</label>
                <input *ngIf="newCategory" nz-input [(ngModel)]="OtherMaterialCategory" nzSize="default"
                    class="form-control " [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="col-lg-3" *ngIf="newCategory">
                <label class=" form-label">Select Store</label>
                <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
                    [(ngModel)]="NewCategoryStoreId" (ngModelChange)="onSelectedStoreChange($event)">
                    <nz-option *ngFor="let s of this.NewCategoryStoreList;" [nzValue]="s.StoreId"
                        [nzLabel]="s.StoreName"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-3">
                <label class="required form-label">Rack</label>
                <nz-select nzShowSearch [disabled]="IsRack" class="form-select " name="ProductId"
                    [(ngModel)]="SelectedRack" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose"
                    (ngModelChange)="OnRackDdlchange()">
                    <nz-option *ngFor="let s of this.RawDetailsRackOriginal;" [nzValue]="s.RackId"
                        [nzLabel]="s.RackName"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-2" *ngIf="newCategory">
                <label class=" form-label">Product Type</label>
                <nz-select class="form-select mb-2" [disabled]="IsProductType" nzShowSearch name="StoreId"
                    nzSize="default" nzPlaceHolder="Choose" [(ngModel)]="NewCatSelectedProductType"
                    (ngModelChange)="OnNewCatProductTypeChange()">
                    <nz-option *ngFor="let item of this.NewCatProductTypeList;" [nzValue]="item"
                        [nzLabel]="item"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-3">
                <label class="required form-label">Raw Materials Category</label>
                <nz-select [disabled]="IsCategory" nzShowSearch class="form-select " name="SelectedRawMaterialCategory"
                    [(ngModel)]="SelectedRawMaterialCategory" [ngModelOptions]="{standalone: true}" nzAllowClear
                    nzPlaceHolder="Choose" (ngModelChange)="OnCategoryDdlchange()">
                    <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                        [nzLabel]="s.ProductCategory"></nz-option>
                </nz-select>
            </div>

            <div class="col-lg-5">
                <label class="required form-label">Product Name</label>
                <nz-select [disabled]="IsProduct" nzShowSearch class="form-select " name="ProductId"
                    [(ngModel)]="selectedproductId" [ngModelOptions]="{standalone: true}" nzAllowClear
                    nzPlaceHolder="Choose" (ngModelChange)="OnProductDdlchange()">
                    <nz-option *ngFor="let s of this.ProductList;" [nzValue]="s.ProductId"
                        [nzLabel]="s.ProductName"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-2">
                <label class="form-label">Unit</label>
                <label nzSize="default" class="form-control " style="padding:5px">{{SelectedUnit}}</label>
            </div>
            <!--<div class="col-lg-2">
            <label class="required form-label">Required QTY</label>
            <input type="text" [disabled]="IsRequiredQuantity" name="ReqQTY" nz-input  style="background: #fff;color: #000;" [(ngModel)]="ReqQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
            </div>-->
            <div class="col-lg-4">
                <label class="required form-label">Supplier</label>
                <nz-select [disabled]="IsSupplier" class="form-select " name="SupplierList"
                    [(ngModel)]="SelectedSupplier" [ngModelOptions]="{standalone: true}" nzAllowClear
                    nzPlaceHolder="Choose" (ngModelChange)="OnSupplierDdlchange()">
                    <nz-option *ngFor="let s of this.FilteredSupplierList;" [nzValue]="s.SupplierName"
                        [nzLabel]="s.SupplierName"></nz-option>
                </nz-select>
            </div>

            <div class="col-lg-3">
                <label class="required form-label">Batch </label>
                <nz-select [disabled]="IsBatch" nzShowSearch class="form-select " name="BatchList"
                    [(ngModel)]="SelectedBatch" [ngModelOptions]="{standalone: true}" nzAllowClear
                    nzPlaceHolder="Choose" (ngModelChange)="OnBatchDdlchange()">
                    <nz-option *ngFor="let s of this.BatchList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-2">
                <label class="required form-label">Barcode</label>
                <nz-select nzShowSearch [disabled]="IsBarcode" class="form-select " name="ProductId"
                    [(ngModel)]="SelectedBarcode" [ngModelOptions]="{standalone: true}" nzAllowClear
                    nzPlaceHolder="Choose" (ngModelChange)="OnBarCodeDdlchange()">
                    <nz-option *ngFor="let s of this.BarcodeList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                </nz-select>
            </div>

            <div class="col-lg-3">
                <label class="required form-label">Available Stock QTY</label>
                <input type="text" name="QTY" nz-input [disabled]="true" style="background: #fff;color: #000;"
                    [(ngModel)]="StockQTY" nzSize="default" class="form-control "
                    [ngModelOptions]="{standalone: true}" />
            </div>
            <!-- <div class="col-lg-2">
                <label class="required form-label">QTY</label>
                <input type="number" [disabled]="IsQuantity" name="QTY" nz-input [(ngModel)]="SelectedQTY" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="col-lg-2">
                <label class="required form-label">Price Per Unit</label>
                <input type="number" name="QTY" nz-input [(ngModel)]="PricePerUnit" nzSize="default" class="form-control " [ngModelOptions]="{standalone: true}" />
            </div> -->
        </div>
    </ng-template>
    <ng-template #modalfooter>
        <div class="text-center">
            <button nz-button nzType="primary" (click)="addRawMaterial()">Add</button>
        </div>
    </ng-template>
</nz-modal>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>