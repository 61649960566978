import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { AlertMessageService } from '../../../Services/AlertMessageService';

import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { PurchaseOrderModel } from '../../../Models/PurchaseOrderModel';
import { CompanyInfo } from '../../../Authentication/UserInfo';
import { SupplierModel } from '../../../Models/SupplierModel';
import { ProformaInvoiceModel } from '../../../Models/ProformaModel';
import { BankMasterModel } from '../../../Models/BankModel';

@Component({
  selector: 'app-proformaPrint',
  templateUrl: './proformaPrint.component.html',
  styleUrls: ['./proformaPrint.component.css'],
})
export class proformaPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  ProformaInvoiceList: ProformaInvoiceModel[] = [];
  ProformaInvoice!: ProformaInvoiceModel;
  NewSupplier: SupplierModel = new SupplierModel();
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Production';
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription();
  BankList: BankMasterModel[] = [];
  Bank: BankMasterModel = new BankMasterModel;
  Poid = 0;
  TotalWord = '';
  GTotal = '';
  Company = {
    CompanyName: '',
    Contact: '',
    Email: '',
    Address: '',
    GST: '',
  };
  count: number;

  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.GetAllBank()
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.Poid = params['id'];
    });
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.Contact = CompanyInfo.Contact;
    this.Company.Email = CompanyInfo.Email;
    this.Company.Address = CompanyInfo.Address.replace('\n', '<br>');
    this.Company.GST = CompanyInfo.GST;
    // this.Company.Website = CompanyInfo.Website;
    this.GetAllProformaInvoice();
  }
  GetAllBank() {


    this.isTableLoading = true;
    let url = this.ApiUrl + "bank/getallbanks";
    this.http.get<BankMasterModel[]>(url).subscribe(res => {
      this.BankList = res;

      this.isTableLoading = false;

      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllBank();
      }
    });
  }
  GetAllProformaInvoice() {
    let url = this.ApiUrl + 'proformainvoice/getallproformainvoices';
    this.http.get<ProformaInvoiceModel[]>(url).subscribe(
      (res) => {
        this.ProformaInvoiceList = res;
        this.ProformaInvoice = this.ProformaInvoiceList.filter(x => x.ProformaInvoiceId == this.Poid)[0];
        var converter = require('number-to-words');
        this.ProformaInvoice.TermsCondition = this.ProformaInvoice.TermsCondition.replaceAll('\n', '<br>');
        this.TotalWord = converter.toWords(this.ProformaInvoice.TotalPrice + this.ProformaInvoice.Moqtotal + this.ProformaInvoice.Gst).toUpperCase()
        this.Bank = this.BankList.filter(x => x.BankId == this.ProformaInvoice.BankId)[0];
        this.ProformaInvoice.TotalPrice = parseFloat(this.ProformaInvoice.TotalPrice.toFixed(3))
        this.ProformaInvoice.Moqtotal = parseFloat(this.ProformaInvoice.Moqtotal.toFixed(3))
        this.ProformaInvoice.Gst = parseFloat(this.ProformaInvoice.Gst.toFixed(3))
        this.ProformaInvoice.Discount = parseFloat(this.ProformaInvoice.Discount.toFixed(3))
        var gt = (this.ProformaInvoice.TotalPrice + this.ProformaInvoice.Moqtotal + this.ProformaInvoice.Gst) - ((this.ProformaInvoice.TotalPrice + this.ProformaInvoice.Moqtotal + this.ProformaInvoice.Gst) * (this.ProformaInvoice.Discount / 100));
        this.GTotal = gt.toFixed(3);
        console.log(this.Bank)
        setTimeout(function () {
          window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
        console.log(this.ProformaInvoice)
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProformaInvoice();
        }
      }
    );
  }



}
