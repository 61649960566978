<div class="card card-flush h-xl-100" *ngIf="IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Manage Roles</nz-page-header-title>

      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small" [nzData]="['']" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable1
      nzBordered>
      <thead>
        <tr>

          <th nzWidth="50px">

          </th>

          <!--<th nzWidth="80px" style="text-align:center">Code</th>-->
          <th nzWidth="200px" style="text-align:center">Role </th>
          <th style="text-align:center">Description </th>
          <th nzWidth="220px" nzRight style="text-align:center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of UserRoleList;let i=index">

          <td nzWidth="20px">
            {{i+1}}

          </td>

          <!--<td>{{data.UserRoleCode}}</td>-->
          <td><b> {{data.UserRoleName}}</b></td>
          <td>{{data.UserRoleDescripton}}</td>
          <td nzRight style="text-align:center">
            
              <div>
                <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                  class="btn btn-sm btn-light-primary" style="line-height:0">
                  Action
                  <span nz-icon nzType="down"></span>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li style="width: 120px;" nz-menu-item (click)="OpenOperationsDrawer(data)">Manage Operations</li>
                    <li style="width: 120px;" nz-menu-item (click)="OpenEditPop(data)">Edit</li>
                    <li style="width: 120px;" nz-menu-item (click)="ShowAssignedUsersPopup(data)">View Assigned Users</li>
                  </ul>
                </nz-dropdown-menu>
              </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
<div class="card card-flush h-xl-100" *ngIf="!IsAuth">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>You are not authorized</nz-page-header-title>



    </nz-page-header>
  </div>
</div>
<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="600"
  nzTitle="Modules & Operations" (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">




        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class="form-label">Select Modules & Operations</label>

          </div>
          <div class="col-lg-12">
            <ul>
              <li *ngFor="let data of MoudleResponsibilityList;let i=index">
                <label [(ngModel)]="data.IsChecked" nz-checkbox
                  (ngModelChange)="OnParantCheckChange(data)">{{data.Module}}</label>
                <ul>
                  <li *ngFor="let d of data.Responsibilities;let i=index"> <label [(ngModel)]="d.IsChecked" nz-checkbox
                      (ngModelChange)="OnChildCheckChange(data,d)">{{d.ResponsibilityName}}</label>
                </ul>
              </li>
            </ul>

          </div>
        </div>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="Save()">Save</button>
        </div>
      </div>

    </div>
  </ng-container>
</nz-drawer>
<nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisibleRole" [nzTitle]="modalTitleRole"
  [nzContent]="modalContentRole" [nzFooter]=null (nzOnCancel)="handleCancelRole()">
  <ng-template #modalTitleRole>{{isNew==true?'Add':'Modify'}} Role</ng-template>

  <ng-template #modalContentRole>


    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">


        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class="required form-label">Role Name</label>
            <input type="text" class="form-control mb-2" value="" [(ngModel)]="UserRole.UserRoleName" />
          </div>

        </div>
        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class="form-label">Description</label>
            <textarea type="text" class="form-control mb-2" value="" rows="6"
              [(ngModel)]="UserRole.UserRoleDescripton"></textarea>
          </div>

        </div>

        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="SaveRole()">Save</button>
        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>

<nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isUserVisible" [nzTitle]="modalTitleUserRole"
  [nzContent]="modalContentAssignedRole" [nzFooter]=null (nzOnCancel)="handleCancelAssignedUsers()">
  <ng-template #modalTitleUserRole>Assigned Users List</ng-template>

  <ng-template #modalContentAssignedRole>


    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">
        <nz-table [nzData]="['']" nzBordered nzShowPagination="false">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of UserRole.AssignedUsers">
              <td>{{ user.Name }}</td>
              <td>{{ user.Email }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </ng-template>
</nz-modal>