<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">

        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Purchase Report</nz-page-header-title>
            <nz-page-header-subtitle>Purchase report</nz-page-header-subtitle>
            <nz-page-header-extra>

            </nz-page-header-extra>

        </nz-page-header>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label style="display:block">From</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.DateFrom"
                                    nzPlaceHolder="Date"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>To</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.DateTo"
                                    nzPlaceHolder="Date"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Supplier</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="request.SupplierId" name="SupplierId" nzAllowClear nzPlaceHolder="All">
                                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                                        [nzLabel]="s.SupplierName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Unit</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="request.Unit" name="Unit" nzAllowClear nzPlaceHolder="All">
                                    <nz-option *ngFor="let s of this.UnitList;" [nzValue]="s.Unit"
                                        [nzLabel]="s.Unit"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Product Type</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                                    (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                                    <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                                    <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                                    <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                <label>Category</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category"
                                    (ngModelChange)="GetAllFirstCategory($event)">
                                    <nz-option *ngFor="let s of this.ProductCategoryList;"
                                        [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>SubCategory</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear
                                    nzPlaceHolder="Category" (ngModelChange)="GetAllSecondCategory($event)">
                                    <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                                        [nzValue]="s.ProductFirstSubCategoryId"
                                        [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>2nd SubCategory</label>
                                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                    [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                                    nzPlaceHolder="SecondCategoryID"
                                    (ngModelChange)="GetSecondCategoryFilteredProduct()">
                                    <nz-option *ngFor="let s of this.ProductSecSubCategoryList;"
                                        [nzValue]="s.ProductSecSubCategoryId"
                                        [nzLabel]="s.ProductSecSubCategory"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="7">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Product Name</label>
                                <nz-select nzShowSearch class="form-select " nzSize="default"
                                    [(ngModel)]="request.ProductId" name="ProductId"
                                    (ngModelChange)="onSelectedProductChange($event)" nzAllowClear
                                    nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                                        [nzLabel]="s.ProductName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Status</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.Status"
                                    nzPlaceHolder="Choose" nzShowSearch nzAllowClear>
                                    <nz-option nzValue="New" nzLabel="New"></nz-option>
                                    <nz-option nzValue="Active" nzLabel="Active"></nz-option>
                                    <nz-option nzValue="Complete" nzLabel="Complete"></nz-option>
                                    <nz-option nzValue="Cancelled" nzLabel="Cancelled"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Action By</label>
                                <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                                    [(ngModel)]="request.ActionBy" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email"
                                        [nzLabel]="s.Name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetPurchaseReport()">
                            Search
                        </button>
                    </div>
                </div>
                <div nz-col [nzSpan]="5">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <label style="display:block;margin-bottom:5px;">More Details</label>
                            <nz-radio-group [(ngModel)]="backDatePO">
                                <label nz-radio nzValue="false">Hide</label>
                                <label nz-radio nzValue="true">Show</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
        <div style="padding: 8px;float:right">
            <!--<nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
        <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
        <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue"
               (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
        <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>-->
            <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
                Export</button>
            <button nz-button nzSize="small" styleSheetFile="assets/css/PurchaseReport.css"
                printSectionId="print-section" ngxPrint [useExistingCss]="true"
                [printStyle]="{ '@page': { size: 'landscape !important' } }" style="margin-left:5px"><i
                    style="top: 2px;right: 37px;position: absolute;" nz-icon nzType="printer"
                    nzTheme="outline"></i>Print</button>
        </div>
        <nz-divider></nz-divider>
        <div style="max-width:100%; overflow:scroll">
            <nz-table id="print-section" nzSize="small" nzShowPagination="false"
                style="width: 100%;page-break-inside: auto;" [nzData]="this.PurchaseOrderList"
                [nzLoading]="isTableLoading" nzBordered>
                <thead>
                    <tr style="text-align: left;page-break-inside:avoid; page-break-after:auto;">
                        <th nzLeft nzWidth="60px">SNo.</th>
                        <th nzWidth="130px" *ngIf="backDatePO == 'true'">PO Number</th>
                        <th nzWidth="130px">PO Status</th>
                        <th nzLeft nzWidth="200px">Supplier Name</th>
                        <th nzWidth="150px">Item Description</th>
                        <th nzWidth="75px" *ngIf="backDatePO == 'true'">Order QTY</th>
                        <th nzWidth="110px" *ngIf="backDatePO == 'true'">Received QTY</th>
                        <th nzWidth="110px" *ngIf="backDatePO == 'true'">Received By</th>
                        <th nzWidth="100px">Price/Unit</th>
                        <th nzWidth="120px">Delivery Terms</th>
                        <th nzWidth="120px">Payment Terms</th>
                        <th nzWidth="130px" *ngIf="backDatePO == 'false'">PO Date</th>
                        <th nzWidth="110px" *ngIf="backDatePO == 'true'">PO Date</th>
                        <th nzWidth="110px" *ngIf="backDatePO == 'true'">Approved By</th>
                        <th nzWidth="110px" *ngIf="backDatePO == 'true'">Approved Date</th>
                        <th nzWidth="175px" *ngIf="backDatePO == 'true'">Added Date Time</th>
                        <th nzWidth="140px">Reference</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of PurchaseOrderList;let i=index"
                        style="page-break-inside:avoid; page-break-after:auto">
                        <td nzWidth="60px" nzLeft style="vertical-align: top;">{{i+1}}</td>
                        <td nzWidth="130px" nzLeft
                            *ngIf="backDatePO == 'true' && (data.Status != 'Active' && data.Status != 'Complete')"></td>
                        <td nzWidth="130px" nzLeft style="vertical-align: top;"
                            *ngIf="backDatePO == 'true' && (data.Status == 'Active' || data.Status == 'Complete')">{{
                            data.Ponumber }}</td>
                        <td nzWidth="130px" nzLeft style="vertical-align: top;"><a class="btn-link"
                                (click)="ShowPODetails(data)">{{
                                data.Status }}</a></td>
                        <td nzWidth="200px" nzLeft style="vertical-align: top;">{{ data.SupplierName }}</td>
                        <td nzWidth="215px" style="vertical-align: top; padding: 0; border-bottom: 0px solid #eee"
                            [colSpan]="backDatePO == 'false' ? 2 : 5">

                            <nz-table style="width: 100%" nzSize="small" [nzData]="data.PurchaseOrderProduct"
                                nzShowPagination="false" nzBordered>
                <tbody>
                    <tr *ngFor="let d of data.PurchaseOrderProduct"
                        style="page-break-inside:avoid; page-break-after:auto">
                        <td style="border-bottom: 1px solid #daa659;width:150px" nzBreakWord>
                            {{ d.ProductName }}
                        </td>
                        <td style="border-bottom: 1px solid #eee;width:75px" *ngIf="backDatePO == 'true'">
                            {{ d.Quantity }}
                        </td>
                        <td style="border-bottom: 1px solid #eee;width:110px" *ngIf="backDatePO == 'true'"
                            [ngStyle]="{'background-color': GetQtyDiffColor(d)}">
                            {{ d.RecievedQuantity }}
                        </td>
                        <td style="border-bottom: 1px solid #eee;width:110px" *ngIf="backDatePO == 'true'"
                            title="Email: {{d.StockRecievedbyUserDetails?.Email}}"
                            [ngStyle]="{'background-color': GetQtyDiffColor(d)}">
                            {{ d.StockRecievedbyUserDetails?.Name }}
                        </td>
                        <td nzWidth="100px" style="border-bottom: 1px solid #eee; width: 100px" nzBreakWord>
                            {{ d.Rate }} / {{ d.Unit }}
                        </td>
                    </tr>
                </tbody>
            </nz-table>
            </td>
            <!--<td style="padding:0;border-bottom: 0px solid #eee" >
        <table style="width: 100%">
            <tr *ngFor="let d of data.PurchaseOrderProduct">
                <td style="padding: 8px;border-bottom: 1px solid #eee">
                    {{ d.ProductName }}
                </td>
            </tr>
        </table>
    </td>
    <td style="padding:0;border-bottom: 0px solid #eee">
        <table style="width: 100%">
            <tr *ngFor="let d of data.PurchaseOrderProduct">
                <td style="padding: 8px;border-bottom: 1px solid #eee">
                    {{ d.Unit }}
                </td>
            </tr>
        </table>
    </td>
    <td style="padding:0;border-bottom: 0px solid #eee">
        <table style="width: 100%">
            <tr *ngFor="let d of data.PurchaseOrderProduct">
                <td style="padding: 8px;border-bottom: 1px solid #eee">
                    {{ d.Rate }}
                </td>
            </tr>
        </table>
    </td>-->


            <td style="vertical-align: top;">{{ data.DeliveryTerm }}</td>
            <td style="vertical-align: top;">{{ data.PaymentTerm }}</td>
            <td style="vertical-align: top;" *ngIf="backDatePO == 'false'">{{ data.PocreationDate | date: 'dd-MMM-yyyy'
                }}</td>
            <td style="vertical-align: top;" [ngStyle]="{'background-color': GetBGColor(data)}"
                *ngIf="backDatePO == 'true'">{{ data.PocreationDate | date: 'dd-MMM-yyyy' }}</td>
            <td style="vertical-align: top;" [ngStyle]="{'background-color': GetBGColor(data)}"
                *ngIf="backDatePO == 'true'">{{ data.ApprovedBy?.Name }}</td>
            <td style="vertical-align: top;" [ngStyle]="{'background-color': GetBGColor(data)}"
                *ngIf="backDatePO == 'true'">{{ data.ApprovedDate | DatetimeConverter }}</td>
            <td style="vertical-align: top;" [ngStyle]="{'background-color': GetBGColor(data)}"
                *ngIf="backDatePO == 'true'">{{ data.AddedDate | DatetimeConverter }}</td>
            <td style="vertical-align: top;">{{ data.Reference??"" }}</td>


            </tr>
            </tbody>
            </nz-table>

        </div>

        
    </div>
</div>

<app-PoDrawer></app-PoDrawer>
<app-PoTimeline></app-PoTimeline>