<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Factory Workers List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your factory workers here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" *ngIf="this.permission.Add" (click)="showModal()">
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>

    <nz-table nzSize="small" [nzPageSize]="10" style="width: 100%" #basicTable [nzData]="this.FactoryWorkersList"
      [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger [nzPageSizeOptions]=[10,50,100,200,500] [nzShowTotal]="totalTemplate"
      nzShowQuickJumper="true" nzResponsive=true>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Name</th>
          <th>Short Name</th>
          <th>Work Shift</th>
          <th>Designation</th>
          <th>Department</th>
          <th nzWidth="150px">Added Date</th>
          <th>Added By</th>

          <th nzWidth="200px" nzRight style="text-align: center" *ngIf="permission.Add || permission.Delete">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.SerialNo }}</td>
          <td>{{ data.Name }}</td>
          <td>{{ data.ShortName }}</td>
          <td>{{ data.WorkShift }}</td>
          <td>{{ data.DesignationName }}</td>
          <td>{{ data.DepartmentName }}</td>
          <td>{{ data.AddedDate | DatetimeConverter}}</td>
          <td>{{ data.AddedBy }}</td>

          <td nzRight style="text-align: center" *ngIf="CheckUserAddPermission(data.AddedBy)">
            <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">
              Edit
            </button>
            &nbsp;
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{ PopUpTitle }}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="nameError">
              <input nz-input formControlName="Name" name="Name" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Short Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="shortNameError">
              <input nz-input formControlName="ShortName" name="ShortName" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Select WorkShift</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Designation">
              <nz-select class="form-select mb-2" nzShowSearch name="WorkShift" nzSize="default" nzPlaceHolder="Choose"
                formControlName="WorkShift">
                <nz-option nzValue="Day" nzLabel="Day"></nz-option>
                <nz-option nzValue="Night" nzLabel="Night"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Select Designation</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Designation">
              <nz-select class="form-select mb-2" nzShowSearch name="DesignationId" nzSize="default"
                nzPlaceHolder="Choose" formControlName="DesignationId">
                <nz-option *ngFor="let option of DesginationList" [nzValue]="option.DesignationId"
                  [nzLabel]="option.Name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Select Department</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Department">
              <nz-select class="form-select mb-2" nzShowSearch name="DepartmentId" nzSize="default"
                nzPlaceHolder="Choose" formControlName="DepartmentId">
                <nz-option *ngFor="let option of DepartmentList" [nzValue]="option.DeptId"
                  [nzLabel]="option.DeptName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">
              Save Factory Worker
            </button>
          </div>
        </form>
      </ng-template>
    </nz-modal>
  </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>