<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

<nz-page-header [nzGhost]="false">
    <nz-page-header-title>Stock Allocation</nz-page-header-title>
    <nz-page-header-subtitle>Allocate your stocks here</nz-page-header-subtitle>
  </nz-page-header>
  <nz-divider></nz-divider>


  <nz-descriptions nzBordered>
    <nz-descriptions-item nzTitle="Supplier Name">{{NewStock.Invoice.SupplierName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Stock Date"> {{NewStock.StockDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Number">{{NewStock.Invoice.InvoiceNumber}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Date">{{NewStock.Invoice.InvoiceDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Total Price" [nzSpan]="2">
        {{NewStock.Invoice.InvoiceTotalPrice}}
    </nz-descriptions-item>
  </nz-descriptions>

<br><br>
<nz-table   [nzPageSize]="100" #basicTable [nzData]="this.NewStock.StockProduct">
    <thead>
      <tr>
        <th></th>
        <th>Product Type</th>
        <th>Product Name</th>
        <th>SKU</th>
        <th>Barcode</th>
        <th>Mfd Date</th>
        <th>Expiry</th>
        <th>Unit</th>
        <th>Quantity</th>
        <th>Per Unit Price</th>
        <th>Grade</th>
        <th>Accepted Qty</th>
        <th style="width: 8%;">Rejected Qty</th>
      </tr>
    </thead>
    <tbody>
        <ng-template ngFor let-data [ngForOf]="basicTable.data">
            <tr>
              <td [(nzExpand)]="data.ExpandRecord"></td>
              <td>{{ data.ProductType }}</td>
              <td>{{ data.ProductName }}</td>
              <td>{{ data.Sku }}</td>
              <td>{{ data.Barcode }}</td>
              <td>{{ data.ManufacturedDate  | date: 'dd-MMM-yyyy' }}</td>
              <td>{{ data.ExpiryDate  | date: 'dd-MMM-yyyy' }}</td>
              <td>{{data.Unit}}</td>
              <td>{{data.Quantity}}</td>
              <td>{{data.PricePerUnit}}</td>
              <td>{{data.Grade}}</td>
              <td>{{data.AcceptedQuantity}}</td>
              <td>{{data.RejectedQuantity}}</td>
            </tr>
            <tr [nzExpand]="data.ExpandRecord">
              <table style="table-layout: auto; background: white; width: 80%;" ng-reflect-list-of-col-width=",,,20%">
                <thead class="ant-table-thead ng-star-inserted">
                  <tr class="ant-table-row ng-star-inserted">
                  <th  style="background: aliceblue;" class="ant-table-cell">Accepted/Rejected</th>
                  <th  style="background: aliceblue;" class="ant-table-cell" >Quantity</th>
                  <th  style="background: aliceblue;" class="ant-table-cell">Store Allocated</th>
                  <th  style="background: aliceblue;" class="ant-table-cell">Rack Allocated</th>
                  <th style="background: aliceblue;" class="text-end ant-table-cell">
                    <a  nz-button nzSize="small" nzType="primary"  class="btn btn-light-primary" (click)="data.NewRecord = true">Add</a> &nbsp;
                    <a nz-button nzSize="small" nzType="primary" class="btn btn-light-success" (click)="SaveAllocationtoDB(data)">Save</a>
                  </th>
                 </tr>
                </thead>
              <tbody class="ant-table-tbody ng-star-inserted">
                <tr *ngIf="data.NewRecord" class="ant-table-thead ng-star-inserted">
                  <td>
                    <nz-select class="form-select mb-2" nzSize="large" [(ngModel)]="NewAllocation.InspectionType" nzAllowClear nzPlaceHolder="Choose">
                      <nz-option nzValue="Accepted" nzLabel="Accepted"></nz-option>
                      <nz-option nzValue="Rejected" nzLabel="Rejected"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 20%;">
                    <input style="width: 70%;" type="text" [(ngModel)]="NewAllocation.Quantity" class="form-control mb-2" (blur)="Quantitychange(NewAllocation)" />
                  </td>
                  <td style="width: 25%;">
                    <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()" [(ngModel)]="NewAllocation.StoreId" nzSize="large" nzAllowClear nzPlaceHolder="Choose">
                      <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
                    </nz-select>

                    <!--<input type="text" [(ngModel)]="NewAllocation.RackAllocation" class="form-control mb-2" />-->
                  </td>
                  <td style="width: 25%;">
                    <nz-select nzShowSearch class="form-select mb-2" nzSize="large" [(ngModel)]="NewAllocation.RackId" nzAllowClear nzPlaceHolder="Choose">
                      <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>

                    </nz-select>

                    <!--<input type="text" [(ngModel)]="NewAllocation.RackAllocation" class="form-control mb-2" />-->
                  </td>
                  <td class="text-end">
                    <div class="text-nowrap">
                      <button type="button" data-repeater-delete="" nzSize='small' class="btn btn-sm btn-icon btn-light-success" (click)="SaveAllocation(data)">
                        <span class="svg-icon svg-icon-2">
                          <i nz-icon nzType="check" nzTheme="outline"></i>
                        </span>
                      </button>&nbsp;
                      <button type="button" data-repeater-delete="" nzSize='small' class="btn btn-sm btn-icon btn-light-danger" (click)="data.NewRecord = false">
                        <span class="svg-icon svg-icon-2">
                          <i nz-icon nzType="close" nzTheme="outline"></i>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
                      <tr *ngFor="let alloc of data.StockProductAllocation" class="ant-table-thead ng-star-inserted">
                          <td class="ant-table-cell">{{alloc.InspectionType}}</td>
                          <td class="ant-table-cell">{{alloc.Quantity}}</td>
                          <td class="ant-table-cell"> {{alloc.StoreName}}  </td>
                          <td class="ant-table-cell">  {{alloc.RackName}} / {{alloc.RackCode}} </td>
                          <td class="text-end ant-table-cell">
                            <a nz-button nzSize="small" nzType="primary" class="btn btn-light-danger" (click)="RemoveAllocation(data, alloc)">Remove</a>
                              <!-- <button type="button" data-repeater-delete="" nzSize = 'small' class="btn btn-sm btn-icon btn-light-danger" (click)="RemoveAllocation(data, alloc)">
                                <span class="svg-icon svg-icon-2">
                                  <i nz-icon nzType="close" nzTheme="outline"></i>
                                </span>
                            </button> -->
                          </td>
                      </tr>
                </tbody>
            </table>
                  </tr>
          </ng-template>

    </tbody>
  </nz-table>
<br>
  <div class="d-flex justify-content-end">
    <!--begin::Button-->
    <a routerLink="/home/stockallocationlist" class="btn btn-success">Back to Stock Allocation List</a> 
    <!--end::Button-->
</div>

  </div>
</div>

