import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ConsumeStockProductMasterModel, ConsumeStockProductPrintMasterModel } from '../../Models/ConsumptionModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
import { AuthService } from '../../Services/auth.service';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';

@Component({
  selector: 'app-Consumptionlist',
  templateUrl: './consumptionlist.component.html',
  styleUrls: ['./consumptionlist.component.css']
})
export class ConsumptionListComponent implements OnInit {

  myDateValue: Date = new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2));
  isValidDate: any
  ApiUrl = environment.Api_Url;
  ConsumptionList: ConsumeStockProductMasterModel[] = [];
  ConsumptionListOriginal: ConsumeStockProductMasterModel[] = [];
  ConsumptionListPrint: ConsumeStockProductPrintMasterModel[] = [];
  ConsumptionListView: ConsumeStockProductPrintMasterModel[] = [];
  ConsumptionPrint!: ConsumeStockProductPrintMasterModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;

  PopUpTitle: string = "Add New Issue";
  searchValue = '';
  toDate: Date = new Date(new Date().setHours(23, 59, 59));
  visible = false;
  fields: ConsumeStockProductPrintMasterModel = new ConsumeStockProductPrintMasterModel();


  exportfields: ConsumeStockProductPrintMasterModel[] = [];

  exportoptions = {
    headers: ["Product", "Sale Order", "Quantity", "Store", "Rack", "Added Date", "Purpose", "Added By"]
  };
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false

  }
  request = {
    ConsumedDateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
    ConsumedDateTo: new Date(new Date().setHours(23, 59, 59)),
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService
    , private router: Router, private auth: AuthService, private loader: LoadingService
  ) { }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllConsumption();
  }
  GetAllConsumption() {
    this.loader.show();
    this.request.ConsumedDateFrom = this.myDateValue;
    this.request.ConsumedDateTo = this.toDate
    this.ConsumptionList = [];
    this.ConsumptionListOriginal = [];
    let url = this.ApiUrl + "report/getallconsumestockproductsfilters";
    this.http.post<ConsumeStockProductMasterModel[]>(url, this.request).subscribe(res => {
      this.ConsumptionListOriginal = res;
      this.ConsumptionList = res;
      this.ConsumptionListView = res
      this.isTableLoading = false;
      this.exportfields = [];
      this.ConsumptionListView.forEach((x) => {

        this.fields = new ConsumeStockProductPrintMasterModel()
        this.fields.ProductName = x.ProductName
        this.fields.SaleOrderNumber = x.SaleOrderNumber
        this.fields.Quantity = x.Quantity
        this.fields.StoreName = x.StoreName
        this.fields.RackName = x.RackName
        this.fields.AddedDate = new DatetimeConverter().transform(x.AddedDate)
        this.fields.Purpose = x.Purpose
        this.fields.AddedBy = x.AddedBy
        this.exportfields.push(this.fields);
        this.isTableLoading = false;

      })
      this.loader.hide();

    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllConsumption();
      }
    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {
    if (this.myDateValue != this.request.ConsumedDateFrom || this.toDate != this.request.ConsumedDateTo) {
      this.GetAllConsumption();
    }
    var res = this.ConsumptionListOriginal;
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ConsumeStockProductMasterModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ConsumptionList = selectepolist;
      this.ConsumptionList = res.filter(
        (item: ConsumeStockProductMasterModel) =>
          item?.ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.SaleOrderNumber?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Quantity == +this.searchValue ||
          item?.RackName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Purpose?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ConsumptionList = res.filter(
        (item: ConsumeStockProductMasterModel) =>
          item?.ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.SaleOrderNumber?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Quantity == +this.searchValue ||
          item?.RackName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Purpose?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllConsumption();
    this.myDateValue = this.request.ConsumedDateFrom;
    this.toDate = this.request.ConsumedDateTo;
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        ('Consumption-export' + exportdate),
        this.exportoptions
      );


  }
}
