import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { Modules } from '../../Models/Enums';
import { AdminStoreModel } from '../../Models/MasterModel';
import { GeneralConfigurationModel } from '../../Models/UserRoleModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-GeneralConfiguration',
  templateUrl: './GeneralConfiguration.component.html'
})
export class GeneralConfigurationComponent {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  Configuration: GeneralConfigurationModel = new GeneralConfigurationModel();
  ConfigurationList: GeneralConfigurationModel[] = [];
  ConfigurationListOriginal: GeneralConfigurationModel[] = [];
  isLoading: boolean = false;
  isStoreLoading: boolean = false;
  isVisible = false;
  isStoreVisible = false;
  searchValue = '';
  isNew = true;
  isTableLoading: boolean = false;
  StoreList: AdminStoreModel[] = [];
  allChecked = false;
  IsAuth = false;
  count: 0;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private msalService: MsalService,
    public http: HttpClient
  ) { }


  GetAllConfiguration() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "generalconfig/getconfig";
    this.http.get<any>(url).subscribe(
      (res) => {
        this.ConfigurationList = res;
        this.ConfigurationListOriginal = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllConfiguration();
        }
      }
    );
  }





  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      //event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  reset(): void {
    this.searchValue = '';

  }
  ngOnInit() {
    this.IsAuth = this.authService.CheckModule(Modules.AdminGeneralConfiguration);
    this.GetAllConfiguration();
  }

  showModal() {
    this.isNew = true;
    this.isVisible = true;

  }
  OpenEditPop(data: GeneralConfigurationModel) {
    this.isNew = false;
    this.Configuration = data;
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isStoreVisible = false;
    this.Configuration = new GeneralConfigurationModel;
  }

  Save() {
    if (this.Configuration.ConfigItem == '') {
      this.alertService.error("Config Item required");
      return
    }
    if (this.Configuration.ConfigValue == '') {
      this.alertService.error("Config Value required");
      return
    }
    this.Configuration.AddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + "generalconfig/addconfig";
    if (this.Configuration.ConfigId > 0) {
      url = this.ApiUrl + "generalconfig/editconfig";
    }
    this.isLoading = true;
    this.http.post<any>(url, this.Configuration).subscribe({
      next: res => { console.log(res); this.alertService.success(res); this.isLoading = this.isVisible = false; this.GetAllConfiguration(); },
      error: res => { console.log(res); this.alertService.error(res.error); this.isLoading = this.isVisible = false; },
    });
  }
}
