import { SaleOrderProductionModel } from "./SalesOrderModel";

export class FormulationCodeModel {
  FabricProductName: string = '';
  AddedBy: string = '';
  CategoryName: string = '';
  SaleFormulationCode: string = '';
  SaleFormulationCodeId: number = 0;
  SaleOrderId: number = 0;
  AddedDate: string = '';
  CategoryId: number = 0;
  ThicknessId: number = 0;
  ThicknessNumber: string = '';
  PreSkinGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  AdhesiveGsm: number = 0;
  FabricGsm: number = 0;
  TotalGsm: number = 0;
  MinSpeed: number = 0;
  MaxSpeed: number = 0;
  FabricProductId: number = 0;
  IsOrderLinkingRequired: boolean = false;
  MixingData: FormulationCodeMixingModel[] = [];
}




export class FormulationCodeMixingModel {
  FormulationCodeMixingId: number = 0;
  SaleFormulationCodeId: number = 0;
  MixingId: number = 0;
  MixingName: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
  GSM: number = 0;
  MixingRawMaterial: FormulationCodeMixingRawMaterialModel[] = [];
}

export class FormulationCodeMixingRawMaterialModel {
  FormulationCodeMixingRawMaterialId: number = 0;
  FormulationCodeMixingId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  AvgGsm: number = 0;
  Quantity: number = 0;
  Unit: string = '';
  Price: number = 0;
  Scquantity: number = 0;
  ProductStock?: any
}

export class FormulationCodePrintModel {

  SaleFormulationCode: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
  CategoryId: number = 0;
  ThicknessId: number = 0;
  PreSkinGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  AdhesiveGsm: number = 0;
  FabricGsm: number = 0;
  TotalGsm: number = 0;
  FabricProductId: number = 0;
  FabricProductName: string = '';
  CategoryName: string = ''
}

export class FormulationProductCalculatePasteReqRequestModel {
  LMConstant: number = 1.5;
  TotalProductionQty: number | 0;
  PreSkinGsm: number | 0;
  SkinGsm: number | 0;
  FoamGsm: number | 0;
  AdhesiveGsm: number | 0;
  PreSkinRemainingPasteScQty: number | 0;
  SkinRemainingPasteScQty: number | 0;
  FoamRemainingPasteScQty: number | 0;
  AdhesiveRemainingPasteScQty: number | 0;
  SaleOrderId: number | 0;
  SaleOrderProduction: SaleOrderProductionModel
}
export class FormulationProductCalculatePasteReqResponseModel {
  PreSkinGsmPasteReq: number | 0;
  SkinGsmPasteReq: number | 0;
  FoamGsmPasteReq: number | 0;
  AdhesiveGsmPasteReq: number | 0;
  PreSkinGsmSCPasteReq: number | 0;
  SkinGsmSCPasteReq: number | 0;
  FoamGsmSCPasteReq: number | 0;
  AdhesiveGsmSCPasteReq: number | 0;
  PreSkinGsm: number | 0;
  SkinGsm: number | 0;
  FoamGsm: number | 0;
  AdhesiveGsm: number | 0;
  PreSkinScGsm: number | 0;
  SkinScGsm: number | 0;
  FoamScGsm: number | 0;
  AdhesiveScGsm: number | 0;
}