<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
      <nz-page-header [nzGhost]="false">
        <nz-page-header-title>Bank Details List</nz-page-header-title>
        <nz-page-header-subtitle>Manage your Bank Details here</nz-page-header-subtitle>
        <nz-page-header-extra>
          <button
            nz-button
            nzType="primary"
            *ngIf="this.permission.Add"
            (click)="showModal()"
          >
            Add New
          </button>
        </nz-page-header-extra>
      </nz-page-header>
      <nz-divider></nz-divider>
      <div style="padding: 8px;float:right">
        <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
        (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
        <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
      </div>
  
      <nz-table
        nzSize="small"
        [nzPageSize]="100"
        style="width: 100%"
        #basicTable
        [nzScroll]="{ x: '1200px', y: '515px' }"
        [nzData]="this.BankList"
        [nzLoading]="isTableLoading"
        nzBordered
      >
        <thead>
          <tr>
            <th nzWidth="150px">Bank Name</th>
            <th nzWidth="200px">Name Account Holder</th>
            <th nzWidth="150px">Account Type</th>
            <th nzWidth="150px">Account No.</th>
            <th nzWidth="150px">IFSC Code</th>
            <th nzWidth="150px">Bank Branch</th>
            <th nzWidth="150px">Swift Code</th>
            <th nzWidth="150px">MICR Code</th>
            <th nzWidth="180px">Added Date</th>
            <th nzWidth="200px">Added By</th>
            <th nzWidth="200px"
                nzRight
                style="text-align: center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.BankName }}</td>
            <td>{{ data.AccountHolderName }}</td>
            <td>{{ data.AccountType }}</td>
            <td>{{ data.AccountNumber }}</td>
            <td>{{ data.Ifsc }}</td>
            <td>{{ data.Branch }}</td>
            <td>{{ data.SwiftCode }}</td>
            <td>{{ data.Micr }}</td>
            <td>{{ data.AddedDate  | DatetimeConverter}}</td>
            <td>{{ data.AddedBy }}</td>

            <td nzRight
                style="text-align: center"
                *ngIf="CheckUserAddPermission(data.AddedBy)">
              <button class="btn btn-sm btn-light-primary"
                      (click)="OpenEditPop(data)">
                Edit
              </button>
              &nbsp;
              <button class="btn btn-sm btn-light-danger"
                      (click)="handleDelete(data)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
  
      <nz-modal
        [nzWidth]="800"
        [nzStyle]="{ top: '40px' }"
        [(nzVisible)]="isVisible"
        [nzTitle]="modalTitle"
        [nzContent]="modalContent"
        [nzFooter]="null"
        (nzOnCancel)="handleCancel()"
      >
        <ng-template #modalTitle>{{ PopUpTitle }}</ng-template>
  
        <ng-template #modalContent>
          <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Bank Name</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="BankName" name="BankName" />
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Name Account Holder </nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="AccountHolderName" name="AccountHolderName" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Account Type </nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-select formControlName="AccountType">
                  <nz-option nzValue="Saving" nzLabel="Saving"></nz-option>
                  <nz-option nzValue="Current" nzLabel="Current"></nz-option>
                

                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Account No. </nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="AccountNumber" name="AccountNumber" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>IFSC Code </nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="Ifsc" name="Ifsc"  />
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Bank Branch</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="Branch" name="Branch" />
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-label [nzSpan]="8">Swift Code </nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="SwiftCode" name="SwiftCode" />
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-label [nzSpan]="8" >MICR Code </nz-form-label>
              <nz-form-control [nzSpan]="14">
                <input nz-input formControlName="Micr" name="Micr Code" />
              </nz-form-control>
            </nz-form-item>
  
         
           
            <div class="text-center">
              <button nz-button nzType="primary" [nzLoading]="isLoading">
                Save
              </button>
            </div>
          </form>
        </ng-template>
      </nz-modal>
    </div>
  </div>
