<table class="footer-table">
  <tr>
    <td style="margin-top:10px; vertical-align: text-bottom; border: solid; border-width: 1px; width: 30%;">
        <div class="producttable-container">
          <br>
            
          <table>
            <tr>
              <td style="text-align: center;">
                <input type="email"  nzSize='small' [(ngModel)]="NewEmail" class="form-control mb-2" />
              </td>
              <td style="text-align: center;">
                <button type="button" nzSize='small' class="btn btn-sm btn-primary" [disabled] = "validateEmail()" (click)="EmailList.push(NewEmail); this.NewEmail=''">
                  Add Email
                 </button>
                 <div class="badge badge-light-danger" *ngIf="validateEmail() && NewEmail != ''">
                 Please Enter Correct Email
                </div>
              </td>
            </tr>
          </table>
        <table style="width: 80%;" class="producttable">
          <thead>
            <tr>
              <th>Email List</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of EmailList">
              <td>{{data}}
                <button type="button" data-repeater-delete="" nzSize='small' class="btn btn-sm btn-icon btn-light-danger" (click)="RemoveEmail(data)">
                  <span class="svg-icon svg-icon-2">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>       
        </table>
        <br>
        <button  style="margin-top:10px;" class="btn btn-sm btn-success" [disabled] = "EmailList.length < 1 || isLoading" (click)="captureScreen()">
          <i nz-icon nzType="loading" *ngIf="isLoading"></i>
          Send Purchase order in Email</button>
         
      </div>
    </td>
    <td>
      <div *ngIf="this.PurchaseOrder" id="pdfTable">
        <table class="companytable">
          <tr>
            <td>
              <div class="company">
                <img src="/assets/img/printlogo.jpeg" />
                <b class="title">{{Company.CompanyName}}</b>
                <p [innerHTML]="Company.Address"></p>
                <p>Contact: {{Company.Contact}}</p>
                <p>Email: {{Company.Email}}</p>
                <p>GSTIN: {{Company.GST}}</p>
              </div>
              <div class="vendor" *ngIf="PurchaseOrder">
                <b class="title">VENDOR</b>
                <p class="name">{{PurchaseOrder.SupplierName}}</p>
                <p>{{NewSupplier.Address}}</p>         
                <p>Contact: {{NewSupplier.SupplierContactNumber}}</p>
                <p>Email: {{NewSupplier.Email}}</p>
                <p>GSTIN: {{NewSupplier.Gst}}</p>
              </div>
            </td>
            <td>
              <div class="po">
                <div class="title">PURCHASE ORDER</div>
                <table class="potable" *ngIf="PurchaseOrder">
                  <tr>
                    <td><b> PO No</b></td>
                    <td>{{PurchaseOrder.Ponumber}}</td>
                  </tr>
                  <tr>
                    <td><b>PO Date</b></td>
                    <td>{{PurchaseOrder.PocreationDate | date: 'dd-MMM-yyyy'}}</td>
                  </tr>
                  <tr>
                    <td><b>Delivery Date</b></td>
                    <td>{{PurchaseOrder.DeliveryDate | date: 'dd-MMM-yyyy'}}</td>
                  </tr>
                  <tr>
                    <td><b>Payment Terms</b></td>
                    <td>{{PurchaseOrder.PaymentTerm}}</td>
                  </tr>
                  <tr>
                    <td><b>Transport</b></td>
                    <td>{{PurchaseOrder.ProductName}}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
    
        <div class="producttable-container">
          <table class="producttable">
            <thead>
              <tr>
                <th width="50px"></th>
                <th>Description</th>
                <th width="90px">QTY</th>
                <th width="70px">UNIT</th>
                <!--<th>Grade</th>-->
                <th width="80px">PRICE</th>
      
                <th width="120px">AMOUNT ({{PurchaseOrder.PurchaseOrderProduct[0].Currency}})</th>
                <th width="60px">IGST/TAX</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of PurchaseOrder.PurchaseOrderProduct;let i=index">
                <td>{{i+1}}</td>
                <td>{{ data.ProductName }}</td>
                <td>{{ data.Quantity }}</td>
                <td>{{ data.Unit }}</td>
                <!--<td>{{ data.Grade }}</td>-->
                <td>{{ data.Rate }}</td>
      
                <td>{{ data.Amount }}</td>
                <td><span *ngIf="data.Igst==0">N/A</span><span *ngIf="data.Igst!=0">{{ data.Igst }}</span> </td>
              </tr>
              <tr *ngFor="let number of [].constructor(this.PrintTableLength)">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
      
          </table>
          <table class="producttotaltable">
      
            <tbody>
              <tr>
                <td style="width:60%">
                  <b> (In words) ({{PurchaseOrder.PurchaseOrderProduct[0].Currency}})-{{TotalWord}} </b><br />
                  <b>Other Comments or Special Instruction</b>
                  <div class="comments">
      
                    <div innerHTML="{{PurchaseOrder.Remarks}}"></div>
                  </div>
                  <ol>
                    <li><p>Text report should be accompained with material without which we will not acceps the consigment.</p></li>
                    <li><p style="margin-top:-5px;">It is necessary to attach the purchase order with invoice/challan.</p></li>
                  </ol>
                </td>
                <td>
                  <table class="producttotaltable" style="text-align: right;">
                    <tbody>
                      <tr>
                        <td style="width:50%"><b> Sub Total </b></td>
                        <td style="width:50%"> {{PurchaseOrder.PototalAmount}}</td>
                      </tr>
                      <tr>
                        <td style="width:50%"><b>Total IGST/TAX </b></td>
                        <td style="width:50%"> <span *ngIf="PurchaseOrder.PototalTax==0">N/A</span><span *ngIf="PurchaseOrder.PototalTax!=0">{{PurchaseOrder.PototalTax}}</span></td>
                      </tr>
                      <tr>
                        <td style="width:50%"><b>S & H</b> </td>
                        <td style="width:50%"> 0.00</td>
                      </tr>
                      <tr>
                        <td style="width:50%"><b>Freight & Insurance </b></td>
                        <td style="width:50%"> 0.00</td>
                      </tr>
                      <tr>
                        <td style="width:50%"><b>Other </b></td>
                        <td style="width:50%"> 0.00</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="border-top: 2px solid;">
                        <td style="width:50%"><b>Net Amount({{PurchaseOrder.PurchaseOrderProduct[0].Currency}}) </b></td>
                        <td style="width:50%"> {{PurchaseOrder.Pograndtotal}}</td>
                      </tr>
                    </tfoot>
                  </table>
      
                </td>
      
      
              </tr>
      
            </tbody>
      
          </table>
      
        </div>
        <div class="footer">
          <p>For any enquiry please contact {{PurchaseOrder.UserDetails?.Name}} {{PurchaseOrder.UserDetails?.Contact}}</p>
          <table class="footer-table">
            <tr>
              <td width="33%" style="text-align: left; padding-left: 20px;">
                <p style="margin-bottom:5px"><b> PREPARED BY:</b></p>
                <p style="margin-bottom: 20px;font-style: italic; font-weight: bolder;font-family:cursive;"><b>{{PurchaseOrder.AddedBy?.Name?.toUpperCase()}}</b></p>
                <p style="margin-top: -15px;">Auth Signatory</p>
              </td>
              <td width="34%">
                <p style="margin-bottom:5px"><b>APPROVED BY:</b></p>
                <p style="margin-bottom: 20px;font-style: italic; font-weight: bolder;font-family:cursive;"><b>{{PurchaseOrder.ApprovedBy?.Name.toUpperCase()}}</b></p>
                <p style="margin-top: -15px;">Auth Signatory</p>
              </td>
              <td width="33%" style="text-align: right; padding-right: 20px;">
                <p style="margin-bottom:50px"><b>DIRECTOR'S SIGNATURE</b></p>
                <p style="margin-top: -15px;">Auth Signatory</p>
              </td>
            </tr>
          </table>
          <p *ngIf="PurchaseOrder.ApprovedBy?.Name">NOTE: This is a digitally generated and approved document. No physical sign required.</p>
        </div>
      </div>
    </td>
  </tr>


