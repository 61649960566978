import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TransportModel, TransportPrintModel, TransportVehicleModel } from '../Models/TransportModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

import * as moment from 'moment';
import { Modules, Responsibility } from '../Models/Enums';
import { AuthService } from '../Services/auth.service';
@Component({
  selector: 'app-transport-management',
  templateUrl: './transportManagement.component.html',
  styleUrls: ['./transportManagement.component.css']
})
export class TransportManagementComponent implements OnInit {

  validateForm!: UntypedFormGroup;
  validateFormV!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  TransportList: TransportModel[] = [];
  TransportListOriginal: TransportModel[] = [];
  NewTransport: TransportModel = new TransportModel;
  NewVehicle: TransportVehicleModel = new TransportVehicleModel;
  isVisible = false;
  isVisibleV = false;
  isLoading: boolean = false;
  isLoadingV: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Transport";
  PopUpTitleV: string = "Add New Vehicle";
  NameError = 'Enter TransportCompany name';

  exportoptions = {
    headers: ["COMPANY NAME", "EMAIL", "CONTACT", "GST", "ADDRESS"]
  };
  fields: TransportPrintModel = new TransportPrintModel;
  exportfields: TransportPrintModel[] = []
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private auth: AuthService, private router: Router) {

  }
  getParamValueQueryString(paramName: string) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Transport, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Transport, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Transport, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      TransportCompanyName: [null, [Validators.required, Validators.minLength(3)], [this.NameAsyncValidator]],
      TransportCompanyAddress: [null, [Validators.required]],
      TransportCompanyEmail: [null, [Validators.email]],
      TransportCompanyContact: [null, [Validators.required, Validators.minLength(10)]],
      //Gst: [null, [Validators.required,  Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]]
      Gst: [null]
    });
    this.validateFormV = this.fb.group({
      VehicleNumber: [null, [Validators.required, Validators.minLength(3)]],
      VehicleType: [null, [Validators.required]],

    });
    this.GetAllTransport();
    ;
    if (this.getParamValueQueryString("type") == 'add') {
      this.showModal()
    }
  }
  get f() { return this.validateForm.controls; }
  get fV() { return this.validateFormV.controls; }
  GetAllTransport() {
    this.isTableLoading = true;

    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe(res => {
      this.TransportList = res;
      this.TransportListOriginal = res;
      this.TransportList.forEach((nos) => {
        nos.ExpandRecord = false;
      })
      this.TransportListOriginal.forEach((nos) => {
        nos.ExpandRecord = false;
      })


      this.TransportList.forEach(x => {
        this.fields = new TransportPrintModel;

        this.fields.TransportCompanyName = x.TransportCompanyName;
        this.fields.TransportCompanyEmail = x.TransportCompanyEmail;
        this.fields.TransportCompanyContact = x.TransportCompanyContact;
        this.fields.Gst = x.Gst;
        this.fields.TransportCompanyAddress = x.TransportCompanyAddress;
        this.exportfields.push(this.fields);
      })
      this.isTableLoading = false;
    }, res => { this.GetAllTransport(); });
  }
  export() {

    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'transport-export' + exportdate, this.exportoptions);
  }
  SaveTransport() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewTransport.TransportCompanyName = this.f['TransportCompanyName'].value;
    this.NewTransport.TransportCompanyAddress = this.f['TransportCompanyAddress'].value;
    this.NewTransport.TransportCompanyContact = this.f['TransportCompanyContact'].value;
    this.NewTransport.TransportCompanyEmail = this.f['TransportCompanyEmail'].value;
    this.NewTransport.Gst = this.f['Gst'].value;
    // if(!this.Validate(this.NewTransport))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "Transport/addupdateTransport";
    this.http.post<TransportModel>(url, this.NewTransport).subscribe({

      next: res => { this.alertService.success("Transport Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllTransport(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  SaveVehicle() {
    if (this.validateFormV.invalid) {
      Object.values(this.validateFormV.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoadingV = false
      return;
    }


    this.NewVehicle.VehicleNumber = this.fV['VehicleNumber'].value;
    this.NewVehicle.VehicleType = this.fV['VehicleType'].value;


    let url = this.ApiUrl + "Transport/addUpdatetransportvehicle";
    this.http.post<TransportVehicleModel>(url, this.NewVehicle).subscribe({

      next: res => {
        this.alertService.success("Vehicle Saved Successfully");
        this.isLoadingV = this.isVisibleV = false;
        this.GetAllTransport();
      },
      error: res => { this.alertService.error(res.error); this.isLoadingV = this.isVisibleV = false; },

    });

  }
  OpenEditPop(data: TransportModel) {
    this.PopUpTitle = "Modify Transport Details";

    this.validateForm.setValue(
      {
        TransportCompanyName: data.TransportCompanyName,
        TransportCompanyAddress: data.TransportCompanyAddress,
        TransportCompanyContact: data.TransportCompanyContact,
        TransportCompanyEmail: data.TransportCompanyEmail,
        Gst: data.Gst
      }
    )
    this.NewTransport = new TransportModel();
    this.NewTransport.TransportId = data.TransportId;

    this.isVisible = true;
  }
  OpenEditPopV(data: TransportVehicleModel) {
    this.PopUpTitleV = "Modify Vehicle Details";
    this.validateFormV.setValue(
      {
        VehicleNumber: data.VehicleNumber,
        VehicleType: data.VehicleType
      }
    )
    this.NewVehicle = new TransportVehicleModel();
    this.NewVehicle.TransportId = data.TransportId;
    this.NewVehicle.VehicleId = data.VehicleId;
    this.isVisibleV = true;
  }
  showModal(): void {
    this.PopUpTitle = "Add New Transport";
    this.validateForm.reset();
    this.validateForm.reset();
    //this.validateForm.setValue(
    //  {
    //    TransportCompanyName: null,
    //    TransportCompanyAddress: null,
    //    TransportCompanyContact: null,
    //    TransportCompanyEmail: null,
    //    Gst: null
    //  }
    //)
    this.NewTransport.TransportCompanyName = "";
    this.NewTransport.TransportId = 0;
    this.NewTransport.TransportCompanyAddress = "";
    this.NewTransport.TransportCompanyContact = "";
    this.NewTransport.TransportCompanyEmail = "";
    this.NewTransport.Gst = "";
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveTransport();

  }
  handleOkV(): void {

    this.isLoadingV = true;
    this.SaveVehicle();

  }
  handleCancel(): void {
    this.isVisible = false;
  }
  handleCancelV(): void {
    this.isVisibleV = false;
  }
  DeleteTransport() {
    let url = this.ApiUrl + "Transport/disabletransportcompany";
    this.http.post<any>(url, this.NewTransport.TransportId).subscribe({

      next: res => {

        this.alertService.success("Transport Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllTransport();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  DeleteVehicle() {
    let url = this.ApiUrl + "Transport/disablevehicle";
    this.http.post<any>(url, this.NewTransport.TransportId).subscribe({

      next: res => {

        this.alertService.success("Vehicle Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllTransport();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: TransportModel) {
    this.NewTransport = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Transport?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteTransport(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  handleDeleteV(data: TransportVehicleModel) {
    this.NewVehicle = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Vehicle?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteVehicle(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  showModalV(data: TransportModel): void {
    this.PopUpTitleV = "Add New Vehicle";
    this.validateFormV.setValue(
      {
        VehicleNumber: null,
        VehicleType: null

      }
    )
    this.NewVehicle.TransportId = data.TransportId;
    this.NewVehicle.VehicleNumber = "";
    this.NewVehicle.VehicleId = 0;
    this.NewVehicle.VehicleType = "";

    this.isVisibleV = true;
  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.TransportList.filter(x => x.TransportId != this.NewTransport.TransportId);
        var nre = res.filter(x => x.TransportCompanyName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter TransportCompany name";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "TransportCompany name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter TransportCompany name";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });


  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.visible = false;
    var res = this.TransportListOriginal;


    this.TransportList = res.filter((item: TransportModel) => item?.TransportCompanyName?.toLowerCase().indexOf(this.searchValue?.toLowerCase()) !== -1
      || item?.TransportCompanyEmail?.toLowerCase().includes(this.searchValue?.toLowerCase())
      || item?.TransportCompanyContact?.toLowerCase().includes(this.searchValue?.toLowerCase())
      || item?.TransportCompanyAddress?.toLowerCase().includes(this.searchValue?.toLowerCase())
    );
  }
}
