export class SupplierModel
    {
        SupplierId : number =0;
        SupplierName : string = '';
        ContactPersonName : string= '';
        Email : string= '';
        SupplierContactNumber : string= '';
        ContactPersonNumber : string= '';
        Address : string= '';
        Gst : string= '';
}



export class CustomerModel {
  CustomerId: number = 0;
  CustomerName: string = '';
  CustomerContactNumber: string = '';
  Email: string = '';
  Address: string = '';
    Gstnumber: string = '';
    Country: string = '';
    State: string = '';
}


export class OrderModel {
  OrderId: number = 0;
  CustomerId: number = 0;
  SaleOrderNo: string = '';
  OrderType: string = '';
  CustomerName: string = '';
  OrderCreationDate: string = '';
  DeliveryDate: string = '';
  OrderProduct: OrderProductModel[] = [];
}

export class OrderProductModel {

  OrderProductId: number = 0;
  OrderId: number = 0;
  ProductCode: string = '';
  ProductId: number = 0;
  ProductName: string = '';
  ColorId: number = 0;
  ColorName: string = '';
  Width: number = 0;
  Thick: number = 0;
  Unit: string = '';
  Rate: number = 0;
  Quantity: number = 0;
  Grade: string = '';
}
