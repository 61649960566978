<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>FORMULATION CODE</nz-page-header-title>
      <nz-page-header-subtitle>Manage your formulation List here</nz-page-header-subtitle>
      <nz-page-header-extra>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search All Formulation Code"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable
      [nzData]="this.FormulationCodeList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzWidth="2px">Formulation Code</th>
          <th nzWidth="1px">Category</th>
          <th nzWidth="2px">Sandwich Lining</th>
          <th nzWidth="2px">Fabric Product</th>
          <th nzWidth="2px">Added Date</th>
          <th nzWidth="2px">Added By</th>
          <th nzWidth="1px" style="text-align:center" nzRight *ngIf="permission.Add || permission.View">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.SaleFormulationCode }}</td>
          <td>{{ data.CategoryName }}</td>
          <td>{{ data.IsOrderLinkingRequired == true ? "Yes":
            "No" }}</td>
          <td>{{ data.FabricProductName }}</td>
          <!--<td>{{ data.BatchNo }}</td>
  <td>{{ data.WorkPlanDate  | date: 'dd-MMM-yyyy' }}</td>-->
          <!-- <td>{{ data.WorkPlanOrder }}</td> -->
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td>{{ data.AddedBy }}</td>

          <td nzRight style="text-align: center" *ngIf="permission.Add || permission.View">
            <div>
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item *ngIf="permission.View" (click)="OpenViewPop(data)">View</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="permission.View"
                    (click)="printstandardformulation(data.SaleFormulationCodeId)">Print</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="permission.Edit"
                    routerLink="/home/production/formulationcode/edit/{{data.SaleFormulationCodeId}}">Edit</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="permission.Delete"
                    (click)="handleDisable(data.SaleFormulationCodeId)">Disable</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>
          <!--<div>
      <button nz-button nz-dropdown  nzTrigger="click" [nzDropdownMenu]="menu" class="btn btn-sm btn-light-primary" style="line-height:0">
        Action
        <span nz-icon nzType="down"></span>
      </button>
        <nz-dropdown-menu #menu="nzDropdownMenu" >
        <ul nz-menu nzSelectable>
          <li style="width: 120px;" nz-menu-item (click)="OpenViewPop(data)">View</li>
          <li style="width: 120px;" nz-menu-item>Edit</li>

        </ul>
      </nz-dropdown-menu>
    </div>-->
          <!--<button class="btn btn-sm btn-light-primary" (click)="OpenViewPop(data)">View</button> &nbsp;-->



        </tr>
      </tbody>
    </nz-table>




  </div>
</div>

<!-- To view open drawer  -->


<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1100" nzTitle="FORMULATION CODE"
  (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div *ngIf="WorkPlanModel">
      <nz-divider nzText="FORMULATION CODE DETAILS"></nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="WorkPlanModel" nzSize="small" [nzColumn]="6">
        <nz-descriptions-item nzTitle="FORMULATION CODE">{{WorkPlanModel.SaleFormulationCode}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="CATEGORY">{{WorkPlanModel.CategoryName}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="SANDWICH LINING">{{WorkPlanModel.IsOrderLinkingRequired == true ? "Yes":
          "No"}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="FABRIC PRODUCT">{{WorkPlanModel.FabricProductName}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="THICKNESS ">{{WorkPlanModel.ThicknessNumber}}</nz-descriptions-item>

      </nz-descriptions>



      <nz-divider nzText="Mixing Details"></nz-divider>
      <nz-table #nzTable [nzData]="WorkPlanModel.MixingData" nzTableLayout="fixed" style="
padding: 5px;
border: 1px solid #ccc;
border-radius: 4px;
margin-top: 10px;
background: #fff;
box-shadow: 1px 1px 2px 2px #415994;

" nzShowPagination="false">

        <tbody>





          <ng-container *ngFor="let data of nzTable.data;let i=index">
            <div style="padding: 10px 0; text-transform: uppercase">
              <i>Mixing Name :</i>
              <b> {{data.MixingName}} </b>
            </div>
            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
              <thead>
                <tr>
                  <th nzWidth="50px">S.No</th>
                  <th nzWidth="100px">Product Name</th>
                  <th nzWidth="200px">Product Code</th>
                  <th nzWidth="100px">Quantity</th>
                  <th nzWidth="100px">Unit</th>
                  <th nzWidth="100px">Price</th>
                  <th nzWidth="100px">Scquantity</th>
                </tr>
              </thead>
        <tbody>
          <ng-container *ngFor="let data of nzTable.data; let i =index">
            <tr>

              <td nzWidth="20px">{{ i + 1 }}</td>
              <td>{{data.ProductName}}</td>
              <td>{{data.ProductCode}}</td>
              <td>{{data.Quantity}}</td>
              <td>{{data.Unit}}</td>
              <td>{{data.Price}}</td>
              <td>{{data.Scquantity}}</td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>


      <nz-table #nzTable [nzData]="data.MixingRawMaterial" nzShowPagination="false">


      </nz-table>

  </ng-container>
  </tbody>
  </nz-table>
  <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 20px">
    <div nz-col [nzSpan]="24">
      <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="5" style="box-shadow: 1px 1px 2px 2px #415994">
        <nz-descriptions-item nzTitle="Pre Skin" nzColon="true"><b>
            {{ WorkPlanModel.PreSkinGsm }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Skin " nzColon="true"><b>
            {{ WorkPlanModel.SkinGsm }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Foam" nzColon="true"><b>
            {{ WorkPlanModel.FoamGsm}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Adhesive" nzColon="true"><b>
            {{ WorkPlanModel.AdhesiveGsm }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true"><b>
            {{ WorkPlanModel.FabricGsm }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Total (GSM) : {{WorkPlanModel.TotalGsm }}" nzColon="true"></nz-descriptions-item>
      </nz-descriptions>
    </div>
  </div>
  <nz-divider></nz-divider>
  <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 20px">
    <div nz-col [nzSpan]="24">
      <nz-descriptions nzBordered nzTitle="Production Line Avg. Speed Range" nzLayout="vertical" [nzColumn]="5"
        style="box-shadow: 1px 1px 2px 2px #415994;text-align: center;">
        <nz-descriptions-item nzTitle="Minimum Speed" nzColon="true"><b>
            {{ WorkPlanModel.MinSpeed }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Maximum Speed " nzColon="true"><b>
            {{ WorkPlanModel.MaxSpeed }}</b></nz-descriptions-item>
      </nz-descriptions>
    </div>
  </div>
  </div>
  </ng-container>
</nz-drawer>