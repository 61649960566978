import { Component, OnInit, OnDestroy, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { Subject, interval, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ColorModel, GrainModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, ThicknessModel } from '../../Models/MasterModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { HttpClient } from '@angular/common/http';
import { DateTypeModel, ReceivedOrdersChartDataModel, ReceivedOrdersModel, ReceivedOrdersTableDataModel, SalesReportExportModel, SalesReportModel, SearchParamsReportModel, TopSellingProducts, TopSellingProductsTableData } from '../../Models/ReportModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { UserModel } from 'src/PmsUIApp/Models/UserModel';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser } from '@angular/common';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';

@Component({
    selector: 'app-SalesReport',
    templateUrl: './SalesReport.component.html',
    styleUrls: ['./SalesReport.component.css']
})

export class SalesReportComponent implements OnInit, OnDestroy {
    ApiUrl = environment.Api_Url;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    SalesReportList: SalesReportModel[] = [];
    SalesReportListOriginal: SalesReportModel[] = [];
    SalesReportExportList: SalesReportExportModel[] = [];
    fields: any;
    IsBtnVisible: boolean = false;
    permission = {
        View: false,
        Add: false,
        Delete: false
    }
    SelectedProductType: string = '';
    SelectedStatus: string = '';
    FilteredProductList: ProductModel[] = [];
    ProductList: ProductModel[] = [];
    CategoryID: number = 0;
    FirstCategoryID: number = 0;
    SecondCategoryID: number = 0;
    selectedProduct: number = 0;
    ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
    ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
    ProductCategoryList: ProductCategoryModel[] = [];
    FilteredProductCategoryList: ProductCategoryModel[] = [];
    exportoptions = {
        headers: [
            "Order Date",
            "Order No.",
            "Item Name",
            "P.Code",
            "Grain Name",
            "Colors",
            "Width",
            "Fabric",
            "Thickness",
            "Finish",
            "Order QTY",
            "Pre Skin (GSM)",
            "Skin (GSM)",
            "Foam (GSM)",
            "Adhesive (GSM)",
            "Fabric (GSM)",
            "Party name",
            "Remarks",
            "Production Status",
            "Order Status",
            "Created Date",
            "Created By"
        ]
    };
    count: number;
    isEmailReportEnabled: boolean = false;
    isEmailButtonLoading: boolean = false;
    enableCustomDateRange: boolean = false;
    enableWorkShiftDropDown: boolean = false;
    ProductTypeList: any = [
        {
            "Text": "GZ(PVC)",
            "Value": 'GZ-'
        },
        {
            "Text": "GZY(PU)",
            "Value": 'GZY-'
        }
    ];
    orderTypeList: any = [
        'Sample',
        'Trial',
        'Product',
        'Job Work'
    ];
    NumberOfRecordsList: any = [
        {
            "Text": "Top 5",
            "Value": "5"
        },
        {
            "Text": "Top 10",
            "Value": "10"
        },
        {
            "Text": "Top 20",
            "Value": "20"
        },
        {
            "Text": "Top 30",
            "Value": "30"
        },
        {
            "Text": "Top 40",
            "Value": "40"
        }
    ]
    ProductionLineList: any = [
        {
            "Text": "All",
            "Value": '0'
        },
        {
            "Text": "Line 1",
            "Value": '1'
        },
        {
            "Text": "Line 2",
            "Value": '2'
        }
    ];
    dateTypeList = [
        {
            "Text": "Received Date",
            "Value": 'addeddate'
        },
        {
            "Text": "Sale Order Date",
            "Value": 'saleorderdate'
        },
        {
            "Text": "Delivery Date",
            "Value": 'deliverydate'
        }];
    selectedDateType: DateTypeModel = this.dateTypeList[0];
    request = {
        DateFilterType: '',
        DateType: 'addeddate', // options are - addeddate, saleorderdate, deliverydate
        FromAddedDate: '',
        ToAddedDate: '',
        FromSaleOrderDate: '',
        ToSaleOrderDate: '',
        FromDeliveryDate: '',
        ToDeliveryDate: '',
        SaleFormulationCodeId: 0,
        SaleOrderNumber: null,
        Status: null,
        AddedBy: '',
        ArticleName: '',
        CustomerId: 0,
        GrainId: 0,
        ColorId: 0,
        ThicknessId: 0,
        ProductType: '', //options are - GZ- or GZY-
        OrderType: '',
    }
    topsellingrequest = {
        NumberOfRecords: 5,
        DateFilterType: '',
        DateFrom: '',
        DateTo: ''
    }
    dateFilterOptions: any = [
        {
            "Text": "Today",
            "Value": 'today'
        },
        {
            "Text": "Yesterday",
            "Value": 'yesterday'
        },
        {
            "Text": "Last 7 Days",
            "Value": 'last7days'
        },
        {
            "Text": "Last 30 Days",
            "Value": 'last30days'
        },
        {
            "Text": "Last Month",
            "Value": 'lastmonth'
        },
        {
            "Text": "Last Year",
            "Value": 'lastyear'
        },
        {
            "Text": "Custom Range",
            "Value": 'custom'
        }
    ];
    selecteddateFilter: string = 'today';
    previouslyselecteddateFilter: string = null;
    refreshInterval: number = 60; // Input for refresh interval in seconds
    autoRefreshSubscription: any;
    private unsubscribe$ = new Subject<void>();
    autoRefreshBtnText: string = 'Start Auto Refresh';
    totalItemsCount: number;
    CustomerList: CustomerModel[];
    FormulationCodeList: FormulationCodeModel[];
    ColorList: ColorModel[];
    GrainList: GrainModel[];
    ThicknessList: ThicknessModel[];
    UserList: UserModel[];
    private root: am5.Root;
    private root1: am5.Root;
    TopSellingProductList: TopSellingProducts[];
    TopSellingProductTableList: TopSellingProductsTableData[];
    activeParentTabName: string = 'salesreport';
    IsMsgShow: boolean = false;
    ReceivedOrdersList: ReceivedOrdersModel[];
    ReceivedOrdersChartDataList: ReceivedOrdersChartDataModel[];
    ReceivedOrdersTableDataList: ReceivedOrdersTableDataModel[];
    ReceivedOrdersRequest: SearchParamsReportModel = new SearchParamsReportModel;
    constructor(@Inject(PLATFORM_ID) private platformId: any, private zone: NgZone, private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService,
        private message: NzMessageService,) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.ReportsProductionPlanning, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsProductionPlanning, Responsibility.Add);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsProductionPlanning, Responsibility.Delete);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.getDateRange("today");
        this.selecteddateFilter = 'today';
        this.topsellingrequest.NumberOfRecords = this.NumberOfRecordsList[0].Value;
        //this.startAutoRefresh();
        this.GetSalesReport();
    }

    GetSalesReport() {
        if (!this.autoRefreshSubscription?.closed) {
            this.message.loading('Refreshing...', { nzDuration: 0 });
        }
        this.request.DateType = this.selectedDateType?.Value;
        this.isTableLoading = true; let url = this.ApiUrl + "report/salesreport";

        this.http.post<SalesReportModel[]>(url, this.request).subscribe(res => {
            this.message.remove();
            let count = this.totalItemsCount = 0;
            this.SalesReportListOriginal = this.SalesReportList = res; this.isTableLoading = false;
            this.SalesReportList.forEach((x) => {
                count++;
                x.SerialNo = count;
            })
            this.totalItemsCount = count;
            this.SalesReportExportList = [];
            this.SalesReportList.forEach((x) => {
                this.fields = new SalesReportExportModel();
                this.fields.OrderDate = x.OrderDate;
                this.fields.SaleOrderNo = x.SaleOrderNo;
                this.fields.ItemName = x.ItemName;
                this.fields.PCode = x.PCode;
                this.fields.Grain = x.Grain;
                this.fields.Colours = x.Colours;
                this.fields.Width = x.Width;
                this.fields.Fabric = x.Fabric;
                this.fields.Thick = x.Thick;
                this.fields.Finish = x.Finish;
                this.fields.OrderQty = x.OrderQty;
                this.fields.PreSkin = x.PreSkin;
                this.fields.Skin = x.Skin;
                this.fields.Foam = x.Foam;
                this.fields.Adhesive = x.Adhesive;
                this.fields.FabricGsm = x.FabricGsm;
                this.fields.PartyName = x.PartyName;
                this.fields.Remarks = x.Remarks;
                this.fields.Status = x.Status;
                this.fields.Status = x.Status;
                this.fields.OrderStatus = x.OrderStatus;
                this.fields.CreatedDate = new DatetimeConverter().transform(x.CreatedDate);
                this.fields.CreatedBy = x.CreatedBy;
                this.SalesReportExportList.push(this.fields);
            })
        }, () => {
            this.message.remove();
            this.count++;
            if (this.count < 2) { this.GetSalesReport(); }
        });
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    export() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        if (this.SalesReportExportList.length > 0)
            new AngularCsv(
                this.SalesReportExportList,
                'sales-report-export' + exportdate,
                this.exportoptions
            );
    }
    getDateRange(label: string) {
        this.enableCustomDateRange = false;
        this.enableWorkShiftDropDown = false;
        this.request.DateType = this.selectedDateType?.Value;
        this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = '';
        const today = new Date();
        let startDate: Date;
        let endDate: Date;

        switch (label) {
            case 'today':
                startDate = new Date(today);
                endDate = new Date(today);
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'fullday';
                this.enableWorkShiftDropDown = true;
                break;

            case 'yesterday':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                endDate = new Date(today);
                endDate.setDate(today.getDate() - 1);
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'fullday';
                this.enableWorkShiftDropDown = true;
                break;

            case 'last7days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 6);
                endDate = new Date(today);
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'fullday';
                this.enableWorkShiftDropDown = true;
                break;

            case 'last30days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 29);
                endDate = new Date(today);
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'fullday';
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastmonth':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'fullday';
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastyear':
                startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
                endDate = new Date(today);
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'fullday';
                this.enableWorkShiftDropDown = true;
                break;

            case 'custom':
                startDate = new Date(today);
                endDate = new Date(today);
                this.enableCustomDateRange = true;
                this.enableWorkShiftDropDown = true;
                this.request.DateFilterType = this.topsellingrequest.DateFilterType = this.ReceivedOrdersRequest.DateFilterType = 'datetime';
                break;

            default:
                startDate = new Date();
                endDate = new Date();
                break;
        }
        if (this.activeParentTabName == 'salesreport') {
            this.request.ToAddedDate = endDate.toISOString();
            this.request.FromAddedDate = startDate.toISOString();
            this.request.ToSaleOrderDate = endDate.toISOString();
            this.request.FromSaleOrderDate = startDate.toISOString();
            this.request.ToDeliveryDate = endDate.toISOString();
            this.request.FromDeliveryDate = startDate.toISOString();
            this.GetSalesReport();
        }
        if (this.activeParentTabName == 'topsellingproducts') {
            this.topsellingrequest.DateTo = endDate.toISOString();
            this.topsellingrequest.DateFrom = startDate.toISOString();
            this.GetTopSellingProducts();
        }
        if (this.activeParentTabName == 'receivedorders') {
            this.ReceivedOrdersRequest.DateTo = endDate.toISOString();
            this.ReceivedOrdersRequest.DateFrom = startDate.toISOString();
            this.GetReceivedOrders();

        }
    }

    setAutoRefreshTime(seconds: number) {
        this.refreshInterval = seconds;
        this.message.info('Refresh Interval set to ' + seconds + ' seconds')
        this.startAutoRefresh();
    }

    startAutoRefresh(): void {
        this.autoRefreshSubscription = interval(this.refreshInterval * 1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.GetSalesReport();
            });
    }

    setAutoRefreshAction(): void {
        if (!this.autoRefreshSubscription?.closed) {
            this.autoRefreshSubscription.unsubscribe();
            this.autoRefreshBtnText = 'Start Auto Refresh';
            this.message.info('Auto refresh stopped');
        }
        else {

            this.startAutoRefresh();
            this.message.info('Auto refresh started');
            this.autoRefreshBtnText = 'Stop Auto Refresh';
        }
    }
    GetAllCustomer() {
        this.loader.show();
        let url = this.ApiUrl + "customer/getallcustomers";
        this.http.get<CustomerModel[]>(url).subscribe({
            next: res => {
                this.CustomerList = res;
                this.loader.hide();
            },
            error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.GetAllCustomer(); }
            }
        });
    }
    GetAllFormulationCode() {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/getallformulationcodesforfilter";
        this.http.get<FormulationCodeModel[]>(url).subscribe({
            next: res => {
                this.FormulationCodeList = res;
                this.loader.hide();
            },
            error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.GetAllFormulationCode(); }
            }
        });
    }
    GetAllColor() {
        this.loader.show();
        let url = this.ApiUrl + "Color/getallColors";
        this.http.get<ColorModel[]>(url).subscribe({
            next: res => {
                this.ColorList = res;
                this.loader.hide();
            },
            error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) {
                    this.GetAllColor();
                }
            }
        });
    }
    GetAllGrain() {
        this.loader.show();
        let url = this.ApiUrl + "Grain/getallGrains";
        this.http.get<GrainModel[]>(url).subscribe({
            next: res => {
                this.GrainList = res;
                this.loader.hide();
            }, error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) {
                    this.GetAllGrain();
                }
            }
        });
    }
    GetAllThickness() {
        let url = this.ApiUrl + "thickness/getallthicknessdata";
        this.http.get<ThicknessModel[]>(url).subscribe(res => {
            this.ThicknessList = res;
        }, () => {
            this.count++
            if (this.count < 2) {
                this.GetAllThickness()
            }
        });
    }
    GetAllUsers() {
        this.loader.show();
        let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
        this.http.get<UserModel[]>(url).subscribe(
            (res) => {
                this.UserList = res;
                this.loader.hide();
            },
            () => {
                this.loader.hide();
                this.count++
                if (this.count < 2) {
                    this.GetAllUsers();
                }
            }
        );
    }
    space(el: any) {
        if (el.target.selectionStart === 0 && el.code === "Space") {
            el.preventDefault();
        }
    }
    onFilterPanelOpen(data: any) {
        if (data == true) {
            this.loader.show();
            this.GetAllCustomer();
            this.GetAllFormulationCode();
            this.GetAllColor();
            this.GetAllGrain();
            this.GetAllThickness();
            this.GetAllUsers()
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.browserOnly(() => {
            if (this.root) {
                this.root.dispose();
            }
            if (this.root1) {
                this.root1.dispose();
            }
        });
    }
    setActiveParentTabName(name: string) {
        this.activeParentTabName = name;
        if (this.selecteddateFilter == null && this.previouslyselecteddateFilter == null && this.activeParentTabName != 'receivedorders') {
            this.getDateRange("today");
        }
        else if (this.activeParentTabName == 'receivedorders') {
            this.previouslyselecteddateFilter = this.selecteddateFilter;
            this.ReceivedOrdersRequest.DataType = 'byorderqty';
            this.getDateRange("last30days");
            this.selecteddateFilter = 'last30days';

        }
        else if (this.previouslyselecteddateFilter != null) {
            this.selecteddateFilter = this.previouslyselecteddateFilter;
        }
        else {
            this.getDateRange(this.selecteddateFilter);
        }

    }
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    GetTopSellingProducts() {
        this.IsMsgShow = false;
        this.TopSellingProductList = [];
        this.isTableLoading = true; let url = this.ApiUrl + "report/gettopsellingproducts";

        this.http.post<TopSellingProducts[]>(url, this.topsellingrequest).subscribe({
            next: res => {
                let count = this.totalItemsCount = 0;
                this.TopSellingProductList = res;
                this.isTableLoading = false;
                this.TopSellingProductTableList = this.TopSellingProductList['TableData'];
                this.TopSellingProductTableList.forEach((x) => {
                    count++;
                    x.SerialNo = count;
                });

                if (this.TopSellingProductList['TableData'].length <= 0) {
                    this.IsMsgShow = true;
                }
                else {
                    this.TopSellingProductsChart();
                }

                this.totalItemsCount = count;
            }, error: res => {
                this.count++;
                if (this.count < 2) { this.GetTopSellingProducts(); }
            }
        });
    }

    TopSellingProductsChart() {
        this.browserOnly(() => {
            if (this.root) {
                this.root.dispose();
            }
            this.root = am5.Root.new("chartdiv");

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true
            }));

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
            cursor.lineY.set("visible", false);


            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let xRenderer = am5xy.AxisRendererX.new(this.root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
                rotation: -50,
                centerY: am5.p50,
                centerX: am5.p100,
                paddingRight: 12
            });

            xRenderer.grid.template.setAll({
                location: 1
            })

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
                maxDeviation: 0.3,
                categoryField: "XAxis",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(this.root, {})
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(this.root, {
                    strokeOpacity: 0.1
                })
            }));


            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            let series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "YAxis",
                sequencedInterpolation: true,
                categoryXField: "XAxis",
                tooltip: am5.Tooltip.new(this.root, {
                    labelText: "{valueY}"
                })
            }));

            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            xAxis.data.setAll(this.TopSellingProductList['ChartData']);
            series.data.setAll(this.TopSellingProductList['ChartData']);


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);
        });
    }

    GetReceivedOrders() {
        this.IsMsgShow = false;
        this.ReceivedOrdersList = [];
        this.ReceivedOrdersTableDataList = [];
        this.ReceivedOrdersChartDataList = [];
        this.isTableLoading = true; let url = this.ApiUrl + "report/getreceivedordersreport";

        this.http.post<ReceivedOrdersModel[]>(url, this.ReceivedOrdersRequest).subscribe({
            next: res => {
                console.log(res)
                let count = this.totalItemsCount = 0;
                this.ReceivedOrdersList = res;
                this.ReceivedOrdersChartDataList = this.ReceivedOrdersList['ChartData'];
                this.ReceivedOrdersTableDataList = this.ReceivedOrdersList['TableData']
                console.log(this.ReceivedOrdersList)
                console.log(this.ReceivedOrdersTableDataList)
                this.ReceivedOrdersChartDataList.forEach((x) => {
                    x.XAxis = new Date(x.XAxis).getTime();
                })
                this.ReceivedOrdersTableDataList.forEach((x) => {
                    count++;
                    x.SerialNo = count;
                })
                console.log(this.ReceivedOrdersList)
                console.log(this.ReceivedOrdersTableDataList)
                this.isTableLoading = false;

                if (this.ReceivedOrdersTableDataList.length <= 0) {
                    this.IsMsgShow = true;
                }
                else {
                    this.GetReceivedOrdersChart();
                }
                this.totalItemsCount = count;
            }, error: res => {
                this.count++;
                if (this.count < 2) { this.GetReceivedOrders(); }
            }
        });
    }

    GetReceivedOrdersChart() {
        if (this.root1) {
            this.root1.dispose();
        };
        this.root1 = am5.Root.new("receivedorderdiv");

        this.root1.setThemes([
            am5themes_Animated.new(this.root1)
        ]);

        let chart = this.root1.container.children.push(am5xy.XYChart.new(this.root1, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true
        }));
        let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root1, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);


        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(this.root1, {
            maxDeviation: 0.2,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(this.root1, {}),
            tooltip: am5.Tooltip.new(this.root1, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root1, {
            renderer: am5xy.AxisRendererY.new(this.root1, {
                pan: "zoom"
            })
        }));
        let series = chart.series.push(am5xy.LineSeries.new(this.root1, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "YAxis",
            valueXField: "XAxis",
            tooltip: am5.Tooltip.new(this.root1, {
                labelText: "{valueY}"
            })
        }));

        series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
        });

        chart.set("scrollbarX", am5.Scrollbar.new(this.root1, {
            orientation: "horizontal"
        }));

        series.data.setAll(this.ReceivedOrdersChartDataList);
        series.appear(1000);
        chart.appear(1000, 100);
    }

    CalculateGrandTotal(column: string) {
        var total = 0;
        switch (column) {
            case column = "receivedordersbyorderqty": {
                total = this.ReceivedOrdersTableDataList.reduce((sum, current) => sum + parseFloat(current.TotalOrderQty?.toString()), 0) ?? 0
                break;
            }
            case column = "receivedordersbynumberoforder": {
                total = this.ReceivedOrdersTableDataList.reduce((sum, current) => sum + parseFloat(current.TotalOrderQty?.toString()), 0) ?? 0
                break;
            }
        }
        return parseFloat(total.toFixed(2));

    }
    ResetTableChart() {
        this.ReceivedOrdersChartDataList = [];
        this.ReceivedOrdersTableDataList = [];
    }

}
