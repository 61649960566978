<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>{{JumboDispatchId ? "Edit Packaging Dispatch: " + JumboDispatch.PackingNumber + " |
                Customer Name: " + CustomerName:
                "Add Packaging Dispatch"}}</nz-page-header-title>
            <nz-page-header-extra>
                <button nz-button nzType="primary" style="margin-right: 5px;" *ngIf="this.JumboInspection.length>0"
                    (click)="modifyFilters()">Modify
                    Filter</button>
                <button nz-button nzType="primary" style="margin-right: 5px;" *ngIf="this.JumboInspection.length>0"
                    (click)="resetFilter()">Reset
                    Filter</button>
                <button nz-button nzType="primary" *ngIf="this.JumboDispatchId" (click)="showExistingRoll()">Show
                    Existing Rolls</button>
            </nz-page-header-extra>
        </nz-page-header>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="this.ShowFilters">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Sales Order No.</label>
                                <nz-select class="form-select " [(ngModel)]="NewFilter.SaleOrderId" nzShowSearch
                                    nzSize="default" name="SaleOrderId" nzAllowClear nzPlaceHolder="Sales Order">
                                    <nz-option *ngFor="let s of this.SalesOrderList;" [nzValue]="s.SaleOrderId"
                                        [nzLabel]="s.SaleOrderNumber"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Customer</label>
                                <nz-select class="form-select mb-2" [(ngModel)]="NewFilter.CustomerId" nzShowSearch
                                    name="CustomerId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                        [nzLabel]="s.CustomerName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Formulation Code</label>
                                <nz-select class="form-select mb-2" [(ngModel)]="NewFilter.SaleFormulationCodeId"
                                    nzShowSearch nzAllowClear name="SelectedFormulationCode" nzSize="default"
                                    nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.FormulationCodeList;"
                                        [nzValue]="s.SaleFormulationCodeId"
                                        [nzLabel]="s.SaleFormulationCode"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Grain Code</label>
                                <nz-select class="form-select mb-2" [(ngModel)]="NewFilter.GrainId" nzSize="default"
                                    nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                                        [nzLabel]="s.GrainCode"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Embossing</label>
                                <nz-select #microwaveRef class="form-select" [(ngModel)]="NewFilter.EmbossingMasterId"
                                    nzAllowClear nzShowSearch nzSize="default" ame="EmbossingMasterId"
                                    nzPlaceHolder="Select">
                                    <nz-option *ngFor="let s of this.EmbossingList;" [nzValue]="s.EmbossingMasterId"
                                        [nzLabel]="s.Name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Print</label>
                                <nz-select class="form-select" nzShowSearch [(ngModel)]="NewFilter.PrintMasterId"
                                    nzAllowClear nzSize="default" name="PrintMasterId" nzPlaceHolder="Select">
                                    <nz-option *ngFor="let s of this.PrintMasterList;" [nzValue]="s.PrintMasterId"
                                        [nzLabel]="s.Name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Lacquer</label>
                                <nz-select #microwaveRef class="form-select" [(ngModel)]="NewFilter.LacquerMasterId"
                                    nzShowSearch nzSize="default" name="Category" nzAllowClear nzPlaceHolder="Lacquer">
                                    <nz-option *ngFor="let s of this.LacquerList;" [nzValue]="s.LacquerMasterId"
                                        [nzLabel]="s.Name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Vaccum</label>
                                <nz-select #microwaveRef class="form-select " [(ngModel)]="NewFilter.VacuumMasterId"
                                    nzShowSearch nzSize="default" name="Category" nzAllowClear nzPlaceHolder="Vaccum">
                                    <nz-option *ngFor="let s of this.VaccumList;" [nzValue]="s.VacuumMasterId"
                                        [nzLabel]="s.Name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Tumbling</label>
                                <nz-select #microwaveRef class="form-select" [(ngModel)]="NewFilter.TumblingMasterId"
                                    nzShowSearch nzSize="default" name="TumblingMasterId" nzAllowClear
                                    nzPlaceHolder="Tumbling">
                                    <nz-option *ngFor="let s of this.TumblingList;" [nzValue]="s.TumblingMasterId"
                                        [nzLabel]="s.Name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Color</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewFilter.ColorId"
                                    nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                        [nzLabel]="s.ColorName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Fabric Color</label>
                                <nz-select class="form-select mb-2" nzSize="default"
                                    [(ngModel)]="NewFilter.FabricColorId" nzShowSearch nzAllowClear
                                    nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                        [nzLabel]="s.ColorName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Thickness</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewFilter.Thick"
                                    nzAllowClear nzShowSearch nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                                        [nzLabel]="s.ThicknessNumber"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Width</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewFilter.Width"
                                    nzAllowClear nzShowSearch nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.WidthList;" [nzValue]="s.WidthId"
                                        [nzLabel]="s.WidthNumber"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label> Order Date (From)</label>
                                <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-select "
                                    [(ngModel)]="NewFilter.OrderDateFrom" nzAllowClear></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label> Order Date (To)</label>
                                <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-select "
                                    [(ngModel)]="NewFilter.OrderDateTo" nzAllowClear></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Article</label>
                                <input nz-input name="Article" [(ngModel)]="NewFilter.Article" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Fabric</label>

                                <span nz-icon nzType="setting" nzTheme="outline"
                                    style="position: absolute; top: 4px; right: 0;cursor:pointer"
                                    (click)="showModal()"></span>
                                <nz-select nzShowSearch class="form-select " nzSize="default"
                                    [(ngModel)]="NewFilter.FabricProductId" name="ProductId" nzAllowClear
                                    nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                                        [nzLabel]="s.ProductName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Grade</label>
                                <nz-select class="form-select mb-2" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                                    [(ngModel)]="NewFilter.Grade">
                                    <nz-option nzValue="1st" nzLabel="1st"></nz-option>
                                    <nz-option nzValue="A" nzLabel="A"></nz-option>
                                    <nz-option nzValue="LOT" nzLabel="LOT"></nz-option>
                                    <nz-option nzValue="NS" nzLabel="NS"></nz-option>
                                    <nz-option nzValue="CUT-PC" nzLabel="CUT-PC"></nz-option>
                                    <nz-option nzValue="FILM" nzLabel="FILM"></nz-option>
                                    <nz-option nzValue="WASTE" nzLabel="WASTE"></nz-option>
                                    <nz-option nzValue="DIRECT" nzLabel="DIRECT"></nz-option>
                                    <nz-option nzValue="N/A" nzLabel="N/A"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetJumboInspectionList()">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="24">
            <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>


            <div nz-row style="margin: 0px 0px;" *ngIf="this.JumboInspection.length>0">
                <div nz-col [nzSpan]="24">
                    <h4 style="margin: 15px 0;">Jumbo Inspection - {{this.JumboInspection.length}} Records Found</h4>
                    <nz-table nzSize="small" *ngIf="this.JumboInspection.length>0" [nzPageSize]="20" style="width: 80%;"
                        #basicTable nzShowPagination="true" [nzData]="this.JumboInspection" nzBordered
                        nzShowSizeChanger>
                        <thead>
                            <tr>
                                <th nzWidth="50px"></th>
                                <th nzWidth="50px"> <input style="margin-right: 5px" type="checkbox"
                                        [checked]="IsCheckedAll" (change)="IsCheckedAll = !IsCheckedAll; CheckAll()" />
                                </th>
                                <th nzWidth="150px">Sale Order Code</th>
                                <th nzWidth="150px">Code
                                    <span nz-icon nzType="caret-up" nzTheme="outline"
                                        (click)="onUp('Code',basicTable.data)"></span>
                                    <span nz-icon nzType="caret-down" nzTheme="outline"
                                        (click)="onDown('Code',basicTable.data)"></span>
                                </th>
                                <th nzWidth="150px">Grade
                                    <span nz-icon nzType="caret-up" nzTheme="outline"
                                        (click)="onUp('Grade',basicTable.data)"></span>
                                    <span nz-icon nzType="caret-down" nzTheme="outline"
                                        (click)="onDown('Grade',basicTable.data)"></span>
                                </th>
                                <th nzWidth="120px">Quantity</th>
                                <th nzWidth="90px">Weight</th>
                                <th nzWidth="90px">Type
                                    <span nz-icon nzType="caret-up" nzTheme="outline"
                                        (click)="onUp('RollType',basicTable.data)"></span>
                                    <span nz-icon nzType="caret-down" nzTheme="outline"
                                        (click)="onDown('RollType',basicTable.data)"></span>
                                </th>
                                <th nzWidth="90px">Added By</th>
                                <th nzWidth="90px">Inspected Date</th>
                                <th nzWidth="50px" nzRight style="text-align: center">Sales Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of basicTable.data;let i = index">
                                <td>{{ data.SerialNo }}</td>
                                <td>
                                    <input style="margin-right: 5px" type="checkbox" [checked]="data.IsChecked"
                                        (change)="data.IsChecked = !data.IsChecked; CalculateDispatchQTY(); CalculateDispatchWeight(); CalculateDispatchGrade(); calculateUpdatedTotalQTY(); calculateUpdatedTotalWeight()" />

                                </td>
                                <td>{{ data.SaleOrderCode}}</td>
                                <td title='Created on {{data.AddedDate | DatetimeConverter }}'>{{ data.Code}}</td>
                                <td>{{ data.Grade}}</td>
                                <td>{{ data.Quantity}} {{data.Unit}}</td>
                                <td>{{ data.Weight}}</td>
                                <td>{{ data.RollType}}</td>
                                <td>{{ data.AddedBy}}</td>
                                <td>{{ data.AddedDate | DatetimeConverter}}</td>
                                <td nzRight style="text-align: center">
                                    <button class="btn btn-sm btn-light-danger"
                                        (click)="OpenViewPop(data)">View</button>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <h4 style="margin: 15px 0;" *ngIf="SaleOrder.SaleOrderProduction">Sales Order Details</h4>
                    <div>
                        <nz-divider nzText="Search Results"></nz-divider>
                        <nz-descriptions style="width: 80%;margin-bottom: 4px;" nzBordered nzLayout="vertical"
                            nzSize="small" [nzColumn]="6">
                            <nz-descriptions-item nzTitle="Total QTY">
                                {{sumquantity()}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="1st Grade QTY">
                                {{sum1stquantity()}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="A Grade QTY">
                                {{sumAquantity()}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Total Weight">
                                {{sumweight()}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="1st Grade Weight">
                                {{sum1stweight()}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="A Grade Weight">
                                {{sumAweight()}}
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <nz-divider *ngIf="this.SelectedDispatch.length>0" nzText="Selected Roll Details"></nz-divider>
                        <nz-descriptions style="width: 80%;margin-bottom: 4px;" nzBordered nzLayout="vertical"
                            nzSize="small" [nzColumn]="this.SelectedDispatch.length">
                            <nz-descriptions-item *ngFor="let data of this.SelectedDispatch;let i = index"
                                nzTitle="{{data.Grade}}(Count:{{data.Count}})">
                                {{data.Qty}}
                            </nz-descriptions-item>

                        </nz-descriptions>
                        <nz-divider nzText="Dispatch Details"></nz-divider>
                        <nz-descriptions style="width: 80%;" nzBordered nzLayout="vertical" nzSize="small"
                            [nzColumn]="5">
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Barcode">
                                <input type="text" nz-input [(ngModel)]="NewDispatch.Barcode" />
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Customer">
                                <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default"
                                    nzAllowClear nzPlaceHolder="Choose" [(ngModel)]="NewDispatch.CustomerId">
                                    <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                        [nzLabel]="s.CustomerName"></nz-option>
                                </nz-select>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Dispatch QTY">
                                {{NewDispatch.DispatchQuantity}}
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Store">
                                <nz-select nzShowSearch class="form-select mb-2"
                                    (ngModelChange)="onSelectedStoreChange($event)" [(ngModel)]="NewDispatch.StoreId"
                                    nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId"
                                        [nzLabel]="s.StoreName"></nz-option>
                                </nz-select>
                            </nz-descriptions-item>

                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Rack">
                                <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default" nzAllowClear
                                    [(ngModel)]="NewDispatch.RackId" nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId"
                                        [nzLabel]="s.RackName"></nz-option>
                                </nz-select>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Weight(KG)">
                                <input type="number" nz-input [(ngModel)]="NewDispatch.Weight"
                                    [value]="NewDispatch.Weight" />
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Transport">
                                <nz-select nzShowSearch class="form-select mb-2"
                                    (ngModelChange)="onSelectedTransportChange($event)"
                                    [(ngModel)]="NewDispatch.TransportId" nzSize="default" nzAllowClear
                                    nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                                        [nzLabel]="s.TransportCompanyName"></nz-option>
                                </nz-select>
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Vehicle">
                                <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default"
                                    (ngModelChange)="onSelectedVehicleChange($event)"
                                    [(ngModel)]="NewDispatch.VehicleId" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId"
                                        [nzLabel]="s.VehicleNumber"></nz-option>
                                    <nz-option nzValue="0" nzLabel="Others"></nz-option>
                                </nz-select>
                                <span style="color: crimson;"><b>{{this.VehicleStatus}}</b></span>
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Dispatch Number">
                                <input type="text" nz-input [(ngModel)]="NewDispatch.DispatchNumber" />
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Dispatch Date">
                                <nz-date-picker class="form-select" [(ngModel)]="NewDispatch.DispatchDate"
                                    nzValue="default"></nz-date-picker>
                            </nz-descriptions-item>
                            <nz-descriptions-item *ngIf="!this.JumboDispatchId" nzTitle="Remark">
                                <textarea nz-input name="remark" placeholder="Remark"
                                    style="height:80px; width:450px; margin-left: -4px;"
                                    [(ngModel)]="NewDispatch.Remark" [ngModelOptions]="{standalone: true}"></textarea>
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <p *ngIf="!this.JumboDispatchId" style="color: darkorange;">
                            <b>
                                Enter transport details for auto Gate-in the dispatch. You can leave the transport
                                details if its not
                                yet decided, you can later select the transport from Dispatch List page.
                            </b>
                        </p>
                        <nz-descriptions *ngIf="this.JumboDispatchId" style="width: 80%;margin-top: 30px;" nzBordered
                            nzLayout="vertical" nzSize="small" [nzColumn]="5">
                            <nz-descriptions-item nzTitle="Dispatch Number">
                                {{this.JumboDispatch.PackingNumber}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Existing Total Quantity">
                                {{this.JumboDispatch.DispatchQuantity}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Existing Total Weight">
                                {{this.JumboDispatch.Weight}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Updated Total Quantity">
                                {{this.calculateUpdatedTotalQTY()}}
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Updated Total Weight">
                                {{this.calculateUpdatedTotalWeight()}}
                            </nz-descriptions-item>
                        </nz-descriptions>
                        <nz-divider></nz-divider>
                        <div class="text-center">
                            <button nz-button nzType="primary" (click)="SaveDispatchItems()" [nzLoading]="isLoading">
                                Save and
                                Dispatch
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1000"
        nzTitle="Sales Order details" (nzOnClose)="handleCancel()">
        <ng-container *nzDrawerContent>
            <div *ngIf="Orderlist">
                <nz-divider nzText="Sales Order Status"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="2">
                    <nz-descriptions-item nzTitle="Current Status" [nzSpan]="1" nzColon="true">
                        <nz-badge nzStatus="processing"></nz-badge><b>{{ Orderlist.Status }}</b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="WorkPlan Number" [nzSpan]="2"><b>{{ Orderlist.WorkPlanNumber
                            }}</b></nz-descriptions-item>
                </nz-descriptions>
                <nz-divider nzText="Sales Order Details"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="6">
                    <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
                        <b>{{ Orderlist.SaleOrderType }}</b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="2"><b>{{ Orderlist.CustomerName
                            }}</b></nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1">
                        <b>
                            {{
                            Orderlist.SaleOrderNumber
                            }}
                        </b>
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Sale Order Date">
                        <b>
                            {{ Orderlist.SaleOrderDate | date: "dd-MMM-yyyy" }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Delivery Date">
                        <b>
                            {{ Orderlist.DeliveryDate | date: "dd-MMM-yyyy" }}
                        </b>
                    </nz-descriptions-item>
                </nz-descriptions>

                <nz-divider nzText="Production Details"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist.SaleOrderProduction" nzSize="small"
                    [nzColumn]="4">
                    <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true">
                        <b>
                            {{
                            Orderlist.SaleFormulationCode
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ Orderlist.Category
                            }}</b></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Thick">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ThicknessValue
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Width">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.WidthNumber
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ManufacturingProductName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Order Quantity">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.OrderQuantity
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Sale Price">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.SalePrice
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Total Sale Price">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.TotalSalePrice
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Grain Code">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.GrainCode
                            }}
                        </b>
                        <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                            nzTooltipTitle="Grain Name: {{Orderlist.SaleOrderProduction.GrainName}}"
                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                        </span>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Color">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ColorName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Fabric Color">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.FabricColorName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Fabric Name">
                        <b>
                            {{
                            Orderlist.FormulationFabricProductName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Manufactured QTY">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ManufacturingQuantity
                            }}
                        </b>
                    </nz-descriptions-item>
                </nz-descriptions>

                <!-- process details  -->
                <nz-divider nzText="Post Process"></nz-divider>
                <!-- print  -->
                <div *ngFor="let p of Orderlist.SaleOrderPostProcessOrder"
                    style="text-align: center;padding: 5px;border: 1px solid #ccc;border-radius: 4px;margin-top: 10px;background: #fff;box-shadow: 1px 1px 2px 2px #415994;          ">
                    <div style="padding: 10px 0; text-transform: uppercase">{{p.PostProcessName}}</div>
                    <nz-table *ngIf="p.PostProcessName=='Print'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                        nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzWidth="50px">S.No</th>
                                <th>Name</th>
                                <th nzWidth="160px">Code</th>
                                <th nzWidth="40px">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionPrint;
                let i = index
              ">
                                <td nzWidth="20px">{{ i + 1 }}</td>
                                <td>{{ data.Name }}</td>
                                <td>{{ data.Code }}</td>
                                <td style="text-align:center">
                                    <div *ngIf="data.Removed == true">
                                        <div class="badge badge-light-danger">InActive</div>
                                        <span nz-icon
                                            nzTooltipTitle="Removed by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                        </span>
                                    </div>
                                    <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <nz-table *ngIf="p.PostProcessName=='Embossing'" nzSize="small" [nzData]="['']" #basicTable1
                        nzBordered nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzWidth="50px">S.No</th>
                                <th>Name</th>
                                <th nzWidth="160px">Code</th>
                                <th nzWidth="40px">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionEmbossing;
                let i = index
              ">
                                <td nzWidth="20px">{{ i + 1 }}</td>
                                <td>{{ data.Name }}</td>
                                <td>{{ data.Code }}</td>
                                <td style="text-align:center">
                                    <div *ngIf="data.Removed == true">
                                        <div class="badge badge-light-danger">InActive</div>
                                        <span nz-icon
                                            nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                        </span>
                                    </div>
                                    <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <nz-table *ngIf="p.PostProcessName=='Vaccum'" nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                        nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzWidth="50px">S.No</th>
                                <th>Name</th>
                                <th nzWidth="160px">Code</th>
                                <th nzWidth="40px">Status</th>
                                <!--<th nzWidth="100px">Price</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionVacuum;
                let i = index
              ">
                                <td nzWidth="20px">{{ i + 1 }}</td>
                                <td>{{ data.Name }}</td>
                                <td>{{ data.Code }}</td>
                                <td style="text-align:center">
                                    <div *ngIf="data.Removed == true">
                                        <div class="badge badge-light-danger">InActive</div>
                                        <span nz-icon
                                            nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                        </span>
                                    </div>
                                    <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
                                </td>
                                <!--<td>{{ data.Price }}</td>-->
                            </tr>
                        </tbody>
                    </nz-table>
                    <nz-table *ngIf="p.PostProcessName=='Tumbling'" nzSize="small" [nzData]="['']" #basicTable1
                        nzBordered nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzWidth="50px">S.No</th>
                                <th>Name</th>
                                <th nzWidth="160px">Code</th>
                                <th nzWidth="40px">Status</th>
                                <!--<th nzWidth="100px">Price</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction
                  ?.SaleOrderProductionTumbling;
                let i = index
              ">
                                <td nzWidth="20px">{{ i + 1 }}</td>
                                <td>{{ data.Name }}</td>
                                <td>{{ data.Code }}</td>
                                <td style="text-align:center">
                                    <div *ngIf="data.Removed == true">
                                        <div class="badge badge-light-danger">InActive</div>
                                        <span nz-icon
                                            nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                        </span>
                                    </div>
                                    <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
                                </td>
                                <!--<td>{{ data.Price }}</td>-->
                            </tr>
                        </tbody>
                    </nz-table>
                    <nz-table *ngIf="p.PostProcessName=='Lacquar'" nzSize="small" [nzData]="['']" #basicTable1
                        nzBordered nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzWidth="50px">S.No</th>
                                <th>Name</th>
                                <th nzWidth="160px">Code</th>
                                <th nzWidth="40px">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                let data of Orderlist?.SaleOrderProduction?.Lacquer;
                let i = index
              ">
                                <td nzWidth="20px">{{ i + 1 }}</td>
                                <td>{{ data.Name }}</td>
                                <td>{{ data.Code }}</td>
                                <td style="text-align:center">
                                    <div *ngIf="data.Removed == true">
                                        <div class="badge badge-light-danger">InActive</div>
                                        <span nz-icon
                                            nzTooltipTitle="Skipped by {{data.RemovedBy}} on {{data.RemovedDate | DatetimeConverter}}"
                                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                        </span>
                                    </div>
                                    <div class="badge badge-light-success" *ngIf="data.Removed != true">Active</div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>

            </div>
            <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
                        <div nz-col [nzSpan]="24">
                            <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="5"
                                style="box-shadow: 1px 1px 2px 2px #415994">
                                <nz-descriptions-item nzTitle="Pre Skin" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.PreSkinGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Skin" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.SkinGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Foam" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.FoamGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Adhesive" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.AdhesiveGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.FabricGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Remarks" nzColon="true">
                                    <b>
                                        {{
                                        Orderlist.Remarks
                                        }}
                                    </b>
                                </nz-descriptions-item>
                            </nz-descriptions>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </nz-drawer>


    <nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isPopupVisible" [nzTitle]="modalTitle"
        [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handlePopupCancel()">
        <ng-template #modalTitle>Search Product</ng-template>

        <ng-template #modalContent>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Category</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Category">
                    <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                    <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="productItem.CategoryID"
                        name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                        (ngModelChange)="GetAllFirstCategory($event)">
                        <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                            [nzLabel]="s.ProductCategory"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Sub Category</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Product First Category">
                    <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                    <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="productItem.FirstCategoryID"
                        name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                        (ngModelChange)="GetAllSecondCategory($event)">
                        <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                            [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">2nd Sub Category</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Second Category">
                    <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
                    <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="productItem.SecondCategoryID"
                        name="ProductSecSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.ProductSecSubCategoryList;"
                            [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </ng-template>
        <ng-template #modalFooter>
            <div class="text-center">
                <a nz-button nzType="primary" (click)="handlePopupCancel()">Search</a>
            </div>
        </ng-template>

    </nz-modal>

    <nz-modal [(nzVisible)]="isExistingRollVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="950"
        [nzTitle]="modalTitleDispatch" [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch"
        (nzOnCancel)="handleDispatchCancel()">
        <ng-template #modalTitleDispatch>Existing Rolls</ng-template>

        <ng-template #modalContentDispatch>
            <nz-table nzSize="small" *ngIf="this.ExistingJumboInspection.length>0" [nzPageSize]="10"
                style="width: 100%;" #basicTable nzShowPagination="true" [nzData]="ExistingJumboInspection" nzBordered>
                <thead>
                    <tr>
                        <th nzWidth="50px"></th>
                        <th nzWidth="150px">Code</th>
                        <th nzWidth="150px">Grade</th>
                        <th nzWidth="150px">Quantity</th>
                        <th nzWidth="150px">Weight</th>
                        <th nzWidth="150px">Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data;let i = index">
                        <td>{{i+1}}</td>
                        <td title='Created on {{data.AddedDate | DatetimeConverter }}'>{{ data.Code}}</td>
                        <td>{{ data.Grade}}</td>
                        <td>{{ data.Quantity}}</td>
                        <td>{{ data.Weight}}</td>
                        <td>{{ data.RollType}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </ng-template>

        <ng-template #modalFooterDispatch>

            <button nz-button nzType="default" (click)="handleDispatchCancel()">Cancel</button>
        </ng-template>
    </nz-modal>