<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Stock Consumption Report</nz-page-header-title>
      <nz-page-header-extra>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="this.filterPanelTxt" [nzActive]="false"
        (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Date Filters</label>
                  <nz-select [(ngModel)]="selecteddateFilter" (ngModelChange)="getDateRange(selecteddateFilter)"
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="this.enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label"> Consumption Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.ConsumedDateFrom" nzAllowClear></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="this.enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label"> Consumption Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ConsumedDateTo" nzAllowClear></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Type</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                    (ngModelChange)="onSelectedProductTypeChange()" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                    <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                    <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Category</label>

                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID"
                    name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                    <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                      [nzLabel]="s.ProductCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Sub Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Sub Category"
                    (ngModelChange)="GetAllSecondCategory($event)">
                    <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                      [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">2nd Sub Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                    nzPlaceHolder="Second Sub Category" (ngModelChange)="GetSecondCategoryFilteredProduct()">
                    <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                      [nzLabel]="s.ProductSecSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Name</label>
                  <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="SelectedProductId"
                    name="ProductId" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                      [nzLabel]="s.ProductName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Measure Unit</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedUnit"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Supplier</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.SupplierId"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                      [nzLabel]="s.SupplierName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Store </label>
                  <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()"
                    [(ngModel)]="StoreID" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId"
                      [nzLabel]="s.StoreName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Rack</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="RackID" nzAllowClear
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Consumed By</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.Consumedby"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email" [nzLabel]="s.Name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Sale Order No.</label>
                  <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full Order No" maxlength="30"
                    [(ngModel)]="request.SaleOrderNo" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Batch No.</label>
                  <input nz-input name="BatchNo" nzAllowClear placeholder="Enter Full Batch No." maxlength="30"
                    [(ngModel)]="request.BatchNo" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Summary Options:</label>
                  <nz-checkbox-group [(ngModel)]="filterSummaryOptions"
                    (ngModelChange)="adjustFilterSummaryOptions($event)"></nz-checkbox-group>
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>
        </div>
        <div style="float: right; margin-bottom: 2%; margin-right: 10px">
          <div style="display: flex">
            <button nz-button nzType="primary" (click)="GetAllConsumption()">
              Apply Filters
            </button>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:left" *ngIf="this.ConsumptionList.length>0 && this.isByShowAllChecked">
      <b>
        <p>Stock Consumed for Total Number of Sale Orders: {{this.TotalConsumedSaleOrderCount}}</p>
        <p>Total Number of Unique Products Consumed: {{this.TotalUniqueProductsConsumedCount}}</p>
      </b>
    </div>
    <div style="padding: 8px;float:right" *ngIf="this.ConsumptionList.length>0">
      <button *ngIf="this.CountOfSelectedSummaryFilters < 2" nz-button nzSize="small" (click)="export()"><i nz-icon
          nzType="export" nzTheme="outline"></i>
        Export</button>
      <p *ngIf="this.CountOfSelectedSummaryFilters > 1">Export is allowed with only one Summary Filter.</p>
    </div>
    <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
    <nz-table *ngIf="this.ConsumptionList.length>0" [nzPageSize]="10" nzSize="small"
      [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable [nzData]="this.ConsumptionList"
      [nzLoading]="isTableLoading" nzBordered [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate"
      nzShowQuickJumper="true" nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="60px" nzLeft>S.No.</th>
          <th nzWidth="200px" nzLeft>Product</th>
          <th nzWidth="150px" *ngIf="isByShowAllChecked || isByBatchNoChecked">Batch No</th>
          <th nzWidth="150px" *ngIf="isByShowAllChecked">Sale Order </th>
          <th nzWidth="100px">Quantity</th>
          <th nzWidth="100px" *ngIf="isByShowAllChecked || isByBatchNoChecked">Price / Unit</th>
          <th nzWidth="100px" *ngIf="isByShowAllChecked">Damaged</th>
          <th nzWidth="150px" *ngIf="isByShowAllChecked || isByStoreChecked || isByStoreRackChecked">Store</th>
          <th nzWidth="150px" *ngIf="isByShowAllChecked || isByStoreRackChecked">Rack</th>
          <th nzWidth="150px" *ngIf="isByShowAllChecked">Consumed Date Time</th>
          <th nzWidth="150px" *ngIf="!isByShowAllChecked">Consumed Date</th>
          <th nzWidth="200px" *ngIf="isByShowAllChecked">Purpose/Reason</th>
          <th nzWidth="200px" *ngIf="isByShowAllChecked">Consumed By</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft>{{ data.SerialNo }}</td>
          <td nzLeft>{{ data.ProductName }}</td>
          <td *ngIf="isByShowAllChecked || isByBatchNoChecked">{{ data.BatchNo }}</td>
          <td *ngIf="isByShowAllChecked">{{ data.SaleOrderNumber }}</td>
          <td>{{ data.Quantity }} {{ data.Unit }}</td>
          <td *ngIf="isByShowAllChecked || isByBatchNoChecked">&#8377;{{ data.PricePerUnit }} / {{ data.Unit }}</td>
          <td *ngIf="isByShowAllChecked">
            <span *ngIf=" data.IsDamaged==true">Yes</span>
            <span *ngIf="data.IsDamaged != true">No</span>
          </td>
          <td *ngIf="isByShowAllChecked || isByStoreChecked || isByStoreRackChecked">{{ data.StoreName }}</td>
          <td *ngIf="isByShowAllChecked || isByStoreRackChecked">{{ data.RackName }}</td>
          <td *ngIf="isByShowAllChecked">{{ data.ConsumedDate | DatetimeConverter }}</td>
          <td *ngIf="!isByShowAllChecked">{{ data.ConsumedDate | date:'dd-MM-yyyy' }}</td>
          <td *ngIf="isByShowAllChecked">{{ data.Purpose }}</td>
          <td *ngIf="isByShowAllChecked">{{ data.AddedBy }}</td>
        </tr>
      </tbody>
    </nz-table>




  </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>