import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { Modules } from '../../Models/Enums';

import { AdminStoreModel, StoreModel } from '../../Models/MasterModel';
import { UserRoleModel, UserStoreMappingModel } from '../../Models/UserModel';
import { MoudleResponsibilityModel, ResponsibilityMasterModel, UserRoleMasterModel, UserRoleResponsibilityMappingModel } from '../../Models/UserRoleModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';
import { LoadingService } from '../../Services/loadingService';

@Component({
  selector: 'app-RolesMaster',
  templateUrl: './RolesMaster.component.html',
  styleUrls: ['./RolesMaster.component.css']
})
export class RolesMasterComponent {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  UserRole: UserRoleMasterModel = new UserRoleMasterModel();
  UserRoleList: UserRoleMasterModel[] = [];
  UserRoleListOriginal: UserRoleMasterModel[] = [];
  isLoading: boolean = false;
  isStoreLoading: boolean = false;
  isVisible = false;
  isVisibleRole = false;
  isStoreVisible = false;
  isUserVisible = false;
  isNew = true;
  isTableLoading: boolean = false;
  StoreList: AdminStoreModel[] = [];
  MoudleResponsibilityList: MoudleResponsibilityModel[] = [];
  ModuleResponsibilityListOriginal: MoudleResponsibilityModel[] = [];
  allChecked = false;
  IsAuth = false;
  searchValue = '';
  count: 0;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private msalService: MsalService,
    public http: HttpClient,
    public loader: LoadingService

  ) { }


  GetAllUserRoles() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "userrolesresponsibility/getalluserroles";
    this.http.get<any>(url).subscribe(
      (res) => {
        this.UserRoleList = res;
        this.UserRoleListOriginal = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllUserRoles();
        }
      }
    );
  }
  GetAllResponsibility() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "userrolesresponsibility/getallresponsibility";
    this.http.get<ResponsibilityMasterModel[]>(url).subscribe(
      (res) => {
        let uniqueModule = [...new Set(res.map(item => item.Module))];

        uniqueModule.forEach(el => {
          var item = new MoudleResponsibilityModel;
          var resp: ResponsibilityMasterModel[] = [];
          item.Module = el;

          item.Responsibilities = res.filter(x => x.Module == el);
          this.MoudleResponsibilityList.push(item);
        })
        console.log(this.MoudleResponsibilityList);
        this.ModuleResponsibilityListOriginal = this.MoudleResponsibilityList;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllResponsibility();
        }
      }
    );
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  search(): void {

    var res = this.UserRoleListOriginal;
    this.UserRoleList = res.filter((item: UserRoleMasterModel) => {
      if (
        item?.UserRoleName?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;

      } else if (
        item?.UserRoleDescripton?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else
        return false;
    });
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  GetMyRole(UserName: string) {
    //UserName = 'amit@damasinfo.com';
    this.loader.show();
    let url = this.ApiUrl + 'User/getuserrole/' + UserName;
    this.http.get<any>(url).subscribe(
      (res) => {
        if (res != null) {
          UserInfo.UserRoles = res;
          this.IsAuth = UserInfo.UserRoles.Admin;
          this.loader.hide();
        }
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetMyRole(UserInfo.EmailID);
        }
      }
    );
  }

  ngOnInit() {
    this.IsAuth = this.authService.CheckModule(Modules.AdminRoles);
    //this.GetMyRole(UserInfo.EmailID);
    this.GetAllUserRoles();
    this.GetAllResponsibility()
    //this.validateForm = this.fb.group({
    //  Username: [null, [Validators.required]],
    //});
  }
  get f() {
    return this.validateForm.controls;
  }
  showModal() {
    this.UserRole = new UserRoleMasterModel;
    this.isNew = true;
    this.isVisibleRole = true;

  }
  OpenEditPop(data: UserRoleMasterModel) {
    this.isNew = false;
    this.UserRole = data;

    this.isVisibleRole = true;
  }
  OpenOperationsDrawer(data: UserRoleMasterModel) {

    this.isNew = false;
    this.UserRole = data;
    this.MoudleResponsibilityList = this.ModuleResponsibilityListOriginal;
    var res = data.Responsibilities.map(x => x.ResponsibilityId);
    this.MoudleResponsibilityList.forEach(el => {
      el.Responsibilities.forEach(x => {

        if (res.includes(x.ResponsibilityId)) {
          x.IsChecked = true
        } else {
          x.IsChecked = false
        }
      })
      if (el.Responsibilities.filter(x => x.IsChecked == true).length == el.Responsibilities.length && el.Responsibilities.filter(x => x.IsChecked = true).length != 0) {
        el.IsChecked = true;
      } else {
        el.IsChecked = false;
      }
    })
    this.MoudleResponsibilityList.sort((a, b) => {
      // Compare the number of checked responsibilities in 'a' and 'b'
      const checkedResponsibilitiesCountA = a.Responsibilities.filter(x => x.IsChecked).length;
      const checkedResponsibilitiesCountB = b.Responsibilities.filter(x => x.IsChecked).length;

      // Sort in descending order based on the checked responsibilities count
      return checkedResponsibilitiesCountB - checkedResponsibilitiesCountA;
    });
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
    this.isStoreVisible = false;
  }
  handleCancelRole(): void {
    this.isVisibleRole = false;

  }
  SaveRole() {
    console.log("userinfo", UserInfo.UserRolesMaster)
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName == "Super Admin").length <= 0) {
      this.alertService.error("You are not authorized. Only Super Admin can make this change.");
      return;
    }
    this.isLoading = true;
    this.UserRole.AddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + "userrolesresponsibility/addupdateuserrole";
    this.http.post<any>(url, this.UserRole).subscribe({

      next: res => { this.alertService.success("Role Saved Successfully"); this.isLoading = this.isVisibleRole = false; this.GetAllUserRoles(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisibleRole = false; },

    });
  }
  Save() {
    console.log("userinfo", UserInfo.UserRolesMaster)
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName == "Super Admin").length <= 0) {
      this.alertService.error("You are not authorized. Only Super Admin can make this change.");
      return;
    }
    this.UserRole.AddedBy = UserInfo.EmailID;
    var Request: UserRoleResponsibilityMappingModel[] = [];

    this.MoudleResponsibilityList.forEach(el => {
      el.Responsibilities.forEach(e => {
        var m: UserRoleResponsibilityMappingModel = new UserRoleResponsibilityMappingModel;
        m.AddedBy = UserInfo.EmailID;
        m.ResponsibilityId = e.ResponsibilityId;
        m.UserRoleId = this.UserRole.UserRoleId;
        if (e.IsChecked) {
          Request.push(m);
        }
      })
    })

    if (Request.length > 0) {
      let url = this.ApiUrl + "userrolesresponsibility/addupdateroleresposibilitymapping";
      this.http.post<any>(url, Request).subscribe({

        next: res => { this.alertService.success("Role Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllUserRoles(); },
        error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

      });
    }
  }

  OnParantCheckChange(data: MoudleResponsibilityModel) {
    data.Responsibilities.forEach(el => {
      el.IsChecked = data.IsChecked
    })
  }
  OnChildCheckChange(data: MoudleResponsibilityModel, res: ResponsibilityMasterModel) {
    if (!res.IsChecked) {
      data.IsChecked = false;
    } else {
      if (data.Responsibilities.filter(el => el.IsChecked == false).length == 0) {
        data.IsChecked = true;
      }
    }
  }
  ShowAssignedUsersPopup(data: UserRoleMasterModel) {
    this.UserRole = data;
    this.isUserVisible = true;
  }
  handleCancelAssignedUsers(): void {
    this.isUserVisible = false;
  }
}
