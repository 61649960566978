<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Final Inspection Details</nz-page-header-title>
        </nz-page-header>
        <nz-divider></nz-divider>

        <nz-collapse>
            <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="4">
                        <label>Sale Order No.</label>
                        <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
                            [(ngModel)]="request.SaleOrderNumber" />
                    </div>
                    <div nz-col [nzSpan]="4">
                        <label>Jumbo No.</label>
                        <input nz-input name="jumboNo" nzAllowClear placeholder="Enter Jumbo Number" maxlength="30"
                            [(ngModel)]="request.JumboNumber" />
                    </div>
                    <div nz-col [nzSpan]="4">
                        <label>Customer</label>
                        <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                            [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                            <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                [nzLabel]="s.CustomerName"></nz-option>
                        </nz-select>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <label>Formulation Code</label>
                        <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                            nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" nzPlaceHolder="Choose">
                            <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                                [nzLabel]="s.SaleFormulationCode"></nz-option>
                        </nz-select>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Color</label>
                                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                                    nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                        [nzLabel]="s.ColorName"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Grain Code</label>
                                <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default"
                                    nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                                        [nzLabel]="s.GrainCode"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <div nz-row [nzGutter]="24" style="margin: 20px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>From</label>
                                <nz-date-picker [(ngModel)]="request.FromDate" nzPlaceHolder="Date"
                                    style="width: 150px;margin-right: 8px;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="3">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>To</label>
                                <nz-date-picker [(ngModel)]="request.ToDate" nzPlaceHolder="Date"
                                    style="width: 150px;margin-right: 8px;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Status</label>
                                <nz-radio-group style="margin-top: 7px;" [(ngModel)]="request.IsInspectionCompleted"
                                    (ngModelChange)="this.finalInspectionList = []">
                                    <label nz-radio nzValue="true">Inspected</label>
                                    <label nz-radio nzValue="false">Not Inspected</label>
                                </nz-radio-group>

                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetFinalInspectionList()">
                            Apply Filter
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 8px;float:right">
            <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"
                    style="top: 2px;right: 48px;position: absolute;"></i> Export</button>
        </div>
        <nz-divider></nz-divider>
        <div style="max-width:100%; overflow:scroll">
            <nz-table id="print-section" nzSize="small" style="width: 100%" #basicTable [nzShowPagination]="true"
                [nzPageSize]="25" [nzData]="this.finalInspectionList" [nzLoading]="isTableLoading" nzBordered>
                <thead>
                    <tr style="text-align: left;">
                        <th>S. No.</th>
                        <th>Jumbo Number</th>
                        <th>WorkPlan Number</th>
                        <th>Sales Order Number</th>
                        <th>Sale Order Status</th>
                        <th>Jumbo Inspection Status</th>
                        <th *ngIf="request.IsInspectionCompleted == 'true'">Inspected QTY</th>
                        <th>Jumbo Roll QTY</th>
                        <th>Customer Name</th>
                        <th>Jumbo Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data; let i = index">
                        <td>{{ data.SerialNo }}</td>
                        <td>{{ data.JumboNo }}</td>
                        <td>{{ data.WorkPlanNo }}</td>
                        <td><nz-tag [nzColor]="'blue'"><a (click)="OpenViewPop(data)"> {{ data.SaleOrderNumber}}
                                </a></nz-tag></td>
                        <td>{{ data.SaleOrderStatus }}</td>
                        <td>{{ GetJumboInspectionStatus(data) }}</td>
                        <td *ngIf="request.IsInspectionCompleted == 'true'"><a class="btn-link"
                                (click)="showDispatchModal(data)">{{ sumquantity(data) }}</a></td>
                        <td>{{ data.JumboRolQty }}</td>
                        <td>{{ data.CustomerName }} </td>
                        <td>{{ data.AddedDate | DatetimeConverter }}</td>
                        <td>
                            <button class="btn btn-sm btn-light-primary" (click)="handleSave(data)"
                                *ngIf="enableReOpenButton(data)">
                                Reopen
                            </button>
                            <button (click)="GotoInspection(data.JumboNo)" class="btn btn-sm btn-light-primary"
                                *ngIf="!data.IsInspectionCompleted">
                                Inspection
                            </button>
                        </td>
                    </tr>

                </tbody>

            </nz-table>

        </div>
    </div>
</div>


<nz-modal [(nzVisible)]="isDispatchVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="1300" [nzTitle]="modalTitleDispatch"
    [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch" (nzOnCancel)="handleDispatchCancel()">
    <ng-template #modalTitleDispatch>Jumbo Dispatch</ng-template>

    <ng-template #modalContentDispatch>
        <nz-table nzSize="small" *ngIf="this.JumboInspection.length>0" [nzPageSize]="10" style="width: 100%;"
            #basicTable nzShowPagination="true" [nzData]="JumboInspection" nzBordered>
            <thead>
                <tr>
                    <th nzWidth="50px"></th>
                    <th nzWidth="120px">Code</th>
                    <th nzWidth="90px">Grade</th>
                    <th nzWidth="90px">Quantity</th>
                    <th nzWidth="90px">Weight</th>
                    <th nzWidth="90px">Unit</th>
                    <th nzWidth="90px">Type</th>
                    <th nzWidth="100px">Inspected By</th>
                    <th nzWidth="150px">Submitted By</th>
                    <th nzWidth="130px">Inspected Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.SerialNo }}</td>
                    <td title='Created on {{data.AddedDate | DatetimeConverter }}'>{{ data.Code}}</td>
                    <td>{{ data.Grade}}</td>
                    <td>{{ data.Quantity}}</td>
                    <td>{{ data.Weight}}</td>
                    <td>{{ data.Unit}}</td>
                    <td>{{ data.RollType}}</td>
                    <td>{{ data.InspectedBy}}</td>
                    <td>{{ data.AddedBy}}</td>
                    <td>{{ data.AddedDate | DatetimeConverter}}</td>
                </tr>
            </tbody>
        </nz-table>
        <p><b>Yield %: {{this.calculateYield()}}</b></p>
    </ng-template>

    <ng-template #modalFooterDispatch>

        <button nz-button nzType="default" (click)="handleDispatchCancel()">Cancel</button>
    </ng-template>
</nz-modal>

<app-SoDrawer></app-SoDrawer>