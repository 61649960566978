
export class ProcessModel {
  ProcessId: number= 0;
  ProcessName: string= '';
  Temperature: number= 0; 
  WeightPressure: number= 0; 
  WeightGsm: number= 0; 
  AddedBy: string= '';
  AddedDate: string= '';
  ProcessRawMaterial: ProcessRawMaterialModel[]=[];
}


export class ProcessRawMaterialModel {
  ProcessRawMaterialId: number= 0;
  ProcessId: number= 0;
  ProductId: number = 0;
  ProductCode: string = '';
  ProductName: string= '';
  Quantity: number = 0;
  Price: number = 0;
  Unit: string = '';
  AvgGsm: string = '';
}

export class ProcessPrintModel {
  ProcessName: string = '';
  Temperature: number = 0;
  WeightPressure: number = 0;
  WeightGsm: number = 0;
  ProcessRawMaterial: number = 0;
  AddedDate: string = '';
  AddedBy: string = '';
  
}
