import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { StoreModel, RackModel, WidthMasterModel } from '../../../Models/MasterModel';
import { TransportModel, TransportVehicleModel } from '../../../Models/TransportModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserInfo } from '../../../Authentication/UserInfo';
import { LoadingService } from '../../../Services/loadingService';
import { WorkPlanJumboMasterModel, WorkPlanMasterModel, WorkPlanOrderModel, JumboInspectionModel } from '../../../Models/WorkPlanModel';
import { SaleOrderDispatchReadyModel, SaleOrderModel, SaleOrderProductionRawMaterialModel } from '../../../Models/SalesOrderModel';
import { IssueProductModel } from '../../../Models/IssueModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'aap-FinalInspection',
  templateUrl: './FinalInspection.component.html',
  styleUrls: ['./FinalInspection.component.css']
})
export class FinalInspectionComponent implements OnInit {
  isValid = true
  isShow = false
  searchValue: any;
  Type = 'Production';
  SaleOrderNumber = "";
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SelectedOrderNo: string = '';
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  NewPOList: IssueProductModel[] = [];
  SaleOrderDispatchItems: any[] = [];
  SaleOrder!: SaleOrderModel;
  RackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreId: number = 0
  DispatchReadyBarcode: string = '';
  DispatchNumber: string = '';
  DispatchDate: string = '';
  PacketNumber: number = 0;
  sumPacketNumber: number = 0;
  DispatchReadyRack: number = 0;
  DispatchReadyQuantity: number = 0;
  TransportId: number = 0;
  VehicleId: number = 0;
  isLoading: boolean = false;
  isLoadingRemove: boolean = false;
  isDispatchedReady: boolean = false;
  TransportList: TransportModel[] = [];
  TransportVehicleList: TransportVehicleModel[] = [];
  jumboTableData: WorkPlanJumboMasterModel[] = [];
  SelectedOrder!: WorkPlanOrderModel | null;
  SelectedJumboNo!: WorkPlanJumboMasterModel | null;
  NewWorkPlan = new WorkPlanJumboMasterModel;
  NewWorkPlanbyJumboNo = new WorkPlanJumboMasterModel
  JIModel = new JumboInspectionModel();
  WeightJIModel = new JumboInspectionModel();
  MeasureUnits: MeasureUnitModel[] = [];
  RemainingQty = 0;
  JumboNumber = '';
  DisableSearchOptions = false;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  weightEditId: number | null = null;
  totalRollSize: number = 0;
  totalRollWeight: number = 0;
  private route$: Subscription = new Subscription;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private loader: LoadingService) { }
  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.FinalInspection, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.FinalInspection, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.FinalInspection, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    sessionStorage.removeItem('lastselectedgrade');
    this.GetAllWorkPlanList();
    this.GetAllUnits();
    //this.GetAllRack();
  }
  search() {
    console.log(`this is search value`,
      this.searchValue)
  }
  GetAllWorkPlanList() {
    if (this.WorkPlanList.length <= 0) {

      let url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
      var item = ["JumboInspection", "PartialDispatchReady"];
      this.http.post<any>(url, item).subscribe({
        next: res => {
          this.WorkPlanList = res.Data;
          this.route$ = this.route.params.subscribe((params: Params) => {
            this.JumboNumber = params["JumboNo"];
            if (this.JumboNumber != null)
              this.GetWorkplanDetailsbyJumbo();
          })
        },
        error: res => { this.alertService.error(res.error); },
      });
    }
  }
  OnWorkPlanChange() {
    this.SelectedJumboNo = null;
    this.JIModel = new JumboInspectionModel();
    this.RemainingQty = 0;
    this.NewWorkPlan = new WorkPlanJumboMasterModel;
    this.SaleOrder = new SaleOrderModel;
    this.SaleOrderDispatchItems = [];
    this.SelectedWorkPlanOrder = [];
    // this.SaleOrder.SaleOrderId;
    this.DispatchReadyBarcode = '';
    this.DispatchReadyRack = 0;
    this.DispatchReadyQuantity = 0;
    this.StoreId = 0;
    this.SaleOrder.SaleOrderDispatchReady = new SaleOrderDispatchReadyModel();
    this.loader.show();

    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + this.SelectedWorkPlan.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "JumboInspection");
      console.log(res);
      this.loader.hide();
    }, res => { this.loader.hide(); });
  }
  OnWorkPlanOrderChange() {

    this.JIModel = new JumboInspectionModel();
    this.RemainingQty = 0;
    this.NewWorkPlan = new WorkPlanJumboMasterModel;
    this.getJumboRollsById();
    this.isDispatchedReady = false;
    this.DispatchReadyBarcode = '';
    this.DispatchReadyRack = 0;
    this.DispatchReadyQuantity = 0;
    this.StoreId = 0;
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + this.SelectedOrderNo;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      console.log(res);
      this.SaleOrder = res;

      //if(this.SaleOrder.Status.toString() == "DispatchReady"){
      //  this.isDispatchedReady = true;
      //  var racked = this.RackListOriginal.filter(x=>x.RackId == this.SaleOrder.SaleOrderDispatchReady.DispatchReadyRack)[0];
      //  var store = this.StoreList.filter(x=>x.StoreId == racked.StoreId)[0];
      //  //this.SaleOrder.SaleOrderDispatchReady.RackName = racked.RackName;
      //  //this.SaleOrder.SaleOrderDispatchReady.StoreName = store.StoreName;
      //}
      this.loader.hide();
    }, res => { this.loader.hide(); });

  }

  GetDispatchDetails() {

    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdispatchdetailbyid/" + this.SelectedOrderNo;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      console.log(res);
      this.loader.hide();
    }, res => { this.loader.hide(); });
    // this.GetAllTransport();
  }
  getJumboRollsById(): void {
    var id = this.SelectedOrderNo;// this.SelectedWorkPlanOrder.filter(el => el.OrderId == parseInt(this.SelectedOrderNo))[0].WorkPlanOrdersId// this.SelectedOrder?.WorkPlanOrdersId;
    let url = this.ApiUrl + 'workplan/getworkplanjumbobyworkplanordersid';
    this.http.get<any>(url + '/' + id).subscribe(
      (res) => {
        this.jumboTableData = res.Data;
        this.jumboTableData = this.jumboTableData.filter(el => el.IsInspectionCompleted != true);
        //this.calculateTotal();

      });
  }
  AddSaleOrderDispatchItems() {
    if (this.JIModel.Grade == '') {
      this.alertService.error("Enter Grade ");
      return
    }
    if (this.SelectedJumboNo?.WorkPlanJumboMasterId == null || this.SelectedJumboNo?.WorkPlanJumboMasterId == 0) {
      this.alertService.error("Please select Jumbo");
      return
    }
    if (isNaN(this.JIModel.Quantity) || this.JIModel.Quantity == null || this.JIModel.Quantity < 1) {
      this.alertService.error("Invalid Quantity");
      return
    }
    if (this.JIModel.InspectedBy == '') {
      this.alertService.error("Please provide Name who Inspected this Roll");
      return
    }
    this.JIModel.AddedBy = UserInfo.UserName;
    this.JIModel.SaleOrderId = this.SaleOrder.SaleOrderId;
    this.JIModel.WorkPlanJumboMasterId = this.SelectedJumboNo?.WorkPlanJumboMasterId ?? 0;

    if (this.NewWorkPlan.JumboInspection == null) {
      this.NewWorkPlan.JumboInspection = [];
    }
    let url = this.ApiUrl + 'saleorder/AddJumboInspectionSingleObj';
    this.isLoading = true;
    this.http.post<any>(url, this.JIModel).subscribe({
      next: (res) => {
        this.alertService.success('Inspection Record Added Successfully');
        sessionStorage.setItem('lastselectedgrade', this.JIModel.Grade)
        this.NewWorkPlan.JumboInspection.push(res);
        var jumboqty = this.SelectedJumboNo?.ActualQuantity ?? 0;
        this.RemainingQty = parseFloat((jumboqty - this.NewWorkPlan.JumboInspection.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0)).toFixed(2)) ?? 0;
        this.calculateYield();
        this.JIModel = new JumboInspectionModel();
        this.JIModel.Grade = sessionStorage.getItem('lastselectedgrade');
        this.isLoading = false;
      },
      error: (res) => {
        this.isLoading = false;
        this.alertService.error(res.error.ResponseBody);
      },
    });
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue?.trim()
    this.searchValue = this.searchValue?.trim();

  }
  checkRemainingQTY() {
    var jumboqty = this.SelectedJumboNo?.ActualQuantity ?? 0;
    var qty = jumboqty - this.NewWorkPlan.JumboInspection.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
    var perc = (qty * 20) / 100;
    var rqty = {
      Min: (qty - perc),
      Max: (qty + perc)
    }
    console.log(qty)
    console.log(rqty)
    return rqty;
  }
  calculateYield() {
    var jumboqty = this.SelectedJumboNo?.ActualQuantity ?? 0;
    var f = this.NewWorkPlan.JumboInspection.filter(el => el.Grade == '1st').reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
    var a = this.NewWorkPlan.JumboInspection.filter(el => el.Grade == 'A').reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
    var total = +f + a;
    this.NewWorkPlan.Yield = parseFloat(((total * 100) / jumboqty).toFixed(3));
    this.totalRollSize = parseFloat(this.NewWorkPlan.JumboInspection.reduce((sum, current) => sum + current.Quantity, 0).toFixed(2));
    this.totalRollWeight = parseFloat(this.NewWorkPlan.JumboInspection.reduce((sum, current) => sum + current.Weight, 0).toFixed(2));
  }

  handleRemoveRow(data: JumboInspectionModel) {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure you want to remove this roll?',
      nzOkDanger: true,
      nzOkText: 'Yes',
      nzOnOk: () => this.RemoveRow(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  RemoveRow(data: JumboInspectionModel) {
    this.isLoadingRemove = true;
    let url = this.ApiUrl + 'saleorder/RemoveJumboInspection';
    this.http.post<any>(url, data).subscribe({
      next: (res) => {
        this.alertService.success('Inspection Record Removed Successfully');
        this.NewWorkPlan.JumboInspection = this.NewWorkPlan.JumboInspection.filter(obj => obj !== data);
        var jumboqty = this.SelectedJumboNo?.ActualQuantity ?? 0;
        this.RemainingQty = parseFloat((jumboqty - this.NewWorkPlan.JumboInspection.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0)).toFixed(2)) ?? 0;
        this.calculateYield();
        this.JIModel = new JumboInspectionModel();
        this.isLoadingRemove = false;
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
      },
    });
  }

  CompleteInspection() {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure you want to complete inspection for ' + this.SelectedJumboNo.JumboNo + '?',
      nzOnOk: () => this.CompleteInspectionService(),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  CompleteInspectionService() {
    let url = this.ApiUrl + 'saleorder/CompleteJumboInspection';
    this.http.post<any>(url, this.SelectedJumboNo).subscribe({
      next: (res) => {
        this.WorkPlanList = [];
        //this.GetAllWorkPlanList();
        this.SelectedWorkPlan = new WorkPlanMasterModel;
        this.SelectedWorkPlanOrder = [];
        this.SelectedOrderNo = '';
        this.SelectedJumboNo = null;
        this.JIModel = new JumboInspectionModel();
        this.RemainingQty = 0;
        this.NewWorkPlan = new WorkPlanJumboMasterModel;
        this.SaleOrder = new SaleOrderModel;
        this.alertService.success('Inspection Completed Successfully');
        this.isLoading = false;
        this.router.navigate(['/home/production/finalInspection/list'])
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
      },
    });
  }

  Save() {
    this.isLoading = true;
    this.NewWorkPlan.WorkPlanJumboMasterId = this.SelectedJumboNo?.WorkPlanJumboMasterId ?? 0;
    this.NewWorkPlan.SaleOrderId = this.SelectedJumboNo?.SaleOrderId ?? 0;
    this.NewWorkPlan.JumboNo = this.SelectedJumboNo?.JumboNo ?? '';

    if (this.NewWorkPlan.JumboInspection.length <= 0) {
      this.alertService.error("Enter Inspection Details.");
      return;
    }

    let url = this.ApiUrl + 'saleorder/addjumboinspection';

    this.http.post<any>(url, this.NewWorkPlan).subscribe({
      next: (res) => {
        this.SelectedWorkPlan = new WorkPlanMasterModel;
        this.SelectedWorkPlanOrder = [];
        this.SelectedOrderNo = '';
        this.SelectedJumboNo = null;
        this.JIModel = new JumboInspectionModel();
        this.RemainingQty = 0;
        this.NewWorkPlan = new WorkPlanJumboMasterModel;
        this.SaleOrder = new SaleOrderModel;
        this.alertService.success('Inspection Completed Successfully');
        this.isLoading = false;
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
      },
    });

  }
  GetWorkplanDetailsbyJumbo() {

    this.loader.show();
    this.JumboNumber = this.JumboNumber.toUpperCase()
    let url = this.ApiUrl + "workplan/GetWorkPlanJumboByJumboNumber/" + this.JumboNumber.toUpperCase();
    this.http.get<WorkPlanJumboMasterModel>(url).subscribe(res => {
      console.log(res);

      if (res == null) {
        this.loader.hide();
        this.alertService.error("Jumbo Number not exist in Jumbo Inspection");
        this.SelectedWorkPlan = new WorkPlanMasterModel;
        this.SelectedJumboNo = new WorkPlanJumboMasterModel;
        this.SelectedOrderNo = ''
        return
      }
      else if (res.SaleOrderStatus != 'JumboInspection' && res.SaleOrderStatus != 'PartialDispatchReady') {
        this.loader.hide();
        this.alertService.error("Jumbo Number not exist in Jumbo Inspection");
        return
      }
      this.NewWorkPlanbyJumboNo = res;

      if (this.WorkPlanList.filter(x => x.WorkPlanId == this.NewWorkPlanbyJumboNo.WorkPlanId).length > 0) {

        this.SelectedWorkPlan = this.WorkPlanList.filter(x => x.WorkPlanId == this.NewWorkPlanbyJumboNo.WorkPlanId)[0];
        this.SelectedJumboNo = null;
        this.JIModel = new JumboInspectionModel();
        this.RemainingQty = 0;
        this.NewWorkPlan = new WorkPlanJumboMasterModel;
        this.SaleOrder = new SaleOrderModel;
        this.SaleOrderDispatchItems = [];
        this.SelectedWorkPlanOrder = [];
        // this.SaleOrder.SaleOrderId;
        this.DispatchReadyBarcode = '';
        this.DispatchReadyRack = 0;
        this.DispatchReadyQuantity = 0;
        this.StoreId = 0;
        this.SaleOrder.SaleOrderDispatchReady = new SaleOrderDispatchReadyModel();
        this.loader.show();

        let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + this.SelectedWorkPlan.WorkPlanId;
        this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
          this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "JumboInspection");
          this.SelectedOrderNo = this.NewWorkPlanbyJumboNo.SaleOrderId.toString();
          console.log(this.SelectedWorkPlanOrder);
          console.log(this.SelectedOrderNo);
          this.RemainingQty = 0;
          this.NewWorkPlan = new WorkPlanJumboMasterModel;
          this.getJumboRollsById();
          this.isDispatchedReady = false;
          this.DispatchReadyBarcode = '';
          this.DispatchReadyRack = 0;
          this.DispatchReadyQuantity = 0;
          this.StoreId = 0;
          this.loader.show();
          let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + this.SelectedOrderNo;
          this.http.get<SaleOrderModel>(url).subscribe(res => {
            console.log(res);
            this.SaleOrder = res;
            this.DisableSearchOptions = true;
            this.SelectedJumboNo = this.jumboTableData.filter(x => x.JumboNo == this.JumboNumber)[0];
            this.CalculateActualQty()
            this.loader.hide();
          }, res => { this.loader.hide(); });
        }, res => { this.loader.hide(); });
      }
      else {
        this.loader.hide();
        this.SelectedWorkPlan = new WorkPlanMasterModel;
        this.SelectedOrderNo = ''
        this.SelectedJumboNo = new WorkPlanJumboMasterModel
        this.SaleOrder.SaleOrderProduction.SaleOrderProductionId = 0;
        this.alertService.error("Jumbo Number not exist in Jumbo Inspection");
      }

      this.loader.hide();
    }, res => { this.loader.hide(); });
    // this.GetAllTransport();
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnits(); }
    });
  }

  CalculateActualQty() {
    // this.NewWorkPlan = this.jumboTableData.filter(x=>x.WorkPlanJumboMasterId == this.SelectedJumboNo)[0];
    this.NewWorkPlan = this.SelectedJumboNo;
    //var SampleQty = this.SelectedJumboNo.ActualQuantity - this.SelectedJumboNo.JumboInspection.filter(x => x.RollType == 'SAMPLE').reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0;
    this.SelectedJumboNo.ActualQuantity;// = SampleQty;
    console.log(this.SelectedJumboNo)
    this.calculateYield();
    var jumboqty = this.SelectedJumboNo?.ActualQuantity ?? 0;
    this.RemainingQty = parseFloat((jumboqty - this.NewWorkPlan.JumboInspection.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0)).toFixed()) ?? 0;
  }
  inspectionprint(data: any, WorkPlanOrdersId: number) {
    window.open(`${window.location.origin}/inspectionprint/` + WorkPlanOrdersId + '/' + data.WorkPlanJumboMasterId + '/' + data.JumboInspectionId);
  }
  inspectionprintAll(data: any, WorkPlanOrdersId: number) {
    window.open(`${window.location.origin}/inspectionallprint/` + WorkPlanOrdersId + '/' + data.WorkPlanJumboMasterId);
  }
  hideRemoveButton(data: any) {
    if (data == "DispatchCompleted" || data == "PartialDispatchCompleted")
      return true
    else
      return false
  }
  StartWeightEdit(id: number): void {
    console.log(id)
    this.weightEditId = id;
    this.NewWorkPlan.JumboInspection.forEach((x) => {
      if (x.JumboInspectionId == id) {
        x.enableWeightSave = true;
      }
    })
  }
  stopWeightEdit(): void {
    this.WeightJIModel = this.NewWorkPlan.JumboInspection.find(x => x.JumboInspectionId === this.weightEditId);
    if (this.WeightJIModel) {
      this.WeightJIModel.enableWeightSave = false;
    }
    this.weightEditId = null;
  }
  SaveWeight() {
    this.loader.show()
    this.stopWeightEdit();
    if (this.WeightJIModel.JumboInspectionId > 0) {
      let url = this.ApiUrl + 'saleorder/AddJumboInspectionSingleObj';
      this.http.post<any>(url, this.WeightJIModel).subscribe({
        next: (res) => {
          this.calculateYield();
          this.WeightJIModel = new JumboInspectionModel();
          this.NewWorkPlan.JumboInspection.forEach((x) =>
            x.enableWeightSave = false
          )
          this.loader.hide();
        },
        error: (res) => {
          this.loader.hide();
          this.alertService.error(res.error.ResponseBody);
        },
      });
    }
    else {
      this.alertService.error("All values are not set properly. Refresh the page and try again.")
    }
  }
  sumquantity() {
    var sum = this.NewWorkPlan.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sum1stquantity() {
    var sum = this.NewWorkPlan.JumboInspection.filter(x => x.Grade == "1st").reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sumAquantity() {
    var sum = this.NewWorkPlan.JumboInspection.filter(x => x.Grade == "A").reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sumweight() {
    var sum = this.NewWorkPlan.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sum1stweight() {
    var sum = this.NewWorkPlan.JumboInspection.filter(x => x.Grade == "1st").reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sumAweight() {
    var sum = this.NewWorkPlan.JumboInspection.filter(x => x.Grade == "A").reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
}
