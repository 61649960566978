import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, ColorModel, GrainModel, ElementModel, ThicknessModel, WidthMasterModel } from '../../../Models/MasterModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../../Models/ProductModel';
import { CustomerModel, OrderModel, OrderProductModel, SupplierModel } from '../../../Models/SupplierModel';
import { TransportModel } from '../../../Models/TransportModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { UserInfo } from '../../../Authentication/UserInfo';
import { Subscription } from 'rxjs';
import { ProcessRawMaterialModel } from '../../../Models/ProcessModel';
import { MixingModel, MixingRawMaterialModel } from '../../../Models/MixingModel';
import { SaleOrderModel, SaleOrderProductionModel, SaleOrderProductionMixingModel, SaleOrderProductionEmbossingModel, SaleOrderProductionPrintModel, SaleOrderProductionTumblingModel, SaleOrderProductionVacuumModel, SaleOrderProductionLacquerRawMaterialModel, SaleOrderProductionRawMaterialModel, SaleOrderProductionElementModel, SaleOrderProductionMixingRawMaterialModel, SaleOrderPostProcessOrderModel } from '../../../Models/SalesOrderModel';
import { ProductionLacquerModel } from '../../../Models/ProductionModel';
import { FormulationCodeModel } from '../../../Models/FormulationCodeModel';
import { LoadingService } from '../../../Services/loadingService';
import { AuthService } from '../../../Services/auth.service';
import { Modules, Responsibility } from '../../../Models/Enums';
import { PIDrawerService } from 'src/PmsUIApp/Services/PIDrawerService';

@Component({
  selector: 'app-presalesorder',
  templateUrl: './presalesorder.component.html',
  styleUrls: ['./presalesorder.component.css']
})
export class PresalesorderComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  ProductFilteredFabricBaseList: ProductModel[] = [];
  OrderList: OrderModel[] = [];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FabricCategoryFilteredList: ProductCategoryModel[] = [];
  ColorList: ColorModel[] = [];
  TransportList: TransportModel[] = [];
  DeliveryTermList: DeliveryTermModel[] = [];
  PaymentTermList: PaymentTermModel[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  GrainList: GrainModel[] = [];
  ElementList: ElementModel[] = [];
  VaccumList: SaleOrderProductionVacuumModel[] = [];
  TumblingList: SaleOrderProductionTumblingModel[] = [];
  LacquerList: ProductionLacquerModel[] = [];
  EmbossingList: SaleOrderProductionEmbossingModel[] = [];
  PrintMasterList: SaleOrderProductionPrintModel[] = [];
  proformaInvoices: any[] = [];
  NewSaleOrder: SaleOrderModel = new SaleOrderModel();
  NewPO: SaleOrderProductionModel = new SaleOrderProductionModel;
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedCategory: any;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  IsPIDrawerOpen = false;
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: 'NA',
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  }
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  listOfSelectedproducts: number[] = [];
  listOfSelectedElement: number[] = [];
  listOfSelectedOrder = [];
  listOfSelectedprocess: number[] = [];
  listOfSelectedmixing: number[] = [];
  listOfSelectedLacquar: ProductionLacquerModel[] = [];
  listOfSelectedOrderProdects: OrderProductModel[] = [];
  listOfSelectedPrint: SaleOrderProductionPrintModel[] = [];
  listOfSelectedEmbossing: SaleOrderProductionEmbossingModel[] = [];
  listOfSelectedVaccum: SaleOrderProductionVacuumModel[] = [];
  listOfSelectedTumbling: SaleOrderProductionTumblingModel[] = [];
  SelectedLacquer = ProductionLacquerModel
  SelectedOrder = 0;
  SelectedProcess = 0;
  productionRawMaterial: SaleOrderProductionRawMaterialModel[] = [];
  productionElement: SaleOrderProductionElementModel[] = [];
  LacquerRawMaterial: SaleOrderProductionLacquerRawMaterialModel[] = [];
  //productionOrder: ProductionOrderModel[] = [];
  SalesOrderId = 0;
  private route$: Subscription = new Subscription;

  processRawMaterial: ProcessRawMaterialModel[] = [];
  //ProcessList: ProcessModel[] = [];
  //ProcessSelectedList: ProductionProcessModel[] = [];
  MixingList: MixingModel[] = [];
  mixingRawMaterial: MixingRawMaterialModel[] = [];
  MixingSelectedList: SaleOrderProductionMixingModel[] = [];
  Wastagetype = '%';
  CustomerList: CustomerModel[] = [];
  FormulationCodeList: FormulationCodeModel[] = [];
  NewFormulationCode: string = ''
  SelectedFormulationCode: FormulationCodeModel = new FormulationCodeModel();
  SelectedFormulationCodeId = 0;
  ThicknessList: ThicknessModel[] = [];
  WidthList: WidthMasterModel[] = [];
  PostProcess: string[] = [
    "Print", "Embossing", "Lacquar", "Vaccum", "Tumbling"
  ]
  SaleOrderPostProcessOrder =
    {
      PrintProcess: 0,
      EmbossingProcess: 0,
      LacquerProcess: 0,
      VacuumProcess: 0,
      TumblingProcess: 0
    }
  SelectedPostProcess: any = [];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  ActionType = '';
  disableFabricChange: boolean = true;
  selectedFabricCategory: string = 'fabric'
  selectedFabricName: string | null;
  selectedNewFabric: ProductModel;
  showProcess: boolean = false;
  LinkingSalesOrderList: SaleOrderModel[];
  FormulationCodeDropDownSpan: number = 6;
  isFabricPopupVisible: boolean = false;
  FilteredProductList: ProductModel[];
  SelectedProductType: string;
  constructor(private fb: UntypedFormBuilder, private auth: AuthService, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private loader: LoadingService, private PIDrawer: PIDrawerService) { }
  handleOk(): void {

    this.isLoading = true;

    this.Save();
  }
  Save() {
    if (this.SelectedFormulationCode.IsOrderLinkingRequired == true && this.NewSaleOrder.OrderIdForLinking <= 0) {
      this.isLoading = false;
      this.alertService.error("Selected formulation Code is for Sandwich Lining. Selecting Upper Order for Auto Linking is required.");
      return;
    }
    if (this.NewPO.GrainId == 0) {
      this.isLoading = false;
      this.alertService.error("Grain Selection Required");
      return;
    }
    if (this.NewPO.Width == 0) {
      this.isLoading = false;
      this.alertService.error("Width Selection Required");
      return;
    }
    if (this.NewPO.ColorId == 0) {
      this.isLoading = false;
      this.alertService.error("Color Selection Required");
      return;
    }
    if (this.NewPO.FabricColorId == 0 && this.SelectedFormulationCode?.FabricProductId != 0) {
      this.isLoading = false;
      this.alertService.error("Fabric Color Selection Required");
      return;
    }
    if (this.NewSaleOrder.SaleOrderType == '') {
      this.isLoading = false;
      this.alertService.error("OrderType Required");
      return;
    }
    if (this.NewPO.ManufacturingProductName == '') {
      this.isLoading = false;
      this.alertService.error("ManufacturingProductName");
      return;
    }
    if (this.NewPO.OrderQuantity == 0) {
      this.isLoading = false;
      this.alertService.error("Quantity");
      return;
    }
    if (this.NewPO.SalePrice == 0) {
      this.isLoading = false;
      this.alertService.error("SalePrice");
      return;
    }
    if (this.NewSaleOrder.CustomerId == 0) {
      this.isLoading = false;
      this.alertService.error("Customer Required");
      return;
    }
    if (this.NewSaleOrder.SaleOrderDate == '') {
      this.isLoading = false;
      this.alertService.error("OrderDate Required");
      return;
    }
    if (this.NewSaleOrder.DeliveryDate == '') {
      this.isLoading = false;
      this.alertService.error("DeliveryDate Required");
      return;
    }
    if (this.NewSaleOrder.SaleFormulationCode == '') {
      this.isLoading = false;
      this.alertService.error("FormulationCode Required");
      return;
    }
    if (this.NewPO.FabricProductId == 0 && this.SelectedFormulationCode?.FabricProductId != 0) {
      this.isLoading = false;
      this.alertService.error("Fabric is not selected. Please check it again.");
      return;
    }
    if (this.NewPO.ProductCategoryId == 0) {
      this.isLoading = false;
      this.alertService.error("Category Required");
      return;
    }
    if (this.NewPO.OrderQuantity <= 0) {
      this.isLoading = false;
      this.alertService.error("Order Quantity Required");
      return;
    }
    if (this.NewSaleOrder.BORNumber.length > 100) {
      this.isLoading = false;
      this.alertService.error("Buyer Order Reference cannot be more than 150 charachers");
      return;
    }
    if (this.NewSaleOrder.Remarks.length > 150) {
      this.isLoading = false;
      this.alertService.error("Remarks cannot be more than 150 charachers");
      return;
    }
    this.NewSaleOrder.SaleOrderPostProcessOrder = [];
    let continueLoop = true;
    if (this.SelectedPostProcess.length > 0) {
      this.SelectedPostProcess.forEach((selProcess) => {
        if (continueLoop) {
          if (this.SelectedPostProcess.filter(x => x.Rank == selProcess.Rank && selProcess.Rank !== 0).length > 1) {
            this.alertService.error("Duplicate Postprocess Order");
            continueLoop = false;
            this.isLoading = false;
            return;
          }
        }
        if (selProcess.Rank > 0) {
          var ps = new SaleOrderPostProcessOrderModel;
          ps.PostProcessName = selProcess.PostProcessName;
          ps.Rank = selProcess.Rank;
          this.NewSaleOrder.SaleOrderPostProcessOrder.push(ps);
        } else {
          this.isLoading = false;
          this.alertService.error(`Select ${selProcess.PostProcessName} Order`);
          return;
        }
        if (!selProcess.selectedProcess || Object.keys(selProcess.selectedProcess).length === 0) {
          this.isLoading = false;
          this.alertService.error(`Add ${selProcess.PostProcessName}`);
          return;
        }
      })
      if (!this.isLoading) {
        return
      }
    }

    // if (this.SaleOrderPostProcessOrder.PrintProcess > 0) {

    //   var ps = new SaleOrderPostProcessOrderModel;
    //   ps.PostProcessName = 'Print';
    //   ps.Rank = this.SaleOrderPostProcessOrder.PrintProcess;
    //   this.NewSaleOrder.SaleOrderPostProcessOrder.push(ps);
    // }
    // else if (this.SelectedPostProcess.filter(x => x.process == 'Print').length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Select Print Order");
    //   return;
    // }
    // if (this.SaleOrderPostProcessOrder.EmbossingProcess > 0) {
    //   var ps = new SaleOrderPostProcessOrderModel;
    //   ps.PostProcessName = 'Embossing';
    //   ps.Rank = this.SaleOrderPostProcessOrder.EmbossingProcess;
    //   this.NewSaleOrder.SaleOrderPostProcessOrder.push(ps);
    // }
    // else if (this.SelectedPostProcess.filter(x => x.process == 'Embossing').length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Select Embossing Order");
    //   return;
    // }
    // if (this.SaleOrderPostProcessOrder.LacquerProcess > 0) {
    //   var ps = new SaleOrderPostProcessOrderModel;
    //   ps.PostProcessName = 'Lacquar';
    //   ps.Rank = this.SaleOrderPostProcessOrder.LacquerProcess;
    //   this.NewSaleOrder.SaleOrderPostProcessOrder.push(ps);
    // }
    // else if (this.SelectedPostProcess.filter(x => x.process == 'Lacquar').length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Select Lacquar Order");
    //   return;
    // }

    // if (this.SaleOrderPostProcessOrder.VacuumProcess > 0) {
    //   var ps = new SaleOrderPostProcessOrderModel;
    //   ps.PostProcessName = 'Vaccum';
    //   ps.Rank = this.SaleOrderPostProcessOrder.VacuumProcess;
    //   this.NewSaleOrder.SaleOrderPostProcessOrder.push(ps);
    // }
    // else if (this.SelectedPostProcess.filter(x => x.process == 'Vaccum').length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Select Vaccum Order");
    //   return;
    // }
    // if (this.SaleOrderPostProcessOrder.TumblingProcess > 0) {
    //   var ps = new SaleOrderPostProcessOrderModel;
    //   ps.PostProcessName = 'Tumbling';
    //   ps.Rank = this.SaleOrderPostProcessOrder.TumblingProcess;
    //   this.NewSaleOrder.SaleOrderPostProcessOrder.push(ps);
    // }
    // else if (this.SelectedPostProcess.filter(x => x.process == 'Tumbling').length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Select Tumbling Order");
    //   return;
    // }
    var totalrank = this.NewSaleOrder.SaleOrderPostProcessOrder.map(x => x.Rank);
    // var checkduplicate = totalrank.filter((e, i, a) => a.indexOf(e) !== i);

    // if (checkduplicate.length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Duplicate Postprocess Order");
    //   return;
    // }
    // if (this.SelectedPostProcess.filter(x => x.process == 'Print').length > 0 && this.listOfSelectedPrint.length == 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Add Print");
    //   return;
    // }
    // if (this.SelectedPostProcess.filter(x => x.process == 'Embossing').length > 0 && this.listOfSelectedEmbossing.length == 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Add Embossing");
    //   return;
    // }
    // if (this.SelectedPostProcess.filter(x => x.process == 'Lacquar').length > 0 && this.listOfSelectedLacquar.length == 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Add Lacquar");
    //   return;
    // }
    // if (this.SelectedPostProcess.filter(x => x.process == 'Vaccum').length > 0 && this.listOfSelectedVaccum.length == 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Add Vaccum");
    //   return;
    // }
    // if (this.SelectedPostProcess.filter(x => x.process == 'Tumbling').length > 0 && this.listOfSelectedTumbling.length == 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Add Tumbling");
    //   return;
    // }
    this.NewPO.SaleOrderProductionRawMaterial = this.productionRawMaterial;
    this.NewPO.SaleOrderProductionElement = this.productionElement;

    //this.NewPO.ProductId = this.selectedproduct.ProductId;
    this.NewPO.AddedBy = this.NewSaleOrder.AddedBy = UserInfo.EmailID;
    this.NewPO.Mixing = this.MixingSelectedList;
    console.log(this.listOfSelectedLacquar)
    console.log(this.NewPO.LacquerMasterId)
    this.NewPO.LacquerMasterId = [];
    this.listOfSelectedLacquar.forEach(x => {
      this.NewPO.LacquerMasterId.push(x.LacquerMasterId);
    })

    this.NewPO.Lacquer = this.SelectedPostProcess.filter((pro) => pro.PostProcessName === "Lacquar").map((pro) => { return { ...pro.selectedProcess, Rank: pro.Rank } });
    this.NewPO.SaleOrderProductionPrint = this.SelectedPostProcess.filter((pro) => pro.PostProcessName === "Print").map((pro) => { return { ...pro.selectedProcess, Rank: pro.Rank } });
    this.NewPO.SaleOrderProductionVacuum = this.SelectedPostProcess.filter((pro) => pro.PostProcessName === "Vaccum").map((pro) => { return { ...pro.selectedProcess, Rank: pro.Rank } });
    this.NewPO.SaleOrderProductionTumbling = this.SelectedPostProcess.filter((pro) => pro.PostProcessName === "Tumbling").map((pro) => { return { ...pro.selectedProcess, Rank: pro.Rank } });
    this.NewPO.SaleOrderProductionEmbossing = this.SelectedPostProcess.filter((pro) => pro.PostProcessName === "Embossing").map((pro) => { return { ...pro.selectedProcess, Rank: pro.Rank } });
    // this.NewPO.LacquerRawMaterial = this.LacquerRawMaterial;
    // this.NewPO.SaleOrderProductionPrint = this.listOfSelectedPrint;
    // this.NewPO.SaleOrderProductionVacuum = this.listOfSelectedVaccum;
    // this.NewPO.SaleOrderProductionTumbling = this.listOfSelectedTumbling;
    // this.NewPO.SaleOrderProductionEmbossing = this.listOfSelectedEmbossing;

    this.NewPO.GrainPrice = this.GrainList.filter(x => x.GrainId == this.NewPO.GrainId)[0].Price;
    this.NewSaleOrder.CategoryId = this.NewPO.ProductCategoryId;
    this.NewSaleOrder.SaleOrderProduction = this.NewPO;
    if (this.NewSaleOrder.SaleOrderProduction.PreSkinGsm < 0 || this.NewSaleOrder.SaleOrderProduction.PreSkinGsm == undefined) {
      this.isLoading = false;
      this.alertService.error("PreSkin GSM cannot be empty, either enter 0 or a higher value");
      return;
    }
    if (this.NewSaleOrder.SaleOrderProduction.SkinGsm < 0 || this.NewSaleOrder.SaleOrderProduction.SkinGsm == undefined) {
      this.isLoading = false;
      this.alertService.error("Skin GSM cannot be empty, either enter 0 or a higher value");
      return;
    }
    if (this.NewSaleOrder.SaleOrderProduction.FoamGsm < 0 || this.NewSaleOrder.SaleOrderProduction.FoamGsm == undefined) {
      this.isLoading = false;
      this.alertService.error("Foam GSM cannot be empty, either enter 0 or a higher value");
      return;
    }
    if (this.NewSaleOrder.SaleOrderProduction.AdhesiveGsm < 0 || this.NewSaleOrder.SaleOrderProduction.AdhesiveGsm == undefined) {
      this.isLoading = false;
      this.alertService.error("Adhesive GSM cannot be empty, either enter 0 or a higher value");
      return;
    }
    if (this.NewSaleOrder.SaleOrderProduction.FabricGsm < 0 || this.NewSaleOrder.SaleOrderProduction.FabricGsm == undefined) {
      this.isLoading = false;
      this.alertService.error("Fabric GSM cannot be empty, either enter 0 or a higher value");
      return;
    }
    this.NewSaleOrder.SaleOrderId = 0;
    console.log(this.NewSaleOrder);

    let url = '';
    if (this.ActionType == undefined) {
      url = this.ApiUrl + "saleorder/addSaleOrder";
    }
    else if (this.ActionType == "edit") {
      url = this.ApiUrl + "saleorder/UpdateSaleOrder";
      this.NewSaleOrder.SaleOrderId = this.SalesOrderId;
    }
    else if (this.ActionType == "copy") {
      url = this.ApiUrl + "saleorder/addSaleOrder";
    }
    this.http.post<any>(url, this.NewSaleOrder).subscribe({
      next: res => {
        this.isLoading = false;
        if (res == 'An error has occured. Please contact administrator') {
          this.alertService.error(res);
        } else {
          this.alertService.success("Entry Saved Successfully");
          this.router.navigate(['/home/salesorder/list'])
        }
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });

  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.SalesOrderId = params["id"];
      this.ActionType = params["type"];
    })
    console.log(this.SalesOrderId, "this is sales order ID");
    this.validateForm = this.fb.group({});
    this.NewSaleOrder.SaleOrderDate = this.NewSaleOrder.DeliveryDate = new Date().toUTCString();
    this.GetAllCustomer();
    this.GetAllFormulationCode();
    this.GetAllProductCategory();
    this.GetAllThickness();
    this.GetAllWidth();
    this.GetAllColor();
    this.GetAllGrain();
    //this.GetAllElement();
    this.GetAllLacquer();
    this.GetAllPrint();
    this.GetAllEmbossing();
    this.GetAllVaccum();
    this.GetAllTumbling();
    //this.GetAllUnits();
    this.GetAllProducts();


    //this.GetAllOrder();
    //this.GetProcess();
    //this.GetMixing();

    if (this.SalesOrderId > 0) {
      this.loader.show();
      setTimeout(() => {                           // <<<---using ()=> syntax
        this.GetSalesOrderDatabyid(this.SalesOrderId)
      }, 3000);

    }
  }
  get f() { return this.validateForm.controls; }

  GetSalesOrderDatabyid(id: number) {

    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + id;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      this.NewSaleOrder = res;
      if (this.ActionType == "copy") {
        this.NewSaleOrder.Remarks = ("Created from Sale Order No. " + this.NewSaleOrder.SaleOrderNumber);
      }
      this.NewPO.FabricProductId = 0;
      this.NewPO = this.NewSaleOrder.SaleOrderProduction;
      this.SelectedFormulationCodeId = this.NewSaleOrder.SaleFormulationCodeId;
      this.SelectedFormulationCode = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.SelectedFormulationCodeId)[0];
      this.SetFabricProduct()
      if (this.SelectedFormulationCode.IsOrderLinkingRequired) {
        this.FormulationCodeDropDownSpan = 4;
        this.GetAllSalesOrderForLinking();
      }
      else {
        this.FormulationCodeDropDownSpan = 6;
      }
      //this.SelectedFormulationCode = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.NewSaleOrder.SaleFormulationCodeId)[0];
      this.SelectedPostProcess = [];
      this.NewPO.ProductCategoryId = this.NewSaleOrder.CategoryId;
      // this.NewSaleOrder.SaleOrderPostProcessOrder.forEach(el => {
      //   this.SelectedPostProcess.push(el.PostProcessName)
      // })
      this.SelectedPostProcess = [...this.NewSaleOrder.SaleOrderPostProcessOrder];
      this.SelectedPostProcess.forEach((pro) => {
        switch (pro.PostProcessName) {
          case "Print":
            const selectedPrintProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionPrint.filter((print) =>
              print.PrintMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
            if (selectedPrintProcess.length > 0) {
              const selecteProcessId = selectedPrintProcess[0].PrintMasterId
              pro.selectedProcess = this.PrintMasterList.filter(x => x.PrintMasterId === selecteProcessId)[0]
            }
            break;
          case "Embossing":
            const selectedEmbossingProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionEmbossing.filter((print) =>
              print.EmbossingMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
            if (selectedEmbossingProcess.length > 0) {
              const selecteProcessId = selectedEmbossingProcess[0].EmbossingMasterId
              pro.selectedProcess = this.EmbossingList.filter(x => x.EmbossingMasterId === selecteProcessId)[0]
            }
            break;
          case "Vaccum":
            const selectedVaccumProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionVacuum.filter((print) =>
              print.VacuumMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
            if (selectedVaccumProcess.length > 0) {
              const selecteProcessId = selectedVaccumProcess[0].VacuumMasterId
              pro.selectedProcess = this.VaccumList.filter(x => x.VacuumMasterId === selecteProcessId)[0]
            }
            break;
          case "Tumbling":
            const selectedTumblingProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionTumbling.filter((print) =>
              print.TumblingMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
            if (selectedTumblingProcess.length > 0) {
              const selecteProcessId = selectedTumblingProcess[0].TumblingMasterId
              pro.selectedProcess = this.TumblingList.filter(x => x.TumblingMasterId === selecteProcessId)[0]
            }
            break;
          case "Lacquar":
            const selectedLacquarProcess = this.NewSaleOrder.SaleOrderProduction.Lacquer.filter((print) =>
              print.LacquerMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
            if (selectedLacquarProcess.length > 0) {
              const selecteProcessId = selectedLacquarProcess[0].LacquerMasterId
              pro.selectedProcess = this.LacquerList.filter(x => x.LacquerMasterId === selecteProcessId)[0]
            }
        }
      })

      let uniquePrint = [...new Set(this.NewPO.SaleOrderProductionPrint.map(item => item.PrintMasterId))];
      this.listOfSelectedPrint = this.PrintMasterList.filter(x => uniquePrint.includes(x.PrintMasterId));
      let uniqueEmbossing = [...new Set(this.NewPO.SaleOrderProductionEmbossing.map(item => item.EmbossingMasterId))];
      this.listOfSelectedEmbossing = this.EmbossingList.filter(x => uniqueEmbossing.includes(x.EmbossingMasterId));
      let uniqueVacuum = [...new Set(this.NewPO.SaleOrderProductionVacuum.map(item => item.VacuumMasterId))];
      this.listOfSelectedVaccum = this.VaccumList.filter(x => uniqueVacuum.includes(x.VacuumMasterId));
      let uniqueTumbling = [...new Set(this.NewPO.SaleOrderProductionTumbling.map(item => item.TumblingMasterId))];
      this.listOfSelectedTumbling = this.TumblingList.filter(x => uniqueTumbling.includes(x.TumblingMasterId));
      let uniqueLacquar = [...new Set(this.NewPO.Lacquer.map(item => item.LacquerMasterId))];
      this.listOfSelectedLacquar = this.LacquerList.filter(x => uniqueLacquar.includes(x.LacquerMasterId));
      this.SaleOrderPostProcessOrder.PrintProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Print")[0]?.Rank ?? 0
      this.SaleOrderPostProcessOrder.EmbossingProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Embossing")[0]?.Rank ?? 0
      this.SaleOrderPostProcessOrder.LacquerProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Lacquar")[0]?.Rank ?? 0
      this.SaleOrderPostProcessOrder.VacuumProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Vaccum")[0]?.Rank ?? 0
      this.SaleOrderPostProcessOrder.TumblingProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Tumbling")[0]?.Rank ?? 0
      console.log(this.SaleOrderPostProcessOrder)
      this.calclulateTotalSalePrice();
      this.calculateGrandTotal();
      this.loader.hide();
    });
  }

  GetAllCustomer() {

    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }
  GetAllOrder() {

    let url = this.ApiUrl + "order/getallorders";
    this.http.get<OrderModel[]>(url).subscribe(res => {
      this.OrderList = res;

    }, res => { });
  }
  GetAllElement() {

    let url = this.ApiUrl + "element/getallelements";
    this.http.get<ElementModel[]>(url).subscribe(res => {
      this.ElementList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllElement(); }
    });
  }
  GetAllColor() {

    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllColor(); }
    });
  }
  GetAllGrain() {

    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllGrain(); }
    });
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => { });
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      if (this.SelectedProductType == 'Raw') {
        this.FabricCategoryFilteredList = res.filter(x => x.ProductType == this.SelectedProductType && (x.ProductCategory?.toLowerCase() == 'fabric' || x.ProductCategory.toLowerCase() == 'base'))
      }
      else {
        this.FabricCategoryFilteredList = res;
      }
      this.FilteredProductList = this.ProductList.filter(
        (x) =>
          x.ProductType == this.SelectedProductType &&
          x.ProductCategoryId == this.productItem.CategoryID
      );
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(); }
    });
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.FilteredProductList = this.ProductList.filter(
        (x) =>
          x.ProductType == this.SelectedProductType &&
          x.ProductCategoryId == data
      );
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.productItem.FirstCategoryID = id;

    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.FilteredProductList = this.ProductList.filter(
        (x) =>
          x.ProductType == this.SelectedProductType &&
          x.ProductCategoryId == data
      );
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.productItem.SecondCategoryID = id;
    }, res => { });
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res.filter(x => x.ProductType == "Finished");
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => { });
  }
  GetAllVaccum() {

    let url = this.ApiUrl + 'vacuum/getallvacuums';
    this.http.get<SaleOrderProductionVacuumModel[]>(url).subscribe(
      (res) => {
        this.VaccumList = res;
        this.VaccumList.forEach(x => x.Quantity = 1);
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllVaccum(); }
      }
    );
  }
  GetAllTumbling() {
    let url = this.ApiUrl + 'tumbling/getalltumblings';
    this.http.get<SaleOrderProductionTumblingModel[]>(url).subscribe(
      (res) => {
        this.TumblingList = res;
        this.TumblingList.forEach(x => x.Quantity = 1);
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllTumbling(); }
      }
    );
  }
  GetAllLacquer() {
    let url = this.ApiUrl + 'lacquer/getalllacquers';
    this.http.get<ProductionLacquerModel[]>(url).subscribe(
      (res) => {
        this.LacquerList = res;

      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllLacquer(); }
      }
    );
  }
  GetAllEmbossing() {

    let url = this.ApiUrl + "embossing/getallembossings";
    this.http.get<SaleOrderProductionEmbossingModel[]>(url).subscribe(res => {
      this.EmbossingList = res;
      this.EmbossingList.forEach(x => x.Quantity = 1);

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllEmbossing(); }
    });
  }
  GetAllPrint() {

    let url = this.ApiUrl + "print/getallprints";
    this.http.get<SaleOrderProductionPrintModel[]>(url).subscribe(res => {
      this.PrintMasterList = res;
      this.PrintMasterList.forEach(x => x.Quantity = 1);
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllPrint(); }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllFormulationCode(); }
    });
  }
  GetAllThickness() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
      this.isTableLoading = false;
      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllThickness(); }
    });
  }
  GetAllWidth() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "width/getallwidthdata";
    this.http.get<WidthMasterModel[]>(url).subscribe(res => {
      this.WidthList = res;
      this.isTableLoading = false;
      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllWidth(); }
    });
  }
  handleCancel(): void {
    this.isVisible = false;
  }

  handleProcess(process: string) {
    this.SelectedPostProcess.push({
      PostProcessName: process,
      Rank: 0,
      selectedProcess: ''
    })
    this.SetDefaultPostProcessOrder()
  }

  removeProcess(process: string) {
    const index = this.SelectedPostProcess.findIndex((postPro) => postPro.PostProcessName === process)
    this.SelectedPostProcess.splice(index, 1);
  }
  UpdateProduct(data: any) {

    this.selectedunit = data.Unit;
    this.productItem.ProductName = data.ProductName;
    this.productItem.ProductID = data.ProductId;
    this.productItem.CategoryID = data.ProductCategoryId;
    //let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    //this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
    //  this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.ProductCategoryId);
    //  this.productItem.FirstCategoryID = data.ProductFirstSubCategoryId;
    //  let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    //  this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
    //    this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId);
    //    this.productItem.SecondCategoryID = data.ProductSecSubCategoryId

    //  }, res => { });


    //}, res => { });
    //this.GetAllFirstCategory(data.ProductCategoryId, data.ProductFirstSubCategoryId);
    //this.GetAllSecondCategory(data.ProductFirstSubCategoryId, data.ProductSecondSubCategoryId);

    //this.productItem.UnitID = data.ProductName;
    //var filteredorders = this.OrderList.filter(x => this.productionOrder.some(item => item.orderId === x.OrderId))
    //filteredorders.forEach(o => {
    //  o.OrderProduct.forEach(p => {
    //    if (p.ProductId == data.ProductId) {
    //      this.NewPO.orderQuantity += p.Quantity;
    //    }
    //  })
    //})


  }
  UpdateUnit(data: any) {


    this.productItem.Unit = this.selectedunit;
    this.productItem.UnitID = data.Id;
    //this.productItem.UnitID = data.ProductName;

  }

  OnRatechange(data: any) {

    var amt = data * this.productItem.Quantity;

    this.productItem.Amount = amt;

  }
  OnQtychange(data: any) {

    var amt = data * this.productItem.Rate;

    //this.Amount = amt;
    this.productItem.Amount = amt;

  }
  // btnAddClick() {
  //   if (!this.Validate(this.productItem)) {
  //     return;
  //   }

  //   var Item = {
  //     CategoryID: this.productItem.CategoryID,
  //     FirstCategoryID: this.productItem.FirstCategoryID,
  //     SecondCategoryID: this.productItem.SecondCategoryID,
  //     ProductID: this.productItem.ProductID,
  //     ProductName: this.productItem.ProductName,
  //     UnitID: this.productItem.UnitID,
  //     AvgGsm: "",
  //     Unit: this.selectedunit,
  //     Rate: this.productItem.Rate,
  //     Quantity: this.productItem.Quantity,
  //     Amount: this.productItem.Amount,
  //     Grade: this.productItem.Grade,
  //     IGST: 0,
  //     Currency: ''
  //   }
  //   this.prolist.push(Item)

  //   this.SubTotal = this.GrandTotal = 0
  //   this.prolist.forEach((element) => {
  //     this.SubTotal += parseFloat(element.Rate.toString());
  //     this.GrandTotal += parseFloat(element.Amount.toString());
  //   });
  //   this.productItem = {
  //     CategoryID: 0,
  //     FirstCategoryID: 0,
  //     SecondCategoryID: 0,
  //     ProductID: 0,
  //     ProductName: '',
  //     UnitID: 0,
  //     Unit: '',
  //     Grade: '',
  //     Rate: 0,
  //     Quantity: 0,
  //     Amount: 0,
  //   }
  //   this.selectedunit = '';
  //   this.selectedproduct = '';


  // }
  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ProductID == '0') {
      this.alertService.error("Select Product"); Isvalid = false;
    }
    else if (model.Rate == '0') {
      this.alertService.error("Enter Rate"); Isvalid = false;
    }
    else if (model.Quantity == '0') {
      this.alertService.error("Enter Quantity"); Isvalid = false;
    }
    return Isvalid;
  }
  OnProductChange() {
    this.selectedCategory = this.ProductList.filter(x => x.ProductId == this.NewPO.ProductId)[0].ProductCategory;
  }

  OnElementDdlchange() {
    this.productionElement = [];

    this.listOfSelectedElement.forEach(item => {
      var checkexist = this.productionElement.filter(x => x.ElementId == item).length;
      if (checkexist == 0) {
        var product = this.ElementList.filter(x => x.ElementId == item)[0];
        var rawproduct = new SaleOrderProductionElementModel;
        rawproduct.ElementId = item;
        rawproduct.ElementName = product.ElementName;
        rawproduct.Value = '';
        rawproduct.PerUnitCost = 0
        this.productionElement.push(rawproduct);
      }

    })
  }
  GetConditionalRequired() {
    if (this.SelectedFormulationCode?.FabricProductId == null || this.SelectedFormulationCode?.FabricProductId == 0) {
      return ''
    }
    else {
      return "required"
    }
  }
  GetConditionalFabricPlaceholder() {
    if (this.SelectedFormulationCode?.FabricProductId == 0) {
      return 'N/A'
    }
    else {
      return 'Choose'
    }
  }
  OnFormulationddlChange() {
    this.NewPO.FabricProductId = 0;
    this.SelectedFormulationCode = this.FormulationCodeList.filter(x => x.SaleFormulationCodeId == this.SelectedFormulationCodeId)[0];
    //this.NewPO.ProductId = this.SelectedFormulationCode.ProductId;
    this.NewSaleOrder.SaleFormulationCode = this.SelectedFormulationCode.SaleFormulationCode;
    this.NewPO.SkinGsm = this.SelectedFormulationCode.SkinGsm;
    this.NewPO.PreSkinGsm = this.SelectedFormulationCode.PreSkinGsm;
    this.NewPO.FoamGsm = this.SelectedFormulationCode.FoamGsm;
    this.NewPO.AdhesiveGsm = this.SelectedFormulationCode.AdhesiveGsm;
    this.NewPO.FabricGsm = this.SelectedFormulationCode.FabricGsm;
    this.NewPO.ProductCategoryId = this.SelectedFormulationCode.CategoryId;
    this.NewPO.Thick = this.SelectedFormulationCode.ThicknessId;
    if (this.SelectedFormulationCode.IsOrderLinkingRequired) {
      this.FormulationCodeDropDownSpan = 4;
      this.GetAllSalesOrderForLinking();
    }
    else {
      this.FormulationCodeDropDownSpan = 6;
    }
    this.SetFabricProduct();
    this.calculateGrandTotal();
    //this.OnProductChange();
    //this.NewSaleOrder = this.SelectedFormulationCode;
    //this.NewPO = this.NewSaleOrder.SaleOrderProduction;
    //this.productionElement = this.NewPO.SaleOrderProductionElement;
    //this.listOfSelectedElement = [];
    //this.productionElement.forEach(x => {
    //  this.listOfSelectedElement.push(x.ElementId)
    //  this.calculateElementTotal(x)
    //})
    //this.listOfSelectedproducts = [];
    //this.productionRawMaterial = this.NewPO.SaleOrderProductionRawMaterial;
    //this.productionRawMaterial.forEach(x => {
    //  this.calculateRawTotal(x);
    //  this.listOfSelectedproducts.push(x.ProductId)
    //})

    //this.SelectedFormulationCode.MixingData.forEach(m => {
    //  var s = new SaleOrderProductionMixingModel;
    //  var r = new SaleOrderProductionMixingRawMaterialModel;
    //  m.MixingRawMaterial.forEach(rr => {
    //    r.AvgGsm = rr.AvgGsm;
    //    r.Price = rr.Price;
    //    r.ProductCode = rr.ProductCode;
    //    r.ProductId = rr.ProductId;
    //    r.ProductName = rr.ProductName;
    //    r.Quantity = rr.Quantity;
    //    r.Unit = rr.Unit;
    //    r.SaleOrderProductionMixingRawMaterialId = rr.FormulationCodeMixingRawMaterialId;
    //    r.SaleOrderProductionMixingId = rr.FormulationCodeMixingId;
    //    s.MixingRawMaterial.push(r);

    //  })
    //  this.MixingSelectedList.push(s);

    //})
    //this.listOfSelectedmixing = [];
    //this.MixingSelectedList.forEach(x => {
    //  this.listOfSelectedmixing.push(x.SaleOrderProductionMixingId)
    //  x.MixingRawMaterial.forEach(m => {
    //    this.calculateMixingTotal(m);
    //  })
    //})
    //this.listOfSelectedPrint = [];
    //this.NewPO.SaleOrderProductionPrint.forEach(y => {
    //  this.listOfSelectedPrint.push(this.PrintMasterList.filter(x => x.PrintMasterId == y.PrintMasterId)[0]);
    //})
    //this.listOfSelectedEmbossing = [];
    //this.NewPO.SaleOrderProductionEmbossing.forEach(y => {
    //  this.listOfSelectedEmbossing.push(this.EmbossingList.filter(x => x.EmbossingMasterId == y.EmbossingMasterId)[0]);
    //})
    //this.listOfSelectedVaccum = [];
    //this.NewPO.SaleOrderProductionVacuum.forEach(y => {
    //  this.listOfSelectedVaccum.push(this.VaccumList.filter(x => x.VacuumMasterId == y.VacuumMasterId)[0]);
    //})
    //this.listOfSelectedTumbling = [];
    //this.NewPO.SaleOrderProductionTumbling.forEach(y => {
    //  this.listOfSelectedTumbling.push(this.TumblingList.filter(x => x.TumblingMasterId == y.TumblingMasterId)[0]);
    //})

    //this.LacquerRawMaterial = this.NewPO.LacquerRawMaterial;
    //this.OnLacquerDdlchange();
    //let url = this.ApiUrl + "saleorder/getsaleorderbyformulationcode/" + this.SelectedFormulationCode;
    //this.http.get<any>(url).subscribe(res => {


    //}, res => { });

  }
  GetProductPrice(data: SaleOrderProductionRawMaterialModel): any {
    let url = this.ApiUrl + "product/getproductdefaultperunitcost/" + data.ProductId;
    this.http.get<any>(url).subscribe(res => {
      data.PerUnitCost = res
      data.TotalCost = data.Quantity * data.PerUnitCost;
      console.log(res)

    }, res => { });
  }

  calculateRawTotal(data: SaleOrderProductionRawMaterialModel) {

    this.NewPO.RawGSMTotal = 0;
    this.productionRawMaterial.forEach((element) => {
      this.NewPO.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
      data.TotalCost = parseFloat((parseFloat(element.Quantity.toString()) * parseFloat(element.PerUnitCost.toString())).toFixed(3))
    });

    //this.NewPO.FinalProcessGSMTotal = this.NewPO.ProcessGSMTotal + this.NewPO.RawGSMTotal;
    this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;
    this.calculateGrandTotal();
  }

  GetMixing() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mixing/getallmixings";
    this.http.get<MixingModel[]>(url).subscribe(res => {
      this.MixingList = res;


    }, res => { });
  }
  OnMixingDdlchange() {
    this.MixingSelectedList = [];

    this.listOfSelectedmixing.forEach(item => {
      var checkexist = this.mixingRawMaterial.filter(x => x.MixingId == item).length;
      if (checkexist == 0) {
        var product = this.MixingList.filter(x => x.MixingId == item)[0];
        var mixing = new SaleOrderProductionMixingModel();
        mixing.AddedBy = product.AddedBy;
        mixing.AddedDate = product.AddedDate;
        mixing.SaleOrderProductionMixingId = product.MixingId;
        mixing.ProductionMixingName = product.MixingName;
        mixing.Total = product.Total;
        mixing.Wastage = product.Wastage;
        mixing.WeightGsm = product.WeightGsm;
        mixing.WastageType = product.WastageType ?? "%";
        mixing.MixingRawMaterial = [];

        product.MixingRawMaterial.forEach(x => {
          var raw = new SaleOrderProductionMixingRawMaterialModel();
          raw.Price = x.Price;
          raw.ProductCode = x.ProductCode;
          raw.ProductId = x.ProductId;
          raw.SaleOrderProductionMixingId = x.MixingId;
          raw.SaleOrderProductionMixingRawMaterialId = x.MixingRawMaterialId;
          raw.ProductName = x.ProductName;
          raw.Quantity = x.Quantity;
          raw.Unit = this.ProductRawList.filter(y => y.ProductId == x.ProductId)[0].Unit;
          //raw.AvgGsm = x.AvgGsm;
          mixing.MixingRawMaterial.push(raw);
        })

        this.MixingSelectedList.push(mixing)
      }

    })
    this.NewPO.MixingTotalCost = 0;
    this.MixingSelectedList.forEach(x => {
      this.NewPO.MixingTotalCost += x.Total;
      this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    })
    this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;

  }
  calculateMixingTotal(data: SaleOrderProductionMixingRawMaterialModel) {
    console.log(this.MixingSelectedList)
    var item = this.MixingSelectedList.filter(x => x.SaleOrderProductionMixingId == data.SaleOrderProductionMixingId)[0];

    item.Total = 0;
    item.WeightGsm = 0;
    item.MixingRawMaterial.forEach((element) => {
      item.Total += parseFloat(element.Quantity.toString());
      item.WeightGsm += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });

    this.OnWastagetypeDdlchange(item)

  }
  //calculateMixingGSM() {

  //  this.NewPO.MixingGSMTotal = 0;
  //  this.MixingSelectedList.forEach(x => {
  //    this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
  //  })
  //}
  OnWastagetypeDdlchange(data: SaleOrderProductionMixingModel) {

    var total = 0;
    data.MixingRawMaterial.forEach((element) => {
      total += parseFloat(element.Quantity.toString());

    });

    if (data.WastageType == "%") {

      data.Total = total - ((data.Wastage * total) / 100);

    } else {
      data.Total = total - data.Wastage;
    }
    this.NewPO.MixingTotalCost = this.NewPO.MixingGSMTotal = 0;
    this.MixingSelectedList.forEach(x => {
      this.NewPO.MixingTotalCost += x.Total;
      this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    })
    this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;
  }
  OnLacquerDdlchange() {
    this.LacquerRawMaterial = [];
    //var l = this.LacquerList.filter(x => x.LacquerMasterId == this.NewPO.LacquerMasterId)[0].LacquerRawMaterial;

    //l.forEach(x => {
    //  var m = new SaleOrderProductionLacquerRawMaterialModel;
    //  m.AvgGsm = (isNaN(parseInt(x.AvgGsm?.toString())) ? 0 : parseInt(x.AvgGsm?.toString()));
    //  m.LacquerMasterId = x.LacquerMasterId;
    //  m.Price = x.Price;
    //  m.ProductCode = x.ProductCode;
    //  m.ProductId = x.ProductId;
    //  m.Quantity = x.Quantity;
    //  m.ProductName = x.ProductName;
    //  m.Unit = x.Unit;
    //  m.Total = x.Quantity * x.Price;
    //  this.LacquerRawMaterial.push(m);  
    //})



  }
  calculateElementTotal(data: any) {
    console.log(data)
    data.Total = (isNaN(parseInt(this.NewPO.OrderQuantity?.toString())) ? 0 : parseInt(this.NewPO.OrderQuantity?.toString())) * (isNaN(parseFloat(data.PerUnitCost?.toString())) ? 0 : parseFloat(data.PerUnitCost?.toString()));
    data.TotalCost = data.Total;
    this.calculateGrandTotal();
  }
  calculatePostTotal(data: any) {
    console.log(data)
    data.Total = (isNaN(parseInt(data.Quantity?.toString())) ? 0 : parseInt(data.Quantity?.toString())) * (isNaN(parseFloat(data.Price?.toString())) ? 0 : parseFloat(data.Price?.toString()));
    data.Total = parseFloat(data.Total).toFixed(3);
    this.calculateGrandTotal();
  }
  //calculateGrandTotal() {
  //  var totalprint = this.listOfSelectedPrint.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
  //  if (isNaN(totalprint)) {totalprint = 0;}
  //  var totalEmbossing = this.listOfSelectedEmbossing.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
  //  if (isNaN(totalEmbossing)) { totalEmbossing = 0; }
  //  var totalVaccum = this.listOfSelectedVaccum.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
  //  if (isNaN(totalVaccum)) { totalVaccum = 0; }
  //  var totalTumbling = this.listOfSelectedTumbling.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
  //  if (isNaN(totalTumbling)) { totalTumbling = 0; }
  //  var totalLacquer = this.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
  //  if (isNaN(totalLacquer)) { totalLacquer = 0; }
  //  var totalElement = this.productionElement.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
  //  if (isNaN(totalElement)) { totalElement = 0; }
  //  var totalRaw = this.productionRawMaterial.reduce((sum, current) => sum + parseFloat(current.TotalCost?.toString()), 0) ?? 0
  //  this.NewSaleOrder.GrandTotal = parseFloat(this.NewPO.TotalCost.toString()) + parseFloat(this.NewPO.ColorPrice.toString()) +
  //    parseFloat(this.NewPO.GrainPrice.toString()) + parseFloat(this.NewPO.ThickPrice.toString()) + parseFloat(this.NewPO.WidthPrice.toString()) +
  //    parseFloat(totalprint.toString()) + parseFloat(totalEmbossing.toString()) + parseFloat(totalVaccum.toString()) + parseFloat(totalTumbling.toString()) + parseFloat(totalLacquer.toString()) +
  //    + parseFloat(totalElement.toString()) + parseFloat(totalRaw.toString());
  //}
  calculateGrandTotal() {
    this.NewPO.TotalGsm =
      parseFloat(this.NewPO.PreSkinGsm?.toString()) +
      parseFloat(this.NewPO.SkinGsm?.toString()) +
      parseFloat(this.NewPO.FoamGsm?.toString()) +
      parseFloat(this.NewPO.AdhesiveGsm?.toString()) +
      parseFloat(this.NewPO.FabricGsm?.toString());
  }
  calclulateTotalSalePrice() {
    this.NewPO.TotalSalePrice = parseFloat(((isNaN(this.NewPO.OrderQuantity) ? 0 : this.NewPO.OrderQuantity) * (isNaN(this.NewPO.SalePrice) ? 0 : this.NewPO.SalePrice)).toString()).toFixed(3)
  }
  CheckPostProcess(data: string) {
    if (this.SelectedPostProcess.filter(x => x.PostProcessName == data).length > 0)
      return true;
    else
      return false;
  }

  duplicatePostProcess(process: string) {
    return this.SelectedPostProcess.filter(x => x.PostProcessName == process)
  }

  SetDefaultPostProcessOrder() {
    console.log(this.SelectedPostProcess)
    if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Print').length == 0) {
      this.SaleOrderPostProcessOrder.PrintProcess = 0;
      this.listOfSelectedPrint = [];
    }
    if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Embossing').length == 0) {
      this.SaleOrderPostProcessOrder.EmbossingProcess = 0;
      this.listOfSelectedEmbossing = [];
    }
    if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Lacquar').length == 0) {
      this.SaleOrderPostProcessOrder.LacquerProcess = 0;
      this.listOfSelectedLacquar = [];
    }
    if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Vaccum').length == 0) {
      this.SaleOrderPostProcessOrder.VacuumProcess = 0;
      this.listOfSelectedVaccum = [];
    }
    if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Tumbling').length == 0) {
      this.SaleOrderPostProcessOrder.TumblingProcess = 0;
      this.listOfSelectedTumbling = [];
    }
    //this.NewSaleOrder.SaleOrderPostProcessOrder = new SaleOrderPostProcessOrderModel;
    //var totalvisible = +this.NewSaleOrder.SaleOrderPostProcessOrder.PrintProcess + +this.NewSaleOrder.SaleOrderPostProcessOrder.EmbossingProcess + +this.NewSaleOrder.SaleOrderPostProcessOrder.LacquerProcess + +this.NewSaleOrder.SaleOrderPostProcessOrder.VacuumProcess + +this.NewSaleOrder.SaleOrderPostProcessOrder.TumblingProcess
    //console.log(totalvisible)
    //if (this.SelectedPostProcess.length > totalvisible) {

    //}
    //this.SelectedPostProcess.forEach(el => {
    //  if (el == 'Print') {
    //    this.NewSaleOrder.SaleOrderPostProcessOrder.PrintProcess == 1;
    //  }
    //  if (el == 'Print') {
    //    this.NewSaleOrder.SaleOrderPostProcessOrder.PrintProcess == 1;
    //  }
    //})
  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase() == 'admin').length <= 0) {
      return startValue.getTime() < new Date().getTime();
    }
    else {
      return false;
    }
  };
  enableFabricChange() {
    // this.disableFabricChange = false;
    this.isFabricPopupVisible = true;
    // this.FilterFabricBaseProduct('fabric');
  }
  ResetFabricChange() {
    this.disableFabricChange = true;
  }
  FilterFabricBaseProduct(data: any) {
    this.ProductFilteredFabricBaseList = [];
    this.ProductFilteredFabricBaseList = this.ProductRawList.filter(x => x.ProductCategory.toLowerCase() == data)
  }
  SetFabricProduct() {
    if (this.NewPO.FabricProductId != null) {
      if (this.ActionType == 'edit' && this.SelectedFormulationCode?.FabricProductName == 'N/A') {
        this.selectedFabricName = this.SelectedFormulationCode?.FabricProductName
      }
      else if (this.ActionType == 'edit' && this.NewPO.FabricProductName != 'N/A' && this.NewPO.FabricProductId > 0) {
        var fabricProductStatus = this.ProductList.filter(x => x.ProductId == this.NewPO.FabricProductId)[0] != null ? true : false;
        if (fabricProductStatus) {
          this.selectedFabricName = this.ProductList.filter(x => x.ProductId == this.NewPO.FabricProductId)[0]?.ProductName;
          this.NewPO.FabricProductId = this.SelectedFormulationCode?.FabricProductId;
        }
        else {
          this.alertService.warning("Not able to Fetch Fabric Product. It was not saved during Sale Order Creation. Please select correct Fabric Product.");
        }
      }
      else {
        this.selectedFabricName = this.SelectedFormulationCode?.FabricProductName;
        this.NewPO.FabricProductId = this.SelectedFormulationCode?.FabricProductId;
      }
    }
    if (this.selectedFabricName == "" && this.SelectedFormulationCode == undefined) {
      this.alertService.warning("Not able to Fetch Fabric Product. It was not saved during Sale Order Creation. Please select correct Fabric Product.");
    }
  }
  SetNewFabricProduct() {
    this.NewPO.FabricProductId = this.selectedNewFabric.ProductId;
    this.selectedFabricName = this.selectedNewFabric.ProductName;
    this.NewPO.FabricGsm = this.selectedNewFabric.AvgGsm == null ? 0 : parseFloat(this.selectedNewFabric.AvgGsm);
    this.calculateGrandTotal();
    this.isFabricPopupVisible = false;
  }
  validateFabricProduct() {
    console.log(this.selectedNewFabric)
    if (this.selectedNewFabric.AvgGsm == null || parseFloat(this.selectedNewFabric.AvgGsm) <= 0) {
      this.alertService.warning("GSM is not set for this product. Please get it updated in Product Master before proceeding.");
      return;
    }
    else if (this.selectedNewFabric.Unit == 'Kgs' && this.selectedNewFabric.WidthInMeter <= 0) {
      this.alertService.warning("Unit is Kgs and Width in MTR is not updated for this product. Please get it updated in Product Master before proceeding.");
      return;
    }
    else {
      this.SetNewFabricProduct();
    }
  }

  handleShowProcessList() {
    this.showProcess = !this.showProcess
  }
  closeProcessList() {
    this.showProcess = false
  }

  getSequenceList() {
    const tempList = new Array(this.SelectedPostProcess.length).fill(0).map((_, index) => index + 1);
    const remainingValues = tempList.filter(value => !this.SelectedPostProcess.some(item => item.Rank === value));
    return remainingValues
  }
  GetAllSalesOrderForLinking() {
    var item = ["NotYet",
      "WorkPlan"];
    let url = this.ApiUrl + 'saleorder/getallsaleordersforfilterbystatus';
    this.http.post<SaleOrderModel[]>(url, item).subscribe(
      (res) => {
        this.LinkingSalesOrderList = res.filter(x => x.SaleOrderId != this.SalesOrderId);
      },
      (res) => { }
    );
  }
  loadProformaInvoices(customerId: number): void {
    let url = this.ApiUrl + `proformainvoice/getproformainvoicesbycustomerid/${customerId}`;
    if (customerId) {
      this.http.get<any[]>(url)
        .subscribe(invoices => {
          this.proformaInvoices = invoices;
        });
    } else {
      this.proformaInvoices = [];
    }
  }
  viewPIDrawer(ProformaInvoiceId: any): void {
    this.IsPIDrawerOpen = false;
    this.PIDrawer.ProformaInvoiceId = ProformaInvoiceId;
    this.PIDrawer.show();

  }
  handlePopupCancel() {
    this.isFabricPopupVisible = false;
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
    this.GetAllProductCategory();
  }
}

