<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Category Wise Stock Report</nz-page-header-title>
      <nz-page-header-subtitle>Category Wise Stock report</nz-page-header-subtitle>
      <nz-page-header-extra>

      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Type</label>
                <nz-select [(ngModel)]="request.ProductType" (ngModelChange)="onSelectedProductTypeChange()" nzShowSearch nzPlaceHolder="Choose">

                  <nz-option *ngFor="let s of this.typeList;" [nzValue]="s.name" [nzLabel]="s.name"></nz-option>

                </nz-select>

              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Category</label>
                <nz-select nzShowSearch [(ngModel)]="request.ProductCategoryId" name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllFirstCategory($event)">
                  <nz-option *ngFor="let s of this.FilteredProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Sub-Category</label>
                <nz-select nzShowSearch [(ngModel)]="request.ProductFirstSubCategoryId" name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllSecondCategory($event)">
                  <nz-option *ngFor="let s of this.FilteredProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product 2nd Sub-Category</label>
                <nz-select nzShowSearch [(ngModel)]="request.ProductSecSubCategoryId" name="ProductSecSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Unit</label>
                <nz-select [(ngModel)]="request.Unit" nzShowSearch nzPlaceHolder="Choose" nzAllowClear (ngModelChange)="OnUnitChange()">

                  <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>

                </nz-select>

              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label class="form-label">Supplier</label>
                <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.SupplierId"
                  nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                    [nzLabel]="s.SupplierName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label class="form-label">WIP Store</label>
                <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                  [(ngModel)]="request.IncludeWIPStore" nzPlaceHolder="Choose">
                  <nz-option nzValue=true nzLabel="Exclude"></nz-option>
                  <nz-option nzValue=false nzLabel="Only WIP"></nz-option>
                  <nz-option nzValue=null nzLabel="Include"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 25px" (click)="GetReport()">
              Search
            </button>
          </div>

        </div>


      </div>
    </div>
    <div style="padding: 8px;float:right" *ngIf="ReportList.length>0">
      <button nz-button nzSize="small" (click)="export1()">
        <i nz-icon nzType="export" nzTheme="outline"></i>
        Export
      </button>
    </div>
    <nz-table *ngIf="ReportList.length>0" style="width: 100%" nzSize="small" [nzPageSize]="100" #basicTable [nzData]="[{}]" nzBordered [nzScroll]="{ x: '1200px',y:'515px' }">

      <thead>
        <tr>

          <th nzWidth="60px">S.No.</th>
          <th nzWidth="100px">Type</th>
          <th nzWidth="150px">Category</th>
          <th nzWidth="200px">Subcategory</th>
          <th nzWidth="150px">2nd Subcategory</th>
          <th nzWidth="100px">Unit</th>
          <th nzWidth="120px">Grand Total QTY</th>
          <th nzWidth="120px">Grand Total AMT</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.FirstReport;let i=index">

          <td style="vertical-align: top;">{{i+1}}</td>
          <td style="vertical-align: top;">{{ data.Type}}</td>
          <td style="vertical-align: top;">{{ data.Category }}</td>
          <td style="vertical-align: top;">{{ data.FirstSubCategory}}</td>
          <td style="vertical-align: top;">{{ data.SecSubCategory }}</td>
          <td style="vertical-align: top;">{{ data.Unit }}</td>
          <td style="vertical-align: top;">{{ data.TotalQty }}</td>
          <td style="vertical-align: top;">&#8377;{{ data.TotalAmount }}</td>

        </tr>
      </tbody>
    </nz-table>
    <div style="padding: 8px;float:right" *ngIf="ReportList.length>0">
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i>
        Export
      </button>
    </div>
    <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found" [nzAction]="actionTemplate3"></nz-alert>
    <ng-template #actionTemplate3>
      <!--<button nz-button nzSize="small" nzType="primary" nzGhost (click)="reset()">Reset filters</button>-->
    </ng-template>

    <!--<nz-descriptions nzBordered nzLayout="vertical" *ngIf="ReportList.length>0" [nzColumn]="7">
    <nz-descriptions-item nzTitle="Type" nzColon="true"><b>{{request.ProductType}}</b> </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Category"><b>{{ReportList[0].ProductCategory}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Subcategory" *ngIf="request.ProductFirstSubCategoryId>0"><b>{{ReportList[0].ProductFirstSubCategory}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Subcategory" *ngIf="request.ProductFirstSubCategoryId<=0"><b>ALL</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="2nd Subcategory" *ngIf="request.ProductSecSubCategoryId>0"><b>{{ReportList[0].ProductSecSubCategory}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="2nd Subcategory" *ngIf="request.ProductSecSubCategoryId<=0"><b>ALL</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Unit" *ngIf="request.Unit != null"><b>{{request.Unit}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Unit" *ngIf="request.Unit == null"><b>ALL</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Grand Total Quantity"><b>{{Total.QTY}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Grand Total Amount"><b>{{Total.Amount}}</b></nz-descriptions-item>


  </nz-descriptions>-->

    <nz-divider nzText="Report" *ngIf="ReportList.length>0"></nz-divider>

    <nz-table *ngIf="ReportList.length>0" style="width: 100%" nzSize="small" [nzPageSize]="100" #basicTable [nzData]="[{}]" nzBordered [nzScroll]="{ x: '1200px',y:'515px' }">

      <thead>
        <tr>

          <th nzWidth="80px">S.No.</th>
          <th nzWidth="150px">Product</th>
          <th nzWidth="150px">Product Type</th>
          <th nzWidth="100px">Category</th>
          <th nzWidth="200px">Subcategory</th>
          <th nzWidth="150px">2nd Subcategory</th>
          <th nzWidth="120px">QTY</th>
          <th nzWidth="120px">Price/Unit</th>
          <th nzWidth="120px">Total QTY</th>
          <th nzWidth="120px">Total AMT</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.FilteredReportList;let i=index">

          <td style="vertical-align: top;">{{i+1}}</td>
          <td style="vertical-align: top;">{{ data.ProductName}}</td>
          <td style="vertical-align: top;">{{ data.ProductType }}</td>
          <td style="vertical-align: top;">{{ data.ProductCategory}}</td>
          <td style="vertical-align: top;">{{ data.ProductFirstSubCategory }}</td>
          <td style="vertical-align: top;">{{ data.ProductSecSubCategory }}</td>
          <td colspan="2" style="padding: 0; border: 0; vertical-align: top; ">
            <table>
              <tr *ngFor="let item of data.ChildList">
                <td style="width: 150px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{ item.Qty }}</td>
                <td style="width: 150px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">&#8377;{{ item.Rate }} / {{ data.Unit}}</td>
              </tr>
            </table>
          </td>
          <td style="vertical-align: top;">{{ data.TotalQty }}</td>
          <td style="vertical-align: top;">&#8377;{{ data.TotalAmount }}</td>

        </tr>
      </tbody>
    </nz-table>

  </div>
</div>
