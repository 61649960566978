import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockPrintModel, StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { IUploadProgress, StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { Observable } from 'rxjs';
import { PurchaseOrderModel } from 'src/PmsUIApp/Models/PurchaseOrderModel';
import {
  ProductSecSubCategoryModel,
  ProductFirstSubCategoryModel,
  ProductCategoryModel,
  RackModel,
  StoreModel,
} from '../../Models/MasterModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import moment from 'moment';
@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent {
  ApiUrl = environment.Api_Url;

  ProductListOriginal: ProductModel[] = [];
  ProductList: ProductModel[] = [];

  NewStockProduct: StockProductModel = new StockProductModel();
  FilteredProductList: ProductModel[] = [];

  StockProductList: StockProductModel[] = [];

  StockProductListOriginal: StockProductModel[] = [];
  SelectedProductType: string = '';
  isLoading: boolean = false;
  size: NzButtonSize = 'large';
  PurchaseOrderList: PurchaseOrderModel[] = [];
  FilteredPurchaseOrderList: PurchaseOrderModel[] = [];
  isVisible = false;
  searchValue = '';
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  IsPoGet = false;
  token: any;
  startValue: Date | null = null;
  endValue: Date | null = null;
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  isTableLoading: boolean = false;
  exportoptions = {
    headers: ["PRODUCT TYPE", "PRODUCT NAME", "QUANTITY"]
  };
  fields: any
  // fields: StockPrintModel = new StockPrintModel;
  exportfields: StockPrintModel[] = []
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    public http: HttpClient,
    private router: Router,
    private auth: AuthService
  ) { }

  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }

  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == this.NewStockProduct.ProductId
    )[0];
    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }

  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetReport() {
    var res = this.StockProductListOriginal;
    this.StockProductList = res.filter((item: StockProductModel) => item.ProductType == this.SelectedProductType || this.SelectedProductType == '')
      .filter((item: StockProductModel) => item.ProductCategoryId == this.CategoryID || this.CategoryID == 0 || this.CategoryID == null)
      .filter((item: StockProductModel) => item.ProductFirstSubCategoryId == this.FirstCategoryID || this.FirstCategoryID == 0 || this.FirstCategoryID == null)
      .filter((item: StockProductModel) => item.ProductSecSubCategoryId == this.SecondCategoryID || this.SecondCategoryID == 0 || this.SecondCategoryID == null)
      .filter((item: StockProductModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null)
    console.log(this.SelectedProductType)
    this.setExportData(this.StockProductList);
  }
  GetAllProductWiseStock() {
    let url = this.ApiUrl + 'stock/getproductwisestock';
    this.http.get<StockProductModel[]>(url).subscribe(
      (res) => {
        this.StockProductListOriginal = res;
        this.StockProductList = res.sort((a, b) => {
          return a.AcceptedQuantity - b.AcceptedQuantity;
        });
        this.setExportData(this.StockProductList);

      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProductWiseStock();
        }
      }
    );

  }
  private setExportData(data: any[]) {
    this.exportfields = [];
    data.forEach(x => {
      // this.fields = new StockProductModel;
      this.fields = {};
      this.fields.ProductName = x.ProductName;
      this.fields.ProductType = x.ProductType;
      this.fields.Quantity = x.Quantity + "/" + x.Unit;
      this.exportfields.push(this.fields);
    });
  }

  //export list 
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'stock-export' + exportdate, this.exportoptions);
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Stock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProducts();
    this.GetAllProductWiseStock();
    this.GetAllProductCategory();
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
} 
