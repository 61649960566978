import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { DemandModel } from "../Models/DemandModel";
import { ESalesOrderStatus, Modules, Responsibility } from "../Models/Enums";
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, StoreModel } from "../Models/MasterModel";
import { MeasureUnitModel } from "../Models/MeasureUnitModel";
import { ProductModel } from "../Models/ProductModel";
import { SaleOrderModel } from "../Models/SalesOrderModel";
import { StockModel } from "../Models/StockModel";
import { AlertMessageService } from "../Services/AlertMessageService";
import { AuthService } from "../Services/auth.service";
import { DatetimeConverter } from "../Services/DatetimeConverter.pipe";
import { LoadingService } from "../Services/loadingService";
import { TimelineService } from "../Services/TimelineService";




@Component({
    selector: 'app-Timeline',
    templateUrl: './Timeline.component.html'
})

export class TimelineComponent {
    ApiUrl = environment.Api_Url;
    IsPopupOpen = this.loader.loading$;

    IsDisabled: boolean = false;
    count: 0;
    permission = {
        View: false,
        Add: false,
        Delete: false,
        Manage: false
    }
    TimeLineObject: any;
    SelectedSaleOrderStatusEnum: ESalesOrderStatus = 0;
    ActivityLogList: any;
    isTimelineVisible = false;
    refreshBtnLoading: boolean;
    constructor(private loader: TimelineService, public http: HttpClient, private alertService: AlertMessageService,
        private router: Router,
        private datePipe: DatetimeConverter,
        private auth: AuthService,
        private _loadingService: LoadingService
    ) {
        this.loader.event_callback.subscribe(x => {
            this.GetAllActivityLog(x);
        }
        )

    }
    ngOnInit() {
        console.log(this.TimeLineObject)
        //this.TimeLineObject = null;
        //this.GetFilteredSalesOrder(this.loader.SaleOrderNo)
        //this.permission.View = this.auth.CheckResponsibility(Modules.Demand, Responsibility.View);
        //this.permission.Add = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Add);
        //this.permission.Delete = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Delete);
        //this.permission.Manage = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Manage);
        //if (this.permission.View != true) {
        //  this.router.navigate(['/home/unauthorized']);
        //}

    }

    handleCancel() {
        //this.IsPopupOpen = new BehaviorSubject<boolean>(false);
        this.loader.hide();
        console.log(this.IsPopupOpen)
    }

    GetAllActivityLog(SaleOrderNumber: string) {
        var request = {
            SaleOrderNumber: SaleOrderNumber,
            Status: null,
            DateFrom: null,
            DateTo: null,
            Addedby: null
        }
        this._loadingService.show();
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, request).subscribe(res => {
            console.log(res);
            this.ActivityLogList = res;
            this.SelectedSaleOrderStatusEnum = ESalesOrderStatus[this.ActivityLogList.Status as keyof typeof ESalesOrderStatus]; //Works with --noImplicitAny
            this.isTimelineVisible = true;
            this._loadingService.hide();
        }, res => {
            this._loadingService.hide();
            this.GetAllActivityLog(SaleOrderNumber);
        });
    }
    GetTimelineRefresh(SaleOrderNumber: string) {
        // this.loader.show();
        this.refreshBtnLoading = true;
        var request = {
            SaleOrderNumber: SaleOrderNumber,
            Status: null,
            DateFrom: null,
            DateTo: null,
            Addedby: null
        }
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, request).subscribe(res => {
            this.ActivityLogList = res;
            this.refreshBtnLoading = false;
            //this.loader.hide();
        }, res => { });
    }
    GetTimeLineStatus(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);
            var last = ESalesOrderStatus[this.ActivityLogList[0]?.Status as keyof typeof ESalesOrderStatus];
            console.log("last", last, "st", st);
            if (stt != null) {
                if (st > last) {
                    return "wait";
                }
                else if (stt[0] === undefined) {
                    return "hide";
                }
                else if (stt.length > 0) {
                    return "finish";
                }

                else
                    return "wait";


            }
            else
                return "error";
        } else { return "error"; }
    }
    GetTimeLineDescription(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);
            var last = ESalesOrderStatus[this.ActivityLogList[0]?.Status as keyof typeof ESalesOrderStatus];
            if (stt != null) {
                if (st > last) {
                    return "";
                }
                else
                    if (stt[0] === undefined) {
                        return "Skipped";
                    }
                    else
                        return "By : " + stt[0]?.AddedBy;
            }
            else
                return "";
        } else { return ""; }
    }
    GetTimeLineSubtitle(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);

            if (stt != null) {
                if (stt[0] === undefined) {
                    return "";
                }
                else if (stt[0].WorkPlanNo != null)
                    return "On : " + this.datePipe.transform(stt[0]?.AddedDate) + ' || In WorkPlan No: ' + stt[0]?.WorkPlanNo;
                else
                    return "On : " + this.datePipe.transform(stt[0]?.AddedDate);
            }
            else
                return "";
        } else { return ""; }
    }
    handleTimelineCancel(): void {
        this.loader.hide();
    }
    GetFilteredSalesOrder(SaleOrderNumber: string) {
        var request = {
            FromAddedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
            ToAddedDate: new Date(new Date().setHours(23, 59, 59)),
            FromSaleOrderDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
            ToSaleOrderDate: new Date(new Date().setHours(23, 59, 59)),
            FromDeliveryDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
            ToDeliveryDate: new Date(new Date().setHours(23, 59, 59)),
            SaleFormulationCodeId: 0,
            SaleOrderNumber: SaleOrderNumber,
            DateType: 'addeddate', // options are - addeddate, saleorderdate, deliverydate
            Status: null,
            AddedBy: '',
            ArticleName: '',
            CustomerId: 0,
            GrainId: 0,
            ColorId: 0,
            ProductType: '', //options are - GZ- or GZY-
            OrderType: ''
        }

        let url = this.ApiUrl + 'saleorder/getallsaleorderswithfilter';
        this.http.post<SaleOrderModel[]>(url, request).subscribe(
            (res) => {
                //this.TimeLineObject = res[0];
                //this.SelectedSaleOrderStatusEnum = ESalesOrderStatus[this.TimeLineObject.Status as keyof typeof ESalesOrderStatus]; //Works with --noImplicitAny
                this.GetAllActivityLog(SaleOrderNumber);
            },
            (res) => { }
        );
    }
}
