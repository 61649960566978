import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authService : AuthService, private router: Router,private modalService: NzModalService) { }

   ngOnInit() {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to logout?',
      nzOnOk: () => this.logout(),
      nzOnCancel:()=>this.router.navigate(['/'])
    });

    //setTimeout(() => modal.destroy();, 5000);
      
   }
   logout()
   {
    this.authService.logout();
    this.router.navigate(['/']);
   }

}
