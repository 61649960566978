import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { BranchModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { DemandModel } from '../../Models/DemandModel';
import { DemandPopService } from '../../Services/DemandPopService';
import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { HttpClient } from '@angular/common/http';
import { PostProcessReportModel, PostProcessReportPrintModel } from '../../Models/ReportModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DatetimeConverter } from '../../Services/DatetimeConverter.pipe';
import moment from 'moment';

@Component({
  selector: 'app-PostProcessReport',
  templateUrl: './PostProcessReport.component.html'
})

export class PostProcessReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  PostProcessList: PostProcessReportModel[] = [];
  PostProcessListOriginal: PostProcessReportModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  IsBtnVisible: boolean = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  printtotal: number = 0;
  embosstotal: number = 0;
  vacuumtotal: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  RequestDate = new Date();
  fields: PostProcessReportPrintModel;
  exportfields: PostProcessReportPrintModel[] = [];
  exportoptions = {
    headers: [
      "S. No.",
      "Date",
      "SALE ORDER NO.",
      "Party Name",
      "Code",
      "Grain",
      "Color",
      "Finish",
      "QTY",
      "Printing",
      "Steel Emboss",
      "Vacuum Emboss",
      "Remarks"
    ]
  };
  count: number;
  constructor(private loader: LoadingService, private datePipe: DatetimeConverter, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsPostProcess, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsPostProcess, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsPostProcess, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.PostProcessReport();
  }

  PostProcessReport() {
    this.isTableLoading = true; let url = this.ApiUrl + "report/GetSaleOrderPostProcessReport";
    var request = { PostProcessDate: this.RequestDate }
    this.http.post<PostProcessReportModel[]>(url, request).subscribe(res => {
      console.log(res)
      let data = res;
      this.PostProcessListOriginal = this.PostProcessList = res; this.isTableLoading = false;
      this.printtotal = data.map(a => a.PrintQuantity).reduce(function (a, b) { return a + b; });
      this.embosstotal = data.map(a => a.EmbossQuantity).reduce(function (a, b) { return a + b; });
      this.vacuumtotal = data.map(a => a.VacuumQuantity).reduce(function (a, b) { return a + b; });

      this.exportfields = [];
      var i = 1;
      this.PostProcessList.forEach((x) => {
        this.fields = new PostProcessReportPrintModel;
        this.fields.Sno = i++;
        if ((x.SaleOrderStatus == 'PrintAssigned' || x.SaleOrderStatus == 'PrintInProcess')) {
          this.fields.Date = this.datePipe.transform(x.PrintAcceptedDate.toString());
        }
        else if ((x.SaleOrderStatus == 'EmbossingAssigned' || x.SaleOrderStatus == 'EmbossingInProcess')) {
          this.fields.Date = this.datePipe.transform(x.EmbossAcceptedDate.toString());
        }
        else if ((x.SaleOrderStatus == 'VacuumAssigned' || x.SaleOrderStatus == 'VacuumInProcess')) {
          this.fields.Date = this.datePipe.transform(x.VaccumAcceptedDate.toString());
        }
        else {
          this.fields.Date = "";
        }
        this.fields.SaleOrderNumber = x.SaleOrderNumber;
        this.fields.PartyName = x.CustomerName;
        this.fields.SaleOrderCode = x.SaleOrderCode;
        this.fields.GrainName = x.GrainName;
        this.fields.ColorName = x.ColorName;
        this.fields.FinishCode = x.FinishCode
        this.fields.QTY = x.OrderQuantity
        this.fields.PrintQuantity = (x.SaleOrderStatus == "PrintAssigned" || x.SaleOrderStatus == "PrintInProcess") ? x.PrintQuantity.toString() : "";
        this.fields.EmbossQuantity = (x.SaleOrderStatus == "EmbossingAssigned" || x.SaleOrderStatus == "EmbossingInProcess") ? x.EmbossQuantity.toString() : "";
        this.fields.VacuumQuantity = (x.SaleOrderStatus == "VacuumAssigned" || x.SaleOrderStatus == "VacuumInProcess") ? x.VacuumQuantity.toString() : "";
        this.fields.Remarks = ((x.PrintRemark == null ? '' : "Print: " + x.PrintRemark) + (x.PrintRemark == null ? '' : " | ") + (x.EmbossingRemark == null ? '' : "Emboss: " + x.EmbossingRemark) + (x.EmbossingRemark == null ? '' : " | ") + (x.VacuumRemark == null ? '' : "Vaccum: " + x.VacuumRemark));
        this.exportfields.push(this.fields);

      });
    }, res => {
      this.count++;
      if (this.count < 2) { this.PostProcessReport(); }
    });
  }


  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'Post-Process-report-export' + exportdate,
        this.exportoptions
      );
  }

}
