import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { JumboDispatchPrintModel, JumboInspectionPrintModel, WorkPlanJumboMasterModel } from 'src/PmsUIApp/Models/WorkPlanModel';

@Component({
  selector: 'app-PackingListPrint',
  templateUrl: './PackingListPrint.component.html',
  styleUrls: ['./PackingListPrint.component.css'],
})
export class PackingListPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  JumboDispatchList: JumboDispatchPrintModel[] = [];
  JumboDispatchFilteredList!: JumboDispatchPrintModel;
  JumboDispatchUIFilteredList!: any;
  private route$: Subscription = new Subscription();
  DispatchId = 0;
  count: 0;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.DispatchId = params['id'];
    });
    this.GetAllJumboDispatch();
  }

  GetAllJumboDispatch() {
    let url = this.ApiUrl + 'saleorder/GetJumboDispatchListByDispatchId/' + this.DispatchId;
    this.http.get<JumboDispatchPrintModel>(url).subscribe(
      (res) => {
        this.JumboDispatchFilteredList = res;
        console.log(this.JumboDispatchFilteredList)
        var saleorderlist = [...new Set(this.JumboDispatchFilteredList.JumboInspection.map(item => item.SaleOrderId))];
        var gradelist = [...new Set(this.JumboDispatchFilteredList.JumboInspection.map(item => item.Grade))];
        console.log(saleorderlist)
        console.log(gradelist)
        this.JumboDispatchUIFilteredList = [];
        saleorderlist.forEach(s => {
          var mainItem = {
            SaleOrderId: s,
            SaleOrderCode: this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == s)[0]?.SaleOrderCode,
            SaleOrderManufacturingProductName: this.JumboDispatchFilteredList.JumboList.filter(x => x.SaleOrderCode == this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == s)[0]?.SaleOrderCode)[0]?.SaleOrderManufacturingProductName,
            item: []
          }
          gradelist.forEach(g => {
            var item = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == s && x.Grade == g);

            if (item.length > 0) {
              var it = {
                JumboInspection: item
              };
              mainItem.item.push(it);

            }
          })
          this.JumboDispatchUIFilteredList.push(mainItem);
        })
        console.log(this.JumboDispatchUIFilteredList)
        setTimeout(function () {
          window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllJumboDispatch();
        }
      }
    );
  }

  sumquantity(obj: WorkPlanJumboMasterModel) {
    var sum = obj.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return sum.toFixed(2)
  }

  sumweight(obj: WorkPlanJumboMasterModel) {
    var sum = obj.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return sum.toFixed(2)
  }

  totalsumquantity(SaleOrderId: number) {
    var sum = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == SaleOrderId).reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return sum.toFixed(2)
  }

  totalsumweight(SaleOrderId: number) {
    var sum = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == SaleOrderId).reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return sum.toFixed(2)
  }
  totalsumRoll(SaleOrderId: number) {
    var sum = this.JumboDispatchFilteredList.JumboInspection.filter(x => x.SaleOrderId == SaleOrderId).reduce((accumulator, object) => {
      return accumulator + 1
    }, 0);
    return sum
  }
  grandtotalsumquantity() {
    var sum = this.JumboDispatchFilteredList.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return sum.toFixed(2)
  }
  grandtotalsumweight() {
    var sum = this.JumboDispatchFilteredList.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return sum.toFixed(2)
  }

}
