<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> {{StockId?"Edit Stock":"Opening Stock"}}</nz-page-header-title>
      <nz-page-header-subtitle>{{StockId?"Edit ":" Add " }}stocks here</nz-page-header-subtitle>
      <nz-page-header-extra>     
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    
    <div>
      <div class="fv-row">
        <div class="row gx-10 mb-5">
          <div class="col-lg-3">
            <label class="required form-label">Product Type</label>
            <nz-select class="form-select mb-2" nzSize="default" [disabled]="IsDisabled" [(ngModel)]="SelectedProductType" (ngModelChange)="onSelectedProductTypeChange()" nzAllowClear nzPlaceHolder="Choose">
              <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
              <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
              <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Product Name</label>
            <nz-select nzShowSearch class="form-select " nzSize="default" [disabled]="IsDisabled" [(ngModel)]="NewStockProduct.ProductId" name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Category</label>

            <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [disabled]="IsDisabled" [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
              <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class=" form-label">SubCategory</label>
            <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [disabled]="IsDisabled" [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllSecondCategory($event)">
              <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class=" form-label">2nd SubCategory</label>
            <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [disabled]="IsDisabled" [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="SecondCategoryID" (ngModelChange)="GetSecondCategoryFilteredProduct()">
              <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Supplier</label>
            <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="NewStockProduct.SupplierId" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId" [nzLabel]="s.SupplierName"></nz-option>

            </nz-select>
          </div>

          <div class="col-lg-3">
            <label class="required form-label">Measure unit</label>
            <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="NewStockProduct.Unit" [disabled]="IsDisabled" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
            </nz-select>
          </div>

       
          <div class="col-lg-3">
            <label class="form-label">Barcode</label>
            <input type="text" name="Barcode" nz-input [(ngModel)]="NewStockProduct.Barcode" nzSize="default" class="form-control " placeholder="Barcode Number" value="" />
          </div>
          <div class="col-lg-3">
            <label class="required form-label">{{StockId?"StockDate":"Stock Date"}}</label>
            <nz-date-picker class="form-select" nzValue="default" nzFormat="dd/MM/yyyy" [(ngModel)]="NewStockProduct.StockDate"></nz-date-picker>
            <!-- <input [disabled]="isValid"  nzFormat="dd/MM/yyyy" [(ngModel)]="NewStockProduct.Batch"/> -->
          </div>
          <div class="col-lg-3">
            <label class=" form-label">Manufactured Date</label>
            <nz-date-picker class="form-select" [nzDisabledDate]="disabledStartDate" [(ngModel)]="NewStockProduct.ManufacturedDate" nzValue="default"></nz-date-picker>
          </div>
          <div class="col-lg-3">
            <label class=" form-label">Expiry </label><br>
            <nz-date-picker class="form-select " [nzDisabledDate]="disabledEndDate" [(ngModel)]="NewStockProduct.ExpiryDate" nzValue="default"></nz-date-picker>
          </div>
          <div class="col-lg-3">
            <label class="form-label">SKU</label>
            <input type="text" name="sku" nz-input [(ngModel)]="NewStockProduct.Sku" nzSize="default" class="form-control" placeholder="SKU Number" value="" />
          </div>

          <div class="col-lg-3">
            <label class="required form-label">Quantity</label>
            <input type="text" name="Quantity" nz-input [(ngModel)]="NewStockProduct.Quantity" class="form-control" placeholder="Quantity" value="" />
          </div>

          <div class="col-lg-3">
            <label class="required form-label">Per Unit Price</label>
            <input type="text" class="form-control" nz-input name="price[]" [(ngModel)]="NewStockProduct.PricePerUnit" nzSize="default" placeholder="0.00" value="0.00" data-kt-element="price" />
          </div>
          <div class="col-lg-3">
            <label class="form-label">Grade</label>
            <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewStockProduct.Grade" nzAllowClear nzPlaceHolder="Choose">
              <nz-option nzValue="NA" nzLabel="NA"></nz-option>
              <nz-option nzValue="0" nzLabel="0"></nz-option>
              <nz-option nzValue="1" nzLabel="1"></nz-option>
              <nz-option nzValue="2" nzLabel="2"></nz-option>
              <nz-option nzValue="3" nzLabel="3"></nz-option>
              <nz-option nzValue="4" nzLabel="4"></nz-option>
              <nz-option nzValue="5" nzLabel="5"></nz-option>
              <nz-option nzValue="6" nzLabel="6"></nz-option>
              <nz-option nzValue="7" nzLabel="7"></nz-option>
              <nz-option nzValue="8" nzLabel="8"></nz-option>
              <nz-option nzValue="9" nzLabel="9"></nz-option>
              <nz-option nzValue="A" nzLabel="A"></nz-option>
              <nz-option nzValue="B" nzLabel="B"></nz-option>
              <nz-option nzValue="c" nzLabel="C"></nz-option>
              <nz-option nzValue="D" nzLabel="D"></nz-option>
              <nz-option nzValue="E" nzLabel="E"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Store </label>
            <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()" [(ngModel)]="StoreID" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Rack.</label>
            <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="RackID" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>

            </nz-select>
          </div>


          <div class="col-lg-10">

          </div>
          <div class="col-lg-2" style="text-align: right;padding-top: 25px;">
            <button type="submit" id="kt_ecommerce_add_product_submit" (click)="handleSave()" class="btn btn-success">
              <span class="indicator-label">save</span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        <div class="row gx-10 mb-5">
         
          <!--end::Col-->
        </div>
      </div>
      <div class="mb-10 fv-row">
        
      </div>
      <!--end::Input group-->
    </div>

    </div>
</div>



