import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel, StockPrintModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';

@Component({
  templateUrl: './StockList.component.html',
})
export class StockListComponent {
  StockList: StockModel[];
  FilteredStockList: StockModel[];
  FilteredStockListPage: StockModel[];
  ApiUrl = environment.Api_Url;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  selectedInspectionStatus: string;
  IsPageView: boolean = false;
  IsPageAllocation: boolean = false;
  IsPageInspection: boolean = false;
  IsPageManageRejected: boolean = false;
  isPageLoading: boolean = false;
  exportoptions = {
    headers: [
      'STOCK DATE',
      'INVOICE NO.',
      'INVOICE DATE',
      "GRN",
      'TOTAL PRICE',
      'PRODUCTS',
      'COUNT',
      'INSPECTION',
      'ALLOCATION',
    ],
  };
  fields: StockPrintModel = new StockPrintModel();
  exportfields: StockPrintModel[] = [];
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    public http: HttpClient,
    public router: ActivatedRoute,
    public routerNav: Router,
    private alertService: AlertMessageService,
    private auth: AuthService
  ) {
    this.StockList = [];
    this.FilteredStockList = [];
    this.FilteredStockListPage = [];
    this.selectedInspectionStatus = '';
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  GetAllStocks() {
    this.isPageLoading = true;
    let url = this.ApiUrl + 'stock/getallstocks';
    this.http.get<StockModel[]>(url).subscribe(
      (res) => {
        this.StockList = res;
        console.log(` this.StockList `, this.StockList)
        this.isPageLoading = false;
        this.FilteredStockList = res;
        if (this.IsPageManageRejected) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.ManageRejectedItemsCompleted == false || x.ManageRejectedItemsCompleted == null) && x.AllocationCompleted == true);
        }
        if (this.IsPageAllocation) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.AllocationCompleted == false || x.AllocationCompleted == null) && x.InspectionCompleted == true);
        }
        if (this.IsPageInspection) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.InspectionCompleted == false || x.InspectionCompleted == null));
        }
        this.FilteredStockListPage = this.FilteredStockList;
        this.setExportData(this.FilteredStockList);
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStocks();
        }
      }
    );
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'stock-export' + exportdate, this.exportoptions);
  }
  SearchChange() {
    if (this.selectedInspectionStatus == 'all') {
      this.FilteredStockList = this.StockList;

    }
    if (this.selectedInspectionStatus == 'progress') {
      this.FilteredStockList = this.StockList.filter(
        (x) => x.InspectionCompleted == false
      );

    }
    if (this.selectedInspectionStatus == 'Completed') {
      this.FilteredStockList = this.StockList.filter(
        (x) => x.InspectionCompleted == true
      );

    }
  }

  SetPageType() {
    var pgurl = this.routerNav.url;
    if (pgurl == '/home/stocklist') {
      this.IsPageView = true;
    }

    if (pgurl == '/home/stockinspectionlist') {
      this.IsPageInspection = true;
    }
    if (pgurl == '/home/stockallocationlist') {
      this.IsPageAllocation = true;
    }
    if (pgurl == '/home/stockmanagerejectedlist') {
      this.IsPageManageRejected = true;
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.StockList, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.StockList, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.StockList, Responsibility.Delete);
    if (this.permission.View != true) {
      this.routerNav.navigate(['/home/unauthorized']);
    }
    this.SetPageType();
    this.GetAllStocks();

  }
  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllStocks();
  }


  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.FilteredStockListPage;
    var res2 = this.FilteredStockListPage
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: StockModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.StockDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.StockDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.FilteredStockList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.FilteredStockList = res.filter(
        (item: StockModel) =>
          item?.Products?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Invoice.InvoiceTotalPrice == this.searchValue ||
          item?.Grn?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Invoice.PONumber?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.Invoice.InvoiceNumber.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.StockId == +this.searchValue
      )
    }
    this.setExportData(this.FilteredStockList);

  }

  setExportData(data: StockModel[]) {
    this.exportfields = [];
    data.forEach((x) => {
      this.fields = new StockPrintModel();

      this.fields.StockDate = x.StockDate;
      this.fields.GRN = x.Invoice.Grn;
      this.fields.InvoiceNumber = x.Invoice?.InvoiceNumber;
      this.fields.InvoiceDate = x.Invoice?.InvoiceDate;
      this.fields.InvoiceTotalPrice = parseFloat(x.Invoice?.InvoiceTotalPrice).toFixed(3);
      this.fields.Products = x.Products;
      this.fields.Count = x.Products.split(',').length;
      this.fields.Inspection =
        x.InspectionCompleted == true ? 'Completed' : 'In-Progress';
      this.fields.Allocation =
        x.AllocationCompleted == true ? 'Completed' : 'In-Progress';

      this.exportfields.push(this.fields);
    });
  }
}
