import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { OutPassPurposeModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import * as moment from 'moment';
@Component({
  selector: 'app-outpasspurpose',
  templateUrl: './outpasspurpose.component.html',
  styleUrls: ['./outpasspurpose.component.css']
})
export class OutPassPurposeComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  OutPassPurposeList: OutPassPurposeModel[] = [];
  OutPassPurposeListOriginal: OutPassPurposeModel[] = [];
  NewOPP: OutPassPurposeModel = new OutPassPurposeModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New OutPass Purpose (maximum 50 characters)";
  nameError = 'Enter valid OutPass Purpose name between 3 to 50';
  shortNameError = 'Max length of short name is 20';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;
  totalItemsCount: number;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }



  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.OutPassPurpose, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.OutPassPurpose, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.OutPassPurpose, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      PurposeName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]]
    });
    this.GetAllOutPassPurposes();
  }
  get f() { return this.validateForm.controls; }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllOutPassPurposes() {
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "outpass/getalloutpasspurpose";
    this.http.get<OutPassPurposeModel[]>(url).subscribe(res => {
      this.OutPassPurposeList = res;
      this.OutPassPurposeListOriginal = res
      this.OutPassPurposeList.forEach((x) => {
        count++;
        x.SerialNo = count;
      })
      this.totalItemsCount = count;
      this.isTableLoading = false;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllOutPassPurposes();
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllOutPassPurposes();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.OutPassPurposeListOriginal;
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: OutPassPurposeModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.OutPassPurposeList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.OutPassPurposeList = res.filter(
        (item: OutPassPurposeModel) =>
          item?.PurposeName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.PurposeCode?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

  Save() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewOPP.PurposeName = this.f['PurposeName'].value;

    let url = this.ApiUrl + "outpass/addupdateoutpasspurpose";
    this.http.post<OutPassPurposeModel>(url, this.NewOPP).subscribe({

      next: res => { this.alertService.success("OutPass Purpose Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllOutPassPurposes(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: OutPassPurposeModel) {
    this.PopUpTitle = "Modify OutPass Purpose Details";
    this.validateForm.setValue(
      {
        PurposeName: data.PurposeName

      }
    )
    this.NewOPP = new OutPassPurposeModel();
    this.NewOPP.PurposeId = data.PurposeId;
    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New OutPass Purpose";
    this.validateForm.reset();
    this.NewOPP.PurposeName = "";
    this.NewOPP.PurposeId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.Save();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  Delete() {
    let url = this.ApiUrl + "outpass/deleteoutpasspurpose";
    this.http.post<any>(url, { PurposeId: this.NewOPP.PurposeId }).subscribe({

      next: res => {

        this.alertService.success("OutPass Purpose Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllOutPassPurposes();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: OutPassPurposeModel) {
    this.NewOPP = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this OutPass Purpose?',
      nzOnOk: () => this.Delete(),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

}
