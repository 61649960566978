import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject, interval, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ColorModel, GrainModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, ThicknessModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { DemandPopService } from '../../Services/DemandPopService';
import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { HttpClient } from '@angular/common/http';
import { ProductionPlanningReportExportModel, ProductionPlanningReportModel } from '../../Models/ReportModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';

@Component({
    selector: 'app-ProductionPlanningReport',
    templateUrl: './ProductionPlanningReport.component.html',
    styleUrls: ['./ProductionPlanningReport.component.css']
})

export class ProductionPlanningReportComponent implements OnInit, OnDestroy {
    ApiUrl = environment.Api_Url;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    DemandList: ProductionPlanningReportModel[] = [];
    ProductionPlanningExportList: ProductionPlanningReportExportModel[] = [];
    fields: any;
    PurchaseOrder!: PurchaseOrderModel;
    PurchaseOrderList: PurchaseOrderModel[] = [];
    IsBtnVisible: boolean = false;
    permission = {
        View: false,
        Add: false,
        Delete: false
    }
    SelectedProductType: string = '';
    SelectedStatus: string = '';
    FilteredProductList: ProductModel[] = [];
    ProductList: ProductModel[] = [];
    CategoryID: number = 0;
    FirstCategoryID: number = 0;
    SecondCategoryID: number = 0;
    selectedProduct: number = 0;
    ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
    ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
    ProductCategoryList: ProductCategoryModel[] = [];
    FilteredProductCategoryList: ProductCategoryModel[] = [];
    exportoptions = {
        headers: [
            "Order Date",
            "Order No.",
            "WorkPlan No.",
            "Item Name",
            "P.Code",
            "Grain Name",
            "Colors",
            "Width",
            "Fabric",
            "Thickness",
            "Finish",
            "Order QTY",
            "Pre Skin (GSM)",
            "Skin (GSM)",
            "Foam (GSM)",
            "Adhesive (GSM)",
            "Fabric (GSM)",
            "Final GSM",
            "Party name",
            "Remarks"
        ]
    };
    count: number;
    isEmailReportEnabled: boolean = false;
    isEmailButtonLoading: boolean = false;
    enableCustomDateRange: boolean = false;
    enableWorkShiftDropDown: boolean = false;
    ProductTypeList: any = [
        {
            "Text": "GZ(PVC)",
            "Value": 'GZ-'
        },
        {
            "Text": "GZY(PU)",
            "Value": 'GZY-'
        }
    ];
    ProductionLineList: any = [
        {
            "Text": "All",
            "Value": '0'
        },
        {
            "Text": "Line 1",
            "Value": '1'
        },
        {
            "Text": "Line 2",
            "Value": '2'
        }
    ];
    WorkShiftList: any = [
        {
            "Text": "All",
            "Value": ''
        },
        {
            "Text": "Day Shift (8AM to 8PM)",
            "Value": 'day'
        },
        {
            "Text": "Night Shift (8PM to 8AM)",
            "Value": 'night'
        }
    ];
    request = {
        WorkPlanDate: '',
        WorkPlanToDate: '',
        WorkPlanFromDate: '',
        ProductType: '', //options are - GZ- or GZY-
        DateFilterType: '',
        WorkShift: '',
        ProductionLineNo: 0,
        SaleFormulationCodeId: 0,
        SaleOrderNumber: null,
        Status: null,
        AddedBy: '',
        ArticleName: '',
        CustomerId: 0,
        GrainId: 0,
        ColorId: 0,
        ThicknessId: 0,
        OrderType: '',
    }
    dateFilterOptions: any = [
        {
            "Text": "Day Shift (8AM to 8PM)",
            "Value": 'dayshift'
        },
        {
            "Text": "Night Shift (8PM to 8AM)",
            "Value": 'nightshift'
        },
        {
            "Text": "Today",
            "Value": 'today'
        },
        {
            "Text": "Yesterday",
            "Value": 'yesterday'
        },
        {
            "Text": "Last 7 Days",
            "Value": 'last7days'
        },
        {
            "Text": "Last 30 Days",
            "Value": 'last30days'
        },
        {
            "Text": "Last Month",
            "Value": 'lastmonth'
        },
        {
            "Text": "Last Year",
            "Value": 'lastyear'
        },
        {
            "Text": "Custom Range",
            "Value": 'custom'
        }
    ];
    selecteddateFilter: string = 'today';
    refreshInterval: number = 60; // Input for refresh interval in seconds
    autoRefreshSubscription: any;
    private unsubscribe$ = new Subject<void>();
    autoRefreshBtnText: string = 'Start Auto Refresh';
    CustomerList: CustomerModel[];
    FormulationCodeList: FormulationCodeModel[];
    ColorList: ColorModel[];
    GrainList: GrainModel[];
    ThicknessList: ThicknessModel[];
    constructor(private loader: LoadingService, private demandService: DemandPopService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService,
        private message: NzMessageService) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.ReportsProductionPlanning, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsProductionPlanning, Responsibility.Add);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsProductionPlanning, Responsibility.Delete);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        var currentHour = parseInt(new Date().getHours().toString());
        if (currentHour >= 8 && currentHour < 20) {
            this.getDateRange("dayshift");
            this.selecteddateFilter = 'dayshift';
        }
        else {
            this.getDateRange("nightshift");
            this.selecteddateFilter = 'nightshift';
        }
        //this.startAutoRefresh();
        this.ProductionPlanningReport();
        this.disabledEmailReport();
    }

    ProductionPlanningReport() {
        if (!this.autoRefreshSubscription?.closed) {
            this.message.loading('Refreshing...', { nzDuration: 0 });
        }
        this.isTableLoading = true; let url = this.ApiUrl + "Report/productionplanningreport";

        this.http.post<ProductionPlanningReportModel[]>(url, this.request).subscribe(res => {
            this.message.remove();
            this.DemandList = res; this.isTableLoading = false;
            this.ProductionPlanningExportList = [];
            this.DemandList.forEach((x) => {
                this.fields = new ProductionPlanningReportExportModel();
                this.fields.OrderDate = x.OrderDate;
                this.fields.SaleOrderNo = x.SaleOrderNo;
                this.fields.WorkPlanNo = x.WorkPlanNo;
                this.fields.ItemName = x.ItemName;
                this.fields.PCode = x.PCode;
                this.fields.Grain = x.Grain;
                this.fields.Colours = x.Colours;
                this.fields.Width = x.Width;
                this.fields.Fabric = x.Fabric;
                this.fields.Thick = x.Thick;
                this.fields.Finish = x.Finish;
                this.fields.OrderQty = x.OrderQty;
                this.fields.PreSkin = x.PreSkin;
                this.fields.Skin = x.Skin;
                this.fields.Adhesive = x.Adhesive;
                this.fields.Foam = x.Foam;
                this.fields.FabricGsm = x.FabricGsm;
                this.fields.FinalGSM = (x.PreSkin + x.Skin + x.Adhesive + x.Foam + x.FabricGsm);
                this.fields.PartyName = x.PartyName;
                this.fields.Remarks = x.Remarks ?? "";
                this.ProductionPlanningExportList.push(this.fields);
            })
        }, res => {
            this.message.remove();
            this.count++;
            if (this.count < 2) { this.ProductionPlanningReport(); }
        });
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    export() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        if (this.ProductionPlanningExportList.length > 0)
            new AngularCsv(
                this.ProductionPlanningExportList,
                'production-planning-report-export' + exportdate,
                this.exportoptions
            );
    }
    sendEmail() {
        this.isEmailButtonLoading = true;
        let url = this.ApiUrl + "Report/productionplanningreportondemandemail";
        var request = { WorkPlanDate: this.request.WorkPlanDate }
        this.http.post<ProductionPlanningReportModel[]>(url, request).subscribe(res => {
            this.isEmailButtonLoading = false;
            this.alertService.success('Email Sent Successfully!!');
        }, res => {
            this.count++;
            if (this.count < 2) { this.sendEmail(); }
        });
        this.isEmailButtonLoading = false;
    }
    disabledEmailReport() {
        this.isEmailReportEnabled = UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase().includes('super admin')).length > 0 ? true : false;
        //if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase() == 'super admin')) {
        //  this.isEmailReportEnabled = true;
        //}
        //else {
        //  this.isEmailReportEnabled = false;
        //}
    }
    getDateRange(label: string) {
        this.enableCustomDateRange = false;
        this.enableWorkShiftDropDown = false;
        this.request.DateFilterType = '';
        this.request.WorkShift = '';
        const today = new Date();
        let startDate: Date;
        let endDate: Date;

        switch (label) {
            case 'dayshift':
                startDate = new Date(new Date(new Date().setHours(8, 0, 0)))
                endDate = new Date(new Date(new Date().setHours(20, 0, 0)))
                this.request.DateFilterType = 'fullday'
                this.request.WorkShift = 'day'
                break;

            case 'nightshift':
                var currentHour = parseInt(new Date().getHours().toString());
                if (currentHour >= 0 && currentHour <= 8) {
                    startDate = new Date(new Date(new Date().setHours(20, 0, 0)).setDate(new Date().getDate() - 1))
                    endDate = new Date(new Date().setHours(8, 0, 0))
                }
                else {
                    startDate = new Date(new Date().setHours(20, 0, 0))
                    endDate = new Date(new Date(new Date().setHours(8, 0, 0)).setDate(new Date().getDate() + 1))
                }
                this.request.DateFilterType = 'fullday'
                this.request.WorkShift = 'night'
                break;

            case 'today':
                startDate = new Date(today);
                endDate = new Date(today);
                this.request.DateFilterType = 'fullday'
                this.enableWorkShiftDropDown = true;
                break;

            case 'yesterday':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                endDate = new Date(today);
                endDate.setDate(today.getDate() - 1);
                this.request.DateFilterType = 'fullday'
                this.enableWorkShiftDropDown = true;
                break;

            case 'last7days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 6);
                endDate = new Date(today);
                this.request.DateFilterType = 'fullday'
                this.enableWorkShiftDropDown = true;
                break;

            case 'last30days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 29);
                endDate = new Date(today);
                this.request.DateFilterType = 'fullday'
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastmonth':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                this.request.DateFilterType = 'fullday'
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastyear':
                startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
                endDate = new Date(today);
                this.request.DateFilterType = 'fullday'
                this.enableWorkShiftDropDown = true;
                break;

            case 'custom':
                startDate = new Date(today);
                endDate = new Date(today);
                this.enableCustomDateRange = true;
                this.enableWorkShiftDropDown = true;
                this.request.DateFilterType = 'datetime'
                break;

            default:
                startDate = new Date();
                endDate = new Date();
                break;
        }
        this.request.WorkPlanFromDate = startDate.toISOString();
        this.request.WorkPlanToDate = endDate.toISOString();
        this.ProductionPlanningReport();
    }

    setAutoRefreshTime(seconds: number) {
        this.refreshInterval = seconds;
        this.message.info('Refresh Interval set to ' + seconds + ' seconds')
        this.startAutoRefresh();
    }

    startAutoRefresh(): void {
        this.autoRefreshSubscription = interval(this.refreshInterval * 1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.ProductionPlanningReport();
            });
    }

    setAutoRefreshAction(): void {
        if (!this.autoRefreshSubscription?.closed) {
            this.autoRefreshSubscription.unsubscribe();
            this.autoRefreshBtnText = 'Start Auto Refresh';
            this.message.info('Auto refresh stopped');
        }
        else {

            this.startAutoRefresh();
            this.message.info('Auto refresh started');
            this.autoRefreshBtnText = 'Stop Auto Refresh';
        }
    }
    CalculateTotal(column: string) {
        var total = 0;
        switch (column) {
            case column = "SaleOrderQuantity": {
                total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.OrderQty?.toString()), 0) ?? 0
                break;
            }
        }
        return parseFloat(total.toFixed(2));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    GetAllCustomer() {
        this.loader.show();
        let url = this.ApiUrl + "customer/getallcustomers";
        this.http.get<CustomerModel[]>(url).subscribe({
            next: res => {
                this.CustomerList = res;
                this.loader.hide();
            },
            error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.GetAllCustomer(); }
            }
        });
    }
    GetAllFormulationCode() {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/getallformulationcodesforfilter";
        this.http.get<FormulationCodeModel[]>(url).subscribe({
            next: res => {
                this.FormulationCodeList = res;
                this.loader.hide();
            },
            error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) { this.GetAllFormulationCode(); }
            }
        });
    }
    GetAllColor() {
        this.loader.show();
        let url = this.ApiUrl + "Color/getallColors";
        this.http.get<ColorModel[]>(url).subscribe({
            next: res => {
                this.ColorList = res;
                this.loader.hide();
            },
            error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) {
                    this.GetAllColor();
                }
            }
        });
    }
    GetAllGrain() {
        this.loader.show();
        let url = this.ApiUrl + "Grain/getallGrains";
        this.http.get<GrainModel[]>(url).subscribe({
            next: res => {
                this.GrainList = res;
                this.loader.hide();
            }, error: () => {
                this.loader.hide();
                this.count++;
                if (this.count < 2) {
                    this.GetAllGrain();
                }
            }
        });
    }
    GetAllThickness() {
        let url = this.ApiUrl + "thickness/getallthicknessdata";
        this.http.get<ThicknessModel[]>(url).subscribe(res => {
            this.ThicknessList = res;
        }, () => {
            this.count++
            if (this.count < 2) {
                this.GetAllThickness()
            }
        });
    }
    space(el: any) {
        if (el.target.selectionStart === 0 && el.code === "Space") {
            el.preventDefault();
        }
    }
    onFilterPanelOpen(data: any) {
        if (data == true) {
            this.loader.show();
            this.GetAllCustomer();
            this.GetAllFormulationCode();
            this.GetAllColor();
            this.GetAllGrain();
            this.GetAllThickness();
        }
    }
}
