import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { TagModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {

  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  TagList: TagModel[] = [];
  NewTag: TagModel = new TagModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Tag";
  NameError = 'Enter Tag name (maximum 50 characters)';
  searchValue = '';
  TagListOriginal: TagModel[] = [];
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: number;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,

    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Tag, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Tag, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Tag, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      TagName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      TagDesc: [null, [Validators.maxLength(200)]]

    });
    this.GetAllTag();
  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllTag();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.TagListOriginal;
    var res2 = this.TagListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: TagModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.TagAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.TagAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.TagList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.TagList = res.filter(
        (item: TagModel) =>
          item?.TagName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.TagDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.TagAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())



      )
    }

  }


  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }


  GetAllTag() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "tag/getalltags";
    this.http.get<TagModel[]>(url).subscribe(res => {
      this.TagList = res;
      this.TagListOriginal = res

      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllTag(); }
    });
  }
  SaveTag() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewTag.TagName = this.f['TagName'].value;

    this.NewTag.TagDesc = this.f['TagDesc'].value;
    this.NewTag.TagAddedBy = UserInfo.EmailID;
    var data = this.TagList.filter(x => x.TagName == this.NewTag.TagName);
    if (data.length > 0) {
      if (this.NewTag.TagId > 0) {
        var res = this.TagList.filter(x => x.TagId != this.NewTag.TagId);
        var nre = res.filter(x => x.TagName == this.NewTag.TagName);
        if (nre.length > 0) {
          this.alertService.error("Tag already exist. Please try again")
          this.isLoading = false
          return;
        }
      }
      else {
        this.alertService.error("Payment Term already exist. Please try again")
        this.isLoading = false
        return;
      }
    }
    // if(!this.Validate(this.NewTag))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "tag/addupdatetag";
    this.http.post<TagModel>(url, this.NewTag).subscribe({

      next: res => { this.alertService.success("Tag Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllTag(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: TagModel) {
    this.PopUpTitle = "Modify Tag Details";
    this.validateForm.setValue(
      {
        TagName: data.TagName,

        TagDesc: data.TagDesc,

      }
    )
    this.NewTag = new TagModel();
    this.NewTag.TagId = data.TagId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Tag";
    this.validateForm.reset();
    this.NewTag.TagName = "";
    this.NewTag.TagId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveTag();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteTag() {
    let url = this.ApiUrl + "Tag/deleteTag";
    this.http.post<any>(url, this.NewTag.TagId).subscribe({

      next: res => {

        this.alertService.success("Tag Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllTag();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: TagModel) {
    this.NewTag = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Tag?',
      nzOnOk: () => this.DeleteTag(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.TagList.filter(x => x.TagId != this.NewTag.TagId);
        var nre = res.filter(x => x.TagName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Tag name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Tag name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Tag name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
}
