<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Order Cost Estimation</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
      <div style="margin:10px">
        <div nz-row [nzGutter]="24" style="margin: 0px 0px; background: #f0f2f5; border-radius: 4px;">
          <nz-divider></nz-divider>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label class="required">Order Type</label>
                <nz-select class="form-select mb-2" nzShowSearch name="OrderType" nzSize="default"
                  nzPlaceHolder="Choose" [(ngModel)]="NewCE.SaleOrderType" [ngModelOptions]="{standalone: true}">
                  <nz-option nzValue="Sample" nzLabel="Sample"></nz-option>
                  <nz-option nzValue="Trial" nzLabel="Trial"></nz-option>
                  <nz-option nzValue="Product" nzLabel="Product"></nz-option>
                  <nz-option nzValue="Job Work" nzLabel="Job Work"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select Customer Name">
                <label class="required">Estimation for</label>
                <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                  [(ngModel)]="NewCE.CustomerId" [ngModelOptions]="{standalone: true}" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                    [nzLabel]="s.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Remark">
                <label for="remark">Remark</label>
                <textarea nz-input name="remark" id="remark" placeholder="Remark" style="margin-left: -4px;"
                  [(ngModel)]="NewCE.Remarks" [ngModelOptions]="{standalone: true}"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
          <div nz-col [nzSpan]="24" style="background: #f0f2f5; border-radius: 4px; ">
            <nz-divider nzText="Formulation Code"></nz-divider>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="this.FormulationCodeDropDownSpan">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                    <label class="required">Formulation Code</label>
                    <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                      [(ngModel)]="NewCE.EstimationFormulationCodeId" (ngModelChange)="OnFormulationddlChange()"
                      [ngModelOptions]="{standalone: true}" nzPlaceHolder="Choose">
                      <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                        [nzLabel]="s.SaleFormulationCode"></nz-option>
                    </nz-select>
                    <label *ngIf="this.NewCE.EstimationFormulationCodeId > 0">Fabric/Base Name:
                      <b>{{this.selectedFabricName}}</b>
                    </label>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Category">
                    <label>Category</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch
                      [(ngModel)]="NewCE.ProductCategoryId" nzPlaceHolder="Choose"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                        [nzLabel]="s.ProductCategory"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select Thickness">
                    <label>Thick</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewCE.Thick"
                      nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                        [nzLabel]="s.ThicknessNumber"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Grain">
                    <label class="required">Grain</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewCE.GrainId"
                      (ngModelChange)="updateGrainPrice()" nzAllowClear nzPlaceHolder="Choose"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                        [nzLabel]="s.GrainName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select Width">
                    <label class="required">Width</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewCE.Width"
                      nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.WidthList;" [nzValue]="s.WidthId"
                        [nzLabel]="s.WidthNumber"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select Article Name">
                    <label class="required">Article Name</label>
                    <input nz-input name="MFG" placeholder="Article Name" [(ngModel)]="NewCE.ManufacturingProductName"
                      [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Quantity">
                    <label class="required">QTY</label>
                    <input nz-input name="OrderQuantity" placeholder="OrderQuantity" value="NewCE.OrderQuantity"
                      [(ngModel)]="NewCE.OrderQuantity" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Price">
                    <label class="required">Estimated Price</label>
                    <input nz-input name="SalePrice" value="this.NewCE.EstimationPrice"
                      [(ngModel)]="NewCE.EstimationPrice" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="calculateTotalEstimatedPrice()" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Total Price">
                    <label class="required">Total Estimated Price</label>
                    <input nz-input name="Total" disabled value="this.TotalEstimatedPrice"
                      [(ngModel)]="this.TotalEstimatedPrice" [ngModelOptions]="{standalone: true}"
                      style="background: #fff;color: #000;" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Color">
                    <label class="required">Color</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewCE.ColorId"
                      nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                        [nzLabel]="s.ColorName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
        <nz-divider nzText="Mixing"></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
          <div nz-col [nzSpan]="24" style="background-color: #f0f2f5; border-radius: 4px;">
            <nz-divider></nz-divider>
            <div nz-col [nzSpan]="24">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                  <label class="required">Select Mixing</label>
                  <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple"
                    [(ngModel)]="listOfSelectedmixing" (ngModelChange)="OnMixingDdlchange()" nzAllowClear
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let s of this.MixingList;" [nzValue]="s.MixingId"
                      [nzLabel]="s.MixingName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <div *ngFor="let i of MixingSelectedList"
                style="padding: 5px; border: 1px solid #ccc; border-radius: 4px; margin-top: 10px; background: #fff; box-shadow: 1px 1px 2px 2px #415994;">
                <div style="padding: 10px 0;text-transform: uppercase;">
                  Mixing Name :
                  <b>{{i.MixingName}}</b>
                  <a class="btn btn-sm btn-light-success" style="float: right; margin-bottom: 10px; "
                    (click)="openAddModel(i)"><i nz-icon nzType="plus-circle" nzTheme="fill"
                      style=" margin-bottom: 3px;"></i>Add</a>
                </div>
                <nz-table nzSize="small" [nzPageSize]="100" [nzData]="['']" #basicTable1 nzBordered
                  nzShowPagination="false">
                  <thead>
                    <tr>
                      <th nzWidth="50px">S.No</th>
                      <th>Product</th>
                      <th nzWidth="100px">Code</th>
                      <th nzWidth="50px">Unit</th>
                      <th nzWidth="100px">Price</th>
                      <th nzWidth="100px">QTY</th>
                      <th nzWidth="100px">Total Cost</th>
                      <th nzWidth="120px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of i.MixingRawMaterial;let i=index">
                      <td nzWidth="20px">{{i+1}}</td>
                      <td>{{ data.ProductName }}</td>
                      <td>{{ data.ProductCode }}</td>
                      <td>{{ data.Unit }}</td>
                      <td>
                        <input type="number" style="width: 80%;" placeholder="Price" nz-input [(ngModel)]="data.Price"
                          (ngModelChange)="updatePrice(i, data, $event)" [ngModelOptions]="{standalone: true}" />
                      </td>
                      <td>
                        <input type="number" style="width: 80%;" placeholder="Quantity" nz-input
                          [(ngModel)]="data.Quantity" (ngModelChange)="updateQuantity(i, data, $event)"
                          [ngModelOptions]="{standalone: true}" />
                      </td>
                      <td>{{(data.Price * data.Quantity).toFixed(2)}}</td>
                      <td>
                        <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)"><i
                            nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Remove</a>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </div>
              <br>
            </div>
          </div>
        </div>
        <nz-divider nzText="Fabric Details"></nz-divider>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="8">
          <nz-divider nzText="Fabric Details"></nz-divider>
          <nz-descriptions-item nzTitle="Material Type">
            <nz-select class="form-select mb-2" nzSize="default" nzPlaceHolder="Choose" [(ngModel)]="MaterialType"
              (ngModelChange)="initilizeProductType($event)" [ngModelOptions]="{standalone: true}">
              <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
              <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
              <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
            </nz-select>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Category">
            <nz-form-control [nzErrorTip]="'Category'">
              <nz-select nzSize="default" [(ngModel)]="FabricCategory"
                (ngModelChange)="filterProductDetailsByCategory()" nzPlaceHolder="Select Category"
                [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let s of this.ProductCategoryList" [nzValue]="s"
                  [nzLabel]="s.ProductCategory"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Product Name">
            <nz-form-control [nzErrorTip]="'Product Name'">
              <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="selectedProduct"
                nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let s of filterProductBasedOnProductTypeAndCategory;" [nzValue]="s"
                  [nzLabel]="s.ProductName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Unit" [nzSpan]="1">
            <nz-form-control [nzErrorTip]="'Unit'">
              <span>{{ selectedProduct?.Unit }}</span>
            </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="GSM" [nzSpan]="1">
            <nz-form-control [nzErrorTip]="'GSM'">
              <input nz-input placeholder="GSM" type="number" [(ngModel)]="FabricGsm"
                [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Quantity" [nzSpan]="1">
            <nz-form-control [nzErrorTip]="'Quantity'">
              <input nz-input placeholder="Quantity" type="number" [(ngModel)]="FabricQuantity"
                [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Price" [nzSpan]="1">
            <nz-form-control [nzErrorTip]="'Price'">
              <input nz-input placeholder="Price" type="number" [(ngModel)]="FabricPrice"
                [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Total Price" [nzSpan]="1">
            <nz-form-control [nzErrorTip]="'Total Price'">
              <span>{{ (FabricQuantity * FabricPrice) || '' }}</span> </nz-form-control>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="">
            <nz-form-control>
              <a class="btn btn-sm btn-light-success" style="float: right; margin-bottom: 10px;"
                (click)="addFabricData()">
                <i nz-icon nzType="plus-circle" nzTheme="fill" style="margin-bottom: 3px;"></i> Add
              </a>
            </nz-form-control>
          </nz-descriptions-item>
        </nz-descriptions>
        <nz-table *ngIf="NewCE.FabricsData.length > 0" [nzData]="NewCE.FabricsData" nzBordered nzSize="small">
          <thead>
            <tr>
              <th nzTitle="S. No.">S.No</th>
              <th nzTitle="Product Name">Product Name</th>
              <th nzTitle="GSM">GSM</th>
              <th nzTitle="Quantity">Quantity</th>
              <th nzTitle="Price">Price</th>
              <th nzTitle="Total Price">Total Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of NewCE.FabricsData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ row.FabricProductName }}</td>
              <td>{{ row.FabricGsm }}</td>
              <td>{{ row.FabricEstimationQuantity }}</td>
              <td>{{ row.FabricProductCostPerLm }}</td>
              <td>{{ row.FabricEstimationQuantity * row.FabricProductCostPerLm }}</td>
              <td>
                <a class="btn btn-sm btn-light-danger" style="float: right; margin-bottom: 10px;"
                  (click)="removeFabricData(i)">
                  <i nz-icon nzType="minus-circle" nzTheme="fill" style="margin-bottom: 3px;"></i> Remove
                </a>
              </td>
            </tr>
            <tr style="font-weight: bold;">
              <td></td>
              <td>Grand Total:</td>
              <td>{{ TotalFabricGSM }}</td>
              <td>{{ TotalFabricQuantity }}</td>
              <td>{{ TotalFabricPrice }}</td>
              <td>{{ GrandTotalFabricPrice }}</td>
            </tr>
          </tbody>
        </nz-table>

        <nz-divider nzText="Weight in GSM"></nz-divider>
        <div nz-row [nzGutter]="24"
          style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding: 30px 0;    background: rgb(240, 242, 245);">
          <div nz-col [nzSpan]="6">
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="3">
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Pre Skin</label><br>
                  <input nz-input type="number" [(ngModel)]="PreSkinGsm" [ngModelOptions]="{standalone: true}"
                    name="PreSkinGsm" (ngModelChange)="calculateGSMGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Skin</label>
                  <input nz-input type="number" [(ngModel)]="SkinGsm" [ngModelOptions]="{standalone: true}"
                    name="SkinGsm" (ngModelChange)="calculateGSMGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Foam</label>
                  <input nz-input type="number" [(ngModel)]="FoamGsm" [ngModelOptions]="{standalone: true}"
                    name="FoamGsm" (ngModelChange)="calculateGSMGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Adhesive</label>
                  <input nz-input type="number" [(ngModel)]="AdhesiveGsm" [ngModelOptions]="{standalone: true}"
                    name="AdhesiveGsm" (ngModelChange)="calculateGSMGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="5">
              <nz-form-item>
                <nz-form-control style="margin-left: -70px;" [nzSpan]="20"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Fabric (GSM)</label>
                  <input nz-input type="number" readonly [(ngModel)]="TotalFabricGSM"
                    [ngModelOptions]="{standalone: true}" name="FabricGsm" (ngModelChange)="calculateGSMGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col [nzSpan]="15">
          </div>
          <div nz-col [nzSpan]="9">
            <nz-descriptions [nzColumn]="1">
              <nz-descriptions-item nzTitle="Total (GSM)" nzColon="true">
                <input nz-input type="text" readonly style="width:130px;" [(ngModel)]="TotalGsm"
                  [ngModelOptions]="{standalone: true}" />
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
        </div>
        <nz-divider></nz-divider>
        <!-- <div><b
                  style="margin-left: 15px;">Inline Scraping(Optional) : </b> <input type="number"
                  style="width: 20%;margin-right: 3px" placeholder="Per LM Constant"
                  [disabled]="this.NewSaleOrder.SaleOrderCosting != null" (ngModelChange)="calculateInline($event)" nz-input
                  [(ngModel)]="this.InlineScraping" /> </div> -->
        <div *ngIf="havePostProcess" nz-col [nzSpan]="24">
          <nz-divider nzText="Costing Details"></nz-divider>
          Note: Costing Details are calculated after the consumption for raw material is completed and if there
          are
          Post Process included in this order then that also needs to be processed before to show the actual
          cost.
          <nz-divider></nz-divider>
          <nz-descriptions *ngIf="havePostProcess" nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">
            <nz-descriptions-item nzTitle="Material Type">
              <nz-select class="form-select mb-2" nzSize="default" nzPlaceHolder="Choose" [(ngModel)]="MaterialType"
                (ngModelChange)="initilizeMaterialType($event)" [ngModelOptions]="{standalone: true}">
                <nz-option nzValue="PVC" nzLabel="PVC"></nz-option>
                <nz-option nzValue="PU" nzLabel="PU"></nz-option>
              </nz-select>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Finishing">
              <nz-select class="form-select mb-2" nzSize="default" nzPlaceHolder="Choose" [(ngModel)]="FinishingType"
                (ngModelChange)="fetchFinishingType($event)" [ngModelOptions]="{standalone: true}">
                <nz-option nzValue="Printing" nzLabel="Printing"></nz-option>
                <nz-option nzValue="Embossing" nzLabel="Embossing"></nz-option>
                <nz-option nzValue="Tumbling" nzLabel="Tumbling"></nz-option>
                <nz-option nzValue="Vacuum" nzLabel="Vacuum"></nz-option>
                <nz-option nzValue="Lacquer" nzLabel="Lacquer"></nz-option>
              </nz-select>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Finishing Type">
              <nz-select class="form-select mb-2" nzSize="default" nzPlaceHolder="Choose"
                [(ngModel)]="SelectedFinishingType" (ngModelChange)="StoreFinishingType($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let option of FinishingTypeData" [nzValue]="option.Code"
                  [nzLabel]="option.Name"></nz-option>
              </nz-select>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Price">
              <input type="number" class="form-control mb-2" [(ngModel)]="Price" (ngModelChange)="StorePrice($event)"
                [ngModelOptions]="{standalone: true}" name="price" placeholder="Enter Price">
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="">
              <a class="btn btn-sm btn-light-success" style="float: right; margin-bottom: 10px;" (click)="addData()">
                <i nz-icon nzType="plus-circle" nzTheme="fill" style="margin-bottom: 3px;"></i> Add
              </a>
            </nz-descriptions-item>
          </nz-descriptions>
          <nz-table *ngIf="dataRows.length > 0" [nzData]="dataRows" nzBordered nzSize="small">
            <thead>
              <tr>
                <th nzTitle="Material Type">Material Type</th>
                <th nzTitle="Finishing Type">Finishing Type</th>
                <th nzTitle="Selected Finishing Type">Selected Finishing Type</th>
                <th nzTitle="Price">Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataRows; let i = index">
                <td>{{ row.MaterialType }}</td>
                <td>{{ row.FinishingType }}</td>
                <td>{{ row.SelectedFinishingType }}</td>
                <td>{{ row.Price }}</td>
                <td>
                  <a class="btn btn-sm btn-light-danger" style="float: right; margin-bottom: 10px;"
                    (click)="removeData(i)">
                    <i nz-icon nzType="minus-circle" nzTheme="fill" style="margin-bottom: 3px;"></i> Remove
                  </a>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <br>
          <div>
            <nz-table [nzData]="MixingSelectedList" #basicTable1 nzBordered nzShowPagination="false">
              <thead>
                <tr>
                  <th>Mixing Name</th>
                  <th>Per Kg Cost</th>
                  <th>GSM</th>
                  <th>Cost Per Lm</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of MixingSelectedList">
                  <td>{{ i.MixingName }}</td>
                  <td>{{ calculateTotalPriceForItem(i) }}</td>
                  <td> {{
                    i.MixingName === 'PRE SKIN' ? this.PreSkinGsm :
                    (i.MixingName === 'SKIN' ? this.SkinGsm :
                    (i.MixingName === 'ADHESIVE' ? this.AdhesiveGsm :
                    (i.MixingName === 'FOAM' ? this.FoamGsm : 0)))
                    }}
                  <td>{{ calculateCostPerLmForItem(i) }}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>
          <div>
            <br>
            <nz-table [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
              <thead>
                <tr>
                  <th></th>
                  <th>GSM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Fabric</td>
                  <td>{{ this.FabricGsm }}</td>
                </tr>
                <tr>
                  <td>Coating</td>
                  <td>{{ this.Coating }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{ this.FabricGsm + this.Coating}}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>
          <br>
          <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">
            <nz-descriptions-item nzTitle="Per LM Constant" [nzSpan]="1">
              <input type="number" style="width: 80%;" placeholder="Per LM Constant"
                (ngModelChange)="calculateLMMost($event)" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="this.PerLMConstant" />
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PASTE COST / LM" [nzSpan]="1" nzColon="true">
              <b>{{ NewCE.TotalCostPerLm }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="GRAIN COST / LM" [nzSpan]="1" nzColon="true"><b>
                {{ NewCE.GrainPrice }}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="FABRIC COST/LM" [nzSpan]="1" nzColon="true"><b>
                {{GrandTotalFabricPrice}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="FINISHING COST/ LM" [nzSpan]="1" nzColon="true">
              <b>
                {{NewCE.TotalFinishPrice}}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="R\M COST / LM">
              <b>
                {{NewCE.TotalCostPerLm + NewCE.GrainPrice + GrandTotalFabricPrice +
                NewCE.TotalFinishPrice}}
              </b>
            </nz-descriptions-item>
          </nz-descriptions>
          <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">
            <nz-descriptions-item nzTitle="** REJECTION %">
              <div class="row">
                <div class="column" style="width: 50%;">
                  <input type="number" style="width: 80%;" placeholder="Rejection Constant"
                    (ngModelChange)="calclulateRejection($event)" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="NewCE.Rejection" />
                </div>
                <div class="column" style="width: 50%;">
                  <b>
                    {{ NewCE.Rejection }}
                  </b>
                </div>
              </div>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PRODUCTION COST / LM">
              <b>
                {{ NewCE.ProductionCostLm }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Line Speed">
              <input type="number" style="width: 80%;" placeholder="Line Speed" nz-input
                [(ngModel)]="this.NewCE.LineSpeed" [ngModelOptions]="{standalone: true}" />
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Overhead">
              <input type="number" style="width: 80%;" placeholder="Overhead cost" nz-input
                [(ngModel)]="this.NewCE.OverheadCost" (ngModelChange)="calclulateProductinCostPerLm($event)"
                [ngModelOptions]="{standalone: true}" />
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Cost">
              <b>
                {{ NewCE.TotalCostPerLm }}
              </b>
            </nz-descriptions-item>
          </nz-descriptions>
          <p style="margin: 10px;">** Rejection Percentage shown above is auto calculated by taking sum quantity
            of
            these Graded Mini Rolls - LOT, NS, CUT-PC, FILM, Waste. Each Roll Details can be seen on Yield
            Report.
          </p>
          <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Estimated QTY">
              <div>
                {{NewCE.OrderQuantity}}
              </div>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Production Line Speed">
              <b>
                {{ NewCE.LineSpeed}}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Formulation Code">
              <b>
                {{ this.SaleFormulationCode }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Estimated Price">
              <b>
                {{ NewCE.EstimationPrice }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Thick">
              <b>
                {{ NewCE.Thick}}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Final Cost">
              <b>
                {{ TotalEstimatedPrice}}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Estimated Profit/Loss Per LM">
              <b>
                {{ (+TotalEstimatedPrice - +TotalCostPerLm).toFixed(2) }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Estimated Profit/Loss">
              <b>
                {{calculateTotalProfitLoss()}}
              </b>
            </nz-descriptions-item>
          </nz-descriptions>
        </div><br>
        <nz-divider></nz-divider>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
        </div>
        <nz-divider></nz-divider>
      </div>
    </form>
  </div>
  <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsPopUpOpen" [nzTitle]="modalTitle"
    [nzContent]="modalContent" [nzFooter]="modalfooter" (nzOnCancel)="closeAddModel()">
    <ng-template #modalTitle>Add Raw material</ng-template>
    <ng-template #modalContent>
      <nz-select class="form-select mb-2" nzSize="default" nzShowSearch nzMode="multiple"
        [(ngModel)]="listOfSelectedproducts" (ngModelChange)="OnProductDdlchange()" nzAllowClear nzPlaceHolder="Choose"
        [ngModelOptions]="{standalone: true}">
        <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s" [nzLabel]="s.ProductName"></nz-option>
      </nz-select>
    </ng-template>
    <ng-template #modalfooter>
      <div class="text-center">
        <button nz-button nzType="primary" (click)="addRawMaterial()">Save</button>
      </div>
    </ng-template>
  </nz-modal>
</div>