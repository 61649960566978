<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Issue Gate Pass</nz-page-header-title>
      <nz-page-header-subtitle>Manage your gate pass here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Date Type</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.DateType" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.dateTypeList;" [nzValue]="s.Value" [nzLabel]="s.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>From Date</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="fromdate" class="form-control"
                    [(ngModel)]="request.FromDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>To Date</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="todate" class="form-control"
                    [(ngModel)]="request.ToDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Purchase Order No.</label>
                  <input nz-input name="purchaseOrderNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                    [(ngModel)]="request.PONumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Supplier</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.SupplierId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                      [nzLabel]="s.SupplierName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Invoice No.</label>
                  <input nz-input name="invoiceNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                    [(ngModel)]="request.InvoiceNumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Transport</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    [(ngModel)]="request.TransportId" (ngModelChange)="GetAllVehicleForFilter($event)"
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                      [nzLabel]="s.TransportCompanyName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Vehicle</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="Vehicle" nzSize="default"
                    [(ngModel)]="request.VehicleId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilterVehicleList;" [nzValue]="s.VehicleId"
                      [nzLabel]="s.VehicleNumber"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Gate Pass Type</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="GateInType" nzSize="default"
                    [(ngModel)]="request.Type" nzPlaceHolder="Choose">
                    <nz-option nzValue="Delivery" nzLabel="Delivery"></nz-option>
                    <nz-option nzValue="Order Dispatch" nzLabel="Order Dispatch"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Gate Pass</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="GatePass" nzSize="default"
                    [(ngModel)]="request.IsGatePassIssued" nzPlaceHolder="Choose">
                    <nz-option nzValue=true nzLabel="Issued"></nz-option>
                    <nz-option nzValue=false nzLabel="Not Issued"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                (click)="GetAllGateInRecords()">
                Apply Filter
              </button>
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="ResetFilters()">
                Reset
              </button>
              <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search  "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>

    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.GateInList"
      [nzLoading]="isTableLoading" [nzScroll]="{ x: '1200px',y:'515px' }" nzBordered nzShowPagination="true"
      nzShowSizeChanger [nzPageSizeOptions]=[50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true"
      nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="50px">Id</th>
          <th nzLeft nzWidth="150px">Vehicle Number</th>
          <th nzWidth="350px">Transport Company</th>
          <th nzWidth="100px">Type</th>
          <th nzWidth="100px">In Weight</th>
          <th nzWidth="100px">Out Weight</th>
          <!--<th>Supplier Name</th>
  <th>Invoice Number</th>-->
          <th nzWidth="350px">Driver Name</th>
          <th nzWidth="150px">Driver Number</th>
          <th nzWidth="120px">Gate-In Date</th>
          <th nzWidth="120px">Pass Issued Date</th>
          <th nzWidth="130px">Added By</th>
          <th nzWidth="100px" nzRight style="text-align:center">Pass Issued</th>
          <th nzWidth="250px" style="text-align:center" nzRight></th>
          <!-- <th nzWidth="20%">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.GateInId }}</td>
          <td nzLeft>{{ data.VehicleNumber }}</td>
          <td>{{ data.TransportCompanyName }}</td>
          <td>{{ data.Type }}</td>
          <td>{{ data.InWeight }}</td>
          <td>{{ data.OutWeight }}</td>
          <!--<td>{{ data.SupplierName }}</td>  
  <td>{{ data.Invoice.InvoiceNumber }}</td>-->
          <td>{{ data.GateInPerson }}</td>
          <td>{{ data.GateInPersonContact }}</td>
          <td>{{ data.GateInDate | DatetimeConverter}}</td>
          <td>{{ data.GatePassIssueDate | DatetimeConverter}}</td>
          <td>{{ data.GatePassAddedBy }}</td>
          <td nzRight style="text-align:center">
            <div class="badge badge-light-danger" *ngIf="!data.GatePassIssue">Not-Issued</div>
            <div class="badge badge-light-success" *ngIf="data.GatePassIssue">Issued</div>
          </td>
          <td nzRight style="text-align:center">
            <button [disabled]="isLoading || data.GatePassIssue" class="btn btn-sm btn-light-primary" nz-popconfirm
              *ngIf="this.permission.Add" nzPopconfirmTitle="Are you sure you want to issue the gate pass?"
              nzOkText="ok" nzCancelText="cancel" (nzOnConfirm)="ConfirmGatePass(data)">
              <i nz-icon nzType="loading" *ngIf="isLoading"></i>
              <i nz-icon nzType="snippets" nzTheme="outline"></i>
              <span class="indicator-label">Issue Gate Pass</span>
            </button>
            <button *ngIf="isLoading || data.GatePassIssue" class="btn btn-sm btn-light-primary"
              (click)="print(data)">Print</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal [nzWidth]="550" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsDriverInputVisible" [nzTitle]="modalTitle1"
  [nzContent]="modalContent1" [nzFooter]=null (nzOnCancel)="handleDriverDetailsPopup()">
  <ng-template #modalTitle1>Driver Details - Sales Order Dispatch</ng-template>
  <ng-template #modalContent1>
    <div *ngIf="IsDriverInputVisible">
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
        <nz-form-item>
          <nz-form-label [nzSpan]="10">Driver Name</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Contact Driver Name required">
            <input required nz-input name="GateInPerson" [(ngModel)]="this.DriverName"
              [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10">Driver Number(10 Digits)</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number(10 Digits)">
            <input required nz-input name="GateInPersonContact" onkeypress="if (this.value.length > 9) return false;"
              [(ngModel)]="this.DriverContact" [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10">In Weight(Kgs)</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter Weight of Vehicle when it arrived in Factory">
            <input required nz-input name="InWeight" [(ngModel)]="this.NewGatePass.InWeight"
              [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10">Out Weight(Kgs)</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter Weight of Vehicle when it is leaving the Factory">
            <input required nz-input name="OutWeight" [(ngModel)]="this.NewGatePass.OutWeight"
              [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <nz-divider></nz-divider>
    <div class="text-center">
      <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="IssueGatePass()">Submit Details</button>
    </div>
  </ng-template>

</nz-modal>

<nz-modal [nzWidth]="550" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsWeightInputVisible" [nzTitle]="modalTitle2"
  [nzContent]="modalContent2" [nzFooter]=null (nzOnCancel)="handleWeightDetailsPopup()">
  <ng-template #modalTitle2>Vehicle Weight Information Required</ng-template>
  <ng-template #modalContent2>
    <div *ngIf="IsWeightInputVisible">
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
        <nz-form-item>
          <nz-form-label [nzSpan]="10">In Weight(Kgs)</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter Weight of Vehicle when it arrived in Factory">
            <input nz-input type="number" name="InWeight" [(ngModel)]="this.NewGatePass.InWeight"
              [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10">Out Weight(Kgs)</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter Weight of Vehicle when it is leaving the Factory">
            <input nz-input type="number" name="OutWeight" [(ngModel)]="this.NewGatePass.OutWeight"
              [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <nz-divider></nz-divider>
    <div class="text-center">
      <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="IssueGatePass()">Submit Details</button>
    </div>
  </ng-template>

</nz-modal>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>