<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>WorkPlan List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your WorkPlan List here</nz-page-header-subtitle>
      <nz-page-header-extra>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Customer</label>
                <nz-select name="select-error" [(ngModel)]="request.CustomerId" nzShowSearch nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of CustomerList" [nzValue]="data.CustomerId"
                    [nzLabel]="data.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Formulation Code</label>
                <nz-select name="select-error" [(ngModel)]="request.FormulationCodeId" nzShowSearch nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of FormulationCodeList" [nzValue]="data.SaleFormulationCodeId"
                    [nzLabel]="data.SaleFormulationCode"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Sale Order Status</label>
                <nz-select name="select-error" [(ngModel)]="request.Status" nzShowSearch nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of status" [nzValue]="data.Value" [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Type</label>
                <nz-select [(ngModel)]="PUPVCType" nzAllowClear>
                  <nz-option *ngFor="let data of PUPVCTypeList" [nzValue]="data.Value"
                    [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>WorkPlan Status</label>
                <nz-select [(ngModel)]="request.IsReviewed" nzAllowClear>
                  <nz-option *ngFor="let data of WorkPlanStatusList" [nzValue]="data.Value"
                    [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Sale Order Number</label>
                <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full Order Number" maxlength="30"
                  [(ngModel)]="request.SaleOrderNumber" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </div>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="4">

            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date (From)</label>
                <nz-date-picker [nzShowTime]="true" [nzFormat]="'MM-dd-yyyy hh:mm a'" nzPlaceHolder="From Date"
                  style="width: 200px;" name="orderDateFrom" [(ngModel)]="request.FromDate"
                  nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date (To)</label>
                <nz-date-picker [nzShowTime]="true" [nzFormat]="'MM-dd-yyyy hh:mm a'" nzPlaceHolder="To Date"
                  style="width: 200px;" name="orderDateTo" [(ngModel)]="request.ToDate" nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="1">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetAllWorkPlanReport()">
              Search
            </button>
          </div>

        </div>


      </div>
    </div>

    <div style="padding: 8px;float:left">
      <p><nz-tag [nzColor]="'lime'">Yellowish</nz-tag><b>= Inspection Not completed</b></p>
      <p><nz-tag [nzColor]="'cyan'">Greenish</nz-tag><b>= Inspection Completed</b></p>
    </div>
    <div style="padding: 8px;float:left">
      <p><nz-tag [nzColor]="'#87d068'">Green</nz-tag><b>= In Production</b></p>
      <p><nz-tag [nzColor]="'#f50'">Orange</nz-tag><b>= Production Completed and Sent to Final Inspection</b></p>
    </div>
    <div style="padding: 8px;float:right">

      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search  All WorkPlan"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1500px', y:'515px' }" style="width: 100%;" #basicTable
      [nzData]="this.WorkPlanList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzWidth="45px" nzLeft>S.No</th>
          <th nzWidth="135px" nzLeft>WorkPlan No</th>
          <th nzWidth="120px">Sales Orders</th>
          <th nzWidth="100px">Production Details</th>
          <th nzWidth="60px">Lot No.</th>
          <th nzWidth="110px">Batch No.</th>
          <th nzWidth="95px">WorkPlan Date Time</th>
          <th nzWidth="120px">WorkPlan Status</th>
          <th nzWidth="120px">Reviewed By</th>
          <th nzWidth="95px">Reviewed Date Time</th>
          <th nzWidth="130px">Added By</th>
          <th nzWidth="170px" style="text-align:center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data;let i = index">
          <td nzLeft>{{i+1}}</td>
          <td nzLeft>{{ data.WorkPlanNo }}</td>
          <td>
            <nz-tag *ngFor="let so of data.WorkPlanOrder" [nzColor]="GetSaleOrderTagColor(so.SaleOrder)"><a
                (click)="OpenSaleOrderViewPop(so.SaleOrder)">
                {{so?.SaleOrder?.SaleOrderNumber}}
              </a></nz-tag>


          </td>
          <td>{{ data.ProductionDetails }}</td>
          <td>{{ data.LotNo }}</td>
          <td>{{ data.BatchNo }}</td>
          <td>{{ data.AddedDate| DatetimeConverter }}</td>
          <td>
            <nz-tag *ngIf="data.IsReviewed == true" nzColor="green">
              Review Completed
            </nz-tag>
            <button class="btn btn-sm btn-light-danger" *ngIf="data.IsReviewed != true && this.permission.Approval"
              (click)="OpenWorkplanReviewPop(data)">Click to Review</button>
            <button class="btn btn-sm btn-light-danger" *ngIf="againReviewButtonEnable(data)"
              (click)="OpenWorkplanReviewPop(data)">Review Again</button>
          </td>
          <td>{{ data.ReviewedBy }}</td>
          <td>{{ data.ReviewedDate| DatetimeConverter }}</td>
          <td>{{ data.AddedBy }}</td>

          <td nzRight style="text-align:center">

            <button class="btn btn-sm btn-light-primary" (click)="OpenWorkplanviewPop(data)">View</button> &nbsp;
            <button class="btn btn-sm btn-light-primary" [disabled]="data.IsReviewed != true"
              (click)="OpenInspectionPop(data)">Pre-Inspection</button>
            &nbsp;
          </td>


        </tr>
      </tbody>
    </nz-table>




  </div>
</div>

<!-- To view open drawer  -->
<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1100" [nzTitle]="workplantitle"
  (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div *ngIf="WorkPlanModel">

      <nz-descriptions nzBordered>
        <nz-descriptions-item nzTitle="WORKPLAN NO">{{WorkPlanModel.WorkPlanNo}}</nz-descriptions-item>

        <nz-descriptions-item nzTitle="LOT NO.">{{WorkPlanModel.LotNo}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="BATCH NO.">{{WorkPlanModel.BatchNo}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="WORKPLAN DATE">{{WorkPlanModel.WorkPlanDate | date:
          "dd-MMM-yyyy"}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="ADDED BY">{{WorkPlanModel.AddedBy}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="PRODUCTION DETAILS" [nzSpan]="3">
          {{WorkPlanModel.ProductionDetails}}</nz-descriptions-item>
      </nz-descriptions>
      <nz-divider nzText="Sale Orders"></nz-divider>
      <nz-divider nzText="Important Note"></nz-divider>
      <p><b>* Only if Pre-Inspection is not completed yet then the Sale Order can be removed from WorkPlan.</b></p>
      <p><b>** Each Sale Order details need to be verified by the person doing this review. </b> </p>
      <p><b style="color: red;">No change in Sale Order details allowed once the Pre-Inspection is completed for it.</b>
      </p>
      <nz-divider></nz-divider>
      <nz-table #nzTable [nzData]="WorkPlanModel.WorkPlanOrder" nzTableLayout="fixed" [nzPageSize]="10"
        nzShowPagination="true">
        <thead>
          <tr>
            <th nzWidth="150px" *ngIf="isOpenWorkplanReview">Click to remove *</th>
            <th>SALES ORDER NO **</th>
            <th>BUYER</th>

            <th nzWidth="75px">QTY</th>
            <th nzWidth="130px">Status</th>
            <th nzWidth="250px" style="text-align: center" nzRight>Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of nzTable.data">
            <tr>
              <td *ngIf="isOpenWorkplanReview" nzWidth="150px">
                <div *ngIf="SaleOrderReviewOpen(data)" class="checkdiv grey400"><input type="checkbox"
                    [checked]="!data.SaleOrder.IsChecked"
                    (change)="data.SaleOrder.IsChecked = !data.SaleOrder.IsChecked;"
                    class="le-checkbox thisonetocheckalso" /></div>
              </td>
              <td>{{ data.SaleOrder.SaleOrderNumber }}</td>
              <td>{{ data.SaleOrder.CustomerName }}</td>

              <td>{{ data.SaleOrder.SaleOrderProduction.OrderQuantity }}</td>
              <td>{{ data.SaleOrder.Status.toString() }}</td>
              <td nzRight style="text-align: center">
                <button class="btn btn-sm btn-light-primary"
                  (click)="OpenSaleOrderViewPop(data.SaleOrder)">View</button> &nbsp;
                <button *ngIf="EnableSaleOrderEditButton(data.SaleOrder)" class="btn btn-sm btn-light-primary"
                  (click)="SaleOrderEdit(data.SaleOrder)">Edit</button> &nbsp;
                <button class="btn btn-sm btn-light-danger" (click)="printMixing(data)">Mixing</button>
              </td>
            </tr>

          </ng-container>
        </tbody>
      </nz-table>
      <br>
      <div *ngIf="isOpenWorkplanReview" class="text-center">
        <button nz-button nzType="primary" [hidden]="IsInspectionCompleted" [nzLoading]="isLoading"
          (click)="ConfirmCompleteReview()">Complete Review</button>
      </div>
      <nz-divider *ngIf="isOpenWorkplanReview"></nz-divider>
    </div>
  </ng-container>
</nz-drawer>


<nz-drawer [nzClosable]="true" [nzVisible]="isVisibleInspection" nzPlacement="right" [nzWidth]="1000"
  nzTitle="Inspection details" (nzOnClose)="handleCancelInspection()">
  <ng-container *nzDrawerContent>
    <div *ngIf="IsSalesOrderWorkplanLoaded">
      <div *ngIf="InspectedOrdersCount.length<=0"
        style="font-size: 16px;text-align: center;border: 1px solid #242f4c;padding: 15px;border-radius: 4px;background: #222f4e;color: #fff;text-transform: uppercase;">
        All sales orders has been inspected.
      </div>

      <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">

        <nz-form-control [nzSpan]="8" nzErrorTip="Enter Element">
          <label class="required"><b>Select Order</b></label>

          <nz-select class="form-select" nzShowSearch name="SelectedWorkPlanOrder" [(ngModel)]="SelectedOrderNo"
            [ngModelOptions]="{standalone: true}" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
            (ngModelChange)="OnWorkPlanOrderChange()">
            <nz-option *ngFor="let s of SelectedWorkPlanOrder;" [nzValue]="s.SaleOrder.SaleOrderId"
              [nzLabel]="s.SaleOrder.SaleOrderNumber"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div *ngIf="IsInspectionCompleted"
        style="border: 1px solid #242f4c;padding: 15px;border-radius: 4px;background: #222f4e;color: #fff;display: flex;justify-content: space-between; align-items: center;height: 60px;">
        <div style="text-align: center;flex: 1;text-transform: uppercase;font-size: 12px;">
          <p>This sales order has been inspected. This is View Only.</p>
        </div>
        <button nz-button nzType="primary" style="align-self: flex-end; margin-right: 10px;"
          [hidden]="!IsInspectionCompleted" [disabled]="enablePreInspectionCancelBtn(Orderlist.Status)"
          [nzLoading]="isLoading" (click)="showInspectionCancelPopup(Orderlist.SaleOrderId)" nz-tooltip
          nzTooltipTitle="{{this.InspectionCancelBtnToolTipTxt}}">Cancel
          Inspection</button>
      </div>
      <div *ngIf="SelectedWorkPlanOrder.length>0">

        <div *ngIf="Orderlist">
          <nz-divider *ngIf="IsInspectionCompleted" nzText="Sales Order Inspection Details"></nz-divider>
          <nz-descriptions nzBordered nzLayout="vertical" *ngIf="IsInspectionCompleted" nzSize="small" [nzColumn]="2">
            <nz-descriptions-item nzTitle="Inspection Completed On" [nzSpan]="1" nzColon="true">
              <b>{{ Orderlist.PreInspectionCompletedOn | DatetimeConverter }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Inspection Completed By"
              [nzSpan]="2"><b>{{Orderlist.PreInspectionCompletedBy}}</b></nz-descriptions-item>
          </nz-descriptions>
          <nz-divider nzText="Sales Order Details"></nz-divider>
          <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="6">
            <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
              <b>{{ Orderlist.SaleOrderType }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="CUSTOMER"
              [nzSpan]="2"><b>{{Orderlist.CustomerName}}</b></nz-descriptions-item>

            <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1">
              <b>
                {{
                Orderlist.SaleOrderNumber
                }}
              </b>
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Sale Order Date">
              <b>
                {{
                Orderlist.SaleOrderDate | date: "dd-MMM-yyyy"
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Delivery Date">
              <b>
                {{
                Orderlist.DeliveryDate | date: "dd-MMM-yyyy"
                }}
              </b>
            </nz-descriptions-item>
          </nz-descriptions>

          <nz-divider nzText="Production Details"></nz-divider>
          <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist?.SaleOrderProduction" nzSize="small"
            [nzColumn]="4">
            <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true">
              <b>
                {{
                Orderlist.SaleFormulationCode
                }}
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{Orderlist.Category}}</b></nz-descriptions-item>
            <nz-descriptions-item
              nzTitle="Thick"><b>{{Orderlist.SaleOrderProduction.ThicknessValue}}</b></nz-descriptions-item>
            <nz-descriptions-item
              nzTitle="Width"><b>{{Orderlist.SaleOrderProduction.WidthNumber}}</b></nz-descriptions-item>
            <nz-descriptions-item
              nzTitle="Article Name"><b>{{Orderlist.SaleOrderProduction.ManufacturingProductName}}</b></nz-descriptions-item>
            <nz-descriptions-item
              nzTitle="Order Quantity"><b>{{Orderlist.SaleOrderProduction.OrderQuantity}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Sale Price"><b>{{ Orderlist.SaleOrderProduction.SalePrice
                }}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Sale Price"><b>{{ Orderlist.SaleOrderProduction.TotalSalePrice
                }}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Grain Code"><b>{{Orderlist.SaleOrderProduction.GrainCode}}</b>
              <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                nzTooltipTitle="Grain Name: {{Orderlist.SaleOrderProduction.GrainName}}" nzTooltipPlacement="left"
                nz-tooltip nzType="info-circle" nzTheme="outline">
              </span>
            </nz-descriptions-item>
            <nz-descriptions-item
              nzTitle="Color"><b>{{Orderlist.SaleOrderProduction.ColorName}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Fabric Color"><b>{{this.FabricColorName}}</b></nz-descriptions-item>

          </nz-descriptions>
          <nz-divider nzText="Formula Calculation Requirements"></nz-divider>
          <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist?.SaleOrderProduction" nzSize="small"
            [nzColumn]="3">
            <nz-descriptions-item nzTitle="Extra MFG Production %">
              <nz-input-group nzSuffix="%">
                <input [disabled]="IsInspectionCompleted" nz-input type="number"
                  [(ngModel)]="Orderlist.SaleOrderProduction.ExtraProduction"
                  (ngModelChange)="SetTotalProductionQty();ResetSaveInspection()" />
              </nz-input-group>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Est. Manufacture QTY (Read Only)">
              {{FormulationPasteReqRecalculateRequest.TotalProductionQty}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="LM Constant">
              <input [disabled]="IsInspectionCompleted" nz-input type="number"
                [(ngModel)]="Orderlist.SaleOrderProduction.LMConstant" (ngModelChange)="ResetSaveInspection()" />
            </nz-descriptions-item>
          </nz-descriptions>
          <nz-divider nzText="Inspection Details"></nz-divider>
          <nz-descriptions nzBordered nzLayout="vertical" [hidden]="Orderlist.FormulationFabricProductId == null"
            nzSize="small" [nzColumn]="5">
            <nz-descriptions-item nzTitle="Fabric" [nzSpan]="1" nzColon="true" style="width:50%">
              <b>
                <nz-select class="form-select mb-2" nzSize="default" [disabled]="IsInspectionCompleted" nzShowSearch
                  [(ngModel)]="SelectedFabricId" (ngModelChange)="calculateFabricQTY()" nzPlaceHolder="Choose"
                  [ngModelOptions]="{standalone: true}">
                  <nz-option *ngFor="let s of this.ProductFilteredList;" [nzValue]="s.ProductId"
                    [nzLabel]="s.ProductName"></nz-option>
                </nz-select>
              </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Unit" [nzSpan]="1" nzColon="true" *ngIf="!IsInspectionCompleted">
              <b>{{Orderlist.FormulationFabricProductUnit}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Unit" [nzSpan]="1" nzColon="true" *ngIf="IsInspectionCompleted">
              <b>{{Orderlist.SaleOrderProduction.FabricProductUnit}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Width" [nzSpan]="1" nzColon="true" *ngIf="!IsInspectionCompleted">
              <b>{{Orderlist.FormulationFabricProducWidthInMeter}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Width" [nzSpan]="1" nzColon="true" *ngIf="IsInspectionCompleted">
              <b>{{Orderlist.SaleOrderProduction.FabricProductWidthInMeter}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="QTY" [nzSpan]="1" nzColon="true">
              <b *ngIf="!IsInspectionCompleted">
                <input type="text" name="Febric" nz-input nzSize="default" [(ngModel)]="FabricQTY"
                  [ngModelOptions]="{standalone: true}" class="form-control " />
              </b>
              <b *ngIf="IsInspectionCompleted">{{Orderlist.FormulationFabricProductQty}}</b> <br>
              <span *ngIf="isUnitConversionNotDone" style="color: red;">(Conversion could not be completed, as width or
                Fabric GSM is
                not available)</span>

            </nz-descriptions-item>

          </nz-descriptions>

          <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
            <div nz-col [nzSpan]="24">
              <nz-divider></nz-divider>
              <a class="btn btn-light-success" *ngIf="!IsInspectionCompleted" style="margin-bottom: 10px; "
                (click)="GetPasteReqQtyToRecalculate()">Re-Calculate
                Quantity</a>
              <nz-divider nzText="Mixing"></nz-divider>
              <p *ngIf="!IsInspectionCompleted"><b>Note:</b> You can move any row by dragging up or down to rearrange
                within same Mixing Section.</p>
              <span *ngIf="!IsInspectionCompleted">
                <div *ngFor="let i of Orderlist.SaleOrderProduction.FormulationMixing" style="
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-top: 10px;
                background: #fff;
                box-shadow: 1px 1px 2px 2px #415994;
              ">
                  <div style="padding: 10px 0; text-transform: uppercase">
                    Mixing Name :
                    <b>{{ i.MixingName }}</b>
                    <a class="btn btn-sm btn-light-success" [hidden]="IsInspectionCompleted"
                      style="float: right; margin-bottom: 10px; " (click)="openAddModel(i)">
                      <i nz-icon nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add
                    </a>
                  </div>
                  <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
                        <th nzWidth="50px">S.No</th>
                        <th nzWidth="70px" *ngIf="i.MixingName.toLowerCase() == 'skin'">Base Material</th>
                        <th>Product</th>

                        <th nzWidth="100px">Unit</th>
                        <!-- <th nzWidth="100px">Price</th> -->
                        <th nzWidth="100px">Ratio</th>
                        <th nzWidth="100px">Calculated QTY</th>
                        <th nzWidth="100px">Calculated SC QTY</th>
                        <th nzWidth="100px">Perishable Material</th>
                        <th nzWidth="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop(i.MixingRawMaterial, $event)">
                      <tr cdkDrag *ngFor="let data of i.MixingRawMaterial; let j = index">
                        <td nzWidth="20px">{{ j + 1 }}</td>
                        <td nzLeft style="text-align: center" *ngIf="i.MixingName.toLowerCase() == 'skin'">
                          <input style="margin-right: 5px" type="checkbox" [checked]="data.IsBaseMaterial"
                            [disabled]="(checkedStatus[i.MixingName] && !data.IsBaseMaterial)"
                            (change)="data.IsBaseMaterial = !data.IsBaseMaterial; onBaseMaterialChecked(data.IsBaseMaterial,i.MixingName)" />
                        </td>
                        <td>{{ data.ProductName }}</td>

                        <td>{{ data.Unit }}</td>
                        <!-- <td>{{ data.Price }}</td> -->
                        <td>{{ data.Quantity }}</td>
                        <td>
                          <input [disabled]="IsInspectionCompleted" nz-input type="number"
                            [(ngModel)]="data.CalculatedQuantity" (ngModelChange)="ReCalculateTotalPerMixing()" />
                        </td>
                        <td> <input [disabled]="IsInspectionCompleted" nz-input type="number"
                            [(ngModel)]="data.CalculatedSCQuantity" [disabled]="!data.Perishable"
                            (ngModelChange)="ReCalculateTotalPerMixing()" />
                        </td>
                        <td style="text-align: center">
                          <input style="margin-right: 5px" type="checkbox" [checked]="data.Perishable"
                            (change)="data.Perishable = !data.Perishable; ReCalculateTotalPerMixing()" />
                        </td>
                        <td style="text-align: center">
                          <button class="btn btn-sm btn-light-primary" [hidden]="IsInspectionCompleted"
                            (click)="handleRemoveRow(data,i.MixingName)">
                            Remove
                          </button> &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td><b>Total</b></td>

                        <td></td>
                        <td *ngIf="i.MixingName.toLowerCase() == 'skin'"></td>
                        <td></td>

                        <td><b>{{this.totalCalculatedQuantities[i.MixingName.toLowerCase()]}} Kgs</b></td>
                        <td><b>{{this.totalCalculatedSCQuantities[i.MixingName.toLowerCase()]}} Kgs</b></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </nz-table>



                </div>
              </span>
              <span *ngIf="IsInspectionCompleted">
                <div *ngFor="let i of Orderlist.SaleOrderProduction.InspectionFormulationMixing" style="
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-top: 10px;
                background: #fff;
                box-shadow: 1px 1px 2px 2px #415994;
              ">
                  <div style="padding: 10px 0; text-transform: uppercase">
                    Mixing Name :
                    <b>{{ i.MixingName }}</b>
                    <a class="btn btn-sm btn-light-success" [hidden]="IsInspectionCompleted"
                      style="float: right; margin-bottom: 10px; " (click)="openAddModel(i)">
                      <i nz-icon nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add
                    </a>
                  </div>
                  <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                    <thead>
                      <tr>
                        <th nzWidth="50px">S.No</th>
                        <th>Product</th>

                        <th nzWidth="100px">Unit</th>
                        <!-- <th nzWidth="100px">Price</th> -->
                        <th nzWidth="150px">Ratio</th>
                        <th nzWidth="150px">Inspected QTY</th>
                        <th nzWidth="100px">Inspected SC QTY</th>
                        <!-- <th>Calculated QTY</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of i.MixingRawMaterial; let j = index">
                        <td nzWidth="20px">{{ j + 1 }}</td>
                        <td>{{ data.ProductName }}</td>

                        <td>{{ data.Unit }}</td>
                        <!-- <td>{{ data.Price }}</td> -->
                        <td>
                          <input [disabled]="IsInspectionCompleted" nz-input type="number"
                            [(ngModel)]="data.BaseQuantity" />
                        </td>
                        <!-- <td>
                                              <input [disabled]="IsInspectionCompleted" nz-input type="number"
                                                     [(ngModel)]="data.Quantity" />
                                          </td>
                                          <td> <input [disabled]="IsInspectionCompleted" nz-input type="number"
                                [(ngModel)]="data.Scquantity" /></td> -->
                        <td>
                          <input [disabled]="IsInspectionCompleted" nz-input type="number"
                            [(ngModel)]="data.Quantity" />
                        </td>
                        <td> <input [disabled]="IsInspectionCompleted" nz-input type="number"
                            [(ngModel)]="data.Scquantity" />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td><b>Total</b></td>
                        <td></td>
                        <td></td>

                        <td><b>{{this.totalCalculatedQuantities[i.MixingName.toLowerCase()]}} Kgs</b></td>
                        <td><b>{{this.totalCalculatedSCQuantities[i.MixingName.toLowerCase()]}} Kgs</b></td>
                      </tr>
                    </tbody>
                  </nz-table>



                </div>
              </span>

            </div>
            <!-- <div nz-col [nzSpan]="24">
            <nz-divider *ngIf="Orderlist.SaleOrderProduction.Lacquer.length > 0" nzText="Lacquer"></nz-divider>
            <div *ngFor="let i of Orderlist.SaleOrderProduction.Lacquer" style="
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 10px;
          background: #fff;
          box-shadow: 1px 1px 2px 2px #415994;
        ">
              <div style="padding: 10px 0; text-transform: uppercase">
                Lacquer Name :
                <b>{{ i.Name }}</b>
              </div>
              <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                  <tr>
                    <th nzWidth="50px">S.No</th>
                    <th>Product</th>

                    <th nzWidth="100px">Unit</th>
                    <th nzWidth="100px">Price</th>
                    <th nzWidth="100px">QTY</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of i.LacquerRawMaterial; let i = index">
                    <td nzWidth="20px">{{ i + 1 }}</td>
                    <td>{{ data.ProductName }}</td>

                    <td>{{ data.Unit }}</td>
                    <td>{{ data.Price }}</td>
                    <td> <input [disabled]="IsInspectionCompleted" nz-input type="number"
                        [(ngModel)]="data.Quantity" /></td>
                    <td style="text-align: center">
                      <button class="btn btn-sm btn-light-primary" [hidden]="IsInspectionCompleted"
                        (click)="handleRemoveRow(data)">Remove</button> &nbsp;
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div> -->
            <nz-divider></nz-divider>
            <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
              <div nz-col [nzSpan]="24">
                <nz-descriptions nzBordered nzLayout="vertical"
                  [nzColumn]="Orderlist.SaleOrderProduction.FormulationMixing.length"
                  style="box-shadow: 1px 1px 2px 2px #415994">

                  <ng-container *ngFor="let item of Orderlist.SaleOrderProduction.FormulationMixing">
                    <nz-descriptions-item *ngIf="item.MixingName.trim().toLowerCase() === 'pre skin'"
                      nzTitle="Pre Skin (GSM)" nzColon="true">
                      <input [disabled]="IsInspectionCompleted" readonly nz-input type="number"
                        [(ngModel)]="Orderlist.SaleOrderProduction.PreSkinGsm"
                        (ngModelChange)="ResetSaveInspection()" />
                    </nz-descriptions-item>

                    <nz-descriptions-item *ngIf="item.MixingName.trim().toLowerCase() === 'skin'" nzTitle="Skin (GSM)"
                      nzColon="true">
                      <input [disabled]="IsInspectionCompleted" readonly nz-input type="number"
                        [(ngModel)]="Orderlist.SaleOrderProduction.SkinGsm" (ngModelChange)="ResetSaveInspection()" />
                    </nz-descriptions-item>

                    <nz-descriptions-item *ngIf="item.MixingName.trim().toLowerCase() === 'foam'" nzTitle="Foam (GSM)"
                      nzColon="true">
                      <input [disabled]="IsInspectionCompleted" readonly nz-input type="number"
                        [(ngModel)]="Orderlist.SaleOrderProduction.FoamGsm" (ngModelChange)="ResetSaveInspection()" />
                    </nz-descriptions-item>

                    <nz-descriptions-item *ngIf="item.MixingName.trim().toLowerCase() === 'adhesive'"
                      nzTitle="Adhesive (GSM)" nzColon="true">
                      <input [disabled]="IsInspectionCompleted" readonly nz-input type="number"
                        [(ngModel)]="Orderlist.SaleOrderProduction.AdhesiveGsm"
                        (ngModelChange)="ResetSaveInspection()" />
                    </nz-descriptions-item>
                  </ng-container>

                </nz-descriptions>
                <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="2"
                  style="box-shadow: 1px 1px 2px 2px #415994;margin-top: 10px;">
                  <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                    <b>
                      {{ this.FabricGsm }}
                    </b>
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="Total GSM" nzColon="true">
                    <b>
                      {{ Orderlist.SaleOrderProduction.PreSkinGsm + Orderlist.SaleOrderProduction.SkinGsm +
                      Orderlist.SaleOrderProduction.FoamGsm + Orderlist.SaleOrderProduction.AdhesiveGsm +
                      this.FabricGsm }}
                    </b>
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
            </div>
            <nz-divider></nz-divider>
            <a class="btn btn-light-success" *ngIf="!IsInspectionCompleted" style="margin-bottom: 10px; "
              (click)="GetPasteReqQtyToRecalculate()">Re-Calculate
              Quantity</a>
            <a class="btn btn-light-success" *ngIf="IsInspectionCompleted" style="margin-bottom: 10px; "
              (click)="PrintJobCard(this.Orderlist.SaleOrderId)">Print Job Card</a>
            <nz-divider [hidden]="IsInspectionCompleted"></nz-divider>
            <div nz-col [hidden]="IsInspectionCompleted" [nzSpan]="24">
              <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="2">
                <nz-descriptions-item nzTitle="Consumption" [nzSpan]="1" nzColon="true" style="width:50%">
                  <b>
                    <label style="margin:10px" nz-checkbox [(ngModel)]="Orderlist.SendToConsumption">
                      <b>
                        Send to
                        Consumption
                      </b>
                    </label>
                  </b>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Store" [nzSpan]="1" nzColon="true" *ngIf="Orderlist.SendToConsumption"
                  style="width:50%">
                  <nz-select class="form-select mb-2" nzShowSearch name="FromStoreID"
                    [(ngModel)]="Orderlist.ConsumptionStoreId" [ngModelOptions]="{standalone: true}" nzSize="default"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.AdminStoreList;" [nzValue]="s.StoreId"
                      [nzLabel]="s.StoreName"></nz-option>
                  </nz-select>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <nz-divider [hidden]="IsInspectionCompleted"></nz-divider>
            <div nz-col [hidden]="IsInspectionCompleted" [nzSpan]="15">
              <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="1">
                <nz-descriptions-item nzTitle="Supervisor Detail" [nzSpan]="1" nzColon="true">
                  <b>
                    <label style="margin:10px">
                      <b>
                        Select current shift Supervisor name:
                      </b>
                    </label>
                  </b>
                  <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="Orderlist.ShiftSupervisorWorkerId"
                    nzSize="default" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FactoryWorkersList;" [nzValue]="s.WorkerId" [nzLabel]="s.Name">
                    </nz-option>
                  </nz-select>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>


            <nz-divider [hidden]="IsInspectionCompleted"></nz-divider>
            <div class="text-center">
              <button nz-button nzType="primary" *ngIf="!IsInspectionCompleted" [disabled]="disableInspectionBtn"
                nz-tooltip [nzTooltipTitle]="this.SaveInspectionToolTipText" [nzLoading]="isLoading"
                (click)="SaveInspection(false)">Save</button>
              <button nz-button nzType="primary" style="margin-left: 10px;" *ngIf="!IsInspectionCompleted"
                [disabled]="disableInspectionBtn" nz-tooltip [nzTooltipTitle]="this.SaveInspectionToolTipText"
                [nzLoading]="isLoading" (click)="SaveInspection(true)">Save and Print Job Card</button>
            </div>
            <nz-divider></nz-divider>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>




<nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsPopUpOpen" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalfooter" (nzOnCancel)="closeAddModel()">
  <ng-template #modalTitle>Add Raw material</ng-template>

  <ng-template #modalContent>
    Select Product :
    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="selectedproduct" nzAllowClear
      nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
      <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s" [nzLabel]="s.ProductName"></nz-option>
    </nz-select>

    Enter Base Quantity : <input [disabled]="IsInspectionCompleted" nz-input type="number"
      [(ngModel)]="selectedQuantity" />

  </ng-template>
  <ng-template #modalfooter>
    <div class="text-center">



      <button nz-button nzType="primary" (click)="addRawMaterial()">Save</button>
    </div>
  </ng-template>
</nz-modal>

<app-SoDrawer></app-SoDrawer>

<nz-modal [(nzVisible)]="isInspectionCancelVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="600"
  [nzTitle]="modalTitleDispatch" [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch"
  (nzOnCancel)="handleInspectionCancel()">
  <ng-template #modalTitleDispatch>Pre-Inspection Cancellation Confirmation</ng-template>

  <ng-template #modalContentDispatch>
    <label><b>Cancelling Inspection for Sale Order No.: {{Orderlist?.SaleOrderNumber}}</b></label>
    <label><b>Provide Reason for Cancellation</b></label>
    <form nz-form [formGroup]="InspectionCancelForm" nzLayout="vertical">
      <nz-form-item>
        <nz-form-control nzErrorTip="Only 180 characters allowed.">
          <nz-textarea-count [nzMaxCharacterCount]="180">
            <textarea nz-input class="form-control mb-2" formControlName="reason" value="" rows="5"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div>
      <p>
        <b style="color: red;">
          Once inspection is cancelled, no information is stored for the current inspected raw materials. Only new
          inspected raw materials will be stored and can be tracked.
        </b>
      </p>
    </div>
  </ng-template>

  <ng-template #modalFooterDispatch>
    <button nz-button nzType="primary" nzDanger (click)="saveInspectionCancel()"
      [nzLoading]="isLoading">Confirm</button>
    <button nz-button nzType="default" (click)="handleInspectionCancel()" [nzLoading]="isLoading">No</button>
  </ng-template>
</nz-modal>