<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Rack List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Rack here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary"   *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.RackList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th>
            Rack Name
            <!--<nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
            <i nz-icon nzType="search"></i>
          </nz-filter-trigger>-->
          </th>
          <th>Rack Code</th>
          <th>Rack Desc</th>
          <th>Store</th>
          <th>AddedDate</th>
          <th>AddedBy</th>

          <th nzWidth="20%" *ngIf="permission.Add || permission.Delete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.RackName }}</td>
          <td>{{ data.RackCode }}</td>
          <td>{{ data.RackDesc }}</td>
          <td>{{ data.StoreName }}</td>
          <td>{{ data.RackAddedDate | DatetimeConverter}}</td>
          <td>{{ data.RackAddedBy }}</td>



          <td *ngIf="CheckUserAddPermission(data.RackAddedBy )">
            <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>

          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div style="padding: 8px;">
          <input type="text" style="width: 188px;margin-bottom: 8px;display: block;" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>

    <nz-modal [nzWidth]="800"
              [nzStyle]="{ top: '20px' }"
              [(nzVisible)]="isVisible"
              [nzTitle]="modalTitle"
              [nzContent]="modalContent"
              [nzFooter]=null
              (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8" nzRequired>Rack Name</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="NameError">
              <input nz-input formControlName="RackName" name="RackName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Rack Code </nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="CodeError">
              <input nz-input formControlName="RackCode" name="RackCode" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Store Name</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Store">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select mb-2" nzShowSearch formControlName="StoreId" name="StoreId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Description</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Description cannot be more than 200 characters">
              <textarea rows="4" nz-input formControlName="RackDesc" name="RackDesc"></textarea>
            </nz-form-control>
          </nz-form-item>
        
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save Rack</button>
          </div>

        </form>
      </ng-template>
    </nz-modal>

  </div>
</div>

