<div *ngIf="IsPopupOpen | async">
    <nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1000"
        nzTitle="Sales Order details" (nzOnClose)="handleCancel()">
        <ng-container *nzDrawerContent>
            <div *ngIf="Orderlist">
                <nz-divider nzText="Sales Order Status"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="2">
                    <nz-descriptions-item nzTitle="Current Status" [nzSpan]="1" nzColon="true">
                        <nz-badge nzStatus="processing"></nz-badge><b>{{ Orderlist.Status }}</b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="WorkPlan Number" [nzSpan]="2"><b>{{ Orderlist.WorkPlanNumber
                            }}</b></nz-descriptions-item>
                </nz-descriptions>
                <nz-divider nzText="Sales Order Details"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="3">
                    <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
                        <b>{{ Orderlist.SaleOrderType }}</b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="1"><b>{{ Orderlist.CustomerName
                            }}</b></nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Buyer Order Reference" [nzSpan]="1"><b>{{ Orderlist.BORNumber
                            }}</b></nz-descriptions-item>
                </nz-descriptions>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist" nzSize="small" [nzColumn]="3">
                    <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1"><b>{{ Orderlist.SaleOrderNumber
                            }}</b></nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Sale Order Date">
                        <b>
                            {{ Orderlist.SaleOrderDate | date: "dd-MMM-yyyy" }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Delivery Date">
                        <b>
                            {{ Orderlist.DeliveryDate | date: "dd-MMM-yyyy" }}
                        </b>
                    </nz-descriptions-item>
                </nz-descriptions>

                <nz-divider nzText="Production Details"></nz-divider>
                <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Orderlist.SaleOrderProduction" nzSize="small"
                    [nzColumn]="4">
                    <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                            Orderlist.SaleFormulationCode
                            }}</b></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ Orderlist.Category
                            }}</b></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Thick">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ThicknessValue
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Width">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.WidthNumber
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ManufacturingProductName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Order Quantity">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.OrderQuantity
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item *ngIf="this.permission.SaleOrderSalePriceView" nzTitle="Sale Price">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.SalePrice
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item *ngIf="this.permission.SaleOrderSalePriceView" nzTitle="Total Sale Price">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.TotalSalePrice
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Grain Name">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.GrainName
                            }}
                        </b>
                        <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                            nzTooltipTitle="Grain Code: {{Orderlist.SaleOrderProduction.GrainCode}}"
                            nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                        </span>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Color">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ColorName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Fabric Color">
                        <b>
                            {{
                            this.FabricColorName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Fabric Name">
                        <b>
                            {{
                            this.FabricName
                            }}
                        </b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Manufactured QTY"
                        *ngIf="Orderlist.SaleOrderProduction.ManufacturingQuantity > 0">
                        <b>
                            {{
                            Orderlist.SaleOrderProduction.ManufacturingQuantity
                            }}
                        </b>
                    </nz-descriptions-item>
                </nz-descriptions>

                <!-- process details  -->
                <nz-divider nzText="Post Process"></nz-divider>
                <!-- print  -->
                <div style="
          text-align: center;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 10px;
          background: #fff;
          box-shadow: 1px 1px 2px 2px #415994;
        ">
                    <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzWidth="50px">Order</th>
                                <th>Post Process Name</th>
                                <th>Name</th>
                                <th nzWidth="160px">Code</th>
                                <th nzWidth="40px">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="postProcessArray.length > 0">
                            <tr *ngFor="let data of postProcessArray; let i = index">
                                <td nzWidth="20px">{{ data.Rank }}</td>
                                <td>{{ data.PostProcessName }}</td>
                                <td>{{ data.Name }}</td>
                                <td>{{ data.Code }}</td>
                                <td style="text-align: center">
                                    <div *ngIf="data.Removed == true">
                                        <div class="badge badge-light-danger">InActive</div>
                                        <span nz-icon nzTooltipTitle="Removed by {{ data.RemovedBy }} on {{
                      data.RemovedDate | DatetimeConverter
                    }}" nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                        </span>
                                    </div>
                                    <div class="badge badge-light-success" *ngIf="data.Removed != true">
                                        Active
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>

            <!-- <nz-divider></nz-divider> -->
            <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="Orderlist?.SaleOrderProduction">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px">
                        <div nz-col [nzSpan]="24">
                            <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="5"
                                style="box-shadow: 1px 1px 2px 2px #415994">
                                <nz-descriptions-item nzTitle="Pre Skin" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.PreSkinGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Skin" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.SkinGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Foam" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.FoamGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Adhesive" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.AdhesiveGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Fabric (GSM)" nzColon="true">
                                    <b>
                                        {{ Orderlist.SaleOrderProduction.FabricGsm }}
                                    </b>
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Remarks" nzColon="true"><b>{{ Orderlist.Remarks
                                        }}</b></nz-descriptions-item>
                            </nz-descriptions>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </nz-drawer>
</div>