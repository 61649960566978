import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { ConsumeStockProductPrintMasterModel } from '../../../Models/ConsumptionModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import * as moment from 'moment';
import { AuthService } from '../../../Services/auth.service';
import { Modules, Responsibility } from '../../../Models/Enums';
import { Router } from '@angular/router';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, RackModel, StoreModel } from 'src/PmsUIApp/Models/MasterModel';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { UserModel } from 'src/PmsUIApp/Models/UserModel';
import { ConsumeStockProductReportModel, SearchParamsStockConsumptionReportModel } from 'src/PmsUIApp/Models/ReportModel';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-Consumptionreport',
  templateUrl: './consumptionreport.component.html'
})
export class ConsumptionReportComponent implements OnInit {

  myDateValue: Date | undefined;
  isValidDate: any
  ApiUrl = environment.Api_Url;
  ConsumptionList: ConsumeStockProductReportModel[] = [];
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;

  PopUpTitle: string = "Add New Issue";
  searchValue = '';
  toDate: Date | undefined;
  visible = false;
  fields: any;


  exportfields: ConsumeStockProductPrintMasterModel[] = [];

  exportoptions = {
    headers: ["Product", "Batch No.", "Sale Order", "Quantity", "Price", "Unit", "Damaged", "Store", "Rack", "Consumed Date", "Purpose/Reason", "Consumed By"]
  };
  filterSummaryOptions = [
    { label: 'Show all Consumption', value: 'showall', checked: true, disabled: false },
    { label: 'Show by Product', value: 'byproduct', checked: false, disabled: false },
    { label: 'Show by Stock Batch No.(For Price)', value: 'bybatchno', checked: false, disabled: false },
    { label: 'Show by Store', value: 'bystore', checked: false, disabled: false },
    { label: 'Show by Store & Rack', value: 'bystorerack', checked: false, disabled: false },
  ];
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false

  }
  MeasureUnits: MeasureUnitModel[];
  ProductList: ProductModel[];
  ProductCategoryList: ProductCategoryModel[];
  FilteredProductList: ProductModel[];
  SelectedProductType: string = '';
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[];
  SelectedProductId: number = 0;
  SelectedUnit: string = '';
  SupplierList: SupplierModel[];
  RackList: RackModel[];
  StoreID: number;
  RackID: number;
  StoreList: StoreModel[];
  UserList: UserModel[];
  filterPanelTxt: string = 'Show Filters';
  request: SearchParamsStockConsumptionReportModel = new SearchParamsStockConsumptionReportModel;
  IsMsgShow: boolean = false;
  dateFilterOptions: any = [
    {
      "Text": "Today",
      "Value": 'today'
    },
    {
      "Text": "Yesterday",
      "Value": 'yesterday'
    },
    {
      "Text": "Last 7 Days",
      "Value": 'last7days'
    },
    {
      "Text": "Last 30 Days",
      "Value": 'last30days'
    },
    {
      "Text": "Last Month",
      "Value": 'lastmonth'
    },
    {
      "Text": "Last Year",
      "Value": 'lastyear'
    },
    {
      "Text": "Custom Range",
      "Value": 'custom'
    }
  ];
  selecteddateFilter: string = 'today';
  enableCustomDateRange: boolean;
  activeParentTabName: string;
  totalItemsCount: number = 0;
  TotalConsumedSaleOrderCount: number = 0;
  TotalUniqueProductsConsumedCount: number = 0;
  CountOfSelectedSummaryFilters = 0;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService
    , private router: Router, private auth: AuthService, private loader: LoadingService
  ) { }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsStockConsumption, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsStockConsumption, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsStockConsumption, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.getDateRange("today");
    this.selecteddateFilter = 'today';
    this.GetAllConsumption();
  }
  GetAllConsumption() {
    this.IsMsgShow = false;
    this.TotalConsumedSaleOrderCount = 0;
    this.TotalUniqueProductsConsumedCount = 0;
    this.request.ProductType = this.SelectedProductType;
    this.request.ProductCategoryId = this.CategoryID;
    this.request.ProductFirstSubCategoryId = this.FirstCategoryID;
    this.request.ProductSecSubCategoryId = this.SecondCategoryID;
    this.request.ProductId = this.SelectedProductId;
    this.request.Unit = this.SelectedUnit;
    this.request.StoreId = this.StoreID;
    this.request.RackId = this.RackID;
    this.loader.show();
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "report/getallconsumestockproductsfilters";
    this.http.post<ConsumeStockProductReportModel[]>(url, this.request).subscribe(res => {
      this.ConsumptionList = res;
      this.isTableLoading = false;
      this.exportfields = [];
      if (this.isByProductChecked) {
        this.aggregateByProduct();
      } else if (this.isByBatchNoChecked) {
        this.aggregateByBatchNo();
      } else if (this.isByStoreChecked) {
        this.aggregateByStore();
      } else if (this.isByStoreRackChecked) {
        this.aggregateByStoreAndRack();
      } else if (this.isByShowAllChecked) {
        this.TotalConsumedSaleOrderCount = new Set(this.ConsumptionList.filter(item => item.SaleOrderId != null).map(item => item.SaleOrderId)).size;
        this.TotalUniqueProductsConsumedCount = new Set(this.ConsumptionList.map(item => item.ProductId)).size;
      }

      if (this.ConsumptionList.length <= 0) {
        this.IsMsgShow = true;
        this.loader.hide();
        return;
      }
      this.ConsumptionList.forEach((x) => {
        count++;
        x.SerialNo = count;
        x.Quantity = parseFloat(x.Quantity.toFixed(2));
      });
      this.totalItemsCount = count;

      this.ConsumptionList.forEach((x) => {
        this.fields = []
        this.fields.ProductName = x.ProductName
        this.fields.BatchNo = x.BatchNo
        this.fields.SaleOrderNumber = x.SaleOrderNumber != null ? x.SaleOrderNumber : ''
        this.fields.Quantity = x.Quantity
        this.fields.Price = x.PricePerUnit
        this.fields.Unit = x.Unit
        this.fields.Damaged = x.IsDamaged == true ? 'Yes' : 'No'
        this.fields.StoreName = x.StoreName
        this.fields.RackName = x.RackName
        this.fields.AddedDate = new DatetimeConverter().transform(x.AddedDate.toString())
        this.fields.Purpose = x.Purpose
        this.fields.AddedBy = x.AddedBy
        this.exportfields.push(this.fields);
        this.isTableLoading = false;
      })
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllConsumption();
      }
    });

  }

  adjustFilterSummaryOptions($event: any[]) {
    this.CountOfSelectedSummaryFilters = 0;
    // Determine if any option other than 'showall' is checked
    const isAnyOtherOptionChecked = $event.some(option => option.value !== 'showall' && option.checked);

    // Update the checked and disabled status based on the event
    this.filterSummaryOptions.forEach(option => {
      // Find the corresponding event option
      const eventOption = $event.find(eOption => eOption.value === option.value);
      // Update the checked and disabled status if the option is found in the event array
      if (eventOption) {
        option.checked = eventOption.checked;
        // Disable 'showall' if any other option is checked
        if (option.value === 'showall') {
          option.disabled = isAnyOtherOptionChecked;
          if (isAnyOtherOptionChecked) {
            option.checked = false;
          }
        }
      }
    });

    // Logic for 'bystore' and 'bystorerack' mutual exclusivity
    const byStoreOption = this.filterSummaryOptions.find(option => option.value === 'bystore');
    const byStoreRackOption = this.filterSummaryOptions.find(option => option.value === 'bystorerack');
    if (byStoreOption && byStoreRackOption) {
      if (byStoreOption.checked) {
        byStoreRackOption.checked = false;
        byStoreRackOption.disabled = true;
      } else if (byStoreRackOption.checked) {
        byStoreOption.checked = false;
        byStoreOption.disabled = true;
      } else {
        // Re-enable both options if neither is checked
        byStoreOption.disabled = false;
        byStoreRackOption.disabled = false;
      }
    }
    this.CountOfSelectedSummaryFilters = this.filterSummaryOptions.filter(option => option.checked).length;

    this.ConsumptionList = [];
  }
  get isByShowAllChecked(): boolean {
    return this.filterSummaryOptions.some(option => option.value === 'showall' && option.checked);
  }
  get isByProductChecked(): boolean {
    return this.filterSummaryOptions.some(option => option.value === 'byproduct' && option.checked);
  }
  get isByBatchNoChecked(): boolean {
    return this.filterSummaryOptions.some(option => option.value === 'bybatchno' && option.checked);
  }
  get isByStoreChecked(): boolean {
    return this.filterSummaryOptions.some(option => option.value === 'bystore' && option.checked);
  }
  get isByStoreRackChecked(): boolean {
    return this.filterSummaryOptions.some(option => option.value === 'bystorerack' && option.checked);
  }

  aggregateByProduct(): void {
    const aggregated = this.ConsumptionList.reduce((acc, curr) => {
      const date = new Date(new DatetimeConverter().transform(curr.ConsumedDate)).toDateString();
      const key = `${curr.ProductId}-${curr.ProductName}-${curr.Unit}-${date}`;
      if (!acc[key]) {
        acc[key] = { ...curr, Quantity: 0, ConsumedDate: date };
      }
      acc[key].Quantity += curr.Quantity;
      return acc;
    }, {});
    this.ConsumptionList = Object.values(aggregated);

    this.exportfields = [];
    this.exportoptions = {
      headers: ["Product", "Quantity", "Unit", "Consumed Date",]
    };
    this.ConsumptionList.forEach((x) => {
      this.fields = []
      this.fields.ProductName = x.ProductName
      this.fields.Quantity = x.Quantity.toFixed(2)
      this.fields.Unit = x.Unit
      this.fields.ConsumedDate = formatDate(x.ConsumedDate, 'dd-MM-yyyy', "en-US", 'IST')
      this.exportfields.push(this.fields);
    })
  }
  aggregateByBatchNo(): void {
    const aggregated = this.ConsumptionList.reduce((acc, curr) => {
      const date = new Date(new DatetimeConverter().transform(curr.ConsumedDate)).toDateString();
      const key = `${curr.ProductId}-${curr.ProductName}-${curr.BatchNo}-${date}`;
      if (!acc[key]) {
        acc[key] = { ...curr, Quantity: 0, ConsumedDate: date };
      }
      acc[key].Quantity += curr.Quantity;
      return acc;
    }, {});

    this.ConsumptionList = Object.values(aggregated);

    this.exportfields = [];
    this.exportoptions = {
      headers: ["Product", "Batch No.", "Quantity", "Price/Unit", "Unit", "Consumed Date",]
    };
    this.ConsumptionList.forEach((x) => {
      this.fields = [];
      this.fields.ProductName = x.ProductName;
      this.fields.BatchNo = x.BatchNo;
      this.fields.Quantity = x.Quantity.toFixed(2);
      this.fields.PricePerUnit = x.PricePerUnit;
      this.fields.Unit = x.Unit;
      this.fields.ConsumedDate = formatDate(x.ConsumedDate, 'dd-MM-yyyy', "en-US", 'IST');
      this.exportfields.push(this.fields);
    })
  }

  aggregateByStore(): void {
    const aggregated = this.ConsumptionList.reduce((acc, curr) => {
      const date = new Date(new DatetimeConverter().transform(curr.ConsumedDate)).toDateString();
      const key = `${curr.ProductId}-${curr.ProductName}-${curr.StoreId}-${curr.StoreName}-${date}`;
      if (!acc[key]) {
        acc[key] = { ...curr, Quantity: 0, ConsumedDate: date };
      }
      acc[key].Quantity += curr.Quantity;
      return acc;
    }, {});

    this.ConsumptionList = Object.values(aggregated);

    this.exportfields = [];
    this.exportoptions = {
      headers: ["Product", "Quantity", "Unit", "Store Name", "Consumed Date",]
    };
    this.ConsumptionList.forEach((x) => {
      this.fields = [];
      this.fields.ProductName = x.ProductName;
      this.fields.Quantity = x.Quantity.toFixed(2);
      this.fields.Unit = x.Unit;
      this.fields.StoreName = x.StoreName;
      this.fields.ConsumedDate = formatDate(x.ConsumedDate, 'dd-MM-yyyy', "en-US", 'IST');
      this.exportfields.push(this.fields);
    })
  }

  aggregateByStoreAndRack(): void {
    const aggregated = this.ConsumptionList.reduce((acc, curr) => {
      const date = new Date(new DatetimeConverter().transform(curr.ConsumedDate)).toDateString();
      const key = `${curr.ProductId}-${curr.ProductName}-${curr.StoreId}-${curr.StoreName}-${curr.RackId}-${curr.RackName}-${date}`;
      if (!acc[key]) {
        acc[key] = { ...curr, Quantity: 0, ConsumedDate: date };
      }
      acc[key].Quantity += curr.Quantity;
      return acc;
    }, {});

    this.ConsumptionList = Object.values(aggregated);

    this.exportfields = [];
    this.exportoptions = {
      headers: ["Product", "Quantity", "Unit", "Store", "Rack", "Consumed Date",]
    };
    this.ConsumptionList.forEach((x) => {
      this.fields = [];
      this.fields.ProductName = x.ProductName;
      this.fields.Quantity = x.Quantity.toFixed(2);
      this.fields.Unit = x.Unit;
      this.fields.StoreName = x.StoreName;
      this.fields.Rackname = x.RackName;
      this.fields.ConsumedDate = formatDate(x.ConsumedDate, 'dd-MM-yyyy', "en-US", 'IST');
      this.exportfields.push(this.fields);
    })
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        ('Consumption-export' + exportdate),
        this.exportoptions
      );
  }
  getDateRange(label: string) {
    this.enableCustomDateRange = false;
    this.request.DateFilterType = '';
    const today = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (label) {
      case 'today':
        startDate = new Date(today);
        endDate = new Date(today);
        this.request.DateFilterType = 'fullday';
        break;

      case 'yesterday':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        endDate = new Date(today);
        endDate.setDate(today.getDate() - 1);
        this.request.DateFilterType = 'fullday';
        break;

      case 'last7days':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 6);
        endDate = new Date(today);
        this.request.DateFilterType = 'fullday';
        break;

      case 'last30days':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 29);
        endDate = new Date(today);
        this.request.DateFilterType = 'fullday';
        break;

      case 'lastmonth':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        this.request.DateFilterType = 'fullday';
        break;

      case 'lastyear':
        startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        endDate = new Date(today);
        this.request.DateFilterType = 'fullday';
        break;

      case 'custom':
        startDate = new Date(today);
        endDate = new Date(today);
        this.enableCustomDateRange = true;
        this.request.DateFilterType = 'datetime';
        break;

      default:
        startDate = new Date();
        endDate = new Date();
        break;
    }
    this.request.ConsumedDateTo = endDate.toISOString();
    this.request.ConsumedDateFrom = startDate.toISOString();
    this.GetAllConsumption();

  }
  GetAllUnits() {
    this.loader.show();
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllUnits()
      }
    });
  }
  GetAllProducts() {
    this.loader.show();
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) { this.GetAllProducts() }
    });
  }

  GetAllProductCategory() {
    this.loader.show();
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllProductCategory()
      }
    });
  }
  onSelectedProductTypeChange() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
    this.loader.hide();
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductCategoryId == this.CategoryID);

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductFirstSubCategoryId == this.FirstCategoryID);

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetSecondCategoryFilteredProduct() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductSecSubCategoryId == this.SecondCategoryID);
    this.loader.hide();
  }
  GetAllSupplier() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++;
      if (this.count < 2) {
        this.GetAllSupplier()
      }
    });
  }
  onSelectedStoreChange() {
    this.loader.show();
    let url = this.ApiUrl + 'rack/getallracks';
    this.http.get<RackModel[]>(url).subscribe(
      (res) => {
        this.RackList = res.filter((x) => x.StoreId == this.StoreID);
        this.RackID = 0;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.onSelectedStoreChange();
        }
      }
    );
  }

  GetAllStore() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
        this.isTableLoading = false;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllUnits();
      this.GetAllProducts();
      this.GetAllProductCategory();
      this.GetAllStore();
      this.GetAllSupplier();
      this.GetAllUsers();
      this.filterPanelTxt = 'Hide Filters';
    }
    else {
      this.filterPanelTxt = 'Show Filters';
    }
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
}
