import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../../environments/environment";
import { UserInfo } from "../../../Authentication/UserInfo";
import { ESalesOrderStatus, Modules, Responsibility } from "../../../Models/Enums";
import { FormulationCodeModel } from "../../../Models/FormulationCodeModel";
import { IssueProductModel } from "../../../Models/IssueModel";
import { RackModel, StoreModel, ColorModel, GrainModel, ThicknessModel, WidthMasterModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from "../../../Models/MasterModel";
import { ProductionLacquerModel } from "../../../Models/ProductionModel";
import { ProductModel } from "../../../Models/ProductModel";
import { SaleOrderProductionRawMaterialModel, SaleOrderModel, SaleOrderDispatchModel, SaleOrderProductionVacuumModel, SaleOrderProductionTumblingModel, SaleOrderProductionEmbossingModel, SaleOrderProductionPrintModel, SaleOrderDispatchReadyModel } from "../../../Models/SalesOrderModel";
import { CustomerModel } from "../../../Models/SupplierModel";
import { TransportModel, TransportVehicleModel } from "../../../Models/TransportModel";
import { WorkPlanMasterModel, WorkPlanJumboMasterModel, JumboInspectionModel, WorkPlanOrderModel, JumboInspectionFilterModel, JumboDispatchModel } from "../../../Models/WorkPlanModel";
import { AlertMessageService } from "../../../Services/AlertMessageService";
import { LoadingService } from "../../../Services/loadingService";
import { GateInModel } from "src/PmsUIApp/Models/GateInModel";
import { AuthService } from "../../../Services/auth.service";
import { Subscription } from "rxjs";


@Component({
  selector: 'app-Packaging',
  templateUrl: './Packaging.component.html',
  styleUrls: ['./Packaging.component.css']
})
export class PackagingComponent implements OnInit {
  Type = 'Production';
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  WorkPlanMasterList: WorkPlanJumboMasterModel[] = [];
  JumboInspection: JumboInspectionModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SelectedOrderNo: string = '';
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  NewPOList: IssueProductModel[] = [];
  SaleOrderDispatchItems: any[] = [];
  SaleOrder: SaleOrderModel = new SaleOrderModel;
  RackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreId: number = 0
  DispatchReadyBarcode: string = '';
  DispatchNumber: string = '';
  VehicleStatus: string = '';
  DispatchDate: string = new Date().toString();
  PacketNumber: number = 0;
  sumPacketNumber: number = 0;
  DispatchReadyRack: number = 0;
  PacketWeight: number = 0;
  sumPacketWeight: number = 0;
  DispatchReadyQuantity: number = 0;
  DispatchReadyWeight: number = 0;
  TransportId: number = 0;
  VehicleId: number = 0;
  isDispatchedReady: boolean = false;
  IsDispatchCompleted: boolean = false;
  TransportList: TransportModel[] = [];
  TransportVehicleList: TransportVehicleModel[] = [];
  SaleOrderDispatchList: SaleOrderDispatchModel[] = [];
  SalesOrderList: SaleOrderModel[] = [];
  CustomerList: CustomerModel[] = [];
  FormulationCodeList: FormulationCodeModel[] = [];
  ColorList: ColorModel[] = [];
  GrainList: GrainModel[] = [];
  VaccumList: SaleOrderProductionVacuumModel[] = [];
  TumblingList: SaleOrderProductionTumblingModel[] = [];
  LacquerList: ProductionLacquerModel[] = [];
  EmbossingList: SaleOrderProductionEmbossingModel[] = [];
  PrintMasterList: SaleOrderProductionPrintModel[] = [];
  ThicknessList: ThicknessModel[] = [];
  WidthList: WidthMasterModel[] = [];
  NewFilter: JumboInspectionFilterModel = new JumboInspectionFilterModel;
  ShowFilters: boolean = true;
  IsCheckedAll: boolean = false;
  NewDispatch: JumboDispatchModel = new JumboDispatchModel;
  Orderlist!: SaleOrderModel;
  ProductList: ProductModel[] = [];
  FilteredProductList: ProductModel[] = [];
  isVisible = false;
  isPopupVisible = false;
  isLoading = false;
  IsMsgShow = false;
  count: 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0

  }
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  SelectedDispatch = [
  ];
  PackingNumber: string = '';
  JumboDispatchId: number = 0;
  CustomerName: string = '';
  ActionType: string = '';
  JumboDispatch: JumboDispatchModel = new JumboDispatchModel;
  ExistingJumboInspection: JumboInspectionModel[] = [];
  private route$: Subscription = new Subscription;
  totalItemsCount: number = 0;
  isExistingRollVisible = false;
  constructor(public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private loader: LoadingService, private auth: AuthService) { }
  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.JumboDispatchId = params["id"];
      this.ActionType = params["type"]
    })
    console.log(this.JumboDispatchId);
    console.log(this.ActionType)
    this.GetAllSalesOrder()
    this.GetAllStore();
    this.GetAllRack();
    this.GetAllCustomer();
    this.GetAllFormulationCode();
    this.GetAllThickness();
    this.GetAllWidth();
    this.GetAllColor();
    this.GetAllGrain();
    this.GetAllLacquer();
    this.GetAllPrint();
    this.GetAllEmbossing();
    this.GetAllVaccum();
    this.GetAllTumbling();
    this.GetAllTransport();
    this.GetAllProducts();
    this.GetAllProductCategory();
    this.NewDispatch.DispatchDate = new Date();
    if (this.ActionType == 'edit') {
      this.GetJumboDispatchWithId(this.JumboDispatchId);
    }
  }

  GetJumboInspectionList() {
    this.loader.show();
    this.IsMsgShow = false;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "saleorder/GetJumboInspectionListWithFilter";
    this.http.post<JumboInspectionModel[]>(url, this.NewFilter).subscribe(res => {
      this.JumboInspection = res;
      count = this.totalItemsCount;
      this.JumboInspection.forEach((x) => {
        count++;
        x.SerialNo = count;
        x.IsChecked = false
      });
      //need to sort JumboInspection by serialNo in descending order by JumboInspectionId
      this.JumboInspection.sort((a, b) => b.JumboInspectionId + a.JumboInspectionId);
      //this.onUp('Grade', this.JumboInspection);
      this.loader.hide();
      if (this.JumboInspection.length <= 0) {
        this.IsMsgShow = true;
        return;
      }
      else if(this.JumboInspection.length > 0){
        this.ShowFilters = false;
      }
    }, res => { this.loader.hide(); });
  }
  GetDispatchDetails() {

    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdispatchdetailbyid/" + this.SelectedOrderNo;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      this.SaleOrderDispatchList = res.SaleOrderDispatch;
      console.log(this.SaleOrderDispatchList);
      if (res.Status.toString() == "DispatchReady" || res.Status.toString() == "DispatchedGateIn") {
        this.SaleOrder.SaleOrderDispatchReady = res.SaleOrderDispatchReady
        this.isDispatchedReady = true;
        var racked = this.RackListOriginal.filter(x => x.RackId == res.SaleOrderDispatchReady?.DispatchReadyRack)[0];
        var store = this.StoreList.filter(x => x.StoreId == racked.StoreId)[0];
        this.SaleOrder.SaleOrderDispatchReady.RackName = racked.RackName;
        this.SaleOrder.SaleOrderDispatchReady.StoreName = store.StoreName;
      }
      this.loader.hide();
    }, res => { this.loader.hide(); });
  }
  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllStore()
      }
    });
  }
  GetAllRack() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackListOriginal = res;
      this.RackList = res;
      //this.RackID = 0;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllRack()
      }
    });
  }
  onSelectedStoreChange($event: number) {
    this.DispatchReadyRack = 0;
    this.RackList = this.RackListOriginal.filter(x => x.StoreId == $event);
  }
  GetAllTransport() {
    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe({
      next: res => { this.TransportList = res },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
        this.count++;
        if (this.count < 2) {
          this.GetAllTransport();
        }
      },
    });
  }
  onSelectedTransportChange($event: number) {
    var StockProductRejectedDispatch = this.TransportList.filter(x => x.TransportId == $event)[0];
    this.TransportVehicleList = StockProductRejectedDispatch.TransportVehicle;

  }

  onSelectedVehicleChange($event: number) {
    let url = this.ApiUrl + "gate/getvehiclestatus?vehicleid=" + $event;
    this.http.get<GateInModel>(url).subscribe({
      next: res => {
        this.VehicleStatus = null;
        if (res != null) {
          this.VehicleStatus = "Selected Vehicle is already Gate-in";
          if (res.GatePassIssue == true) {
            this.VehicleStatus = this.VehicleStatus + " and Gate pass issued";
          }
        }
      },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
      },
    });
  }

  AddSaleOrderDispatchItems() {

    var Item = {
      PacketNumber: this.PacketNumber,
      PacketWeight: this.PacketWeight,
      TransportId: this.TransportId,
      TransportName: this.TransportId == 0 ? 'Other' : this.TransportList.filter(el => el.TransportId == this.TransportId)[0].TransportCompanyName,
      VehicleId: this.VehicleId,
      VehicleName: this.VehicleId == 0 ? 'Other' : this.TransportVehicleList.filter(el => el.VehicleId == this.VehicleId)[0].VehicleNumber,
      VehicleNumber: this.VehicleId == 0 ? 'Other' : this.TransportVehicleList.filter(el => el.VehicleId == this.VehicleId)[0].VehicleNumber,
      DispatchNumber: this.DispatchNumber,
      DispatchDate: new Date(this.DispatchDate),
      SaleOrderId: this.SaleOrder.SaleOrderId,
      AddedBy: UserInfo.EmailID,
      IsDispatchCompleted: this.IsDispatchCompleted
    }
    if (Item.PacketNumber <= 0) {
      this.alertService.error("Enter Packet Number");
      return;
    }
    if (Item.PacketWeight <= 0) {
      this.alertService.error("Enter Packet Weight");
      return;
    }
    if (Item.TransportId <= 0) {
      this.alertService.error("Select Transport");
      return;
    }
    if (Item.VehicleId <= 0) {
      this.alertService.error("Select Vehicle");
      return;
    }
    if (Item.DispatchNumber == '') {
      this.alertService.error("Enter Dispatch Number");
      return;
    }
    if (Item.DispatchDate.toString() == '') {
      this.alertService.error("Select Dispatch Date");
      return;
    }
    this.SaleOrderDispatchItems.push(Item);
    this.sumPacketNumber = this.SaleOrderDispatchItems.length;
    this.sumPacketWeight = Number.parseInt(this.sumPacketWeight.toString()) + Number.parseInt(this.PacketWeight.toString());
    this.PacketNumber = 0;
    this.PacketWeight = 0;
    this.TransportId = 0;
    this.VehicleId = 0;
    this.DispatchNumber = '';
    //this.DispatchDate = '';
  }
  SaveReadyDispatch() {

    var Item = new SaleOrderDispatchReadyModel();
    Item.SaleOrderId = this.SaleOrder.SaleOrderId;
    Item.DispatchReadyBarcode = this.DispatchReadyBarcode;
    Item.DispatchReadyRack = this.DispatchReadyRack;
    Item.DispatchReadyQuantity = this.DispatchReadyQuantity;
    let url = this.ApiUrl + "saleorder/addsaleorderdispatchready";
    this.http.post<any>(url, Item).subscribe({
      next: res => {
        this.isDispatchedReady = true;
        this.SaleOrder.Status = ESalesOrderStatus.DispatchReady;
        this.alertService.success("Entry Saved Successfully");
        this.SaleOrder.SaleOrderDispatchReady = Item;
        this.SaleOrder.SaleOrderDispatchReady.RackName = this.RackList.filter(x => x.RackId == this.DispatchReadyRack)[0].RackName;
        this.SaleOrder.SaleOrderDispatchReady.StoreName = this.RackList.filter(x => x.RackId == this.DispatchReadyRack)[0].StoreName;
        this.IsDispatchCompleted = false;
      },
      error: res => { this.alertService.error(res.error); },
    });
  }
  sumDispatchQTY(data: JumboInspectionModel) {
    if (data.DispatchedQuantity > data.Quantity) {
      data.DispatchedQuantity = data.Quantity;
      this.alertService.error("Dispatch Quantity cannot be more than Quantity");
      return
    }
    this.DispatchReadyQuantity = 0;
    this.JumboInspection.forEach(x => {
      this.DispatchReadyQuantity += x.DispatchedQuantity ?? 0
    })
  }

  GetAllJumboList() {
    let url = this.ApiUrl + 'workplan/getallworkplanjumbo';
    this.http.get<any>(url).subscribe({
      next: res => {
        this.WorkPlanMasterList = res.Data;
        this.JumboInspection = [];
        this.WorkPlanMasterList.filter(x => x.SaleOrderId == parseInt(this.SelectedOrderNo)).forEach(el => {
          el.JumboInspection.forEach(x => {
            this.JumboInspection.push(x)
          })

        })
        console.log(this.JumboInspection)
      },
      error: res => { },
    });
  }
  handleRemoveRow(data: any) {
    this.SaleOrderDispatchItems = this.SaleOrderDispatchItems.filter((obj) => obj !== data);

  }
  clearWorkplan() {
    this.sumPacketWeight = 0;
    this.JumboInspection = [];
    this.SaleOrderDispatchList = [];
    this.SelectedOrderNo = '';
    this.SaleOrder = new SaleOrderModel;
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.productItem.CategoryID = this.ProductCategoryList.filter(x => x.ProductCategory.toUpperCase() == 'FABRIC')[0]?.ProductCategoryId
      this.FilteredProductList = this.ProductList.filter(x => x.ProductCategoryId == this.productItem.CategoryID)
      console.log(this.ProductCategoryList)
    }, res => { this.GetAllProductCategory() });
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    if (data != null) {
      let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
      this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
        this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
        this.productItem.FirstCategoryID = id;
        this.FilteredProductList = this.ProductList.filter(x => x.ProductCategoryId == data)
      }, res => { });
    }
    else {
      this.FilteredProductList = this.ProductList;
    }
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.productItem.SecondCategoryID = id;
    }, res => { });
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.FilteredProductList = this.ProductList;
    }, (res) => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProducts();
      }
    })
  }

  GetAllSalesOrder() {
    let url = this.ApiUrl + 'saleorder/getallsaleorders';
    this.http.get<SaleOrderModel[]>(url).subscribe(
      (res) => {
        this.SalesOrderList = res.filter(x => x.Status.toString() == "MoveToDispatch" || x.Status.toString() == "JumboInspection" || x.Status.toString() == "PartialDispatchReady" || x.Status.toString() == "PartialDispatchCompleted");
      },
      (res) => { }
    );
  }
  GetAllCustomer() {
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllCustomer()
      }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllFormulationCode()
      }
    });
  }
  GetAllVaccum() {
    let url = this.ApiUrl + 'vacuum/getallvacuums';
    this.http.get<SaleOrderProductionVacuumModel[]>(url).subscribe(
      (res) => {
        this.VaccumList = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllVaccum()
        }
      }
    );
  }
  GetAllTumbling() {
    let url = this.ApiUrl + 'tumbling/getalltumblings';
    this.http.get<SaleOrderProductionTumblingModel[]>(url).subscribe(
      (res) => {
        this.TumblingList = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllTumbling()
        }
      }
    );
  }
  GetAllLacquer() {
    let url = this.ApiUrl + 'lacquer/getalllacquers';
    this.http.get<ProductionLacquerModel[]>(url).subscribe(
      (res) => {
        this.LacquerList = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllLacquer()
        }
      }
    );
  }
  GetAllEmbossing() {
    let url = this.ApiUrl + "embossing/getallembossings";
    this.http.get<SaleOrderProductionEmbossingModel[]>(url).subscribe(res => {
      this.EmbossingList = res;

    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllEmbossing()
      }
    });
  }
  GetAllPrint() {
    let url = this.ApiUrl + "print/getallprints";
    this.http.get<SaleOrderProductionPrintModel[]>(url).subscribe(res => {
      this.PrintMasterList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllPrint()
      }
    });
  }
  GetAllThickness() {
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllThickness()
      }
    });
  }
  GetAllWidth() {
    let url = this.ApiUrl + "width/getallwidthdata";
    this.http.get<WidthMasterModel[]>(url).subscribe(res => {
      this.WidthList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllWidth()
      }
    });
  }
  GetAllColor() {
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;

    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllColor()
      }
    });
  }
  GetAllGrain() {
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;

    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllGrain()
      }
    });
  }
  CalculateDispatchQTY() {
    this.NewDispatch.DispatchQuantity = 0;
    this.JumboInspection.forEach(x => {
      if (x.IsChecked) {
        this.NewDispatch.DispatchQuantity += x.Quantity;
      }
    })
    this.NewDispatch.DispatchQuantity = parseFloat(this.NewDispatch.DispatchQuantity.toFixed(2));
  }
  CalculateDispatchWeight() {
    this.NewDispatch.Weight = 0;
    this.JumboInspection.forEach(x => {
      if (x.IsChecked) {
        this.NewDispatch.Weight += x.Weight;
      }
    })
    this.NewDispatch.Weight = parseFloat(this.NewDispatch.Weight.toFixed(2));
  }
  CalculateDispatchGrade() {
    this.SelectedDispatch = [];
    this.JumboInspection.forEach(x => {
      if (x.IsChecked) {
        if (this.SelectedDispatch.filter(y => y.Grade == x.Grade).length > 0) {
          this.SelectedDispatch.filter(y => y.Grade == x.Grade)[0].Qty += x.Quantity
          this.SelectedDispatch.filter(y => y.Grade == x.Grade)[0].Count += 1
        } else {
          var item = {
            "Grade": x.Grade,
            "Qty": x.Quantity,
            "Count": 1
          }
          this.SelectedDispatch.push(item);
        }
      }
    })
  }
  CheckAll() {
    this.NewDispatch.DispatchQuantity = 0;
    this.JumboInspection.forEach(x => {
      x.IsChecked = this.IsCheckedAll;
      if (x.IsChecked) {
        this.NewDispatch.DispatchQuantity += x.Quantity;
      }
    })
    this.NewDispatch.Weight = 0;
    this.JumboInspection.forEach(x => {
      x.IsChecked = this.IsCheckedAll;
      if (x.IsChecked) {
        this.NewDispatch.Weight += x.Weight;
      }
    })
    this.CalculateDispatchGrade();
  }
  SaveDispatchItems() {
    var selectedDispatch = this.JumboInspection.filter(x => x.IsChecked == true);
    if (selectedDispatch.length > 0) {
      this.NewDispatch.JumboInspection = selectedDispatch;
    }
    else {
      this.alertService.error("Please select at lease one roll.")
      return;
    }
    if (this.NewDispatch.TransportId == null) {
      this.NewDispatch.TransportId = 0;
    }

    if (this.ActionType != 'edit') {
      if (isNaN(this.NewDispatch.CustomerId) || this.NewDispatch.CustomerId <= 0) {
        this.alertService.error("Please select customer.")
        return;
      }
      if (isNaN(this.NewDispatch.StoreId) || this.NewDispatch.StoreId <= 0) {
        this.alertService.error("Please select Store.")
        return;
      }
      if (isNaN(this.NewDispatch.RackId) || this.NewDispatch.RackId <= 0) {
        this.alertService.error("Please select rack.")
        return;
      }
      if (this.NewDispatch.DispatchDate == null) {
        this.alertService.error("Please select Dispatch Date.")
        return;
      }
      if (this.NewDispatch.Remark?.length > 0 && this.NewDispatch.Remark?.length > 150) {
        this.alertService.error("Remark cannot be more than 150 characters.")
        return;
      }
    }

    console.log(this.NewDispatch)
    this.isLoading = true;
    let url = '';
    if (!this.JumboDispatchId) {
      url = this.ApiUrl + "saleorder/AddJumboDispatch";
    }
    else {
      this.NewDispatch.JumboDispatchId = this.JumboDispatchId;
      url = this.ApiUrl + "saleorder/jumbodispatchedit";
    }

    this.http.post<any>(url, this.NewDispatch).subscribe({
      next: res => {
        this.resetFilter();
        this.alertService.success(res.ResponseBody);
        this.router.navigate(['/home/dispatch/packaging/list']);
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },
    });

  }
  resetFilter() {
    this.JumboInspection = [];
    this.NewFilter = new JumboInspectionFilterModel;
  }

  modifyFilters() {
    this.ShowFilters = true;
  }

  OpenViewPop(data: any) {
    this.loader.show();
    let url =
      this.ApiUrl + 'saleorder/getsaleorderdataforviewbyid/' + data.SaleOrderId;

    this.http.get<any>(url).subscribe((res) => {
      this.Orderlist = res;
      this.isVisible = true;
      this.calclulateTotalSalePrice();
      this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
      this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach(
        (element) => {
          this.Orderlist.SaleOrderProduction.RawGSMTotal +=
            parseFloat(element.Quantity.toString()) *
            (isNaN(parseInt(element.AvgGsm?.toString()))
              ? 0
              : parseInt(element.AvgGsm?.toString()));
        }
      );
      this.Orderlist.SaleOrderProduction.MixingGSMTotal =
        this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
      this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(
        (element) => {
          this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(
            element.Total
          )
            ? 0
            : element.Total;
          this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(
            parseInt(element.WeightGsm?.toString())
          )
            ? 0
            : parseInt(element.WeightGsm.toString());
          this;
        }
      );
      this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal =
        this.Orderlist.SaleOrderProduction.MixingGSMTotal +
        this.Orderlist.SaleOrderProduction.RawGSMTotal;
      var totalprint =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalprint);
      var totalEmbossing =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalEmbossing);
      var totalVaccum =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalVaccum);
      var totalTumbling =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalTumbling);
      //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      //console.log(totalLacquer);
      var totalElement =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(isNaN(totalElement) ?? 0);
      var totalRaw =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce(
          (sum, current) => sum + parseFloat(current.TotalCost?.toString()),
          0
        ) ?? 0;
      console.log(totalRaw);
      this.Orderlist.GrandTotal =
        parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
        parseFloat(isNaN(totalprint) == true ? '0' : totalprint.toString()) +
        parseFloat(
          isNaN(totalEmbossing) == true ? '0' : totalEmbossing.toString()
        ) +
        parseFloat(isNaN(totalVaccum) == true ? '0' : totalVaccum.toString()) +
        parseFloat(
          isNaN(totalTumbling) == true ? '0' : totalTumbling.toString()
        ) +
        /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
        parseFloat(
          isNaN(totalElement) == true ? '0' : totalElement.toString()
        ) +
        parseFloat(totalRaw.toString());
      console.log(this.Orderlist);
      this.loader.hide();
    });
  }
  calclulateTotalSalePrice() {
    this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  onUp(key: any, data: any) {
    let res = this.JumboInspection;
    data = data.sort((a: any, b: any) =>
      a[key] > b[key] ? 1 : -1
    );
  }
  onDown(key: any, data: any) {
    let res = this.JumboInspection;
    data = data.sort((a: any, b: any) =>
      a[key] < b[key] ? 1 : -1
    );
  }
  showModal() {
    this.isPopupVisible = true;
  }
  handlePopupCancel(): void {
    this.isPopupVisible = false;
  }
  sumquantity() {
    var sum = this.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sum1stquantity() {
    var sum = this.JumboInspection.filter(x => x.Grade == "1st").reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sumAquantity() {
    var sum = this.JumboInspection.filter(x => x.Grade == "A").reduce((accumulator, object) => {
      return accumulator + object.Quantity
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sumweight() {
    var sum = this.JumboInspection.reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sum1stweight() {
    var sum = this.JumboInspection.filter(x => x.Grade == "1st").reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  sumAweight() {
    var sum = this.JumboInspection.filter(x => x.Grade == "A").reduce((accumulator, object) => {
      return accumulator + object.Weight
    }, 0);
    return parseFloat(sum.toFixed(2))
  }
  GetJumboDispatchWithId(DispatchId: number) {
    this.loader.show();
    let url = this.ApiUrl + 'saleorder/GetJumboDispatchListByDispatchId/' + DispatchId;
    this.http.get<JumboDispatchModel>(url).subscribe({
      next: res => {
        this.JumboDispatch = res;
        this.CustomerName = this.JumboDispatch.CustomerDetails[0].CustomerName
        this.ExistingJumboInspection = this.JumboDispatch.JumboInspection;
        this.loader.hide();
      },
      error: res => {
        this.alertService.error(res.error);
        this.loader.hide();
      },
    }
    );
  }
  handleDispatchCancel(): void {
    this.isExistingRollVisible = false;
  }
  showExistingRoll() {
    this.isExistingRollVisible = true;
  }
  calculateUpdatedTotalQTY() {
    if (this.ActionType == 'edit') {
      if (!isNaN(this.NewDispatch.DispatchQuantity)) {
        return this.NewDispatch.DispatchQuantity + this.JumboDispatch.DispatchQuantity;
      }
      else {
        return this.JumboDispatch.DispatchQuantity;
      }
    }
    else {
      return null
    }
  }
  calculateUpdatedTotalWeight() {
    if (this.ActionType == "edit") {
      if (!isNaN(this.NewDispatch.Weight)) {
        return this.NewDispatch.Weight + parseFloat(this.JumboDispatch.Weight.toString());
      }
      else {
        return this.JumboDispatch.Weight;
      }
    }
    else {
      return null
    }
  }
}

