import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { ThicknessModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
@Component({
  selector: 'app-thickness',
  templateUrl: './thickness.component.html',
  styleUrls: ['./thickness.component.css']
})
export class ThicknessComponent implements OnInit {
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  ThicknessList: ThicknessModel[] = [];
  ThicknessListOriginal: ThicknessModel[] = [];
  NewThickness: ThicknessModel = new ThicknessModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  PopUpTitle: string = "Add New Thickness";
  ThicknessNumberError = 'Enter Thickness number';
  ThicknessCodeError = 'Enter Thickness code';
  ThicknessUnitError = 'Select Unit';
  searchValue = ''
  options = {
    //fieldSeparator: ',',
    //quoteStrings: '"',
    //decimalseparator: '.',
    //showLabels: true,
    //showTitle: true,
    //title: 'Your title',
    //useBom: true,
    //noDownload: true,
    headers: ["Sno.", "Branch Name", "Branch Code", "Branch Desc", "Added Date", "Added By"]
    //useHeader: false,
    //nullToEmptyString: true,
  };
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: number;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) {


  }

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllThickness();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ThicknessListOriginal;
    var res2 = this.ThicknessListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ThicknessModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ThicknessList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ThicknessList = res.filter(
        (item: ThicknessModel) =>
          item?.ThicknessNumber?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Code?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ThicknessNumber?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Thickness, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Thickness, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Thickness, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      ThicknessNumber: [null, [Validators.required, Validators.minLength(3)], [this.ThicknessNumberAsyncValidator]],
      Unit: [null, [Validators.required]],
      Code: [null, [Validators.required]],
      Description: [null, [Validators.maxLength(200)]]

    });
    this.GetAllThickness();
    this.GetAllUnits();
  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnits(); }
    });
  }
  GetAllThickness() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
      this.ThicknessListOriginal = res
      this.isTableLoading = false;
      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => { this.GetAllThickness(); });
  }
  SaveThickness() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewThickness.ThicknessNumber = this.f['ThicknessNumber'].value;
    this.NewThickness.Unit = this.f['Unit'].value;
    this.NewThickness.Code = this.f['Code'].value;
    this.NewThickness.Description = this.f['Description'].value;
    this.NewThickness.AddedBy = UserInfo.EmailID;
    this.NewThickness.AddedDate = null;
    let url = this.ApiUrl + "thickness/addupdatethickness";
    this.http.post<ThicknessModel>(url, this.NewThickness).subscribe({

      next: res => { this.alertService.success("Thickness Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllThickness(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: ThicknessModel) {
    this.PopUpTitle = "Modify Thickness Details";
    this.validateForm.setValue(
      {
        ThicknessNumber: data.ThicknessNumber,
        Unit: data.Unit,
        Code: data.Code,
        Description: data.Description
      }
    )
    this.NewThickness = new ThicknessModel();
    this.NewThickness.ThicknessId = data.ThicknessId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Thickness";
    this.validateForm.reset();
    this.NewThickness = new ThicknessModel();
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveThickness();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteThickness() {
    let url = this.ApiUrl + "thickness/deletethickness";
    this.http.post<any>(url, this.NewThickness).subscribe({

      next: res => {

        this.alertService.success("Thickness Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllThickness();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = false; },

    });

  }
  handleDelete(data: ThicknessModel) {
    this.NewThickness = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Thickness?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteThickness(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }

  ThicknessNumberAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.ThicknessList.filter(x => x.ThicknessId != this.NewThickness.ThicknessId);
        var nre = res.filter(x => x.ThicknessNumber.toLowerCase() == control.value.toLowerCase());
        this.ThicknessNumberError = '';

        if (control.value == '') {
          this.ThicknessNumberError = "Enter Thickness Number (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.ThicknessNumberError = "Thickness number already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.ThicknessNumberError = "Enter Thickness Number (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

}
