import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductModel } from '../../../Models/ProductModel';
import { LinkedSaleOrderModel, SaleOrderCostingModel, SaleOrderModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import {
  WorkPlanMasterModel,
} from 'src/PmsUIApp/Models/WorkPlanModel';
import { UserInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { LoadingService } from '../../../Services/loadingService';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ESalesOrderStatus, Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../../Services/auth.service';
import { FormulationCodeModel } from '../../../Models/FormulationCodeModel';
import { CustomerModel } from '../../../Models/SupplierModel';
import { ColorModel, GrainModel, PostProcessCostingMasterModel } from 'src/PmsUIApp/Models/MasterModel';
import moment from 'moment';

@Component({
  selector: 'app-LinkSaleOrder',
  templateUrl: './LinkSaleOrder.component.html',
  styleUrls: ['./LinkSaleOrder.component.css'],
})
export class LinkSaleOrderComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  SalesList: SaleOrderModel[] = [];
  TimeLineObject: any;
  searchValue = '';
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  disableAll: boolean = false;
  disableLinkedAll: boolean = false;
  exportoptions = {
    headers: [
      'SaleOrderType',
      'SaleOrderNumber',
      'Delivery Date',
      'AddedBy',
      'SaleOrderDate',
      'Status',
    ],
  };

  fields: any;
  exportfields = [
    {
      SaleOrderType: '',
      SaleOrderNumber: '',
      Status: '',
      DeliveryDate: '',
      AddedBy: '',
      SaleOrderDate: '',
    },
  ];
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false,
    Workplan: false,
    Costing: false
  }
  totalItemsCount: number = 0;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loader: LoadingService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Delete);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Manage);
    this.permission.Workplan = this.auth.CheckResponsibility(Modules.Workplan, Responsibility.Add);
    this.permission.Costing = this.auth.CheckResponsibility(Modules.Costing, Responsibility.HighValueApproval);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllSalesOrder();
  }

  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }

  onSearch() {

  }
  onPrimayChecked(res) {
    this.disableAll = res;
    this.SalesList.forEach(element => {
      element.IsLinkedChecked = false;
    });
  }

  onLinkedChecked(res) {
    this.disableLinkedAll = res;
    this.SalesList.forEach(element => {
      if (element.IsLinkedChecked != true) {
        element.IsLinkedChecked = false;
      }
    });
  }

  CheckDisabled(res) {
    if (res.IsPrimaryChecked == true) {
      return false;
    }
    else
      return this.disableAll;
  }
  CheckLiningDisabled(res) {
    if (res.IsLinkedChecked == true) {
      return false;
    }
    else
      return this.disableLinkedAll;
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'sale-Order-list-export' + exportdate,
        this.exportoptions
      );
  }
  get f() {
    return this.validateForm.controls;
  }

  GetAllSalesOrder() {
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + 'saleorder/getallsaleorderdataforlinking';
    this.http.get<SaleOrderModel[]>(url).subscribe(
      (res) => {
        this.SalesList = res;
        count = this.totalItemsCount;
        this.SalesList.forEach((x) => {
          count++;
          x.SerialNo = count;
        })
        this.totalItemsCount = count;
        this.isTableLoading = false;
      },
      (res) => { }
    );
  }

  SaveLinkSaleOrder() {
    var res: LinkedSaleOrderModel[] = [];
    var pid = this.SalesList.filter(x => x.IsPrimaryChecked)[0].SaleOrderId;
    var lid = this.SalesList.filter(x => x.IsLinkedChecked);
    lid.forEach(element => {
      if (element.SaleOrderId != pid) {
        var data = new LinkedSaleOrderModel();
        data.LinkedSaleOrder = element.SaleOrderId;
        data.ParentSaleOrder = pid;
        res.push(data);
      }
    });
    let url = this.ApiUrl + 'saleorder/linksaleorder';
    this.http.post<any>(url, res).subscribe({
      next: (res) => {
        this.alertService.success(res);
        this.GetAllSalesOrder();
        this.isLoading = false;
        this.loader.hide();
      },
      error: (res) => {
        this.alertService.error(res.error.ResponseBody);
        this.GetAllSalesOrder();
        this.isLoading = false;
        this.loader.hide();
      },
    });
  }

}
