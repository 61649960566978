<div *ngIf="this.ProformaInvoice">
  <table class="companytable">
    <tr>
      <td>
        <div class="company">
          <img src="/assets/img/printlogo.jpeg" />
          <b class="title">{{ Company.CompanyName }}</b>
          <p [innerHTML]="Company.Address"></p>
          <p>Contact: {{ Company.Contact }}</p>
          <p>Email: {{ Company.Email }}</p>
          <p>GSTIN: {{ Company.GST }}</p>
        </div>
        <div class="vendor" *ngIf="ProformaInvoice">
          <b class="title">Consignee</b>

          <p class="name">{{ ProformaInvoice.Customer.CustomerName }}</p>

          <p>{{ ProformaInvoice.Customer.Address }}</p>
          <p>Contact:{{ ProformaInvoice.Customer.CustomerContactNumber }}</p>
          <p>Email:{{ ProformaInvoice.Customer.Email }}</p>
          <p>GSTIN:{{ ProformaInvoice.Customer.Gstnumber }}</p>
          <hr />
          <p>Material Type: {{ProformaInvoice.MaterialType}}</p>
          <p>HSN/SAC Code: {{ProformaInvoice.Hsncode}}</p>
          <p>Port of Loading: {{ProformaInvoice.PortOfLoading}}</p>
          <p>Final Destination: {{ProformaInvoice.FinalDestination}}</p>
          <p>Port of Discharge :{{ProformaInvoice.PortOfDischarge}}</p>
          <p>Mode of Transport :{{ProformaInvoice.ModeOfTransport}}</p>

          <hr />
        </div>
      </td>
      <td>
        <div class="po" style="margin-top:0">
          <div class="title">PROFORMA INVOICE</div>
          <table class="potable" *ngIf="ProformaInvoice">
            <tr>
              <td><b> Invoice No</b></td>
              <td>{{ ProformaInvoice.ProformaInvoiceNumber }}</td>
            </tr>

            <tr>
              <td><b>Date</b></td>
              <td>{{ ProformaInvoice.ProformaInvoiceDate | date: "dd-MMM-yyyy" }}</td>
            </tr>
            <tr>
              <td><b>Buyer Ref No.</b></td>
              <td>{{ ProformaInvoice.BuyerReferenceNumber}}</td>

            </tr>
            <tr>
              <td><b>{{ProformaInvoice.ReferenceType}}'s Ref</b></td>
              <td>{{ ProformaInvoice.ConsignorReference }}</td>

            </tr>
            <tr>
              <td><b>Bank Details :</b></td>
              <td style="color: blue">
                {{Bank?.BankName?.toString().toUpperCase()}}, {{Bank?.Branch?.toString().toUpperCase()}} BRANCH

              </td>
            </tr>
            <td><b>Beneficiary Name:</b></td>
            <td style="color: blue">{{Bank?.AccountHolderName?.toString().toUpperCase()}}</td>
            <tr>
              <td><b> ACCOUNT NUMBER:</b></td>
              <td style="color: blue">{{Bank?.AccountNumber}}</td>
            </tr>
            <tr>
              <td><b> IFSC CODE:</b></td>
              <td style="color: blue">{{Bank?.Ifsc?.toString().toUpperCase()}}</td>
            </tr>
            <tr>
              <td><b>MICR CODE:</b></td>
              <td style="color: blue">{{Bank?.Micr?.toString().toUpperCase()}}</td>
            </tr>
            <tr>
            <tr>
              <td><b>SWIFT CODE:</b></td>
              <td style="color: blue">{{Bank?.SwiftCode?.toString().toUpperCase()}}</td>
            </tr>


          </table>
        </div>
      </td>
    </tr>
  </table>

  <div class="producttable-container">
    <table class="producttable">
      <thead>
        <tr>
          <th width="50px">Sr.No</th>
          <th width="400px">Zaibunco Product Code/Description of Goods</th>
          <th width="150px">Article Name</th>
          <th>Quantity(MTR)</th>
          <th>PRICE({{ProformaInvoice.Currency}}/MTR)</th>
          <th>AMOUNT({{ProformaInvoice.Currency}})</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ProformaInvoice.ProformaInvoiceItem; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{data.SaleFormulationCodeProductName}} / {{ data.DescriptionOfGoods }}</td>
          <td>{{ data.ArticleName }}</td>
          <td>{{ data.Quantity }}</td>
          <td>{{ data.Price }}</td>
          <td>{{ data.Price*data.Quantity }}</td>

        </tr>

      </tbody>
    </table>
    <table class="producttotaltable">
      <tbody>
        <tr>
          <td style="width: 60%">
            <b>Amount Chargeable</b>
            <div class="comments"></div>
          </td>
          <td>
            <b> (In words) ({{ProformaInvoice.Currency}})-{{TotalWord}} </b>
          </td>


          <td>
            <table class="producttotaltable" style="text-align: right">
              <tbody>
                <tr>
                  <td style="width: 75%"><b> Gross Total Amount </b></td>
                  <td style="width: 25%"> {{ProformaInvoice.TotalPrice}} </td>
                </tr>
                <tr>
                  <td style="width: 75%"><b> Add {{ProformaInvoice.Moq}}% Below MOQ Charge % </b></td>
                  <td style="width: 25%"> {{ProformaInvoice.Moqtotal}} </td>
                </tr>
                <tr>
                  <td style="width: 75%"><b> After MOQ </b></td>
                  <td style="width: 25%"> {{ProformaInvoice.TotalPrice + ProformaInvoice.Moqtotal}} </td>
                </tr>
                <tr *ngIf="ProformaInvoice.Gstn>'0'">
                  <td style="width: 75%"><b> GST&#64;{{ProformaInvoice.Gstn}}% </b></td>
                  <td style="width: 25%"> {{ProformaInvoice.Gst}} </td>
                </tr>
                <tr *ngIf="ProformaInvoice.Gstn>'0'">
                  <td style="width: 75%"><b>After GST Price </b></td>
                  <td style="width: 25%"> {{ProformaInvoice.TotalPrice + ProformaInvoice.Moqtotal +
                    ProformaInvoice.Gst}} </td>
                </tr>
                <tr>
                  <td style="width: 75%"><b>R/O </b></td>
                  <td style="width: 25%">0.00</td>
                </tr>
                <tr>
                  <td style="width: 75%"><b>Discount </b></td>
                  <td style="width: 25%">{{ProformaInvoice.Discount}}%</td>
                </tr>
                <!-- <tr>
                  <td style="width:50%"><b>Freight & Insurance </b></td>
                  <td style="width:50%"> 0.00</td>
                </tr> -->
                <!-- <tr>
                  <td style="width:50%"><b>Other </b></td>
                  <td style="width:50%"> 0.00</td>
                </tr> -->
              </tbody>
              <tfoot>
                <tr style="border-top: 2px solid">
                  <td style="width: 75%"><b>G.Total({{ProformaInvoice.Currency}}) </b></td>
                  <td style="width: 25%">{{GTotal}}</td>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <b>Terms & Conditions</b>
    <div [innerHTML]="ProformaInvoice.TermsCondition| nzSanitizer: 'html'"></div>

  </div>
  <!--<div class="footer" style="margin-bottom: 480px">
    <table class="footer-table">
      <tr>
        <td width="45%" style="vertical-align:text-top; padding-left: 20px; border-color: black; border-width: 2px;">
          <p style="margin-top: 5px"><b> Approved BY</b></p>
        </td>
        <td width="15%">
        </td>
        <td width="40%" style="text-align: left; padding-right: 20px;border-color: black; border-width: 2px;">
          <p style="margin-top: 10px">Signature</p>
          <p style="text-align: right;"><b>FOR ZAIBUNCO INDUSTRIES PVT LTD</b></p>
          <p style="font-size:10px;line-height:4;"><br /></p>
          <p style="margin-bottom: 2px;text-align: center;">AUTHORISED SIGNATORY</p>
        </td>
      </tr>
    </table>
  </div>-->
  <div class="footer" style="margin-bottom:480px;">
    <p>For any enquiry please contact </p>
    <table class="footer-table">
      <tr>
        <td width="33%" style="text-align: left; padding-left: 20px;">
          <p style="margin-bottom:50px"><b> PREPARED BY - </b></p>
          <p style="margin-top: -15px;">Auth Signatory</p>
        </td>
        <td width="34%">
          <p style="margin-bottom:50px"><b>APPROVED BY</b></p>
          <p style="margin-top: -15px;">Auth Signatory</p>
        </td>
        <td width="33%" style="text-align: right; padding-right: 20px;">
          <p style="margin-bottom:50px"><b>DIRECTOR'S SIGNATURE</b></p>
          <p style="margin-top: -15px;">Auth Signatory</p>
        </td>
      </tr>
    </table>
  </div>
</div>