import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../environments/environment";
import { ESalesOrderStatus, Modules, Responsibility } from "../../Models/Enums";
import { AlertMessageService } from "../../Services/AlertMessageService";
import { AuthService } from "../../Services/auth.service";
import { LoadingService } from "../../Services/loadingService";
import { SaleOrderModel } from "src/PmsUIApp/Models/SalesOrderModel";



@Component({
    selector: 'app-ActivityLog',
    templateUrl: './ActivityLog.component.html'
})
export class ActivityLogComponent implements OnInit {
    ApiUrl = environment.Api_Url;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    permission = {
        View: false
    }
    statusList = Object.values(ESalesOrderStatus).filter(value => typeof value != 'number');
    request = {
        SaleOrderNumber: null,
        Status: null,
        DateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 3)),
        DateTo: new Date(new Date().setHours(23, 59, 59)),
        Addedby: null
    }
    ActivityLogList: any;
    Orderlist: SaleOrderModel;
    constructor(private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.ActivityLog, Responsibility.View);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.GetAllActivityLog();
    }
    GetAllActivityLog() {
        this.isTableLoading = true;
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, this.request).subscribe(res => {
            console.log(res);
            this.ActivityLogList = res;
            this.isTableLoading = false;
        }, res => {
            this.GetAllActivityLog();
        });
    }
    OpenViewPop(data: any) {
        this.loader.show();
        let url =
            this.ApiUrl + 'saleorder/getsaleorderdataforviewbyid/' + data.SaleOrderId;

        this.http.get<any>(url).subscribe((res) => {
            this.Orderlist = res;
            this.isVisible = true;
            this.calclulateTotalSalePrice();
            this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
            this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach(
                (element) => {
                    this.Orderlist.SaleOrderProduction.RawGSMTotal +=
                        parseFloat(element.Quantity.toString()) *
                        (isNaN(parseInt(element.AvgGsm?.toString()))
                            ? 0
                            : parseInt(element.AvgGsm?.toString()));
                }
            );
            this.Orderlist.SaleOrderProduction.MixingGSMTotal =
                this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
            this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(
                (element) => {
                    this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(
                        element.Total
                    )
                        ? 0
                        : element.Total;
                    this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(
                        parseInt(element.WeightGsm?.toString())
                    )
                        ? 0
                        : parseInt(element.WeightGsm.toString());
                    this;
                }
            );
            this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal =
                this.Orderlist.SaleOrderProduction.MixingGSMTotal +
                this.Orderlist.SaleOrderProduction.RawGSMTotal;
            var totalprint =
                this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce(
                    (sum, current) => sum + parseFloat(current.Total?.toString()),
                    0
                ) ?? 0;
            console.log(totalprint);
            var totalEmbossing =
                this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce(
                    (sum, current) => sum + parseFloat(current.Total?.toString()),
                    0
                ) ?? 0;
            console.log(totalEmbossing);
            var totalVaccum =
                this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce(
                    (sum, current) => sum + parseFloat(current.Total?.toString()),
                    0
                ) ?? 0;
            console.log(totalVaccum);
            var totalTumbling =
                this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce(
                    (sum, current) => sum + parseFloat(current.Total?.toString()),
                    0
                ) ?? 0;
            console.log(totalTumbling);
            //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            //console.log(totalLacquer);
            var totalElement =
                this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce(
                    (sum, current) => sum + parseFloat(current.Total?.toString()),
                    0
                ) ?? 0;
            console.log(isNaN(totalElement) ?? 0);
            var totalRaw =
                this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce(
                    (sum, current) => sum + parseFloat(current.TotalCost?.toString()),
                    0
                ) ?? 0;
            console.log(totalRaw);
            this.Orderlist.GrandTotal =
                parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
                parseFloat(isNaN(totalprint) == true ? '0' : totalprint.toString()) +
                parseFloat(
                    isNaN(totalEmbossing) == true ? '0' : totalEmbossing.toString()
                ) +
                parseFloat(isNaN(totalVaccum) == true ? '0' : totalVaccum.toString()) +
                parseFloat(
                    isNaN(totalTumbling) == true ? '0' : totalTumbling.toString()
                ) +
                /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
                parseFloat(
                    isNaN(totalElement) == true ? '0' : totalElement.toString()
                ) +
                parseFloat(totalRaw.toString());
            console.log(this.Orderlist);
            this.loader.hide();
        });
    }
    handleCancel(): void {
        this.isVisible = false;
    }
    calclulateTotalSalePrice() {
        this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
    }
}