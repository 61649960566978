import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductionVaccumModel } from 'src/PmsUIApp/Models/ProductionModel';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';

@Component({
  selector: 'app-vaccum-list',
  templateUrl: './vaccum-list.component.html',
  styleUrls: ['./vaccum-list.component.css'],
})
export class VaccumListComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  VaccumList: ProductionVaccumModel[] = [];
  VaccumListOriginal: ProductionVaccumModel[] = [];
  VaccumListOriginal2: ProductionVaccumModel[] = [];
  NewVaccum: ProductionVaccumModel = new ProductionVaccumModel();
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Vaccum';
  VaccumNameError = 'Enter Vaccum name';
  VaccumCodeError = 'Enter Vaccum code';
  searchValue = ''
  exportoptions = {
    headers: ['VaccumName', 'Code', 'Description'],
  };
  fields: any;
  exportfields = [
    {
      VaccumName: '',
      Code: '',
      Description: '',
    },
  ];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService, private auth: AuthService, private router: Router
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Vaccum, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Vaccum, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Vaccum, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      VaccumName: [
        null,
        [Validators.required, Validators.minLength(3)],
        [this.VaccumNameAsyncValidator],
      ],
      Code: [null, [Validators.required], [this.VaccumCodeAsyncValidator]],
      Description: [null, [Validators.maxLength(200)]],
    });
    this.GetAllVaccum();
  }
  get f() {
    return this.validateForm.controls;
  }

  GetAllVaccum() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'vacuum/getallvacuums';
    this.http.get<ProductionVaccumModel[]>(url).subscribe(
      (res) => {
        this.VaccumList = res;
        this.VaccumListOriginal = res;
        this.VaccumListOriginal2 = res;
        this.exportfields = [];
        this.VaccumListOriginal.forEach((x) => {
          this.fields = {};
          this.fields.VaccumName = x.Name;
          this.fields.Code = x.Code;
          this.fields.Description = x.Description;
          this.exportfields.push(this.fields);
        });
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllVaccum(); }
      }
    );
  } reset(): void {
    this.searchValue = '';
    this.search();
  }
  search() {

    var res = this.VaccumListOriginal2;
    this.VaccumList = res.filter((item: ProductionVaccumModel) => {


      if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.VacuumMasterId == +this.searchValue
      ) {
        return true;
      } else if (
        item?.Code == this.searchValue
      ) {
        return true;
      }
      else if (
        item?.AddedDate?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else {
        let searchDate: any = new Date(this.searchValue);

        searchDate.setDate(searchDate.getDate() + 1);
        if (!isNaN(searchDate)) {
          searchDate = searchDate.toISOString().split('T')[0];
        }

        let splitDate = item.AddedDate.split('T')[0];

        return splitDate == searchDate ? true : false;
      }


    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  SaveVaccum() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;
      return;
    }

    const d = new Date();

    this.NewVaccum.Name = this.f['VaccumName'].value;
    this.NewVaccum.Code = this.f['Code'].value;
    this.NewVaccum.Description = this.f['Description'].value;
    this.NewVaccum.AddedDate = d.toLocaleTimeString();
    let url = this.ApiUrl + 'vacuum/addupdatevacuum';
    this.http.post<ProductionVaccumModel>(url, this.NewVaccum).subscribe({
      next: (res) => {
        this.alertService.success('Vaccum Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllVaccum();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
  Validate(model: ProductionVaccumModel) {
    var Isvalid: boolean = true;
    if (model.Name == '') {
      this.alertService.error('Enter Vaccum Name');
      Isvalid = false;
    } else if (model.Code == '') {
      this.alertService.error('Enter Code');
      Isvalid = false;
    }

    return Isvalid;
  }
  // Edit details 
  OpenEditPop(data: ProductionVaccumModel) {
    this.PopUpTitle = 'Modify Vaccum Details';
    this.validateForm.setValue({
      VaccumName: data.Name,
      Code: data.Code,
      Description: data.Description,
    });
    this.NewVaccum = new ProductionVaccumModel();
    this.NewVaccum.VacuumMasterId = data.VacuumMasterId;
    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = 'Add New Vaccum';
    this.validateForm.reset();
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveVaccum();
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  // validation for Name
  VaccumNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.VaccumList.filter(
          (x) => x.VacuumMasterId != this.NewVaccum.VacuumMasterId
        );
        var nre = res.filter(
          (x) => x.Name.toLowerCase() == control.value.toLowerCase()
        );
        this.VaccumNameError = '';

        if (control.value == '') {
          this.VaccumNameError = 'Enter Vaccum name';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.VaccumNameError = 'Vaccum name already exist';
          observer.next({ error: true, duplicated: true });
        } else {
          this.VaccumNameError = 'Enter Vaccum name';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  // validation for Code
  VaccumCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.VaccumList.filter(
          (x) => x.VacuumMasterId != this.NewVaccum.VacuumMasterId
        );
        var nre = res.filter(
          (x) => x.Code.toLowerCase() == control.value.toLowerCase()
        );
        this.VaccumCodeError = '';
        if (control.value == '') {
          this.VaccumCodeError = 'Enter Vaccum code';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.VaccumCodeError = 'Vaccum code already exist';
          observer.next({ error: true, duplicated: true });
        } else {
          this.VaccumCodeError = 'Enter Vaccum code';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  handleDelete(data: ProductionVaccumModel) {
    this.NewVaccum = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Vacuum?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteVacuum(this.NewVaccum),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  DeleteVacuum(data: ProductionVaccumModel) {
    let url = this.ApiUrl + 'vacuum/deletevacuum';

    this.http.post<ProductionVaccumModel>(url, data).subscribe({
      next: (res) => {
        this.alertService.success('Vacuum Deleted Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllVaccum();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
}
