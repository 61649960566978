<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Costing</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    


    <div nz-col [nzSpan]="24">

      <nz-form-item *ngIf="this.Type=='Production'">

        <nz-form-control [nzSpan]="12" nzErrorTip="Enter Element">
          <label><b>Select Workplan</b></label>
          <nz-select class="form-select" nzShowSearch name="WorkPlanNo" [(ngModel)]="SelectedWorkPlan" nzSize="default" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanChange()">
            <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">

        <nz-form-control [nzSpan]="12" nzErrorTip="Enter Element" *ngIf="this.Type=='Production'">
          <label><b>Select Order</b></label>
          <nz-select class="form-select" nzShowSearch name="SelectedWorkPlanOrder" [(ngModel)]="SelectedOrderNo" [ngModelOptions]="{standalone: true}" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanOrderChange()">
            <nz-option *ngFor="let s of SelectedWorkPlanOrder;" [nzValue]="s.SaleOrder.SaleOrderId" [nzLabel]="s.SaleOrder.SaleOrderNumber"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <div *ngIf="this.IsLoaded" nz-row [nzGutter]="24" style="margin: 0px 0px;">

        <div nz-col [nzSpan]="12" *ngFor="let i of SaleOrder.SaleOrderProduction.InspectionFormulationMixing">


          <div style="padding: 10px 0; margin-top: 10px; text-transform: uppercase; font-size: 13px; text-align: center; color: black; background: #415994;">

            <b>{{i.MixingName}}</b>
          </div>
          <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false" style="margin-left: 0; margin-right:0">

            <tbody>
              <tr>

                <td width="40%" style="text-align:left; border: 1px solid rgb(104, 102, 102);"><b>Item Name</b></td>
                <td width="15%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>QTY</b></td>
                <td width="15%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>SC QTY</b></td>
                <td width="15%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>Price</b></td>
                <td width="15%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>Cost</b></td>
              </tr>
              <tr *ngFor="let data of i.MixingRawMaterial;let i=index">

                <td width="40%" style="border: 1px solid rgb(104, 102, 102);text-align: left;">{{ data.ProductName }}</td>
                <td style="text-align:center; border:1px solid rgb(104, 102, 102);">{{ data.Quantity }}</td>
                <td style="text-align:center; border:1px solid rgb(104, 102, 102);">{{ data.Scquantity }}</td>
                <td style="text-align:center; border:1px solid rgb(104, 102, 102);"><input type="text" name="Price" [(ngModel)]="data.Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control "/></td>
                <td style="text-align:center; border:1px solid rgb(104, 102, 102);">{{ data.Quantity * data.Price }}</td>

                <!-- <td style="text-align:center; border:1px solid rgb(104, 102, 102);"><input type="text" name="Price" [(ngModel)]="data.Cost" nz-input [disabled]="true" style="background: #fff;color: #000;" nzSize="default" class="form-control" /></td> -->
              </tr>

            </tbody>
            <tfoot style="background: #d9d9d9;">
              <tr>

                <td width="40%" style="text-align:left; border: 1px solid rgb(104, 102, 102);"><b> Total </b></td>
                <td style="text-align:center; border: 1px solid rgb(104, 102, 102);">{{GetQuantityTotal(i.MixingRawMaterial)}}</td>
                <td style="text-align:center; border: 1px solid rgb(104, 102, 102);">{{GetScQuantityTotal(i.MixingRawMaterial)}}</td>
                <td style="text-align:center; border: 1px solid rgb(104, 102, 102);">{{GetPriceTotal(i.MixingRawMaterial)}}</td>
                <td style="text-align:center; border: 1px solid rgb(104, 102, 102);">{{GetCostTotal(i.MixingRawMaterial)}}</td>
              </tr>
            </tfoot>
          </nz-table>

        </div>



      </div>
      <nz-divider nzText="Costing Details"></nz-divider>
      <div *ngIf="this.IsLoaded" nz-row [nzGutter]="24" style="margin: 0px 0px;">



        <div nz-col [nzSpan]="24">



          <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false" style="margin-left: 0; margin-right: 0; width: 80%;">
            <thead>
              <tr>

                <td width="30%" style="text-align:left; border: 1px solid rgb(104, 102, 102);"></td>
                <td width="20%" style="text-align:center; border: 1px solid rgb(104, 102, 102);" colspan="2"><b> Per KG Cost </b></td>

                <td width="10%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>GSM</b></td>
                <td width="20%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b></b></td>
                <td width="20%" style="text-align:center; border: 1px solid rgb(104, 102, 102);"><b>COST PER LM</b></td>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let i of SaleOrder.SaleOrderProduction.InspectionFormulationMixing">

                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);">{{ i.MixingName }}</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102);">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="i.CostPerKg"/>
                </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102);">{{ i.WeightGsm }}</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102);">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="i.CostGsm"/>
                </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); ">{{ i.WeightGsm }}</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102);">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="i.CostPerLm"/>
                </td>
              </tr>
              <tr>

                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px;"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9;"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9;"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9;">PASTE COST \ LM</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9;">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control" [(ngModel)]="SaleOrder.SaleOrderCosting.PasteCostLm" />
                </td>
              </tr>
              <tr>
                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; "><b>GSM</b></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; "><b>THICK</b></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; "><b></b></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">GRAIN COST \ LM</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control" [(ngModel)]="SaleOrder.SaleOrderCosting.GrainCostLm" />
                </td>
              </tr>
              <tr>
                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"><b> FABRIC </b></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">
                  <input type="text" name="Price" [(ngModel)]="SaleOrder.SaleOrderCosting.FabricCost" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control" />
                </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">FABRIC COST \ LM</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="SaleOrder.SaleOrderCosting.FabricCostLm" />
                </td>
              </tr>
              <tr>
                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"><b>COATING</b></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">
                  <input type="text" name="Price" [(ngModel)]="SaleOrder.SaleOrderCosting.CoatingCost" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control" />
                </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">FINISHING COST \ LM</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="SaleOrder.SaleOrderCosting.FinishingCostLm" />
                </td>
              </tr>
              <tr>
                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"><b>TOTAL</b></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px;">{{GetGSMTotal()}}</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">R\M COST \ LM</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control" [(ngModel)]="SaleOrder.SaleOrderCosting.RmcostLm" />
                </td>
              </tr>
              <tr>
                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; "></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">REJECTION 6%</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; ">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="SaleOrder.SaleOrderCosting.Rejection" />
                </td>
              </tr>
              <tr>
                <td width="20%" style="text-align: left; border: 1px solid rgb(104, 102, 102);"></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; "></td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">  </td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">PRODUCTION COST LM</td>
                <td style="text-align: center; padding-left: 15px; border: 1px solid rgb(104, 102, 102); height: 40px; background: #d9d9d9; ">
                  <input type="text" name="Price" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control " [(ngModel)]="SaleOrder.SaleOrderCosting.ProductionCostLm" />
                </td>
              </tr>
            </tbody>

          </nz-table>

        </div>

        <div class="text-center" style="width: 95%;">
          <button nz-button nzType="primary" (click)="SaveCosting()">Save</button>
        </div>

      </div>
    </div>



      

  </div>
</div>
