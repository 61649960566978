<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Paste Consumption Report</nz-page-header-title>
            <nz-page-header-subtitle>View Your Production Paste Consumption Detailed Report
                Here</nz-page-header-subtitle>
        </nz-page-header>
        <nz-divider></nz-divider>
        <nz-collapse>
            <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Product Type</label>
                                    <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Customer</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default"
                                        nzAllowClear [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                            [nzLabel]="s.CustomerName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Formulation/Product Code</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode"
                                        nzSize="default" nzAllowClear [(ngModel)]="request.SaleFormulationCodeId"
                                        nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.FormulationCodeList;"
                                            [nzValue]="s.SaleFormulationCodeId"
                                            [nzLabel]="s.SaleFormulationCode"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Color</label>
                                    <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                                        nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                            [nzLabel]="s.ColorName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Grain Name</label>
                                    <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default"
                                        nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                                            [nzLabel]="s.GrainName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Thickness</label>
                                    <nz-select class="form-select mb-2" nzSize="default"
                                        [(ngModel)]="request.ThicknessId" nzAllowClear nzShowSearch
                                        nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                                            [nzLabel]="s.ThicknessNumber"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                                    <label>Article Name</label>
                                    <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30"
                                        [(ngModel)]="request.ArticleName" (keydown)="space($event)" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label>Sale Order No.</label>
                                    <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full No."
                                        maxlength="30" [(ngModel)]="request.SaleOrderNumber"
                                        (keydown)="space($event)" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                        <div style="display: flex">
                            <button nz-button nzSize="small" nzType="primary" (click)="PasteConsumptionReport()">
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Date Filter</label>
                                <nz-select [(ngModel)]="selecteddateFilter"
                                    (ngModelChange)="getDateRange(selecteddateFilter)" nzPlaceHolder="Choose"
                                    [ngModelOptions]="{standalone: true}">
                                    <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5" *ngIf="this.enableCustomDateRange">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label style="display:block">From Date</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.WorkPlanFromDate"
                                    nzPlaceHolder="Date" nzShowTime></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5" *ngIf="this.enableCustomDateRange">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>To Date</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.WorkPlanToDate"
                                    nzPlaceHolder="Date" nzShowTime></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4" *ngIf="enableWorkShiftDropDown">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Work Shift</label>
                                <nz-select [(ngModel)]="request.WorkShift" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let data of WorkShiftList" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Production Line No.</label>
                                <nz-select [(ngModel)]="request.ProductionLineNo" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let data of ProductionLineList" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Product Type</label>
                                <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px" (click)="PasteConsumptionReport()">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 8px;float:right">
            <nz-button-group style="margin-left:5px">
                <button nz-button nzSize="small" (click)="setAutoRefreshAction()">{{this.autoRefreshBtnText}}</button>
                <button nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight" nzSize="small">
                    <span nz-icon nzType="ellipsis"></span>
                </button>
            </nz-button-group>
            <nz-dropdown-menu #menu1="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item (click)="setAutoRefreshTime(30)">Refresh Every 30 Seconds</li>
                    <li nz-menu-item (click)="setAutoRefreshTime(60)">Refresh Every 60 Seconds</li>
                    <li nz-menu-item (click)="setAutoRefreshTime(300)">Refresh Every 5 Minute</li>
                    <li nz-menu-item (click)="setAutoRefreshTime(900)">Refresh Every 15 Minute</li>
                </ul>
            </nz-dropdown-menu>
            <!-- <button nz-button nzSize="small" (click)="export()" style="margin-left:5px"><i nz-icon nzType="export"
                    nzTheme="outline" style="top: 2px;right: 49px;position: absolute;"></i> Export</button> -->
            <!-- <button nz-button *ngIf="this.isEmailReportEnabled" [nzLoading]="isEmailButtonLoading" nzSize="small"
                (click)="sendEmail()" style="margin-left:5px"><i nz-icon nzType="mail" nzTheme="outline"
                    style="top: 2px;right: 79px;position: absolute;"></i> Send Email</button> -->

        </div>
        <div style="padding: 8px;float:left">
            <b>** For the orders where Paste Consumption Details were not provided, it will show as <b
                    style="color: red;">red</b>.</b>
        </div>
        <nz-divider></nz-divider>

        <div style="max-width:100%; overflow:scroll;border-color: black;">
            <nz-table id="print-section" nzSize="small" nzShowPagination="false" [nzScroll]="{ x: '2500px', y:'515px' }"
                style="width: 100%;page-break-inside: auto;" [nzData]="this.PasteConsumptionList"
                [nzLoading]="isTableLoading" nzBordered>
                <thead>

                    <tr
                        style="text-align: left;font-size:x-small;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                        <th nzWidth="60px" nzLeft>S.No.</th>
                        <th nzWidth="95px" rowspan="2">Production Date</th>
                        <th nzWidth="80px" rowspan="2" nzLeft>Order No.</th>
                        <!-- <th nzWidth="140px">WorkPlan No.</th> -->
                        <th nzWidth="120px">Item Name</th>
                        <th nzWidth="90px">P.Code</th>
                        <th nzWidth="90px">Grain Name</th>
                        <th nzWidth="120px">Colors</th>
                        <th nzWidth="80px">Width</th>
                        <th nzWidth="200px">Fabric</th>
                        <th nzWidth="70px">Thick</th>
                        <th nzWidth="150px">Finish</th>
                        <th nzWidth="90px">Order QTY (MTR)</th>
                        <th nzWidth="130px">Extra PRD QTY (MTR) / Percentage</th>
                        <th nzWidth="90px">Pre Skin (STD. GSM)</th>
                        <th nzWidth="90px">Skin (STD. GSM)</th>
                        <th nzWidth="90px">Foam (STD. GSM)</th>
                        <th nzWidth="90px">Adhesive (STD. GSM)</th>
                        <th nzWidth="90px">Fabric (STD. GSM)</th>
                        <th nzWidth="90px">Final (STD. GSM)</th>
                        <th nzWidth="100px">Party name</th>
                        <th nzWidth="70px">PRD QTY (MTR)</th>
                        <th nzWidth="90px">Pre Skin WT.</th>
                        <th nzWidth="90px">Skin WT.</th>
                        <th nzWidth="90px">Foam WT.</th>
                        <th nzWidth="90px">Adhesive WT.</th>
                        <th nzWidth="100px">Pre Skin (After PRD SC GSM)</th>
                        <th nzWidth="90px">Skin (After PRD SC GSM)</th>
                        <th nzWidth="90px">Foam (After PRD SC GSM)</th>
                        <th nzWidth="100px">Adhesive (After PRD SC GSM)</th>
                        <th nzWidth="90px">Final (After PRD SC GSM)</th>
                        <th nzWidth="100px">Pre Skin (Costing GSM)</th>
                        <th nzWidth="90px">Skin (Costing GSM)</th>
                        <th nzWidth="90px">Foam (Costing GSM)</th>
                        <th nzWidth="100px">Adhesive (Costing GSM)</th>
                        <th nzWidth="90px">Final (Costing GSM)</th>
                        <th nzWidth="90px">Extra GSM Pre Skin </th>
                        <th nzWidth="90px">Extra GSM Skin</th>
                        <th nzWidth="90px">Extra GSM Foam</th>
                        <th nzWidth="90px">Extra GSM Adhesive</th>
                        <th nzWidth="90px">Total Extra GSM</th>
                        <th nzWidth="250px">Production Remarks</th>

                    </tr>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of this.PasteConsumptionList; let i = index"
                        style="text-align: left;font-size: x-small;border-color: black;page-break-inside:avoid; page-break-after:auto"
                        [ngClass]="{'selected-row': i === selectedRowIndex}" (click)="onRowClick(i)">
                        <td style="border-color: black;" nzLeft>{{ i + 1 }}</td>
                        <td style="border-color: black;">{{ data.ProductionCompletedDate | DatetimeConverter }}
                        </td>
                        <td style="border-color: black;"
                            [ngStyle]="{'background-color': checkRemainingOrActualWeightProvided(data)}" nzLeft>{{
                            data.SaleOrderNo }}</td>
                        <!-- <td style="border-color: black;">{{ data.WorkPlanNo }}</td> -->
                        <td style="border-color: black;" nzBreakWord>{{ data.ItemName }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.PCode }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Grain }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Colours}}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Width }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Fabric }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Thick }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Finish }}</td>
                        <td style="border-color: black;">{{ data.OrderQty }}</td>
                        <td style="border-color: black;">{{GetTotalProductionQty(data)}} / {{ data.ExtraProduction }}%
                        </td>
                        <td style="border-color: black;">{{ data.StdPreSkinGsm }}</td>
                        <td style="border-color: black;">{{ data.StdSkinGsm }}</td>
                        <td style="border-color: black;">{{ data.StdFoamGsm }}</td>
                        <td style="border-color: black;">{{ data.StdAdhesiveGsm }}</td>
                        <td style="border-color: black;">{{ data.FabricGsm }}</td>
                        <td style="border-color: black;">{{ data.StdPreSkinGsm + data.StdSkinGsm +
                            data.StdFoamGsm +
                            data.StdAdhesiveGsm +
                            data.FabricGsm }}</td>
                        <td style="border-color: black;"> {{ data.PartyName }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.MFGQty }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.PreSkinActualPasteQty }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.SkinActualPasteQty }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.FoamActualPasteQty }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.AdhesiveActualPasteQty }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.PreSkinScGsm }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.SkinScGsm }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.FoamScGsm }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.AdhesiveScGsm }}</td>
                        <td style="border-color: black;">{{ GetFinalAfterPRDScGSM(data) }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.PreSkinGsm }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.SkinGsm }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.FoamGsm }}</td>
                        <td style="border-color: black;">{{ data.SaleOrderCompleteProduction.AdhesiveGsm }}</td>
                        <td style="border-color: black;">{{ GetFinalAfterPRDGSM(data) }}</td>
                        <td style="border-color: black;">{{ data.ExtraPreSkinGsm }}</td>
                        <td style="border-color: black;">{{ data.ExtraSkinGsm }}</td>
                        <td style="border-color: black;">{{ data.ExtraFoamGsm }}</td>
                        <td style="border-color: black;">{{ data.ExtraAdhesiveGsm }}</td>
                        <td style="border-color: black;">{{ GetFinalExtraGSM(data) }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Remarks }}</td>

                    </tr>
                </tbody>
                <tfoot>
                    <tr
                        style="text-align: left;font-size: x-small;border-color: black;page-break-inside:avoid; page-break-after:auto">
                        <td nzLeft></td>
                        <td></td>
                        <td nzLeft></td>
                        <td colspan="7" style="border-color: black;font-weight: bold;"></td>
                        <td style="border-color: black;font-weight: bold;">Totals</td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('OrderQuantity')}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('ExtraProductionQTY').toFixed(2)}} /
                            {{CalculateTotal('ExtraProductionPerc')}} %</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('StdPreSkinGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('StdSkinGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('StdFoamGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('StdAdhesiveGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('FabricGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('StdFinalGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;"></td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('MFGQty').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('StdPreSkinWeight').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('StdSkinWeight').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('StdFoamWeight').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('StdAdhesiveWeight').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionPreSkinScGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionSkinScGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionFoamScGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionAdhesiveScGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionFinalScGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionPreSkinGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionSkinGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionFoamGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionAdhesiveGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('AfterProductionFinalGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('ExtraPreSkinGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('ExtraSkinGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">{{CalculateTotal('ExtraFoamGsm').toFixed(2)}}
                        </td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('ExtraAdhesiveGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;">
                            {{CalculateTotal('TotalExtraGsm').toFixed(2)}}</td>
                        <td style="border-color: black;font-weight: bold;"></td>
                    </tr>
                </tfoot>
            </nz-table>
        </div>

    </div>
</div>