import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';



@Pipe({
  name: 'DatetimeConverter'
})

export class DatetimeConverter implements PipeTransform {
  constructor() {
  }
  transform(value: string) {
    if (value) {
      var d = new Date(value);
      var dd = new Date(new Date(d).setMinutes(d.getMinutes() + 330))
      return new DatePipe('en-US').transform(dd, 'dd-MMM-yyyy, h:mm a');
    }
    else {
      return '';
    }
  }
}



@Pipe({
  name: 'callback',
  pure: false
})
export class CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}
