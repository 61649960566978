<div nz-col [nzSpan]="10">
  <nz-form-item>
    <nz-form-control
      class="select-container"
      [nzSpan]="24"
      tabindex="1"
      (blur)="closeProcessList()"
    >
      <label>Select Post process</label>
      <div class="select-label" (click)="handleShowProcessList()">
        <span *ngFor="let s of SelectedPostProcess"
          >{{ s.PostProcessName }}
          <span
            (click)="removeProcess(s.PostProcessName)"
            style="cursor: pointer"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
          ></span
        ></span>
      </div>
      <div
        class="select-container-list"
        [ngClass]="showProcess ? 'active' : ''"
      >
        <ul>
          <li *ngFor="let s of this.PostProcess" (click)="handleProcess.emit(s)">
            {{ s }}
          </li>
        </ul>
      </div>
    </nz-form-control>
  </nz-form-item>
</div>
<div nz-row [nzGutter]="24" style="margin: 0px 0px">
  <ng-container *ngFor="let lacPro of SelectedPostProcess; let inx = index">
    <ng-container *ngIf="lacPro.PostProcessName === 'Print'">
      <div
        nz-col
        [nzSpan]="8"
        style="
          background-color: #f0f2f5;
          border-radius: 4px;
          border: 3px solid #fff;
        "
      >
        <nz-divider nzText="Print"></nz-divider>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="6">
              <label>Select Order</label>
            </nz-form-control>
            <nz-form-control [nzSpan]="6">
              <nz-select
                class="form-select"
                nzSize="default"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].Rank"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let val of getSequenceList(); let idx = index"
                  [nzValue]="val"
                  [nzLabel]="val"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
              <label>Select Print</label>
              <nz-select
                #microwaveRef
                class="form-select"
                nzShowSearch
                nzSize="default"
                name="PrintMasterId"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].selectedProcess"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let s of this.PrintMasterList"
                  [nzValue]="s"
                  [nzLabel]="s.Name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-divider></nz-divider>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lacPro.PostProcessName === 'Embossing'">
      <div
        nz-col
        [nzSpan]="8"
        style="background: #f0f2f5; border-radius: 4px; border: 3px solid #fff"
      >
        <nz-divider nzText="Embossing"></nz-divider>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="6">
              <label>Select Order</label>
            </nz-form-control>
            <nz-form-control [nzSpan]="6">
              <nz-select
                #microwaveRef
                class="form-select"
                nzSize="default"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].Rank"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let val of getSequenceList(); let idx = index"
                  [nzValue]="val"
                  [nzLabel]="val"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
              <label>Select Embossing</label>
              <nz-form-control [nzSpan]="22" nzErrorTip="Enter Amount">
                <nz-select
                  #microwaveRef
                  class="form-select"
                  nzShowSearch
                  nzSize="default"
                  name="EmbossingMasterId"
                  nzPlaceHolder="Select"
                  [(ngModel)]="SelectedPostProcess[inx].selectedProcess"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <nz-option
                    *ngFor="let s of this.EmbossingList"
                    [nzValue]="s"
                    [nzLabel]="s.Name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-control>
          </nz-form-item>
          <nz-divider></nz-divider>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lacPro.PostProcessName === 'Lacquar'">
      <div
        nz-col
        [nzSpan]="8"
        style="
          background-color: #f0f2f5;
          border-radius: 4px;
          border: 3px solid #fff;
        "
      >
        <nz-divider nzText="Lacquar"></nz-divider>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="6">
              <label>Select Order</label>
            </nz-form-control>
            <nz-form-control [nzSpan]="6">
              <nz-select
                #microwaveRef
                class="form-select"
                nzSize="default"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].Rank"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let val of getSequenceList(); let idx = index"
                  [nzValue]="val"
                  [nzLabel]="val"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
              <label>Select Lacquar</label>
              <nz-select
                class="form-select mb-2"
                nzSize="default"
                [(ngModel)]="SelectedPostProcess[inx].selectedProcess"
                nzAllowClear
                nzShowSearch
                nzPlaceHolder="Choose"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let s of this.LacquerList"
                  [nzValue]="s"
                  [nzLabel]="s.Name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-divider></nz-divider>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lacPro.PostProcessName === 'Vaccum'">
      <div
        nz-col
        [nzSpan]="8"
        style="
          background-color: #f0f2f5;
          border-radius: 4px;
          border: 3px solid #fff;
        "
      >
        <nz-divider nzText="Vaccum"></nz-divider>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="6">
              <label>Select Order</label>
            </nz-form-control>
            <nz-form-control [nzSpan]="6">
              <nz-select
                #microwaveRef
                class="form-select"
                nzSize="default"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].Rank"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let val of getSequenceList(); let idx = index"
                  [nzValue]="val"
                  [nzLabel]="val"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
              <label>Select Vaccum</label>
              <nz-select
                #microwaveRef
                class="form-select"
                nzShowSearch
                nzSize="default"
                name="VaccumMasterId"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].selectedProcess"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let s of this.VaccumList"
                  [nzValue]="s"
                  [nzLabel]="s.Name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-divider></nz-divider>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lacPro.PostProcessName === 'Tumbling'">
      <div
        nz-col
        [nzSpan]="8"
        style="
          background-color: #f0f2f5;
          border-radius: 4px;
          border: 3px solid #fff;
        "
      >
        <nz-divider nzText="Tumbling"></nz-divider>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="6">
              <label>Select Order</label>
            </nz-form-control>
            <nz-form-control [nzSpan]="6">
              <nz-select
                #microwaveRef
                class="form-select"
                nzSize="default"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].Rank"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let val of getSequenceList(); let idx = index"
                  [nzValue]="val"
                  [nzLabel]="val"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
              <label>Select Tumbling</label>
              <nz-select
                #microwaveRef
                class="form-select"
                nzShowSearch
                nzSize="default"
                name="TumblingMasterId"
                nzPlaceHolder="Select"
                [(ngModel)]="SelectedPostProcess[inx].selectedProcess"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let s of this.TumblingList"
                  [nzValue]="s"
                  [nzLabel]="s.Name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-divider></nz-divider>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
