import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { environment } from 'src/environments/environment';
import { SaleOrderModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import { EstimationCodeMixingModel } from 'src/PmsUIApp/Models/CostingModel';

@Component({
    selector: 'app-CostEstimationPrint',
    templateUrl: './costEstimationPrint.component.html',
    styleUrls: ['./costEstimationPrint.component.css']
})
export class CostEstimationPrintComponent implements OnInit {
    ApiUrl = environment.Api_Url;
    OrderDetails: SaleOrderModel;
    Production!: SaleOrderModel;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    typeList: any[] = [];
    PopUpTitle: string = "Add New Production";
    searchValue = '';
    visible = false;
    EstimationOrderId: number;
    private route$: Subscription = new Subscription;
    TotalEstimatedPrice: string = '';
    GrandTotalFabricPrice: number = 0;
    TotalFabricGSM: number = 0;
    TotalFabricQuantity: number = 0;
    TotalFabricPrice: number = 0;
    TotalQuantity: number = 0;
    TotalPrice: number = 0;
    PerLMConstant: number = 0;
    TotalCostPerLm: number = 0;
    PreSkinGsm: number = 0;
    AdhesiveGsm: number = 0;
    SkinGsm: number = 0;
    FoamGsm: number = 0;
    FabricGsm: number = 0;
    TotalGsm: number = 0;
    Coating: number = 0;
    MixingSelectedList: EstimationCodeMixingModel[] = [];
    permission = {
        View: false,
        SaleOrderView: false
    }
    Company = {
        CompanyName: '',
        HeadOfficeAddress: '',
        FactoryAddress: ''
    };
    totalCalculatedQuantities: { [mixingName: string]: number } = {};
    totalCalculatedSCQuantities: { [mixingName: string]: number } = {};
    TotalProductionQty: number = 0;
    FabricQTYFromKGToMTR: number;
    GSMType: string = '';
    GSMTypePrintText: string = '';
    CEPrint: any;
    constructor(public http: HttpClient, private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route$ = this.route.params.subscribe((params: Params) => {
            this.EstimationOrderId = params["id"];
        })
        this.GSMTypePrintText = 'FINAL GSM';
        this.Company.CompanyName = CompanyInfo.CompanyName;
        this.Company.HeadOfficeAddress = CompanyInfo.HeadOfficAddress;
        this.Company.FactoryAddress = CompanyInfo.FactoryAddress;
        this.PrintCostEstimationData(this.EstimationOrderId);
        setTimeout(function () {
            window.print();
            window.onafterprint = close;
            function close() {
                window.close();
            }
        }, 1)
    }
    PrintCostEstimationData(id: number) {
        this.isTableLoading = true;
        let url =
            this.ApiUrl + 'estimationorder/getestimationorderbyid/' + id;
        this.http.get<any>(url).subscribe((data) => {
            this.CEPrint = data;
            if (data.EstimationMixingTable.length > 0) {
                this.AdhesiveGsm = data.EstimationMixingTable[0].AdhesiveGsm || 0;
                this.PreSkinGsm = data.EstimationMixingTable[0].PreSkinGsm || 0;
                this.SkinGsm = data.EstimationMixingTable[0].SkinGsm || 0;
                this.FoamGsm = data.EstimationMixingTable[0].FoamGsm || 0;
                this.FabricGsm = data.EstimationMixingTable[0].FabricGsm || 0;
            } else {
                this.AdhesiveGsm = 0;
                this.PreSkinGsm = 0;
                this.SkinGsm = 0;
                this.FoamGsm = 0;
                this.FabricGsm = 0;
            }
            this.MixingSelectedList = data.EstimationMixingTable.map((mixing: any) => {
                const newMixing: EstimationCodeMixingModel = {
                    MixingId: mixing.MixingId,
                    MixingName: mixing.MixingName,
                    AdhesiveGsm: this.AdhesiveGsm,
                    PreSkinGsm: this.PreSkinGsm,
                    SkinGsm: this.SkinGsm,
                    FoamGsm: this.FoamGsm,
                    FabricGsm: this.FabricGsm,
                    MixingRawMaterial: mixing.EstimationCodeMixingRawMaterialTable
                };
                return newMixing;
            });
            this.Coating = this.PreSkinGsm + this.SkinGsm + this.AdhesiveGsm;
            this.calculateGrandTotals();
            this.calculateGSMGrandTotal();
            this.calculateTotalEstimatedPrice();
            this.isVisible = true;
        });
    }
    calculateGSMGrandTotal() {
        if (!isNaN(this.PreSkinGsm)) {
            this.TotalGsm += this.PreSkinGsm;
        }
        if (!isNaN(this.SkinGsm)) {
            this.TotalGsm += this.SkinGsm;
        }
        if (!isNaN(this.FoamGsm)) {
            this.TotalGsm += this.FoamGsm;
        }
        if (!isNaN(this.AdhesiveGsm)) {
            this.TotalGsm += this.AdhesiveGsm;
        }
        if (!isNaN(this.TotalFabricGSM)) {
            this.TotalGsm += this.TotalFabricGSM;
        }
    }
    calculateGrandTotals() {
        this.TotalFabricGSM = this.CEPrint.EstimationFabricProductDetail.reduce((total, fabric) => {
            return total + fabric.FabricGsm;
        }, 0);
        this.TotalFabricQuantity = this.CEPrint.EstimationFabricProductDetail.reduce((total, fabric) => {
            return total + fabric.FabricEstimationQuantity;
        }, 0);
        this.TotalFabricPrice = this.CEPrint.EstimationFabricProductDetail.reduce((total, fabric) => {
            return total + fabric.FabricProductCostPerLm;
        }, 0);
        this.GrandTotalFabricPrice = this.CEPrint.EstimationFabricProductDetail.reduce((total, fabric) => {
            return total + (fabric.FabricEstimationQuantity * fabric.FabricProductCostPerLm);
        }, 0);
        this.FabricGsm = this.TotalFabricGSM;
        this.CEPrint.FabricProductCostPerLm = this.GrandTotalFabricPrice;
    }
    calculateTotalProfitLoss() {
        return (parseFloat(this.TotalEstimatedPrice) - this.TotalCostPerLm * this.CEPrint.OrderQuantity).toFixed(2)
    }
    calculateCostPerLmForItem(item: any): number {
        let totalCost = item.MixingRawMaterial.reduce((sum, current) => sum + (current.Price) == 0 ? 0 : current.Price * parseFloat(current.Quantity?.toFixed(2)), 0);
        let totalQuantity = item.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0);
        let GSM = item.MixingName === 'PRE SKIN' ? this.PreSkinGsm : (item.MixingName === 'SKIN' ? this.SkinGsm :
            (item.MixingName === 'ADHESIVE' ? this.AdhesiveGsm : 0));
        let clm = ((totalCost / totalQuantity / 1000) * this.PerLMConstant) * GSM;
        this.TotalQuantity = this.TotalQuantity + totalQuantity
        this.TotalPrice = this.TotalPrice + totalCost
        this.CEPrint.TotalCostPerLm = parseFloat((this.TotalCostPerLm + clm).toFixed(2));
        return isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
    }
    calculateTotalPriceForItem(item: any): String {
        let totalCost = item.MixingRawMaterial.reduce((sum, current) => sum + (current.Price) == 0 ? 0 : current.Price * parseFloat(current.Quantity?.toFixed(2)), 0);
        let totalQuantity = item.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0);
        return (totalCost / totalQuantity).toFixed(2);
    }
    calculateTotalEstimatedPrice() {
        this.TotalEstimatedPrice = parseFloat(((isNaN(this.CEPrint.OrderQuantity) ? 0 : this.CEPrint.OrderQuantity) * (isNaN(this.CEPrint.EstimationPrice) ? 0 : this.CEPrint.EstimationPrice)).toString()).toFixed(3)
    }

}
