import { SaleOrderProductionModel } from "./SalesOrderModel";

export class CostingModel {
  SerialNo: number;
  SaleOrderId: number;
  SaleOrderNumber: string;
  CustomerName: string;
  SaleOrderCode: string;
  FabricCost: number;
  CoatingCost: number;
  PasteCostLm: number;
  GrainCostLm: number;
  FabricCostLm: number;
  FinishingCostLm: number;
  RmcostLm: number;
  Rejection: number;
  RejectionCostLm: number;
  ProductionCostLm: number;
  PerLmconstant: number;
  OverheadCost: number;
  PackagingCostPerUnit: number;
  MiscellaneousCostPerUnit: number;
  TotalCostLm: number;
  FinalTotalCost: number;
  ProfitLossLm: number;
  TotalProfitLoss: number;
  CostingStatus: string;
  SaleOrderMaterialType: string;
  PrintCostPerUnit: number;
  EmbossingCostPerUnit: number;
  VacuumCostPerUnit: number;
  TumblingCostPerUnit: number;
  LacquerCostPerUnit: number;
  InlineScraping: number;
  AddedBy: string;
  AddedDate: string;
  ProductionCompletedDate: string;
  SaleOrderProduction!: SaleOrderProductionModel;
}

export class CostingPrintModel {
  SerialNo: number;
  SaleOrderNumber: string;
  CustomerName: string;
  SaleOrderCode: string;
  SaleOrderMaterialType: string;
  Article: string;
  OrderQTY: number;
  MFGQTY: number;
  Rejection: number;
  RejectionCostLm: number;
  FabricCost: number;
  ProductionCostLm: number;
  PerLmconstant: number;
  PasteCostLm: number;
  GrainCostLm: number;
  FabricCostLm: number;
  PrintCostPerUnit: number;
  EmbossingCostPerUnit: number;
  VacuumCostPerUnit: number;
  LacquerCostPerUnit: number;
  InlineScraping: number;
  TumblingCostPerUnit: number;
  FinishingCostLm: number;
  RmcostLm: number;
  OverheadCost: number;
  TotalCostLm: number;
  FinalTotalCost: number;
  ProfitLossLm: number;
  TotalProfitLoss: number;
  CostingStatus: string;
  AddedBy: string;
  AddedDate: string;
}

export class CostEstimationProductionModel {
  EstimationFormulationCodeId: number = 0;
  SaleOrderType: string = '';
  ProductCategoryId: number = 0;
  ManufacturingProductName: string = '';
  OrderQuantity!: number;
  LMConstant: number = 1.5;
  ExtraProduction!: number;
  ManufacturingQuantity!: number;
  ColorId: number = 0;
  GrainId: number = 0;
  GrainPrice: number = 0;
  Thick: number = 0;
  Width: number = 0;
  EstimationPrice: number = 0;
  ProductionCostLm: number = 0;
  FabricProductId: number = 0
  FabricProductUnit: string = '';
  FabricProductName: string = '';
  TotalFinishPrice: number = 0;
  OverheadCost: number = 0;
  Rejection: number = 0;
  TotalCostPerLm: number = 0;
  LineSpeed: number = 0;
  TotalProfitLoss: number = 0;
  CustomerId: number = 0;
  Remarks: string;
  Disabled: string = '';
  AddedBy: string = '';
  DisabledBy: string = '';
  AddedDate: string = '';
  DisabledDate: string = '';
  FinishingData: EstimationFinishingModel[] = [];
  MixingData: EstimationCodeMixingModel[] = [];
  FabricsData: EstimationCodeFabricsModel[] = [];
}
export class EstimationCodeFabricsModel {
  FabricProductId: string;
  FabricProductName: string;
  FabricGsm: number;
  FabricEstimationQuantity: number;
  FabricProductCostPerLm: number;
}
export class EstimationFinishingModel {
  EstimationMaterialType: string = '';
  Finishing: string = '';
  FinishingType: string = '';
  Price: number = 0;
}

export class EstimationCodeMixingModel {
  MixingId: number = 0;
  MixingName: string = '';
  PreSkinGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  AdhesiveGsm: number = 0;
  FabricGsm: number = 0;
  MixingRawMaterial: EstimationCodeMixingRawMaterialModel[] = [];
}
export class EstimationCodeMixingRawMaterialModel {
  EstimationCodeRawMaterialMixingId: number = 0;
  EstimationCodeMixingId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  Quantity: number = 0;
  Unit: string = '';
  Price: number = 0;
}