import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, ColorModel, GrainModel, ElementModel } from '../../Models/MasterModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../Models/ProductModel';
import { OrderModel, OrderProductModel, SupplierModel } from '../../Models/SupplierModel';
import { TransportModel } from '../../Models/TransportModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { ProductionModel, ProductionElementModel, ProductionOrderModel, ProductionRawMaterialModel, ProductionProcessModel, ProductionMixingModel, ProductionProcessRawMaterial, ProductionMixingRawMaterial, ProductionVaccumModel, ProductionTumblingModel, ProductionLacquerModel, ProductionEmbossingModel, ProductionPrintMasterModel, LacquerRawMaterial } from '../../Models/ProductionModel';
import { UserInfo } from '../../Authentication/UserInfo';
import { Subscription } from 'rxjs';
import { ProcessModel, ProcessRawMaterialModel } from '../../Models/ProcessModel';
import { MixingModel, MixingRawMaterialModel } from '../../Models/MixingModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-newmixing',
  templateUrl: './newmixing.component.html',
  styleUrls: ['./newmixing.component.css']
})
export class NewmixingComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  OrderList: OrderModel[] = [];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  ColorList: ColorModel[] = [];
  TransportList: TransportModel[] = [];
  DeliveryTermList: DeliveryTermModel[] = [];
  PaymentTermList: PaymentTermModel[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  GrainList: GrainModel[] = [];
  ElementList: ElementModel[] = [];
  VaccumList: ProductionVaccumModel[] = [];
  TumblingList: ProductionTumblingModel[] = [];
  LacquerList: ProductionLacquerModel[] = [];
  EmbossingList: ProductionEmbossingModel[] = [];
  PrintMasterList: ProductionPrintMasterModel[] = [];
  NewPO: ProductionModel = new ProductionModel;
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedCategory: any;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: 'NA',
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  }
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  listOfSelectedproducts = [];
  listOfSelectedElement = [];
  listOfSelectedOrder = [];
  listOfSelectedprocess: number[] = [];
  listOfSelectedmixing: number[] = [];
  listOfSelectedOrderProdects: OrderProductModel[] = [];
  SelectedLacquer = ProductionLacquerModel
  SelectedOrder = 0;
  SelectedProcess = 0;
  productionRawMaterial: ProductionRawMaterialModel[] = [];
  productionElement: ProductionElementModel[] = [];
  LacquerRawMaterial: LacquerRawMaterial[] = [];
  productionOrder: ProductionOrderModel[] = [];
  ProductionId = 0;
  private route$: Subscription = new Subscription;

  processRawMaterial: ProcessRawMaterialModel[] = [];
  ProcessList: ProcessModel[] = [];
  ProcessSelectedList: ProductionProcessModel[] = [];
  MixingList: MixingModel[] = [];
  mixingRawMaterial: MixingRawMaterialModel[] = [];
  MixingSelectedList: ProductionMixingModel[] = [];
  Wastagetype = '%';
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private auth: AuthService) { }
  handleOk(): void {

    this.isLoading = true;

    this.Save();
  }
  Save() {
    //if (this.validateForm.invalid) {
    //  Object.values(this.validateForm.controls).forEach(control => {
    //    if (control.invalid) {
    //      control.markAsDirty();
    //      control.updateValueAndValidity({ onlySelf: true });
    //    }
    //  });
    //  this.isLoading = false;

    //  return;
    //}
    if (this.NewPO.ManufacturingProductName == '') {
      this.isLoading = false;
      this.alertService.error("Manufacturing Product Name Required");
      return;
    }
    if (this.productionOrder.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add Orders");
      return;
    }
    if (this.productionRawMaterial.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add RawMaterial");
      return;
    }
    if (this.productionElement.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add Elements");
      return;
    }

    if (this.productionRawMaterial.filter(x => x.Quantity <= 0).length > 0) {
      this.isLoading = false;
      this.alertService.error("Enter RawMaterial Quantity");
      return;
    }
    if (this.ProcessSelectedList.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add Process");
      return;
    }
    if ((this.ProcessSelectedList.length > 0) && (this.NewPO.ProcessFormulationCode == '')) {
      this.isLoading = false;
      this.alertService.error("Please add Process Formulation Code");
      return;
    }
    if (this.MixingSelectedList.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add Mixing");
      return;
    }
    if ((this.MixingSelectedList.length > 0) && (this.NewPO.MixingFormulationCode == '')) {
      this.isLoading = false;
      this.alertService.error("Please add  Mixing Formulation Code");
      return;
    }
    this.NewPO.ProductionOrder = this.productionOrder;
    this.NewPO.ProductionRawMaterial = this.productionRawMaterial;
    this.NewPO.ProductionElement = this.productionElement;
    this.NewPO.ProductId = this.selectedproduct.ProductId;
    this.NewPO.AddedBy = UserInfo.EmailID;
    this.NewPO.Process = this.ProcessSelectedList;
    this.NewPO.Mixing = this.MixingSelectedList;



    let url = this.ApiUrl + "production/addupdateproduction";
    this.http.post<ProductionModel>(url, this.NewPO).subscribe({
      next: res => {
        this.alertService.success("Entry Saved Successfully"); this.isLoading = false;
        this.prolist = [];
        this.router.navigate(['/home/production/list'])
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });

  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProductionId = params["id"];
    })

    this.validateForm = this.fb.group({
      supplierId: [null, [Validators.required]],
      deliveryTermId: [null, [Validators.required]],
      ponumber: [null, [Validators.required]],
      transportId: [null, [Validators.required]],
      paymentTermId: [null, [Validators.required]],
      pocreationDate: [new Date().toUTCString(), [Validators.required]],
      reference: [null]

    });

    this.GetAllProductCategory();
    this.GetAllColor();
    this.GetAllUnits();
    this.GetAllProducts();
    this.GetAllGrain();
    this.GetAllElement();
    this.GetAllOrder();
    this.GetProcess();
    this.GetMixing();
    this.GetAllVaccum();
    this.GetAllTumbling();
    this.GetAllLacquer();
    this.GetAllEmbossing();
    this.GetAllPrint();
    if (this.ProductionId > 0)
      this.GetProductionDatabyid(this.ProductionId)
  }
  get f() { return this.validateForm.controls; }

  GetProductionDatabyid(id: number) {
    let url = this.ApiUrl + "production/getproductiondatabyid/" + id;
    this.http.get<any>(url).subscribe(res => {
      this.NewPO = res;
      this.NewPO.ProductId = this.ProductionId;
      this.productionOrder = this.NewPO.ProductionOrder;
      this.productionElement = this.NewPO.ProductionElement;
      this.productionRawMaterial = this.NewPO.ProductionRawMaterial;
      this.ProcessSelectedList = this.NewPO.Process;
      this.MixingSelectedList = this.NewPO.Mixing;

    });
  }

  GetProduction() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "production/getallproductions";
    this.http.get<ProductionModel[]>(url).subscribe(res => {
      this.NewPO = res.filter(x => x.ProductionId == this.ProductionId)[0];
      this.productionOrder = this.NewPO.ProductionOrder;
      this.productionElement = this.NewPO.ProductionElement;
      this.productionRawMaterial = this.NewPO.ProductionRawMaterial;
    }, res => { });
  }
  GetAllOrder() {

    let url = this.ApiUrl + "order/getallorders";
    this.http.get<OrderModel[]>(url).subscribe(res => {
      this.OrderList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllOrder(); }
    });
  }
  GetAllElement() {

    let url = this.ApiUrl + "element/getallelements";
    this.http.get<ElementModel[]>(url).subscribe(res => {
      this.ElementList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllElement(); }
    });
  }
  GetAllColor() {

    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllColor(); }
    });
  }
  GetAllGrain() {

    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllGrain(); }
    });
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnits(); }
    });
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(); }
    });
  }

  GetAllFirstCategory(data: any, id: number = 0) {



    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.productItem.FirstCategoryID = id;

    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {



    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);

      this.productItem.SecondCategoryID = id;
    }, res => { });
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res;
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  GetAllVaccum() {

    let url = this.ApiUrl + 'vacuum/getallvacuums';
    this.http.get<ProductionVaccumModel[]>(url).subscribe(
      (res) => {
        this.VaccumList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllVaccum(); }
      }
    );
  }
  GetAllTumbling() {
    let url = this.ApiUrl + 'tumbling/getalltumblings';
    this.http.get<ProductionTumblingModel[]>(url).subscribe(
      (res) => {
        this.TumblingList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllTumbling(); }
      }
    );
  }
  GetAllLacquer() {
    let url = this.ApiUrl + 'lacquer/getalllacquers';
    this.http.get<ProductionLacquerModel[]>(url).subscribe(
      (res) => {
        this.LacquerList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetAllLacquer(); }
      }
    );
  }
  GetAllEmbossing() {

    let url = this.ApiUrl + "embossing/getallembossings";
    this.http.get<ProductionEmbossingModel[]>(url).subscribe(res => {
      this.EmbossingList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllEmbossing(); }
    });
  }
  GetAllPrint() {

    let url = this.ApiUrl + "print/getallprints";
    this.http.get<ProductionPrintMasterModel[]>(url).subscribe(res => {
      this.PrintMasterList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllPrint(); }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  UpdateProduct(data: any) {

    this.selectedunit = data.Unit;
    this.productItem.ProductName = data.ProductName;
    this.productItem.ProductID = data.ProductId;
    this.productItem.CategoryID = data.ProductCategoryId;
    //let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    //this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
    //  this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.ProductCategoryId);
    //  this.productItem.FirstCategoryID = data.ProductFirstSubCategoryId;
    //  let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    //  this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
    //    this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId);
    //    this.productItem.SecondCategoryID = data.ProductSecSubCategoryId

    //  }, res => { });


    //}, res => { });
    //this.GetAllFirstCategory(data.ProductCategoryId, data.ProductFirstSubCategoryId);
    //this.GetAllSecondCategory(data.ProductFirstSubCategoryId, data.ProductSecondSubCategoryId);

    //this.productItem.UnitID = data.ProductName;
    //var filteredorders = this.OrderList.filter(x => this.productionOrder.some(item => item.orderId === x.OrderId))
    //filteredorders.forEach(o => {
    //  o.OrderProduct.forEach(p => {
    //    if (p.ProductId == data.ProductId) {
    //      this.NewPO.orderQuantity += p.Quantity;
    //    }
    //  })
    //})


  }
  UpdateUnit(data: any) {


    this.productItem.Unit = this.selectedunit;
    this.productItem.UnitID = data.Id;
    //this.productItem.UnitID = data.ProductName;

  }

  OnRatechange(data: any) {

    var amt = data * this.productItem.Quantity;

    this.productItem.Amount = amt;

  }
  OnQtychange(data: any) {

    var amt = data * this.productItem.Rate;

    //this.Amount = amt;
    this.productItem.Amount = amt;

  }
  btnAddClick() {
    if (!this.Validate(this.productItem)) {
      return;
    }

    var Item = {
      CategoryID: this.productItem.CategoryID,
      FirstCategoryID: this.productItem.FirstCategoryID,
      SecondCategoryID: this.productItem.SecondCategoryID,
      ProductID: this.productItem.ProductID,
      ProductName: this.productItem.ProductName,
      UnitID: this.productItem.UnitID,
      AvgGsm: "",
      Unit: this.selectedunit,
      Rate: this.productItem.Rate,
      Quantity: this.productItem.Quantity,
      Amount: this.productItem.Amount,
      Grade: this.productItem.Grade,
      IGST: 0,
      Currency: ''
    }
    this.prolist.push(Item)

    this.SubTotal = this.GrandTotal = 0
    this.prolist.forEach((element) => {
      this.SubTotal += parseFloat(element.Rate.toString());
      this.GrandTotal += parseFloat(element.Amount.toString());
    });
    this.productItem = {
      CategoryID: 0,
      FirstCategoryID: 0,
      SecondCategoryID: 0,
      ProductID: 0,
      ProductName: '',
      UnitID: 0,
      Unit: '',
      Grade: '',
      Rate: 0,
      Quantity: 0,
      Amount: 0,
    }
    this.selectedunit = '';
    this.selectedproduct = '';


  }
  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ProductID == '0') {
      this.alertService.error("Select Product"); Isvalid = false;
    }
    else if (model.Rate == '0') {
      this.alertService.error("Enter Rate"); Isvalid = false;
    }
    else if (model.Quantity == '0') {
      this.alertService.error("Enter Quantity"); Isvalid = false;
    }
    return Isvalid;
  }
  handleRemoveRow(data: any) {
    this.productionOrder = this.productionOrder.filter(obj => obj !== data);
    this.SelectedOrder = 0;
    this.NewPO.OrderQuantity = 0;

    if (this.productionOrder.length == 0) {
      this.selectedproductId = 0
    }
    else {
      this.productionOrder.forEach(x => {
        this.NewPO.OrderQuantity += parseFloat(x.Value.toString());
      })
    }

    this.NewPO.ManufacturingQuantity = this.NewPO.OrderQuantity;
  }
  OnProductDdlchange() {
    this.productionRawMaterial = [];

    this.listOfSelectedproducts.forEach(item => {
      var checkexist = this.productionRawMaterial.filter(x => x.ProductId == item).length;
      if (checkexist == 0) {
        var product = this.ProductRawList.filter(x => x.ProductId == item)[0];
        var rawproduct = new ProductionRawMaterialModel;
        rawproduct.ProductId = item;
        rawproduct.ProductName = product.ProductName;
        rawproduct.ProductCode = product.ProductCode;
        rawproduct.Quantity = 0;
        rawproduct.Unit = product.Unit;
        rawproduct.AvgGsm = product.AvgGsm;
        this.productionRawMaterial.push(rawproduct);
      }

    })
  }
  OnElementDdlchange() {
    this.productionElement = [];

    this.listOfSelectedElement.forEach(item => {
      var checkexist = this.productionElement.filter(x => x.ElementId == item).length;
      if (checkexist == 0) {
        var product = this.ElementList.filter(x => x.ElementId == item)[0];
        var rawproduct = new ProductionElementModel;
        rawproduct.ElementId = item;
        rawproduct.ElementName = product.ElementName;
        rawproduct.Value = '';

        this.productionElement.push(rawproduct);
      }

    })
  }

  OnOrderDdlchange() {

    if (this.selectedproductId == 0) {
      this.isVisible = true;
      this.listOfSelectedOrderProdects = this.OrderList.filter(x => x.OrderId == this.SelectedOrder)[0].OrderProduct
    }
    else {
      var filteredorder = this.OrderList.filter(x => x.OrderId == this.SelectedOrder)[0].OrderProduct
      if (filteredorder.filter(x => x.ProductId == this.selectedproductId).length > 0) {

        var fo = filteredorder.filter(x => x.ProductId == this.selectedproductId)[0];

        if (this.productionOrder.filter(x => x.orderId == fo.OrderId).length == 0) {

          var order = new ProductionOrderModel();
          order.orderId = fo.OrderId;
          order.ProductCode = fo.ProductCode;
          order.Unit = fo.Unit;

          order.SaleOrderNo = this.OrderList.filter(x => x.OrderId == this.SelectedOrder)[0].SaleOrderNo;
          order.Products = fo.ProductName;
          order.Value = fo.Quantity;

          this.productionOrder.push(order);
          this.NewPO.OrderQuantity += fo.Quantity;

          this.NewPO.ManufacturingQuantity = this.NewPO.OrderQuantity;
        }
        else {
          this.alertService.error("Selected order is already exist")
        }
      } else {
        this.alertService.error("Selected product does not exist in this order")
      }
    }

  }
  OnSelectProduct(data: OrderProductModel) {

    this.selectedproductId = data.ProductId;
    var selectedProduct = this.ProductList.filter(x => x.ProductId == this.selectedproductId)[0];
    this.selectedCategory = selectedProduct.ProductCategory;
    //this.productionOrder = [];

    var order = new ProductionOrderModel();
    order.orderId = data.OrderId;
    order.SaleOrderNo = this.OrderList.filter(x => x.OrderId == this.SelectedOrder)[0].SaleOrderNo;
    order.Products = data.ProductName;
    order.Value = data.Quantity;
    order.ProductCode = selectedProduct.ProductCode;
    order.Unit = selectedProduct.Unit;
    this.productionOrder.push(order);
    this.NewPO.Width = data.Width;
    this.NewPO.Thick = data.Thick;
    this.NewPO.OrderQuantity = data.Quantity;
    this.NewPO.ManufacturingQuantity = this.NewPO.OrderQuantity;

    this.NewPO.ColorId = this.OrderList.filter(x => x.OrderId == this.SelectedOrder)[0].OrderProduct.filter(x => x.ProductId == this.selectedproductId)[0].ColorId;


    //this.listOfSelectedOrder.forEach(item => {
    //  var checkexist = this.productionOrder.filter(x => x.orderId == item).length;
    //  if (checkexist == 0) {
    //    var product = this.OrderList.filter(x => x.OrderId == item)[0];
    //    var rawproduct = new ProductionOrderModel;
    //    rawproduct.orderId = product.OrderId;
    //    rawproduct.SaleOrderNo = product.SaleOrderNo;
    //    rawproduct.Products = product.OrderProduct.filter(x => x.ProductName).join(',')
    //    this.productionOrder.push(rawproduct);
    //  }

    //})
    //var filteredorders = this.OrderList.filter(f => this.productionOrder.some(item => item.orderId === f.OrderId));
    //var filteredproducts: number[] = []
    //filteredorders.forEach(o => {
    //  o.OrderProduct.forEach(p => {
    //    if (!filteredproducts.includes(p.ProductId))
    //      filteredproducts.push(p.ProductId)
    //  })
    //})

    this.ProductFilteredList = this.ProductList.filter(f => f.ProductId == this.selectedproductId);
    this.selectedproduct = this.ProductFilteredList.filter(x => x.ProductId == this.selectedproductId)[0];

    this.isVisible = false;
  }

  GetProcess() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "process/getallprocesss";
    this.http.get<ProcessModel[]>(url).subscribe(res => {
      this.ProcessList = res;




    }, res => {
      this.count++;
      if (this.count < 2) { this.GetProcess(); }
    });
  }

  OnProcessDdlchange() {
    this.ProcessSelectedList = [];

    this.listOfSelectedprocess.forEach(item => {
      var checkexist = this.processRawMaterial.filter(x => x.ProcessId == item).length;
      if (checkexist == 0) {
        var product = this.ProcessList.filter(x => x.ProcessId == item)[0];
        var process = new ProductionProcessModel();
        process.AddedBy = product.AddedBy;
        process.AddedDate = product.AddedDate;
        process.ProductionProcessId = product.ProcessId;
        process.ProductionProcessName = product.ProcessName;
        process.Temperature = product.Temperature;
        process.WeightGsm = product.WeightGsm;
        process.WeightPressure = product.WeightPressure;

        process.ProductionProcessRawMaterial = [];

        product.ProcessRawMaterial.forEach(x => {
          var raw = new ProductionProcessRawMaterial();
          raw.Price = x.Price;
          raw.ProductCode = x.ProductCode;
          raw.ProductId = x.ProductId;
          raw.ProductionProcessId = x.ProcessId;
          raw.ProductionProcessRawMaterialId = x.ProcessRawMaterialId;
          raw.ProductName = x.ProductName;
          raw.Quantity = x.Quantity;
          raw.Unit = this.ProductRawList.filter(y => y.ProductId == x.ProductId)[0].Unit;
          raw.AvgGsm = x.AvgGsm;
          process.ProductionProcessRawMaterial.push(raw);
        })

        this.ProcessSelectedList.push(process)
      }

    })
    this.NewPO.ProcessGSMTotal = 0;
    this.ProcessSelectedList.forEach(x => {
      this.NewPO.ProcessGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    })
    this.NewPO.FinalProcessGSMTotal = this.NewPO.ProcessGSMTotal + this.NewPO.RawGSMTotal;
  }
  calculateRawTotal(data: ProductionRawMaterialModel) {

    this.NewPO.RawGSMTotal = 0;
    this.productionRawMaterial.forEach((element) => {
      this.NewPO.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });

    this.NewPO.FinalProcessGSMTotal = this.NewPO.ProcessGSMTotal + this.NewPO.RawGSMTotal;
    this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;

  }
  calculateProcessTotal(data: ProductionProcessRawMaterial) {
    var item = this.ProcessSelectedList.filter(x => x.ProductionProcessId == data.ProductionProcessId)[0];


    item.WeightGsm = 0;
    item.ProductionProcessRawMaterial.forEach((element) => {
      item.WeightGsm += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });
    this.NewPO.ProcessGSMTotal = 0;
    this.ProcessSelectedList.forEach(x => {

      this.NewPO.ProcessGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    })
    this.NewPO.FinalProcessGSMTotal = this.NewPO.ProcessGSMTotal + this.NewPO.RawGSMTotal;
  }
  GetMixing() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mixing/getallmixings";
    this.http.get<MixingModel[]>(url).subscribe(res => {
      this.MixingList = res;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetMixing(); }
    });
  }
  OnMixingDdlchange() {
    this.MixingSelectedList = [];
    this.listOfSelectedmixing.forEach(item => {
      var checkexist = this.mixingRawMaterial.filter(x => x.MixingId == item).length;
      if (checkexist == 0) {
        var product = this.MixingList.filter(x => x.MixingId == item)[0];
        var mixing = new ProductionMixingModel();
        mixing.AddedBy = product.AddedBy;
        mixing.AddedDate = product.AddedDate;
        mixing.ProductionMixingId = product.MixingId;
        mixing.ProductionMixingName = product.MixingName;
        mixing.Total = product.Total;
        mixing.Wastage = product.Wastage;
        mixing.WeightGsm = product.WeightGsm;
        mixing.WastageType = product.WastageType ?? "%";
        mixing.ProductionMixingRawMaterial = [];

        product.MixingRawMaterial.forEach(x => {
          var raw = new ProductionMixingRawMaterial();
          raw.Price = x.Price;
          raw.ProductCode = x.ProductCode;
          raw.ProductId = x.ProductId;
          raw.ProductionMixingId = x.MixingId;
          raw.ProductionMixingRawMaterialId = x.MixingRawMaterialId;
          raw.ProductName = x.ProductName;
          raw.Quantity = x.Quantity;
          raw.Unit = this.ProductRawList.filter(y => y.ProductId == x.ProductId)[0].Unit;
          raw.AvgGsm = x.AvgGsm;
          mixing.ProductionMixingRawMaterial.push(raw);
        })

        this.MixingSelectedList.push(mixing)
      }

    })
    this.NewPO.MixingTotalCost = 0;
    this.MixingSelectedList.forEach(x => {
      this.NewPO.MixingTotalCost += x.Total;
      this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    })
    this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;

  }
  calculateMixingTotal(data: ProductionMixingRawMaterial) {
    var item = this.MixingSelectedList.filter(x => x.ProductionMixingId == data.ProductionMixingId)[0];
    item.Total = 0;
    item.WeightGsm = 0;
    item.ProductionMixingRawMaterial.forEach((element) => {
      item.Total += parseFloat(element.Quantity.toString());
      item.WeightGsm += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
    });
    this.OnWastagetypeDdlchange(item)

  }
  //calculateMixingGSM() {

  //  this.NewPO.MixingGSMTotal = 0;
  //  this.MixingSelectedList.forEach(x => {
  //    this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
  //  })
  //}
  OnWastagetypeDdlchange(data: ProductionMixingModel) {
    var total = 0;
    data.ProductionMixingRawMaterial.forEach((element) => {
      total += parseFloat(element.Quantity.toString());

    });
    if (data.WastageType == "%") {
      data.Total = total - ((data.Wastage * total) / 100);

    } else {
      data.Total = total - data.Wastage;
    }
    this.NewPO.MixingTotalCost = this.NewPO.MixingGSMTotal = 0;
    this.MixingSelectedList.forEach(x => {
      this.NewPO.MixingTotalCost += x.Total;
      this.NewPO.MixingGSMTotal += isNaN(parseInt(x.WeightGsm.toString())) ? 0 : parseInt(x.WeightGsm.toString());
    })
    this.NewPO.FinalMixingGSMTotal = this.NewPO.MixingGSMTotal + this.NewPO.RawGSMTotal;
  }
  OnLacquerDdlchange() {
    this.LacquerRawMaterial = this.LacquerList.filter(x => x.LacquerMasterId == this.NewPO.LacquerMasterId)[0].LacquerRawMaterial;


  }
}

