import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { PaymentTermModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-term',
  templateUrl: './payment-term.component.html',
  styleUrls: ['./payment-term.component.css']
})
export class PaymentTermComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  PaymentTermList: PaymentTermModel[] = [];
  PaymentTermListOriginal: PaymentTermModel[] = [];
  NewPaymentTerm: PaymentTermModel = new PaymentTermModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New PaymentTerm";
  NameError = 'Enter PaymentTerm name';
  searchValue = ''

  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.PaymentTerm, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.PaymentTerm, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.PaymentTerm, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }


    this.validateForm = this.fb.group({
      PaymentTermName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      PaymentTermDesc: [null, [Validators.maxLength(200)]]

    });
    this.GetAllPaymentTerm();
  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllPaymentTerm() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "paymentTerm/getallpaymentterms";
    this.http.get<PaymentTermModel[]>(url).subscribe(res => {
      this.PaymentTermList = res;
      this.PaymentTermListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllPaymentTerm();
      }
    });
  }
  SavePaymentTerm() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewPaymentTerm.PaymentTerm = this.f['PaymentTermName'].value;
    this.NewPaymentTerm.PaymentTermDesc = this.f['PaymentTermDesc'].value;
    this.NewPaymentTerm.PaymentTermAddedBy = UserInfo.EmailID;
    var data = this.PaymentTermList.filter(x => x.PaymentTerm == this.NewPaymentTerm.PaymentTerm);
    if (data.length > 0) {
      if (this.NewPaymentTerm.PaymentTermId > 0) {
        var res = this.PaymentTermList.filter(x => x.PaymentTermId != this.NewPaymentTerm.PaymentTermId);
        var nre = res.filter(x => x.PaymentTerm == this.NewPaymentTerm.PaymentTerm);
        if (nre.length > 0) {
          this.alertService.error("Payment Term already exist. Please try again")
          this.isLoading = false
          return;
        }
      }
      else {
        this.alertService.error("Payment Term already exist. Please try again")
        this.isLoading = false
        return;
      }
    }

    // if(!this.Validate(this.NewPaymentTerm))
    // {
    //   this.isLoading=false
    //     return;
    // }
    let url = this.ApiUrl + "paymentterm/addupdatepaymentterm";
    this.http.post<PaymentTermModel>(url, this.NewPaymentTerm).subscribe({

      next: res => { this.alertService.success("PaymentTerm Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllPaymentTerm(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: PaymentTermModel) {
    this.PopUpTitle = "Modify PaymentTerm Details";
    this.validateForm.setValue(
      {
        PaymentTermName: data.PaymentTerm,
        PaymentTermDesc: data.PaymentTermDesc,

      }
    )
    this.NewPaymentTerm = new PaymentTermModel();
    this.NewPaymentTerm.PaymentTermId = data.PaymentTermId;

    this.isVisible = true;
  }


  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllPaymentTerm();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.PaymentTermListOriginal;
    var res2 = this.PaymentTermListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: PaymentTermModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.PaymentTermAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.PaymentTermAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.PaymentTermList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.PaymentTermList = res.filter(
        (item: PaymentTermModel) =>
          item?.PaymentTerm?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.PaymentTermAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.PaymentTermDesc?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }


  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  showModal(): void {
    this.PopUpTitle = "Add New PaymentTerm";
    this.validateForm.reset();
    this.NewPaymentTerm.PaymentTerm = "";
    this.NewPaymentTerm.PaymentTermId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SavePaymentTerm();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeletePaymentTerm() {
    let url = this.ApiUrl + "PaymentTerm/deletePaymentTerm";
    this.http.post<any>(url, this.NewPaymentTerm).subscribe({

      next: res => {

        this.alertService.success("PaymentTerm Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllPaymentTerm();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: PaymentTermModel) {
    this.NewPaymentTerm = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this PaymentTerm?',
      nzOkDanger: true,
      nzOnOk: () => this.DeletePaymentTerm(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.PaymentTermList.filter(x => x.PaymentTermId != this.NewPaymentTerm.PaymentTermId);
        var nre = res.filter(x => x.PaymentTerm.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter PaymentTerm name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "PaymentTerm name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter PaymentTerm name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

}
