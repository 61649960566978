<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Sales Report</nz-page-header-title>
            <nz-page-header-subtitle>Showing all Sale Orders with filters here</nz-page-header-subtitle>
        </nz-page-header>
        <nz-tabset nzSize="large">
            <nz-tab nzTitle="Sales Report" (nzSelect)="setActiveParentTabName('salesreport')">
                <nz-collapse>
                    <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false"
                        (nzActiveChange)="onFilterPanelOpen($event)">
                        <div nz-col [nzSpan]="24">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Date Type</label>
                                            <nz-select name="select-error" [(ngModel)]="selectedDateType">
                                                <nz-option *ngFor="let data of dateTypeList" [nzValue]="data"
                                                    [nzLabel]="data.Text"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Date Filters</label>
                                            <nz-select [(ngModel)]="selecteddateFilter"
                                                (ngModelChange)="getDateRange(selecteddateFilter)"
                                                nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                                                <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                                    [nzLabel]="data.Text"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4"
                                    *ngIf="selectedDateType?.Value=='saleorderdate' && this.enableCustomDateRange">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label> Order Date (From)</label>
                                            <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom"
                                                class="form-control" [(ngModel)]="request.FromSaleOrderDate"
                                                nzAllowClear></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4"
                                    *ngIf="selectedDateType?.Value=='saleorderdate'  && this.enableCustomDateRange">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label> Order Date (To)</label>
                                            <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo"
                                                class="form-control" [(ngModel)]="request.ToSaleOrderDate"
                                                nzAllowClear></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4"
                                    *ngIf="selectedDateType?.Value=='addeddate'  && this.enableCustomDateRange">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label> Received Date (From)</label>
                                            <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom"
                                                class="form-control" [(ngModel)]="request.FromAddedDate"
                                                nzAllowClear></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4"
                                    *ngIf="selectedDateType?.Value=='addeddate' && this.enableCustomDateRange">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label> Received Date (To)</label>
                                            <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo"
                                                class="form-control" [(ngModel)]="request.ToAddedDate"
                                                nzAllowClear></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4"
                                    *ngIf="selectedDateType?.Value=='deliverydate' && this.enableCustomDateRange">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label> Delivery Date (From)</label>
                                            <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom"
                                                class="form-control" [(ngModel)]="request.FromDeliveryDate"
                                                nzAllowClear></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4"
                                    *ngIf="selectedDateType?.Value=='deliverydate' && this.enableCustomDateRange">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label> Delivery Date (To)</label>
                                            <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo"
                                                class="form-control" [(ngModel)]="request.ToDeliveryDate"
                                                nzAllowClear></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Product Type</label>
                                            <nz-select [(ngModel)]="request.ProductType" nzAllowClear
                                                nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                                    [nzLabel]="data.Text"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Customer</label>
                                            <nz-select class="form-select mb-2" nzShowSearch name="CustomerId"
                                                nzSize="default" nzAllowClear [(ngModel)]="request.CustomerId"
                                                nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                                    [nzLabel]="s.CustomerName"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Formulation/Product Code</label>
                                            <nz-select class="form-select mb-2" nzShowSearch
                                                name="SelectedFormulationCode" nzSize="default" nzAllowClear
                                                [(ngModel)]="request.SaleFormulationCodeId" nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let s of this.FormulationCodeList;"
                                                    [nzValue]="s.SaleFormulationCodeId"
                                                    [nzLabel]="s.SaleFormulationCode"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <!-- <div nz-col [nzSpan]="4">
                                <nz-form-item>
                                    <nz-form-control [nzSpan]="24">
                                        <label>Order Status</label>
                                        <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                                            <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                                [nzLabel]="data.Text"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
                                <!-- <div nz-col [nzSpan]="4">
                                <nz-form-item>
                                    <nz-form-control [nzSpan]="24">
                                        <label>Production Status</label>
                                        <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                                            <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                                [nzLabel]="data.Text"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Color</label>
                                            <nz-select class="form-select mb-2" nzSize="default"
                                                [(ngModel)]="request.ColorId" nzShowSearch nzAllowClear
                                                nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                                    [nzLabel]="s.ColorName"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Grain Name</label>
                                            <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId"
                                                nzSize="default" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                                                    [nzLabel]="s.GrainName"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Thickness</label>
                                            <nz-select class="form-select mb-2" nzSize="default"
                                                [(ngModel)]="request.ThicknessId" nzAllowClear nzShowSearch
                                                nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let s of this.ThicknessList;"
                                                    [nzValue]="s.ThicknessId" [nzLabel]="s.ThicknessNumber"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                                            <label>Article Name</label>
                                            <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30"
                                                [(ngModel)]="request.ArticleName" (keydown)="space($event)" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                            <label>Order Created By</label>
                                            <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                                                [(ngModel)]="request.AddedBy" nzAllowClear nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email"
                                                    [nzLabel]="s.Name"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                            <label>Order Type</label>
                                            <nz-select name="select-error" [(ngModel)]="request.OrderType" nzShowSearch
                                                nzAllowClear nzPlaceHolder="Choose">
                                                <nz-option *ngFor="let data of orderTypeList" [nzValue]="data"
                                                    [nzLabel]="data"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                            <label>Sale Order No.</label>
                                            <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number"
                                                maxlength="30" [(ngModel)]="request.SaleOrderNumber"
                                                (keydown)="space($event)" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                                <div style="display: flex">
                                    <button nz-button nzSize="small" nzType="primary" (click)="GetSalesReport()">
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nz-collapse-panel>
                </nz-collapse>
                <nz-divider></nz-divider>
                <nz-tabset nzSize="default">
                    <nz-tab nzTitle="Table View">

                        <div style="padding: 8px;float:right">
                            <nz-button-group style="margin-left:5px">
                                <button nz-button nzSize="small"
                                    (click)="setAutoRefreshAction()">{{this.autoRefreshBtnText}}</button>
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight"
                                    nzSize="small">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                            </nz-button-group>
                            <nz-dropdown-menu #menu1="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item (click)="setAutoRefreshTime(30)">Refresh Every 30 Seconds</li>
                                    <li nz-menu-item (click)="setAutoRefreshTime(60)">Refresh Every 60 Seconds</li>
                                    <li nz-menu-item (click)="setAutoRefreshTime(300)">Refresh Every 5 Minute</li>
                                    <li nz-menu-item (click)="setAutoRefreshTime(900)">Refresh Every 15 Minute</li>
                                </ul>
                            </nz-dropdown-menu>
                            <button nz-button nzSize="small" (click)="export()" style="margin-left:5px"><i nz-icon
                                    nzType="export" nzTheme="outline"
                                    style="top: 2px;right: 49px;position: absolute;"></i>
                                Export</button>
                            <!-- <button nz-button *ngIf="this.isEmailReportEnabled" [nzLoading]="isEmailButtonLoading" nzSize="small"
                            (click)="sendEmail()" style="margin-left:5px"><i nz-icon nzType="mail" nzTheme="outline"
                                style="top: 2px;right: 79px;position: absolute;"></i> Send Email</button> -->
                            <!-- <button nz-button nzSize="small" printSectionId="print-section" ngxPrint
                            printTitle="Sales Report - From: {{request.FromAddedDate | date:'dd-MM-yyyy HH:mm:ss a'}} To: {{request.ToAddedDate | date:'dd-MM-yyyy HH:mm:ss a'}}"
                            [useExistingCss]="true" [printStyle]="{ '@page': { size: 'landscape !important' } }"
                            style="margin-left:5px"><i style="top: 2px;right: 39px;position: absolute;" nz-icon nzType="printer"
                                nzTheme="outline"></i>Print</button> -->
                        </div>
                        <nz-divider></nz-divider>

                        <div style="max-width:100%; overflow:scroll;border-color: black;">
                            <nz-table id="print-section" nzSize="small" style="width: 100%;page-break-inside: auto;"
                                [nzScroll]="{ x: '1700px',y:'515px' }" [nzData]="this.SalesReportList" #basicTable
                                [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger
                                [nzPageSize]="20" [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate"
                                nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;font-size:x-small;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzLeft nzWidth="50px"></th>
                                        <th nzLeft nzWidth="80px" rowspan="2">Order Date</th>
                                        <th nzLeft nzWidth="80px" rowspan="2">Order No.</th>
                                        <th nzWidth="80px">Article Name</th>
                                        <th nzWidth="70px">P.Code</th>
                                        <th nzWidth="70px">Grain Name</th>
                                        <th nzWidth="80px">Colors</th>
                                        <th nzWidth="60px">Width</th>
                                        <th nzWidth="80px">Fabric</th>
                                        <th nzWidth="60px">Thick</th>
                                        <th nzWidth="80px">Finish</th>
                                        <th nzWidth="70px">Order QTY (MTR)</th>
                                        <th>Pre Skin (GSM)</th>
                                        <th>Skin (GSM)</th>
                                        <th>Foam (GSM)</th>
                                        <th>Adhesive (GSM)</th>
                                        <th>Fabric (GSM)</th>
                                        <th>Final (GSM)</th>
                                        <th nzWidth="100px">Party name</th>
                                        <th nzWidth="100px">Remarks</th>
                                        <th nzWidth="90px">Order Created Date</th>
                                        <th nzWidth="100px">Order Created By</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable.data;"
                                        style="text-align: left;font-size: x-small;border-color: black;page-break-inside:avoid; page-break-after:auto">
                                        <td style="border-color: black;" nzLeft>{{ data.SerialNo }}</td>
                                        <td style="border-color: black;" nzLeft>{{ data.OrderDate }}</td>
                                        <td style="border-color: black;" nzLeft>{{ data.SaleOrderNo }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.ItemName }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.PCode }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Grain }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Colours}}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Width }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Fabric }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Thick }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Finish }}</td>
                                        <td style="border-color: black;">{{ data.OrderQty }}</td>
                                        <!-- <td style="border-color: black;">{{ data.Rate }}</td> -->
                                        <td style="border-color: black;">{{ data.PreSkin }}</td>
                                        <td style="border-color: black;">{{ data.Skin }}</td>
                                        <td style="border-color: black;">{{ data.Foam }}</td>
                                        <td style="border-color: black;">{{ data.Adhesive }}</td>
                                        <td style="border-color: black;">{{ data.FabricGsm }}</td>
                                        <td style="border-color: black;">{{ data.PreSkin + data.Skin + data.Foam +
                                            data.Adhesive
                                            +
                                            data.FabricGsm }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.PartyName }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.Remarks }}</td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.CreatedDate |
                                            DatetimeConverter }}
                                        </td>
                                        <td style="border-color: black;" nzBreakWord>{{ data.CreatedBy }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                </nz-tabset>
            </nz-tab>
            <nz-tab nzTitle="Top Selling Products" (nzSelect)="setActiveParentTabName('topsellingproducts')">
                <nz-collapse>
                    <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false">
                        <div nz-col [nzSpan]="24">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Date Filters</label>
                                            <nz-select [(ngModel)]="selecteddateFilter"
                                                (ngModelChange)="getDateRange(selecteddateFilter)"
                                                nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                                                <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                                    [nzLabel]="data.Text"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Records</label>
                                            <nz-select [(ngModel)]="topsellingrequest.NumberOfRecords">
                                                <nz-option *ngFor="let data of NumberOfRecordsList"
                                                    [nzValue]="data.Value" [nzLabel]="data.Text"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                                <div style="display: flex">
                                    <button nz-button nzSize="small" nzType="primary" (click)="GetTopSellingProducts()">
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nz-collapse-panel></nz-collapse>
                <nz-divider></nz-divider>
                <nz-tabset>
                    <nz-tab nzTitle="Chart View">
                        <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
                        <div class="card-body pt-5">
                            <div id="chartdiv" class="w-100 h-400px"></div>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Table View">
                        <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
                        <div style="padding: 8px;float:right" *ngIf="this.TopSellingProductTableList?.length > 0">
                            <button nz-button nzSize="small" printSectionId="print-section-topsellingproduct" ngxPrint
                            printTitle="Sales Report - Top Selling Products - From: {{topsellingrequest.DateFrom| date:'dd-MM-yyyy HH:mm:ss a'}} To: {{topsellingrequest.DateTo| date:'dd-MM-yyyy HH:mm:ss a'}}"
                            [useExistingCss]="true" [printStyle]="{ '@page': { size: 'landscape !important' } }"
                            style="margin-left:5px"><i style="top: 2px;right: 39px;position: absolute;" nz-icon nzType="printer"
                                nzTheme="outline"></i>Print</button>
                        </div>
                        <nz-divider></nz-divider>
                        <div style="max-width:100%; overflow:scroll;border-color: black;"
                            *ngIf="this.TopSellingProductTableList?.length > 0">
                            <nz-table id="print-section-topsellingproduct" nzSize="small" style="width: 100%;page-break-inside: auto;"
                                [nzScroll]="{ x: '800px',y:'515px' }" [nzData]="this.TopSellingProductTableList"
                                #basicTable2 [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
                                nzShowSizeChanger [nzPageSize]="20" [nzPageSizeOptions]=[20,50,100,200,500]
                                [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzWidth="50px">S. No.</th>
                                        <th nzWidth="80px">Product Code</th>
                                        <th nzWidth="80px">Thickness</th>
                                        <th nzWidth="80px">Total Order QTY</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable2.data;"
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto">
                                        <td style="border-color: black;">{{ data.SerialNo }}</td>
                                        <td style="border-color: black;">{{ data.FormulationCode }}</td>
                                        <td style="border-color: black;">{{ data.Thickness }}</td>
                                        <td style="border-color: black;">{{ data.TotalOrderQty }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                </nz-tabset>
            </nz-tab>
            <nz-tab nzTitle="Received Orders" (nzSelect)="setActiveParentTabName('receivedorders')">
                <nz-collapse>
                    <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false">
                        <div nz-col [nzSpan]="24">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzSpan]="4">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label>Date Filters</label>
                                            <nz-select [(ngModel)]="selecteddateFilter"
                                                (ngModelChange)="getDateRange(selecteddateFilter)"
                                                nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                                                <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                                    [nzLabel]="data.Text"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="5">
                                    <nz-form-item>
                                        <nz-form-control [nzSpan]="24">
                                            <label style="display:block;margin-bottom:5px;">Show Data by</label>
                                            <nz-radio-group [(ngModel)]="ReceivedOrdersRequest.DataType"
                                                (ngModelChange)="ResetTableChart()">
                                                <label nz-radio nzValue="byorderqty">Order Quantity in MTR </label>
                                                <label nz-radio nzValue="byNumberOfOrders">Number of Orders</label>
                                            </nz-radio-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                                <div style="display: flex">
                                    <button nz-button nzSize="small" nzType="primary" (click)="GetReceivedOrders()">
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nz-collapse-panel></nz-collapse>
                <nz-divider></nz-divider>
                <div>
                    <label
                        *ngIf="this.ReceivedOrdersRequest.DataType == 'byorderqty' && this.ReceivedOrdersTableDataList?.length > 0">Grand
                        Total Order Quantity in MTR: <b>{{CalculateGrandTotal('receivedordersbyorderqty')}}</b></label>
                    <label
                        *ngIf="this.ReceivedOrdersRequest.DataType == 'byNumberOfOrders' && this.ReceivedOrdersTableDataList?.length > 0">Grand
                        Total Number of Orders: <b>{{CalculateGrandTotal('receivedordersbynumberoforder')}}</b></label>
                </div>
                <nz-divider></nz-divider>
                <nz-tabset>
                    <nz-tab nzTitle="Chart View">
                        <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
                        <div class="card-body pt-5">
                            <div id="receivedorderdiv" class="w-100 h-400px"></div>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Table View">
                        <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
                        <div style="padding: 8px;float:right" *ngIf="this.ReceivedOrdersTableDataList?.length > 0">
                            <button nz-button nzSize="small" printSectionId="print-section-receivedorders" ngxPrint
                            printTitle="Sales Report - Received Orders - From: {{ReceivedOrdersRequest.DateFrom| date:'dd-MM-yyyy HH:mm:ss a'}} To: {{ReceivedOrdersRequest.DateTo| date:'dd-MM-yyyy HH:mm:ss a'}}"
                            [useExistingCss]="true" [printStyle]="{ '@page': { size: 'landscape !important' } }"
                            style="margin-left:5px"><i style="top: 2px;right: 39px;position: absolute;" nz-icon nzType="printer"
                                nzTheme="outline"></i>Print</button>
                        </div>
                        <nz-divider></nz-divider>
                        <div style="max-width:100%; overflow:scroll;border-color: black;"
                            *ngIf="this.ReceivedOrdersTableDataList?.length > 0">
                            <nz-table id="print-section-receivedorders" nzSize="small" style="width: 100%;page-break-inside: auto;"
                                [nzScroll]="{ x: '800px',y:'515px' }" [nzData]="this.ReceivedOrdersTableDataList"
                                #basicTable2 [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
                                nzShowSizeChanger [nzPageSize]="20" [nzPageSizeOptions]=[20,50,100,200,500]
                                [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzWidth="80px">Sale Order Date</th>
                                        <th nzWidth="80px" *ngIf="this.ReceivedOrdersRequest.DataType == 'byorderqty'">
                                            Total Order QTY</th>
                                        <th nzWidth="80px"
                                            *ngIf="this.ReceivedOrdersRequest.DataType == 'byNumberOfOrders'">Number of
                                            Orders</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable2.data;"
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto">
                                        <td style="border-color: black;">{{ data.ReceivedDate | date:'dd-MM-yyyy' }}
                                        </td>
                                        <td style="border-color: black;">{{ data.TotalOrderQty }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                </nz-tabset>
            </nz-tab>
        </nz-tabset>
    </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>