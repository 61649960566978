<div *ngIf="this.IssueSlipProducts.length > 0" id="pdfTable" style="border:1px solid;background-color: white;">
  <div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
    <p style="font-size: 14px;"><b>{{this.Company.CompanyName}}</b></p>
  </div>
  <div style="text-transform: uppercase; font-size: 12px; text-align: center; color: black; border: 1px solid black;">
    <b>Software Generated Material Issue Slip No: <b>{{IssueSlipProducts[0].IssueSlipNumber}}</b></b>
  </div>
  <!-- <nz-descriptions *ngIf="IssueSlipProducts.length > 0" nzBordered nzSize="small" [nzColumn]="2"
    style="border: 1px solid black;">
    <nz-descriptions-item nzTitle="Issue Slip No."
      style="font-size: x-small;"><b>{{IssueSlipProducts[0].IssueSlipNumber}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Requested Date Time"><b>{{IssueSlipProducts[0].SlipGeneratedDate |
        DatetimeConverter}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Requested By"></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Store/Department Name"><b>{{IssueSlipProducts[0].ToStoreName}}</b></nz-descriptions-item>
  </nz-descriptions> -->
  <div class="producttable-container">
    <table class="producttable" style="text-transform: uppercase;">
      <tbody>
        <tr>
          <td><b>TO/My:</b> Store Name: <b>{{IssueSlipProducts[0].ToStoreName}}</b></td>
          <td><b>FROM:</b> Store Name: <b>{{IssueSlipProducts[0].FromStoreName}}</b></td>
        </tr>
        <tr>
          <td>Requested By:</td>
          <td>Date Time: <b>{{IssueSlipProducts[0].SlipGeneratedDate | DatetimeConverter}}</b></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="producttable-container">
    <table class="producttable" style="font-size: smaller;">
      <thead>
        <tr>
          <th width="50px">S.No.</th>
          <th>ITEM NAME</th>
          <th>UNIT</th>
          <th>ORDER NO.</th>
          <th>DEMAND QTY</th>
          <th>ISSUED QTY</th>
          <th>REMARKS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of IssueSlipProducts;let i=index">
          <td>{{ data.IssueId}}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Unit }}</td>
          <td>{{ data.SaleOrderNumber }}</td>
          <td>{{ data.DemandQuantity }}</td>
          <td></td>
          <td>{{ data.Remark }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="footer">
    <table class="footer-table">
      <tr>
        <td width="25%">
          <p style="margin-bottom:5px;margin-top: 22px;"><b> PREPARED BY:</b></p>
          <p style="margin-bottom: 20px;font-style: italic; font-weight: bolder;font-family:cursive;">
            <b>{{IssueSlipProducts[0].SlipGeneratedBy.toUpperCase()}}</b>
          </p>
        </td>
        <td width="25%">
          <p style="margin-bottom:5px;"><b>APPROVED BY:</b></p>
          <p style="margin-bottom: 20px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <!-- <b>{{PurchaseOrder.ApprovedBy?.Name.toUpperCase()}}</b> -->
          </p>
        </td>
        <td width="25%">
          <p style="margin-bottom:5px"><b>ISSUED BY:</b></p>
          <p style="margin-bottom: 20px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <!-- <b>{{PurchaseOrder.ApprovedBy?.Name.toUpperCase()}}</b> -->
          </p>
        </td>
        <td width="25%">
          <p style="margin-bottom:5px"><b>RECEIVED BY:</b></p>
          <p style="margin-bottom: 20px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <!-- <b>{{PurchaseOrder.ApprovedBy?.Name.toUpperCase()}}</b> -->
          </p>
        </td>
      </tr>
    </table>
    <p>Powered By: KanzenFlow</p>
  </div>
</div>