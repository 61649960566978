import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { IssueProductActionModel, IssueProductModel, IssueProductViewModel, SearchParamsProductIssueModel } from '../../Models/IssueModel';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, RackModel, StoreModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { SaleOrderModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { UserModel } from 'src/PmsUIApp/Models/UserModel';

@Component({
  selector: 'app-Issuelist',
  templateUrl: './Issuelist.component.html',
  styleUrls: ['./Issuelist.component.css']
})
export class IssueListComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  ProductionList: IssueProductModel[] = [];
  ProductionListView: IssueProductViewModel[] = [];
  ProductionListOriginal: IssueProductModel[] = [];
  IssuePrintList: any[] = [];
  Production: IssueProductActionModel = new IssueProductActionModel();
  isVisible = false;
  isSaleOrderViewVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Issue";
  searchValue = '';
  myDateValue: Date = new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7));
  toDate: Date = new Date(new Date().setHours(23, 59, 59));
  saleOrderNumber = '';
  isValidDate: any
  visible = false;
  RackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  fields: IssueProductViewModel = new IssueProductViewModel();
  totalItemsCount: number = 0;
  request: SearchParamsProductIssueModel = new SearchParamsProductIssueModel();
  exportoptions = {
    headers: [
      "Issue Serial No.",
      "Issue Slip No.",
      "Issue Type",
      "Product",
      "Sale Order No.",
      "Quantity",
      "Batch No.",
      "From Store",
      "To Store",
      "Remark",
      "Added Date",
      "Added By",
      "Action Date",
      "Action By",
      "Status"
    ]
  };
  exportfields: IssueProductViewModel[] = [];
  count: 0;
  permission = {
    View: false,
    Add: false
  }
  Orderlist: SaleOrderModel;
  showPrintBtn: boolean;
  FilteredProductCategoryList: ProductCategoryModel[];
  FilteredProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  FilteredProductSecSubCategoryList: ProductSecSubCategoryModel[];
  ProductCategoryList: ProductCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[];
  MeasureUnits: MeasureUnitModel[];
  ProductList: ProductModel[];
  SupplierList: SupplierModel[];
  ToStoreID: number = 0;
  FromStoreID: number = 0;
  StoreList: StoreModel[];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  SelectedProductType: string = '';
  SelectedProductId: number = 0;
  SelectedUnit: string = '';
  FilteredProductList: ProductModel[];
  UserList: UserModel[];
  IsMsgShow: boolean = false;
  filterPanelTxt: string = 'Show Filters'
  IsAdminActionEnabled: boolean = false;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient,
    private alertService: AlertMessageService, private modalService: NzModalService, private auth: AuthService, private router: Router, private loader: LoadingService) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Issue, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Issue, Responsibility.Add);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.IsAdminActionEnabled = UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase().includes('super admin')).length > 0 || UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase().includes('admin')).length > 0 ? true : false;
    this.GetAllProduction();
    this.GetAllRack();
  }

  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;
      this.RackListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllRack();
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  GetAllProduction() {
    var filterData = {
      DateFrom: this.myDateValue,
      DateTo: this.toDate,
      SaleOrderNumber: this.saleOrderNumber,
      Status: ''
    }
    this.request.ProductType = this.SelectedProductType ?? '';
    this.request.ProductCategoryId = this.CategoryID ?? 0;
    this.request.ProductFirstSubCategoryId = this.FirstCategoryID ?? 0;
    this.request.ProductSecSubCategoryId = this.SecondCategoryID ?? 0;
    this.request.ProductId = this.SelectedProductId ?? 0;
    this.request.Unit = this.SelectedUnit ?? '';
    this.request.ToStoreId = this.ToStoreID ?? 0;
    this.request.FromStoreId = this.FromStoreID ?? 0;
    this.ProductionList = [];
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "issueproduct/GetIssueProductRequests";
    this.http.post<IssueProductModel[]>(url, this.request).subscribe(res => {
      console.log(`res`, res)
      this.ProductionList = res;
      this.ProductionListOriginal = res;
      this.ProductionListView = res
      this.isTableLoading = false;
      this.exportfields = [];
      this.ProductionListView.forEach((x) => {
        count++;
        this.fields = new IssueProductViewModel()
        this.fields.IssueId = x.IssueId
        this.fields.IssueSlipNumber = x.IssueSlipNumber
        this.fields.IssueType = x.SaleOrderNumber == null ? "Store" : "WorkPlan"
        this.fields.ProductName = x.ProductName
        this.fields.SaleOrderNumber = x.SaleOrderNumber == null ? "" : x.SaleOrderNumber
        this.fields.Quantity = x.Quantity
        this.fields.Batch = x.Batch
        this.fields.FromStoreName = x.FromStoreName
        this.fields.ToStoreName = x.ToStoreName
        this.fields.Remark = x.Remark == null ? "" : x.Remark
        this.fields.CreatedDate = x.CreatedDate
        this.fields.CreatedBy = x.CreatedBy
        this.fields.ActionDate = x.ActionDate == null ? "" : x.ActionDate
        this.fields.ActionBy = x.ActionBy == null ? "" : x.ActionBy
        this.fields.Status = x.Status
        this.exportfields.push(this.fields);
      })
      this.totalItemsCount = count;
      this.ProductionList.forEach((x) => {
        x.IsDisabled = x.IssueSlipId == null ? false : true;
      })


    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProduction();
      }
    });
  }
  OpenViewPop(data: IssueProductModel) {
    this.isVisible = true;
    this.Production.IssueId = data.IssueId;
    this.Production.IssueQuantity = data.DemandQuantity;
    this.Production.DemandQuantity = data.DemandQuantity;
    this.RackList = this.RackListOriginal.filter(x => x.StoreId == data.ToStore)
    this.Production.RackId = this.RackList[0].RackId;
    //this.Production = data;

  }

  handleOk(): void {

    //this.isLoading = true;
    //this.Save();

  }
  //calculateTotal() {
  //  return this.Production.ProductionProduct.reduce((accum, curr) => accum + curr.Amount, 0);
  //}
  handleCancel(): void {
    this.isVisible = false;
  }
  reset(): void {
    this.searchValue = '';
    this.isLoading = false
    this.search();
    this.GetAllProduction();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }



  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ProductionListOriginal;
    var res2 = this.ProductionListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: IssueProductModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.ActionDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.ActionDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ProductionList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ProductionList = res.filter(
        (item: IssueProductModel) =>
          item?.ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Quantity == +this.searchValue ||
          item?.FromStoreName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Status?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ToStoreName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ActionBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Remark?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }






  OnSubmit(type: string) {
    this.isLoading = true;
    this.Production.Status = type;
    this.Production.ActionBy = UserInfo.EmailID;
    //this.Production.ActionDate = "08/03/2022"//new Date().toString();
    var requestdata: IssueProductActionModel[] = [];
    requestdata.push(this.Production);
    let url = this.ApiUrl + "issueproduct/actionissueproductrequest";
    this.http.post<any>(url, this.Production).subscribe({
      next: res => { this.alertService.success(res); this.isLoading = false; this.handleCancel(); this.GetAllProduction() },
      error: res => {
        console.log(res.error);
        this.alertService.error(res.error); this.isLoading = false;
      },
    });

  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'issue-export' + exportdate,
        this.exportoptions
      );


  }
  onUp(key: any, data: any) {
    data = data.sort((a: any, b: any) =>
      a[key] > b[key] ? 1 : -1
    );
  }
  onDown(key: any, data: any) {
    data = data.sort((a: any, b: any) =>
      a[key] < b[key] ? 1 : -1
    );
  }
  OpenSaleOrderViewPop(data: any) {
    this.loader.show();
    let url =
      this.ApiUrl + 'saleorder/getsaleorderdataforviewbyid/' + data.SaleOrderId;

    this.http.get<any>(url).subscribe((res) => {
      this.Orderlist = res;
      this.isSaleOrderViewVisible = true;
      this.calclulateTotalSalePrice();
      this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
      this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach(
        (element) => {
          this.Orderlist.SaleOrderProduction.RawGSMTotal +=
            parseFloat(element.Quantity.toString()) *
            (isNaN(parseInt(element.AvgGsm?.toString()))
              ? 0
              : parseInt(element.AvgGsm?.toString()));
        }
      );
      this.Orderlist.SaleOrderProduction.MixingGSMTotal =
        this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
      this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(
        (element) => {
          this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(
            element.Total
          )
            ? 0
            : element.Total;
          this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(
            parseInt(element.WeightGsm?.toString())
          )
            ? 0
            : parseInt(element.WeightGsm.toString());
          this;
        }
      );
      this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal =
        this.Orderlist.SaleOrderProduction.MixingGSMTotal +
        this.Orderlist.SaleOrderProduction.RawGSMTotal;
      var totalprint =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalprint);
      var totalEmbossing =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalEmbossing);
      var totalVaccum =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalVaccum);
      var totalTumbling =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(totalTumbling);
      //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
      //console.log(totalLacquer);
      var totalElement =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce(
          (sum, current) => sum + parseFloat(current.Total?.toString()),
          0
        ) ?? 0;
      console.log(isNaN(totalElement) ?? 0);
      var totalRaw =
        this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce(
          (sum, current) => sum + parseFloat(current.TotalCost?.toString()),
          0
        ) ?? 0;
      console.log(totalRaw);
      this.Orderlist.GrandTotal =
        parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
        parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
        parseFloat(isNaN(totalprint) == true ? '0' : totalprint.toString()) +
        parseFloat(
          isNaN(totalEmbossing) == true ? '0' : totalEmbossing.toString()
        ) +
        parseFloat(isNaN(totalVaccum) == true ? '0' : totalVaccum.toString()) +
        parseFloat(
          isNaN(totalTumbling) == true ? '0' : totalTumbling.toString()
        ) +
        /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
        parseFloat(
          isNaN(totalElement) == true ? '0' : totalElement.toString()
        ) +
        parseFloat(totalRaw.toString());
      console.log(this.Orderlist);
      this.loader.hide();
    });
  }
  calclulateTotalSalePrice() {
    this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
  }
  handleSaleOrderCancel(): void {
    this.isSaleOrderViewVisible = false;
  }
  add() {
    var selectedIssueRequest = this.ProductionList.filter(item => item.IsChecked).map(
      finalResult => {
        return {
          IssueId: finalResult.IssueId
        };
      });
    this.IssuePrintList = selectedIssueRequest;

    if (this.IssuePrintList.length > 0) {
      this.showPrintBtn = true;

      // Find the 'ToStore' value of the first selected item
      const toStoreValue = this.ProductionList.find(item => item.IsChecked)?.ToStore;

      // Disable all items that don't have the same 'ToStore' value
      this.ProductionList.forEach(item => {
        if (item.ToStore !== toStoreValue) {
          item.IsDisabled = true;
        }
      });

    } else {
      this.showPrintBtn = false;
      // If no items are selected, ensure all items are enabled
      this.ProductionList.forEach((item) => {
        if (item.IssueSlipId == null) {
          item.IsDisabled = false
        }
      });
    }
  }
  GenerateAndPrintIssueSlip(): void {
    this.loader.show();

    let url = this.ApiUrl + 'issueproduct/addissuesliprequest';
    this.http.post<any>(url, this.IssuePrintList).subscribe({
      next: (res) => {
        this.PrintSlip(res);
        this.isLoading = false;
        this.showPrintBtn = false;
        this.GetAllProduction();
        this.loader.hide();
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;
        this.GetAllProduction();
        this.loader.hide();
      },
    });
  }
  PrintSlip(IssueSlipId: number) {
    window.open(`${window.location.origin}/issueslipprint/` + IssueSlipId);
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllUnits();
      this.GetAllProducts();
      this.GetAllProductCategory();
      this.GetAllStore();
      this.GetAllUsers();
      this.filterPanelTxt = 'Hide Filters';
    }
    else {
      this.filterPanelTxt = 'Show Filters';
    }
  }
  GetAllUnits() {
    this.loader.show();
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllUnits()
      }
    });
  }
  GetAllProducts() {
    this.loader.show();
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) { this.GetAllProducts() }
    });
  }

  GetAllProductCategory() {
    this.loader.show();
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllProductCategory()
      }
    });
  }
  onSelectedProductTypeChange() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
    this.loader.hide();
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductCategoryId == this.CategoryID);

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductFirstSubCategoryId == this.FirstCategoryID);

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetSecondCategoryFilteredProduct() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductSecSubCategoryId == this.SecondCategoryID);
    this.loader.hide();
  }
  GetAllStore() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
        this.isTableLoading = false;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  enableIssueSelect(data: IssueProductModel) {
    if (data.Status == 'Pending' && (data.CreatedBy == UserInfo.EmailID || this.IsAdminActionEnabled))
      return true;
    else
      return false;
  }
}
