import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, DeptModel } from '../../Models/MasterModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../Models/ProductModel';
import { PurchaseOrderModel, PurchaseOrderProductModel } from '../../Models/PurchaseOrderModel';
import { SupplierModel } from '../../Models/SupplierModel';
import { TransportModel } from '../../Models/TransportModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { UserInfo } from '../../Authentication/UserInfo';
import { Subscription } from 'rxjs';
import { UserModel } from '../../Models/UserModel';
import { LoadingService } from '../../Services/loadingService';
import { DemandModel } from '../../Models/DemandModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { KnittingDivisionStockModel } from 'src/PmsUIApp/Models/KnittingDivisionStockModel';

@Component({
  selector: 'app-purchaseorder',
  templateUrl: './purchaseorder.component.html',
  styleUrls: ['./purchaseorder.component.css']
})
export class PurchaseorderComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  FilteredProductList: ProductModel[] = [];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  TransportList: TransportModel[] = [];
  DeliveryTermList: DeliveryTermModel[] = [];
  PaymentTermList: PaymentTermModel[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  CurrencyList: string[] = [];
  NewPO: PurchaseOrderModel = new PurchaseOrderModel;
  selectedproduct: any;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: 'NA',
    IGST: 0,
    Rate: 0,
    Quantity: 0,
    Amount: 0,
    Currency: 'INR'
  }
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  private route$: Subscription = new Subscription;
  PoId = 0;
  ActionType = '';
  IsDisabled = false
  UserList: UserModel[] = [];
  SelectedProductType: string = '';
  DemandList: DemandModel[] = [];
  FabricStockList: KnittingDivisionStockModel[] = [];
  IsEditing: boolean = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  DeptList: DeptModel[];
  count: any;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private auth: AuthService, private loader: LoadingService) { }
  handleOk(): void {

    this.isLoading = true;

    this.Save();
  }
  Save() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;

      return;
    }
    if (this.prolist.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please add Items");
      return;
    }
    if (this.prolist.filter(x => x.Rate <= 0).length > 0) {
      this.isLoading = false;
      this.alertService.error("Add Product Rate");
      return;
    }
    if (this.NewPO.Remarks.length > 150) {
      this.isLoading = false;
      this.alertService.error("Remarks cannot be more than 150 charachers");
      return;
    }
    //this.NewGateIn.SupplierId=this.f['SupplierId'].value;
    /*this.NewGateIn.Invoice.SupplierId=this.f['SupplierId'].value;*/
    this.NewPO.SupplierId = this.f['supplierId'].value;
    this.NewPO.DeliveryTermId = this.f['deliveryTermId'].value;
    //this.NewPO.Ponumber = this.f['ponumber'].value;
    this.NewPO.TransportId = this.f['transportId'].value;
    this.NewPO.PaymentTermId = this.f['paymentTermId'].value;
    this.NewPO.PocreationDate = this.f['pocreationDate'].value;
    this.NewPO.DeliveryDate = this.f['podeliveryDate'].value;
    this.NewPO.Reference = this.f['reference'].value;
    this.NewPO.PototalAmount = this.GrandTotal.toString();
    this.NewPO.ContactPersonUserId = this.f['ContactPersonUserId'].value;
    this.NewPO.DepartmentId = this.f['departmentId'].value;
    this.NewPO.PurchaseOrderProduct = [];
    this.prolist.forEach(x => {
      var inv = new PurchaseOrderProductModel();
      inv.ProductId = x.ProductID;
      inv.Unit = x.Unit;
      inv.Rate = parseFloat(x.Rate.toString());
      inv.Quantity = parseFloat(x.Quantity.toString());
      inv.Amount = x.Amount;
      inv.Grade = x.Grade;
      inv.Igst = x.IGST;
      inv.Currency = x.Currency;
      this.NewPO.PurchaseOrderProduct.push(inv);
    })
    if (this.ActionType == 'adddemand') {
      this.NewPO.Demand = this.DemandList;
    }


    let url = this.ApiUrl + "purchaseorder/addpurchaseorder";
    if (this.ActionType == "edit") {
      url = this.ApiUrl + "purchaseorder/updatepurchaseorder";
    }
    this.http.post<any>(url, this.NewPO).subscribe({

      next: res => {
        if (res == 'An error has occured. Please contact administrator') {
          this.alertService.error(res); this.isLoading = false;
          return;
        }
        this.alertService.success("Entry Saved Successfully"); this.isLoading = false;
        this.prolist = [];
        this.router.navigate(['/home/po/list'])
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },

    });

  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.PoId = params["id"];
      this.ActionType = params["type"];
    })
    this.validateForm = this.fb.group({
      supplierId: [null, [Validators.required]],
      deliveryTermId: [null, [Validators.required]],
      departmentId: [null, [Validators.required]],
      /*ponumber: [null, [Validators.required]],*/
      transportId: [null],
      paymentTermId: [null, [Validators.required]],
      pocreationDate: [new Date().toUTCString(), [Validators.required]],
      podeliveryDate: [new Date().toUTCString(), [Validators.required]],
      reference: [null, [Validators.maxLength(100)]],
      Currency: ['INR', [Validators.required]],
      ContactPersonUserId: [null, [Validators.required]]
    });
    this.GetAllSuppliers();
    this.GetAllPaymentTerm();
    this.GetAllDeliveryTerm();
    this.GetAllProductCategory();
    this.GetAllTransport();
    this.GetAllUnits();
    this.GetAllCurrency();
    this.GetAllProducts();
    this.GetAllUsers();
    this.GetAllDept();
    if (this.ActionType == 'adddemand') {
      this.GetAllDemand()
    }
    else if (this.ActionType == 'addfabricstock') {
      this.GetSelectedFabricStock();
    }
    else if (this.ActionType != undefined && this.ActionType != '') {
      this.GetAllPurchaseOrder();
    }

  }

  get f() { return this.validateForm.controls; }
  GetAllPurchaseOrder() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/" + this.PoId;

    this.http.get<PurchaseOrderModel>(url).subscribe(
      (res) => {
        this.NewPO = res;
        this.validateForm.setValue(
          {
            supplierId: this.NewPO.SupplierId,
            deliveryTermId: this.NewPO.DeliveryTermId,
            transportId: this.NewPO.TransportId,
            departmentId: this.NewPO.DepartmentId,
            paymentTermId: this.NewPO.PaymentTermId,
            pocreationDate: this.NewPO.PocreationDate,
            podeliveryDate: this.NewPO.DeliveryDate,
            reference: this.NewPO.Reference,
            Currency: this.NewPO.PurchaseOrderProduct[0]?.Currency,
            ContactPersonUserId: this.NewPO.ContactPersonUserId
          }

        )
        this.prolist = [];
        this.NewPO.PurchaseOrderProduct.forEach(x => {
          var p = new productItem();
          p.Amount = x.Amount;
          p.Grade = x.Grade;
          p.IGST = x.Igst;
          p.Currency = x.Currency;

          p.ProductID = x.ProductId;
          p.ProductName = x.ProductName;
          p.Quantity = x.Quantity;
          p.Rate = x.Rate;
          p.Unit = x.Unit;
          this.prolist.push(p);
        })
        this.GrandTotal = this.prolist.reduce((sum, current) => sum + parseFloat(current.Amount?.toString()), 0) ?? 0
        this.GrandTotal = parseFloat(this.GrandTotal.toFixed(3));
        this.loader.hide();

      },
      (res) => { this.GetAllPurchaseOrder() }
    );
  }
  GetAllDemand() {
    var demands = localStorage.getItem("DemandIds");
    console.log(demands);

    if (demands != null) {
      let url = this.ApiUrl + "purchaseorder/getalldemands";
      this.http.get<DemandModel[]>(url).subscribe(res => {
        //res.forEach(x => {
        //  if (demands.includes(x.DemandId.toString())) {
        //    this.DemandList.push(x);
        //  }
        //})
        this.DemandList = res.filter(item1 => JSON.parse(demands).some(item2 => item1.DemandId === item2.DemandId));

        let unique = [...new Set(this.DemandList.map(item => item.ProductId))];
        unique.forEach(x => {
          var item = new productItem;
          item.ProductID = x;
          item.ProductName = this.DemandList.filter(i => i.ProductId == x)[0]?.ProductName;
          item.Quantity = this.DemandList.filter(i => i.ProductId == x).reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0;
          item.Unit = this.DemandList.filter(i => i.ProductId == x)[0]?.Unit;
          item.Currency = this.f['Currency'].value;
          this.prolist.push(item)
        })

        localStorage.removeItem("DemandIds");
      }, res => {
        this.GetAllDemand();
      });
    }
  }
  GetSelectedFabricStock() {
    var fabricStocks = localStorage.getItem("FabricStockIds");
    var fabricStockListJson = JSON.parse(fabricStocks);

    var fabricStockIds = [];
    if (Array.isArray(fabricStockListJson) && fabricStockListJson.length) {
      fabricStockIds = fabricStockListJson.map(item => item.FabricStockId);
    } else {
      fabricStockIds = [];
    }

    if (fabricStocks != null) {
      let url = this.ApiUrl + "iot/getknittingfabricstocksbyids";
      this.http.post<KnittingDivisionStockModel[]>(url, fabricStockIds).subscribe(res => {
        //res.forEach(x => {
        //  if (demands.includes(x.DemandId.toString())) {
        //    this.DemandList.push(x);
        //  }
        //})
        this.FabricStockList = res;

        let unique = [...new Set(this.FabricStockList.map(item => item.ProductId))];
        unique.forEach(x => {
          var item = new productItem;
          item.ProductID = x;
          item.ProductName = this.FabricStockList.filter(i => i.ProductId == x)[0]?.ProductName;
          item.Quantity = this.FabricStockList.filter(i => i.ProductId == x).reduce((sum, current) => sum + parseFloat(current.Weight?.toString()), 0) ?? 0;
          item.Unit = this.FabricStockList.filter(i => i.ProductId == x)[0]?.Unit;
          item.Currency = this.f['Currency'].value;
          item.Grade = 'NA';
          item.IGST = 0;
          this.prolist.push(item)
        })

        // localStorage.removeItem("FabricStockIds");
      }, res => {
        this.GetSelectedFabricStock();
      });
    }
  }
  GetAllSuppliers() {
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
    }, res => { this.GetAllSuppliers() });
  }
  GetAllDeliveryTerm() {

    let url = this.ApiUrl + "deliveryterm/getalldeliveryterms";
    this.http.get<DeliveryTermModel[]>(url).subscribe(res => {
      this.DeliveryTermList = res;

    }, res => { this.GetAllPaymentTerm() });
  }
  GetAllPaymentTerm() {

    let url = this.ApiUrl + "paymentTerm/getallpaymentterms";
    this.http.get<PaymentTermModel[]>(url).subscribe(res => {
      this.PaymentTermList = res;

    }, res => { this.GetAllPaymentTerm() });
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => { this.GetAllUnits() });
  }
  GetAllCurrency() {
    let url = this.ApiUrl + "data/getcurrencylist";
    this.http.get<string[]>(url).subscribe(res => {
      this.CurrencyList = res;
    }, res => { this.GetAllCurrency() });
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      const filteredProductCategoryIds = this.FilteredProductList.map(x => x.ProductCategoryId);
      this.ProductCategoryList = res.filter(x => filteredProductCategoryIds.includes(x.ProductCategoryId));
    }, res => { this.GetAllProductCategory() });
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    if (data != null) {
      let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
      this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
        this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
        this.productItem.FirstCategoryID = id;
        this.FilteredProductList = this.ProductList.filter(x => x.ProductCategoryId == data)
      }, res => { });
    }
    else {
      this.FilteredProductList = this.ProductList;
    }
  }
  GetAllSecondCategory(data: any, id: number = 0) {


    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.productItem.SecondCategoryID = id;
      this.FilteredProductList = this.ProductList.filter(x => x.ProductFirstSubCategoryId == data)
    }, res => { });
  }
  FilterSecSubCategory(data: any) {
    this.FilteredProductList = this.ProductList.filter(x => x.ProductSecSubCategoryId == data)
  }
  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.FilteredProductList = this.ProductList;
    }, res => { this.GetAllProducts(); });
  }

  UpdateProduct(data: any) {
    console.log(this.selectedproduct)
    if (data != null) {
      this.selectedunit = data.Unit;
      this.SelectedProductType = data.ProductType;
      this.productItem.ProductName = data.ProductName;
      this.productItem.ProductID = data.ProductId;
      this.productItem.CategoryID = data.ProductCategoryId;
      let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
      this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
        this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.ProductCategoryId);
        this.productItem.FirstCategoryID = data.ProductFirstSubCategoryId;
        let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
          this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId);
          this.productItem.SecondCategoryID = data.ProductSecSubCategoryId

        }, res => { });


      }, res => { });
      this.GetAllFirstCategory(data.ProductCategoryId, data.ProductFirstSubCategoryId);
      this.GetAllSecondCategory(data.ProductFirstSubCategoryId, data.ProductSecondSubCategoryId);
      this.IsDisabled = true;
    } else {
      this.IsDisabled = false;
      this.FilteredProductList = this.ProductList;
    }
    //this.productItem.UnitID = data.ProductName;
  }
  UpdateUnit(data: any) {


    this.productItem.Unit = this.selectedunit;
    this.productItem.UnitID = data.Id;
    //this.productItem.UnitID = data.ProductName;

  }
  GetAllTransport() {


    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe(res => {
      this.TransportList = res;

    }, res => { this.GetAllTransport(); });
  }
  OnRatechange(data: any) {
    var amt = data * this.productItem.Quantity;
    this.productItem.Amount = parseFloat(amt.toFixed(3));
  }
  OnQtychange(data: any) {
    var amt = data * this.productItem.Rate;
    this.productItem.Amount = parseFloat(amt.toFixed(2));
  }
  btnAddClick() {
    if (!this.Validate(this.productItem)) {
      return;
    }
    this.IsDisabled = false;
    var Item = {
      CategoryID: this.productItem.CategoryID,
      FirstCategoryID: this.productItem.FirstCategoryID,
      SecondCategoryID: this.productItem.SecondCategoryID,
      ProductID: this.productItem.ProductID,
      ProductName: this.productItem.ProductName,
      UnitID: this.productItem.UnitID,
      Unit: this.selectedunit,
      AvgGsm: "",
      IGST: this.productItem.IGST,
      Rate: this.productItem.Rate,
      Quantity: this.productItem.Quantity,
      Amount: this.productItem.Amount,
      Grade: this.productItem.Grade,
      Currency: this.f['Currency'].value
    }
    if (this.IsEditing) {
      var data = this.prolist.filter(x => x.ProductID == this.productItem.ProductID)[0]
      this.prolist = this.prolist.filter(obj => obj !== data);
      this.IsEditing = false;
    }
    this.prolist.push(Item)

    this.SubTotal = this.GrandTotal = 0
    this.prolist.forEach((element) => {
      this.SubTotal += parseFloat(element.Rate.toString());
      this.GrandTotal += parseFloat(parseFloat(element.Amount.toFixed(3)).toString());
    });
    this.productItem = {
      CategoryID: 0,
      FirstCategoryID: 0,
      SecondCategoryID: 0,
      ProductID: 0,
      ProductName: '',
      UnitID: 0,
      Unit: '',
      Grade: 'NA',
      IGST: 0,
      Rate: 0,
      Quantity: 0,
      Amount: 0,
      Currency: 'INR'
    }
    this.selectedunit = '';
    this.selectedproduct = '';
    this.GetAllProducts();

  }
  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ProductID == '0') {
      this.alertService.error("Select Product"); Isvalid = false;
    }
    else if (model.Rate == '0') {
      this.alertService.error("Enter Rate"); Isvalid = false;
    }
    else if (model.Quantity == '0') {
      this.alertService.error("Enter Quantity"); Isvalid = false;
    }
    else if (model.IGST == '') {
      this.alertService.error("Enter IGST"); Isvalid = false;
    }
    if (!this.IsEditing) {
      if (this.prolist.filter(x => x.ProductID == model.ProductID).length > 0) {
        this.alertService.error("Product already added."); Isvalid = false;
      }
    }
    return Isvalid;
  }
  handleRemoveRow(data: any) {
    this.prolist = this.prolist.filter(obj => obj !== data);
    this.SubTotal = this.GrandTotal = 0
    this.prolist.forEach((element) => {
      this.SubTotal += parseFloat(element.Rate.toString());
      this.GrandTotal += parseFloat(parseFloat(element.Amount.toFixed(3)).toString());
    });
  }
  editRow(data: any) {
    this.productItem = this.prolist.filter(obj => obj == data)[0];
    this.selectedproduct = this.FilteredProductList.filter(x => x.ProductId == this.productItem.ProductID)[0];
    this.UpdateProduct(this.selectedproduct)
    this.IsEditing = true;
  }
  GetAllUsers() {
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        console.log(res);

        this.UserList = res;

        this.UserList.forEach(el => {
          el.Roles = el.UserRole.map(x => x.UserRoleName).join(',');

        })

        this.validateForm.get('ContactPersonUserId').setValue(this.UserList.filter(x => x.Name == "Fahad Mohammad kaisar")[0]?.UserId);

      },
      (res) => {
        this.GetAllUsers();
      }
    );
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
    this.GetAllProductCategory();
  }
  GetAllDept() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "dept/getalldepts";
    this.http.get<DeptModel[]>(url).subscribe(res => {
      this.DeptList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllDept();
      }
    });
  }
}
