import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';


import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, Observable, Subscription } from 'rxjs';

import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { CompanyInfo } from '../../Authentication/UserInfo';
import { SupplierModel } from '../../Models/SupplierModel';
import jspdf from 'jspdf';  
import html2canvas from 'html2canvas'; 
import { ISasToken } from 'src/PmsUIApp/azure-storage/azureStorage';
import { BlobStorageService } from 'src/PmsUIApp/azure-storage/blob-storage.service';


@Component({
  selector: 'app-poemail',
  templateUrl: './poemail.component.html',
  styleUrls: ['./poemail.component.css']
})
export class POEmailComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  EmailList: string[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  NewSupplier: SupplierModel = new SupplierModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production";
  searchValue = '';
  NewEmail = '';
  PrintTableLength: number = 0;
  visible = false;
  private route$: Subscription = new Subscription;
  token: any;
  Poid = 0;
  TotalWord = '';
  Company={
    CompanyName: '',
    Contact : '',
    Email: '',
    Address: '',
    GST: '',
    };
  

  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private blobStorage: BlobStorageService,
    private router: Router) { }


  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.Poid = params["id"];
    })
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.Contact = CompanyInfo.Contact;
    this.Company.Email = CompanyInfo.Email;
    this.Company.Address = CompanyInfo.Address.replace("\n", "<br>");
    this.Company.GST = CompanyInfo.GST;
    // this.Company.Website = CompanyInfo.Website;
    this.GetAllPurchaseOrder();
  }
  
  GetAllPurchaseOrder() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/"+ this.Poid;
    this.http.get<PurchaseOrderModel>(url).subscribe(res => {
      this.PurchaseOrder = res;
      if (this.PurchaseOrder.Status == 'New' || this.PurchaseOrder.Status == 'Cancelled') {
        this.router.navigate(['/home/unauthorized']);
        return;
      }
      this.PurchaseOrder.PurchaseOrderProduct.forEach(x => {
        x.IgstTotal = (x.Amount * x.Igst) / 100;
        x.Total = x.Amount + x.Igst;
        this.PurchaseOrder.PototalTax += x.IgstTotal;
        this.PurchaseOrder.Pograndtotal += x.Total;
      })

      if (this.PurchaseOrder.PurchaseOrderProduct.length < 8) {
        this.PrintTableLength = 8
      }
      else {
        this.PrintTableLength = 2
      }

      this.PurchaseOrder.PototalTax = this.PurchaseOrder.PurchaseOrderProduct.reduce((sum, current) => sum + current.IgstTotal, 0);
      this.PurchaseOrder.PototalTax = parseFloat(this.PurchaseOrder.PototalTax.toFixed(3))
      this.PurchaseOrder.PototalAmount = parseFloat(this.PurchaseOrder.PototalAmount).toFixed(3)
      this.PurchaseOrder.Pograndtotal = (this.PurchaseOrder.PurchaseOrderProduct.reduce((sum, current) => sum + current.IgstTotal + current.Amount, 0)).toFixed(3);
      this.GetAllSupplier();
      var converter = require('number-to-words');
      this.TotalWord = converter.toWords(this.PurchaseOrder.Pograndtotal).toUpperCase()
      console.log(this.PurchaseOrder)
    }, res => {    this.GetAllPurchaseOrder();});
  }
  
  GetAllSupplier() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.NewSupplier = res.filter(x => x.SupplierId == this.PurchaseOrder.SupplierId)[0]
      this.EmailList.push(this.NewSupplier.Email);
    }, res => { });
  }
  
  public captureScreen()  
  {  
    this.isLoading = true;
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4', true); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0 , position, imgWidth, imgHeight, undefined,'FAST')  
      var pfile = pdf.output('blob'); 
      this.uploadFile(<File>pfile);
    });  
  }  

  validateEmail(){
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return !expression.test(this.NewEmail); // true
  }

  RemoveEmail(data) {
    var index = this.EmailList.indexOf(data);
    if (index !== -1) {
      this.EmailList.splice(index, 1);
    }
  }
  uploadFile(file: File) {
    // let url = this.ApiUrl + "data/getstoragetokenforpoupload";
    // this.http.get<any>(url).subscribe(res => {
    //   this.token = res;
    //   const accessToken: ISasToken = {
    //     container: this.token.StorageContainerName,
    //     filename: this.PurchaseOrder.Poid +'_'+ this.PurchaseOrder.Ponumber + '_' + file.name,
    //     storageAccessToken: this.token.StorageAccountToken,
    //     storageUri: this.token.StorageAccountHost
    //   };
    //   return this.blobStorage
    //     .uploadToBlobStorage(accessToken, file);
    // }, res => { this.alertService.error("An error has been occured"); });
      let testData:FormData = new FormData();
      testData.append('file_upload', file, this.PurchaseOrder.Ponumber.toString());
      testData.append('POid', this.PurchaseOrder.Poid.toString());
      testData.append('EmailList', JSON.stringify(this.EmailList));
      let url = this.ApiUrl + "purchaseorder/sendpoemail";
      this.http.post(url, testData).subscribe(response => {
        this.isLoading = false;
        this.alertService.success("Email has been sent successfully");
        console.log(response);
      });
  }
}
