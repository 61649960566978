import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductionEmbossingModel } from 'src/PmsUIApp/Models/ProductionModel';
import { combineAll, from, map, Observable, Observer } from 'rxjs';
import { IUploadProgress } from '../../../Models/StockModel';
import { ISasToken } from '../../../azure-storage/azureStorage';
import { BlobStorageService } from '../../../azure-storage/blob-storage.service';
import { InterceptorSkipHeader } from 'src/PmsUIApp/Services/HttpInterceptorService';
import { StorageService } from 'src/PmsUIApp/Services/storage.service';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../../Models/Enums';
import { AuthService } from '../../../Services/auth.service';

@Component({
  selector: 'app-embossing-list',
  templateUrl: './embossing-list.component.html',
  styleUrls: ['./embossing-list.component.css']
})
export class EmbossingListComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  EmbList: ProductionEmbossingModel[] = [];
  EmbListListOriginal: ProductionEmbossingModel[] = [];
  EmbListListOriginal2: ProductionEmbossingModel[] = [];
  NewEmb: ProductionEmbossingModel = new ProductionEmbossingModel();
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Embossing";
  EmbNameError = 'Enter Embossing name';
  EmbCodeError = 'Enter Embossing Code';
  searchValue = ''
  exportoptions = {
    headers: ["EmbName", "Code", "Image, Description"]
  };
  fields: any;
  exportfields = [{
    "EmbName": "",
    "Code": "",
    "Image": "",
    "Description": "",
    "EmbossingMasterId": ""
  }];
  StorageAccContainerName = 'productionembossing';
  getToken: any;
  postToken: any;
  uploadProgress$: Observable<IUploadProgress[]> | undefined;
  filesSelected = false;
  uploadfile: any;
  uploadinvoice: string = '';
  ImageArray: any[] = [];
  FilteredImageArray: any[] = [];
  isImageVisible = false;
  count: number;
  IsNewFile = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }

  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private blobStorage: BlobStorageService, private storage: StorageService
    , private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Embossing, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Embossing, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Embossing, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      EmbName: [null, [Validators.required, Validators.minLength(3)], [this.VaccumNameAsyncValidator]],
      Code: [null, [Validators.required], [this.VaccumCodeAsyncValidator]],
      Image: [null],
      Description: [null, [Validators.maxLength(200)]]


    });
    this.GetAllEmbossing();
    // this.getStorageSASToken(this.StorageAccContainerName);
  }
  get f() { return this.validateForm.controls; }

  GetAllEmbossing() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "embossing/getallembossings";
    this.http.get<ProductionEmbossingModel[]>(url).subscribe(res => {
      this.EmbList = res;
      this.EmbListListOriginal = res;
      this.EmbListListOriginal2 = res;
      this.exportfields = [];
      this.EmbListListOriginal.forEach(x => {

        this.fields = {};
        this.fields.EmbName = x.Name;
        this.fields.Code = x.Code;
        this.fields.ImageName = x.ImageName;
        this.fields.Description = x.Description;
        this.fields.EmbossingMasterId = x.EmbossingMasterId
        this.exportfields.push(this.fields);

      })
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllEmbossing(); }
    });
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search() {

    var res = this.EmbListListOriginal2;
    this.EmbList = res.filter((item: ProductionEmbossingModel) => {


      if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.EmbossingMasterId == +this.searchValue
      ) {
        return true;
      } else if (
        item?.Code == this.searchValue
      ) {
        return true;
      }
      else if (
        item?.AddedDate?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else {
        let searchDate: any = new Date(this.searchValue);

        searchDate.setDate(searchDate.getDate() + 1);
        if (!isNaN(searchDate)) {
          searchDate = searchDate.toISOString().split('T')[0];
        }

        let splitDate = item.AddedDate.split('T')[0];

        return splitDate == searchDate ? true : false;
      }


    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  getStorageSASToken(ContainerName: string): any {
    let url = this.ApiUrl + "storage/getstoragesastoken/" + ContainerName;
    this.http.get(url, { headers: { skip: "true" } }).subscribe(res => {

      this.getToken = res;
      this.postToken = res;
    }, res => { this.getStorageSASToken(ContainerName) });
  }

  // Edit Details 
  OpenEditPop(data: ProductionEmbossingModel) {
    this.PopUpTitle = "Modify Embossing Details";
    this.validateForm.setValue(
      {
        EmbName: data.Name,
        Code: data.Code,
        Image: data.ImageName,
        Description: data.Description,

      }
    )
    this.NewEmb = new ProductionEmbossingModel();
    this.NewEmb.EmbossingMasterId = data.EmbossingMasterId;
    this.uploadfile = null;
    this.isVisible = true;
    this.IsNewFile = false;
  }


  showModal(): void {
    this.PopUpTitle = "Add New Embossing";
    this.validateForm.reset();
    this.NewEmb.Name = "";
    this.NewEmb.EmbossingMasterId = 0;

    this.NewEmb.Code = "";
    this.NewEmb.ImageName = "";
    this.NewEmb.Description = "";
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.Save();
  }
  Save() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;
      return;
    }

    const d = new Date();

    this.NewEmb.Name = this.f['EmbName'].value;
    this.NewEmb.Code = this.f['Code'].value;
    this.NewEmb.Description = this.f['Description'].value;
    this.NewEmb.AddedDate = d.toLocaleTimeString();
    let url = this.ApiUrl + 'embossing/addupdateembossing';
    this.http.post<any>(url, this.NewEmb).subscribe({
      next: (res) => {
        this.NewEmb = res;
        if (this.uploadfile) {
          this.fetchStorageTokenAndUpload();
        }
        this.alertService.success('Embossing Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllEmbossing();
        this.EmbList = [];
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  private fetchStorageTokenAndUpload(): Observable<any> {
    this.storage.getStorageSASToken(this.StorageAccContainerName)
      .subscribe(
        (token: string) => {
          this.uploadProgress$ = from(this.uploadfile as FileList).pipe(
            map(file => this.uploadFile(file, token)),
            combineAll()
          );
        },
        (error: any) => {
          console.error('Error fetching storage token:', error);
        }
      );
      return null;
  }
  // onImgupload() {
  //   this.fetchStorageToken();
  //   this.uploadProgress$ = from(this.uploadfile as FileList).pipe(
  //     map(file => this.uploadFile(file)),
  //     combineAll()
  //   );
  // }
  uploadFile(file: File, token: any): Observable<IUploadProgress> {
    if (this.IsNewFile) {
      const accessToken: ISasToken = {
        container: token.StorageContainerName,
        filename: this.NewEmb.EmbossingMasterId + '/' + file.name,
        storageAccessToken: token.StorageAccountToken,
        storageUri: token.StorageAccountHost
      };

      return this.blobStorage
        .uploadToBlobStorage(accessToken, file)
        .pipe(map(progress => this.mapProgress(file, progress)));
    } else {
      return null;
    }
  }
  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress
    };
  }
  onChange(event: any): void {
    this.filesSelected = true;
    //this.getStorageSASToken(this.StorageAccContainerName);
    this.uploadfile = event.target.files;
    this.IsNewFile = true;
    //this.oninvoiceupload();
    // this.uploadProgress$ = from(event.target.files as FileList).pipe(
    //   map(file => this.uploadFile(file)),
    //   combineAll()
    // );
  }

  GetImages($event: any) {
    this.storage.getStorageSASToken(this.StorageAccContainerName).subscribe((res: any) => {
      this.getToken = res;
      this.FilteredImageArray = this.ImageArray = [];
      var Blobtoken = this.getToken.StorageAccountToken;
      let url = this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + Blobtoken + "&restype=container&comp=list";

      const headers = new HttpHeaders().set(InterceptorSkipHeader, ''); // To exclude AD Authorization header
      this.http.get<any>(url, <Object>{ responseType: 'text', headers }).subscribe(res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res, "text/xml");
        var x = xmlDOM.documentElement.childNodes;
        for (var i = 0; i < x.length; i++) {

          if (x[i].nodeName == "Blobs") {
            for (var j = 0; j < x[i].childNodes.length; j++) {

              var str = x[i].childNodes[j].childNodes[0].childNodes[0].nodeValue ?? "";
              var arr = str.split('/')
              var item = {
                //container: arr[0],
                ID: arr[0],
                Img: arr[1],
                URL: this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + "/" + str + Blobtoken
              }
              this.ImageArray.push(item);

            }
          }
          // + ": " + x[i].childNodes[0].nodeValue + "<br>"
        }
        this.FilteredImageArray = this.ImageArray.filter(x => x.ID == $event);
        this.isImageVisible = true;
      }, res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res.error.text, "text/xml");
        const value = xmlDOM.getElementsByTagName("Blobs");

      });
    });
  }

  handleCancelImage(): void {
    this.isImageVisible = false;
  }

  // validation for Name
  VaccumNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.EmbList.filter(
          (x) => x.EmbossingMasterId != this.NewEmb.EmbossingMasterId
        );
        var nre = res.filter(
          (x) => x.Name.toLowerCase() == control.value.toLowerCase()
        );
        this.EmbNameError = '';

        if (control.value == '') {
          this.EmbNameError = 'Enter Emb name';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.EmbNameError = 'Emb name already exist';
          observer.next({ error: true, duplicated: true });
        } else {
          this.EmbNameError = 'Enter Emb name';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  // validation for Code
  VaccumCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.EmbList.filter(
          (x) => x.EmbossingMasterId != this.NewEmb.EmbossingMasterId
        );
        var nre = res.filter(
          (x) => x.Code.toLowerCase() == control.value.toLowerCase()
        );
        this.EmbCodeError = '';
        if (control.value == '') {
          this.EmbCodeError = 'Enter Embossing code';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.EmbCodeError = 'Embossing code already exist';
          observer.next({ error: true, duplicated: true });
        } else {
          this.EmbCodeError = 'Enter Embossing code';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  handleDelete(data: ProductionEmbossingModel) {
    this.NewEmb = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Embossing?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteEmbossing(this.NewEmb),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  DeleteEmbossing(data: ProductionEmbossingModel) {
    let url = this.ApiUrl + 'embossing/deleteembossing';

    this.http.post<ProductionEmbossingModel>(url, data).subscribe({
      next: (res) => {
        this.alertService.success('Embossing Deleted Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllEmbossing();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
}

