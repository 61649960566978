
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../../window.providers';

@Injectable()
export class AppConfigService {
  private appConfig: any;

  constructor(private http: HttpClient, private router: Router, @Inject(WINDOW) private window: Window) { }

  loadAppConfig() {
    return this.http.get('/assets/Config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  getConfig() {
    return this.appConfig;
  }
  getURL() {

    return this.router.url
  }
  getHostname(): string {
    return this.window.location.origin;
  }
  //   getWithExpiry(key: string) {
  //     const itemStr = localStorage.getItem(key)

  //     // if the item doesn't exist, return null
  //     if (!itemStr) {
  //       return null
  //     }

  //     const item = JSON.parse(itemStr)
  //     const now = new Date()

  //     // compare the expiry time of the item with the current time
  //     if (now.getTime() > item.expiry) {
  //       // If the item is expired, delete the item from storage
  //       // and return null
  //       localStorage.removeItem(key)
  //       return null
  //     }
  //     return item.value
  //   }
}
