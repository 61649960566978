import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { GrainModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';

@Component({
  selector: 'app-Grain',
  templateUrl: './grain.component.html',
  styleUrls: ['./grain.component.css']
})
export class GrainComponent implements OnInit {
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  GrainList: GrainModel[] = [];
  GrainListOriginal: GrainModel[] = [];
  NewGrain: GrainModel = new GrainModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Grain";
  NameError = 'Enter Grain name (maximum 50 characters)';
  CodeError = 'Enter Grain code';
  searchValue = '';
  visible = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;

  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,

    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {


    this.permission.View = this.auth.CheckResponsibility(Modules.Grain, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Grain, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Grain, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      GrainName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.NameAsyncValidator]],
      GrainCode: [null, [Validators.required], [this.CodeAsyncValidator]],
      Price: [null, [Validators.required]],
      GrainDesc: [null, [Validators.maxLength(200)]]

    });
    this.GetAllGrain();
  }

  get f() { return this.validateForm.controls; }

  GetAllGrain() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;
      this.GrainListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllGrain();
      }
    });
  }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  SaveGrain() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.NewGrain.GrainName = this.f['GrainName'].value;
    this.NewGrain.GrainCode = this.f['GrainCode'].value;
    this.NewGrain.Price = this.f['Price'].value;
    this.NewGrain.GrainDesc = this.f['GrainDesc'].value;
    this.NewGrain.GrainAddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + "Grain/addupdateGrain";
    this.http.post<GrainModel>(url, this.NewGrain).subscribe({

      next: res => { this.alertService.success("Grain Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllGrain(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  OpenEditPop(data: GrainModel) {
    this.PopUpTitle = "Modify Grain Details";
    this.validateForm.setValue(
      {
        GrainName: data.GrainName,
        GrainCode: data.GrainCode,
        Price: data.Price,
        GrainDesc: data.GrainDesc,

      }
    )
    this.NewGrain = new GrainModel();
    this.NewGrain.GrainId = data.GrainId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Grain";
    this.validateForm.reset();
    this.NewGrain.GrainName = "";
    this.NewGrain.GrainId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveGrain();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteGrain() {
    let url = this.ApiUrl + "Grain/deleteGrain";
    this.http.post<any>(url, this.NewGrain).subscribe({

      next: res => {

        this.alertService.success("Grain Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllGrain();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: GrainModel) {
    this.NewGrain = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Grain?',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteGrain(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }

  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.GrainList.filter(x => x.GrainId != this.NewGrain.GrainId);
        var nre = res.filter(x => x.GrainName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Grain name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Grain name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Grain name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  CodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.GrainList.filter(x => x.GrainId != this.NewGrain.GrainId);
        var nre = res.filter(x => x.GrainCode.toLowerCase() == control.value.toLowerCase());

        this.CodeError = '';
        if (control.value == '') {
          this.CodeError = "Enter Grain code";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.CodeError = "Grain code already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.CodeError = "Enter Grain code";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllGrain();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.GrainListOriginal;
    var res2 = this.GrainListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: GrainModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.GrainAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.GrainAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.GrainList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.GrainList = res.filter(
        (item: GrainModel) =>
          item?.GrainName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.GrainCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.GrainDesc?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.GrainAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }
}
