import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SaleOrderModel } from '../../Models/SalesOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { CompanyInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { SaleOrderCostingService } from 'src/PmsUIApp/Services/SaleOrderCostingService';
import { SaleOrderCostingDataService } from 'src/PmsUIApp/Services/SaleOrderCostingData.service';

@Component({
    selector: 'app-SingleOrderCostingPrint',
    templateUrl: './SingleOrderCostingPrint.component.html',
    styleUrls: ['./SingleOrderCostingPrint.component.css']
})
export class SingleOrderCostingPrint implements OnInit {
    ApiUrl = environment.Api_Url;
    OrderDetails: SaleOrderModel;
    Production!: SaleOrderModel;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    typeList: any[] = [];
    PopUpTitle: string = "Add New Production";
    searchValue = '';
    visible = false;
    private route$: Subscription = new Subscription;
    SaleOrderId = 0;
    PrintStr = ''
    EmbossingStr = ''
    TumblingStr = ''
    LacquerStr = ''
    VaccumStr = ''
    permission = {
        View: false,
        SaleOrderView: false
    }
    Company = {
        CompanyName: '',
        HeadOfficeAddress: '',
        FactoryAddress: ''
    };
    totalCalculatedQuantities: { [mixingName: string]: number } = {};
    totalCalculatedSCQuantities: { [mixingName: string]: number } = {};
    TotalProductionQty: number = 0;
    FabricQTYFromKGToMTR: number;
    GSMType: string = '';
    GSMTypePrintText: string = '';
    dataloadCheck: boolean = false;
    constructor(public http: HttpClient, private route: ActivatedRoute, private loader: LoadingService, private auth: AuthService,
        private router: Router, private alertService: AlertMessageService,
        private SaleOrderCostingService: SaleOrderCostingService,
        public costingService: SaleOrderCostingDataService) { }

    ngOnInit() {
        this.route$ = this.route.params.subscribe((params: Params) => {
            this.SaleOrderId = params["id"];
            this.GSMType = params["type"];
        })
        if (this.GSMType == 'costing') {
            this.GSMTypePrintText = 'FINAL GSM';
        }
        else {
            this.GSMTypePrintText = 'STANDARD GSM';
        }
        this.Company.CompanyName = CompanyInfo.CompanyName;
        this.Company.HeadOfficeAddress = CompanyInfo.HeadOfficAddress;
        this.Company.FactoryAddress = CompanyInfo.FactoryAddress;
        // this.GetProductionDatabyid(this.SaleOrderId);
        this.costingService.GetPostProcessCosting();
        const data1$ = this.costingService.GetSaleOrderDetails(this.SaleOrderId, this.GSMType).subscribe((res) => {
            if (res == true) {
                setTimeout(function () {
                    window.print();
                    window.onafterprint = close;
                    function close() {
                        window.close();
                    }
                }, 3000)
            }
        });
    }
}
