<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

<nz-page-header [nzGhost]="false">
    <nz-page-header-title>Stock Details</nz-page-header-title>
    <nz-page-header-subtitle>View your stock details here</nz-page-header-subtitle>
  </nz-page-header>
  <nz-divider></nz-divider>
  <nz-descriptions nzBordered>
    <nz-descriptions-item nzTitle="Supplier Name">{{NewStock.Invoice.SupplierName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Stock Date"> {{NewStock.StockDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Number">{{NewStock.Invoice.InvoiceNumber}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Date">{{NewStock.Invoice.InvoiceDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Total Price">
        {{NewStock.Invoice.InvoiceTotal}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="View Invoice">
       <a class="btn btn-sm btn-light-info" *ngIf="this.NewStock.Invoice.InvoiceFile != null && this.NewStock.Invoice.InvoiceFile != ''" [href]="NewStock.Invoice.InvoiceFile" target="_blank">Link to Invoice</a>
       <span *ngIf="this.NewStock.Invoice.InvoiceFile == null" >No invoice file found</span>
       <span *ngIf="this.NewStock.Invoice.InvoiceFile == ''" >
        <p>This is a transfer stock detail or</p>
        <p> no invoice was uploaded</p>
      </span>
  </nz-descriptions-item>
  </nz-descriptions>

<br><br>
<nz-table   [nzPageSize]="100"#basicTable [nzData]="this.NewStock.StockProduct">
    <thead>
      <tr>
        <th></th>
        <th>Product Type</th>
        <th>Product Name</th>
        <th>SKU</th>
        <th>Barcode</th>
        <th>Mfd Date</th>
        <th>Expiry</th>
        <th>Unit</th>
        <th>Quantity</th>
        <th>Per Unit Price</th>
        <th>Grade</th>
        <th>Accepted Qty</th>
        <th style="width: 8%;">Rejected Qty</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-data [ngForOf]="basicTable.data">
      <tr>
        <td [(nzExpand)]="data.ExpandRecord"></td>
        <td>{{ data.ProductType }}</td>
        <td>{{ data.ProductName }}</td>
        <td>{{ data.Sku }}</td>
        <td>{{ data.Barcode }}</td>
        <td>{{ data.ManufacturedDate  | date: 'dd-MMM-yyyy' }}</td>
        <td>{{ data.ExpiryDate  | date: 'dd-MMM-yyyy' }}</td>
        <td>{{data.Unit}}</td>
        <td>{{data.Quantity}}</td>
        <td>{{data.PricePerUnit}}</td>
        <td>{{data.Grade}}</td>
        <td>{{data.AcceptedQuantity}}</td>
        <td>
            {{data.RejectedQuantity}}
        </td>
        <td>
            {{data.Comments}}
        </td>
      </tr>
      <tr [nzExpand]="data.ExpandRecord">
        <table>
          <tr>
            <td style="vertical-align: top;">

              <nz-card style="width: 97%;" nzTitle="Allocation Details">
                <p><nz-table #innerTable [nzData]="data.StockProductAllocation" nzSize="middle" [nzShowPagination]="false">
                  <thead>
                    <tr>
                      <th style="background: aliceblue;">Accepted/Rejected</th>
                      <th style="background: aliceblue;">Quantity</th>
                      <th style="background: aliceblue;">Rack Allocated</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let da of innerTable.data">
                      <td>{{ da.InspectionType }}</td>
                      <td>{{ da.Quantity }}</td>
                      <td>{{ da.RackName }}</td>
                    </tr>
                  </tbody>
                </nz-table></p>
              </nz-card>
            </td>
            <td style="vertical-align: top;">
              <nz-card style="width: 97%;" nzTitle="Rejected Item Details">
                <p><nz-table #innerTableri [nzData]="data.StockProductManageRejected" nzSize="middle" [nzShowPagination]="false">
                  <thead>
                    <tr>
                      <th style="background: aliceblue;">Quantity</th>
                      <th style="background: aliceblue;">Action</th>
                      <th style="background: aliceblue;">Dispact Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dat of innerTableri.data">
                      <td>{{ dat.Quantity }}</td>
                      <td>{{ dat.ItemAction }}</td>
                      <td>
                        <span *ngIf="dat.ItemAction != 'Dispatch'">N/A</span>
                        <span *ngIf="dat.ItemAction == 'Dispatch' && dat.StockProductRejectedDispatch != null">
                          <b>Dispatch id : </b>{{dat.StockProductRejectedDispatch.DispatchId}} ; <b>Transport Company : </b>{{dat.StockProductRejectedDispatch.TransportCompany}}; <b>Vehicle No : </b>{{dat.StockProductRejectedDispatch.VehicleNumber == null ? 'Others' : dat.StockProductRejectedDispatch.VehicleNumber }}</span>
                        </td>
                    </tr>
                  </tbody>
                </nz-table></p>
              </nz-card>
              
            </td>
          </tr>
        </table>
        




        <!-- <table nzSize="small" style="table-layout: auto; background: white; width: 35%;" ng-reflect-list-of-col-width=",,,20%">
          <thead class="ant-table-thead ng-star-inserted">
            <tr class="ant-table-row ng-star-inserted">
            <th  style="background: aliceblue;" class="ant-table-cell">Accepted/Rejected</th>
            <th  style="background: aliceblue;" class="ant-table-cell" >Quantity</th>
            <th  style="background: aliceblue;" class="ant-table-cell">Rack Allocated</th>
           </tr>
          </thead>
        <tbody class="ant-table-tbody ng-star-inserted">
                <tr *ngFor="let alloc of data.StockProductAllocation" class="ant-table-thead ng-star-inserted">
                    <td class="ant-table-cell">{{alloc.InspectionType}}</td>
                    <td class="ant-table-cell">{{alloc.Quantity}}</td>
                    <td class="ant-table-cell">{{alloc.RackAllocation}}</td>
                </tr>
          </tbody>
      </table> -->
            </tr>
    </ng-template>
    </tbody>
  </nz-table>

  <br>
  <div class="d-flex justify-content-end">
    <!--begin::Button-->
    <a routerLink="/home/stocklist" class="btn btn-success">Back to Stock List</a> 
    <!--end::Button-->
</div>
  </div>
</div>




