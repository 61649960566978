import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { MeasurementConversionModel } from '../../Models/MasterModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
@Component({
  selector: 'app-measurement-conversion',
  templateUrl: './measurement-conversion.component.html',
  styleUrls: ['./measurement-conversion.component.css']
})
export class MeasurementConversionComponent implements OnInit {
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  searchValue = ''
  ConversionList: MeasurementConversionModel[] = [];
  ConversionListOriginal: MeasurementConversionModel[] = [];
  NewConversion: MeasurementConversionModel = new MeasurementConversionModel;
  MeasureUnits: MeasureUnitModel[] = [];
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Conversion";
  BranchNameError = 'Enter From Unit';
  BranchCodeError = 'Enter To Unit';
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;

  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,

    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {

    this.permission.View = this.auth.CheckResponsibility(Modules.Conversion, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Conversion, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Conversion, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllUnits();
    this.validateForm = this.fb.group({
      FromUnit: [null, [Validators.required]],
      ToUnit: [null, [Validators.required]],
      ConversionValue: [null, [Validators.required]]

    });
    this.GetAllConversion();
  }
  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllUnits();
      }
    });
  }
  GetAllConversion() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "measure/getallmeasurementconversion";
    this.http.get<MeasurementConversionModel[]>(url).subscribe(res => {
      this.ConversionList = res;
      this.ConversionListOriginal = res;
      this.isTableLoading = false;
    }, res => { this.GetAllConversion(); });
  }

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllConversion();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {
    var res = this.ConversionListOriginal;
    var res2 = this.ConversionListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: MeasurementConversionModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ConversionList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ConversionList = res.filter(
        (item: MeasurementConversionModel) =>
          item?.FromUnit?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ToUnit?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ConversionValue == +this.searchValue ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }


  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  SaveConversion() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewConversion.FromUnit = this.f['FromUnit'].value;
    this.NewConversion.ToUnit = this.f['ToUnit'].value;
    this.NewConversion.ConversionValue = this.f['ConversionValue'].value;
    this.NewConversion.AddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + "measure/addupdatetag";
    this.http.post<MeasurementConversionModel>(url, this.NewConversion).subscribe({

      next: res => { this.alertService.success("Conversion Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllConversion(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: MeasurementConversionModel) {
    this.PopUpTitle = "Modify Conversion Details";
    this.validateForm.setValue(
      {
        FromUnit: data.FromUnit,
        ToUnit: data.ToUnit,
        ConversionValue: data.ConversionValue,

      }
    )
    this.NewConversion = new MeasurementConversionModel();
    this.NewConversion.ConversionId = data.ConversionId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Conversion";
    this.validateForm.reset();
    //this.NewConversion.BranchName = "";
    this.NewConversion.ConversionId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveConversion();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteBranch() {
    let url = this.ApiUrl + "Branch/deleteBranch";
    this.http.post<any>(url, this.NewConversion.ConversionId).subscribe({

      next: res => {

        this.alertService.success("Branch Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllConversion();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: MeasurementConversionModel) {
    this.NewConversion = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Branch?',
      nzOnOk: () => this.DeleteBranch(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }


}
