<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Conversion List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your conversion here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary"  *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>  
    <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.ConversionList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th>From Unit</th>
          <th>To Unit</th>
          <th>Conversion Value</th>
          <th>Added Date</th>
          <th>Added By</th>
          
          <th nzWidth="20%" *ngIf="permission.Add || permission.Delete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.FromUnit }}</td>
          <td>{{ data.ToUnit }}</td>
          <td>{{ data.ConversionValue }}</td>
          <td>{{ data.AddedDate |DatetimeConverter }}</td>
          <td>{{ data.AddedBy }}</td>
          


          <td *ngIf="CheckUserAddPermission(data.AddedBy )">
            <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>

          </td>
        </tr>
      </tbody>
    </nz-table>


    <nz-modal [nzWidth]="800"
              [nzStyle]="{ top: '20px' }"
              [(nzVisible)]="isVisible"
              [nzTitle]="modalTitle"
              [nzContent]="modalContent"
              [nzFooter]=null
              (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8" nzRequired>From Unit</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="BranchNameError">
             
              <nz-select class="form-select mb-2" nzShowSearch formControlName="FromUnit" name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>To Unit </nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="BranchCodeError">
              <nz-select class="form-select mb-2" nzShowSearch formControlName="ToUnit" name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
              </nz-select>
              <!--<input nz-input formControlName="ToUnit" name="BranchCode" />-->
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Conversion Value</nz-form-label>
            <nz-form-control  [nzSpan]="14" nzErrorTip="Enter Conversion Value ">
              <input nz-input formControlName="ConversionValue" />
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save Conversion</button>
          </div>

        </form>
      </ng-template>
    </nz-modal>

  </div>
</div>

