import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SaleOrderModel } from '../../../Models/SalesOrderModel';
import { LoadingService } from '../../../Services/loadingService';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { CompanyInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';

@Component({
    selector: 'app-InspectionDetailsPrint',
    templateUrl: './InspectionDetailsPrint.component.html',
    styleUrls: ['./InspectionDetailsPrint.component.css']
})
export class InspectionDetailsPrintComponent implements OnInit {
    ApiUrl = environment.Api_Url;
    ProductionList: SaleOrderModel[] = [];
    ProductionListOriginal: SaleOrderModel[] = [];
    Production!: SaleOrderModel;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    typeList: any[] = [];
    PopUpTitle: string = "Add New Production";
    searchValue = '';
    visible = false;
    private route$: Subscription = new Subscription;
    SaleOrderId = 0;
    PrintStr = ''
    EmbossingStr = ''
    TumblingStr = ''
    LacquerStr = ''
    VaccumStr = ''
    permission = {
        View: false,
        SaleOrderView: false
    }
    Company = {
        CompanyName: '',
        HeadOfficeAddress: '',
        FactoryAddress: ''
    };
    totalCalculatedQuantities: { [mixingName: string]: number } = {};
    totalCalculatedSCQuantities: { [mixingName: string]: number } = {};
    TotalProductionQty: number = 0;
    FabricQTYFromKGToMTR: number;
    constructor(public http: HttpClient, private route: ActivatedRoute, private loader: LoadingService, private auth: AuthService,
        private router: Router, private alertService: AlertMessageService) { }

    ngOnInit() {
        // this.permission.View = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.View);
        // this.permission.SaleOrderView = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.View)
        // if (this.permission.View != true || this.permission.SaleOrderView != true) {
        //   this.router.navigate(['/home/unauthorized']);
        // }
        this.route$ = this.route.params.subscribe((params: Params) => {
            this.SaleOrderId = params["id"];
        })
        this.Company.CompanyName = CompanyInfo.CompanyName;
        this.Company.HeadOfficeAddress = CompanyInfo.HeadOfficAddress;
        this.Company.FactoryAddress = CompanyInfo.FactoryAddress;

        this.GetProductionDatabyid(this.SaleOrderId);
    }

    GetProductionDatabyid(id: number) {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + id;
        this.http.get<any>(url).subscribe(res => {

            this.Production = res;
            if (this.Production.Status.toString() != "WorkPlan") {
                //this.IsInspectionCompleted = true
            }
            this.calclulateTotalSalePrice()
            this.Production.SaleOrderProduction.RawGSMTotal = 0;
            this.Production.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach((element) => {

                this.Production.SaleOrderProduction.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
            });
            this.Production.SaleOrderProduction.MixingGSMTotal = this.Production.SaleOrderProduction.MixingTotalCost = 0;
            this.Production.SaleOrderProduction.FormulationMixing.forEach(element => {
                this.Production.SaleOrderProduction.MixingTotalCost += isNaN(element.Total) ? 0 : element.Total;
                this.Production.SaleOrderProduction.MixingGSMTotal += isNaN(parseInt(element.WeightGsm?.toString())) ? 0 : parseInt(element.WeightGsm.toString());
            })
            //if (this.IsInspectionCompleted) {
            this.calculateFormulaQuantityAfterInspection();
            //}
            //else {
            //    this.calculateFormulaQuantity();
            //}

            this.Production.SaleOrderProduction.FinalMixingGSMTotal = this.Production.SaleOrderProduction.MixingGSMTotal + this.Production.SaleOrderProduction.RawGSMTotal;
            var totalprint = this.Production.SaleOrderProduction?.SaleOrderProductionPrint.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalprint);
            var totalEmbossing = this.Production.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalEmbossing);
            var totalVaccum = this.Production.SaleOrderProduction?.SaleOrderProductionVacuum.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalVaccum);
            var totalTumbling = this.Production.SaleOrderProduction?.SaleOrderProductionTumbling.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalTumbling);
            //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            //console.log(totalLacquer);
            var totalElement = this.Production.SaleOrderProduction?.SaleOrderProductionElement.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(isNaN(totalElement) ?? 0);
            var totalRaw = this.Production.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce((sum, current) => sum + parseFloat(current.TotalCost?.toString()), 0) ?? 0
            console.log(totalRaw);
            this.Production.GrandTotal = parseFloat(this.Production.SaleOrderProduction?.TotalCost.toString()) + parseFloat(this.Production.SaleOrderProduction?.ColorPrice.toString()) +
                parseFloat(this.Production.SaleOrderProduction?.GrainPrice.toString()) +
                parseFloat(this.Production.SaleOrderProduction?.ThickPrice.toString()) +
                parseFloat(this.Production.SaleOrderProduction?.WidthPrice.toString()) +
                parseFloat(isNaN(totalprint) == true ? "0" : totalprint.toString()) +
                parseFloat(isNaN(totalEmbossing) == true ? "0" : totalEmbossing.toString()) +
                parseFloat(isNaN(totalVaccum) == true ? "0" : totalVaccum.toString()) +
                parseFloat(isNaN(totalTumbling) == true ? "0" : totalTumbling.toString()) +
                parseFloat(isNaN(totalElement) == true ? "0" : totalElement.toString()) +
                parseFloat(totalRaw.toString());
            this.SetTotalProductionQty()
            this.Production.SaleOrderProduction.FinalMixingGSMTotal = this.Production.SaleOrderProduction.InspectionFormulationMixing[0].PreSkinGsmPasteReq
                + this.Production.SaleOrderProduction.InspectionFormulationMixing[0].SkinGsmPasteReq
                + this.Production.SaleOrderProduction.InspectionFormulationMixing[0].FoamGsmPasteReq
                + this.Production.SaleOrderProduction.InspectionFormulationMixing[0].AdhesiveGsmPasteReq
            setTimeout(function () {
                window.print();
                window.onafterprint = close;
                function close() {
                    window.close();
                }
            }, 1)
            this.loader.hide();
        });
    }
    SetTotalProductionQty() {
        if (this.Production.SaleOrderProduction.ExtraProduction > 0) {
            this.TotalProductionQty = parseFloat((this.Production.SaleOrderProduction.OrderQuantity + (this.Production.SaleOrderProduction.OrderQuantity * this.Production.SaleOrderProduction.ExtraProduction) / 100).toFixed(2));
            this.calculateFabricQtyKGtoMTR()
        }
        else {
            this.TotalProductionQty = this.Production.SaleOrderProduction.OrderQuantity;
            this.calculateFabricQtyKGtoMTR();
        }
    }
    calclulateTotalSalePrice() {
        this.Production.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Production.SaleOrderProduction.OrderQuantity) ? 0 : this.Production.SaleOrderProduction.OrderQuantity) * (isNaN(this.Production.SaleOrderProduction.SalePrice) ? 0 : this.Production.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
    }
    calculateFormulaQuantityAfterInspection() {
        this.Production.SaleOrderProduction.InspectionFormulationMixing.forEach(element => {
            let totalCalculatedForMixing = 0;
            let totalCalculatedSCForMixing = 0;
            element.MixingRawMaterial.forEach(raw => {
                totalCalculatedForMixing += raw.Quantity;
                totalCalculatedSCForMixing += raw.Scquantity;
            })
            this.totalCalculatedQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedForMixing.toFixed(2));
            this.totalCalculatedSCQuantities[element.MixingName.trim().toLowerCase()] = parseFloat(totalCalculatedSCForMixing.toFixed(2));

        })
    }
    calculateFabricQtyKGtoMTR() {
        if (this.Production.SaleOrderProduction.InspectionSaleFormulationCode?.FabricProductUnit != "Mtrs" && this.Production.SaleOrderProduction.FabricProductId > 0) {
            if (this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter != null && this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter > 0 && this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm != null && this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm > 0) {
                this.FabricQTYFromKGToMTR = parseFloat(((this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductQty * 1000) / (this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm * this.Production.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter)).toFixed(2));
            } else {
                this.FabricQTYFromKGToMTR = this.TotalProductionQty;
            }
        }
    }
}
