import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../../environments/environment";
import { Modules, Responsibility } from "../../../Models/Enums";
import { GateInModel } from "../../../Models/GateInModel";
import { TransportModel, TransportVehicleModel } from "../../../Models/TransportModel";
import { JumboDispatchModel, JumboInspectionFilterModel, JumboInspectionModel } from "../../../Models/WorkPlanModel";
import { AlertMessageService } from "../../../Services/AlertMessageService";
import { AuthService } from "../../../Services/auth.service";
import { LoadingService } from "../../../Services/loadingService";
import { CustomerModel } from "src/PmsUIApp/Models/SupplierModel";
import { FormulationCodeModel } from "src/PmsUIApp/Models/FormulationCodeModel";

@Component({
  selector: 'app-PackagingList',
  templateUrl: './PackagingList.component.html'
})

export class PackagingListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  isVisible = false;
  isDispatchVisible = false;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    AddTransport: false
  }
  JumboDispatchList: JumboDispatchModel[] = [];
  JumboInspection: JumboInspectionModel[] = [];
  TransportList: TransportModel[] = [];
  TransportVehicleList: TransportVehicleModel[] = [];
  NewDispatch: JumboDispatchModel = new JumboDispatchModel;
  TransportId: number = 0;
  VehicleId: number = 0;
  count: 0;
  VehicleStatus: string = '';
  IsCheckedAll: boolean = false;
  showsearch: boolean = false;
  searchBtnText: string = 'Show Search';
  request = {
    SaleOrderNumber: '',
    CustomerId: 0,
    SaleFormulationCodeId: 0,
    VehicleId: 0,
    TransportId: 0,
    Remark: '',
    AddedBy: '',
    DispatchDateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    DispatchDateTo: new Date(new Date().setHours(23, 59, 59)),
    PackingNumber: '',
    JumboNumber: '',
    RollCode: ''
  }
  PackingNumberPrefix: string = 'ZB/PCK/';
  PackingNumber: string = '';
  CustomerList: CustomerModel[];
  FormulationCodeList: FormulationCodeModel[];
  totalItemsCount: number = 0;
  totalJumboRollItemsCount: number = 0;
  constructor(private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.Delete);
    this.permission.AddTransport = this.auth.CheckResponsibility(Modules.Transport, Responsibility.Add);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetJumboDispatchList();
    this.GetAllTransport();
  }
  GetAllTransport() {
    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe({
      next: res => { this.TransportList = res; },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
        this.count++;
        if (this.count < 2) {
          this.GetAllTransport();
        }
      },
    });
  }
  onSelectedTransportChange($event: number) {
    var StockProductRejectedDispatch = this.TransportList.filter(x => x.TransportId == $event)[0];
    this.TransportVehicleList = StockProductRejectedDispatch.TransportVehicle;

  }
  GetJumboDispatchList() {
    this.loader.show();
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "saleorder/getjumbodispatchlistwithfilter";
    this.http.post<JumboDispatchModel[]>(url, this.request).subscribe(res => {
      this.JumboDispatchList = res;
      this.JumboDispatchList.forEach((x) => {
        count++;
        x.SerialNo = count;
        x.DispatchStatus = x.DispatchQuantity <= 0 ? "Cancelled" : "Created";
      });
      this.totalItemsCount = count;
      this.loader.hide();
    }, res => { this.loader.hide(); });
  }
  SearchSubmit() {
    this.request.PackingNumber = this.PackingNumberPrefix + this.PackingNumber;
    this.GetJumboDispatchList();
  }
  SearchReset() {
    this.request = {
      SaleOrderNumber: '',
      CustomerId: 0,
      SaleFormulationCodeId: 0,
      VehicleId: 0,
      TransportId: 0,
      Remark: '',
      AddedBy: '',
      DispatchDateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 700)),
      DispatchDateTo: new Date(new Date().setHours(23, 59, 59)),
      PackingNumber: '',
      JumboNumber: '',
      RollCode: ''
    }
    this.PackingNumber = '';
    this.GetJumboDispatchList();
  }
  handleOk(): void {
    this.isLoading = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  handleAddTransport(data: JumboDispatchModel): void {
    this.showModal(data);
  }

  showModal(data: JumboDispatchModel): void {
    this.NewDispatch = data;
    this.isVisible = true;
    if (this.NewDispatch.TransportId > 0) {
      this.onSelectedTransportChange(this.NewDispatch.TransportId);
    }
  }



  handleDispatchCancel(): void {
    this.isDispatchVisible = false;
  }
  showDispatchModal(data: JumboDispatchModel): void {
    this.JumboInspection = data.JumboInspection;
    let count = this.totalItemsCount = 0;
    this.JumboInspection.forEach((x) => {
      count++;
      x.SerialNo = count;
    });
    this.totalItemsCount = count;
    this.NewDispatch.JumboDispatchId = data.JumboDispatchId;
    this.isDispatchVisible = true;
  }
  SaveDispatchItems() {
    console.log(this.NewDispatch)
    this.isLoading = true;
    let url = this.ApiUrl + "saleorder/AddJumboDispatch";
    this.http.post<any>(url, this.NewDispatch).subscribe({
      next: res => {
        this.alertService.success("Packing list updated successfullly");
        this.GetJumboDispatchList();
        this.handleCancel();
      },
      error: res => { this.alertService.error(res.error); this.isLoading = false; },
    });
  }
  Print(data: JumboDispatchModel) {
    window.open(`${window.location.origin}/packinglistprint/` + data.JumboDispatchId);
  }
  onSelectedVehicleChange($event: number) {
    let url = this.ApiUrl + "gate/getvehiclestatus?vehicleid=" + $event;
    this.http.get<GateInModel>(url).subscribe({
      next: res => {
        this.VehicleStatus = null;
        if (res != null) {
          this.VehicleStatus = "Selected Vehicle is already Gate-in";
          if (res.GatePassIssue == true) {
            this.VehicleStatus = this.VehicleStatus + " and Gate pass issued";
          }
        }
      },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
      },
    });
  }
  CheckAll() {
    this.NewDispatch.DispatchQuantity = 0;
    this.JumboInspection.forEach(x => {
      x.IsChecked = this.IsCheckedAll;
      if (x.IsChecked) {
        this.NewDispatch.DispatchQuantity += x.Quantity;
      }
    })
    this.NewDispatch.Weight = 0;
    this.JumboInspection.forEach(x => {
      x.IsChecked = this.IsCheckedAll;
      if (x.IsChecked) {
        this.NewDispatch.Weight += x.Weight;
      }
    })
  }
  handleRemove() {
    var selectedDispatch = this.JumboInspection.filter(x => x.IsChecked == true);
    if (selectedDispatch.length > 0) {
      this.NewDispatch.JumboInspection = selectedDispatch;
    }
    else {
      this.alertService.error("Please select at lease one roll.")
      return;
    }
    if (selectedDispatch.length == this.JumboInspection.length) {
      const modal1 = this.modalService.confirm({
        nzTitle: 'Cancel Dispatch Confirmation',
        nzContent: 'All rolls selected to remove, this dispatch will be cancelled. Are you sure to proceed?',
        nzOkDanger: true,
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzOnOk: () => this.removeSelectedRolls(),
      });
      setTimeout(() => modal1.destroy(), 30000);
    }
    else {
      const modal2 = this.modalService.confirm({
        nzTitle: 'Confirm Roll Remove',
        nzContent: 'Are you sure to remove the selected roll from this Dispatch?',
        nzOkDanger: true,
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzOnOk: () => this.removeSelectedRolls(),
      });
      setTimeout(() => modal2.destroy(), 30000);
    }
  }
  removeSelectedRolls() {
    // var selectedDispatch = this.JumboInspection.filter(x => x.IsChecked == true);
    // if (selectedDispatch.length > 0) {
    //   this.NewDispatch.JumboInspection = selectedDispatch;
    // }
    // else {
    //   this.alertService.error("Please select at lease one roll.")
    //   return;
    // }
    this.loader.show();
    let url = this.ApiUrl + "saleorder/jumbodispatchremoveroll";
    this.http.post<any>(url, this.NewDispatch).subscribe({
      next: res => {
        this.alertService.success(res.ResponseBody);
        this.handleDispatchCancel();
        this.GetJumboDispatchList();
        this.loader.hide()
      },
      error: res => {
        this.alertService.error(res.error);
        this.isLoading = false;
        this.loader.hide()
      },
    });
  }
  EnableEditButtons(data: JumboDispatchModel) {
    if (data.DispatchQuantity > 0 && this.permission.Add == true && data.IsGateOut != true) {
      return true;
    }
    else {
      return false;
    }
  }
  EnableEmailButton(data: JumboDispatchModel) {
    if (data.DispatchQuantity > 0 && this.permission.View == true && data.CustomerDetails[0].Email != null && data.CustomerDetails[0].Email != "") {
      return true;
    }
    else {
      return false;
    }
  }
  EnablePrintButton(data: JumboDispatchModel) {
    if (data.DispatchQuantity > 0 && this.permission.View == true) {
      return true;
    }
    else {
      return false;
    }
  }
  email(data: number) {
    window.open(`${window.location.origin}/dispatchpackagingemail/` + data);
  }
  DisableRollSelection(data: JumboInspectionModel) {
    var GateOutStatus = this.JumboDispatchList.filter(x => x.JumboDispatchId == data.JumboDispatchId)[0];
    if (data.SaleOrderStatus.toString() == "DispatchCompleted" || (data.SaleOrderStatus.toString() == "PartialDispatchCompleted" && GateOutStatus.IsGateOut == true)) {
      return true;
    }
    else {
      return false;
    }
  }
  DisableRemoveRollsBtn(data: number) {
    var GateOutStatus = this.JumboDispatchList.filter(x => x.JumboDispatchId == data)[0];
    if (GateOutStatus.IsGateOut == true) {
      return true;
    }
    else {
      return false;
    }
  }
  showSearchSection() {
    this.showsearch = !this.showsearch;
    if (this.showsearch) {
      this.searchBtnText = "Hide Search";
    }
    else {
      this.searchBtnText = "Show Search";
    }

  }
  GetAllCustomer() {
    if (this.showsearch) {
      this.loader.show();
      let url = this.ApiUrl + "customer/getallcustomers";
      this.http.get<CustomerModel[]>(url).subscribe(res => {
        this.CustomerList = res;
        this.loader.hide();
      }, res => {
        this.count++;
        if (this.count < 2) { this.GetAllCustomer(); }
      });
    }
  }
  GetAllFormulationCode() {
    if (this.showsearch) {
      this.loader.show();
      let url = this.ApiUrl + "saleorder/getallformulationcodes";
      this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
        this.FormulationCodeList = res;
        this.loader.hide();
      }, res => {
        this.count++;
        if (this.count < 2) { this.GetAllFormulationCode(); }
      });
    }
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
}
