import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { MixingModel, MixingPrintModel } from '../../Models/MixingModel';


import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';


@Component({
  selector: 'app-Mixinglist',
  templateUrl: './mixinglist.component.html',
  styleUrls: ['./mixinglist.component.css']
})
export class MixingListComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  ProductionList: MixingModel[] = [];
  ProductionListOriginal: MixingModel[] = [];
  Production!: MixingModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Mixing";
  searchValue = '';
  visible = false;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any;
  exportoptions = {
    headers: ["MIXING NAME", "WASTAGE", "TOTAL", "ADDED BY", "ADDED DATE"]
  };

  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  fields: MixingPrintModel = new MixingPrintModel;
  exportfields: MixingPrintModel[] = []
  count: number;
  constructor(private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) { }


  ngOnInit() {

    this.permission.View = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Mixing, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.GetAllProduction();
  }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  GetAllProduction() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mixing/getallmixings";
    this.http.get<MixingModel[]>(url).subscribe(res => {
      this.ProductionList = res;
      this.ProductionListOriginal = res;
      this.ProductionList.forEach(x => {
        this.fields = new MixingPrintModel;

        this.fields.MixingName = x.MixingName;
        this.fields.Wastage = x.Wastage;
        this.fields.Total = x.Total;


        this.fields.AddedBy = x.AddedBy;
        this.fields.AddedDate = x.AddedDate;




        this.exportfields.push(this.fields);
      })
      this.isTableLoading = false;


    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProduction(); }
    });
  }
  OpenViewPop(data: any) {
    this.isVisible = true;
    this.Production = data;

  }
  export() {

    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'mixing-export' + exportdate, this.exportoptions);
  }
  handleOk(): void {

    //this.isLoading = true;
    //this.Save();

  }
  //calculateTotal() {
  //  return this.Production.ProductionProduct.reduce((accum, curr) => accum + curr.Amount, 0);
  //}
  handleCancel(): void {
    this.isVisible = false;
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllProduction();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }




  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ProductionListOriginal;
    var res2 = this.ProductionListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: MixingModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.ProductionList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.ProductionList = res.filter(
        (item: MixingModel) =>
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.MixingName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Wastage == +this.searchValue ||
          item.WeightGsm == +this.searchValue ||
          item?.WastageType?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Total == +this.searchValue

      )
    }

  }
  handleDelete(data: MixingModel) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: '<b>Are you sure to delete this Mixing?</b>',
      nzOkText: 'Yes',
      nzOkDanger: true,
      nzOnOk: () => this.DeleteMixing(data),
      nzCancelText: 'No'
    });
  }
  DeleteMixing(data: MixingModel) {
    let url = this.ApiUrl + 'mixing/deletemixing';
    this.http.post(url, data).subscribe({
      next: (res) => {
        this.alertService.success('Mixing Deleted Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllProduction();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    })
  }

}

