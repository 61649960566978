export class TransportModel
    {
        TransportId : number =0;
        TransportCompanyName : string = '';
        Gst : string= '';
        TransportCompanyAddress : string= '';
        TransportCompanyContact : string= '';
        TransportCompanyEmail : string= '';
        TransportVehicle :TransportVehicleModel[] = [];
        ExpandRecord: boolean = false;
    }

    export class TransportVehicleModel
    {
        VehicleId : number =0;
        TransportId : number =0;
        VehicleNumber : string= '';
        VehicleType : string= '';
        
}

export class TransportPrintModel {
  
  TransportCompanyName: string = '';
  TransportCompanyEmail: string = '';
  TransportCompanyContact: string = '';
  Gst: string = '';
  TransportCompanyAddress: string = '';
}
