import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';

import { ProductionModel, ProductionElementModel, ProductionOrderModel, ProductionRawMaterialModel } from '../../Models/ProductionModel';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  selector: 'app-processprint',
  templateUrl: './processprint.component.html',
  styleUrls: ['./processprint.component.css']
})
export class ProcessprintComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  ProductionList: ProductionModel[] = [];
  ProductionListOriginal: ProductionModel[] = [];
  Production!: ProductionModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production";
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription;
  ProductionId = 0;
  permission = {
    View: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute,private auth: AuthService, private router: Router) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Customer, Responsibility.View);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProductionId = params["id"];
    })
    
    this.GetProductionDatabyid(this.ProductionId);
  }

    
  GetProductionDatabyid(id: number) {
    let url = this.ApiUrl + "production/getproductiondatabyid/" + id;
    this.http.get<any>(url).subscribe(res => {

      this.Production = res;
     
      if (this.Production != null) {
        //this.Production?.Process.forEach(x => {
        //  x.WeightGsm = x.ProductionProcessRawMaterial.reduce((sum, current) => sum + parseInt(current.AvgGsm) * current.Quantity, 0);
        //})
        this.Production.FinalProcessGSMTotal = this.Production.Process.reduce((sum, current) => sum + current.WeightGsm, 0)
        setTimeout(function () {
          window.print();
          window.close();
        }, 1)
      }
    });
  }

  
}
