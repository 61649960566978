import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, ColorModel, GrainModel, ElementModel, RackModel, StoreModel } from '../../../Models/MasterModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../../Models/ProductModel';
import { PurchaseOrderModel, PurchaseOrderProductModel } from '../../../Models/PurchaseOrderModel';
import { OrderModel, OrderProductModel, SupplierModel } from '../../../Models/SupplierModel';
import { TransportModel } from '../../../Models/TransportModel';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';

import { UserInfo } from '../../../Authentication/UserInfo';

import { MixingModel, MixingRawMaterialModel } from '../../../Models/MixingModel';
import { Subscription } from 'rxjs';
import { isNumber } from '@amcharts/amcharts5/.internal/core/util/Type';
import { MbformulationMasterModel, MbformulationProductModel, MbformulationRawMaterialMasterModel, MbformulationRawMaterialProductModel } from 'src/PmsUIApp/Models/MBFormulationModel';
import { ConsumeStockProductModel, IssueSaleOrderProductsStockModel } from 'src/PmsUIApp/Models/StockProductModel';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';

@Component({
  selector: 'app-MBFormulation',
  templateUrl: './MBFormulation.component.html'
})

export class MBFormulationComponent implements OnInit {
  StoreWiseStock: IssueSaleOrderProductsStockModel[] = [];
  validateForm!: UntypedFormGroup;
  RackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreID: number = 0;
  RackID: number = 0;
  FromStoreID: number = 0;
  ProductCategoryId: number = 0;
  ProductFirstSubCategoryId: number = 0;
  ProductSecSubCategoryId: number = 0;
  radioValue = '1';
  selectedMBFormulation: any;
  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  NewPO: MbformulationMasterModel = new MbformulationMasterModel;
  AddMBF: MbformulationProductModel = new MbformulationProductModel;
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];

  listOfSelectedproducts: number[] = [];
  productionRawMaterial: MbformulationRawMaterialMasterModel[] = [];
  MbformulationMasterList: MbformulationMasterModel[] = [];
  MbformulationRawMaterialMasterList: MbformulationRawMaterialMasterModel[] = [];
  consumeStockColumnMsg = "";
  permission = {
    View: false,
    Add: false,
    Delete: false
  }

  private route$: Subscription = new Subscription;
  count: number;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router,
    private auth: AuthService) { }
  handleOk(): void {

    this.isLoading = true;

    this.Save();
  }

  GetMBformulation() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "mbformulation/getallmbformulations";
    this.http.get<MbformulationMasterModel[]>(url).subscribe(res => {
      this.MbformulationMasterList = res;
    }, res => { });
  }



  Save() {

    var saveflag = true;
    // if (this.NewPO.MixingName == '') {
    //   this.isLoading = false;
    //   this.alertService.error("Mixing Name Required");
    //   return;
    // }
    if (this.radioValue == '0' && this.NewPO.MbformulationName == '') {
      this.isLoading = false;
      this.alertService.error("Please enter new name.");
      return;
    }
    if (this.radioValue == '0' && this.ProductCategoryId < 1) {
      this.isLoading = false;
      this.alertService.error("Please select category.");
      return;
    }
    if (this.FromStoreID < 1) {
      this.isLoading = false;
      this.alertService.error("Please select consumption store.");
      return;
    }
    if (this.StoreID < 1) {
      this.isLoading = false;
      this.alertService.error("Please select storage store.");
      return;
    }
    if (this.RackID < 1) {
      this.isLoading = false;
      this.alertService.error("Please select storage store rack.");
      return;
    }
    if (this.productionRawMaterial.length <= 0) {
      this.isLoading = false;
      this.alertService.error("Please Add Raw Material");
      return;
    }
    // if (this.MixingId == 0) {
    //   if (this.MixingList.filter(x => x.MixingName == this.NewPO.MixingName).length > 0) {
    //     this.isLoading = false;
    //     this.alertService.error("Mixing name already exist");
    //     return;
    //   }
    // } else {
    //   if (this.MixingList.filter(x => x.MixingName == this.NewPO.MixingName && x.MixingId != this.MixingId).length > 0) {
    //     this.isLoading = false;
    //     this.alertService.error("Mixing name already exist");
    //     return;
    //   }
    // }


    // if (this.productionRawMaterial.filter(x => x.Price <= 0).length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Invalid Price (should be greater than 0)");
    //   return;
    // }
    // if (this.productionRawMaterial.filter(x => x.Quantity <= 0).length > 0) {
    //   this.isLoading = false;
    //   this.alertService.error("Invalid Quantity (should be greater than 0)");
    //   return;
    // }

    this.NewPO.MbformulationRawMaterial = this.productionRawMaterial;

    this.AddMBF.FinalProductId = this.NewPO.FinalProductId;
    this.AddMBF.BatchSize = this.NewPO.BatchSize;
    this.AddMBF.MbformulationProductName = this.NewPO.MbformulationName;
    this.AddMBF.ProductCategoryId = this.ProductCategoryId;
    this.AddMBF.ProductFirstSubCategoryId = this.ProductFirstSubCategoryId;
    this.AddMBF.ProductSecSubCategoryId = this.ProductSecSubCategoryId;
    this.AddMBF.MbformulationId = this.NewPO.MbformulationId;
    this.AddMBF.RackId = this.RackID;
    this.AddMBF.FromStoreId = this.FromStoreID;
    this.AddMBF.Quantity = parseFloat(this.calculatetotalQty());
    console.log(this.productionRawMaterial)
    this.productionRawMaterial.forEach(x => {
      var consumequantity = 0;
      var mbr: MbformulationRawMaterialProductModel = {
        MbformulationRawMaterialProductId: 0,
        MbformulationProductId: 0,
        RawMaterialProductId: x.RawMaterialProductId,
        RawMaterialProductName: '',
        RawMaterialProductCode: '',
        QuantityInKg: x.QuantityInKg,
        QuantityBatchSizeInKg: x.QuantityBatchSizeInKg,
        AddedBy: '',
        AddedDate: '',
        ConsumeStockForRawMaterial: []
      };
      x.IssueProduct.forEach((ip) => {
        var item = new ConsumeStockProductModel();
        item.AddedBy = UserInfo.EmailID;
        item.ProductId = ip.ProductId;
        item.RackId = ip.RackId;
        item.StoreId = ip.StoreId;
        item.Quantity = ip.ConsumptionQuantity?.toString();
        item.StockId = ip.StockId;
        item.StockProductId = ip.StockProductId;
        item.SaleOrderId = ip.SaleOrderId;
        item.Purpose = "Pigment MB: " + this.AddMBF.MbformulationProductName
        item.Unit = ip.Unit;
        item.IsDamaged = ip.IsDamaged;
        if (ip.ConsumptionQuantity > 0) {
          consumequantity = consumequantity + ip.ConsumptionQuantity;
          mbr.ConsumeStockForRawMaterial.push(item);
        }
      });
      if (consumequantity != x.QuantityBatchSizeInKg) {
        saveflag = false;
      }

      this.AddMBF.MbformulationRawMaterialProduct.push(mbr);
    })

    var aurl = "mbformulation/addmbformulationproduct";
    // if (this.MixingId > 0) {
    //   aurl = "mixing/updatemixing";
    // }
    let url = this.ApiUrl + aurl;
    if (saveflag) {
      this.http.post<MbformulationProductModel>(url, this.AddMBF).subscribe({
        next: res => {
          this.alertService.success(res); this.isLoading = false;
          this.GetMBformulation();
          this.clearcache();
        },
        error: res => { this.alertService.error(res.error.ResponseBody); this.isLoading = false; },

      });
    }
    else {
      this.isLoading = false;
      this.alertService.error("Defined quantity is not equal to consumption quantity");
    }

  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.PigmentMB, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.PigmentMB, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.PigmentMB, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetMBformulation();
    this.validateForm = this.fb.group({});
    this.GetAllProducts();
    this.GetAllStore();
    this.GetAllRack();
    this.GetAllProductCategory();
  }
  get f() { return this.validateForm.controls; }

  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res;
      this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  handleCancel(): void {
    this.isVisible = false;
  }

  onSelectedStoreChange(data: any) {
    this.RackList = this.RackListOriginal.filter((x) => x.StoreId == data);
    this.RackID = 0;
  }

  GetAllStore() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }

  onSelectedFromStoreChange(data: any) {
    this.isTableLoading = true;
    let url =
      this.ApiUrl +
      'consume/getallproductstoconsumebystoreid/' + data;
    this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(
      (res) => {
        this.StoreWiseStock = res.filter((x) => x.Quantity > 0);
        if (this.productionRawMaterial.length > 0) {
          this.productionRawMaterial.forEach(item => {
            item.IssueProduct = this.StoreWiseStock.filter(x => x.ProductId == item.RawMaterialProductId);
          })
        }
        this.consumeStockColumnMsg = "No stock available in selected store.";
        this.isTableLoading = false;
      },
      (res) => { }
    );
  }

  showstock(data) {
    return this.StoreWiseStock.filter(x => x.ProductId == data).length > 0;
  }

  getstock(data) {
    var res = this.StoreWiseStock.filter(x => x.ProductId == data);
    var resdata = this.productionRawMaterial.filter(x => x.RawMaterialProductId == data)[0];
    resdata.IssueProduct = res;
    return res;
  }

  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'rack/getallracks';
    this.http.get<RackModel[]>(url).subscribe(
      (res) => {
        this.RackList = res;
        this.RackListOriginal = res;
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllRack();
        }
      }
    );
  }


  clearcache() {
    this.listOfSelectedproducts = [];
    this.productionRawMaterial = [];
    this.NewPO = new MbformulationMasterModel;
    this.AddMBF = new MbformulationProductModel;
    this.selectedMBFormulation = null;
    this.StoreID = 0;
    this.RackID = 0;
    this.ProductCategoryId = 0;
    this.ProductFirstSubCategoryId = 0;
    this.ProductSecSubCategoryId = 0;
    this.StoreWiseStock = [];
  }

  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(); }
    });

  }
  GetAllFirstCategory(data: any) {

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);

    }, res => { });
  }
  GetAllSecondCategory(data: any) {

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);

    }, res => { });
  }

  calculatetotalQty() {
    var res = this.productionRawMaterial.reduce((accumulator, obj) => {
      return (accumulator + obj.QuantityBatchSizeInKg);
    }, 0);

    return parseFloat(res.toString()).toFixed(3);
  }

  CalculateBatchChange() {
    this.productionRawMaterial.forEach(x => {
      x.QuantityBatchSizeInKg = parseFloat(((x.QuantityInKg / this.productionRawMaterial[0].QuantityInKg) * this.NewPO.BatchSize).toFixed(2));
    })
  }

  CalculateQuantityBatchSizeInKg(data) {
    if (this.NewPO.BatchSize == 0) {
      this.alertService.error("Please enter batch size");
      return;
    }
    var res = this.productionRawMaterial.filter(x => x.RawMaterialProductId == data.RawMaterialProductId)[0];
    res.QuantityBatchSizeInKg = parseFloat(((data.QuantityInKg / this.productionRawMaterial[0].QuantityInKg) * this.NewPO.BatchSize).toFixed(2));
  }

  OnMBFormulationDdlchange(val: any) {
    this.listOfSelectedproducts = [];
    this.NewPO = this.MbformulationMasterList.filter(x => x.MbformulationId == val)[0];
    this.productionRawMaterial = this.NewPO.MbformulationRawMaterial;
    this.productionRawMaterial.forEach(x => {
      x.IssueProduct = this.StoreWiseStock.filter(y => y.ProductId == x.RawMaterialProductId);
      this.listOfSelectedproducts.push(x.RawMaterialProductId);
    });
  }

  OnProductDdlchange() {
    this.consumeStockColumnMsg = "Select consumption store to see available stock.";
    if (this.listOfSelectedproducts.length < this.productionRawMaterial.length) {
      let filteredpid: number[] = [];
      this.productionRawMaterial.forEach(x => {
        filteredpid.push(x.RawMaterialProductId);
      })
      let missing = filteredpid.filter(item => this.listOfSelectedproducts.indexOf(item) < 0)[0];
      var d = this.productionRawMaterial.filter(x => x.RawMaterialProductId == missing)[0]
      this.productionRawMaterial = this.productionRawMaterial.filter(obj => obj !== d);
    }
    else {
      this.listOfSelectedproducts.forEach(item => {
        var checkexist = this.productionRawMaterial.filter(x => x.RawMaterialProductId == item).length;
        if (checkexist == 0) {
          var product = this.ProductRawList.filter(x => x.ProductId == item)[0];
          var rawproduct = new MbformulationRawMaterialMasterModel;
          rawproduct.RawMaterialProductId = item;

          rawproduct.RawMaterialProductName = product.ProductName;
          rawproduct.RawMaterialProductCode = product.ProductCode;
          rawproduct.QuantityInKg = 0;
          rawproduct.QuantityBatchSizeInKg = 0;
          rawproduct.IssueProduct = this.StoreWiseStock.filter(x => x.ProductId == rawproduct.RawMaterialProductId);
        }
        if (this.productionRawMaterial.filter(x => x.RawMaterialProductId == item).length == 0) {
          this.productionRawMaterial.push(rawproduct);
        }

      })
    }
  }

}
