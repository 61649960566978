<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Product Wise Stock</nz-page-header-title>
      <nz-page-header-subtitle>
        View your product wise stock
      </nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Type</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType" (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                  <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                  <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                  <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Category</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                  <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>SubCategory</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllSecondCategory($event)">
                  <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>2nd SubCategory</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="SecondCategoryID" (ngModelChange)="GetSecondCategoryFilteredProduct()">
                  <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Name</label>
                <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="selectedProduct" name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.FilteredPProductList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <!--<div nz-col [nzSpan]="3">

          <nz-form-item>
            <nz-form-control [nzSpan]="24">
              <label>Unit</label>
              <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="NewStockProduct.Unit" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>-->

          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetReport()">
              Search
            </button>
          </div>

        </div>


      </div>
    </div>
    <div style="padding: 8px; float: right">
      <!--<input type="text"
             style="width: 250px; margin-right: 8px"
             nz-input
             placeholder="Search Product ProductType/ProductName/Quantity"
             [(ngModel)]="searchValue"
             (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button
              nzSize="small"
              nzType="primary"
              (click)="search()"
              style="margin-right: 8px">-->
        <!-- [disabled]="!searchValue" -->
        <!--Search
      </button>
      <button nz-button nzSize="small" (click)="reset()">-->
        <!-- [disabled]="!searchValue" -->
        <!--Reset
      </button>-->
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button>
    </div>
    <nz-table #basicTable
              [nzScroll]="{ x: '1200px', y: '515px' }"
              style="width: 100%"
              [nzData]="['']"
              nzSize="small"
              [nzPageSize]="100"
              [nzPageSize]="12"
              nzBordered>
      <thead>
        <tr>
          <th nzWidth="30px">SNo.</th>
          <th nzWidth="80px">Product Type</th>
          <th nzWidth="120px">Product Name</th>
          <th nzWidth="120px">Total Quantity</th>
          <th nzWidth="120px">Store Name</th>
          <th nzWidth="120px">Rack Name</th>
          <th nzWidth="120px">Quantity Per Rack</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.FilteredStoreWiseList; let i = index">
          <td style="
              border-bottom: 1px solid #415994;
              border-left: 1px solid #415994;
            ">
            {{ i + 1 }}
          </td>
          <td style="
              border-bottom: 1px solid #415994;
              border-left: 1px solid #415994;
            ">
            {{ data.ProductType }}
          </td>
          <td style="border-bottom: 1px solid #415994">
            {{ data.ProductName }}
          </td>
          <td style="border-bottom: 1px solid #415994">{{ data.Quantity }} / {{ data.Unit }}</td>
          <td style="
              border-bottom: 1px solid #415994;
              padding: 0;
              border-right: 1px solid #415994;
            "
              nzWidth="360px"
              colspan="3">
            <table style="width: 100%">
              <tr *ngFor="
                  let d of data.StoreDetailsCopy.length
                    ? data.StoreDetailsCopy
                    : data.StoreDetails
                ">
                <td Width="120px" style="padding: 5px; border: 1px solid #eee">
                  {{ d.StoreName }}
                </td>
                <td Width="120px" style="padding: 5px; border: 1px solid #eee">
                  {{ d.RackName }}
                </td>
                <td Width="120px" style="padding: 5px; border: 1px solid #eee">
                  {{ d.Quantity }} / {{ d.Unit }}
                </td>
              </tr>
            </table>
          </td>
          <!--<td>
                      <span *ngFor="let el of productStoreData[data.ProductName]">
                          <span *ngIf="el.storeData.length"> {{el.storeName}}
                              <hr>
                          </span>
                      </span>
                  </td>
                  <td>
                      <span *ngFor="let el of productStoreData[data.ProductName]">
                          <span *ngIf="el.storeData.length">
                              <span *ngFor="let data of el.storeData"> {{
                                  data.racks}} <br></span>
                              <hr>
                          </span>
                      </span>

                  </td>
                  <td>
                      <span *ngFor="let el of productStoreData[data.ProductName]">
                          <span *ngIf="el.storeData.length">
                              <span *ngFor="let data of el.storeData"> {{
                                  data.rackQuantity}} <br> </span>
                              <hr>
                          </span>
                      </span>
                  </td>-->
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
