<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Product 2nd SubCategory List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your 2nd SubCategory here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary"  *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.ProductSecSubCategoryList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th>Category</th>
          <th nzWidth="150px">Sub Category</th>
          <th nzWidth="150px">2nd Sub-Category</th>
          <th>Description</th>
          <th>AddedDate</th>
          <th>AddedBy</th>

          <th nzWidth="20%"  *ngIf="permission.Add || permission.Delete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.ProductCategory }}</td>
          <td>{{ data.ProductFirstSubCategory }}</td>
          <td>{{ data.ProductSecSubCategory }}</td>

          <td>{{ data.ProductSecSubCategoryDesc }}</td>
          <td>{{ data.ProductSecSubCategoryAddedDate |DatetimeConverter}}</td>
          <td>{{ data.ProductSecSubCategoryAddedBy }}</td>



          <td  *ngIf="CheckUserAddPermission(data.ProductSecSubCategoryAddedBy )">
            <button class="btn btn-sm btn-light-primary" [disabled]="enableEditDeleteBtn('Edit')" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button class="btn btn-sm btn-light-danger" [disabled]="enableEditDeleteBtn('Delete')" (click)="handleDelete(data)">Delete</button>

          </td>
        </tr>
      </tbody>
    </nz-table>


    <nz-modal [nzWidth]="800"
              [nzStyle]="{ top: '20px' }"
              [(nzVisible)]="isVisible"
              [nzTitle]="modalTitle"
              [nzContent]="modalContent"
              [nzFooter]=null
              (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8"nzRequired>Product 2nd SubCategory Name</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid SubCategory Name">
              <input nz-input formControlName="ProductSecSubCategoryName" name="ProductSecSubCategoryName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8"nzRequired>Category</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Category">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select mb-2" nzShowSearch formControlName="ProductCategoryId" name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetFilteredFC($event)">
                <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8"nzRequired>First Category</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Sub Category">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select mb-2" nzShowSearch formControlName="ProductFirstSubCategoryId" name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.FilteredFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8"nzRequired>Description</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Description required">
              <textarea rows="4" nz-input formControlName="ProductSecSubCategoryDesc" name="ProductSecSubCategoryDesc"></textarea>
            </nz-form-control>
          </nz-form-item>
          -->
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoading">Save Sub Category</button>
          </div>

        </form>
      </ng-template>
    </nz-modal>

  </div>
</div>

