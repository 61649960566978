import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { BranchModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import * as moment from 'moment';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  BranchList: BranchModel[] = [];
  BranchListOriginal: BranchModel[] = [];
  NewBranch: BranchModel = new BranchModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Branch (maximum 50 characters)";
  BranchNameError = 'Enter Branch name';
  BranchCodeError = 'Enter Branch code';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  options = {
    //fieldSeparator: ',',
    //quoteStrings: '"',
    //decimalseparator: '.',
    //showLabels: true,
    //showTitle: true,
    //title: 'Your title',
    //useBom: true,
    //noDownload: true,
    headers: ["Sno.", "Branch Name", "Branch Code", "Branch Desc", "Added Date", "Added By"]
    //useHeader: false,
    //nullToEmptyString: true,
  };
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }



  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Branch, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Branch, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Branch, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    console.log(this.permission)
    this.validateForm = this.fb.group({
      BranchName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)], [this.BranchNameAsyncValidator]],
      BranchCode: [null, [Validators.required], [this.BranchCodeAsyncValidator]],
      BranchDesc: [null, [Validators.maxLength(200)]]

    });
    this.GetAllBranch();
  }
  get f() { return this.validateForm.controls; }
  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }
  GetAllBranch() {


    this.isTableLoading = true;
    let url = this.ApiUrl + "branch/getallbranches";
    this.http.get<BranchModel[]>(url).subscribe(res => {
      this.BranchList = res;
      this.BranchListOriginal = res

      this.isTableLoading = false;

      //new AngularCsv(this.BranchList, 'branch-export', this.options);
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllBranch();
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllBranch();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.BranchListOriginal;
    var res2 = this.BranchListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: BranchModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.BranchAddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.BranchAddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.BranchList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.BranchList = res.filter(
        (item: BranchModel) =>
          item?.BranchName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.BranchCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.BranchAddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.BranchDesc?.toLowerCase().includes(this.searchValue?.toLowerCase())


      )
    }

  }

  SaveBranch() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewBranch.BranchName = this.f['BranchName'].value;
    this.NewBranch.BranchCode = this.f['BranchCode'].value;
    this.NewBranch.BranchDesc = this.f['BranchDesc'].value;
    this.NewBranch.BranchAddedBy = UserInfo.EmailID;

    let url = this.ApiUrl + "Branch/addupdateBranch";
    this.http.post<BranchModel>(url, this.NewBranch).subscribe({

      next: res => { this.alertService.success("Branch Saved Successfully"); this.isLoading = this.isVisible = false; this.GetAllBranch(); },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: BranchModel) {
    this.PopUpTitle = "Modify Branch Details";
    this.validateForm.setValue(
      {
        BranchName: data.BranchName,
        BranchCode: data.BranchCode,
        BranchDesc: data.BranchDesc,

      }
    )
    this.NewBranch = new BranchModel();
    this.NewBranch.BranchId = data.BranchId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Branch";
    this.validateForm.reset();
    this.NewBranch.BranchName = "";
    this.NewBranch.BranchId = 0;
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SaveBranch();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeleteBranch() {
    let url = this.ApiUrl + "Branch/deleteBranch";
    this.http.post<any>(url, this.NewBranch.BranchId).subscribe({

      next: res => {

        this.alertService.success("Branch Deleted Successfully"); this.isLoading = this.isVisible = false;
        this.GetAllBranch();

      },
      error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(data: BranchModel) {
    this.NewBranch = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Branch?',
      nzOnOk: () => this.DeleteBranch(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }

  BranchNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.BranchList.filter(x => x.BranchId != this.NewBranch.BranchId);
        var nre = res.filter(x => x.BranchName.toLowerCase() == control.value.toLowerCase());
        this.BranchNameError = '';

        if (control.value == '') {
          this.BranchNameError = "Enter Branch name (maximum 50 characters)";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.BranchNameError = "Branch name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.BranchNameError = "Enter Branch name (maximum 50 characters)";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  BranchCodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.BranchList.filter(x => x.BranchId != this.NewBranch.BranchId);
        var nre = res.filter(x => x.BranchCode.toLowerCase() == control.value.toLowerCase());

        this.BranchCodeError = '';
        if (control.value == '') {
          this.BranchCodeError = "Enter Branch code";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.BranchCodeError = "Branch code already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.BranchCodeError = "Enter Branch code";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
}
