<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

<nz-page-header [nzGhost]="false">
    <nz-page-header-title>Stock Manage rejected items</nz-page-header-title>
    <nz-page-header-subtitle>Manage you rejected stock items here</nz-page-header-subtitle>
  </nz-page-header>
  <nz-divider></nz-divider>

  <nz-descriptions nzBordered>
    <nz-descriptions-item nzTitle="Supplier Name">{{NewStock.Invoice.SupplierName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Stock Date"> {{NewStock.StockDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Number">{{NewStock.Invoice.InvoiceNumber}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Date">{{NewStock.Invoice.InvoiceDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Total Price" [nzSpan]="2">
        {{NewStock.Invoice.InvoiceTotalPrice}}
    </nz-descriptions-item>
  </nz-descriptions>

<br><br>
<nz-table   [nzPageSize]="100"#basicTable [nzData]="this.NewStock.StockProduct" [nzLoading]="this.isPageLoading">
    <thead>
      <tr>
        <th></th>
        <th>Product Type</th>
        <th>Product Name</th>
        <th>SKU</th>
        <th>Barcode</th>
        <th>Mfd Date</th>
        <th>Expiry</th>
        <th>Unit</th>
        <th>Quantity</th>
        <th>Per Unit Price</th>
        <th>Grade</th>
        <th>Accepted Qty</th>
        <th style="width: 8%;">Rejected Qty</th>
      </tr>
    </thead>
    <tbody>
        <ng-template ngFor let-data [ngForOf]="basicTable.data">
          <tr [ngStyle]="{'background-color': data.RejectedQuantity<=0 ? '#dde9e3' : null }">
            <td *ngIf="data.RejectedQuantity>0" [(nzExpand)]="data.ExpandRecord"></td>
            <td *ngIf="data.RejectedQuantity<=0" ></td>
            <td>{{ data.ProductType }}</td>
            <td>{{ data.ProductName }}</td>
            <td>{{ data.Sku }}</td>
            <td>{{ data.Barcode }}</td>
            <td>{{ data.ManufacturedDate  | date: 'dd-MMM-yyyy' }}</td>
            <td>{{ data.ExpiryDate  | date: 'dd-MMM-yyyy' }}</td>
            <td>{{data.Unit}}</td>
            <td>{{data.Quantity}}</td>
            <td>{{data.PricePerUnit}}</td>
            <td>{{data.Grade}}</td>
            <td>{{data.AcceptedQuantity}}</td>
            <td>{{data.RejectedQuantity}}</td>
          </tr>
            <tr [nzExpand]="data.ExpandRecord" *ngIf="data.RejectedQuantity>0">
              <table style="table-layout: auto; background: white; width:100%;" >
                <thead class="ant-table-thead ng-star-inserted">
                  <tr class="ant-table-row ng-star-inserted">

                    <th style="background: aliceblue; " class="ant-table-cell">Store</th>
                    <th style="background: aliceblue; " class="ant-table-cell">Rack</th>
                    <th style="background: aliceblue; width: 100px; " class="ant-table-cell">QTY</th>
                    <th style="background: aliceblue;  width: 120px;" class="ant-table-cell">Accept QTY</th>
                    <th style="background: aliceblue; width: 120px" class="ant-table-cell">Dispose QTY</th>
                    <th style="background: aliceblue; width: 120px" class="ant-table-cell">Dispatch QTY</th>
                    <th style="background: aliceblue; width: 120px" class="ant-table-cell">Company</th>
                    <th style="background: aliceblue; width: 120px" class="ant-table-cell">Vehicle No.</th>
                    <th style="background: aliceblue; width: 120px" class="ant-table-cell">Dispatch No.</th>
                    <th style="background: aliceblue; width: 120px" class="ant-table-cell">
                      <a nz-button nzSize="small" nzType="primary" class="btn btn-light-success" (click)="SaveManageRejectItems(data)">Save</a>
                    </th>
                  </tr>
                </thead>
                <tbody class="ant-table-tbody ng-star-inserted">
                  <tr *ngFor="let alloc of data.StockProductAllocation | callback:filterRejected" class="ant-table-row ng-star-inserted">

                    <td class="ant-table-cell">{{alloc.StoreName}}</td>
                    <td class="ant-table-cell">{{alloc.RackName}}</td>
                    <td class="ant-table-cell">{{alloc.Quantity}}</td>
                    <td class="ant-table-cell"><input type="text" nz-input [(ngModel)]="alloc.AcceptQTY" (change)="validateModel(alloc,1)" nzSize="default"  class="form-control mb-2" /></td>
                    <td class="ant-table-cell"><input type="text" nz-input [(ngModel)]="alloc.DisposeQTY" nzSize="default" (change)="validateModel(alloc,2)" class="form-control mb-2" /></td>
                    <td class="ant-table-cell"><input type="text" nz-input [(ngModel)]="alloc.DispatchQTY" nzSize="default" (change)="validateModel(alloc,3)" class="form-control mb-2" /></td>
                    <td class="ant-table-cell">
                      <nz-select nzShowSearch *ngIf="alloc.DispatchQTY>0" class="form-select mb-2" (ngModelChange)="onSelectedTransportChange($event)" [(ngModel)]="alloc.TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId" [nzLabel]="s.TransportCompanyName"></nz-option>
                      </nz-select>
                    </td>
                    <td class="ant-table-cell">
                      <nz-select nzShowSearch *ngIf="alloc.DispatchQTY>0" class="form-select mb-2" [(ngModel)]="alloc.VehicleId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId" [nzLabel]="s.VehicleNumber"></nz-option>
                        <nz-option nzValue="0" nzLabel="Others"></nz-option>
                      </nz-select>
                    </td>
                    <td class="ant-table-cell">
                      <input type="text" nz-input *ngIf="alloc.DispatchQTY>0" nzSize="default" [disabled]="alloc.DispatchQTY<=0" [(ngModel)]="alloc.DispatchId" class="form-control mb-2" />
                    </td>

                    <td>
                      
                    </td>
                  </tr>
                </tbody>

              </table>
            <table style="table-layout: auto; background: white; width:100%;display:none" ng-reflect-list-of-col-width=",,,20%">
              <thead class="ant-table-thead ng-star-inserted">
                <tr class="ant-table-row ng-star-inserted">
                  <th style="background: aliceblue; width: 15%;" class="ant-table-cell">Quantity</th>
               
                  <th style="background: aliceblue;  width: 15%;" class="ant-table-cell">Action</th>
                  <th style="background: aliceblue; width: 50;" class="ant-table-cell">Dispact Details</th>
                  <th style="background: aliceblue; width: 20%;" class="text-end ant-table-cell">
                    <a nz-button nzSize="small" nzType="primary" class="btn btn-light-primary" (click)="data.NewRecord = true">Add</a> &nbsp;
                    <a nz-button nzSize="small" nzType="primary" class="btn btn-light-success" (click)="SaveManageRejectItemtoDB(data)">Save</a>
                  </th>
                </tr>
            </thead>
            <tbody class="ant-table-tbody ng-star-inserted">
             
              <tr *ngIf="data.NewRecord" class="ant-table-thead ng-star-inserted">
                <td>
                  <input style="width: 70%;" type="text" nz-input nzSize="default" [(ngModel)]="NewManageRejectItem.Quantity" class="form-control mb-2" />
                </td>
               
                <td>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewManageRejectItem.ItemAction" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option nzValue="Accept" nzLabel="Accept"></nz-option>
                    <nz-option nzValue="Dispatch" nzLabel="Dispatch"></nz-option>
                    <nz-option nzValue="Dispose" nzLabel="Dispose"></nz-option>
                  </nz-select>
                </td>
                <td>
                  <!--<nz-descriptions nzBordered *ngIf="NewManageRejectItem.ItemAction=='Accept'">
      <nz-descriptions-item nzTitle="" [nzSpan]="8">

        <div class="row gx-10 mb-5">
          <div class="col-lg-6">
            <label class=" form-label">Store </label>
            <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()" [(ngModel)]="StoreID" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-6">
            <label class=" form-label">Rack.</label>
            <nz-select nzShowSearch class="form-select mb-2"  nzSize="default" [(ngModel)]="RackID" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>

            </nz-select>
          </div>
        </div>

      </nz-descriptions-item>-->
                  <!--<nz-descriptions-item nzTitle="Dispatch No." [nzSpan]="2">
      <input type="text" nz-input nzSize="default" [disabled]="NewManageRejectItem.ItemAction != 'Dispatch'" [(ngModel)]="NewManageRejectItem.StockProductRejectedDispatch.DispatchId" class="form-control mb-2" />
    </nz-descriptions-item>-->
                  <!--</nz-descriptions>-->
                  <nz-descriptions nzBordered *ngIf="NewManageRejectItem.ItemAction=='Dispatch'">
                    <nz-descriptions-item nzTitle="Transport" [nzSpan]="8">

                      <div class="row gx-10 mb-5">
                        <div class="col-lg-6">
                          <label class=" form-label">Company </label>
                          <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedTransportChange($event)" [(ngModel)]="NewManageRejectItem.StockProductRejectedDispatch.TransportId" nzSize="default" [nzDisabled]="NewManageRejectItem.ItemAction != 'Dispatch'" nzAllowClear nzPlaceHolder="Choose">
                            <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId" [nzLabel]="s.TransportCompanyName"></nz-option>
                          </nz-select>
                        </div>
                        <div class="col-lg-6">
                          <label class=" form-label">Vehicle No.</label>
                          <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="NewManageRejectItem.StockProductRejectedDispatch.VehicleId" nzSize="default" [nzDisabled]="NewManageRejectItem.ItemAction != 'Dispatch'" nzAllowClear nzPlaceHolder="Choose">
                            <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId" [nzLabel]="s.VehicleNumber"></nz-option>
                            <nz-option nzValue="0" nzLabel="Others"></nz-option>
                          </nz-select>
                        </div>
                      </div>

                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Dispatch No." [nzSpan]="2">
                      <input type="text" nz-input nzSize="default" [disabled]="NewManageRejectItem.ItemAction != 'Dispatch'" [(ngModel)]="NewManageRejectItem.StockProductRejectedDispatch.DispatchId" class="form-control mb-2" />
                    </nz-descriptions-item>
                  </nz-descriptions>
                  <nz-descriptions nzBordered *ngIf="NewManageRejectItem.ItemAction=='Dispose'">
                   
                    <nz-descriptions-item nzTitle="Comment" [nzSpan]="2">
                      <input type="text" nz-input nzSize="default" class="form-control mb-2" [(ngModel)]="NewManageRejectItem.Comment" />
                    </nz-descriptions-item>
                  </nz-descriptions>
                </td>
                <td class="text-end">
                  <div class="text-nowrap">
                    <button type="button" data-repeater-delete="" nzSize='small' class="btn btn-sm btn-icon btn-light-success" (click)="SaveManageRejectItem(data)">
                      <span class="svg-icon svg-icon-2">
                        <i nz-icon nzType="check" nzTheme="outline"></i>
                      </span>
                    </button>&nbsp;
                    <button type="button" data-repeater-delete="" nzSize='small' class="btn btn-sm btn-icon btn-light-danger" (click)="data.NewRecord = false">
                      <span class="svg-icon svg-icon-2">
                        <i nz-icon nzType="close" nzTheme="outline"></i>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
             
              <tr *ngFor="let alloc of data.StockProductManageRejected" class="ant-table-row ng-star-inserted">
                <td class="ant-table-cell">{{alloc.Quantity}}</td>
                <td class="ant-table-cell">{{data.StockProductAllocation[0].StoreName}}</td>
                <td class="ant-table-cell">{{data.StockProductAllocation[0].RackName}}</td>
                <td class="ant-table-cell">{{alloc.ItemAction}}</td>
                <td class="ant-table-cell">
                  <span *ngIf="alloc.ItemAction == 'Accept'">N/A</span>
                  <span *ngIf="alloc.ItemAction == 'Dispose'">{{alloc.Comment}} </span>
                  <span *ngIf="alloc.ItemAction == 'Dispatch' && alloc.StockProductRejectedDispatch != null">
                    <b>Dispatch id : </b>{{alloc.StockProductRejectedDispatch.DispatchId}} ; <b>Transport Company : </b>{{alloc.StockProductRejectedDispatch.TransportCompany}}; <b>Vehicle No : </b>{{alloc.StockProductRejectedDispatch.VehicleNumber == null ? 'Others' : alloc.StockProductRejectedDispatch.VehicleNumber }}
                  </span>
                </td>
                <td class="text-end ant-table-cell">
                  <!-- <button type="button" data-repeater-delete="" nzsize="small" class="btn btn-sm btn-icon btn-light-danger">
      <span class="svg-icon svg-icon-2">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </span>
    </button> -->
                  <a nz-button nzSize="small" nzType="primary" *ngIf="alloc.Id == 0" class="btn btn-light-danger" (click)="RemoveManageRejectItem(data, alloc)">Remove</a>
                </td>
              </tr>
            </tbody>
            </table>


            </tr>
          </ng-template>

    </tbody>
  </nz-table>
<br>
  <div class="d-flex justify-content-end">
    <!--begin::Button-->
    <a routerLink="/home/stockmanagerejectedlist" class="btn btn-success">Back to Stock List</a> 
    <!--end::Button-->
</div>

  </div>
</div>


