<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">


    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Transport List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your transport here</nz-page-header-subtitle>
      <nz-page-header-extra>
        
        <button nz-button nzType="primary" (click)="showModal()" *ngIf="this.permission.Add">Add New</button>
      </nz-page-header-extra>
     
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search Vehicle / Transport  " [(ngModel)]="searchValue"    
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i> Export</button>
    </div>
    <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.TransportList" [nzLoading]="isTableLoading" nzBordered [nzScroll]="{ x: '1200px',y:'515px' }" >
      <thead>
        <tr>
          <th nzLeft nzWidth="50px"></th>
          <th nzWidth="250px">Company Name</th>

          <th nzWidth="350px">Email</th>
          <th nzWidth="150px">Contact</th>

          <th nzWidth="200px">GST</th>
          <th nzWidth="800px">Address</th>
          <th nzWidth="300px" style="text-align:center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
          <ng-template ngFor let-data [ngForOf]="basicTable.data">
          <tr >
              <td nzLeft [(nzExpand)]="data.ExpandRecord"></td>
          <td>{{ data.TransportCompanyName }}</td>
          
          <td>{{ data.TransportCompanyEmail }}</td>
          <td>{{ data.TransportCompanyContact }}</td>
        
          <td>{{ data.Gst }}</td>
          <td>{{ data.TransportCompanyAddress }}</td>
          
          <td nzRight>
            <a class="btn btn-sm btn-light-primary" (click)="showModalV(data)">Add Vehicle</a> &nbsp; 
            <button  class="btn btn-sm btn-light-info" (click)="OpenEditPop(data)">Edit</button> &nbsp;
            <button  class="btn btn-sm btn-light-danger" [disabled]="!this.permission.Delete" (click)="handleDelete(data)">Delete</button>
            
          </td>
          </tr>
          <tr [nzExpand]="data.ExpandRecord">
              <table nzSize="small" style="width: 80%; background-color: white;" nzBordered>
                <thead class="ant-table-thead ng-star-inserted">
                  <tr class="ant-table-row ng-star-inserted">
                  <th style="background: aliceblue; width: 40%; padding-left: 20px;" class="ant-table-cell">Vehicle Type</th>
                  <th style="background: aliceblue;  width: 30%;" class="ant-table-cell">Vehicle Number</th>
                  <th style="background: aliceblue;  width: 30%;" class="ant-table-cell">Action</th>
                 
                </tr>
              </thead>
              <tbody class="ant-table-tbody ng-star-inserted">
                 
                  <tr *ngFor="let alloc of data.TransportVehicle" class="ant-table-row ng-star-inserted">
                    <td class="ant-table-cell" style="padding-left: 20px;">{{alloc.VehicleType}}</td>
                    <td class="ant-table-cell">{{alloc.VehicleNumber}}</td>
                    <td>
                      
                    <button  class="btn btn-sm btn-light-primary" (click)="OpenEditPopV(alloc)">Edit</button> &nbsp;
                    <button  class="btn btn-sm btn-light-danger" [disabled]="!this.permission.Delete" (click)="handleDeleteV(alloc)">Delete</button>
                    
                  </td>
                  </tr>
                </tbody>
              </table>
  
  
              </tr>
          </ng-template>
      </tbody>
    </nz-table>
    
    <nz-modal
    [nzWidth]="800"
    [nzStyle]="{ top: '20px' }"
        [(nzVisible)]="isVisible"
        [nzTitle]="modalTitle"
        [nzContent]="modalContent"
        [nzFooter]=null
        (nzOnCancel)="handleCancel()"
      >
        <ng-template #modalTitle>{{PopUpTitle}}</ng-template>
  
        <ng-template #modalContent>
          <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
              <nz-form-item>
                
                  <nz-form-label [nzSpan]="8" nzRequired>Company Name</nz-form-label>
                  <nz-form-control  [nzSpan]="14" [nzErrorTip]="NameError">
                    <input nz-input formControlName = "TransportCompanyName" name="TransportCompanyName" />
                  </nz-form-control>
              </nz-form-item>
            
             <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Company Contact Number</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Contact Number">
                  <input nz-input formControlName = "TransportCompanyContact" type="number" name="TransportCompanyContact" />
                </nz-form-control>
            </nz-form-item>
            
            <nz-form-item>
              <nz-form-label [nzSpan]="8">Email</nz-form-label>
              <nz-form-control  pattern="emailPattern" [nzSpan]="14" nzErrorTip="Invalid Email">
                <input nz-input formControlName = "TransportCompanyEmail" name="TransportCompanyEmail" />
              </nz-form-control>
          </nz-form-item>
         
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Company Address</nz-form-label>
            <nz-form-control  [nzSpan]="14" nzErrorTip="Transport Company Address required">
              <input nz-input formControlName= "TransportCompanyAddress" name="TransportCompanyAddress" />
            </nz-form-control>
        </nz-form-item>
        
          <nz-form-item>
          <nz-form-label [nzSpan]="8">GST / TAX</nz-form-label>
          <nz-form-control  [nzSpan]="14" nzErrorTip="Valid GST number required">
              <input nz-input formControlName = "Gst" name="Gst" />
          </nz-form-control>
          </nz-form-item> 
  
  <div class="text-center">
  <button nz-button nzType="primary" [nzLoading]="isLoading">Save Transport</button> 
  </div>
  
          </form>
        </ng-template>
  
       
      </nz-modal>
  
      <nz-modal
    [nzWidth]="500"
    [nzStyle]="{ top: '20px' }"
        [(nzVisible)]="isVisibleV"
        [nzTitle]="modalTitleV"
        [nzContent]="modalContentV"
        [nzFooter]=null
        (nzOnCancel)="handleCancelV()"
      >
        <ng-template #modalTitleV>{{PopUpTitleV}}</ng-template>
  
        <ng-template #modalContentV>
          <form nz-form [formGroup]="validateFormV" (ngSubmit)="handleOkV()">
              <nz-form-item>
                
                  <nz-form-label [nzSpan]="8">Vehicle Number</nz-form-label>
                  <nz-form-control  [nzSpan]="14" nzErrorTip="Enter valid Vehicle Number">
                    <input nz-input #VehicleNumber formControlName = "VehicleNumber" name="VehicleNumber" (input)="VehicleNumber.value = VehicleNumber.value.toUpperCase()"/>
                  </nz-form-control>
              </nz-form-item>
            
             <nz-form-item>
                <nz-form-label [nzSpan]="8">Vehicle Type</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Vehicle Type">
                  <input nz-input formControlName = "VehicleType" name="VehicleType" />
                </nz-form-control>
            </nz-form-item>
            
          
  
          <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoadingV">Save Vehicle</button> 
          </div>
  
          </form>
        </ng-template>
  
       
      </nz-modal>

  </div>
</div>

