<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

<nz-page-header [nzGhost]="false">
    <nz-page-header-title>Stock Inspection</nz-page-header-title>
    <nz-page-header-subtitle>Inspect your Stocks details here</nz-page-header-subtitle>
  </nz-page-header>
  <nz-divider></nz-divider>

  <nz-descriptions nzBordered>
    <nz-descriptions-item nzTitle="Supplier Name">{{NewStock.Invoice.SupplierName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Stock Date"> {{NewStock.StockDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Number">{{NewStock.Invoice.InvoiceNumber}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Invoice Date">{{NewStock.Invoice.InvoiceDate  | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Total Price" [nzSpan]="2">
        {{NewStock.Invoice.InvoiceTotalPrice}}
    </nz-descriptions-item>
  </nz-descriptions>

<br><br>
<nz-table  [nzPageSize]="100" #basicTable [nzData]="this.NewStock.StockProduct">
    <thead>
      <tr>
        <th>Product Type</th>
        <th>Product Name</th>
        <th>SKU</th>
        <th>Barcode</th>
        <th>Mfd Date</th>
        <th>Expiry</th>
        <th>Unit</th>
        <th>Quantity</th>
        <th>Per Unit Price</th>
        <th>Grade</th>
        <th>Accepted Qty</th>
        <th style="width: 8%;">Rejected Qty</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data.ProductType }}</td>
        <td>{{ data.ProductName }}</td>
        <td>{{ data.Sku }}</td>
        <td>{{ data.Barcode }}</td>
        <td>{{ data.ManufacturedDate  | date: 'dd-MMM-yyyy' }}</td>
        <td>{{ data.ExpiryDate  | date: 'dd-MMM-yyyy' }}</td>
        <td>{{data.Unit}}</td>
        <td>{{data.Quantity}}</td>
        <td>{{data.PricePerUnit}}</td>
        <td>{{data.Grade}}</td>
        <td>{{data.AcceptedQuantity}}</td>
        <td>
            <input nz-input *ngIf="!this.NewStock.InspectionCompleted" (blur)="rejectedchange(data)" [(ngModel)] = "data.RejectedQuantity"/>
            <span *ngIf="this.NewStock.InspectionCompleted" class="fw-bolder">{{data.RejectedQuantity}}</span>
        </td>
        <td>
            <input nz-input *ngIf="!this.NewStock.InspectionCompleted" [(ngModel)] = "data.Comments"/>
            <span *ngIf="this.NewStock.InspectionCompleted" class="fw-bolder">{{data.Comments}}</span>
        </td>
      </tr>
    </tbody>
  </nz-table>
<br>
  <div class="d-flex justify-content-end">
    <!--begin::Button-->
    <a routerLink="/home/stockinspectionlist" class="btn btn-success">Back to Stock Inspection List</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button type="submit" id="kt_ecommerce_add_product_submit" *ngIf="!this.NewStock.InspectionCompleted && this.permission.Add" (click)="SaveStockInspection()" class="btn btn-primary">
        <span class="indicator-label">Save Inspection</span>
        <span class="indicator-progress">Please wait...
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
    </button>
    <p *ngIf="!this.permission.Add" style="color: red;"><b>You don't have permission to complete Stock Inspection</b></p>
    <!--end::Button-->
</div>
  </div>
</div>



