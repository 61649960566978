<div *ngIf="IsPopupOpen | async">
    <nz-drawer [nzClosable]="true" [nzVisible]="isDrawerVisible" nzPlacement="right" [nzWidth]="800"
        nzTitle="Proforma Invoice details" (nzOnClose)="handleCancel()">
        <ng-container *nzDrawerContent>
            <nz-descriptions nzBordered nzLayout="vertical" *ngIf="ProformaInvoice">
                <nz-descriptions-item nzTitle="Material Type">{{ProformaInvoice.MaterialType}}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="HSN/SAC Code">{{ProformaInvoice.Hsncode}}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Port of Loading">{{ProformaInvoice.PortOfLoading}}</nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Final Destination">{{ProformaInvoice.FinalDestination}}</nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Port of Discharge">{{ProformaInvoice.PortOfDischarge}}</nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Mode of Transport">{{ProformaInvoice.ModeOfTransport}}</nz-descriptions-item>
            </nz-descriptions>
            <nz-divider nzText="Consignee"></nz-divider>
            <nz-descriptions nzBordered nzLayout="vertical" *ngIf="ProformaInvoice">
                <nz-descriptions-item nzTitle="Name">{{ ProformaInvoice.Customer.CustomerName
                    }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Address">{{ ProformaInvoice.Customer.Address
                    }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Contact">{{ ProformaInvoice.Customer.CustomerContactNumber
                    }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Email">{{ ProformaInvoice.Customer.Email }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="GSTIN">{{ ProformaInvoice.Customer.Gstnumber
                    }}</nz-descriptions-item>
            </nz-descriptions>
            <nz-divider nzText="Invoice/Bank Details"></nz-divider>
            <nz-descriptions nzBordered nzLayout="vertical" *ngIf="ProformaInvoice">
                <nz-descriptions-item nzTitle="Invoice No">{{ ProformaInvoice.ProformaInvoiceNumber
                    }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Date">{{ ProformaInvoice.ProformaInvoiceDate | date: "dd-MMM-yyyy"
                    }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Buyer Ref No.">{{ ProformaInvoice.BuyerReferenceNumber
                    }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="{{ ProformaInvoice.ReferenceType }}'s Ref">{{
                    ProformaInvoice.ConsignorReference }}</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Bank Details.">{{ Bank?.BankName}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Beneficiary Name.">{{ Bank?.AccountHolderName}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="ACCOUNT NUMBER.">{{ Bank?.AccountNumber}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="IFSC CODE.">{{ Bank?.Ifsc}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="MICR CODE.">{{ Bank?.Micr}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="SWIFT CODE.">{{ Bank?.SwiftCode}}
                </nz-descriptions-item>
            </nz-descriptions>
            <nz-divider></nz-divider>
            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered style="width:100%">
                <thead>
                    <tr>
                        <th width="50px">S.No</th>
                        <th width="400px">Zaibunco Product Code/Description of Goods</th>
                        <th width="150px">Article Name</th>
                        <th>Quantity(MTR)</th>
                        <th>PRICE({{ProformaInvoice?.Currency}}/MTR)</th>
                        <th>AMOUNT({{ProformaInvoice?.Currency}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of ProformaInvoice.ProformaInvoiceItem; let i = index"
                        nzShowPagination="false">
                        <td>{{ i + 1 }}</td>
                        <td>{{data.SaleFormulationCodeProductName}} / {{ data.DescriptionOfGoods }}</td>
                        <td>{{ data.ArticleName }}</td>
                        <td>{{ data.Quantity }}</td>
                        <td>{{ data.Price }}</td>
                        <td>{{ (data.Price*data.Quantity).toFixed(2) }}</td>
                    </tr>
                </tbody>
                <tfoot *ngIf="this.ProformaInvoice.ProformaInvoiceItem.length>0">
                    <th></th>
                    <th></th>
                    <th>Total</th>
                    <th>{{ CalculateGrandTotal(this.ProformaInvoice.ProformaInvoiceItem ,'Quantity') }}</th>
                    <th>{{ CalculateGrandTotal(this.ProformaInvoice.ProformaInvoiceItem ,'Price') }}</th>
                    <th>{{ CalculateGrandTotal(this.ProformaInvoice.ProformaInvoiceItem ,'Total Price') }}</th>
                </tfoot>

            </nz-table>
            <div>
                <b>Terms & Conditions:</b>
                <div [innerHTML]="ProformaInvoice.TermsCondition| nzSanitizer: 'html'"></div>
            </div>
        </ng-container>
    </nz-drawer>
</div>