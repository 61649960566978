import { HttpClient } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MeasureUnitModel } from '../Models/MeasureUnitModel';


@Injectable()
export class ApiHttpService {
  ApiUrl = environment.Api_Url;
  constructor(public http: HttpClient) {

  }
  public get(url: string, options?: any) {
    return this.http.get(url, options);
  }
  public post(url: string, data: any, options?: any) {
    return this.http.post(url, data, options);
  } 
  
}
