export class InvoiceModel
    {
        InvoiceId : number = 0;
        InvoiceNumber : string = '';
        InvoiceDate : string= '';
        InvoiceTotalPrice : string = '';
        InvoiceFile : string= '';
        EwayBill : string= '';
        EwayBillDate : string= '';
        SupplierId : number= 0;
  Poid: number = 0;
  PONumber: string = '';
        SupplierName : string= '';
  Grn: string = '';

  IsPocomplete: boolean = false;
  SubTotal: number = 0;
  GST: number = 0;
  FreightInsurance: number = 0;
  ShippingHandling: number = 0;
  OtherCharges: number = 0;
  InvoiceTotal: number = 0;
}
export class InvoiceMapping {
  InvoiceId: number = 0;
  InvoiceNumber: string = '';
  SupplierId: number = 0;
  SupplierName: string = '';
  Poid: number = 0;
  Ponumber: string = '';
}
