import { UserRoleMasterModel } from "./UserRoleModel";

export class UserRoleModel {
  RoleId: number = 0;
  Username: string="";
  Admin: boolean = false;
  MasterModule: boolean = false;
  PurchaseOrderModule: boolean = false;
  ProductionModule: boolean = false;
  OrderModule: boolean = false;
  InventoryModule: boolean = false;
  GateModule: boolean = false;
  GatePassPermission: boolean = false;
  IssueModule: boolean = false;
}


export class UserStoreMappingModel {
  UserStoreId: number = 0;
  StoreId: number = 0;
  Username: string = "";
  Action: string = "";
}

export class UserModel {
  UserId: number = 0;
  Name: string = "";
  Contact: string = "";
  Email: string = "";
  Address: string = "";
  Roles: string = "";
  UserRole:UserRoleMasterModel[]=[]
}
