<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Formulation Code</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">

      <div style="margin:10px">


        <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
          <div nz-col [nzSpan]="24" style="background: #f0f2f5; border-radius: 4px; ">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="4">
                <nz-divider nzText="Formulation Code"></nz-divider>
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label class="required">Formulation Code</label>
                    <input nz-input name="FormulationCode" placeholder="Formulation Code"
                      value="NewSaleOrder.SaleFormulationCode" [(ngModel)]="NewSaleOrder.SaleFormulationCode"
                      [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="6">
                <nz-divider nzText="Category "></nz-divider>
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label class="required">Select Finished Category </label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch
                      [(ngModel)]="NewSaleOrder.CategoryId" nzPlaceHolder="Choose"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                        [nzLabel]="s.ProductCategory"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-divider nzText="Sandwich Lining"></nz-divider>
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Select for Yes</label>
                    <input nz-input type="checkbox" nzSize="large" [(ngModel)]="NewSaleOrder.IsOrderLinkingRequired"
                      name="IsOrderLinkingRequired" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-divider nzText="Thickness "></nz-divider>
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Select Thickness </label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch
                      [(ngModel)]="NewSaleOrder.ThicknessId" nzPlaceHolder="Choose"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                        [nzLabel]="s.ThicknessNumber"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="7">
                <nz-divider nzText="Fabric Material"></nz-divider>
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                    <label>Select Raw Products</label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <nz-select class="form-select mb-2" nzSize="default" nzAllowClear nzShowSearch
                        [(ngModel)]="Selectedproduct" (ngModelChange)="OnProductDdlchange()" nzPlaceHolder="Choose"
                        [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s.ProductId"
                          [nzLabel]="s.ProductName"></nz-option>
                        /n
                      </nz-select>
                    </nz-form-control>
                  </nz-form-control>
                </nz-form-item>



              </div>
              <!--<div nz-col [nzSpan]="8">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
        <label  style="margin-top: 10px;"  class="required">Product</label>
        <nz-select class="form-select mb-2" nzShowSearch name="ProductId" nzSize="default" [(ngModel)]="this.NewSaleOrder.ProductId" (ngModelChange)="OnProductChange()" [ngModelOptions]="{standalone: true}" nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.ProductFilteredList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>-->

            </div>

          </div>


        </div>


        <nz-divider nzText="Mixing"></nz-divider>

        <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
          <div nz-col [nzSpan]="24" style="background-color: #f0f2f5; border-radius: 4px;">
            <nz-divider></nz-divider>
            <div nz-col [nzSpan]="24">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                  <label class="required">Select Mixing</label>
                  <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple"
                    [(ngModel)]="listOfSelectedmixing" (ngModelChange)="OnMixingDdlchange()" nzAllowClear
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let s of this.MixingList;" [nzValue]="s.MixingId"
                      [nzLabel]="s.MixingName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <div *ngFor="let i of MixingSelectedList"
                style="padding: 5px; border: 1px solid #ccc; border-radius: 4px; margin-top: 10px; background: #fff; box-shadow: 1px 1px 2px 2px #415994;">
                <div style="padding: 10px 0;text-transform: uppercase;">
                  Mixing Name :
                  <b>{{i.MixingName}}</b>
                  <a class="btn btn-sm btn-light-success" style="float: right; margin-bottom: 10px; "
                    (click)="openAddModel(i)"><i nz-icon nzType="plus-circle" nzTheme="fill"
                      style=" margin-bottom: 3px;"></i>Add</a>
                </div>
                <nz-table nzSize="small" [nzPageSize]="100" [nzData]="['']" #basicTable1 nzBordered
                  nzShowPagination="false">
                  <thead>
                    <tr>

                      <th nzWidth="50px">S.No</th>
                      <th>Product</th>
                      <th nzWidth="200px">Code</th>
                      <th nzWidth="100px">Gsm</th>
                      <th nzWidth="100px">Unit</th>


                      <th nzWidth="100px">QTY</th>
                      <th nzWidth="100px">SCQTY</th>
                      <th nzWidth="120px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of i.MixingRawMaterial;let i=index">

                      <td nzWidth="20px">{{i+1}}</td>
                      <td>{{ data.ProductName }}</td>
                      <td>{{ data.ProductCode }}</td>
                      <td>{{ data.AvgGsm }}</td>
                      <td>{{ data.Unit }}</td>
                      <td>
                        <input nz-input placeholder="Quantity" value="data.Quantity" [(ngModel)]="data.Quantity"
                          [ngModelOptions]="{standalone: true}" />

                      </td>
                      <td><input nz-input placeholder="Scquantity" value="data.Scquantity" [(ngModel)]="data.Scquantity"
                          [ngModelOptions]="{standalone: true}" /></td>
                      <td>
                        <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)"><i
                            nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Remove</a>

                      </td>


                    </tr>

                  </tbody>

                </nz-table>
                <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px;">
                  <div nz-col [nzSpan]="8"></div>
                  <div nz-col [nzSpan]="16">

                    <div nz-row [nzGutter]="24">


                      <div nz-col [nzSpan]="6">
                        <nz-form-item>

                          <!-- <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
                      <label>Wastage</label>
                      <input nz-input name="Wastage" placeholder="Wastage" value="i.Wastage" [(ngModel)]="i.Wastage" [ngModelOptions]="{standalone: true}" (ngModelChange)="OnWastagetypeDdlchange(i)" />
                    </nz-form-control> -->
                        </nz-form-item>

                      </div>
                      <div nz-col [nzSpan]="6">
                        <nz-form-item>

                          <!-- <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
                      <label>Wastage Type</label>
                      <nz-select #microwaveRef class="form-select" nzSize="default" [(ngModel)]="i.WastageType" [ngModelOptions]="{standalone: true}" name="Wastagetype" nzPlaceHolder="Product" (ngModelChange)="OnWastagetypeDdlchange(i)">
                        <nz-option nzValue="%" nzLabel="%"></nz-option>
                        <nz-option nzValue="Flat" nzLabel="Flat"></nz-option>
                      </nz-select>
                    </nz-form-control> -->
                        </nz-form-item>

                      </div>
                      <div nz-col [nzSpan]="6">
                        <nz-form-item>

                          <!-- <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Weight Gsm</label>
                      <input nz-input name="WeightGsm" placeholder="Weight Gsm" value="i.WeightGsm" [(ngModel)]="i.WeightGsm" disabled [ngModelOptions]="{standalone: true}" />
                    </nz-form-control> -->
                        </nz-form-item>

                      </div>
                      <div nz-col [nzSpan]="6">
                        <nz-form-item>

                          <!-- <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Total</label>
                      <input nz-input name="Total" placeholder="Total" disabled value="i.Total" [(ngModel)]="i.Total" [ngModelOptions]="{standalone: true}" />
                    </nz-form-control> -->
                        </nz-form-item>

                      </div>



                    </div>
                  </div>


                </div>
              </div>
              <!-- <div *ngIf="MixingSelectedList.length>0" nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px;">
          <div nz-col [nzSpan]="12"></div>
          <div nz-col [nzSpan]="12">
            <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="3" style="box-shadow: 1px 1px 2px 2px #415994;">
              <nz-descriptions-item nzTitle="Formulation Code" nzColon="true"><input nz-input name="MixingFormulationCode" placeholder="Formulation Code" [(ngModel)]="NewPO.MixingFormulationCode" [ngModelOptions]="{standalone: true}" /> </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Febric GSM" nzColon="true"><b>{{ NewPO.MixingGSMTotal }}</b> </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Grand Total" nzColon="true"><b>{{ NewPO.MixingTotalCost }}</b> </nz-descriptions-item>
              <nz-descriptions-item [nzSpan]="2" nzColon="true"><b></b> </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Final Weight (GSM)" [nzSpan]="1" nzColon="true"><b>{{ NewPO.FinalMixingGSMTotal }}</b> </nz-descriptions-item>
            </nz-descriptions>

          </div>


        </div> -->
              <nz-divider></nz-divider>
            </div>

          </div>
        </div>


        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24"
          style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding-bottom: 12px;    background: rgb(240, 242, 245);">
          <div nz-col [nzSpan]="18">
            <!-- <nz-descriptions [nzColumn]="18">
                <nz-descriptions-item nzTitle="Weight in GSM" style="margin-left:134px;" >
                </nz-descriptions-item>
            </nz-descriptions> -->
            <label
              style="margin-left:134px;margin-top: 25px; margin-left: 134px;margin-bottom: 25px;font-weight: 500;">Weight
              in GSM</label>
          </div>
          <div nz-col [nzSpan]="6">

          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="3">

            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" style="margin-left: -70px;" nzErrorTip="Select supplier Name">
                  <label style="margin-left: -1px;">Pre Skin</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.PreSkinGsm" [ngModelOptions]="{standalone: true}"
                    name="PreSkinGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" style="margin-left: -70px;" nzErrorTip="Select supplier Name">
                  <label style="margin-left: -1px;">Skin</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.SkinGsm" [ngModelOptions]="{standalone: true}"
                    name="SkinGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" style="margin-left: -70px;" nzErrorTip="Select supplier Name">
                  <label style="margin-left: -1px;">Foam</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.FoamGsm" [ngModelOptions]="{standalone: true}"
                    name="FoamGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" style="margin-left: -70px;" nzErrorTip="Select product Name">
                  <label style="margin-left: -1px;">Adhesive</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.AdhesiveGsm" [ngModelOptions]="{standalone: true}"
                    name="AdhesiveGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="5">
              <nz-form-item>

                <nz-form-control [nzSpan]="20" style="margin-left: -70px;" nzErrorTip="Enter Unit number">
                  <label style="margin-left: -1px;">Fabric (GSM)</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.FabricGsm" [ngModelOptions]="{standalone: true}"
                    name="FabricGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>

            </div>

          </div>
          <div nz-col [nzSpan]="15">
          </div>
          <div nz-col [nzSpan]="9">
            <nz-descriptions [nzColumn]="2">
              <nz-descriptions-item nzTitle="Total (GSM)" nzColon="true">
                <input nz-input type="text"
                  style="width: 250px;background: #fff;color: #000;font-weight: 700;border: none;" disabled
                  [(ngModel)]="NewPO.TotalGsm" [ngModelOptions]="{standalone: true}" />
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
          <div nz-col [nzSpan]="3">

          </div>
          <nz-divider></nz-divider>

          <div nz-col [nzSpan]="18">
            <label
              style="margin-left:134px;margin-top: 25px; margin-left: 134px;margin-bottom: 25px;font-weight: 500;">Production
              Speed Average Range</label>
          </div>
          <div nz-col [nzSpan]="6">

          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="3">

            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" style="margin-left: 20px;" nzErrorTip="Select supplier Name">
                  <label style="margin-left: -1px;">Min. Speed/MTR</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.MinSpeed" [ngModelOptions]="{standalone: true}"
                    name="PreSkinGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" style="margin-left: 20px;" nzErrorTip="Select supplier Name">
                  <label style="margin-left: -1px;">Max. Speed/MTR</label>
                  <input nz-input [(ngModel)]="NewSaleOrder.MaxSpeed" [ngModelOptions]="{standalone: true}"
                    name="SkinGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
        </div>
        <nz-divider></nz-divider>
      </div>
    </form>


  </div>
</div>




<nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsPopUpOpen" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalfooter" (nzOnCancel)="closeAddModel()">
  <ng-template #modalTitle>Add Raw material</ng-template>

  <ng-template #modalContent>
    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch nzMode="multiple"
      [(ngModel)]="listOfSelectedproducts" (ngModelChange)="OnProductDdlchange()" nzAllowClear nzPlaceHolder="Choose"
      [ngModelOptions]="{standalone: true}">
      <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s" [nzLabel]="s.ProductName"></nz-option>
    </nz-select>

  </ng-template>
  <ng-template #modalfooter>
    <div class="text-center">



      <button nz-button nzType="primary" (click)="addRawMaterial()">Save</button>
    </div>
  </ng-template>
</nz-modal>