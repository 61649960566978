import { DemandModel } from "./DemandModel";
import { PMSPurchaseOrderStatus } from "./Enums";
import { KnittingDivisionStockModel } from "./KnittingDivisionStockModel";
import { UserModel } from "./UserModel";

export class PurchaseOrderModel {
  SerialNo: number = 0;
  Poid: number = 0;
  Ponumber: string = '';
  SupplierId: number = 0;
  SupplierName: string = '';
  SupplierEmail: string = '';
  DeliveryDate: string = '';
  DeliveryTermId: number = 0;
  DeliveryTerm: string = '';
  PaymentTermId: number = 0;
  PaymentTerm: string = '';
  TransportId: number = 0;
  TransportCompanyName: any
  ProductName: string = '';
  PototalAmount: string = '';
  PototalTax: number = 0;
  Pograndtotal: string = '';
  PocreationDate: string = '';
  Grn: string = '';
  Reference: string = '';
  AddedDate: string = '';
  AddedBy!: UserModel;
  ActionPersonName: string = '';
  ApprovedPersonName: string = '';
  ActionPersonEmail: string = '';
  ApprovedPersonEmail: string = '';
  ActionBy!: UserModel;
  Status: string = '';
  IsPocomplete: boolean = false;
  IsInvoiceAttached: boolean = false;
  Remarks: string = '';
  ContactPersonUserId: number = 0;
  PurchaseOrderProduct: PurchaseOrderProductModel[] = [];
  Demand: DemandModel[] = [];
  FabricStock: KnittingDivisionStockModel[] = [];
  UserDetails!: UserModel;
  ApprovedBy!: UserModel;
  ApprovedDate: string = "";
  POHighValue: string = "";
  DepartmentId: number = 0;
  DepartmentName: string = '';
}

export class PurchaseOrderProductModel {
  PoproductId: number = 0;
  Poid: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  Unit: string = '';
  Rate: number = 0;
  Quantity: number = 0;
  RecievedQuantity: number = 0;
  Amount: number = 0;
  Grade: string = '';
  Currency: string = '';
  Igst: number = 0;
  IgstTotal: number = 0;
  Total: number = 0;
}

export class PurchaseOrderPrintModel {
  SupplierName: string = '';
  Ponumber: string = '';
  DeliveryTerm: string = '';
  PaymentTerm: string = '';
  ProductName: string = '';
  Currency: string = '';

  PototalAmount: string = '';
  PocreationDate: string = '';

  Grn: string = '';
  Reference: string = '';
  AddedDate: string = '';
  AddedBy: string = '';
  Status: string = '';
  PurchaseOrderProduct: PurchaseOrderProductModel[] = [];

}

export class POActionModel {
  Poid: number = 0;
  Status: PMSPurchaseOrderStatus;
  Remark: string = '';
}