<div style="padding: 30px" class="card card-flush h-xl-100;">
  <div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
    <p style="font-size: 15px;"><b>{{this.Company.CompanyName}}</b></p>
    <p style="font-size: 10px;"><b>Head Office: </b>{{this.Company.HeadOfficeAddress}}</p>
    <p style="font-size: 10px;"><b>Factory Address: </b>{{this.Company.FactoryAddress}}</p>
  </div>
  <div
    style="text-transform: uppercase; font-size: 13px; text-align: center; color: black; border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
    <b>Software Generated Material Out Pass No.: {{OutPass.OutpassNumber}}</b>
  </div>
  <div
    style="text-transform: uppercase; color: black; border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
    <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="4" style="border: black;">
      <nz-descriptions-item nzTitle="OutPass To" [nzSpan]="2" nzColon="true"><b>{{OutPass.OutpassTo}}</b>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="OutPass Date" [nzSpan]="2"><b>{{OutPass.OutpassDate |
          DatetimeConverter}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="OutPass Type" [nzSpan]="2"><b>{{OutPass.OutpassType}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Purpose" [nzSpan]="2"><b>{{OutPass.Purpose}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Remark" [nzSpan]="4"><b>
          <div innerHTML="{{OutPass?.Remark}}"></div>
        </b></nz-descriptions-item>
    </nz-descriptions>
    <br />
    <br />
    <table class="table" style="border: 1px solid;">
      <thead>
        <tr id="line">
          <th scope="col"><b>S.No</b></th>
          <th scope="col"><b>Raw Meterial</b></th>
          <th scope="col"><b>Qty</b></th>
          <th scope="col"><b>Amount</b></th>
          <th scope="col"><b>Unit</b></th>
          <th scope="col"><b>Total</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.OutPass?.OutpassItems;let i=index" id="line">
          <td>{{i+1}}</td>

          <td>{{ data.ProductName }}</td>
          <td>{{ data.Quantity }}</td>
          <td>{{ data.Amount }}</td>
          <td>{{ data.Unit}}</td>
          <td>{{ data.Total}}</td>

        </tr>
      </tbody>
    </table>
    <div class="footer">
      <table class="footer-table">
        <tr>
          <td width="50%" style="text-align: left; padding-left: 20px;">
            <p style="margin:5px"><b> PREPARED BY:</b></p>
            <p style="margin: 10px;font-style: italic; font-weight: bolder;font-family:cursive;">
              <b>{{this.OutPass.AddedBy}}</b></p>
          </td>
          <td width="50%">
            <p style="margin:5px"><b>APPROVED BY:</b></p>
            <p style="margin: 25px;"></p>
            <p style="margin: 15px;">Authorized Signatory</p>
          </td>
        </tr>
      </table>
      <p style="margin-top: 20px;">Powered By: KanzenFlow</p>
    </div>
  </div>
</div>