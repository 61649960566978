import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { combineAll, combineLatestAll, from, map, Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GateInModel, GateInPrintModel } from '../../Models/GateInModel';
import { SaleOrderModel } from '../../Models/SalesOrderModel';
import { IUploadProgress } from '../../Models/StockModel';
import { SupplierModel } from '../../Models/SupplierModel';
import {
  TransportModel,
  TransportVehicleModel,
} from '../../Models/TransportModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { InterceptorSkipHeader } from '../../Services/HttpInterceptorService';
import { UserInfo } from '../../Authentication/UserInfo';
import { ISasToken } from '../../azure-storage/azureStorage';
import { BlobStorageService } from '../../azure-storage/blob-storage.service';
import { InvoiceMapping, InvoiceModel } from '../../Models/InvoiceModel';
import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { DatetimeConverter } from '../../Services/DatetimeConverter.pipe';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { StorageService } from '../../Services/storage.service';
import { LoadingService } from '../../Services/loadingService';
@Component({
  templateUrl: './GateIn.component.html',
  styles: [
    `
      * {
        box-sizing: border-box;
      }
      
      .column {
        float: left;
        width: 33.33%;
        padding: 5px;
      }
      
      /* Clearfix (clear floats) */
      .row::after {
        content: '';
        clear: both;
        display: table;
      }
      `,
  ],
})
export class GateInComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  validateFormT!: UntypedFormGroup;
  validateFormV!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  SupplierList: SupplierModel[] = [];
  GateInList: GateInModel[] = [];
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  GateInListOriginal: GateInModel[] = [];
  NewGateIn: GateInModel = new GateInModel();
  SalesList: SaleOrderModel[] = [];
  isVisible = false;
  isImageVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  isDispatchSaleOrder: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Gate Entry';
  TransportList: TransportModel[] = [];
  VehicleList: TransportVehicleModel[] = [];
  FilterVehicleList: TransportVehicleModel[] = [];
  uploadProgress$: Observable<IUploadProgress[]> | undefined;
  filesSelected = false;
  uploadinvoicefile: any;
  uploadinvoice: string = '';
  IsChecked: boolean = false;
  InvoiceList: InvoiceModel[] = [];
  InvoiceListOriginal: InvoiceModel[] = [];
  InvoiceModel: InvoiceMapping = new InvoiceMapping();
  InvoiceMappingModel: InvoiceMapping[] = [];
  PurchaseOrderList: PurchaseOrderModel[] = [];
  FilteredPurchaseOrderList: PurchaseOrderModel[] = [];
  FilteredSupplierList: SupplierModel[] = [];
  token: any;
  getToken: any;
  ImageArray: any[] = [];
  FilteredImageArray: any[] = [];
  NewTransport: TransportModel = new TransportModel();
  NewVehicle: TransportVehicleModel = new TransportVehicleModel();
  PopUpTitleT: string = 'Add New Transport';
  PopUpTitleV: string = 'Add New Vehicle';
  NameError = 'Enter TransportCompany name';
  isVisibleT = false;
  isVisibleV = false;
  isLoadingT: boolean = false;
  isLoadingV: boolean = false;
  VehicleId = 0;
  selectedPOValue: string = '';
  exportoptions = {
    headers: [
      'VEHICLE NUMBER',
      'TRANSPORT COMPANY',
      'PERSON NAME',
      'CONTACT NUMBER',
      'GATE-IN DATE',
      'GATE PASS ISSUED DATE',
      'OUT DATE',
      'ADDED BY',
      'GATE-IN',
      'PASS ISSUED',
      'GATE-OUT',
    ],
  };
  fields: GateInPrintModel = new GateInPrintModel();
  exportfields: GateInPrintModel[] = [];
  // public innerWidth: any;
  count: 0;
  // @HostListener('window:resize', ['$event'])
  // onResize(event: any) {
  //   this.innerWidth = window.innerWidth;
  // }
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  StorageAccContainerName: string = 'gate';
  request = {
    FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
    ToDate: new Date(new Date().setHours(23, 59, 59)),
    VehicleId: 0,
    TransportId: 0,
    DateType: 'gatein', // options are - gatein, gateout, gatepass
    SupplierId: 0,
    Type: '',
    IsGatePassIssued: null,
    PONumber: '',
    InvoiceNumber: ''
  }
  totalItemsCount: number;
  dateTypeList = [
    {
      "Text": "Gate In",
      "Value": 'gatein'
    },
    {
      "Text": "Gate Out",
      "Value": 'gateout'
    },
    {
      "Text": "Gate Pass",
      "Value": 'gatepass'
    }];
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private blobStorage: BlobStorageService, private auth: AuthService, private router: Router,
    public storage: StorageService, public loader: LoadingService
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.GateIn, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.GateIn, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.GateIn, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    // this.innerWidth = window.innerWidth;
    this.validateForm = this.fb.group({
      TransportId: [null, [Validators.required]],
      VehicleId: [null, [Validators.required]],
      GateInPersonContact: [null],
      GateInPerson: [null],
      IsChecked: false,
    });
    this.validateFormT = this.fb.group({
      TransportCompanyName: [
        null,
        [Validators.required, Validators.minLength(3)],
        [this.NameAsyncValidator],
      ],
      TransportCompanyAddress: [null, [Validators.required]],
      TransportCompanyEmail: [null, [Validators.email]],
      TransportCompanyContact: [
        null,
        [Validators.required, Validators.minLength(10)],
      ],
      VehicleNumber: [null, [Validators.required, Validators.minLength(3)]],
      VehicleType: [null, [Validators.required]],
      //Gst: [null, [Validators.required,  Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]]
      Gst: [null],
    });
    this.validateFormV = this.fb.group({
      VehicleNumber: [null, [Validators.required, Validators.minLength(3)]],
      VehicleType: [null, [Validators.required]],
    });
    //this.GetAllSupplier();
    this.GetAllGateInRecords();
    this.GetAllTransport();
    this.GetAllSuppliers();
    this.GetAllInvoices();
    this.GetAllPurchaseOrder();
    // this.getStorageToken();
  }
  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === 'Space') ||
      (event.key === 'Enter' && event.keyCode === 13)
    ) {

      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }
  get f() {
    return this.validateForm.controls;
  }
  get fT() {
    return this.validateFormT.controls;
  }
  get fV() {
    return this.validateFormV.controls;
  }

  GetAllSalesOrder() {
    this.isDispatchSaleOrder = true;
    if (this.SalesList.length < 1) {
      this.isTableLoading = true;
      let url =
        this.ApiUrl + 'saleorder/getallsaleorderdatabystatus/Dispatched';
      this.http.get<SaleOrderModel[]>(url).subscribe(
        (res) => {
          this.SalesList = res;
          this.isTableLoading = false;
        },
        (res) => { }
      );
    }
  }

  // getStorageToken(): any {
  //   let url = this.ApiUrl + 'data/getstoragetokenforgate';
  //   this.http.get<any>(url).subscribe(
  //     (res) => {
  //       this.tokenget = res;
  //       this.token = res;
  //     },
  //     (res) => {
  //       this.getStorageToken();
  //     }
  //   );
  // }
  // GetAllGateInRecordsOld() {
  //   this.isTableLoading = true;
  //   let url = this.ApiUrl + 'gate/getgateinrecords';
  //   this.http.get<GateInModel[]>(url).subscribe(
  //     (res) => {
  //       this.GateInList = res;
  //       this.GateInListOriginal = res;
  //       this.GateInList.forEach((x) => {
  //         this.fields = new GateInPrintModel();

  //         this.fields.VehicleNumber = x.VehicleNumber;
  //         this.fields.TransportCompanyName = x.TransportCompanyName;
  //         this.fields.GateInPerson = x.GateInPerson;
  //         this.fields.GateInPersonContact = x.GateInPersonContact;
  //         this.fields.GateInDate =
  //           new DatetimeConverter().transform(x.GateInDate) ?? '';
  //         this.fields.GatePassIssueDate =
  //           new DatetimeConverter().transform(x.GatePassIssueDate) ?? '';
  //         this.fields.GateOutDate =
  //           new DatetimeConverter().transform(x.GateOutDate) ?? '';
  //         this.fields.AddedBy = '';
  //         this.fields.GateIn = x.GateIn == true ? 'Completed' : 'Not yet';
  //         this.fields.GatePassIssue =
  //           x.GatePassIssue == true ? 'Issued' : 'Not Issued';
  //         this.fields.GateOut = x.GateOut == true ? 'Completed' : 'Not yet';

  //         this.exportfields.push(this.fields);
  //       });
  //       //this.GateInList.forEach(x => {
  //       //  x.Invoice.InvoiceFile = environment.BlobURL + 'invoices/' + x.Invoice.InvoiceFile + environment.BlobSASToken;
  //       //})
  //       console.log();
  //       this.isTableLoading = false;
  //     },
  //     (res) => {
  //       this.count++;
  //       if (this.count < 2) {
  //         this.GetAllGateInRecords();
  //       }
  //     }
  //   );
  // }
  GetAllGateInRecords() {
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + 'gate/getgateinrecordswithfilter';
    this.http.post<GateInModel[]>(url, this.request).subscribe(
      (res) => {
        this.GateInList = res;
        this.GateInListOriginal = res;
        this.GateInList.forEach((x) => {
          count++;
          this.fields = new GateInPrintModel();

          this.fields.VehicleNumber = x.VehicleNumber;
          this.fields.TransportCompanyName = x.TransportCompanyName;
          this.fields.GateInPerson = x.GateInPerson;
          this.fields.GateInPersonContact = x.GateInPersonContact;
          this.fields.GateInDate =
            new DatetimeConverter().transform(x.GateInDate) ?? '';
          this.fields.GatePassIssueDate =
            new DatetimeConverter().transform(x.GatePassIssueDate) ?? '';
          this.fields.GateOutDate =
            new DatetimeConverter().transform(x.GateOutDate) ?? '';
          this.fields.AddedBy = '';
          this.fields.GateIn = x.GateIn == true ? 'Completed' : 'Not yet';
          this.fields.GatePassIssue =
            x.GatePassIssue == true ? 'Issued' : 'Not Issued';
          this.fields.GateOut = x.GateOut == true ? 'Completed' : 'Not yet';

          this.exportfields.push(this.fields);
        });
        this.totalItemsCount = count;
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllGateInRecords();
        }
      }
    );
  }
  ResetFilters() {
    this.request = {
      FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
      ToDate: new Date(new Date().setHours(23, 59, 59)),
      VehicleId: 0,
      TransportId: 0,
      DateType: 'gatein', // options are - gatein, gateout, gatepass
      SupplierId: 0,
      Type: '',
      IsGatePassIssued: null,
      PONumber: '',
      InvoiceNumber: ''
    }
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'getein-export' + exportdate, this.exportoptions);
  }
  GetAllTransport() {
    let url = this.ApiUrl + 'transport/getalltransport';
    this.http.get<TransportModel[]>(url).subscribe({
      next: (res) => {
        this.TransportList = res;
      },
      error: (res) => {
        this.GetAllTransport();
      },
    });
  }
  GetAllSuppliers() {
    let url = this.ApiUrl + 'supplier/getallsuppliers';
    this.http.get<SupplierModel[]>(url).subscribe(
      (res) => {
        this.SupplierList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllSuppliers();
        }
      }
    );
  }
  GetAllPurchaseOrder() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'purchaseorder/getallpurchaseorders';
    this.http.get<PurchaseOrderModel[]>(url).subscribe(
      (res) => {
        res = res.filter((x) => x.Status == 'Active');
        this.PurchaseOrderList = res;
        this.FilteredPurchaseOrderList = res;
        this.isTableLoading = false;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllPurchaseOrder();
        }
      }
    );
  }

  GetAllInvoices() {
    let url = this.ApiUrl + 'stock/getallunstockedinvoices';
    this.http.get<InvoiceModel[]>(url).subscribe(
      (res) => {
        this.InvoiceList = res;
        this.InvoiceListOriginal = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllInvoices();
        }
      }
    );
  }
  GetAllVehicle(data: TransportModel) {
    if (data != undefined && data.TransportVehicle) {
      this.VehicleList = data.TransportVehicle;

      this.InvoiceModel.Poid = 0;
    }
  }
  GetAllVehicleForFilter(TransportId: any) {
    this.FilterVehicleList = [];
    if (TransportId != undefined && TransportId) {
      this.FilterVehicleList = this.TransportList.find(x => x.TransportId == TransportId).TransportVehicle;
    }
  }

  Save() {
    if (this.isDispatchSaleOrder) {
      this.SaveSaleOrderDispatch();
    } else {
      this.SaveInvoice();
    }
  }

  SaveInvoice() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;

      return;
    }
    if (this.InvoiceMappingModel.length <= 0) {
      this.isLoading = false;
      this.alertService.error('Please add Invoice');
      return;
    }

    //this.NewGateIn.SupplierId=this.f['SupplierId'].value;
    /*this.NewGateIn.Invoice.SupplierId=this.f['SupplierId'].value;*/
    this.NewGateIn.VehicleId = this.f['VehicleId'].value;
    this.NewGateIn.GateInPersonContact = this.f['GateInPersonContact'].value;
    this.NewGateIn.GateInPerson = this.f['GateInPerson'].value;
    this.NewGateIn.AddedBy = UserInfo.EmailID;
    this.InvoiceMappingModel.forEach((x) => {
      var inv = new InvoiceModel();
      inv.InvoiceNumber = x.InvoiceNumber;
      inv.SupplierId = x.SupplierId;
      inv.Poid = x.Poid;
      this.NewGateIn.Invoice.push(inv);
    });
    //this.NewGateIn.Invoice.InvoiceNumber = this.f['InvoiceNumber'].value;
    //this.NewGateIn.Invoice.InvoiceTotalPrice = this.f['InvoiceTotalPrice'].value;
    //this.NewGateIn.Invoice.InvoiceDate = this.f['InvoiceDate'].value;
    //this.NewGateIn.Invoice.EwayBill = this.f['EwayBill'].value;
    //this.NewGateIn.Invoice.EwayBillDate = this.f['EwayBillDate'].value;

    //this.uploadinvoice = this.f['InvoiceFile'].value;
    //if(this.uploadinvoice != null) {
    //  this.oninvoiceupload();
    //  this.NewGateIn.Invoice.InvoiceFile = this.NewGateIn.SupplierId + '_' + this.NewGateIn.Invoice.InvoiceNumber + '_' + this.uploadinvoice.replace('C:\\fakepath\\', '');
    //}

    let url = this.ApiUrl + 'gate/addgatein';
    this.http.post<GateInModel>(url, this.NewGateIn).subscribe({
      next: (res) => {
        this.NewGateIn = res;
        if (this.uploadinvoicefile) {
          this.fetchStorageTokenAndUpload();
        }
        this.alertService.success('Entry Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllGateInRecords();
        this.InvoiceMappingModel = [];
        this.selectedPOValue = '';
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;
      },
    });
  }

  SaveSaleOrderDispatch() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false;

      return;
    }
    var selectedSaleOrder = this.SalesList.filter((x) => x.IsChecked);
    if (selectedSaleOrder.length <= 0) {
      this.isLoading = false;
      this.alertService.error('Please Select Sale order to dispatch');
      return;
    }
    this.NewGateIn.SaleOrderId = selectedSaleOrder.map(
      (item) => item.SaleOrderId
    );
    this.NewGateIn.VehicleId = this.f['VehicleId'].value;
    this.NewGateIn.GateInPersonContact = this.f['GateInPersonContact'].value;
    this.NewGateIn.GateInPerson = this.f['GateInPerson'].value;
    this.NewGateIn.AddedBy = UserInfo.EmailID;
    //}

    let url = this.ApiUrl + 'gate/addgateinforsaleorderdispatch';
    this.http.post<GateInModel>(url, this.NewGateIn).subscribe({
      next: (res) => {
        this.NewGateIn = res;
        this.alertService.success('Entry Saved Successfully');
        this.isLoading = this.isVisible = false;
        this.GetAllGateInRecords();
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;
      },
    });
  }

  OpenEditPop(data: GateInModel) {
    this.PopUpTitle = 'Modify GateIn Details';
    this.validateForm.setValue({
      SupplierId: data.SupplierId,
      VehicleId: data.VehicleId,
      GateInPersonContact: data.GateInPersonContact,
      GateInPerson: data.GateInPerson,
      //InvoiceNumber : data.Invoice.InvoiceNumber,
      //InvoiceTotalPrice : data.Invoice.InvoiceTotalPrice,
      //InvoiceDate : data.Invoice.InvoiceDate,
      //EwayBill : data.Invoice.EwayBill,
      //EwayBillDate : data.Invoice.EwayBillDate
    });
    this.NewGateIn = new GateInModel();
    this.NewGateIn.GateInId = data.GateInId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = 'Add New GateIn';
    //this.validateForm.setValue(
    //  {
    //    SupplierId: null,
    //    TransportId:null,
    //    VehicleId: null,

    //    GateInPersonContact: null,
    //    GateInPerson: null,
    //    InvoiceNumber: null,
    //    InvoiceDate: null,
    //    InvoiceTotalPrice: null,
    //    EwayBill: null,
    //    EwayBillDate: null
    //  }
    //)

    this.validateForm.reset();

    this.isVisible = true;
  }
  showModalT(): void {
    this.PopUpTitleT = 'Add New Transport';
    this.validateFormT.reset();

    //this.validateForm.setValue(
    //  {
    //    TransportCompanyName: null,
    //    TransportCompanyAddress: null,
    //    TransportCompanyContact: null,
    //    TransportCompanyEmail: null,
    //    Gst: null
    //  }
    //)
    this.NewTransport.TransportCompanyName = '';
    this.NewTransport.TransportId = 0;
    this.NewTransport.TransportCompanyAddress = '';
    this.NewTransport.TransportCompanyContact = '';
    this.NewTransport.TransportCompanyEmail = '';
    this.NewTransport.Gst = '';
    this.isVisibleT = true;
  }
  showModalV(): void {
    if (this.f['TransportId'].value == null) {
      this.alertService.error('Select Transport');
      return;
    }
    this.PopUpTitleV = 'Add New Vehicle';
    this.validateFormV.setValue({
      VehicleNumber: null,
      VehicleType: null,
    });
    this.NewVehicle.TransportId = this.f['TransportId'].value.TransportId;
    this.NewVehicle.VehicleNumber = '';
    this.NewVehicle.VehicleId = 0;
    this.NewVehicle.VehicleType = '';

    this.isVisibleV = true;
  }
  handleOk(): void {
    this.isLoading = true;
    this.Save();
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  Delete() {
    let url = this.ApiUrl + 'supplier/deletesupplier';
    this.http.post<any>(url, this.NewGateIn.GateInId).subscribe({
      next: (res) => {
        this.alertService.success('Supplier Deleted Successfully');
        this.isLoading = this.isVisible = false;
        //this.GetAllSupplier();
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
  handleDelete(data: GateInModel) {
    this.NewGateIn = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this record?',
      nzOnOk: () => this.Delete(),
    });

    setTimeout(() => modal.destroy(), 5000);
  }

  handleOkT(): void {
    this.isLoadingT = true;
    this.SaveTransport();
  }
  handleOkV(): void {
    this.isLoadingV = true;
    this.SaveVehicle();
  }
  handleCancelT(): void {
    this.isVisibleT = false;
  }
  handleCancelV(): void {
    this.isVisibleV = false;
  }
  SaveTransport() {
    if (this.validateFormT.invalid) {
      Object.values(this.validateFormT.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoadingT = false;
      return;
    }

    this.NewTransport.TransportCompanyName =
      this.fT['TransportCompanyName'].value;
    this.NewTransport.TransportCompanyAddress =
      this.fT['TransportCompanyAddress'].value;
    this.NewTransport.TransportCompanyContact =
      this.fT['TransportCompanyContact'].value;
    this.NewTransport.TransportCompanyEmail =
      this.fT['TransportCompanyEmail'].value;
    this.NewTransport.Gst = this.fT['Gst'].value;
    if (
      this.TransportList.filter(
        (x) =>
          x.TransportCompanyName.toLowerCase() ==
          this.NewTransport.TransportCompanyName.toLowerCase()
      ).length > 0
    ) {
      this.alertService.error('TransportCompanyName already exists');
      return;
    }
    if (
      this.VehicleList.filter(
        (x) =>
          x.VehicleNumber.toLowerCase() ==
          this.fT['VehicleNumber'].value.toLowerCase()
      ).length > 0
    ) {
      this.alertService.error('VehicleNumber already exists');
      return;
    }

    let url = this.ApiUrl + 'Transport/addupdateTransport';
    this.http.post<TransportModel>(url, this.NewTransport).subscribe({
      next: (res) => {
        let url = this.ApiUrl + 'transport/getalltransport';
        this.http.get<TransportModel[]>(url).subscribe({
          next: (res) => {
            this.TransportList = res;
            this.NewTransport = this.TransportList.filter(
              (x) =>
                x.TransportCompanyName == this.NewTransport.TransportCompanyName
            )[0];
            this.NewVehicle.TransportId = this.NewTransport.TransportId;
            this.NewVehicle.VehicleNumber = this.fT['VehicleNumber'].value;
            this.NewVehicle.VehicleType = this.fT['VehicleType'].value;

            let url = this.ApiUrl + 'Transport/addUpdatetransportvehicle';
            this.http
              .post<TransportVehicleModel>(url, this.NewVehicle)
              .subscribe({
                next: (res) => {
                  this.alertService.success('Vehicle Saved Successfully');
                  this.isLoadingT = this.isVisibleT = false;
                  let url = this.ApiUrl + 'transport/getalltransport';
                  this.http.get<TransportModel[]>(url).subscribe({
                    next: (res) => {
                      this.TransportList = res;
                      this.NewTransport = this.TransportList.filter(
                        (x) =>
                          x.TransportCompanyName ==
                          this.NewTransport.TransportCompanyName
                      )[0];
                      this.VehicleId =
                        this.NewTransport.TransportVehicle.filter(
                          (x) =>
                            x.VehicleNumber == this.NewVehicle.VehicleNumber
                        )[0].VehicleId;
                      this.validateForm.controls['TransportId'].setValue(
                        this.NewTransport
                      );
                    },
                    error: (res) => {
                      this.alertService.error(
                        'An error has been occured. Please try again'
                      );
                    },
                  });
                },
                error: (res) => {
                  this.alertService.error(res.error);
                  this.isLoadingV = this.isVisibleV = false;
                },
              });
          },
          error: (res) => {
            this.alertService.error(
              'An error has been occured. Please try again'
            );
          },
        });
      },
      error: (res) => {
        this.alertService.error('An error has been occured. Please try again');
        this.isLoading = this.isVisible = false;
      },
    });
  }
  SaveVehicle() {
    if (this.validateFormV.invalid) {
      Object.values(this.validateFormV.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoadingV = false;
      return;
    }

    this.NewVehicle.VehicleNumber = this.fV['VehicleNumber'].value;
    this.NewVehicle.VehicleType = this.fV['VehicleType'].value;

    let url = this.ApiUrl + 'Transport/addUpdatetransportvehicle';
    this.http.post<TransportVehicleModel>(url, this.NewVehicle).subscribe({
      next: (res) => {
        this.alertService.success('Vehicle Saved Successfully');
        this.isLoadingV = this.isVisibleV = false;
        let url = this.ApiUrl + 'transport/getalltransport';
        this.http.get<TransportModel[]>(url).subscribe({
          next: (res) => {
            this.TransportList = res;
            this.NewTransport = this.TransportList.filter(
              (x) => x.TransportId == this.NewVehicle.TransportId
            )[0];
            this.VehicleId = this.NewTransport.TransportVehicle.filter(
              (x) => x.VehicleNumber == this.NewVehicle.VehicleNumber
            )[0].VehicleId;
            this.validateForm.controls['TransportId'].setValue(
              this.NewTransport
            );
          },
          error: (res) => {
            this.alertService.error(
              'An error has been occured. Please try again'
            );
          },
        });
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoadingV = this.isVisibleV = false;
      },
    });
  }
  files: NzUploadFile[] = [];
  mockOSSData = {
    dir: 'user-dir/',
    expire: '1577811661',
    host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
    accessId: 'c2hhb2RhaG9uZw==',
    policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
    signature: 'ZGFob25nc2hhbw==',
  };

  transformFile = (file: NzUploadFile): NzUploadFile => {
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = this.mockOSSData.dir + filename;

    return file;
  };

  getExtraData = (file: NzUploadFile): {} => {
    const { accessId, policy, signature } = this.mockOSSData;

    return {
      key: file.url,
      OSSAccessKeyId: accessId,
      policy,
      Signature: signature,
    };
  };

  //onChange(e: NzUploadChangeParam): void {

  //}
  onChange(event: any): void {
    this.filesSelected = true;

    this.uploadinvoicefile = event.target.files;
    //this.oninvoiceupload();
    // this.uploadProgress$ = from(event.target.files as FileList).pipe(
    //   map(file => this.uploadFile(file)),
    //   combineAll()
    // );
  }
  private fetchStorageTokenAndUpload(): Observable<any> {
    this.storage.getStorageSASToken(this.StorageAccContainerName)
      .subscribe(
        (token: string) => {
          this.uploadProgress$ = from(this.uploadinvoicefile as FileList).pipe(
            map(file => this.uploadFile(file, token)),
            combineLatestAll()
          );
          this.uploadinvoicefile = null;
        },
        (error: any) => {
          console.error('Error fetching storage token:', error);
        }
      );
    return null;
  }
  // oninvoiceupload() {
  //   this.uploadProgress$ = from(this.uploadinvoicefile as FileList).pipe(
  //     map((file) => this.uploadFile(file)),
  //     combineAll()
  //   );
  // }

  uploadFile(file: File, token: any): Observable<IUploadProgress> {
    const accessToken: ISasToken = {
      container: token.StorageContainerName,
      filename: 'GateIn/' + this.NewGateIn.GateInId + '/' + file.name,
      storageAccessToken: token.StorageAccountToken,
      storageUri: token.StorageAccountHost,
    };

    return this.blobStorage
      .uploadToBlobStorage(accessToken, file)
      .pipe(map((progress) => this.mapProgress(file, progress)));
  }
  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress,
    };
  }
  handleCheckbox(data: boolean) {
    this.IsChecked = data;
  }
  onSelectedInvoiceChange($event: any) {
    if ($event == null) {
      //this.NewGateIn.Invoice = new InvoiceModel;
    } else {
      var res = this.InvoiceList.filter((x) => x.InvoiceId == $event)[0];
      //this.NewGateIn.Invoice = res;
    }
  }

  onSelectedSupplierChange($event: any) {
    if ($event == null) {
      this.InvoiceList = this.InvoiceListOriginal;
    } else {
      var res = this.InvoiceListOriginal.filter((x) => x.SupplierId == $event);
      this.InvoiceList = res;
      this.FilteredPurchaseOrderList = this.PurchaseOrderList.filter(
        (x) => x.SupplierId == $event
      );
    }
    this.InvoiceModel.Poid = 0;
    this.InvoiceModel.InvoiceId = 0;
  }
  onSelectedPOChange($event: any) {
    if ($event == null) {
    } else {
      var res = this.SupplierList.filter((x) => x.SupplierId == $event);
      this.InvoiceModel.SupplierId = this.PurchaseOrderList.filter(
        (x) => x.Poid == $event
      )[0].SupplierId;
      this.selectedPOValue = this.FilteredPurchaseOrderList.filter(
        (x) => x.Poid == $event
      )[0].Ponumber;
      //this.validateForm.setValue(
      //  {
      //    TransportId: this.PurchaseOrderList.filter(x => x.Poid == $event)[0].TransportId

      //  })
      var selectedtransportID = this.PurchaseOrderList.filter(
        (x) => x.Poid == $event
      )[0].TransportId;
      this.VehicleId = 0;
      this.validateForm.controls['TransportId'].setValue(
        this.TransportList.filter(
          (x) => x.TransportId == selectedtransportID
        )[0]
      );

      //this.validateForm.controls['TransportId'].setValue = this.PurchaseOrderList.filter(x => x.Poid == $event)[0].TransportId;
    }
  }
  handleAddInvoice() {
    if (this.InvoiceModel.SupplierId == 0) {
      this.alertService.error('Select Supplier');
      return;
    }
    if (this.IsChecked) {
      if (this.InvoiceModel.InvoiceId == 0) {
        this.alertService.error('Select Invoice');
        return;
      }
    } else {
      if (this.InvoiceModel.InvoiceNumber == '') {
        this.alertService.error('Enter Invoice No.');
        return;
      }
    }
    this.InvoiceModel.Poid = parseInt(this.selectedPOValue);
    var I = new InvoiceMapping();
    I.InvoiceId = this.InvoiceModel.InvoiceId;
    I.InvoiceNumber = this.InvoiceModel.InvoiceNumber;
    I.SupplierId = this.InvoiceModel.SupplierId;
    I.SupplierName = this.SupplierList.filter(
      (x) => x.SupplierId == this.InvoiceModel.SupplierId
    )[0].SupplierName;
    if (this.InvoiceModel.Poid > 0) {
      I.Ponumber = this.PurchaseOrderList.filter(
        (x) => x.Poid == parseInt(this.selectedPOValue)
      )[0].Ponumber;
      I.Poid = this.InvoiceModel.Poid;
    }
    if (this.IsChecked) {
      I.InvoiceNumber = this.InvoiceListOriginal.filter(
        (x) => x.InvoiceId == this.InvoiceModel.InvoiceId
      )[0].InvoiceNumber;
    }
    this.InvoiceMappingModel.push(I);
    this.InvoiceModel.SupplierId = 0;
    this.InvoiceModel.InvoiceId = 0;
    this.InvoiceModel.InvoiceNumber = '';
    this.InvoiceModel.SupplierName = '';
    console.log(this.InvoiceMappingModel);
    console.log(this.PurchaseOrderList);
  }
  handleRemoveInvoice(data: any) {
    this.InvoiceMappingModel = this.InvoiceMappingModel.filter(
      (obj) => obj !== data
    );
  }

  GetImages($event: any) {
    this.storage.getStorageSASToken(this.StorageAccContainerName).subscribe((res: any) => {
      this.getToken = res;
      this.FilteredImageArray = this.ImageArray = [];
      var Blobtoken = this.getToken.StorageAccountToken;
      let url = this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + Blobtoken + "&restype=container&comp=list";

      const headers = new HttpHeaders().set(InterceptorSkipHeader, ''); // To exclude AD Authorization header
      this.http.get<any>(url, <Object>{ responseType: 'text', headers }).subscribe(res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res, "text/xml");
        var x = xmlDOM.documentElement.childNodes;
        for (var i = 0; i < x.length; i++) {

          if (x[i].nodeName == "Blobs") {
            for (var j = 0; j < x[i].childNodes.length; j++) {

              var str = x[i].childNodes[j].childNodes[0].childNodes[0].nodeValue ?? "";
              var arr = str.split('/')
              var item = {
                container: arr[0],
                ID: arr[1],
                Img: arr[2],
                URL: this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + "/" + str + Blobtoken
              }
              this.ImageArray.push(item);

            }
          }
          // + ": " + x[i].childNodes[0].nodeValue + "<br>"
        }
        this.FilteredImageArray = this.ImageArray.filter(x => x.container == "GateIn").filter(x => x.ID == $event);
        this.isImageVisible = true;
      }, res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res.error.text, "text/xml");
        const value = xmlDOM.getElementsByTagName("Blobs");

      });
    });
  }
  handleCancelImage(): void {
    this.isImageVisible = false;
  }

  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllGateInRecords();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.GateInListOriginal;
    var res2 = this.GateInListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: GateInModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.GateInDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.GateInDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.GateInList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.GateInList = res.filter(
        (item: GateInModel) => {
          if (
            item?.VehicleNumber?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.TransportCompanyName?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.Invoice[0]?.PONumber?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            (this.GetInvoices(item.Invoice)).toLowerCase().includes(this.searchValue?.toLowerCase())
          ) {
            return true;
          } else if (
            item?.GateInPerson?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.GateInPersonContact?.toLowerCase().includes(
              this.searchValue?.toLowerCase()
            )
          ) {
            return true;
          } else if (
            item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
          ) {
            return true;
          } else if (this.searchValue?.toLowerCase() == 'completed') {
            return item.GateIn == true || item.GateOut == true;
          } else if (this.searchValue?.toLowerCase() == 'not yet') {
            return !item.GateIn || !item.GateOut
          } else if (this.searchValue?.toLowerCase() == 'issued') {
            return item.GatePassIssue == true;
          } else if (this.searchValue?.toLowerCase() == 'not-issued') {
            return !item.GatePassIssue
          } else {
            return false;
          }
        })

    }
  }

  GetInvoices(data: InvoiceModel[]) {
    if (data.length == 0) {
      return 'NA'
    }
    else if (data.length == 1) {
      return data[0].InvoiceNumber;
    }
    else {
      var r = '';
      data.forEach((x, i) => {
        r += x.InvoiceNumber + ", "
      })
      return r.substring(0, r.length - 2);
    }

  }

  onFilterPanelOpen(data: any) {
    if (data == true) {
      // this.loader.show();
    }
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }

  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.TransportList.filter(
          (x) => x.TransportId != this.NewTransport.TransportId
        );
        var nre = res.filter(
          (x) =>
            x.TransportCompanyName.toLowerCase() == control.value.toLowerCase()
        );
        this.NameError = '';

        if (control.value == '') {
          this.NameError = 'Enter TransportCompany name';
          observer.next({ error: true, duplicated: true });
        } else if (nre.length > 0) {
          this.NameError = 'TransportCompany name already exist';
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = 'Enter TransportCompany name';
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
}

