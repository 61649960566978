<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Pigment MB</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="10">
        <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="clearcache()">
          <label nz-radio nzValue="0">New Pigment MB</label>
          <label nz-radio nzValue="1">Existing Pigment MB</label>
        </nz-radio-group>
      </div>
      <div nz-col [nzSpan]="6">
        <label *ngIf="radioValue == '1'" nz-checkbox  [(ngModel)]="AddMBF.IsMasterUpdate"><b> Update Master Record </b></label>
      </div>
    </div>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
      
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="6">
            <nz-form-item *ngIf="radioValue == '1'">
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                <label class="required form-label">Select Pigment MB Name </label>
                <nz-select class="form-select mb-2" nzSize="default" (ngModelChange)="OnMBFormulationDdlchange($event)" nzShowSearch
                 [(ngModel)]="selectedMBFormulation"  nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                  <nz-option *ngFor="let s of this.MbformulationMasterList;" [nzValue]="s.MbformulationId" [nzLabel]="s.MbformulationName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="radioValue == '0'">
              <nz-form-control [nzSpan]="24" nzErrorTip="Pigment MB Name">
                <label class="required form-label">Enter Pigment MB Name </label>
                <input nz-input name="MixingName" placeholder="Pigment MB Name" value="NewPO.MbformulationName" [(ngModel)]="NewPO.MbformulationName" [ngModelOptions]="{standalone: true}" />
              </nz-form-control>
            </nz-form-item>
    
          </div>
         
          <div  *ngIf="radioValue == '0'" nz-col [nzSpan]="4">
            <label class="required form-label">Category </label>
            <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="ProductCategoryId" name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllFirstCategory($event)" [ngModelOptions]="{standalone: true}">
              <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
            </nz-select>
          </div>
          <div  *ngIf="radioValue == '0'" nz-col [nzSpan]="4">
            <label class="form-label">Sub Category </label>
            <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="ProductFirstSubCategoryId" name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllSecondCategory($event)" [ngModelOptions]="{standalone: true}">
              <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
            </nz-select>
          </div>
          <div  *ngIf="radioValue == '0'" nz-col [nzSpan]="4">
            <label class="form-label">2nd Sub Category </label>
            <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="ProductSecSubCategoryId" name="ProductSecSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
              <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
            </nz-select>
          </div>

          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Batch Size">
                <label class="required form-label">Batch Size (KG) </label>
                <input nz-input name="BatchSize" placeholder="Batch Size" [(ngModel)]="NewPO.BatchSize" (ngModelChange)="CalculateBatchChange()" [ngModelOptions]="{standalone: true}" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="6">
            <nz-card nzType="inner" nzTitle="Consumption Store">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24">
                <label class="required form-label">Select Store</label>
                <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="FromStoreID" (ngModelChange)="onSelectedFromStoreChange($event)" nzSize="default" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
                </nz-select>
              </div>
            </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-card nzType="inner" nzTitle="Produced Product Storage Store">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="12">
                <label class="required form-label">Select Store</label>
                <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange($event)" [(ngModel)]="StoreID" nzSize="default" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
                </nz-select>
              </div>
              <div nz-col [nzSpan]="12">
                <label class="required form-label"> Select Rack</label>
                <nz-select nzShowSearch class="form-select mb-2" [disabled]="this.StoreID < 1" nzSize="default" [(ngModel)]="RackID" nzAllowClear [ngModelOptions]="{standalone: true}" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                </nz-select>
              </div>

            </div>
            </nz-card>
          </div>
          </div>

      <div style="margin:10px">


        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24" style="background-color: #f0f2f5; border-radius: 4px;">
            <nz-divider nzText="Raw Material"></nz-divider>
            <div nz-col [nzSpan]="24">
              <nz-form-item>

                <nz-form-control [nzSpan]="22" nzErrorTip="Enter Amount">
                  <label class="required">Select Raw Products</label>
                  <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple" [(ngModel)]="listOfSelectedproducts" (ngModelChange)="OnProductDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                  <tr>

                    <th nzWidth="50px">S.No</th>
                    <th>Product</th>
                    <th>Code</th>
                    <th nzWidth="">Base Quantity (KG)</th>
                    <th nzWidth="">Calculated Batch Size (KG)</th>
                    <th nzWidth="">Consume stock</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of productionRawMaterial;let i=index">

                    <td nzWidth="20px">{{i+1}}</td>
                    <td>{{ data.RawMaterialProductName }}</td>
                    <td>{{ data.RawMaterialProductCode }}</td>
                    <td>
                      <input nz-input [(ngModel)]="data.QuantityInKg" [ngModelOptions]="{standalone: true}" (ngModelChange)="CalculateQuantityBatchSizeInKg(data)" />
                    </td>
                    <td>
                      <input nz-input [(ngModel)]="data.QuantityBatchSizeInKg" disabled [ngModelOptions]="{standalone: true}" style="color: black;" />
                    </td>
                    <td>
                      <div *ngIf="!showstock(data.RawMaterialProductId)"> {{this.consumeStockColumnMsg}}</div>
                      <div *ngIf="showstock(data.RawMaterialProductId)">
                      <nz-table nzSize="small" #basicTable [nzData]="['']" [nzShowPagination]="false" nzBordered>
                        <thead>
                          <tr>
                            <th>Batch</th>
                            <th>Barcode</th>
                            <th>Rack</th>
                            <th>QTY</th>
                            <th>Consumption QTY</th>
                            <th>Remaining QTY</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let st of data.IssueProduct;let i=index">
                            <td >{{ st.Batch }}</td>
                            <td >{{ st.BarCode }}</td>
                            <td >{{ st.RackName }}</td>
                
                            <td>{{ st.Quantity }} / {{st.Unit}}</td>
                            <td>
                              <input nz-input [(ngModel)]="st.ConsumptionQuantity" [ngModelOptions]="{standalone: true}" type="number" />
                            </td>
                            <td>{{ st.RemainingQuantity }}</td>
                            </tbody>
                      </nz-table>
                    </div>
                    </td>
                  </tr>

                </tbody>

              </nz-table>
              <br>
              <div *ngIf="this.productionRawMaterial.length > 0"><b>Total Produced Quantity : {{calculatetotalQty()}}</b></div>
              <br>
            </div>
          </div>
        </div>
        <nz-divider></nz-divider>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
        </div>
        <nz-divider></nz-divider>
      </div>
    </form>


  </div>
</div>



