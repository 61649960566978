<app-Timeline></app-Timeline>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Costing List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your costing here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" routerLink="/home/costing/add">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                  <label>Customer</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                    [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                      [nzLabel]="s.CustomerName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Select Product Category">
                  <label>Category</label>
                  <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="request.CategoryId"
                    name="ProductCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                      [nzLabel]="s.ProductCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                  <label>Formulation Code</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                      [nzLabel]="s.SaleFormulationCode"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                  <label>Article Name</label>
                  <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30" [(ngModel)]="request.ArticleName"
                    (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Color</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId" nzShowSearch
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                      [nzLabel]="s.ColorName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Grain Name</label>
                  <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default" nzShowSearch
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                      [nzLabel]="s.GrainName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="3">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Thickness</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ThicknessId" nzAllowClear
                    nzShowSearch nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                      [nzLabel]="s.ThicknessNumber"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Sale Order No.</label>

                  <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
                    [(ngModel)]="request.SaleOrderNumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="5">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Added By</label>
                  <input nz-input name="" nzAllowClear placeholder="Enter Username" maxlength="30"
                    [(ngModel)]="request.AddedBy" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Date Type</label>
                  <nz-select name="select-error" [(ngModel)]="request.DateType">
                    <nz-option *ngFor="let data of dateTypeList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Date Filter</label>
                  <nz-select [(ngModel)]="selecteddateFilter" (ngModelChange)="getDateRange(selecteddateFilter)"
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='saleorderdate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Order Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromSaleOrderDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='saleorderdate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Order Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToSaleOrderDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='submitteddate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Submitted Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromAddedDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='submitteddate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Submitted Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToAddedDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='productiondate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Production Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromProductionDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='productiondate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Production Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToProductionDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='finalinspectiondate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Final Inspection Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromFinalInspectionDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='finalinspectiondate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Final Inspection Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToFinalInspectionDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='deliverydate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Delivery Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromDeliveryDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="request.DateType=='deliverydate' && enableCustomDateRange">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Delivery Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToDeliveryDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4" *ngIf="enableWorkShiftDropDown">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Work Shift</label>
                  <nz-select [(ngModel)]="request.WorkShift" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of WorkShiftList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Production Line No.</label>
                  <nz-select [(ngModel)]="request.ProductionLineNo" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of ProductionLineList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="3">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Order Type</label>
                  <nz-select name="select-error" [(ngModel)]="request.OrderType" nzShowSearch nzAllowClear
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of orderTypeList" [nzValue]="data" [nzLabel]="data"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Product Type</label>
                  <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of PUPVCTypeList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Costing Status</label>
                  <nz-select [(ngModel)]="request.CostingStatus" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of CostingStatus" [nzValue]="data" [nzLabel]="data"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="onSearch()">
                Apply Filter
              </button>
              <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
      <button nz-button nzSize="small" (click)="PrintListPage()"><i nz-icon nzType="printer" nzTheme="outline"></i>
        Print</button>
      <!-- <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
        printTitle="Costing Export Report"
        [printStyle]="{ '@page': { size: 'landscape !important' } }" style="margin-left:5px"><i
          style="top: 2px;right: 39px;position: absolute;" nz-icon nzType="printer" nzTheme="outline"></i>Print</button> -->
    </div>

    <div style="padding: 8px;float:left">
      <p>Click on Status of the order to open Costing Details</p>
      <p><nz-tag [nzColor]="'blue'">Blue</nz-tag><b>- Pending - Raw Material Consumption not completed yet</b></p>
      <p><nz-tag [nzColor]="'orange'">Orange</nz-tag><b>- Partial Ready - Raw Material Consumption Completed but waiting
          for Final
          Inspection to auto calculate Rejection %.</b></p>
      <p><nz-tag [nzColor]="'yellow'">Yellow</nz-tag><b>- Ready - Raw Material Consumption Completed but waiting for
          Overhead</b></p>
      <p><nz-tag [nzColor]="'green'">Green</nz-tag><b>- Submitted - Raw Material Consumption Completed and Submitted
          with Overhead
          and Rejection %</b></p>
    </div>
    <nz-table id="print-section" nzSize="small" style="font-size: small;" #basicTable
      [nzData]="this.CostingListOriginal" [nzLoading]="isTableLoading" nzBordered
      [nzScroll]="{ x: '1500px', y: '515px' }" [nzPageSize]="20" nzShowPagination="true" nzShowSizeChanger
      [nzPageSizeOptions]=[50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
      <thead>
        <tr style="font-size: small;">
          <th nzWidth="60px">S.No.</th>
          <th nzWidth="120px">Sale Order No.</th>
          <th nzWidth="110px" style="text-align:center">Status</th>
          <th nzLeft nzWidth="120px">Customer Name</th>
          <th nzWidth="200px">Sale Order Code</th>
          <th nzWidth="90px">Alias</th>
          <th nzWidth="90px">Order QTY</th>
          <th nzWidth="90px">MFD QTY</th>
          <th nzWidth="100px">Rejection %</th>
          <th nzWidth="110px">Rejection Cost /LM</th>
          <th nzWidth="110px">Fabric Total Cost</th>
          <th nzWidth="110px">Production Cost /LM</th>
          <th nzWidth="110px">Overhead Cost /LM</th>
          <th nzWidth="110px">Total Cost /LM</th>
          <th nzWidth="130px">Final Total Cost</th>
          <th nzWidth="110px">Profit/Loss Per LM</th>
          <th nzWidth="130px">Total Profit/Loss</th>
          <th nzWidth="150px">Production Completed Date</th>
          <th nzWidth="150px">Submitted Date</th>
          <th nzWidth="130px">Submitted By</th>
          <th nzRight nzWidth="130px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.SerialNo }}</td>
          <td>{{ data.SaleOrderNumber }}</td>
          <td style="text-align:center">
            <nz-tag [nzColor]="GetSaleOrderTagColor(data)"><a (click)="OpenCostingPop(data)">
                {{data.CostingStatus}}
              </a></nz-tag>
          </td>
          <td nzLeft nzWidth="120px">{{ data.CustomerName }}</td>
          <td>{{ data.SaleOrderCode }}</td>
          <td>{{ data.SaleOrderProduction.ManufacturingProductName }}</td>
          <td>{{ data.SaleOrderProduction.OrderQuantity }} MTR</td>
          <td>{{ data.SaleOrderProduction.ManufacturingQuantity }} MTR</td>
          <td>{{ data.Rejection }} %</td>
          <td>&#8377;{{ data.RejectionCostLm }}</td>
          <td>&#8377;{{ data.FabricCost }}</td>
          <td *ngIf="data.CostingStatus != 'Submitted'">&#8377;{{ data.ProductionCostLm }}</td>
          <td *ngIf="data.CostingStatus == 'Submitted'" (click)="OpenViewPop(data)"><span class="spn"><a
                class="btn-link">&#8377;{{ data.ProductionCostLm
                }}</a></span></td>
          <td>&#8377;{{ data.OverheadCost }}</td>
          <td>&#8377;{{ data.TotalCostLm }}</td>
          <td>&#8377;{{ data.FinalTotalCost | RoundOff}}</td>
          <td>&#8377;{{ data.ProfitLossLm | RoundOff }}</td>
          <td>&#8377;{{ data.TotalProfitLoss }}</td>
          <td>{{ data.ProductionCompletedDate | DatetimeConverter }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td>{{ data.AddedBy }}</td>
          <td nzRight style="text-align: center">
            <div>
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item (click)="OpenCostingPop(data)">View</li>
                  <li style="width: 120px;" nz-menu-item (click)="PrintPage(data.SaleOrderId)">Print</li>
                  <li style="width: 120px;" nz-menu-item (click)="PrintPageStandardGSM(data.SaleOrderId)">Print with Std
                    GSM</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr style="text-align: left;font-weight: bold;">
          <td colspan="3"></td>
          <td nzLeft nzWidth="120px"></td>
          <td colspan="2" style="text-align: right;">Grand Total</td>
          <td>{{CalculateTotal('SaleOrderQuantity')}} MTRs</td>
          <td>{{CalculateTotal('ManufacturingQuantity')}} MTRs</td>
          <td colspan="6"></td>
          <td colspan="2">&#8377;{{CalculateTotal('FinalTotalCost')}}</td>
          <td colspan="4">&#8377;{{CalculateTotal('TotalProfitLoss')}}</td>
          <td nzRight></td>
        </tr>
      </tfoot>
    </nz-table>
  </div>
</div>

<nz-modal [(nzVisible)]="isPerMtrDetailsVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="1150"
  [nzTitle]="modalTitleDispatch" [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch"
  (nzOnCancel)="handleRMDetailsCancel()">
  <ng-template #modalTitleDispatch>Raw Material Cost Details</ng-template>

  <ng-template #modalContentDispatch>
    <nz-table nzTemplateMode nzBordered>
      <thead>
        <tr>
          <th>Sale Order No.</th>
          <th>Per LM</th>
          <th>Paste /LM</th>
          <th>Grain Cost /LM</th>
          <th>Fabric Cost /LM</th>
          <th>Finishing Cost /LM</th>
          <th>Raw Material Cost /LM</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ PerLmData.SaleOrderNumber}}</td>
          <td>{{ PerLmData.PerLmconstant}}</td>
          <td>&#8377;{{ PerLmData.PasteCostLm}}</td>
          <td>&#8377;{{ PerLmData.GrainCostLm}}</td>
          <td>&#8377;{{ PerLmData.FabricCostLm}}</td>
          <td>&#8377;{{ PerLmData.FinishingCostLm}}</td>
          <td>&#8377;{{ PerLmData.RmcostLm}}</td>
        </tr>
      </tbody>
    </nz-table>
    <nz-table nzTemplateMode nzBordered>
      <thead>
        <tr>
          <th colspan="6">Finishing Cost Details</th>
        </tr>
        <tr>
          <th rowSpan="2">Print Per Unit</th>
          <th rowSpan="2">Embossing Per Unit</th>
          <th rowSpan="2">Vacuum Per Unit</th>
          <th colspan="2">Lacquer Cost</th>
          <th rowSpan="2">Tumbling Per Unit</th>
        </tr>
        <tr>
          <th>Lacquer Cost Per Unit</th>
          <th>Inline Scraping Per Unit</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&#8377;{{ PerLmData.PrintCostPerUnit}}</td>
          <td>&#8377;{{ PerLmData.EmbossingCostPerUnit}}</td>
          <td>&#8377;{{ PerLmData.VacuumCostPerUnit}}</td>
          <td>&#8377;{{ PerLmData.LacquerCostPerUnit}}</td>
          <td>&#8377;{{ PerLmData.InlineScraping}}</td>
          <td>&#8377;{{ PerLmData.TumblingCostPerUnit}}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooterDispatch>
    <button nz-button nzType="default" (click)="handleRMDetailsCancel()">Close</button>
  </ng-template>
</nz-modal>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>

<app-SaleOrderCostingView></app-SaleOrderCostingView>