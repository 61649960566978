<div>
  <table class="companytable">
    <tr>
      <td>
        <div class="container1">
          <div class="image">
            <img src="/assets/img/logo-globe.jpg" (load)="CheckOnload()" />
          </div>
          <div class="text">
            <b style="text-transform: uppercase;">{{ Company.CompanyName }}</b>
            <p style="font-size: 12px;">{{Company.Address}}</p>
          </div>
        </div>
        <!-- <div class="company">
          <table style=" border-top: 1px solid;margin-top:10px"><b>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">Sale Order No:</td>
                <td style="padding-left:10px"><b>{{JumboData.SaleOrderNumber}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">JUMBO ROLL ID:</td>
                <td style="padding-left:10px"><b>{{JumboData.JUMBOROLLID}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">PRD Line/Supervisor:</td>
                <td style="padding-left:10px"><b>{{JumboData.ProductionLineNo}} / {{JumboData.SupervisorName }}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">PARTY NAME:</td>
                <td style="padding-left:10px"><b>{{JumboData.CUSTOMERNAME}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">ARTICLE NAME:</td>
                <td style="padding-left:10px"><b>{{JumboData.ARTICLENAME}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">PRODUCT CODE:</td>
                <td style="padding-left:10px"><b>{{JumboData.PRODUCTCODE}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>GRAIN NAME:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.GRAIN}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">COLOUR/FINISH:</td>
                <td style="padding-left:10px"><b>{{JumboData.COLOUR}}/{{JumboData.FINISH}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">THICKNESS:</td>
                <td style="padding-left:10px"><b>{{JumboData.THICKNESS}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">WIDTH:</td>
                <td style="padding-left:10px"><b>{{JumboData.WIDTH}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">LENGTH & WEIGHT:</td>
                <td style="padding-left:10px"><b>MTR - {{JumboData.LENGTH }}/KG - {{JumboData.WEIGHT}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>DATE:</b></td>
                <td style="padding-left:10px"><b>{{JumboData.DATE}}</b></td>
              </tr>
            </b>
          </table>




        </div> -->
        <div class="company">
          <table style=" border-top: 1px solid;margin-top:10px"><b>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">Sale Order No:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.SaleOrderNumber}}</b></td>
              </tr>
              <tr *ngIf="JumboPrintData.ParentSaleOrderNumber != null">
                <td style=" border-right: 1px solid; text-align: center;">Upper Sale Order No:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.ParentSaleOrderNumber }}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">JUMBO ROLL ID:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.JumboNo}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">PRD Line/Supervisor:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.ProductionLineNo}} / {{JumboPrintData.SupervisorName
                    }}</b>
                </td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">PARTY NAME:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.CustomerName}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">ARTICLE NAME:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.ArticleName}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">PRODUCT CODE:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.SaleFormulationCode}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>GRAIN NAME:</b></td>
                <td style="padding-left:10px"><b>{{JumboPrintData.GrainName}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">COLOUR/FINISH:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.ColorName}}/{{JumboPrintData.FinishCode}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">THICKNESS:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.Thickness}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">WIDTH:</td>
                <td style="padding-left:10px"><b>{{JumboPrintData.WidthNumber}}</b></td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;">LENGTH & WEIGHT:</td>
                <td style="padding-left:10px"><b>MTR - {{JumboPrintData.Length }}/KG - {{JumboPrintData.Weight}}</b>
                </td>
              </tr>
              <tr>
                <td style=" border-right: 1px solid; text-align: center;"><b>DATE:</b></td>
                <td style="padding-left:10px"><b>{{JumboPrintData.JumboRollDate }}</b></td>
              </tr>
            </b>
          </table>
        </div>
      </td>
    </tr>
  </table>





</div>