import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { StoreModel, RackModel } from '../../Models/MasterModel';
import { TransportModel, TransportVehicleModel } from '../../Models/TransportModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute } from '@angular/router';
import { UserInfo } from '../../Authentication/UserInfo';
import { LoadingService } from '../../Services/loadingService';
import { JumboInspectionModel, WorkPlanJumboMasterModel, WorkPlanMasterModel, WorkPlanOrderModel } from '../../Models/WorkPlanModel';
import { SaleOrderDispatchModel, SaleOrderDispatchReadyModel, SaleOrderModel, SaleOrderProductionRawMaterialModel } from '../../Models/SalesOrderModel';
import { IssueProductModel } from '../../Models/IssueModel';
import { ESalesOrderStatus, Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-Dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.css']
})
export class DispatchComponent implements OnInit {
  Type = 'Production';
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  WorkPlanMasterList: WorkPlanJumboMasterModel[] = [];
  JumboInspection: JumboInspectionModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SelectedOrderNo: string = '';
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  NewPOList: IssueProductModel[] = [];
  SaleOrderDispatchItems: any[] = [];
  SaleOrder: SaleOrderModel = new SaleOrderModel;
  RackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreId: number = 0
  DispatchReadyBarcode: string = '';
  DispatchNumber: string = '';
  DispatchDate: string = new Date().toString();
  PacketNumber: number = 0;
  sumPacketNumber: number = 0;
  DispatchReadyRack: number = 0;
  PacketWeight: number = 0;
  sumPacketWeight: number = 0;
  DispatchReadyQuantity: number = 0;
  TransportId: number = 0;
  VehicleId: number = 0;
  isDispatchedReady: boolean = false;
  IsDispatchCompleted: boolean = false;
  TransportList: TransportModel[] = [];
  TransportVehicleList: TransportVehicleModel[] = [];
  SaleOrderDispatchList: SaleOrderDispatchModel[] = [];
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private loader: LoadingService, private auth: AuthService) { }
  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.DispatchPackaging, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllWorkPlanList();
    this.GetAllStore();
    this.GetAllRack();
  }
  GetAllWorkPlanList() {
    if (this.WorkPlanList.length <= 0) {

      let url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
      var item = ["MoveToDispatch", "DispatchReady", "DispatchedGateIn"];
      this.http.post<any>(url, item).subscribe({
        next: res => {
          this.WorkPlanList = res.Data;
        },
        error: res => { this.alertService.error(res.error); },
      });
    }
  }

  OnWorkPlanChange() {
    this.clearWorkplan();
    this.SaleOrder = new SaleOrderModel;
    this.SaleOrderDispatchItems = [];
    this.SelectedWorkPlanOrder = [];
    // this.SaleOrder.SaleOrderId;
    this.DispatchReadyBarcode = '';
    this.DispatchReadyRack = 0;
    this.DispatchReadyQuantity = 0;
    this.StoreId = 0;
    this.SaleOrder.SaleOrderDispatchReady = new SaleOrderDispatchReadyModel();
    this.loader.show();

    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + this.SelectedWorkPlan.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "MoveToDispatch" || x.SaleOrder.Status.toString() == "DispatchReady" || x.SaleOrder.Status.toString() == "DispatchedGateIn");
      console.log(res);
      this.loader.hide();
    }, res => { });
  }
  OnWorkPlanOrderChange() {

    this.GetAllJumboList()

    this.isDispatchedReady = false;
    this.DispatchReadyBarcode = '';
    this.DispatchReadyRack = 0;
    this.DispatchReadyQuantity = 0;
    this.StoreId = 0;
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + this.SelectedOrderNo;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      console.log(res);
      this.SaleOrder = res;
      this.GetDispatchDetails();
      this.loader.hide();
    }, res => { });
    this.GetAllTransport();
  }
  GetDispatchDetails() {

    this.loader.show();
    let url = this.ApiUrl + "saleorder/getsaleorderdispatchdetailbyid/" + this.SelectedOrderNo;
    this.http.get<SaleOrderModel>(url).subscribe(res => {
      this.SaleOrderDispatchList = res.SaleOrderDispatch;
      console.log(this.SaleOrderDispatchList);
      if (res.Status.toString() == "DispatchReady" || res.Status.toString() == "DispatchedGateIn") {
        this.SaleOrder.SaleOrderDispatchReady = res.SaleOrderDispatchReady
        this.isDispatchedReady = true;
        var racked = this.RackListOriginal.filter(x => x.RackId == res.SaleOrderDispatchReady?.DispatchReadyRack)[0];
        var store = this.StoreList.filter(x => x.StoreId == racked.StoreId)[0];
        this.SaleOrder.SaleOrderDispatchReady.RackName = racked.RackName;
        this.SaleOrder.SaleOrderDispatchReady.StoreName = store.StoreName;
      }
      this.loader.hide();
    }, res => { this.loader.hide(); });
    // this.GetAllTransport();
  }
  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllStore(); }
    });
  }

  GetAllRack() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackListOriginal = res;
      this.RackList = res;
      //this.RackID = 0;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllRack(); }
    });
  }

  onSelectedStoreChange($event: number) {
    this.DispatchReadyRack = 0;
    this.RackList = this.RackListOriginal.filter(x => x.StoreId == $event);
  }

  GetAllTransport() {
    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe({
      next: res => { this.TransportList = res; },
      error: res => {
        this.alertService.error("An error has been occured. Please try again"),
          this.count++;
        if (this.count < 2) { this.GetAllTransport(); }
      },
    });
  }

  onSelectedTransportChange($event: number) {
    var StockProductRejectedDispatch = this.TransportList.filter(x => x.TransportId == $event)[0];
    this.TransportVehicleList = StockProductRejectedDispatch.TransportVehicle;

  }

  AddSaleOrderDispatchItems() {

    var Item = {
      PacketNumber: this.PacketNumber,
      PacketWeight: this.PacketWeight,
      TransportId: this.TransportId,
      TransportName: this.TransportId == 0 ? 'Other' : this.TransportList.filter(el => el.TransportId == this.TransportId)[0].TransportCompanyName,
      VehicleId: this.VehicleId,
      VehicleName: this.VehicleId == 0 ? 'Other' : this.TransportVehicleList.filter(el => el.VehicleId == this.VehicleId)[0].VehicleNumber,
      VehicleNumber: this.VehicleId == 0 ? 'Other' : this.TransportVehicleList.filter(el => el.VehicleId == this.VehicleId)[0].VehicleNumber,
      DispatchNumber: this.DispatchNumber,
      DispatchDate: new Date(this.DispatchDate),
      SaleOrderId: this.SaleOrder.SaleOrderId,
      AddedBy: UserInfo.EmailID,
      IsDispatchCompleted: this.IsDispatchCompleted
    }
    if (Item.PacketNumber <= 0) {
      this.alertService.error("Enter Packet Number");
      return;
    }
    if (Item.PacketWeight <= 0) {
      this.alertService.error("Enter Packet Weight");
      return;
    }
    if (Item.TransportId <= 0) {
      this.alertService.error("Select Transport");
      return;
    }
    if (Item.VehicleId <= 0) {
      this.alertService.error("Select Vehicle");
      return;
    }
    if (Item.DispatchNumber == '') {
      this.alertService.error("Enter Dispatch Number");
      return;
    }
    if (Item.DispatchDate.toString() == '') {
      this.alertService.error("Select Dispatch Date");
      return;
    }
    this.SaleOrderDispatchItems.push(Item);
    this.sumPacketNumber = this.SaleOrderDispatchItems.length;
    this.sumPacketWeight = Number.parseInt(this.sumPacketWeight.toString()) + Number.parseInt(this.PacketWeight.toString());
    this.PacketNumber = 0;
    this.PacketWeight = 0;
    this.TransportId = 0;
    this.VehicleId = 0;
    this.DispatchNumber = '';
    //this.DispatchDate = '';
  }

  SaveReadyDispatch() {

    var Item = new SaleOrderDispatchReadyModel();
    Item.SaleOrderId = this.SaleOrder.SaleOrderId;
    Item.DispatchReadyBarcode = this.DispatchReadyBarcode;
    Item.DispatchReadyRack = this.DispatchReadyRack;
    Item.DispatchReadyQuantity = this.DispatchReadyQuantity;
    let url = this.ApiUrl + "saleorder/addsaleorderdispatchready";
    this.http.post<any>(url, Item).subscribe({
      next: res => {
        this.isDispatchedReady = true;
        this.SaleOrder.Status = ESalesOrderStatus.DispatchReady;
        this.alertService.success("Entry Saved Successfully");
        this.SaleOrder.SaleOrderDispatchReady = Item;
        this.SaleOrder.SaleOrderDispatchReady.RackName = this.RackList.filter(x => x.RackId == this.DispatchReadyRack)[0].RackName;
        this.SaleOrder.SaleOrderDispatchReady.StoreName = this.RackList.filter(x => x.RackId == this.DispatchReadyRack)[0].StoreName;
        this.IsDispatchCompleted = false;
      },
      error: res => { this.alertService.error(res.error); },
    });
  }
  sumDispatchQTY(data: JumboInspectionModel) {
    if (data.DispatchedQuantity > data.Quantity) {
      data.DispatchedQuantity = data.Quantity;
      this.alertService.error("Dispatch Quantity cannot be more than Quantity");
      return
    }
    this.DispatchReadyQuantity = 0;
    this.JumboInspection.forEach(x => {
      this.DispatchReadyQuantity += x.DispatchedQuantity ?? 0
    })
  }
  SaveDispatchItems() {
    if (this.SaleOrderDispatchItems.length <= 0) {
      this.alertService.error("Add Dispatch Details");
      return;
    }
    this.SaleOrderDispatchItems.forEach(e => {
      e.IsDispatchCompleted = this.IsDispatchCompleted
    })
    //var Item = {

    //  TransportId: this.TransportId,
    //  VehicleId: this.VehicleId,
    //  DispatchNumber: this.DispatchNumber,
    //  DispatchDate: this.DispatchDate,
    //  SaleOrderDispatchItems: this.SaleOrderDispatchItems,
    //}
    let url = this.ApiUrl + "saleorder/addsaleorderdispatch";
    this.http.post<any>(url, this.SaleOrderDispatchItems).subscribe({
      next: res => {
        this.isDispatchedReady = true;
        this.alertService.success("Disptach completed Successfully.");
        this.SaleOrder = new SaleOrderModel;
        this.SaleOrderDispatchItems = [];
        this.SelectedWorkPlanOrder = [];
        // this.SaleOrder.SaleOrderId;
        this.DispatchReadyBarcode = '';
        this.DispatchReadyRack = 0;
        this.DispatchReadyQuantity = 0;
        this.StoreId = 0;
        this.WorkPlanList = [];
        this.SelectedWorkPlan = new WorkPlanMasterModel;
        this.GetAllWorkPlanList();
        this.isDispatchedReady = false;
        this.clearWorkplan();
      },
      error: res => { this.alertService.error(res.error); },
    });
  }

  GetAllJumboList() {
    let url = this.ApiUrl + 'workplan/getallworkplanjumbo';
    this.http.get<any>(url).subscribe({
      next: res => {
        this.WorkPlanMasterList = res.Data;
        this.JumboInspection = [];
        this.WorkPlanMasterList.filter(x => x.SaleOrderId == parseInt(this.SelectedOrderNo)).forEach(el => {
          el.JumboInspection.forEach(x => {
            this.JumboInspection.push(x)
          })

        })
        console.log(this.JumboInspection)
      },
      error: res => { },
    });
  }

  handleRemoveRow(data: any) {
    this.SaleOrderDispatchItems = this.SaleOrderDispatchItems.filter((obj) => obj !== data);

  }
  clearWorkplan() {
    this.sumPacketWeight = 0;
    this.JumboInspection = [];
    this.SaleOrderDispatchList = [];
    this.SelectedOrderNo = '';
    this.SaleOrder = new SaleOrderModel;
  }
}
