<div style="border:1px solid;background-color: white;" id="jobcardprint" *ngIf="CEPrint">
    <div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
        <p style="font-size: 15px;"><b>{{this.Company.CompanyName}}</b></p>
        <p style="font-size: 10px;"><b>Head Office: </b>{{this.Company.HeadOfficeAddress}}</p>
        <p style="font-size: 10px;"><b>Factory Address: </b>{{this.Company.FactoryAddress}}</p>
    </div>
    <div style="text-transform: uppercase; font-size: 13px; text-align: center; color: black; border: 1px solid black;">
        <b>Software Generated After Production Costing Details for Order No:
            {{CEPrint.EstimationOrderId}}</b>
    </div>
    <div style="text-transform: uppercase; font-size: 11px; text-align: center; color: black; border: 1px solid black;">
        <b>The calculations are based on: {{GSMTypePrintText}}</b>
    </div>
    <nz-divider nzText="Cost Estimation Details"></nz-divider>
    <nz-descriptions *ngIf="CEPrint" nzBordered nzSize="small" [nzColumn]="3" style="border: 1px solid black;">
        <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
            <b>{{ CEPrint.SaleOrderType }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Estimation for" [nzSpan]="1"><b>{{ CEPrint.CustomerName
                }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Remark" [nzSpan]="1"><b>{{ CEPrint.Remarks
                }}</b></nz-descriptions-item>
    </nz-descriptions>
    <nz-divider nzText="Production Details"></nz-divider>
    <nz-descriptions *ngIf="CEPrint" nzBordered nzSize="small" [nzColumn]="4" style="border: 1px solid black;">
        <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                CEPrint.EstimationSaleFormulationCode 
                }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Thick">
            <b>
                {{
                CEPrint.ThicknessNumber
                }}
            </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Grain">
            <b>
                {{
                CEPrint.GrainName
                }}
            </b>
            <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                nzTooltipTitle="Grain Code: {{CEPrint.GrainId}}" nzTooltipPlacement="left" nz-tooltip
                nzType="info-circle" nzTheme="outline">
            </span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Width">
            <b>
                {{
                CEPrint.WidthNumber
                }}
            </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2">
            <b>
                {{
                CEPrint.ManufacturingProductName
                }}
            </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="QTY">
            <b>
                {{
                CEPrint.OrderQuantity
                }}
            </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Estimated Price">
            <b>
                {{
                CEPrint.EstimationPrice
                }}
            </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Total Estimated Price">
            <b>
                {{
                calculateTotalEstimatedPrice()
                }}
            </b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Color">
            <b>
                {{
                CEPrint.ColorName
                }}
            </b>
        </nz-descriptions-item>
    </nz-descriptions>
    <nz-divider nzText="Fabric"></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px" *ngIf="CEPrint.EstimationFabricProductDetail.length > 0">
        <div nz-col [nzSpan]="24">
            <span>
                <div *ngFor="let i of CEPrint.EstimationFabricProductDetail">
                    <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                        <thead>
                            <tr>
                                <th nzTitle="S. No." nzWidth="50px">S.No</th>
                                <th nzTitle="Product Name">Product Name</th>
                                <th nzTitle="GSM" nzWidth="100px">GSM</th>
                                <th nzTitle="Quantity" nzWidth="100px">Quantity</th>
                                <th nzTitle="Price" nzWidth="100px">Price</th>
                                <th nzTitle="Total Price" nzWidth="100px">Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of CEPrint.FabricsData; let i = index">
                                <td nzWidth="20px">{{ i + 1 }}</td>
                                <td>{{ data.ProductName }}</td>
                                <td>{{ data.GSM }}</td>
                                <td>{{ data.Quantity }}</td>
                                <td>{{ data.Price }}</td>
                                <td>{{ data.Quantity * data.Price }}</td>
                            </tr>
                            <tr style="font-weight: bold;">
                                <td></td>
                                <td>Grand Total:</td>
                                <td>{{ TotalFabricGSM }}</td>
                                <td>{{ TotalFabricQuantity }}</td>
                                <td>{{ TotalFabricPrice }}</td>
                                <td>{{ GrandTotalFabricPrice }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </span>
        </div>
    </div>
    <nz-divider nzText="Mixing Details"></nz-divider>
    <div nz-col [nzSpan]="24" style="margin: 0px 0px;">
        <div nz-col [nzSpan]="24" *ngFor="let i of MixingSelectedList">
            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false"
                style="margin-left: 0; margin-right:0;font-weight: bolder;">
                <thead>
                    <tr style="text-transform: uppercase; font-size: 13px; text-align: center; color: black;">
                        <td colspan="7" style="border: 1px solid black;">
                            <b>{{i.MixingName}}</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="80px" style="text-align:left; border: 1px solid;font-size: small;"><b>S.No.</b>
                        </td>
                        <td width="200px" style="text-align:left; border: 1px solid;font-size: small;"><b>Product
                                Name</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Code</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Unit</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Price</b>
                        </td>
                        <td width="90px" style="text-align:center; border: 1px solid;font-size: small;"><b>QTY</b>
                        </td>
                        <td width="140px" style="text-align:center; border: 1px solid;font-size: small;"><b>Total
                                Cost</b>
                        </td>
                    </tr>
                    <tr *ngFor="let data of i.MixingRawMaterial;let i=index">
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ i + 1 }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{
                            data.ProductName }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{
                            data.ProductCode }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Unit
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Price
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Quantity
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{(data.Price *
                            data.Quantity).toFixed(2)}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <nz-divider nzText="Weight in GSM"></nz-divider>
        <div nz-row [nzGutter]="24"
            style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding: 30px 0;    background: rgb(240, 242, 245);">
            <div nz-col [nzSpan]="6">
            </div>
            <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="3">
                </div>
                <div nz-col [nzSpan]="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                            nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                            <label style="margin-left: -1px;">Pre Skin</label><br>
                            <input nz-input readonly type="number" [(ngModel)]="this.PreSkinGsm"
                                [ngModelOptions]="{standalone: true}" name="PreSkinGsm" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                            nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                            <label style="margin-left: -1px;">Skin</label>
                            <input nz-input readonly type="number" [(ngModel)]="this.SkinGsm"
                                [ngModelOptions]="{standalone: true}" name="SkinGsm" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                    <nz-form-item>
                        <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                            nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                            <label style="margin-left: -1px;">Foam</label>
                            <input nz-input readonly type="number" [(ngModel)]="this.FoamGsm"
                                [ngModelOptions]="{standalone: true}" name="FoamGsm" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                    <nz-form-item>
                        <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                            nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                            <label style="margin-left: -1px;">Adhesive</label>
                            <input nz-input readonly type="number" [(ngModel)]="this.AdhesiveGsm"
                                [ngModelOptions]="{standalone: true}" name="AdhesiveGsm" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                    <nz-form-item>
                        <nz-form-control style="margin-left: -70px;" [nzSpan]="20"
                            nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                            <label style="margin-left: -1px;">Fabric (GSM)</label>
                            <input nz-input type="number" [(ngModel)]="this.TotalFabricGSM"
                                [ngModelOptions]="{standalone: true}" name="FabricGsm" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-col [nzSpan]="15">
            </div>
            <div nz-col [nzSpan]="9">
                <nz-descriptions [nzColumn]="1">
                    <nz-descriptions-item nzTitle="Total (GSM)" nzColon="true">
                        <input nz-input type="text" readonly style="width:130px;" [(ngModel)]="this.TotalGsm"
                            [ngModelOptions]="{standalone: true}" />
                    </nz-descriptions-item>
                </nz-descriptions>
            </div>
        </div>
        <nz-divider></nz-divider>
        <nz-divider nzText="Costing Details"></nz-divider>
        <p>
            Note: Costing Details are calculated after the consumption for raw material is completed and if there are
            Post Process included in this order then that also needs to be processed before to show the actual cost.
        </p>
        <nz-divider></nz-divider>
        <nz-table *ngIf="CEPrint.EstimationFinishingTable?.length > 0" [nzData]="CEPrint.EstimationFinishingTable"
            nzBordered nzSize="small">
            <thead>
                <tr>
                    <th nzTitle="Material Type">Material Type</th>
                    <th nzTitle="Finishing Type">Finishing Type</th>
                    <th nzTitle="Selected Finishing Type">Selected Finishing Type</th>
                    <th nzTitle="Price">Price</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of CEPrint.EstimationFinishingTable; let i = index">
                    <td>{{ row.MaterialType }}</td>
                    <td>{{ row.FinishingType }}</td>
                    <td>{{ row.SelectedFinishingType }}</td>
                    <td>{{ row.Price }}</td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div>
            <nz-table [nzData]="MixingSelectedList" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                    <tr>
                        <th>Mixing Name</th>
                        <th>Per Kg Cost</th>
                        <th>GSM</th>
                        <th>Cost Per Lm</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let i of MixingSelectedList">
                        <td>{{ i.MixingName }}</td>
                        <td>{{ calculateTotalPriceForItem(i) }}</td>
                        <td> {{
                            i.MixingName === 'PRE SKIN' ? this.CEPrint.PreSkinGsm :
                            (i.MixingName === 'SKIN' ? this.CEPrint.SkinGsm :
                            (i.MixingName === 'ADHESIVE' ? this.CEPrint.AdhesiveGsm :
                            (i.MixingName === 'FOAM' ? this.CEPrint.FoamGsm : 0)))
                            }}
                        <td>{{ calculateCostPerLmForItem(i) }}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <div>
            <br>
            <nz-table [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                    <tr>
                        <th></th>
                        <th>GSM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Fabric</td>
                        <td>{{ this.FabricGsm }}</td>
                    </tr>
                    <tr>
                        <td>Coating</td>
                        <td>{{ this.Coating }}</td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>{{ this.FabricGsm + this.Coating}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <br>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="5">
            <nz-descriptions-item nzTitle="Per LM Constant" [nzSpan]="1">
                <b>{{this.PerLMConstant}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PASTE COST / LM" [nzSpan]="1" nzColon="true">
                <b>{{ CEPrint.TotalCostPerLm }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="GRAIN COST / LM" [nzSpan]="1" nzColon="true"><b>
                    {{ CEPrint.GrainPrice }}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="FABRIC COST/LM" [nzSpan]="1" nzColon="true"><b>
                    {{GrandTotalFabricPrice}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="FINISHING COST/ LM" [nzSpan]="1" nzColon="true">
                <b>
                    {{CEPrint.TotalFinishPrice}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="R\M COST / LM">
                <b>
                    {{CEPrint.TotalCostPerLm + CEPrint.GrainPrice + CEPrint.FabricProductCostPerLm +
                    CEPrint.TotalFinishPrice}}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">
            <nz-descriptions-item nzTitle="REJECTION %">
                {{CEPrint.Rejection }}%
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PRODUCTION COST / LM">
                <b>
                    {{ CEPrint.ProductionCostLm }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Line Speed">
                <b>
                    {{ CEPrint.LineSpeed }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Overhead">
                <b>
                    {{ CEPrint.OverheadCost }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Cost">
                <b>
                    {{ CEPrint.TotalCostPerLm }}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
        <p style="margin: 10px;">** Rejection Percentage shown above is auto calculated by
            taking sum quantity of
            these Graded Mini Rolls - LOT, NS, CUT-PC, FILM, Waste. Each Roll Details can be
            seen on Yield Report.
        </p>
        <p style="margin: 10px;">*** Overhead is auto calculated based on Line Speed for this
            order -
            {{CEPrint.LineSpeed}}
            MTRs/Minute and Current
            Monthly Average Overhead
            provided in Config Setting.
        </p>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Estimated QTY">
                <b>
                    {{CEPrint.OrderQuantity}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Production Line Speed">
                <b>
                    {{ CEPrint.LineSpeed}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Formulation Code">
                <b>
                    {{ CEPrint.EstimationSaleFormulationCode }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Estimated Price">
                <b>
                    {{ CEPrint.EstimationPrice }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Thick">
                <b>
                    {{ CEPrint.Thick}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Final Cost">
                <b>
                    {{ TotalEstimatedPrice}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Estimated Profit/Loss Per LM">
                <b>
                    {{ (+TotalEstimatedPrice - +TotalCostPerLm).toFixed(2) }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Estimated Profit/Loss">
                <b>
                    {{calculateTotalProfitLoss()}}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
    </div>
</div>
<app-loader></app-loader>