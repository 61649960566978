import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel, DeliveryTermModel, PaymentTermModel, ColorModel, GrainModel, ElementModel, StoreModel, BranchModel, DeptModel, AdminStoreModel } from '../../Models/MasterModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { productItem, ProductModel } from '../../Models/ProductModel';
import { PurchaseOrderModel, PurchaseOrderProductModel } from '../../Models/PurchaseOrderModel';
import { OrderModel, OrderProductModel, SupplierModel } from '../../Models/SupplierModel';
import { TransportModel } from '../../Models/TransportModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductionModel, ProductionElementModel, ProductionOrderModel, ProductionRawMaterialModel } from '../../Models/ProductionModel';
import { UserInfo } from '../../Authentication/UserInfo';
import { IssueProductModel } from '../../Models/IssueModel';
import { WorkPlanMasterModel, WorkPlanOrderModel } from '../../Models/WorkPlanModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductStockStoreModel, SaleOrderProductionRawMaterialModel } from '../../Models/SalesOrderModel';
import { IssueSaleOrderProductsStockModel, ProductStockStoreReportModel } from '../../Models/StockProductModel';
import { ESalesOrderStatus, Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { DemandPopService } from '../../Services/DemandPopService';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';

@Component({
  selector: 'app-Issue',
  templateUrl: './Issue.component.html',
  styleUrls: ['./Issue.component.css']
})
export class IssueComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  SupplierList: SupplierModel[] = [];
  FilteredSupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  ProductFilteredList: ProductModel[] = [];
  ProductRawList: ProductModel[] = [];
  ProductionList: ProductionModel[] = [];
  ProductionListOriginal: ProductionModel[] = [];
  Production!: ProductionModel;
  StoreList: StoreModel[] = [];
  AdminStoreList: AdminStoreModel[] = [];
  StoreListOriginal: StoreModel[] = [];
  BranchList: BranchModel[] = [];
  DeptList: DeptModel[] = [];
  DeptListOriginal: DeptModel[] = [];

  NewPO: IssueProductModel = new IssueProductModel;
  NewPOList: IssueProductModel[] = [];
  NewRawMaterialCategoryList: string[] = [];
  selectedproduct: any;
  selectedproductId: any = 0;
  selectedunit: any;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isVisible = false;
  isTableLoading: boolean = true;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: '',
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  }
  Amount = 0;
  SubTotal = 0;
  GrandTotal = 0;
  prolist: productItem[] = [];
  listOfSelectedproducts = [];
  listOfSelectedElement = [];
  listOfSelectedOrder = [];
  listOfSelectedOrderProdects: OrderProductModel[] = [];
  SelectedProduction = 0;
  SelectedDepartment1 = 0;
  SelectedDepartment2 = 0;
  SelectedStore = 0;
  SelectedRack = 0;
  SelectedStore1 = 0;
  SelectedStore2 = 0;
  SelectedSupplier = 0;
  SelectedBatch: string = '';
  SelectedBarcode: string = '';
  SelectedRemark: string = '';
  SelectedUnit: string = '-';
  SelectedQTY = 0;
  SelectedRawMaterialCategory: string = '';
  StockQTY = 0;
  ReqQTY = 0;
  productionRawMaterial: ProductionRawMaterialModel[] = [];
  productionElement: ProductionElementModel[] = [];
  productionOrder: ProductionOrderModel[] = [];
  allChecked = false;
  Type = '';
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  SelectedProductType: string = '';
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  FromStoreID: number = 0;
  WorkPlanList: WorkPlanMasterModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SelectedOrderNo: string = '';
  StockList: SaleOrderProductionRawMaterialModel[] = [];
  RawDetails: IssueSaleOrderProductsStockModel[] = [];
  RawDetailsOriginal: IssueSaleOrderProductsStockModel[] = [];
  RawDetailsSupplierOriginal: IssueSaleOrderProductsStockModel[] = [];
  RawDetailsBatchOriginal: IssueSaleOrderProductsStockModel[] = [];
  RawDetailsBarcodeOriginal: IssueSaleOrderProductsStockModel[] = [];
  RawDetailsStoreOriginal: IssueSaleOrderProductsStockModel[] = [];
  SelectedRawDetails: IssueSaleOrderProductsStockModel[] = [];
  SelectedRawList: IssueSaleOrderProductsStockModel[] = [];
  BatchList: string[] = [];
  BarcodeList: string[] = [];
  IsSupplier = true;
  IsBatch = true;
  IsBarcode = true;
  IsStore = true;
  IsRack = true;
  IsQuantity = true;
  permission = {
    View: false,
    Add: false
  }
  IsAuth = false;
  count: 0;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute, private router: Router, private loader: LoadingService, private demandService: DemandPopService,
    private auth: AuthService) { }
  handleOk(): void {

    // this.isLoading = true;

    this.Save();
  }
  GetAllWorkPlanList() {
    if (this.WorkPlanList.length <= 0) {
      this.isTableLoading = true;
      let url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
      var item = ["Inspection"];
      //var item = ["Inspection", "RawMaterialRequested", "RawMaterialIssued", "LacquerAssigned", "LacquerInProcess"];
      this.http.post<any>(url, item).subscribe(
        (res) => {
          this.WorkPlanList = res.Data;
        },
        (res) => { }
      );
    }
  }
  validateIssue(): boolean {

    if (this.FromStoreID < 1) {
      this.alertService.error("Please select To Store(Your Store)");
      return false;
    }
    var IsValid = true;
    let unique = [...new Set(this.StockList.map(item => item.ProductId))];
    if (this.SelectedRawList.length < unique.length && this.Type == 'Production') {
      this.alertService.error("All Required Raw Material needs to be Issued or change the store where those are present.");
      IsValid = false;
    }
    // if (this.Type == 'Store') {
    //   var CheckCount = this.NewPOList.filter(x => x.IsChecked == true).length;
    //   if (CheckCount == 0) {
    //     this.alertService.error("Select Products");
    //     return false;
    //   }
    //   else {
    //     this.NewPOList.filter(x => x.IsChecked).forEach(x => {

    //       if (x.FromStore <= 0) {
    //         this.alertService.error("Select From Store of " + x.ProductName);
    //         IsValid = false;

    //       }
    //       else if (x.ToStore <= 0) {
    //         this.alertService.error("Select To Store of " + x.ProductName);
    //         IsValid = false;
    //       }
    //       else if (x.FromStore == x.ToStore) {
    //         this.alertService.error("FromStore & ToStore cannot be same ");
    //         IsValid = false;
    //       }
    //       else if (x.Quantity <= 0) {
    //         this.alertService.error("Enter Issue Quantity of " + x.ProductName);
    //         IsValid = false;
    //       }
    //       else if (x.Quantity > x.QuantityValue) {
    //         this.alertService.error("Invalid Issue Quantity of " + x.ProductName);
    //         IsValid = false;

    //       }


    //       x.ActionBy = UserInfo.EmailID;
    //       x.CreatedBy = UserInfo.EmailID;

    //     })
    //     return IsValid;
    //   }
    // }
    return IsValid;
  }
  Save() {

    //this.validateIssue()
    if (this.validateIssue()) {

      this.NewPOList = [];
      this.SelectedRawList.forEach(x => {
        var I = new IssueProductModel;
        I.Quantity = x.Quantity;
        I.ProductId = x.ProductId;
        I.FromStore = x.StoreId;
        I.ToStore = this.FromStoreID;
        I.CreatedBy = UserInfo.EmailID;
        I.FromStore = x.StoreId;
        I.FromRack = x.RackId;
        I.StockId = x.StockId;
        I.StockProductId = x.StockProductId;
        I.SaleOrderId = parseInt(this.SelectedOrderNo);
        I.Remark = x.Remark;
        this.NewPOList.push(I);
      })
      if (this.Type == 'Production') {

        //this.StockList.forEach(x => {

        //  x.ProductStock.forEach(y => {
        //    if (y.AcceptQTY > 0 && y.AcceptQTY <= x.AvailableQTY) {

        //      var I = new IssueProductModel;
        //      I.Quantity = y.AcceptQTY;
        //      I.ProductId = x.ProductId;
        //      I.FromStore = y.StoreId;
        //      I.ToStore = this.FromStoreID;
        //      I.CreatedBy = UserInfo.EmailID;
        //      I.SaleOrderId = parseInt(this.SelectedOrderNo)
        //      this.NewPOList.push(I);
        //    }
        //  })

        //})
        if (this.NewPOList.length > 0) {
          this.loader.show();
          let url = this.ApiUrl + "issueproduct/saleorderissueproductrequest";
          this.http.post<IssueProductModel[]>(url, this.NewPOList).subscribe({
            next: res => {
              this.alertService.success("Issue Saved Successfully"); this.isLoading = false;
              this.loader.hide();
              this.router.navigate(['/home/issue/list'])
            },
            error: res => { this.alertService.error(res.error); this.isLoading = false; },

          });
        }
      }
      else {


        //this.NewPOList = this.SelectedRawList
        //this.StockList.forEach(x => {

        //  x.ProductStock.forEach(y => {
        //    if (y.AcceptQTY > 0 && y.AcceptQTY <= x.AvailableQTY) {

        //      var I = new IssueProductModel;
        //      I.Quantity = y.AcceptQTY;
        //      I.ProductId = x.ProductId;
        //      I.FromStore = y.StoreId;
        //      I.ToStore = this.FromStoreID;
        //      I.CreatedBy = UserInfo.EmailID;
        //      I.SaleOrderId = parseInt(this.SelectedOrderNo)
        //      this.NewPOList.push(I);
        //    }
        //  })

        //})
        if (this.NewPOList.length > 0) {
          this.loader.show();
          let url = this.ApiUrl + "issueproduct/issueproductrequest";
          this.http.post<string>(url, this.NewPOList).subscribe({
            next: res => {
              this.alertService.success(res);
              this.isLoading = false;
              this.router.navigate(['/home/issue/list'])
              this.loader.hide();
            },
            error: res => { this.alertService.error(res); this.isLoading = false; },

          });
        } else {
          this.loader.hide();
          this.isLoading = false;
        }
      }
    }
    else {
      this.isLoading = false;
    }
  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.Issue, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Issue, Responsibility.Add);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      supplierId: [null, [Validators.required]],
      deliveryTermId: [null, [Validators.required]],
      ponumber: [null, [Validators.required]],
      transportId: [null, [Validators.required]],
      paymentTermId: [null, [Validators.required]],
      pocreationDate: [new Date().toUTCString(), [Validators.required]],
      reference: [null]
    });

    //this.GetAllProduction();
    this.GetAllProducts();
    this.GetAllSupplier();
    //this.GetAllDept();
    //this.GetAllStore();
    //this.GetAllProductCategory();
    //this.GetAllWorkPlanList();
    this.GetRole(UserInfo.EmailID)

  }
  get f() { return this.validateForm.controls; }

  GetRole(UserName: string) {
    this.loader.show();
    let url = this.ApiUrl + "User/getuserrole/" + UserName;
    this.http.get<any>(url).subscribe(res => {

      if (res != null) {
        UserInfo.UserRoles = res
        this.IsAuth = true;
        this.loader.hide();
      }
    }, res => {
      this.GetRole(UserName);
      this.loader.hide();
    });
  }
  GetUserStores(UserName: string) {
    this.isTableLoading = true;
    this.AdminStoreList.forEach(x => x.IsChecked = false);
    let url = this.ApiUrl + "user/getuserstores/" + UserName;
    this.http.get<number[]>(url).subscribe(res => {
      console.log(res);
      this.AdminStoreList.forEach(s => {
        if (res.includes(s.StoreId)) {
          s.IsChecked = true;
        }
      })
      this.AdminStoreList = this.AdminStoreList.filter(x => x.IsChecked);
      this.isTableLoading = false;
    }, res => { });
  }
  GetAllStore() {
    if (this.StoreListOriginal.length <= 0) {
      this.isTableLoading = true;
      let url = this.ApiUrl + "store/getallstores";
      this.http.get<StoreModel[]>(url).subscribe(res => {
        this.StoreList = res;
        res.forEach(x => {
          var str = new AdminStoreModel()
          str.StoreId = x.StoreId;
          str.StoreName = x.StoreName;
          this.AdminStoreList.push(str);
        })
        this.StoreListOriginal = res;
        setTimeout(() => { this.SelectedStore1 = this.SelectedStore2 = this.StoreListOriginal[0].StoreId; }, 10)
        this.isTableLoading = false;
        this.GetUserStores(UserInfo.EmailID);
      }, res => { });
    }
  }

  GetAllDept() {

    let url = this.ApiUrl + "dept/getalldepts";
    this.http.get<DeptModel[]>(url).subscribe(res => {
      this.DeptList = res;
      this.DeptListOriginal = res;
      setTimeout(() => { this.SelectedDepartment1 = this.SelectedDepartment2 = this.DeptList[0].DeptId; }, 10)

      this.isTableLoading = false;
    }, res => { });
  }

  onSelectedProductTypeChange() {
    if (this.CategoryID > 0) {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductCategoryId == this.CategoryID && x.ProductType == this.SelectedProductType);
    } else {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
    }
    this.bindProduct();
  }

  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductFilteredList = res;
      //this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProducts();
      }
    });
  }
  GetAllProductCategory() {
    this.FirstCategoryID = 0;
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;

    }, res => { });
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FirstCategoryID = this.SecondCategoryID = 0;

    if (data == null) {
      this.ProductFilteredList = this.ProductRawList;
    }
    else {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductCategoryId == this.CategoryID && x.ProductType == this.SelectedProductType);

      //let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
      //this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      //  this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);


      //}, res => { });

    }
    this.bindProduct();
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.SecondCategoryID = 0;
    if (data == null) {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductType == "Raw" && x.ProductCategoryId == this.CategoryID);
      this.ProductFirstSubCategoryList = [];
    } else {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductType == "Raw" && x.ProductFirstSubCategoryId == this.FirstCategoryID);


      let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
      this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
        this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);


      }, res => { });
      this.bindProduct();
    }
  }
  GetSecondCategoryFilteredProduct() {

    if (this.SecondCategoryID == null) {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductType == "Raw" && x.ProductFirstSubCategoryId == this.FirstCategoryID);
      this.ProductSecSubCategoryList = [];
    } else {
      this.ProductFilteredList = this.ProductList.filter(x => x.ProductType == "Raw" && x.ProductSecSubCategoryId == this.SecondCategoryID);

    }
    this.bindProduct()
  }
  GetIssueProduct() {
    //if (this.FromStoreID <= 0)
    //{
    //  this.alertService.error("Select To store");
    //  return;
    //}
    //if (this.StockList.filter(x => x.ProductId == this.selectedproductId).length > 0) {
    //  this.alertService.error("Already added.");
    //  return;
    //}
    this.SelectedSupplier = 0;
    if (this.Type == "Store") {
      if (this.selectedproductId > 0) {
        this.loader.show();
        let url = this.ApiUrl + "issueproduct/getstockforproduct/" + this.selectedproductId;
        this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(res => {


          if (res.length > 0) {
            this.RawDetailsOriginal = res;
            this.RawDetails = res;
            let unique = [...new Set(res.map(item => item.SupplierId))];

            this.FilteredSupplierList = this.SupplierList.filter(x => unique.includes(x.SupplierId));
            this.SelectedRawDetails = this.RawDetails.filter(x => x.ProductId == this.selectedproductId);
            this.CategoryID = this.ProductList.filter(x => x.ProductId == this.selectedproductId)[0].ProductCategoryId;
            this.SelectedProductType = this.ProductList.filter(x => x.ProductId == this.selectedproductId)[0].ProductType;
            this.SelectedUnit = this.ProductList.filter(x => x.ProductId == this.selectedproductId)[0].Unit;
            // this.OnSupplierDdlchange();
            //var item = new SaleOrderProductionRawMaterialModel();
            //item.ProductId = this.selectedproductId;
            //item.ProductName = res[0].ProductName;
            //res.forEach(x => {
            //  var st = new ProductStockStoreModel();
            //  st.StoreId = x.StoreId;
            //  st.Quantity = x.Quantity;
            //  st.StoreName = x.StoreName;
            //  st.Supplier = x.SupplierName;
            //  st.Barcode = x.BarCode;
            //  st.StockDate = x.BatchNumber;
            //  if (this.FromStoreID == x.StoreId) {
            //    st.IsDisabled = true;
            //  }
            //  else {
            //    st.IsDisabled = false;
            //  }
            //  item.ProductStock.push(st);
            //})
            //this.StockList.push(item)
            //this.StockList.forEach(x => {
            //  x.AcceptQTY = x.Quantity;

            //  //x.AvailableQTY = x.ProductStock.reduce((sum, current) => sum + parseFloat(current.IsDisabled == false ? current.Quantity?.toString() : "0"), 0) ?? 0
            //  x.AvailableQTY = x.ProductStock.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
            //})
            console.log(this.StockList)
            this.loader.hide();
          } else {
            this.alertService.error("No stock available");
            this.loader.hide();
          }

        }, res => { });
      }
      else {
        this.alertService.error("Select Product");
        return;
      }
    }
    else {
      var res = this.StockList.filter(x => x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory)[0]
      this.RawDetailsOriginal = res.ProductStock;
      this.RawDetailsOriginal.forEach(x => x.MixingName == this.SelectedRawMaterialCategory);
      this.RawDetails = this.RawDetailsOriginal;
      console.log(this.RawDetails)

      let unique = [...new Set(this.RawDetails.map(item => item.SupplierId))];
      this.SelectedUnit = this.ProductList.filter(x => x.ProductId == this.selectedproductId)[0].Unit;
      this.FilteredSupplierList = this.SupplierList.filter(x => unique.includes(x.SupplierId));
      this.SelectedRawDetails = this.RawDetails.filter(x => x.ProductId == this.selectedproductId)
      //this.BatchList = this.RawDetails.map(x => x.BatchNumber);
      //this.BatchList = [...new Set(this.BatchList.map(item => item))];
      //this.IsBatch = false;
      this.ReqQTY = res.Quantity;

    }
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  UpdateProduct(data: any) {

    this.selectedunit = data.Unit;
    this.productItem.ProductName = data.ProductName;
    this.productItem.ProductID = data.ProductId;
    this.productItem.CategoryID = data.ProductCategoryId;
    //let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    //this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
    //  this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.ProductCategoryId);
    //  this.productItem.FirstCategoryID = data.ProductFirstSubCategoryId;
    //  let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    //  this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
    //    this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId);
    //    this.productItem.SecondCategoryID = data.ProductSecSubCategoryId

    //  }, res => { });


    //}, res => { });
    //this.GetAllFirstCategory(data.ProductCategoryId, data.ProductFirstSubCategoryId);
    //this.GetAllSecondCategory(data.ProductFirstSubCategoryId, data.ProductSecondSubCategoryId);

    //this.productItem.UnitID = data.ProductName;
    //var filteredorders = this.OrderList.filter(x => this.productionOrder.some(item => item.orderId === x.OrderId))
    //filteredorders.forEach(o => {
    //  o.OrderProduct.forEach(p => {
    //    if (p.ProductId == data.ProductId) {
    //      this.NewPO.orderQuantity += p.Quantity;
    //    }
    //  })
    //})


  }
  UpdateUnit(data: any) {


    this.productItem.Unit = this.selectedunit;
    this.productItem.UnitID = data.Id;
    //this.productItem.UnitID = data.ProductName;

  }

  OnRatechange(data: any) {

    var amt = data * this.productItem.Quantity;

    this.productItem.Amount = amt;

  }
  OnQtychange(data: any) {

    var amt = data * this.productItem.Rate;

    //this.Amount = amt;
    this.productItem.Amount = amt;

  }
  btnAddClick() {
    if (!this.Validate(this.productItem)) {
      return;
    }

    var Item = {
      CategoryID: this.productItem.CategoryID,
      FirstCategoryID: this.productItem.FirstCategoryID,
      SecondCategoryID: this.productItem.SecondCategoryID,
      ProductID: this.productItem.ProductID,
      ProductName: this.productItem.ProductName,
      UnitID: this.productItem.UnitID,
      Unit: this.selectedunit,
      AvgGsm: "",
      Rate: this.productItem.Rate,
      Quantity: this.productItem.Quantity,
      Amount: this.productItem.Amount,
      Grade: this.productItem.Grade,
      IGST: 0,
      Currency: ''
    }
    this.prolist.push(Item)

    this.SubTotal = this.GrandTotal = 0
    this.prolist.forEach((element) => {
      this.SubTotal += parseFloat(element.Rate.toString());
      this.GrandTotal += parseFloat(element.Amount.toString());
    });
    this.productItem = {
      CategoryID: 0,
      FirstCategoryID: 0,
      SecondCategoryID: 0,
      ProductID: 0,
      ProductName: '',
      UnitID: 0,
      Unit: '',
      Grade: '',
      Rate: 0,
      Quantity: 0,
      Amount: 0,
    }
    this.selectedunit = '';
    this.selectedproduct = '';


  }
  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ProductID == '0') {
      this.alertService.error("Select Product"); Isvalid = false;
    }
    else if (model.Rate == '0') {
      this.alertService.error("Enter Rate"); Isvalid = false;
    }
    else if (model.Quantity == '0') {
      this.alertService.error("Enter Issue Quantity"); Isvalid = false;
    }
    else if (this.SelectedRemark.length > 150) {
      this.alertService.error("Remark cannot be more that 150 characters"); Isvalid = false;
    }
    return Isvalid;
  }
  handleRemoveRow(data: any) {

    this.prolist = this.prolist.filter(obj => obj !== data);
    this.SubTotal = this.GrandTotal = 0
    this.prolist.forEach((element) => {
      this.SubTotal += parseFloat(element.Rate.toString());
      this.GrandTotal += parseFloat(element.Amount.toString());
    });
  }
  OnRawMaterialCategoryDdlchange() {
    this.selectedproductId = 0;
    this.SelectedUnit = '-';
    this.ReqQTY = 0
    if (this.SelectedRawMaterialCategory == '' || this.SelectedRawMaterialCategory == null) {

      return;
    }
    this.NewPOList = [];
    this.StockList.filter(x => x.MixingName == this.SelectedRawMaterialCategory).forEach(x => {
      if (this.SelectedRawList.filter(s => s.ProductId == x.ProductId && s.MixingName == x.MixingName).length <= 0) {
        x.AcceptQTY = x.Quantity;
        x.AvailableQTY = x.ProductStock.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0;

        var issueitem = new IssueProductModel;
        issueitem.ProductId = x.ProductId;
        issueitem.ProductCode = x.ProductCode;
        issueitem.ProductName = x.ProductName;
        issueitem.Quantity = 0;
        issueitem.QuantityValue = 0;
        issueitem.ProductId = x.ProductId;
        this.NewPOList.push(issueitem);

      }
    })
  }
  OnProductDdlchange() {
    this.GetIssueProduct();
    this.IsSupplier = false;
    this.IsBatch = true;
    if (this.Type == 'Store') {

    }
    this.IsBarcode = true;
    this.IsStore = true;
    this.IsRack = true;
    this.IsQuantity = true;
    this.StockQTY = 0

    //this.productionRawMaterial = [];

    //this.listOfSelectedproducts.forEach(item => {
    //  var checkexist = this.productionRawMaterial.filter(x => x.ProductId == item).length;
    //  if (checkexist == 0) {
    //    var product = this.ProductRawList.filter(x => x.ProductId == item)[0];
    //    var rawproduct = new ProductionRawMaterialModel;
    //    rawproduct.ProductId = item;
    //    rawproduct.ProductName = product.ProductName;
    //    rawproduct.ProductCode = product.ProductCode;
    //    rawproduct.Quantity = 0;
    //    rawproduct.Unit = product.Unit;
    //    this.productionRawMaterial.push(rawproduct);
    //  }

    //})
  }
  GetAllSupplier() {

    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
      this.FilteredSupplierList = res
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllSupplier();
      }
    });
  }
  OnSupplierDdlchange() {
    this.RawDetails = this.RawDetailsOriginal.filter(x => x.SupplierId == this.SelectedSupplier);
    this.RawDetailsSupplierOriginal = this.RawDetails;
    console.log(this.RawDetails);
    this.BatchList = this.RawDetails.filter(x => x.SupplierId == this.SelectedSupplier).map(x => x.Batch);
    console.log(this.BatchList);
    this.BatchList = [...new Set(this.BatchList.map(item => item))];

    this.SelectedBatch = '';
    this.SelectedStore = 0;
    this.SelectedBarcode = '';
    this.SelectedRack = 0;
    this.IsSupplier = false;
    this.IsBatch = false;
    this.IsBarcode = true;
    this.IsStore = true;
    this.IsRack = true;
    this.IsQuantity = true;
    this.StockQTY = 0

    // this.SelectedRawDetails = this.RawDetails.filter(x => x.BatchNumber == this.SelectedBatch)
    // this.SelectedStore = this.SelectedRawDetails[0].StoreId;
    // this.SelectedRack = this.SelectedRawDetails[0].RackId;
    // this.StockQTY = this.SelectedRawDetails[0].Quantity;
    // let unique = [...new Set(this.RawDetails.filter(x => x.SupplierId == this.SelectedSupplier).map(item => item.StoreId))];
    //this.StoreList = this.StoreList.filter(x => unique.includes(x.StoreId));
    //this.OnBatchDdlchange()
  }
  OnBatchDdlchange() {
    if (this.Type == 'Store') {


    }
    this.RawDetails = this.RawDetailsSupplierOriginal.filter(x => x.Batch == this.SelectedBatch);
    this.RawDetailsBatchOriginal = this.RawDetails;
    this.BarcodeList = this.RawDetails.filter(x => x.Batch == this.SelectedBatch).map(x => x.BarCode);
    console.log(this.BarcodeList)
    this.BarcodeList = [...new Set(this.BarcodeList.map(item => item))];
    this.SelectedRawDetails = this.RawDetails.filter(x => x.BarCode == this.SelectedBarcode)
    // this.SelectedStore = this.SelectedRawDetails[0].StoreId;
    // this.StockQTY = this.SelectedRawDetails[0].Quantity;
    // console.log(this.SelectedRawDetails)
    this.SelectedBarcode = '';
    this.SelectedStore = 0;
    this.SelectedRack = 0;
    this.IsBarcode = false;
    this.IsStore = true;
    this.IsRack = true;
    this.IsQuantity = true;
    this.StockQTY = 0

  }

  OnBarCodeDdlchange() {
    this.RawDetails = this.RawDetailsBatchOriginal.filter(x => x.BarCode == this.SelectedBarcode);
    this.RawDetailsBarcodeOriginal = this.RawDetails;
    // console.log(this.BarcodeList)
    this.SelectedRawDetails = this.RawDetails.filter(x => x.BarCode == this.SelectedBarcode)
    let unique = [...new Set(this.RawDetails.filter(x => x.BarCode == this.SelectedBarcode).map(item => item.StoreId))];
    this.StoreList = this.StoreListOriginal.filter(x => unique.includes(x.StoreId));
    // this.SelectedStore = this.SelectedRawDetails[0].StoreId;
    // this.StockQTY = this.SelectedRawDetails[0].Quantity;
    // console.log(this.SelectedRawDetails)
    this.SelectedStore = 0;
    this.SelectedRack = 0;
    this.IsStore = false;
    this.IsRack = true;
    this.IsQuantity = true;
    this.StockQTY = 0

  }


  OnStoreDdlchange() {
    this.RawDetails = this.RawDetailsBarcodeOriginal.filter(x => x.StoreId == this.SelectedStore);
    this.RawDetailsStoreOriginal = this.RawDetails;
    // console.log(this.BarcodeList)
    this.SelectedRawDetails = this.RawDetails.filter(x => x.StoreId == this.SelectedStore)
    //   let unique = [...new Set(this.RawDetails.filter(x => x.StoreId == this.SelectedStore).map(item => item.RackId))];
    //   this.StoreList = this.StoreListOriginal.filter(x => unique.includes(x.StoreId));
    //  // this.SelectedStore = this.SelectedRawDetails[0].StoreId;
    // this.StockQTY = this.SelectedRawDetails[0].Quantity;
    // console.log(this.SelectedRawDetails)
    this.SelectedRack = 0;
    this.IsRack = false;
    this.StockQTY = 0

  }

  OnRackDdlchange() {
    console.log(this.SelectedRawDetails.filter(x => x.RackId == this.SelectedRack))
    if (this.Type == 'Store') {
      this.StockQTY = this.SelectedRawDetails.filter(x => x.RackId == this.SelectedRack && x.SupplierId == this.SelectedSupplier)[0].Quantity;
    } else {
      var sumExistQty = this.SelectedRawList.filter(x => x.MixingName != this.SelectedRawMaterialCategory && x.ProductId == this.selectedproductId).reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
      var selectedRawDetails = this.SelectedRawDetails.filter(x => x.RackId == this.SelectedRack)[0];
      if (sumExistQty == 0) {
        this.StockQTY = selectedRawDetails.Quantity;
      }
      else
        this.StockQTY = selectedRawDetails.Quantity - sumExistQty;
    }
    this.IsQuantity = false;
    if (this.StockQTY < 0) {
      this.StockQTY = 0;
    }
  }

  OnProductionDdlchange() {
    this.loader.show();
    this.NewPOList = [];
    this.Production.ProductionRawMaterial.forEach(x => {
      var issueitem = new IssueProductModel;
      issueitem.ProductId = x.ProductId;
      issueitem.ProductCode = x.ProductCode;
      issueitem.ProductName = x.ProductName;
      issueitem.Quantity = x.Quantity;
      issueitem.QuantityValue = x.Quantity;
      issueitem.ProductId = x.ProductId;
      this.NewPOList.push(issueitem);
    })
    this.productionRawMaterial = this.Production.ProductionRawMaterial;
    this.loader.hide();
  }
  updateAllChecked() {

    this.NewPOList.forEach(x => x.IsChecked = this.allChecked)
  }
  OnTypeDdlchange() {
    this.StockList = [];
    this.SelectedOrderNo = '';
    this.SelectedUnit = '-';
    this.loader.show();
    this.GetAllStore();
    if (this.Type == "Store") {
      this.GetAllProductCategory();
      this.NewPOList = [];
      this.ProductFilteredList.forEach(x => {
        var issueitem = new IssueProductModel;
        issueitem.ProductId = x.ProductId;
        issueitem.ProductCode = x.ProductCode;
        issueitem.ProductName = x.ProductName;
        issueitem.Quantity = 0;
        issueitem.QuantityValue = 0;
        issueitem.ProductId = x.ProductId;
        this.NewPOList.push(issueitem);
      })

    }
    else {
      this.GetAllWorkPlanList();
      this.NewPOList = [];
      this.Production = new ProductionModel();
    }
    this.loader.hide();
  }
  bindProduct() {
    this.NewPOList = [];
    this.ProductFilteredList.forEach(x => {
      var issueitem = new IssueProductModel;
      issueitem.ProductId = x.ProductId;
      issueitem.ProductCode = x.ProductCode;
      issueitem.ProductName = x.ProductName;
      issueitem.Quantity = 0;
      issueitem.QuantityValue = 0;
      issueitem.ProductId = x.ProductId;
      this.NewPOList.push(issueitem);
    })
    this.selectedproductId = '';
  }
  OnWorkPlanChange() {
    this.SelectedOrderNo = '';
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + this.SelectedWorkPlan.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "Inspection");
      //this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "Inspection" || x.SaleOrder.Status.toString() == "RawMaterialRequested" || x.SaleOrder.Status.toString() == "RawMaterialIssued" || x.SaleOrder.Status.toString() == "LacquerAssigned" || x.SaleOrder.Status.toString() == "LacquerInProcess");
      console.log(res);
      this.loader.hide();
    }, res => { });
  }
  OnWorkPlanOrderChange() {
    this.loader.show();
    this.Reset();
    this.isTableLoading = true;
    let url = this.ApiUrl + "issueproduct/getstockforsaleorder/" + this.SelectedOrderNo
    this.http.get<SaleOrderProductionRawMaterialModel[]>(url).subscribe(res => {
      console.log(res);
      this.StockList = res;
      this.StockList.forEach(x => {
        x.ProductStock.forEach(y => {
          y.MixingName = x.MixingName
        })
      })
      this.NewRawMaterialCategoryList = [...new Set(res.map(item => item.MixingName))];
      console.log(this.NewRawMaterialCategoryList);


      this.loader.hide();
    }, res => { });
  }

  movesaleorder() {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'The selected store contains all required raw material. Do you want to move this sale order to consumption?',
      nzOnOk: () => {
        this.NewPOList = [];
        var I = new IssueProductModel;
        I.ProductId = 0;
        I.ToStore = this.FromStoreID;
        I.SaleOrderId = parseInt(this.SelectedOrderNo);
        this.NewPOList.push(I);

        this.loader.show();
        let url = this.ApiUrl + "issueproduct/saleorderissueproductrequest";
        this.http.post<IssueProductModel[]>(url, this.NewPOList).subscribe({
          next: res => {
            this.alertService.success("Sale order moved to comsumption"); this.isLoading = false;
            this.loader.hide();
            this.router.navigate(['/home/issue/list'])
          },
          error: res => { this.alertService.error(res.error); this.isLoading = false; },

        });
      },
    });
  }


  OnStoreChange() {
    if (this.Type == "Store") {
      return;
    }
    if (this.FromStoreID == null) {
      return;
    }
    this.loader.show();
    this.SelectedRawMaterialCategory = '';
    this.isTableLoading = true;
    let url = this.ApiUrl + "issueproduct/getstockforsaleorderwithconsumptionstoreid?saleorderid=" + this.SelectedOrderNo + "&consumptionstoreid=" + this.FromStoreID;
    this.http.get<SaleOrderProductionRawMaterialModel[]>(url).subscribe(res => {
      if (res.length == 0) {
        this.movesaleorder();
      }
      else {
        this.StockList = res;
        console.log(res);
        this.StockList.forEach(x => {
          x.ProductStock.forEach(y => {
            y.MixingName = x.MixingName
          })
        })
        this.NewRawMaterialCategoryList = [...new Set(res.map(item => item.MixingName))];
        this.NewPOList = [];
        this.SelectedRawList = [];
        this.StockList.forEach(x => {
          x.AcceptQTY = x.Quantity;
          x.AvailableQTY = x.ProductStock.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0;
          var issueitem = new IssueProductModel;
          issueitem.ProductId = x.ProductId;
          issueitem.ProductCode = x.ProductCode;
          issueitem.ProductName = x.ProductName;
          issueitem.Quantity = 0;
          issueitem.QuantityValue = 0;
          issueitem.ProductId = x.ProductId;
          this.NewPOList.push(issueitem);
        })
        this.selectedproductId = 0;
        this.SelectedSupplier = 0;
        this.SelectedBatch = '';
        this.SelectedStore = 0;
        this.SelectedBarcode = '';
        this.SelectedRack = 0;
        this.IsSupplier = true;
        this.IsBatch = true;
        this.IsBarcode = true;
        this.IsStore = true;
        this.IsRack = true;
        this.IsQuantity = true;
        this.StockQTY = 0
        this.ReqQTY = 0
        this.SelectedQTY = 0
      }
      this.loader.hide();
    }, res => { });
  }

  CheckQty(data: SaleOrderProductionRawMaterialModel) {
    //if (data.AcceptQTY > data.Quantity)
    //{
    //  data.AcceptQTY = 0;
    //  this.alertService.error("QTY cannot be more than required.");
    //}
    if (data.AcceptQTY > data.AvailableQTY) {
      data.AcceptQTY = 0;
      this.alertService.error("QTY cannot be more than " + data.AvailableQTY);
    }
  }
  CheckStoreQTY(data: SaleOrderProductionRawMaterialModel, Stock: ProductStockStoreModel) {
    var totalAcceptQTY = data.ProductStock.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
    console.log(totalAcceptQTY);
    console.log(data);
    if (totalAcceptQTY > data.AcceptQTY) {
      Stock.AcceptQTY = 0;
      Stock.IsChecked = false;
      this.alertService.error("Stock QTY cannot be more than " + data.AcceptQTY);
    }
  }
  AddNewProductRecord() {
    if (this.SelectedQTY <= 0) {
      this.alertService.error("Enter QTY");
      return;
    } else if (this.SelectedQTY > this.StockQTY) {
      this.alertService.error("QTY cannot be more than " + this.StockQTY);
      return;
    }

    console.log(this.RawDetails)
    if (this.Type == "Store") {
      if (this.SelectedRemark.length > 150) {
        this.alertService.error("Remark cannot be more that 150 characters"); return;
      }
      // this.GetIssueProduct();
      if (this.SelectedRack > 0) {

        if (this.SelectedRawList.filter(x => x.RackId == this.SelectedRack && x.ProductId == this.selectedproductId && x.SupplierId == this.SelectedSupplier).length <= 0) {
          var item = this.RawDetails.filter(x => x.RackId == this.SelectedRack && x.ProductId == this.selectedproductId && x.SupplierId == this.SelectedSupplier)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          item.Remark = this.SelectedRemark;
          item.MixingName = this.SelectedRawMaterialCategory;
          this.SelectedRawList.push(item)
        }
      }
      else if (this.SelectedStore > 0) {
        if (this.SelectedRawList.filter(x => x.StoreId == this.SelectedStore && x.ProductId == this.selectedproductId).length <= 0) {
          var item = this.RawDetails.filter(x => x.StoreId == this.SelectedStore && x.ProductId == this.selectedproductId)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          item.Remark = this.SelectedRemark;
          item.MixingName = this.SelectedRawMaterialCategory;
          this.SelectedRawList.push(item)
        }
      }
      else if (this.SelectedBarcode != '') {
        if (this.SelectedRawList.filter(x => x.BarCode == this.SelectedBarcode && x.ProductId == this.selectedproductId).length <= 0) {
          var item = this.RawDetails.filter(x => x.BarCode == this.SelectedBarcode && x.ProductId == this.selectedproductId)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          item.Remark = this.SelectedRemark;
          item.MixingName = this.SelectedRawMaterialCategory;
          this.SelectedRawList.push(item)
        }
      }
      else if (this.SelectedBatch != '') {
        if (this.SelectedRawList.filter(x => x.BatchNumber == this.SelectedBatch && x.ProductId == this.selectedproductId).length <= 0) {
          var item = this.RawDetails.filter(x => x.BatchNumber == this.SelectedBatch && x.ProductId == this.selectedproductId)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          item.Remark = this.SelectedRemark;
          item.MixingName = this.SelectedRawMaterialCategory;
          this.SelectedRawList.push(item)
        }
      }
      else if (this.SelectedSupplier > 0) {
        if (this.SelectedRawList.filter(x => x.SupplierId == this.SelectedSupplier && x.ProductId == this.selectedproductId).length <= 0) {
          var item = this.RawDetails.filter(x => x.SupplierId == this.SelectedSupplier && x.ProductId == this.selectedproductId)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          item.Remark = this.SelectedRemark;
          item.MixingName = this.SelectedRawMaterialCategory;
          console.log(item.Remark);
          this.SelectedRawList.push(item)
        }
      }
      this.SelectedQTY = 0
    }
    else {
      if (this.SelectedRack > 0) {

        if (this.SelectedRawList.filter(x => x.RackId == this.SelectedRack && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory).length <= 0) {
          var item = this.RawDetails.filter(x => x.RackId == this.SelectedRack && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory)[0];
          var sumExistQty = this.SelectedRawList.filter(x => x.MixingName != this.SelectedRawMaterialCategory && x.ProductId == this.selectedproductId && x.RackId == this.SelectedRack).reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
          item.StockQuantity = item.Quantity - sumExistQty;
          item.Quantity = this.SelectedQTY;
          this.SelectedRawList.push(item)
          console.log(item)
        }
      }
      else if (this.SelectedStore > 0) {
        if (this.SelectedRawList.filter(x => x.StoreId == this.SelectedStore && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory).length <= 0) {
          var item = this.RawDetails.filter(x => x.StoreId == this.SelectedStore && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          this.SelectedRawList.push(item)
        }
      }
      else if (this.SelectedBarcode != '') {
        if (this.SelectedRawList.filter(x => x.BarCode == this.SelectedBarcode && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory).length <= 0) {
          var item = this.RawDetails.filter(x => x.BarCode == this.SelectedBarcode && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          this.SelectedRawList.push(item)
        }
      }
      else if (this.SelectedBatch != '') {
        if (this.SelectedRawList.filter(x => x.BatchNumber == this.SelectedBatch && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory).length <= 0) {
          var item = this.RawDetails.filter(x => x.BatchNumber == this.SelectedBatch && x.ProductId == this.selectedproductId && x.MixingName == this.SelectedRawMaterialCategory)[0]
          item.StockQuantity = item.Quantity;
          item.Quantity = this.SelectedQTY;
          this.SelectedRawList.push(item)

        }
      }
      this.SelectedQTY = 0
    }
    this.ResetWithoutTableData();
    this.NewRawMaterialCategoryList = [...new Set(this.StockList.filter(x => !this.SelectedRawList.find(rm => rm.MixingName == x.MixingName && rm.ProductId == x.ProductId)).map(item => item.MixingName))];
  }
  RemoveStockProduct(item: IssueSaleOrderProductsStockModel) {
    var index = this.SelectedRawList.indexOf(item);
    if (index !== -1) {
      this.SelectedRawList.splice(index, 1);
    }
    this.NewRawMaterialCategoryList = [...new Set(this.StockList.filter(x => !this.SelectedRawList.find(rm => rm.MixingName == x.MixingName && rm.ProductId == x.ProductId)).map(item => item.MixingName))];
  }
  OpenDemand() {
    if (this.SelectedQTY > 0) {
      this.demandService._demandModel.ProductId = this.selectedproductId;
      this.demandService._demandModel.StoreId = this.FromStoreID;
      this.demandService._demandModel.Quantity = this.SelectedQTY;
      this.demandService.IsFromOutside = true;

      this.demandService.show();
    }
    else {
      this.demandService.IsFromOutside = false;
      this.demandService.show();
    }
  }
  Reset() {
    this.SelectedRawMaterialCategory = '';
    this.selectedproductId = 0;
    this.SelectedSupplier = 0;
    this.SelectedBatch = '';
    this.SelectedStore = 0;
    this.SelectedBarcode = '';
    this.SelectedRack = 0;
    this.IsSupplier = true;
    this.IsBatch = true;
    this.IsBarcode = true;
    this.IsStore = true;
    this.IsRack = true;
    this.IsQuantity = true;
    this.StockQTY = 0
    this.ReqQTY = 0
    this.SelectedQTY = 0
    this.SelectedUnit = '-'
    this.SelectedRawList = [];
  }
  ResetWithoutTableData() {
    this.SelectedRawMaterialCategory = '';
    this.SelectedProductType = '';
    this.CategoryID = 0;
    this.selectedproductId = 0;
    this.SelectedSupplier = 0;
    this.SelectedBatch = '';
    this.SelectedStore = 0;
    this.SelectedBarcode = '';
    this.SelectedRack = 0;
    this.IsSupplier = true;
    this.IsBatch = true;
    this.IsBarcode = true;
    this.IsStore = true;
    this.IsRack = true;
    this.IsQuantity = true;
    this.StockQTY = 0;
    this.ReqQTY = 0;
    this.SelectedQTY = 0;
    this.SelectedUnit = '-';
    this.NewPOList = [];
  }
  CalculateAmount(data: IssueSaleOrderProductsStockModel) {
    return (data.Quantity * data.PerUnitPrice).toFixed(2)
  }
}
