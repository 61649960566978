<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Product Stock History Report</nz-page-header-title>
      <nz-page-header-subtitle>Product Stock History report</nz-page-header-subtitle>
      <nz-page-header-extra>

      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Product</label>
                <nz-select name="select-error" [(ngModel)]="request.ProductId" nzShowSearch
                  (ngModelChange)="onDdlChange()">
                  <nz-option *ngFor="let data of ProductList" [nzValue]="data.ProductId"
                    [nzLabel]="data.ProductName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Supplier</label>
                <nz-select name="select-error" [(ngModel)]="request.SupplierId" nzShowSearch nzAllowClear
                  (ngModelChange)="onDdlChange()">
                  <nz-option *ngFor="let data of SupplierList" [nzValue]="data.SupplierId"
                    [nzLabel]="data.SupplierName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="5">

            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date (From)</label>
                <nz-date-picker [nzShowTime]="true" [nzFormat]="'MM-dd-yyyy hh:mm a'" nzPlaceHolder="From Date" style="width: 200px; margin-right: 8px" name="orderDateFrom"
                  [(ngModel)]="request.FromDate" nzAllowClear (ngModelChange)="onDdlChange()"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date (To)</label>
                <nz-date-picker [nzShowTime]="true" [nzFormat]="'MM-dd-yyyy hh:mm a'" nzPlaceHolder="To Date" style="width: 200px; margin-right: 8px" name="orderDateTo"
                  [(ngModel)]="request.ToDate" nzAllowClear (ngModelChange)="onDdlChange()"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="search()">
              Search
            </button>
          </div>

        </div>


      </div>
    </div>
    <!--<div style="padding: 8px;float:right">-->
    <!--<input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search name / code / type" [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
  <button nz-button nzSize="small" nzType="primary" (click)="search()" [disabled]="IsSearching" style="margin-right: 8px;">Search</button>
  <button nz-button nzSize="small" (click)="reset()">Reset</button>-->
    <!--<button nz-button nzSize="small" (click)="export()">
      <i nz-icon nzType="export" nzTheme="outline"></i>
      Export
    </button>
  </div>-->
    <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"
      [nzAction]="actionTemplate3"></nz-alert>
    <ng-template #actionTemplate3>
      <button nz-button nzSize="small" nzType="primary" nzGhost (click)="reset()">Reset filters</button>
    </ng-template>

    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="ReportList.length>0" [nzColumn]="4">
      <nz-descriptions-item nzTitle="Type" nzColon="true"><b>{{ReportList[0].ProductType}}</b> </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Product Name"><b>{{ReportList[0].ProductName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Product Code"><b>{{ReportList[0].ProductCode}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Unit"><b>{{ReportList[0].Unit}}</b></nz-descriptions-item>


    </nz-descriptions>
    <nz-divider nzText="Stock TimeLine" *ngIf="ReportList.length>0"></nz-divider>
    <nz-timeline>
      <nz-timeline-item *ngFor="let data of timeline" [nzColor]='data.Type == "Consumption"? "red" : "green" '>
        {{data.Date.toString() | DatetimeConverter}} : <b>QTY : {{data.QTY}}</b> ({{data.Description}}) <b *ngIf="data.RejectedQTY > 0"> : Rejected QTY : {{data.RejectedQTY}} </b> <b
          style="float:right">Remaining QTY : {{ data.RemainingQTY }}</b>
      </nz-timeline-item>

    </nz-timeline>
    <nz-divider nzText="Summary : Closing Stock: {{ClosingStock}} " *ngIf="ReportList.length>0"></nz-divider>
    <nz-table *ngIf="Summary.length>0" style="width: 100%" nzSize="small" [nzPageSize]="100" style="width: 100%"
      #basicTable [nzData]="this.Summary" nzBordered>

      <thead>
        <tr>

          <th nzWidth="160px">Date</th>
          <th nzWidth="150px">Store</th>
          <th nzWidth="150px">Rack</th>
          <th nzWidth="100px">Available QTY</th>
          <th nzWidth="200px">Batch</th>
          <th nzWidth="150px">Barcode</th>
          <th nzWidth="120px">Rate</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">


          <td>{{ data.Date.toString() | DatetimeConverter }}</td>
          <td>{{ data.Store }}</td>
          <td>{{ data.Rack}}</td>
          <td>{{ data.RemainingQTY }}</td>
          <td>{{ data.Batch }}</td>
          <td>{{ data.Barcode }}</td>
          <td>{{ data.Rate }}</td>

        </tr>
      </tbody>
    </nz-table>

  </div>
</div>