<nz-layout>
  <nz-header>
    <div class="app-header">
      <div class="logo">
        <img src="assets/img/zaibunco.png" style="width: 150px;">
      </div>
    </div>
  </nz-header>
  <nz-content style="height: 100vh; background-color:#f8f8fb; ">
    <h1 class="text-secondary hero--title text-center">Zaibunco Industries PVT. LTD.</h1>
    <div class="text_Sub sub_title text-center">Leading Manufacturer of PU/PVC Synthetic Leather</div>
    <div style="background: #f8f8fb;padding:30px;">
      <div nz-row class="justify-content-center gap-5">
        <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
          <div>
            <nz-card class="custom_card card_padding">
              <button type="button" class="custom_btn font-weight-700 btn-blue-3 " (click)="GotoBelt()">Belt</button>
            </nz-card>
          </div>
        </div>

        <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
          <div>
            <nz-card class="custom_card card_padding">
              <button type="button" class="custom_btn font-weight-700 btn-blue-3 " style="
    background-color: #50cd89 !important;
" nzBlock routerLink="/home/welcome">Rexine</button>
            </nz-card>
          </div>
        </div>
      </div>
      <div nz-row class="justify-content-center gap-5">
        <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
          <div>
            <nz-card class="custom_card card_padding">
              <button type="button" class="custom_btn font-weight-700 btn-blue-3 "
                (click)="GotoLeather()">Leather</button>
            </nz-card>
          </div>
        </div>
      </div>
    </div>

  </nz-content>
</nz-layout>