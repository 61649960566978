
export class DemandModel {
  DemandId: number;
  StoreId: number | null;
  StoreName: string;
  ProductName: string;
  ProductId: number | null;
  Quantity: number | null;
  Unit: string;
  Status: string;
  AddedBy: string;
  AddedDate: Date | null;
  Poid: number | null;
  PONumber: string | null;
  IsChecked: boolean = false;
  ProductType: string = '';
  ProductCategoryId: number = 0;
  ProductFirstSubCategoryId: number = 0;
  ProductSecSubCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategory: string = '';
  ProductSecSubCategory: string = '';
}
