<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Stock</nz-page-header-title>
      <nz-page-header-subtitle>Manage your stocks here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!-- <button nz-button nzType="primary" (click)="showModal()">Add New</button> -->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Type</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                  (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                  <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                  <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                  <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Category</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID"
                  name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                  <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                    [nzLabel]="s.ProductCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>SubCategory</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                  [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category"
                  (ngModelChange)="GetAllSecondCategory($event)">
                  <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId"
                    [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>2nd SubCategory</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                  [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="SecondCategoryID"
                  (ngModelChange)="GetSecondCategoryFilteredProduct()">
                  <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                    [nzLabel]="s.ProductSecSubCategory"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Name</label>
                <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="selectedProduct"
                  name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                    [nzLabel]="s.ProductName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetReport()">
              Search
            </button>
          </div>

        </div>


      </div>
    </div>
    <div style="padding: 8px;float:right">
      <button nz-button nzSize="small" style="margin-left: 8px;" (click)="export()"><i nz-icon nzType="export"
          nzTheme="outline"></i> Export</button>
      <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
        style="margin-left:5px"><i style="top: 2px;right: 39px;position: absolute;" nz-icon nzType="printer"
          nzTheme="outline"></i>Print</button>
    </div>


    <nz-table id="print-section" nzSize="small" style="width: 100%;" #basicTable [nzData]="this.StockProductList"
      [nzPageSize]="100" [nzLoading]="isTableLoading" nzBordered>
      <!--begin::Table head-->
      <thead class="ant-table-thead ng-star-inserted">
        <!--begin::Table row-->
        <tr class="ant-table-row ng-star-inserted">
          <th class="ant-table-cell" style="width: 100px;">Product Type</th>
          <th style="width: 100px; ;">Product Name</th>
          <th style="width: 100px;">Quantity / Unit</th>
        </tr>

        <!--end::Table row-->
      </thead>
      <!--end::Table head-->
      <!--begin::Table body-->
      <tbody class="fw-bold text-gray-600">
        <!--begin::Table row-->
        <tr *ngFor="let spl of basicTable.data ;">
          <td>
            {{spl.ProductType}}
          </td>
          <td>
            {{spl.ProductName}}
          </td>
          <td>
            {{spl.Quantity}} / {{spl.Unit}}
          </td>
        </tr>
      </tbody>
    </nz-table>



  </div>
</div>