import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserInfo } from '../../Authentication/UserInfo';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-UsersLoginHistory',
  templateUrl: './UsersLoginHistory.component.html'
})
export class UsersLoginHistoryComponent implements OnInit {
  LoginHistoryList: any = [];
  FilteredLoginHistoryList: any = [];
  constructor(private authService: AuthService, public http: HttpClient) {

  }
  ngOnInit() {
    this.GetLoginDetails();
  }

  async GetLoginDetails() {
    this.LoginHistoryList = (await this.authService.GetSignInList()).value;
    if (UserInfo.EmailID.includes('@damasinfo.com')) {
      this.FilteredLoginHistoryList = this.LoginHistoryList
    }
    else {
      this.FilteredLoginHistoryList = this.LoginHistoryList.filter((x) => x.userPrincipalName?.includes('pms.zaibunco.com'));
    }
  }

}
