import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-CostEstimationListPrint',
  templateUrl: './costEstimationListPrint.component.html',
  styleUrls: ['./costEstimationListPrint.component.css'],
})
export class CostEstimationListPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  private route$: Subscription = new Subscription();
  count: 0;
  totalItemsCount: number;
  CostEstimationListOriginal: any[];
  request = {
    FabricProductId: 0,
    CustomerId: 0,
    Thick: 0,
    EstimationFromDate: null, // new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    EstimationToDate: null, // new Date(new Date().setHours(23, 59, 59)),
    EstimationOrderId: 6
}
  constructor(
    public http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route$ = this.route.queryParams.subscribe(params => {
      this.request.FabricProductId = params['FabricProductId'];
      this.request.CustomerId = params['CustomerId'];
      this.request.Thick = params['Thick'];
      this.request.EstimationFromDate = params['EstimationFromDate'];
      this.request.EstimationToDate = params['EstimationToDate'];
      this.request.EstimationOrderId = params['EstimationOrderId'];
    });
    this.GetFilteredCosting();
  }
  GetFilteredCosting() {
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + 'estimationorder/getestimationlistbyfilter';
    this.http.post<any[]>(url, this.request).subscribe({
      next: res => {
        this.CostEstimationListOriginal = res;
        count = this.totalItemsCount;
        this.CostEstimationListOriginal.forEach((x) => {
          count++;
          x.SerialNo = count;
        });
        this.totalItemsCount = count;
        setTimeout(function () {
          window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
      },
      error: res => {
        this.count++
        if (this.count < 2) {
          this.GetFilteredCosting();
        }
      }
    })
  }

}
