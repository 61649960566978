<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
  
      <nz-page-header [nzGhost]="false">
        <nz-page-header-title>Lacquer List</nz-page-header-title>
        <nz-page-header-subtitle>Manage your Lacquer  here</nz-page-header-subtitle>
        <nz-page-header-extra>
  
          <button nz-button nzType="primary" (click)="showModal()" *ngIf="this.permission.Add">Add New</button>
          <!-- <button type="button" class="btn btn-warning" >Add new</button> -->
        </nz-page-header-extra>
  
      </nz-page-header>
      <nz-divider></nz-divider>
      <div style="padding: 8px; float: right">
        <input
          type="text"
          style="width: 250px; margin-right: 8px"
          nz-input
          placeholder="Search name / code"
          [(ngModel)]="searchValue"
          (keydown)="onKeydown($event)"
          (ngModelChange)="ValidateText()"
        />
        <button
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="search()"
          style="margin-right: 8px"
        >
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
      </div>
      <nz-table nzSize="small"[nzPageSize]="100" style="width: 100%;"  [nzScroll]="{ x: '1200px',y:'515px' }" #basicTable [nzData]="this.LacquerList" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr>
            <th nzWidth="60px" nzLeft> Name</th>
            <th nzWidth="100px">Code</th>
            <th nzWidth="130px">Description</th>
            <th nzWidth="80px" style="text-align:center" nzRight>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td nzLeft>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
            
              
  
  
            <td nzRight style="text-align:center"> 
              <!-- <button  class="btn btn-sm btn-dark">Edit</button> -->
              <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
              <button class="btn btn-sm btn-light-danger" [disabled]="!this.permission.Delete" (click)="handleDelete(data)">Delete</button>
  
            </td>
          </tr>
        </tbody>
      </nz-table>
  
  
      <nz-modal [nzWidth]="800"
                [nzStyle]="{ top: '20px' }"
                [(nzVisible)]="isVisible"
                [nzTitle]="modalTitle"
                [nzContent]="modalContent"
                [nzFooter]=null
                (nzOnCancel)="handleCancel()">
        <ng-template #modalTitle>{{PopUpTitle}}</ng-template>
  
        <ng-template #modalContent>
          <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
            <nz-form-item>

              <nz-form-label [nzSpan]="8" nzRequired>Name</nz-form-label>
              <nz-form-control [nzSpan]="14" [nzErrorTip]="LacquerNameError">
                <input nz-input formControlName="LacquerName" name="LacquerName" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Code</nz-form-label>
              <nz-form-control [nzSpan]="14" [nzErrorTip]="LacquerCodeError">
                <input nz-input formControlName="Code" name="Code" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="8">Description</nz-form-label>
              <nz-form-control [nzSpan]="14"  nzErrorTip="Description cannot be more than 200 characters">
                <textarea nz-input formControlName="Description" name="Description"></textarea>
              </nz-form-control>
            </nz-form-item>
            <div nz-row [nzGutter]="24">
              <!-- <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select supplier Name">
                    <label>Category</label>
                     <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="NewOrderProductItem.CategoryID" [ngModelOptions]="{standalone: true}" name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                      <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
              <!-- <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select supplier Name">
                    <label>Sub-Category</label>
                     <nz-select #microwaveRef class="form-select" nzShowSearch nzSize="default" [(ngModel)]="NewOrderProductItem.FirstCategoryID" [ngModelOptions]="{standalone: true}" name="Category" nzAllowClear nzPlaceHolder="First Category" (ngModelChange)="GetAllSecondCategory($event)">
                      <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
              <!-- <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select supplier Name">
                    <label>2nd Sub-Category</label>
                     <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="NewOrderProductItem.SecondCategoryID" [ngModelOptions]="{standalone: true}" name="Category" nzAllowClear nzPlaceHolder="Second Category">
                      <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                    <label>Product</label>
                     <!-- <nz-select #microwaveRef class="form-select"  nzMode="multiple" nzShowSearch nzSize="default" [(ngModel)]="selectedProductM" [ngModelOptions]="{standalone: true}" name="Category" nzAllowClear nzPlaceHolder="Product" (ngModelChange)="OnProductDdlchange($event)  ">
                      <nz-option *ngFor="let s of this.ProductList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                    </nz-select>  -->
                    
                    <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple" [(ngModel)]="listOfSelectedproducts" (ngModelChange)="OnProductDdlchange()" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ProductRawList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
                    </nz-select>

                  </nz-form-control>
                </nz-form-item>
              </div>


              <!-- <div nz-col [nzSpan]="3">
                <a class="btn btn-sm btn-light-primary" style="float:right  " (click)="btnAddClick()"><i nz-icon nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add</a>
              </div> -->
            </div>
            <nz-table nzSize="small" style="width: 100%;" #basicTable1 [nzData]="['']" nzBordered>
              <thead>
                <tr>

                  <th>Product</th>
                  <th>Code</th>
                  <th>GSM</th>
                  <th>Unit</th>
                  <th>Price</th>
                  <th>Qty</th>
                 
                
                 
                  
                 
                  <th nzWidth="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of productionRawMaterial;let i=index">
                  <td>{{ data.ProductName }}</td>
                  <td>{{ data.ProductCode }}</td>
                  <td>{{ data.AvgGsm}}</td>                 
                  <td>{{ data.Unit }}</td>
                  <td>
                    <input  style="width: 100px;" placeholder="Price" value="data.Price" [(ngModel)]="data.Price" [ngModelOptions]="{standalone: true}" />
                  </td>
                <td>
                  <input style="width: 100px;"  nz-input placeholder="Amount" value="data.Quantity" [(ngModel)]="data.Quantity" [ngModelOptions]="{standalone: true}" (ngModelChange)="calculateTotal()" />
                </td>         
                  
                 
                  <td>
                    <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)"><i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Remove</a>
                  </td>


                  <!--<td>
          <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
          <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>
        </td>-->
                </tr>
              </tbody>
            </nz-table>
                
            <div nz-row [nzGutter]="24" >  
              <div nz-col [nzSpan]="6">
                <!-- <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Total</label>
                    <input nz-input name="Total" placeholder="Total" value="NewLacquer.Total" disabled [(ngModel)]="NewLacquer.Total" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item> -->

              </div>  
              <div nz-col [nzSpan]="6">
                <!-- <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Total</label>
                    <input nz-input name="Total" placeholder="Total" value="NewLacquer.Total" disabled [(ngModel)]="NewLacquer.Total" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item> -->

              </div>  

              <div nz-col [nzSpan]="6">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Weight Gsm</label>
                    <input nz-input name="WeightGsm" placeholder="Weight Gsm" disabled value="NewLacquer.WeightGsm" [(ngModel)]="NewLacquer.WeightGsm" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>

              </div>
              <div nz-col [nzSpan]="6">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label>Total</label>
                    <input nz-input name="Total" placeholder="Total" value="NewLacquer.Total" disabled [(ngModel)]="NewLacquer.Total" [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>

              </div>

            </div>
              

            <div class="text-center">
              <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
            </div>

          </form>
        </ng-template>
      </nz-modal>
  
    </div>
  </div>
  
