import { CustomerModel } from "./SupplierModel";

export class ProformaInvoiceItemModel {
  ProformaInvoiceItemId: number = 0;
  ProformaInvoiceId: number = 0;
  SaleFormulationCodeId: number = 0;
  SaleFormulationCode: string = '';
  SaleFormulationCodeProductName: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Amount: number = 0;
  DescriptionOfGoods: string = '';
  ArticleName: string = '';
}

export class ProformaInvoiceModel {
  ProformaInvoiceId: number = 0;
  CountryOfOrigin: string = '';
  ProformaInvoiceDate: string = '';
  MaterialType: string = '';
  Hsncode: string = '';
  PortOfLoading: string = '';
  DeliveryTermId: String = '';
  ModeOfTransport: string = '';
  FinalDestination: string = '';
  PortOfDischarge: string = '';
  Gst: number = 0;
  AddedDate: string = '';
  Customer!: CustomerModel;
  CustomerId: number = 0;
  Gstn: string = '';
  ProformaInvoiceNumber: string = '';
  ConsignorReference: string = '';
  ReferenceType: string = 'Consignor';
  BuyerReferenceNumber: string = '';
  CountryOfDestinaton: string = '';
  BankName: string = '';
  BankBranch: string = '';
  BankAccountNumber: string = '';
  Ifsccode: string = '';
  SwiftCode: string = '';
  BeneficiaryName: string = '';
  AddedBy: string = '';
  Moq: number = 0;
  Discount: number = 0;
  Moqtotal: number = 0;
  TotalPrice: number = 0;
  Currency: string = '';
  TermsCondition: string = '';
  BankId: number = 0;
  ProformaInvoiceItem: ProformaInvoiceItemModel[] = [];
  ProformaInvoiceListOriginal: any;
}
